import { combineReducers } from "redux";

import steps from "./steps/steps.reducer";
import { ReducerHelpers } from "../../../../candidate/_helpers/ReducerHelpers";
import HeroOnboardingConstants from "../../../_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../_constants/actions/HeroProfileConstants";

const loading = (state, action) =>
    ReducerHelpers.loading(state, action, [
        HeroOnboardingConstants.SAVE_USER,
        HeroOnboardingConstants.SAVE_PERSONAL,
        HeroOnboardingConstants.SAVE_POSITIONS,
        HeroOnboardingConstants.SAVE_ADVICE,
        HeroOnboardingConstants.SAVE_SKILLS,
        HeroOnboardingConstants.GET_TEST,
        HeroOnboardingConstants.VIDEO_SKIP,
        HeroProfileConstants.PUBLISH,
        // HeroProfileConstants.GET_SOFT_SKILLS_RESULTS,
        HeroProfileConstants.SAVE_PROFILE_PICTURE,
    ]);

export default combineReducers({ steps, loading });
