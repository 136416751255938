import { useTranslate } from "react-translate.ts";
import { Panel } from "../../../../common/components/Panel/Panel";

import AdminEdit from "../../AdminEdit/AdminEdit";
import QualificationEdit, {
    QualificationEditProps,
} from "../../QualificationEdit/QualificationEdit";

interface QualificationEditPanelProps extends QualificationEditProps {}

export default function QualificationEditPanel(
    props: QualificationEditPanelProps
) {
    const translate = useTranslate("admin.jobs.edit.qualification");

    return (
        <Panel anchor="qualification" data-test="qualification-edit-panel">
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <QualificationEdit {...props} />
        </Panel>
    );
}
