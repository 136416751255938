/**
 * 
 * Removes function handler to element
 * @param {Element} node
 * @param {string} eventName
 * @param {function} handler
 * @param {boolean} shouldCapture
 */
export function removeEventHandler<T extends Node | Window>(node: T, eventName: string, handler: any, shouldCapture?: boolean) {
    // @ts-expect-error
    if (node.detachEvent) {
        // @ts-expect-error
        node.detachEvent(`on${eventName}`, handler);
    } else {
        node.removeEventListener(eventName, handler, shouldCapture);
    }
}
