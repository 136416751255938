import { combineReducers } from "redux";
import authentication from "./authentication.reducer";
import auth from "./auth/auth.reducer";
import notifications from "./notifications";
import languages from "./languages.reducer";
import onboarding from "./onboarding.reducer";
import profile from "./profile.reducer";
import heroes from "./heroes/heroes.reducer";
import _public from "./public/public.reducer";
import { CompanyProfileReducer } from "../_actions/CompanyProfileActions";
import { HeroReducer } from "../_actions/HeroActions";
import { CookieConfigReducer } from "../_actions/CookieConfigActions";

export default combineReducers({
    authentication,
    auth,
    notifications,
    languages,
    onboarding,
    company: CompanyProfileReducer,
    profile,
    public: _public,
    heroes,
    hero: HeroReducer,
    cookie: CookieConfigReducer
});
