import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ProfileCompletion, ProfileTabs } from './';
import {
    FlexItem,
    FlexContainer,
    ResponsiveContainer,
} from '../../../../common/components';
import { ProfileBasicMobile } from './mobile/ProfileBasicMobile';
import { PROFILE_AVATAR_EDIT_PATH } from '../../../_constants/route.constants';
import ProfileSelectors from '../../../_selectors/ProfileSelectors';

export function ProfileBasic() {
    const { name, headerTitle } = useSelector(mapState);

    return (
        <ResponsiveContainer
            renderMobile={() => (
                <ProfileBasicMobile profilePicture={<ProfilePicture />} />
            )}
        >
            <div id="profile-basic">
                <FlexContainer spacing={2} justify="center">
                    <FlexItem size={2} textAlign="center">
                        <ProfilePicture />
                    </FlexItem>
                    <FlexItem size={7}>
                        <div id="profile-info">
                            <FlexContainer>
                                <FlexItem size={12}>
                                    <h4>{name}</h4>
                                    <span id="header-title">{headerTitle}</span>
                                </FlexItem>
                                <FlexItem size={12}>
                                    <ProfileTabs id="profile-tabs" />
                                </FlexItem>
                            </FlexContainer>
                        </div>
                    </FlexItem>
                    <FlexItem size={3}>
                        <ProfileCompletion />
                    </FlexItem>
                </FlexContainer>
            </div>
        </ResponsiveContainer>
    );
}

function ProfilePicture() {
    const { imageUrl } = useSelector(mapState);

    return (
        <Link to={PROFILE_AVATAR_EDIT_PATH}>
            <div className="profile-picture">
                <img src={imageUrl || '/img/avatar.svg'} alt="" />
                <div
                    id="profile-picture-icon"
                    className={!!imageUrl ? 'has-image' : ''}
                />
            </div>
        </Link>
    );
}

function mapState(state) {
    return {
        name: ProfileSelectors.getFullName(state),
        imageUrl: ProfileSelectors.getPhoto(state).imageUrl,
        headerTitle: ProfileSelectors.getHeaderTitle(state),
    };
}
