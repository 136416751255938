import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { AutocompleteService } from "../../../candidate/_services";
import Field from "../../../common/components/input/Field/Field";
import RangeField from "../../../common/components/input/RangeField/RangeField";
import Select from "../../../common/components/input/Select/Select";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import IIdealLanguage from "../../../common/types/IIdealLanguage";
import ILanguage from "../../../common/types/ILanguage";
import ILanguageLevel from "../../../common/types/ILanguageLevel";
import ISkillWeightType from "../../../common/types/ISkillWeightType";
import { LanguageActions } from "../../../common/_actions";
import { SKILL_WEIGHT_TYPE_PREFERRED } from "../../../common/_constants/actions/skill.constants";
import { LanguageSelectors } from "../../../common/_selectors/LanguageSelectors";
import AdminJobActions from "../../_actions/AdminJobActions";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import SkillsEdit from "../SkillsEdit/SkillsEdit";
import { Formik, useFormikContext } from "formik";
import FormField from "../../../common/components/input/FormField";
import FormSelect from "../../../common/components/input/FormSelect";
import { ArraySchema, ObjectSchema } from "yup";
import { REQUIRED_FORM_FIELD } from "../../../common/_constants/validation.constants";

export type LanguageEditProps = {
    languages: IIdealLanguage[];
    jobPostId: number;
};

type Values = {
    language: ILanguage;
    weightType: ISkillWeightType;
    levelMin: ILanguageLevel;
    levelMax: ILanguageLevel;
};

type State = {
    visible: boolean;
    values: Values;
};

const TYPE_FIELD = "weightType";
const LANG_FIELD = "language";
const LEVEL_MIN_FIELD = "levelMin";
const LEVEL_MAX_FIELD = "levelMax";

const DEFAULT_VALUES: Values = {
    [LANG_FIELD]: undefined,
    [LEVEL_MIN_FIELD]: undefined,
    [LEVEL_MAX_FIELD]: undefined,
    [TYPE_FIELD]: SKILL_WEIGHT_TYPE_PREFERRED,
};

export default function LanguageEdit(props: LanguageEditProps) {
    const { languages, jobPostId } = props;
    const levels = useSelector(LanguageSelectors.getLanguageLevels);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isVisible && levels.length === 0) {
            dispatch(LanguageActions.getLanguageLevels());
        }
    }, [isVisible, levels, dispatch]);

    return (
        <Formik initialValues={DEFAULT_VALUES} onSubmit={submit} validationSchema={createValidation()}>
            <Inner
                languages={languages}
                jobPostId={jobPostId}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        </Formik>
    );

    function createValidation() {
        return new ObjectSchema({
            [LANG_FIELD]: new ObjectSchema().required(REQUIRED_FORM_FIELD).nullable(),
        });
    }

    function submit(values: Values) {
        dispatch(AdminJobActions.saveLanguage({ ...values, jobPostId }));
    }
}

type InnerProps = LanguageEditProps & {
    isVisible: boolean;
    setIsVisible: (newValue: boolean) => void;
};

function Inner(props: InnerProps) {
    const { languages, jobPostId, isVisible, setIsVisible } = props;
    const translate = useTranslate("admin.edit.skills.lang");
    const translateSkills = useTranslate("admin.edit.skills");
    const levels = useSelector(LanguageSelectors.getLanguageLevels);
    const pending = AdminJobSelectors.useGetPending();
    const dispatch = useDispatch();
    const formik = useFormikContext<Values>();

    return (
        <SkillsEdit<IIdealLanguage>
            skillName={translate("skillName")}
            data={languages}
            head={[<div>{translateSkills("table.level")}</div>]}
            cols={["3fr", "2fr"]}
            createItem={(lang) => {
                const isLoading = pending.lang.includes(lang.id);
                if (isLoading) {
                    return <Skeleton height={18} count={2} width="80%" />;
                }

                return (
                    <>
                        <div data-test="lang-edit-name">
                            {lang.language.name}
                        </div>
                        <div>{renderLevelRange(lang)}</div>
                    </>
                );
            }}
            title={translate("title")}
            isVisible={isVisible}
            onEdit={openEditDialog}
            onCreate={openNewDialog}
            onSave={save}
            onCancel={cancel}
            onClose={cancel}
            onDelete={deleteLang}
            createButtonText={translate("create")}
            getItemName={(item) => item?.language?.name}
            valueName={TYPE_FIELD}
        >
            <FormField name={LANG_FIELD} label={translate("form.skill.label")} required>
                <FormSelect<ILanguage>
                    name={LANG_FIELD}
                    placeholder={translate("form.skill.placeholder")}
                    loadOptions={AutocompleteService.languages}
                    getOptionLabel={(option) => option.name}
                    searchable
                    data-test="lang-edit-skill"
                />
            </FormField>
            <RangeField
                label={translate("form.level.label")}
                from={
                    <FormSelect<ILanguageLevel>
                        name={LEVEL_MIN_FIELD}
                        placeholder={translate("form.level.placeholder.min")}
                        options={levels}
                        getOptionLabel={getLevelOptionLabel}
                        data-test="lang-edit-level-min"
                    />
                }
                to={
                    <FormSelect<ILanguageLevel>
                        name={LEVEL_MAX_FIELD}
                        placeholder={translate("form.level.placeholder.max")}
                        options={levels}
                        getOptionLabel={getLevelOptionLabel}
                        data-test="lang-edit-level-max"
                    />
                }
            />
        </SkillsEdit>
    );

    async function save() {
        await formik.submitForm();

        if (formik.isValid) {
            closeDialog();
        }
    }

    function cancel() {
        closeDialog();
    }

    function openNewDialog() {
        formik.setValues(DEFAULT_VALUES);
        setIsVisible(true);
    }

    function openEditDialog(values: IIdealLanguage) {
        formik.setValues(values);
        setIsVisible(true);
    }

    function closeDialog() {
        formik.setValues(DEFAULT_VALUES);
        formik.setErrors({});
        formik.setTouched({});
        setIsVisible(false);
    }

    function deleteLang(language: IIdealLanguage) {
        dispatch(
            AdminJobActions.deleteLanguage({
                id: language.id,
                jobPostId,
            })
        );
    }
}

function getLevelOptionLabel(option: ILanguageLevel) {
    return option.name;
}

function renderLevelRange(language: IIdealLanguage) {
    const { levelMax, levelMin } = language;
    const parts = [];
    if (levelMin) {
        parts.push(levelMin.name);
    }
    if (levelMax) {
        parts.push(levelMax.name);
    }

    if (levelMin && levelMax) {
        parts.splice(1, 0, " - ");
    }

    return parts.join("");
}
