import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const team = (state: MainState) => state.admin.team;

const getMembers = createSelector(team, (team) => team.members);

const isLoading = createSelector(team, (team) => team.loading);
const getUpdating = createSelector(team, (team) => team.updating);

const AdminTeamSelectors = {
    getMembers,
    isLoading,
    getUpdating
};

export default AdminTeamSelectors;
