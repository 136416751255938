import React, { useEffect } from 'react';
import Async from 'react-select/async';

import {
    useReactSelectTheme,
    useReactSelectStyle,
} from '../../hooks/useReactSelectStyle';
import { createClass } from 'classname-helper';

export function SearchBar({
    searchProps,
    SearchInput,
    children,
    buttons,
    buttonLabel,
    onApply,
    isMobile,
    buttonIsLoading,
    className,
}) {
    const styles = useReactSelectStyle({}, { isMobile }).plainSelect;
    const selectTheme = useReactSelectTheme();

    useEffect(() => {
        window.addEventListener('keydown', handleKeys);

        return () => window.removeEventListener('keydown', handleKeys);
    }, [handleKeys]);

    return (
        <div
            className={createClass({
                'search-bar': true,
                [className]: !!className,
            })}
        >
            <div className="search-bar--inputs font-book">
                <div className="search-input">
                    <div className="search-input--icon search">
                        <img src="/img/icons/search.svg" alt="" />
                    </div>
                    {SearchInput ? (
                        SearchInput
                    ) : (
                        <Async
                            styles={styles}
                            theme={selectTheme}
                            {...searchProps}
                        />
                    )}
                </div>
                {children}
            </div>
            <div className="search-bar--buttons">
                {buttons}
                <button
                    className={`search-bar--buttons--button ${
                        buttonIsLoading ? 'is-loading' : ''
                    }`}
                    onClick={onApply}
                    disabled={buttonIsLoading}
                >
                    <strong>{buttonLabel}</strong>
                </button>
            </div>
        </div>
    );

    function handleKeys(event) {
        if (event.key === 'Enter') {
            onApply();
        }
    }
}
