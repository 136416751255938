import {
    ONBOARDING_TEST_BEGIN,
    ONBOARDING_TEST_SUCCESS,
    ONBOARDING_TEST_ERROR
} from '../../../common/_constants/actions/onboarding.constants';

const initialState = { isLoading: false, isLoaded: false };
export default function test(state = initialState, action) {
    switch (action.type) {
        case ONBOARDING_TEST_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case ONBOARDING_TEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        case ONBOARDING_TEST_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}
