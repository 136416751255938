import { combineReducers } from "redux";
import HeroListConstants from "../../../common/_constants/actions/HeroListConstants";

import { ReducerHelpers } from "../../_helpers/ReducerHelpers";

const state = (state, action) =>
    ReducerHelpers.loading(state, action, [HeroListConstants.GET_TOP_MATCHES]);

export default combineReducers({ top, state });

function top(state = [], action) {
    return list(state, action, HeroListConstants.GET_TOP_MATCHES_SUCCESS);
}

function list(state, action, actionName) {
    if (action.type === actionName) {
        return action.payload.content;
    }

    return state;
}
