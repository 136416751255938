import Select from 'react-select';

import { useTheme } from '../../_helpers/useTheme';

export function ReactSelect({
    hasError,
    styles,
    showDropdown = false,
    placeholder,
    noBorder,
    ...rest
}) {
    const { reactSelect } = useTheme({ showDropdown, hasError, noBorder });
    return (
        <Select
            styles={reactSelect}
            placeholder={placeholder}
            {...rest}
        />
    );
}
