import { Translate } from "react-translate.ts";

import {
    HardSkillsMatchChart,
    JobMatch,
    ProfileItemCard,
} from "../../../common/components";

export function HardSkills({ fit, match, skills }) {
    return (
        <ProfileItemCard
            label="candidate.profile.hardSkills.title"
            extraContent={<ExtraContent fit={fit} />}
        >
            <HardSkillsMatchChart
                skills={match?.skillScores?.hardSkills || []}
                additionalSkills={skills}
            />
        </ProfileItemCard>
    );
}

function ExtraContent({ fit }) {
    return (
        <JobMatch
            fit={fit}
            label={<Translate>common.jobs.match.hardSkills</Translate>}
            size="small"
        />
    );
}
