const COMMON = "common";
const CANDIDATE = "candidate";
const ADMIN = "admin";

const TranslationsConstants = {
    common: COMMON,
    candidate: CANDIDATE,
    admin: ADMIN,
    softSkillsTest: CANDIDATE + ".softSkillsTest",
};

export default TranslationsConstants;
