import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const list = (state: MainState) => state.candidate.company.list;

const getData = createSelector(list, (list) => list.data);

const getPagination = createSelector(list, (list) => list.pagination);

const isLoading = createSelector(list, (list) => list.loading);

const getFilters = createSelector(list, (list) => list.filters);

const CompanyListSelectors = {
    getData,
    isLoading,
    getPagination,
    getFilters
};

export default CompanyListSelectors;
