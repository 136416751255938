import React from 'react';
import { Confirm, BaseDialog } from './';

export function ConfirmDialog({
    isOpen,
    onCancel,
    onConfirm,
    label,
    title,
    confirmText,
    cancelText
}) {
    return (
        <BaseDialog isVisible={isOpen} onClose={onCancel}>
            <Confirm
                onCancel={onCancel}
                onConfirm={onConfirm}
                confirmText={confirmText}
                cancelText={cancelText}
                label={label}
                title={title}
            />
        </BaseDialog>
    );
}
