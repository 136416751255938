import { RequestService, UserService } from '../../common/_services';

export const ProfileService = {
    getProfile,
    jobRoles,
    newSkill,
    deleteSkill,
    uploadProfilePhoto,
    updateEmail,
    verifyEmail,
    updatePhone,
    updateAddress,
};

function getProfile(profileId) {
    return RequestService.get(`api/profiles/${profileId}`);
}

function jobRoles(search) {
    const query = search != null ? 'autocomplete?search=' + search : '';
    return RequestService.get(`jobs/roles/${query}`).then((list) => list);
}

function newSkill(item) {
    const profileId = UserService.getProfileId();

    return RequestService.post(`profiles/${profileId}/skill`, item);
}

function deleteSkill(id) {
    const profileId = UserService.getProfileId();

    return RequestService.delete(`profiles/${profileId}/skill/${id}`);
}

function updateEmail(email) {
    const profileId = UserService.getProfileId();

    return RequestService.post(`profiles/${profileId}/emails`, { email });
}

function verifyEmail(payload) {
    const profileId = UserService.getProfileId();
    const { emailId, token } = payload;

    return RequestService.get(
        `profiles/${profileId}/emails/${emailId}/verify/${token}`
    );
}

function updatePhone(payload) {
    const profileId = UserService.getProfileId();

    const { countryId, number } = payload;
    return RequestService.post(`profiles/${profileId}/phone-numbers`, {
        countryId,
        number,
    });
}

function updateAddress(payload) {
    const profileId = UserService.getProfileId();

    return RequestService.post(`profiles/${profileId}/addresses`, {
        ...payload,
    });
}

/**
 * @param {{image:string; originalImage: string; fileName: string}} payload
 */
function uploadProfilePhoto(payload) {
    const { file, originalFile, fileName, profileId } = payload;

    return RequestService.post(`profiles/${profileId}/images`, {
        file,
        originalFile,
        fileName,
    });
}
