import React from 'react';
import { createClass } from 'classname-helper';

import { useSwitch } from '../../hooks/useSwitch';
import { TooltipLabel } from '../TooltipLabel';

export function BaseNavButton(props) {
    const { children, icon, Icon, Menu, title } = props;
    const [isOpen, , close, toggle] = useSwitch();

    return (
        <TooltipLabel label={title}>
            <div className="header-nav__button">
                <div className="header-nav__button__icon" onClick={toggle}>
                    {Icon ? (
                        Icon()
                    ) : (
                        <i
                            className={createClass({
                                [icon]: true,
                                active: isOpen,
                            })}
                        />
                    )}
                    {children}
                </div>
                <Menu isOpen={isOpen} onClose={close} />
            </div>
        </TooltipLabel>
    );
}
