import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-translate.ts';

import { Button, ActionDialog, AutoCheck } from '../../../../common/components';
import { ADMIN_CANDIDATES_PATH } from '../../../../common/_constants/route.constants';

export function InviteDialog({
    isVisible,
    onClose,
    onConfirm,
    jobPostId,
    ...rest
}) {
    const translate = useTranslate('admin.candidate.inviteDialog');

    return (
        <ActionDialog
            heading={translate('heading')}
            subHeading={translate('subHeading')}
            infoList={[
                translate('info1'),
                translate('info2'),
                translate('info3'),
            ]}
            buttonLabel={translate('buttonLabel')}
            autoCheckLabel={translate('autoCheck')}
            isVisible={isVisible}
            onClose={onClose}
            onConfirm={() => onConfirm(jobPostId)}
            icons={['/img/dialog/invite.svg', '/img/dialog/sent.svg']}
            {...rest}
        >
            <Info
                onClose={onClose}
                jobPostId={jobPostId}
                translate={translate}
            />
        </ActionDialog>
    );
}

function Info({ onClose, jobPostId, translate }) {
    return (
        <Fragment>
            <strong className="font-34 text-center">{translate('done')}</strong>
            <div className="text-center">{translate('doneInfo')}</div>
            <AutoCheck text={translate('autoCheck2')} />
            <div>
                <Button isWide onClick={onClose}>
                    {translate('button1')}
                </Button>
            </div>
            <Link
                to={{
                    pathname: ADMIN_CANDIDATES_PATH,
                    state: { jobPostId },
                }}
            >
                <Button isWide variant="secondary">
                    {translate('button2')}
                </Button>
            </Link>
        </Fragment>
    );
}
