import { takeLatest } from 'redux-saga/effects';

import { SagaHelpers } from '../_helpers';
import {
    GET_PROFILE_IMAGE,
    GET_PROFILE_IMAGE_SUCCESS,
    GET_PROFILE_IMAGE_FAIL,
} from '../_constants/actions/profile-actions.constants';
import { ProfileService } from '../_services/ProfileService';
import {
    GET_PROFILE_COMPLETENESS,
    GET_PROFILE_TYPE,
    ProfileActions,
    SAVE_PROFILE_TYPE,
} from '../_actions/ProfileActions';

function* getProfileImage(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.getProfileImage, action.payload],
        actions: [
            (payload) => ({ type: GET_PROFILE_IMAGE_SUCCESS, payload }),
            (error) => ({ type: GET_PROFILE_IMAGE_FAIL, error }),
        ],
    });
}

function* getProfileCompleteness(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.getProfileCompleteness, action.payload],
        actions: [
            ProfileActions.getProfileCompletenessSuccess,
            ProfileActions.getProfileCompletenessFail,
        ],
    });
}

function* getProfileType(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.getProfileType, action.payload],
        actions: [
            ProfileActions.getProfileTypeSuccess,
            ProfileActions.getProfileTypeFail,
        ],
    });
}

function* saveProfileType(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.saveProfileType, action.payload],
        actions: [
            () => ProfileActions.saveProfileTypeSuccess(action.payload),
            ProfileActions.saveProfileTypeFail,
        ],
    });
}

export default [
    takeLatest(GET_PROFILE_IMAGE, getProfileImage),
    takeLatest(GET_PROFILE_COMPLETENESS, getProfileCompleteness),
    takeLatest(GET_PROFILE_TYPE, getProfileType),
    takeLatest(SAVE_PROFILE_TYPE, saveProfileType),
];
