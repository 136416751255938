import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import moment from "moment";

import {
    Button,
    SVGIcon,
    InlineLinks,
    LoadingOverlay,
} from "../../../common/components";
import {
    Panel,
    PanelHeadingProps,
    PanelProps,
} from "../../../common/components/Panel/Panel";
import styles from "./AdminEdit.module.scss";
import { addEventHandler } from "../../../common/_helpers";
import { ButtonProps } from "../../../common/components/buttons/Button/Button";

export type AdminEditProps = {
    heading: string | JSX.Element;
    links?: { to: string; label: string; active?: boolean }[];
    onSave?: () => void;
    previewLink?: string | { to: string; label: string };
    buttonProps?: ButtonProps;
    lastSavedTimestamp?: string;
    useHashLinks?: boolean;
    previewPath?: string;
};

export default function AdminEdit(props: PropsWithChildren<AdminEditProps>) {
    const {
        heading,
        children,
        links,
        onSave,
        buttonProps = {},
        lastSavedTimestamp,
        previewLink,
        useHashLinks = false,
        previewPath,
    } = props;
    const match = useRouteMatch();
    const linksRef = useRef<HTMLDivElement | null>(null);
    const [linkVisible, setLinkVisible] = useState(true);

    useEffect(() => {
        handleScroll();
    }, [linksRef.current]);

    useEffect(() => {
        addEventHandler(window, "scroll", handleScroll);

        return () => addEventHandler(window, "scroll", handleScroll);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <div>
                    <h1>{heading}</h1>
                </div>
                <div className={styles.publish}>
                    {/* <div className={styles.publish__time}>
                        {lastSavedTimestamp ? (
                            <>
                                <SVGIcon name="check" />
                                Saved {moment(lastSavedTimestamp).fromNow()}
                            </>
                        ) : null}
                    </div> */}
                    {buttonProps ? (
                        <div className={styles.publish__button}>
                            <Button {...buttonProps} onClick={handleSave} />
                        </div>
                    ) : null}
                </div>
            </div>
            {links ? (
                <div className={styles.links} ref={linksRef}>
                    <InlineLinks>
                        {links?.map((link, i) =>
                            useHashLinks ? (
                                <NavHashLink
                                    key={i}
                                    to={link.to}
                                    isActive={() => link.active as boolean}
                                >
                                    {link.label}
                                </NavHashLink>
                            ) : (
                                <NavLink key={i} to={link.to}>
                                    {link.label}
                                </NavLink>
                            )
                        )}
                    </InlineLinks>
                    {previewLink ? (
                        <div
                            style={{
                                visibility: linkVisible ? "visible" : "hidden",
                            }}
                            className={styles.preview}
                        >
                            <Link to={previewPath || match.path + "/preview"}>
                                {previewLink}
                            </Link>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className={styles.content}>{children}</div>
        </div>
    );

    function handleScroll() {
        if (!linksRef.current) {
            return;
        }

        const { y } = linksRef.current.getBoundingClientRect();
        setLinkVisible(y > 40);
    }

    function handleSave() {
        if (buttonProps.disabled) {
            return;
        }

        onSave && onSave();
    }
}

function EditPanel(
    props: PropsWithChildren<
        { heading: string; subtitle: string; isLoading?: boolean } & PanelProps
    >
) {
    const { heading, subtitle, children, isLoading = false, ...rest } = props;

    return (
        <Panel className={styles.panel} {...rest}>
            <LoadingOverlay isLoading={isLoading} withIcon={false} />
            <div>
                <PanelHeading style={{ marginBottom: 13 }}>
                    {heading}
                </PanelHeading>
                <Panel.Subtitle>{subtitle}</Panel.Subtitle>
            </div>
            <div className={styles.panel__content}>{children}</div>
        </Panel>
    );
}

function PanelHeading(props: PanelHeadingProps) {
    return <Panel.Heading {...props} style={{ marginBottom: 13 }} />;
}

AdminEdit.Panel = EditPanel;
AdminEdit.PanelHeading = PanelHeading;
