import React from 'react';

import { useTranslate } from 'react-translate.ts';

export function InterpretationInfo(props) {
    const translate = useTranslate('candidate.softSkillsTest.info');

    const { time, /*tested,*/ count } = props;
    return (
        <div className="interpretation--info">
            <div className="interpretation--info--item">
                {translate('duration')}: {time} ({translate('count', { count })}
                )
            </div>
            {/* <div className="interpretation--info--item">
                {translate('tested')}: {tested}
            </div> */}
        </div>
    );
}
