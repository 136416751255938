import React, { Fragment } from 'react';
import { useTranslate } from 'react-translate.ts';

import { Flex, MatchChart } from '../../../../../common/components';
import { resolveMatch } from '../../../../../common/_helpers';

export function TopJobMatch({ match, ...rest }) {
    const translate = useTranslate();
    const message = resolveMatch(match).message;
    return (
        <Flex {...rest} alignItems="center">
            <MatchChart match={match} className="margin-right-10" />
            <Flex direction="column">
                <Item
                    label={translate(
                        'common.company.profile.topJobMatch.label'
                    )}
                    value={translate(message)}
                />
                <Item
                    label={translate(
                        'common.company.profile.topJobMatch.percent'
                    )}
                    value={
                        <Fragment>
                            {Math.round(match * 100)}
                            <span className="font-10">%</span>
                        </Fragment>
                    }
                />
            </Flex>
        </Flex>
    );
}

function Item({ className, label, value }) {
    return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            className={className}
        >
            <span className="font-10 text-secondary padding-right-30">
                {label}
            </span>
            <strong className="font-14">{value}</strong>
        </Flex>
    );
}
