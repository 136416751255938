import { combineReducers } from 'redux';

import cards from './cards.reducer';
import archived from './archived.reducer';
import declined from './declined.reducer';
import loading from './loading.reducer';

export default combineReducers({
    cards,
    archived,
    declined,
    loading,
});
