import React from 'react';
import { addClass, createClass } from 'classname-helper';

import {
    PROGRESS_STRONG_COLOR,
    PROGRESS_GOOD_COLOR,
    PROGRESS_FAIR_COLOR,
    PROGRESS_BAD_COLOR,
} from '../../_constants/theme.constants';

export function BaseProgressBar(props) {
    const {
        score,
        value,
        maxValue = 1,
        className,
        coloredProgress,
        coloredInverted,
        children,
        useMarkers = true,
        isRounded,
        color,
        ...rest
    } = props;

    const background = coloredProgress
        ? getProgressColor(score ? score : value, maxValue, coloredInverted)
        : color;
    const width = getBarPercentage(value, maxValue) + '%';

    return (
        <div
            className={createClass({
                progress: true,
                [className]: !!className,
                'is-rounded': isRounded,
            })}
            {...rest}
        >
            {children}
            <div
                className={addClass('progressbar', className)}
                style={{ width, background }}
            ></div>
            {useMarkers ? (
                <div className="progress-marker-wrapper">
                    <Marker />
                    <Marker />
                </div>
            ) : null}
        </div>
    );
}

function Marker({ className, ...rest }) {
    return <div className={addClass('progress-marker', className)} {...rest} />;
}

function getProgressColor(value, maxValue, coloredInverted) {
    let percentage = getBarPercentage(value, maxValue);

    if (coloredInverted) {
        percentage = 100 - percentage;
    }

    if (percentage > 75) {
        return PROGRESS_STRONG_COLOR;
    }

    if (percentage > 50) {
        return PROGRESS_GOOD_COLOR;
    }

    if (percentage > 25) {
        return PROGRESS_FAIR_COLOR;
    }

    return PROGRESS_BAD_COLOR;
}

const getBarPercentage = (value, maxValue) =>
    Math.round((value / maxValue) * 100);
