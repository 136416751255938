import produce  from "immer";
import INotification from "../types/INotification";

const NotificationHelpers = {
    addNotification,
    removeNotification
}

function hasSameNotification(notification: INotification, notifications: INotification[]) {
    const index = notifications.findIndex((item) => item.message === notification.message && item.type === notification.type);

    return index >= 0;
}

function addNotification(notification: INotification, notifications: INotification[]) {
    if (!hasSameNotification(notification, notifications)) {
        return produce(notifications, (draft) => {
            draft.push(notification);
        })
    }

    return notifications;
}

function removeNotification(timestamp: number, notifications: INotification[]) {
    const index = notifications.findIndex((item) => item.timestamp === timestamp);
    
    if (index >= 0) {
        return produce(notifications, (draft) => {
            draft.splice(index, 1);
        })
    }

    return notifications;
}

export default NotificationHelpers;
