import { RequestService } from '../../common/_services';
import { TRACKING_DECLINED } from '../../admin/_constants/actions/tracking.constants';

export const ApplicationsTrackingService = {
    getApplications,
    getArchivedApplications,
    getRejectedApplications,
    acceptJob,
    rejectJob,
};

const url = 'job-applications-tracking';

function getApplications({ profileId }) {
    return RequestService.get(`profiles/${profileId}/${url}`);
}

function getArchivedApplications({ profileId }) {
    return RequestService.get(`profiles/${profileId}/${url}?archived=true`);
}

function getRejectedApplications({ profileId }) {
    return RequestService.get(
        `profiles/${profileId}/${url}/${TRACKING_DECLINED}`
    );
}

function acceptJob({ id, jobPostId, profileId, newStatus }) {
    return RequestService.put(`profiles/${profileId}/job-applications`, {
        id,
        jobPostId,
        profileId,
        status: 'ACCEPTED',
    });
}

function rejectJob({ id, jobPostId, profileId }) {
    return RequestService.put(`profiles/${profileId}/job-applications`, {
        id,
        jobPostId,
        profileId,
        status: 'DECLINED',
    });
}
