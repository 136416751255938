import { useTranslate } from "react-translate.ts";

import { REQUIRED_FORM_FIELD } from "../../_constants/validation.constants";

export function FieldError({ name, error }) {
    const translate = useTranslate("common.misc.validation");

    if (!error || !name || error === REQUIRED_FORM_FIELD) {
        return null;
    }

    return (
        <div className="field-error">{translate(error, { field: name })}</div>
    );
}
