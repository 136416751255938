import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseNavButton } from './BaseNavButton';

const INTERVAL = 30000;

export function BaseNotificationButton(props) {
    const { count, getNotificationsCountAction, Menu, icon, ...rest } = props;
    const intervalRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        getNotifications();

        intervalRef.current = setInterval(getNotifications, INTERVAL);

        return () => clearInterval(intervalRef.current);
    }, []);

    return (
        <BaseNavButton Menu={Menu} icon={icon} {...rest}>
            {count > 0 ? (
                <div
                    className="notification-bubble"
                    style={{ top: -7, right: -7 }}
                >
                    {count}
                </div>
            ) : null}
        </BaseNavButton>
    );

    function getNotifications() {
        dispatch(getNotificationsCountAction());
    }
}
