export const jobRoleConstants = {
    JOB_ROLES_BEGIN: 'JOB_ROLES_BEGIN',
    JOB_ROLES_SUCCESS: 'JOB_ROLES_SUCCESS',
    JOB_ROLES_ERROR: 'JOB_ROLES_ERROR',

    JOB_ROLE_CARDS: 'JOB_ROLE_CARDS',
    JOB_ROLE_CARDS_SUCCESS: 'JOB_ROLE_CARDS_SUCCESS',
    JOB_ROLE_CARDS_FAIL: 'JOB_ROLE_CARDS_FAIL',

    JOB_ROLE_BEGIN: 'JOB_ROLE_BEGIN',
    JOB_ROLE_SUCCESS: 'JOB_ROLE_SUCCESS',
    JOB_ROLE_ERROR: 'JOB_ROLE_ERROR',

    JOB_ROLE_INDUSTRIES: 'JOB_ROLE_INDUSTRIES',
    JOB_ROLE_INDUSTRIES_SUCCESS: 'JOB_ROLE_INDUSTRIES_SUCCESS',
    JOB_ROLE_INDUSTRIES_FAIL: 'JOB_ROLE_INDUSTRIES_FAIL',

    JOB_ROLE_INDUSTRY_CATEGORIES: 'JOB_ROLE_INDUSTRY_CATEGORIES',
    JOB_ROLE_INDUSTRY_CATEGORIES_SUCCESS:
        'JOB_ROLE_INDUSTRY_CATEGORIES_SUCCESS',
    JOB_ROLE_INDUSTRY_CATEGORIES_FAIL: 'JOB_ROLE_INDUSTRY_CATEGORIES_FAIL',
};

export const GET_JOB_ROLES = 'GET_JOB_ROLES';
export const GET_JOB_ROLES_SUCCESS = 'GET_JOB_ROLES_SUCCESS';
export const GET_JOB_ROLES_FAIL = 'GET_JOB_ROLES_FAIL';

export const GET_JOB_ROLE = 'GET_JOB_ROLE';
export const GET_JOB_ROLE_SUCCESS = 'GET_JOB_ROLE_SUCCESS';
export const GET_JOB_ROLE_FAIL = 'GET_JOB_ROLE_FAIL';

export const GET_JOB_INDUSTRIES = 'GET_JOB_INDUSTRIES';
export const GET_JOB_INDUSTRIES_SUCCESS = 'GET_JOB_INDUSTRIES_SUCCESS';
export const GET_JOB_INDUSTRIES_FAIL = 'GET_JOB_INDUSTRIES_FAIL';

export const JOB_ROLES_LIST_CHANGE_ROLES = 'JOB_ROLES_LIST_CHANGE_ROLES';
export const JOB_ROLES_LIST_CHANGE_INDUSTRY = 'JOB_ROLES_LIST_CHANGE_INDUSTRY';

export const JOB_ROLES_CHANGE_PAGE = 'JOB_ROLES_CHANGE_PAGE';
