import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import {
    PopupOverlay,
    Flex,
    LoadingOverlay,
    Pagination,
    ConfirmDialog,
    BasePageLayout,
} from "../../../common/components";
import { CandidateItem, SortPanel } from "./components";

import { CandidateActions } from "../../_actions/CandidateActions";
import { ArrayHelper } from "../../../common/_helpers/ArrayHelper";
import { ADMIN_HIRE_PATH } from "../../../common/_constants/route.constants";
import { CandidateSearchActions, JobsActions } from "../../_actions";
import { InviteDialog } from "./components/InviteDialog";
import { useSwitch } from "../../../common/hooks/useSwitch";
import { CandidateOverlay } from "../../components/candidate-profile/CandidateOverlay";
import { CandidateSearchSelectors } from "../../_selectors/CandidateSearchSelectors";
import { useDisableScroll } from "../../../common/hooks";
import { ADMIN_HIRE_SAVED_PATH } from "../../_constants/route.constants";
import { CandidateTrackingActions } from "../../_actions/CandidateTrackingActions";
import { Search } from "./components/Search";
import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";

export function AdminHire() {
    const [currentProfileId, setCurrentProfileId] = useState(0);
    const {
        cardsPagination,
        savedPagination,
        jobs,
        companyId,
        activeCandidate,
        filters,
        isInviteLoading,
    } = useSelector((state) => mapState(state, currentProfileId));

    const {
        getCards,
        getSaved,
        changePage,
        getJobs,
        inviteCandidate,
        setFavourite,
        unsetFavourite,
        rejectCandidate,
        changeFilter,
    } = mapActions(useDispatch());
    const jobPost = filters.jobPost;

    const [
        candidateOverlayIsOpen,
        openCandidateOverlay,
        closeCandidateOverlay,
    ] = useSwitch(false);
    const [inviteDialogIsOpen, openInviteDialog, closeInviteDialog] =
        useSwitch(false);
    const [rejectedCandidate, setRejectedCandidate] = useState(null);

    const translate = useTranslate("admin.hire");
    const { pathname } = useLocation();

    useEffect(() => void getJobs(companyId), [companyId]);
    useEffect(() => {
        if (!ArrayHelper.isEmpty(jobs)) {
            changeFilter("jobPost", jobs[0]);
        }
    }, [jobs]);

    useEffect(() => {
        if (jobPost && jobPost.id) {
            getSaved(jobPost.id, cardsPagination.page, filters);
            getCards(jobPost.id, savedPagination.page, filters);
        }
    }, [jobPost]);

    const ref = useDisableScroll(candidateOverlayIsOpen);

    const isConfirmVisible = !!rejectedCandidate;
    return (
        <CompanyPageLayout>
            <BasePageLayout
                heading={translate("heading")}
                id="admin-hire"
                forwardRef={ref}
                headerContent={
                    <Search jobs={jobs} jobPost={jobPost} onApply={search} />
                }
                links={[
                    {
                        path: ADMIN_HIRE_PATH,
                        exact: true,
                        label: translate("links.all"),
                        count: cardsPagination.elements,
                    },
                    {
                        path: ADMIN_HIRE_SAVED_PATH,
                        label: translate("links.saved"),
                        iconName: "save",
                        count: savedPagination.elements,
                    },
                ]}
                extraLinksContent={
                    <SortPanel
                        filters={filters.calculators}
                        onApply={() => {
                            search();
                            changePage(0);
                        }}
                    />
                }
            >
                <Flex direction="column">
                    {jobPost && activeCandidate ? (
                        <InviteDialog
                            isVisible={inviteDialogIsOpen}
                            onClose={() => {
                                closeInviteDialog();
                                closeCandidateOverlay();
                                setCurrentProfileId(0);
                            }}
                            onConfirm={(jobPostId) =>
                                inviteCandidate(
                                    activeCandidate.profile.id,
                                    jobPostId
                                )
                            }
                            jobPostId={jobPost.id}
                            isConfirmed={
                                activeCandidate.jobApplicationStatus !==
                                "NOT_APPLIED"
                            }
                            isLoading={isInviteLoading}
                        />
                    ) : null}
                    <PopupOverlay
                        isOpen={candidateOverlayIsOpen}
                        onClose={closeCandidateOverlay}
                    >
                        {activeCandidate ? (
                            <CandidateOverlay
                                candidate={activeCandidate}
                                profileId={activeCandidate.profile.id}
                                jobPostId={jobPost && jobPost.id}
                                onInvite={openInviteDialog}
                                onFavourite={handleFavourite}
                                onReject={(candidate) =>
                                    setRejectedCandidate(candidate)
                                }
                            />
                        ) : null}
                    </PopupOverlay>
                    {jobPost ? (
                        <ConfirmDialog
                            isOpen={isConfirmVisible}
                            onCancel={() => setRejectedCandidate(null)}
                            onConfirm={() => {
                                rejectCandidate(rejectedCandidate, jobPost.id);
                                setRejectedCandidate(null);
                            }}
                            label={"admin.candidate.tracking.reject.label"}
                            confirmText={
                                "admin.candidate.tracking.reject.button"
                            }
                            title={"admin.candidate.tracking.reject.title"}
                        />
                    ) : null}
                    <Content
                        jobPost={jobPost}
                        setCurrentProfileId={setCurrentProfileId}
                        onInvite={openInviteDialog}
                        onFavourite={handleFavourite}
                        onReject={(candidate) =>
                            setRejectedCandidate(candidate)
                        }
                        openCandidateOverlay={openCandidateOverlay}
                    />
                </Flex>
            </BasePageLayout>
        </CompanyPageLayout>
    );

    function handleFavourite(candidate) {
        if (!candidate.isFavourite) {
            setFavourite(candidate, candidate.profile.id, jobPost.id);
        } else {
            unsetFavourite(candidate, candidate.profile.id, jobPost.id);
        }
    }

    function search() {
        if (!jobPost || !jobPost.id) {
            return;
        }

        // applyFilters(
        //     jobPost.id,
        //     cardsPagination.page,
        //     filters,
        //     pathname === ADMIN_HIRE_SAVED_PATH
        // );

        if (pathname === ADMIN_HIRE_SAVED_PATH) {
            getSaved();
        } else {
            getCards();
        }
    }
}

function Content({
    jobPost,
    setCurrentProfileId,
    openCandidateOverlay,
    onInvite,
    onFavourite,
    onReject,
}) {
    const {
        isLoading,
        cards,
        saved,
        filters,
        cardsPagination,
        savedPagination,
    } = useSelector(mapState);
    const { getCards, getSaved, changePage } = mapActions(useDispatch());

    return (
        <div id="admin-hire--grid">
            <LoadingOverlay isLoading={isLoading} />
            <Switch>
                <Route exact path={ADMIN_HIRE_PATH}>
                    <Cards
                        {...{
                            jobPost,
                            setCurrentProfileId,
                            openCandidateOverlay,
                            onInvite,
                            onFavourite,
                            onReject,
                            page: cardsPagination.page,
                            cards,
                            filters,
                            getCards,
                        }}
                    />
                    <Pagination
                        pages={cardsPagination.pages}
                        page={cardsPagination.page}
                        onPageChange={changePage}
                    />
                </Route>
                <Route path={ADMIN_HIRE_SAVED_PATH}>
                    <Cards
                        {...{
                            jobPost,
                            setCurrentProfileId,
                            openCandidateOverlay,
                            onInvite,
                            onFavourite,
                            onReject,
                            page: savedPagination.page,
                            cards: saved,
                            filters,
                            getCards: getSaved,
                        }}
                    />
                    <Pagination
                        pages={savedPagination.pages}
                        page={savedPagination.page}
                        onPageChange={changePage}
                    />
                </Route>
            </Switch>
        </div>
    );
}

function Cards({
    jobPost,
    setCurrentProfileId,
    onInvite,
    onReject,
    onFavourite,
    openCandidateOverlay,
    filters,
    cards,
    page,
    getCards,
}) {
    const { pathname } = useLocation();

    useEffect(() => {
        if (jobPost) {
            getCards(jobPost.id, page, filters);
            window.scrollTo(0, 0);
        }
    }, [jobPost, page, pathname]);

    return cards.map((candidate, index) => (
        <CandidateItem
            key={index}
            candidate={candidate}
            onActionClick={setCurrentProfileId}
            onDetailClick={handleDetailClick}
            onInvite={onInvite}
            onReject={onReject}
            onFavourite={onFavourite}
        />
    ));

    function handleDetailClick(profileId) {
        openCandidateOverlay();
        setCurrentProfileId(profileId);
    }
}

function mapState(state, profileId) {
    const { admin, common } = state;
    const { pagination, loading, filters } = admin.candidates.search;

    return {
        cards: CandidateSearchSelectors.getCards(state),
        pagination,
        isLoading: loading,
        jobs: admin.jobs.list,
        companyId: common.auth.user.companyId,
        activeCandidate: CandidateSearchSelectors.getCard(state, profileId),
        filters: CandidateSearchSelectors.getFilters(state),
        saved: CandidateSearchSelectors.getSaved(state),
        isInviteLoading: CandidateSearchSelectors.isInviteLoading(state),
        cardsPagination: CandidateSearchSelectors.getCardsPagination(state),
        savedPagination: CandidateSearchSelectors.getSavedPagination(state),
    };
}

function mapActions(dispatch) {
    return {
        getCards: () => void dispatch(CandidateActions.getCards()),
        getSaved: () => void dispatch(CandidateActions.getFavourites()),
        changePage: (page) => void dispatch(CandidateActions.changePage(page)),
        getJobs: (companyId) =>
            void dispatch(JobsActions.getCompanyJobs(companyId)),
        inviteCandidate: (profileId, jobPostId) =>
            void dispatch(
                CandidateActions.inviteCandidate(profileId, jobPostId)
            ),
        setFavourite: (candidate, profileId, jobPostId) =>
            void dispatch(
                CandidateActions.setFavouriteCandidate(
                    candidate,
                    profileId,
                    jobPostId
                )
            ),
        unsetFavourite: (candidate, profileId, jobPostId) =>
            void dispatch(
                CandidateActions.unsetFavouriteCandidate(
                    candidate,
                    profileId,
                    jobPostId
                )
            ),
        rejectCandidate: (candidate, jobPostId) => {
            dispatch(
                CandidateTrackingActions.rejectCandidate({
                    candidateId: candidate.id,
                    status: candidate.jobApplicationStatus,
                    profileId: candidate.profile.id,
                    jobPostId,
                })
            );
        },
        changeFilter: (key, value) =>
            void dispatch(CandidateSearchActions.setFilter(key, value)),
    };
}
