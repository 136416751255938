import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { IntegratedDialog } from "../../../../../common/components";
import { PROFILE_PATH_INFO } from "../../../../_constants/route.constants";
import { FormButtons } from "../FormButtons";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import { ProfileInformationActions } from "../../../../_actions/ProfileInformationActions";
import ProfileInformationSelectors from "../../../../_selectors/ProfileInformationSelectors";
import { Translate } from "react-translate.ts";

export function AboutMeEdit() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { about, profileId } = useSelector(mapState);
    const [value, setValue] = useState(about ? about.content : "");

    return render();

    function render() {
        return (
            <IntegratedDialog onClose={close}>
                <div id="about-me-edit" className="profile-edit">
                    <h3 className="text-center">
                        <Translate textOnly>
                            candidate.profile.information.aboutMe.edit.heading
                        </Translate>
                    </h3>
                    <div className="text-center text-secondary margin-y-10 padding-bottom-10">
                        <Translate textOnly>
                            candidate.profile.information.aboutMe.edit.subheading
                        </Translate>
                    </div>
                    <textarea
                        className="input noresize w100"
                        value={value}
                        onChange={onChange}
                    />
                    <FormButtons
                        onCancel={close}
                        onConfirm={save}
                        confirmText="common.actions.save"
                    />
                </div>
            </IntegratedDialog>
        );

        function onChange({ target }) {
            setValue(target.value);
        }
    }

    function save() {
        dispatch(
            ProfileInformationActions.updateAboutMe(
                about ? about.id : null,
                value,
                profileId
            )
        );
        close();
    }

    function close() {
        history.push(PROFILE_PATH_INFO);
    }

    function mapState(state) {
        return {
            profileId: AuthSelectors.getProfileId(state),
            about: ProfileInformationSelectors.getAboutMe(state),
        };
    }
}
