import { HeroProfile } from "../HeroProfile/HeroProfile";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export default function HeroProfilePage() {
    return (
        <CandidatePageLayout>
            <HeroProfile />
        </CandidatePageLayout>
    );
}
