import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useParams,
    generatePath,
    useRouteMatch,
    Redirect,
} from "react-router-dom";
import { useTranslate } from "react-translate.ts";
import { ScrollToTop } from "../../../common/components";

import BaseListLayout from "../../../common/components/layout/BaseListLayout/BaseListLayout";
import Pagination from "../../../common/components/misc/Pagination/Pagination";
import usePreviousValue from "../../../common/hooks/usePreviousValue";
import { AuthSelectors } from "../../../common/_selectors";
import { MainState } from "../../../root.reducer";
import CompanyList from "../../components/company/CompaniesList/CompanyList";
import CompanyActions from "../../_actions/CompanyActions";
import CompanyListSelectors from "../../_selectors/CompanyListSelectors2";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export default function CompanyListPage() {
    const { filters } = useSelector(mapState);
    const dispatch = useDispatch();
    const translate = useTranslate("candidate.company.cards");
    const prevTitle = usePreviousValue(filters.title);
    const match = useRouteMatch();

    if (prevTitle !== filters.title) {
        return <Redirect to={generatePath(match.path, { page: 1 })} />;
    }

    return (
        <CandidatePageLayout>
            <BaseListLayout
                title={translate("heading")}
                searchProps={{
                    placeholder: translate("search"),
                    onSearch: search,
                }}
            >
                <Content />
            </BaseListLayout>
        </CandidatePageLayout>
    );

    function search(title: string) {
        dispatch(CompanyActions.updateFilters({ title }));
    }
}

function Content() {
    const { isSigned, pagination, companies, filters } = useSelector(mapState);
    const dispatch = useDispatch();
    const { page } = useParams<{ page?: string }>();

    useEffect(() => {
        if (!isSigned) {
            return;
        }

        dispatch(
            CompanyActions.getCompanies({
                filters,
                page: parseInt(page) - 1 || 0,
            })
        );
    }, [page, filters, isSigned]);

    return (
        <>
            <ScrollToTop />
            <CompanyList companies={companies} />
            <Pagination pages={pagination.pages} />
        </>
    );
}

function mapState(state: MainState) {
    return {
        companies: CompanyListSelectors.getData(state),
        pagination: CompanyListSelectors.getPagination(state),
        filters: CompanyListSelectors.getFilters(state),
        isSigned: AuthSelectors.isSigned(state),
    };
}
