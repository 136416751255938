import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslate } from 'react-translate.ts';

import {
    PROFILE_PATH_INFO,
    PROFILE_HARD_SKILLS_PATH,
    PROFILE_CONTACT_PATH,
} from '../../../_constants/route.constants';
import { InlineLinks } from '../../../../common/components';

export function ProfileTabs({ id }) {
    const translate = useTranslate('common.profile.tabs');

    return (
        <InlineLinks id={id}>
            <NavLink to={PROFILE_PATH_INFO}>{translate('information')}</NavLink>
            <NavLink to={PROFILE_HARD_SKILLS_PATH}>
                {translate('hardSkills')}
            </NavLink>
            {/* <NavLink to={PROFILE_CONTACT_PATH}>{translate('contact')}</NavLink> */}
            {/*<NavLink*/}
            {/*    id="profile-preferences-tab"*/}
            {/*    to={PROFILE_PREFERENCES_PATH}*/}
            {/*    activeClassName="is-selected"*/}
            {/*>*/}
            {/* {translate('preferences')} */}
            {/*</NavLink>*/}
        </InlineLinks>
    );
}
