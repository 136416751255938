import * as Lockr from "lockr";

/**
 * Service class for local and session storage manipulation
 */
export class StorageService {
    /**
     * Returns item from local storage
     * @param {string} key
     * @param {any?} defaultValue
     */
    static getLocal(key, defaultValue) {
        return Lockr.get(key, defaultValue);
    }

    /**
     * Sets item to local storage
     * @param {string} key
     * @param {string} item
     */
    static setLocal(key, item) {
        Lockr.set(key, item);
    }

    /**
     * Removes item from local storage
     * @param {string} key
     */
    static removeLocal(key) {
        Lockr.rm(key);
    }

    /**
     * Remove all local data
     */
    static clearLocal() {
        Lockr.flush();
    }

    /**
     * Returns item from session storage
     * @param {string} key
     */
    static getSession(key) {
        return sessionStorage.getItem(key);
    }

    /**
     * Sets item to session storage
     * @param {string} key
     * @param {string} item
     */
    static setSession(key, item) {
        sessionStorage.setItem(key, item);
    }

    /**
     * Checks if key is in local storage
     * @param {string} key
     */
    static hasLocal(key) {
        const item = this.getLocal(key);
        return item !== null && item !== undefined;
    }
}
