import React from "react";
import { ProgressChart } from "./ProgressChart";

export function HardSkillsMatchChart({
    skills,
    additionalSkills,
    skillsClass,
}) {
    return (
        <div className={`skills padding-y-10 ${skillsClass || ""}`}>
            {renderSkills()}
        </div>
    );
    function renderSkills() {
        const hardSkills = skills.map((item) => ({
            name: item.idealSkill.skills.map((skill) => skill.name).join(" / "),
            score: item.skillMatchScore,
            level: item.actualSkill && item.actualSkill.level,
            levelRange: {
                min: item.idealSkill.levelMin,
                max: item.idealSkill.levelMax,
            },
            yearsOfExperience:
                item.actualSkill && item.actualSkill.timeYearCount,
            yearsOfExperienceRange: {
                min: item.idealSkill && item.idealSkill.timeYearCountMin,
                max: item.idealSkill && item.idealSkill.timeYearCountMax,
            },
            isRequired:
                item.idealSkill && item.idealSkill.weightType === "REQUIRED",
        }));

        return (
            <ProgressChart
                items={hardSkills}
                additionalItems={
                    additionalSkills &&
                    additionalSkills
                        .filter((item) => item.skill.type === "hard")
                        .map((item) => ({
                            name: item.skill.name,
                            ideal: 0,
                            level: item.level,
                            yearsOfExperience: item.timeYearCount,
                            score: 0,
                        }))
                }
            />
        );
    }
}
