import { useTranslate } from "react-translate.ts";
import { Panel, PanelProps } from "../../../../common/components/Panel/Panel";
import BenefitsEdit, {
    BenefitsEditProps,
} from "../../BenefitsEdit/BenefitsEdit";
import AdminEdit from "../AdminEdit";

export default function BenefitsEditPanel(
    props: BenefitsEditProps & PanelProps
) {
    const { benefits, onSubmit, onDelete, pending, ...rest } = props;
    const translate = useTranslate("admin.jobs.edit.benefits");

    return (
        <Panel {...rest} data-test="benefits-edit-panel">
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <BenefitsEdit
                benefits={benefits}
                onSubmit={onSubmit}
                onDelete={onDelete}
                pending={pending}
            />
        </Panel>
    );
}
