import { InputHTMLAttributes, MutableRefObject, useEffect } from "react";

export type FileInputRef = {
    element?: HTMLInputElement | null;
    openDialog?: () => void;
};

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
    forwardRef?: MutableRefObject<FileInputRef>;
}

export default function FileInput(props: FileInputProps) {
    const { forwardRef, ...rest } = props;

    useEffect(() => {
        forwardRef.current.openDialog = openDialog;

        return () => {
            forwardRef.current = {};
        };
    }, [forwardRef]);

    return (
        <input
            {...rest}
            ref={(ref) => {
                forwardRef.current.element = ref;
            }}
            style={{ display: "none" }}
            type="file"
            hidden
        />
    );

    function openDialog() {
        forwardRef?.current?.element?.click();
    }
}
