import { useTranslate } from "react-translate.ts";

import SideBar from "../../../../common/components/nav/SideBar/SideBar";
import { ADMIN_WALLET_PATH } from "../../../_constants/route.constants";
import {
    ADMIN_CANDIDATES_PATH,
    ADMIN_HIRE_PATH,
    ADMIN_TEAM_PATH,
    JOBS_PATH, PROFILE_PATH
} from "../../../../common/_constants/route.constants";

export default function CompanySideBar() {
    const translate = useTranslate("admin.header.nav.link");

    return (
        <SideBar
            links={[
                {
                    to: JOBS_PATH,
                    label: translate("jobs"),
                    icon: "bag-light",
                },
                {
                    to: ADMIN_CANDIDATES_PATH,
                    label: translate("candidates"),
                    icon: "candidate",
                },
                {
                    to: PROFILE_PATH,
                    label: translate("profile"),
                    icon: "buildings",
                },
                {
                    to: ADMIN_TEAM_PATH,
                    label: translate("team"),
                    icon: "team",
                },
                {
                    to: ADMIN_HIRE_PATH,
                    label: translate("hire"),
                    icon: "head",
                },
                // {
                //     to: ADMIN_WALLET_PATH,
                //     label: translate("wallet"),
                //     icon: "bar-chart",
                // },
            ]}
        />
    );
}
