import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { Button, ActionDialog, AutoCheck } from '../../../../common/components';

export function UnlockDialog({ children, onInspect, ...rest }) {
    const translate = useTranslate('admin.candidate.unlockDialog');

    return (
        <ActionDialog
            heading={translate('heading')}
            subHeading={translate('subHeading')}
            infoList={[
                translate('info1'),
                translate('info2'),
                translate('info3'),
            ]}
            buttonLabel={translate('buttonLabel')}
            autoCheckLabel={translate('autoCheck')}
            icons={['/img/dialog/lock.svg', '/img/dialog/unlock.svg']}
            {...rest}
        >
            <Info onInspect={onInspect} translate={translate}>
                {children}
            </Info>
        </ActionDialog>
    );
}

function Info({ children, onInspect, translate }) {
    return (
        <>
            <strong className="font-34 text-center">{translate('done')}</strong>
            <div className="text-center">{translate('contact')}</div>
            <div>{children}</div>
            <AutoCheck text={translate('autoCheck2')} />
            <Button isWide onClick={onInspect}>
                {translate('button')}
            </Button>
        </>
    );
}
