import { createSelector } from "reselect";

import { AuthHelpers } from "../_helpers";
import {
    ROLE_HERO,
    ROLE_HR_ADMIN,
    ROLE_USER,
} from "../_constants/role.constants";
import { ACTIVE_STATUS } from "../_constants/user.constants";
import { MainState } from "../../root.reducer";
import { useSelector } from "react-redux";

const auth = (state: MainState) => state.common.auth;

const getUser = createSelector(auth, ({ user }) => user);

const isAdmin = createSelector(getUser, (user) =>
    AuthHelpers.hasUserRole(user, ROLE_HR_ADMIN)
);

const isHero = createSelector(getUser, (user) =>
    AuthHelpers.hasUserRole(user, ROLE_HERO)
);

const userStatus = createSelector(getUser, (user) => user.userStatus.status);

const isActive = createSelector(
    userStatus,
    (status) => status === ACTIVE_STATUS
);

const getProfileId = createSelector(getUser, (user) => user.profileId);
const getHeroProfileId = createSelector(getUser, (user) => user.heroProfileId);

const getCompanyId = createSelector(getUser, (user) => user.companyId);

const isSigned = createSelector(auth, ({ signed }) => signed);

const getUserEmail = createSelector(getUser, (user) => user.username);

const getUUID = createSelector(getUser, (user) => user.uuid);

const isStudent = createSelector(getUser, (user) =>
    AuthHelpers.hasUserRole(user, ROLE_USER)
);

export const AuthSelectors = {
    isAdmin,
    userStatus,
    getProfileId,
    getHeroProfileId,
    getCompanyId,
    getUser,
    isSigned,
    getUserEmail,
    isHero,
    isActive,
    getUUID,
    useGetCompanyId,
    useGetProfileId,
    isStudent
};

function useGetCompanyId() {
    return useSelector(getCompanyId);
}

function useGetProfileId() {
    return useSelector(getProfileId);
}
