import { Fragment } from 'react';
import { createClass } from 'classname-helper';

import { FieldError } from '../form';
import { Translate } from 'react-translate.ts';

export function InputWrapper({
    children,
    label,
    className,
    hasError,
    error,
    required,
    disabled,
    displayName,
    ...rest
}) {
    return (
        <Fragment>
            <div className={createContainerClass()} {...rest}>
                {renderLabel()}
                {children}
                {renderRequired()}
                {renderError()}
            </div>
        </Fragment>
    );

    function renderError() {
        if (!hasError || error === 'required') {
            return null;
        }

        return <FieldError name={displayName} error={error} />;
    }

    function renderLabel() {
        if (!label) {
            return null;
        }

        const labelClassName = `${required ? 'required' : ''}`;

        return (
            <label className={labelClassName}>
                <Translate textOnly>{label}</Translate>
            </label>
        );
    }

    function renderRequired() {
        if (!required) {
            return null;
        }

        const requiredClassName = `required ${
            hasError && error === 'required' ? 'has-danger' : ''
        }`;

        return <div className={requiredClassName} />;
    }

    function createContainerClass() {
        return createClass({
            flex: true,
            'flex-column': true,
            [className]: !!className,
            disabled,
        });
    }
}
