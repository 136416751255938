import { useTranslate } from "react-translate.ts";
import { Panel, PanelProps } from "../../../../common/components/Panel/Panel";
import AdminEdit from "../../AdminEdit/AdminEdit";
import HardSkillsEdit, {
    HardSkillsEditProps,
} from "../../HardSkillsEdit/HardSkillsEdit";

export default function HardSkillsEditPanel(
    props: HardSkillsEditProps & PanelProps
) {
    const { skills, jobPostId, ...rest } = props;
    const translate = useTranslate("admin.jobs.edit.skills.hard");

    return (
        <Panel anchor="hard-skills" data-test="hard-skills" {...rest}>
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <HardSkillsEdit skills={skills} jobPostId={jobPostId} />
        </Panel>
    );
}
