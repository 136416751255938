import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CareerPathsSelectors } from "../../../../../_selectors/CareerPathsSelectors";
import { CareerPathsActions } from "../../../../../_actions";
import { MathHelper } from "../../../../../../common/_helpers";
import { DiagramContent } from "./DiagramContent";
import { UserProfileSelectors } from "../../../../../../common/_selectors";
import ProfileSelectors from "../../../../../_selectors/ProfileSelectors";

const MIN_ZOOM = 0.4;
const MAX_ZOOM = 1;

export function Diagram(props) {
    const {
        wrapperRef,
        matchScoreEnabled,
        hoveredPathIndex,
        highlightedPathIndex,
        onRoute,
        activeRouteSearch,
        onNodeSelect,
        selectedNode = null,
        displayOnly = false,
        onIndustrySelect,
        selectedCategoryId,
        selectedSubcategoryId,
    } = props;

    const [zoom, setZoom] = useState(0.6);

    const { map, paths, categories, isLoading, hasFailed, image, name } =
        useSelector(mapState);

    const { setSelected } = mapActions(useDispatch());

    return (
        <DiagramContent
            wrapperRef={wrapperRef}
            map={map}
            // paths={paths}
            isLoading={isLoading}
            hasFailed={hasFailed}
            selectedNode={selectedNode}
            activeRouteSearch={activeRouteSearch}
            onNodeSelect={onNodeSelect}
            displayOnly={displayOnly}
            onRoute={onRoute}
            highlightedPathIndex={highlightedPathIndex}
            hoveredPathIndex={hoveredPathIndex}
            matchScoreEnabled={matchScoreEnabled}
            onZoom={setZoom}
            onControlsZoom={zoomMap}
            unsetSelectedNode={() => setSelected(null)}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            zoom={zoom}
            categories={categories}
            profileImage={image}
            profileName={map.title}
            onCategorySelect={onIndustrySelect}
            selectedCategoryId={selectedCategoryId}
            selectedSubcategoryId={selectedSubcategoryId}
        />
    );

    function zoomMap(value) {
        const newZoom = MathHelper.clamp(zoom + value, MIN_ZOOM, MAX_ZOOM);
        setZoom(newZoom);
    }
}

function mapState(state) {
    return {
        map: CareerPathsSelectors.map.getData(state),
        hasFailed: CareerPathsSelectors.map.hasFailed(state),
        isLoading: CareerPathsSelectors.map.isLoading(state),
        paths: CareerPathsSelectors.routing.getPaths(state),
        categories: CareerPathsSelectors.map.getCategories(state),
        image: UserProfileSelectors.getImage(state),
        name: ProfileSelectors.getFullName(state),
    };
}

function mapActions(dispatch) {
    return {
        setSelected: (node) =>
            dispatch(CareerPathsActions.setSelectedNode(node)),
    };
}
