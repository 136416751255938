const GET_INVITES = 'INVITE_FRIENDS__GET_INVITES';
const GET_INVITES_SUCCESS = 'INVITE_FRIENDS__GET_INVITES_SUCCESS';
const GET_INVITES_FAIL = 'INVITE_FRIENDS__GET_INVITES_FAIL';

const SEND_INVITES = 'INVITE_FRIENDS__SEND_INVITES';
const SEND_INVITES_SUCCESS = 'INVITE_FRIENDS__SEND_INVITES_SUCCESS';
const SEND_INVITES_FAIL = 'INVITE_FRIENDS__SEND_INVITES_FAIL';

const SEND_INVITE = 'INVITE_FRIENDS__SEND_INVITE';
const SEND_INVITE_SUCCESS = 'INVITE_FRIENDS__SEND_INVITE_SUCCESS';
const SEND_INVITE_FAIL = 'INVITE_FRIENDS__SEND_INVITE_FAIL';

const InviteFriendsConstants = {
    GET_INVITES,
    GET_INVITES_SUCCESS,
    GET_INVITES_FAIL,
    SEND_INVITES,
    SEND_INVITES_SUCCESS,
    SEND_INVITES_FAIL,
    SEND_INVITE,
    SEND_INVITE_SUCCESS,
    SEND_INVITE_FAIL,
};

export default InviteFriendsConstants;
