export const AuthHelpers = {
    hasUserRole,
};

function hasUserRole(user, roles) {
    if (!user.roles) {
        return false;
    }

    return !!user.roles.find((role) => roles.includes(role.name));
}
