import React, { Fragment } from 'react';
import { useTranslate } from 'react-translate.ts';
import { RoomOutlined } from '@material-ui/icons';
import TextTruncate from 'react-text-truncate';

import { Flex } from '../..';
import { resolveLogoUrl } from '../../../_helpers';
import { TopJobMatch } from './Desktop/TopJobMatch';
import { FollowButton } from './FollowButton';
import { useSwitch } from '../../../hooks/useSwitch';

export function Base({ company, onFollow, displayOnly = true }) {
    const translate = useTranslate('common.company.profile');

    const { match } = company;

    return (
        <>
            <TopPart
                company={company}
                onFollow={onFollow}
                translate={translate}
                displayOnly={displayOnly}
            />
            {match ? (
                <TopJobMatch
                    match={match}
                    className="padding-y-10 margin-right-40 border-y w100"
                />
            ) : null}
            <Summary description={company.description} translate={translate} />
        </>
    );
}

function TopPart({ company, onFollow, displayOnly }) {
    const { logoUrl, url, name, headquarterLocation, followed } = company;
    const logoUrlAPI = resolveLogoUrl(logoUrl, url);

    return (
        <Flex wrap="wrap" className="padding-bottom-20">
            <Flex wrap="wrap" alignItems="center">
                <div className="margin-right-20">
                    <img
                        className="company-profile--logo "
                        src={logoUrl || logoUrlAPI}
                        alt=""
                    />
                </div>
                <span className="font-34 padding-y-10 w100-md">{name}</span>
            </Flex>
            {headquarterLocation ? (
                <Flex
                    className="font-14 padding-bottom-30 w100"
                    alignItems="center"
                >
                    <span className="padding-right-10">
                        {headquarterLocation.text}
                    </span>
                    <RoomOutlined color="primary" />
                </Flex>
            ) : null}
            {displayOnly ? null : (
                <FollowButton onFollow={onFollow} isFollowed={followed} />
            )}
            {/* <Update translate={translate} /> */}
        </Flex>
    );
}

/*function Industries({ industries }) {
    if (!industries) {
        return null;
    }

    return (
        <Flex className="w100 padding-bottom-20">
            <span className="font-13 text-secondary">
                {industries.map((industry) => industry.name).join(', ')}
            </span>
        </Flex>
    );
}

function Update({ translate }) {
    return (
        <Flex className="uppercase w100 padding-top-40">
            <span className="text-secondary font-heavy padding-right-10 ">
                {translate('update')}
            </span>
            <strong>
                {translate('updated', {
                    count: Math.floor(Math.random() * 4),
                })}
            </strong>
        </Flex>
    );
}*/

function Summary({ description, translate }) {
    const [isOpen, open] = useSwitch();

    return (
        <div className="padding-y-30 w100">
            <div className="padding-bottom-10">
                <strong className="font-18">{translate('summary')}</strong>
            </div>

            <div className="font-13 text-secondary font-light line-h-24">
                {isOpen ? (
                    description
                ) : (
                    <>
                        <TextTruncate
                            textElement={Fragment}
                            element="span"
                            text={description}
                            line={4}
                        />
                        <span
                            className="font-13 clickable text-primary underline"
                            onClick={open}
                        >
                            {translate('buttons.read-more')}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
}

/*function ViewStats({ views, translate }) {
    return (
        <Flex className="uppercase w50 padding-top-20">
            <span className="text-secondary font-heavy padding-right-10 ">
                {translate('views')}
            </span>
            <strong>{views}</strong>
        </Flex>
    );
}

function FollowerStats({ followers, translate }) {
    return (
        <Flex className="uppercase w50 padding-top-20">
            <span className="text-secondary font-heavy padding-right-10 ">
                {translate('followers')}
            </span>
            <strong>{followers}</strong>
        </Flex>
    );
}*/
