import styles from "./LanguageMatch.module.scss";
import { useTranslate } from "react-translate.ts";
import { ProgressBar } from "../../../common/components/ProgressBar/ProgressBar";
import Skeleton from "../../../common/components/Skeleton/Skeleton";

type LanguageMatchProps = {
    match: number;
    name: string;
    level: string | null;
    levelMin: string | null;
    levelMax: string | null | undefined;
    fontSize?: number;
    isLoading: boolean;
};

export function LanguageMatch(props: LanguageMatchProps) {
    const { match, name, level, levelMin, levelMax, isLoading } = props;

    const translateMisc = useTranslate("common.misc");

    return (
        <div className={styles.container}>
            <div className="font-book">
                <div className={styles.name}>
                    {!isLoading ? name : <Skeleton height={19} width={120} />}
                </div>
                <div className={styles.values}>
                    {!isLoading ? renderLevels(level, levelMin, levelMax) : <Skeleton height={13} width={180} />}
                </div>
            </div>
            <div className={styles.progress}>
                <ProgressBar progress={match * 100} />
                {/* <ProgressBar progress={100} /> */}
            </div>
        </div>
    );

    function renderLevels(
        level: string | null,
        min: string | null,
        max: string | null | undefined
    ) {
        return `${level || 'none'} ( ${translateMisc("experienceRequired", {
            min,
            max,
        })} )`;
    }
}
