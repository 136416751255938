import { useEffect, useState } from "react";
import { addEventHandler } from "../_helpers/addEventHandler";
import { removeEventHandler } from "../_helpers/removeEventHandler";

export function useMeasurements(reference) {
    const [state, setState] = useState({
        height: 0,
        width: 0,
        left: 0,
        top: 0,
        aspect: 0,
        scroll: { x: 0, y: 0 },
    });

    useEffect(() => {
        if (reference && reference.current) {
            addEvents();
        } else {
            removeEvents();
        }

        return () => removeEvents();
    }, [reference]);

    return state;

    function measure() {
        if (!reference.current) {
            return;
        }

        const {
            offsetWidth,
            offsetHeight,
            offsetLeft,
            offsetTop,
            scrollTop,
            scrollLeft,
        } = reference.current;

        setState({
            height: offsetHeight,
            width: offsetWidth,
            left: offsetLeft,
            top: offsetTop,
            aspect: offsetWidth / offsetHeight,
            scroll: { x: scrollLeft, y: scrollTop },
        });
    }

    function addEvents() {
        if (!reference || !reference.current) {
            return;
        }

        addEventHandler(window, "resize", measure);
        addEventHandler(reference.current, "scroll", measure);
        addEventHandler(window, "scroll", measure);

        measure();
    }

    function removeEvents() {
        if (!reference || !reference.current) {
            return;
        }

        removeEventHandler(window, "resize", measure);
        removeEventHandler(reference.current, "scroll", measure);
        removeEventHandler(window, "scroll", measure);
    }
}
