import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import { BaseSkills } from "./BaseSkills";
import styles from "./SoftSkills.module.scss";
import { SOFT_SKILL_TEST_PATH } from "../../../../common/_constants/route.constants";
import { SoftSkillsChart } from "../../../../common/components";
import ISkillScore from "../../../../common/types/ISkillScore";
import { SKILL_TYPE_SOFT } from "../../../../common/_constants/actions/skill.constants";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import { BREAKPOINT_SM } from "../../../../common/_constants/theme.constants";
import { SoftSkillsBarChart } from "../../../../common/components/charts/SoftSkillsBarChart";

type SoftSkillsProps = {
    heading: string;
    match: number;
    skills: ISkillScore[];
    isLoading: boolean;
};

export function SoftSkills(props: SoftSkillsProps) {
    const { heading, match, skills, isLoading } = props;

    const translate = useTranslate("candidate.jobDetail");
    const translateJobs = useTranslate("common.jobs");

    const chartData = useMemo(() => createChartDataset(skills), [skills]);

    const isMobile = useBreakpoint(BREAKPOINT_SM);

    const height = isMobile ? 300 : 450;
    const radius = isMobile ? 100 : 150;

    return (
        <BaseSkills
            heading={heading}
            match={match}
            label={
                <>
                    <span>{`${translateJobs("match.softSkills")} `}</span>
                    <Link to={SOFT_SKILL_TEST_PATH}>
                        {translateJobs("detail.skillsLink")}
                    </Link>
                </>
            }
            isLoading={isLoading}
        >
            <div className={styles.container}>
                {isMobile ? (
                    <SoftSkillsBarChart
                        data={chartData}
                        height={800}
                        width="108%"
                        style={{ marginLeft: "-8%" }}
                    />
                ) : (
                    <SoftSkillsChart
                        data={chartData}
                        height={600}
                        width="75%"
                    />
                )}
            </div>
        </BaseSkills>
    );
}

function createChartDataset(skills: ISkillScore[]) {
    if (!skills) {
        return null;
    }

    return skills.map((item, i) => {
        const { idealSkill, actualSkill, skillMatchScore } = item;

        const idealMin = idealSkill?.levelMin || 0;
        const idealMax = idealSkill?.levelMax || 100;
        return {
            idealMin: idealMin,
            idealMax: idealMax,
            you: actualSkill?.level || 0,
            match: (skillMatchScore || 0) / 100,
            name: actualSkill?.skill?.name || idealSkill?.skills?.[0].name,
            index: i + 1,
            idealRange: [idealMin, idealMax],
        };
    });
}
