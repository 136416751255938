import React from 'react';
import { Translate } from 'react-translate.ts';

import { Button } from '../../buttons';
import { Check } from '@material-ui/icons';
import { Flex } from '../../layout';

export function FollowButton({ isFollowed, onFollow }) {
    return (
        <Button
            variant={isFollowed ? 'secondary' : 'primary'}
            isThin
            onClick={isFollowed ? () => {} : onFollow}
            className="company-profile--follow"
        >
            {isFollowed ? <Followed /> : <Default />}
        </Button>
    );
}

function Default() {
    return (
        <Translate textOnly>common.company.profile.buttons.follow</Translate>
    );
}

function Followed() {
    return (
        <Flex alignItems="center">
            <Check fontSize="small" />

            <Translate className="padding-left-10">
                common.company.profile.buttons.following
            </Translate>
        </Flex>
    );
}
