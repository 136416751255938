import { useTranslate } from "react-translate.ts";

import {
    APPLICATIONS_TRACKING_PATH,
    CAREER_PATHS_PATH,
    DASHBOARD_PATH,
    HEROES_PATH,
    JOB_ROLES_PATH,
    PROFILE_PATH,
    SOFT_SKILL_TEST_PATH,
} from "../../../_constants/route.constants";
import {
    COMPANY_PATH,
    JOBS_PATH,
} from "../../../../common/_constants/route.constants";
import MobileNav from "../../../../common/components/nav/MobileNav/MobileNav";

export default function CandidateMobileNav() {
    const translate = useTranslate("candidate.header.nav.link");

    return (
        <MobileNav
            links={[
                {
                    to: CAREER_PATHS_PATH,
                    label: translate("career"),
                    icon: "wheel",
                    size: 25,
                },
                {
                    to: DASHBOARD_PATH,
                    label: translate("dashboard"),
                    icon: "summary",
                    size: 20,
                },
                {
                    to: SOFT_SKILL_TEST_PATH,
                    label: translate("tests"),
                    icon: "idea",
                    size: 21,
                },
                // {
                //     to: JOB_ROLES_PATH,
                //     label: translate("jobRoles"),
                //     icon: "bag-light",
                //     size: 19,
                // },
                {
                    to: HEROES_PATH,
                    label: translate("heroes"),
                    icon: "hero",
                    size: 23,
                },
                {
                    to: PROFILE_PATH,
                    label: translate("profile"),
                    icon: "head",
                    size: 21,
                },
                {
                    to: JOBS_PATH,
                    label: translate("jobs"),
                    icon: "chair",
                },
                {
                    to: APPLICATIONS_TRACKING_PATH,
                    label: translate("tracking"),
                    icon: "track",
                },
                {
                    to: COMPANY_PATH,
                    label: translate("companies"),
                    icon: "company",
                },
            ]}
        />
    );
}
