import React, { ReactNode } from "react";
import { useTranslate } from "react-translate.ts";

import styles from "./InterpretationContent.module.scss";
import Button from "../../buttons/Button/Button";

type InterpretationContentProps = {
    children?: ReactNode;
    className?: string;
};

export default function InterpretationContent(props: InterpretationContentProps) {
    const { children, className } = props;

    return <div className={className}>{children}</div>;
}

type InterpretationMoreProps = {
    children: ReactNode;
    showLess?: boolean;
    onShowMore?: () => void;
};

function InterpretationMore(props: InterpretationMoreProps) {
    const { showLess, onShowMore, children } = props;
    const translate = useTranslate("candidate.interpretation");

    if (showLess === true) {
        return (
            <div className={styles.more}>
                <div className={styles.more__text}>{translate("more")}</div>
                <Button
                    className={styles.button}
                    variant="secondary"
                    onClick={() => onShowMore && onShowMore()}
                >
                    {translate("readWhole")}
                </Button>
            </div>
        );
    }

    return <>{children}</>;
}

InterpretationContent.More = InterpretationMore;
