import React from "react";
import { useDispatch } from "react-redux";

import { OnboardingButtons } from "../../../components";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";

export function HeroBackButton(props) {
    const { onClick, disabled, ...rest } = props;
    const dispatch = useDispatch();

    return (
        <OnboardingButtons.Back
            {...rest}
            onClick={handleClick}
            disabled={disabled}
        />
    );

    function handleClick() {
        if (disabled) {
            return;
        }

        onClick ? onClick() : removeStep();
    }

    function removeStep() {
        dispatch(HeroOnboardingActions.addStep(-1));
    }
}
