import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate.ts';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { LoadingOverlay, ConfirmDialog } from '../../../../common/components';
import { DeleteDrop } from '../../index';
import { CandidateTrackingActions } from '../../../_actions';
import { CandidateColumn } from './CandidateColumn';
import {
    TRACKING_APPLIED,
    TRACKING_SHORTLISTED,
    TRACKING_INVITED,
    TRACKING_ACCEPTED,
    TRACKING_DECLINED,
} from '../../../_constants/actions/tracking.constants';
import { UnlockDialog } from './UnlockDialog';
import {
    makeGetCard,
    makeGetTrackingCards,
} from '../../../_selectors/candidateTracking.selectors';
import { CandidateCard } from './CandidateCard';
import { WalletActions } from '../../../_actions/WalletActions';
import { HEADER_HEIGHT } from '../../../../common/_constants/theme.constants';

const initState = {
    cardType: null,
    droppedCandidateId: null,
};

export function CandidatesActive({
    jobPostId,
    onCardClick,
    showUnlock,
    setRejectedCandidate,
    unlockState,
    rejectedCandidate,
    unlock,
    hideUnlock,
    onReject,
    onAccept,
}) {
    const [state, dispatch] = useReducer(reducer, initState);
    const [afterDrop, setAfterDrop] = useState(false);
    const { isLoading, candidate, credits } = useSelector((globalState) =>
        mapState(globalState, unlockState)
    );

    const {
        changeState,
        rejectCandidate,
        unlockCandidate,
        getTrackingCards,
        getWallet,
    } = mapActions(useDispatch(), unlock);

    const isDeleteVisible = !!state.cardType;
    const isUnlockVisible = !!unlockState.candidateId;
    const isConfirmVisible = !!rejectedCandidate;

    const translate = useTranslate('admin.candidate.tracking');

    const [drag, setDrag] = useState(null);

    useEffect(() => void getWallet(), []);
    useEffect(() => {
        getTrackingCards(jobPostId);
    }, [jobPostId]);

    const dropTimerRef = useRef(null);
    const scrollCardRef = useRef(null);
    useEffect(() => {
        if (afterDrop && !isLoading) {
            dropTimerRef.current = setTimeout(() => setAfterDrop(false), 5000);

            if (state.droppedCandidateId) {
                scrollCardRef.current = document.getElementById(
                    `candidate-card-${state.droppedCandidateId}`
                );

                if (scrollCardRef.current) {
                    const rect = scrollCardRef.current.getBoundingClientRect();
                    window.scrollTo(0, rect.top - HEADER_HEIGHT - 100);
                }
            }
        }

        return () => clearTimeout(dropTimerRef.current);
    }, [afterDrop, isLoading]);

    return (
        <div>
            <UnlockDialog
                isVisible={isUnlockVisible}
                isConfirmed={candidate && candidate.unlocked}
                onClose={hideUnlock}
                onConfirm={() => {
                    unlockCandidate(candidate, jobPostId, credits);
                    onAccept();
                }}
                onInspect={() => {
                    onCardClick(candidate);
                    hideUnlock();
                }}
            >
                <CandidateCard candidate={candidate} canDrag={false} />
            </UnlockDialog>
            <ConfirmDialog
                isOpen={isConfirmVisible}
                onCancel={() => setRejectedCandidate(null)}
                onConfirm={() => {
                    rejectCandidate(rejectedCandidate, jobPostId);
                    setRejectedCandidate(null);
                    onReject();
                }}
                label={'admin.candidate.tracking.reject.label'}
                confirmText={'admin.candidate.tracking.reject.button'}
                title={'admin.candidate.tracking.reject.title'}
            />
            <LoadingOverlay isLoading={isLoading} />
            <DndProvider backend={Backend}>
                <div id="tracking-layout__grid">
                    <CandidateColumn
                        label={translate('new')}
                        type={TRACKING_APPLIED}
                        accept={[TRACKING_SHORTLISTED, TRACKING_APPLIED]}
                        onCardDragStart={onCardDragStart}
                        onCardDragEnd={handleDrop}
                        jobPostId={jobPostId}
                        cardSelector={makeGetTrackingCards}
                        onCardClick={onCardClick}
                        dragState={drag}
                        setDragState={setDrag}
                        isHighlighted={state.cardType === TRACKING_SHORTLISTED}
                        afterDrop={afterDrop}
                        droppedCandidateId={state.droppedCandidateId}
                        showUnlockAction={showUnlock}
                        canDrag
                        canDrop
                    />
                    <CandidateColumn
                        label={translate('shortlist')}
                        type={TRACKING_SHORTLISTED}
                        accept={[TRACKING_APPLIED, TRACKING_SHORTLISTED]}
                        onCardDragStart={onCardDragStart}
                        onCardDragEnd={handleDrop}
                        jobPostId={jobPostId}
                        cardSelector={makeGetTrackingCards}
                        onCardClick={onCardClick}
                        dragState={drag}
                        setDragState={setDrag}
                        isHighlighted={state.cardType === TRACKING_APPLIED}
                        afterDrop={afterDrop}
                        droppedCandidateId={state.droppedCandidateId}
                        canDrag
                        canDrop
                    />
                    <CandidateColumn
                        label={translate('sent-offers')}
                        type={TRACKING_INVITED}
                        cardSelector={makeGetTrackingCards}
                        onCardClick={onCardClick}
                    />
                    <CandidateColumn
                        label={translate('accepted')}
                        type={TRACKING_ACCEPTED}
                        accept={[
                            TRACKING_APPLIED,
                            TRACKING_SHORTLISTED,
                            TRACKING_ACCEPTED,
                        ]}
                        dragAccept={[
                            TRACKING_APPLIED,
                            TRACKING_SHORTLISTED,
                            TRACKING_ACCEPTED,
                        ]}
                        onCardClick={onCardClick}
                        cardSelector={makeGetTrackingCards}
                        dragState={drag}
                        setDragState={setDrag}
                        isHighlighted={
                            state.cardType === TRACKING_APPLIED ||
                            state.cardType === TRACKING_SHORTLISTED
                        }
                        afterDrop={afterDrop}
                        droppedCandidateId={state.droppedCandidateId}
                        canDrop
                        canDrag
                    />
                </div>
                <DeleteDrop
                    isVisible={isDeleteVisible}
                    type={TRACKING_DECLINED}
                    accept={[TRACKING_APPLIED, TRACKING_SHORTLISTED]}
                />
            </DndProvider>
        </div>
    );

    function onCardDragStart(item) {
        showDelete(item.type);
    }

    function handleDrop(item, dropResult) {
        hideDelete();

        if (!dropResult) {
            return;
        }

        const type = dropResult.type;

        if (type === TRACKING_DECLINED) {
            setRejectedCandidate(item.payload);
            return;
        }

        if (type === TRACKING_ACCEPTED) {
            const { id, status } = item.payload;
            showUnlock({
                candidateId: id,
                filter: status,
            });
        } else {
            changeState(item.payload, type, jobPostId);
            dispatch({ type: DROP_CARD, payload: item.payload.id });
            setAfterDrop(true);
        }
    }

    function showDelete(cardType) {
        dispatch({ type: SHOW_DELETE, payload: cardType });
    }

    function hideDelete() {
        dispatch({ type: HIDE_DELETE });
    }
}

function mapState(state, { candidateId, filter }) {
    const getCard = makeGetCard(candidateId, filter);

    return {
        isLoading: state.admin.candidates.candidateTracking.loading,
        candidate: getCard(state),
        credits: state.admin.wallet.data.account.credit,
    };
}

const SHOW_DELETE = 'SHOW_DELETE';
const HIDE_DELETE = 'HIDE_DELETE';
const DROP_CARD = 'DROP_CARD';

function mapActions(dispatch, unlock) {
    return {
        changeState: (candidate, newStatus, jobPostId) => {
            const { id, status, basicInfo, jobPost } = candidate;
            jobPostId = jobPostId || jobPost.id;
            if (basicInfo.id && jobPostId && id) {
                dispatch(
                    CandidateTrackingActions.changeState({
                        candidateId: id,
                        oldStatus: status,
                        newStatus,
                        jobPostId,
                        profileId: basicInfo.id,
                    })
                );
            }
        },
        rejectCandidate: (candidate, jobPostId) => {
            const { id, status, basicInfo, jobPost } = candidate;
            jobPostId = jobPostId || jobPost.id;
            if (basicInfo.id && jobPostId && id) {
                dispatch(
                    CandidateTrackingActions.rejectCandidate({
                        candidateId: id,
                        status,
                        jobPostId,
                        profileId: basicInfo.id,
                    })
                );
            }
        },
        unlockCandidate: (candidate, jobPostId, credits) => {
            const { id, status, basicInfo, jobPost } = candidate;
            jobPostId = jobPostId || jobPost.id;
            if (basicInfo.id && jobPostId && id) {
                dispatch(
                    CandidateTrackingActions.unlockCandidate({
                        candidateId: id,
                        oldStatus: status,
                        jobPostId,
                        profileId: basicInfo.id,
                        credits,
                    })
                );
            }
            unlock();
        },
        getTrackingCards: (jobPostId) =>
            dispatch(CandidateTrackingActions.getTrackingCards(jobPostId)),
        getWallet: () => void dispatch(WalletActions.getWallet()),
    };
}

function reducer(state, action) {
    switch (action.type) {
        case DROP_CARD:
            return Object.assign({}, state, {
                droppedCandidateId: action.payload,
            });
        case SHOW_DELETE:
            return Object.assign({}, state, { cardType: action.payload });
        case HIDE_DELETE:
            return Object.assign({}, state, { cardType: null });
        default:
            return state;
    }
}
