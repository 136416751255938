import React from "react";
import { useTranslate } from "react-translate.ts";
import { useRouteMatch, Link } from "react-router-dom";

import { Button } from "../../../../common/components";

export function InProgress(props) {
    const translate = useTranslate("candidate.softSkillsTest.inProgress");
    const match = useRouteMatch();

    return (
        <div id="soft-skills-test--results" className="test-in-progress">
            <div id="soft-skills-test--results--inner">
                <img src="/img/soft-skills/progress-indicator.svg" alt="" />
                <div id="soft-skills-test--results--title">
                    {translate("title")}
                </div>
                <div
                    id="soft-skills-test--results--hint"
                    className="font-light"
                >
                    {translate("hint")}
                </div>
                <div id="soft-skills-test--results--chart">
                    {props.isLocked ? (
                        <Button disabled>{translate("continue")}</Button>
                    ) : (
                        <Link to={match.path + "/test"}>
                            <Button>{translate("continue")}</Button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}
