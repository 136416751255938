import React from 'react';

import { BaseNavMenu } from './BaseNavMenu';
import { Languages } from './Languages';

export function LanguageMenu(props) {
    const { isOpen, onClose } = props;
    return (
        <BaseNavMenu
            isOpen={isOpen}
            onClose={onClose}
            arrowRight={20}
            id="language-menu"
        >
            <div id="lang-list">
                <Languages />
            </div>
        </BaseNavMenu>
    );
}
