import React, { useMemo } from 'react';

import { Select } from '../input';

/**
 *
 * @param { { addedYears?: number, yearCount?: number, value: { date: number } } } props
 */
export function SelectYear(props) {
    const {
        addedYears,
        yearCount,
        hasError,
        value,
        clearOption,
        clearOptionText,
        styles = {},
        ...rest
    } = props;

    const currentYear = new Date().getFullYear();

    const options = useMemo(
        () => createOptions(currentYear, addedYears, yearCount),
        [currentYear, addedYears, yearCount]
    );

    const getOptionValue = (option) => option.date;

    return (
        <Select
            styles={styles}
            defaultValue={options[0]}
            options={options}
            getOptionLabel={getOptionValue}
            getOptionValue={getOptionValue}
            isSearchable={false}
            value={value}
            clearOption={clearOption ? { date: clearOptionText } : null}
            {...rest}
        />
    );
}

function createOptions(currentYear, addedYears, yearCount) {
    const added = addedYears || 0;
    const count = (yearCount || 15) + added;
    const values = Array.from(new Array(count), (_, index) => {
        return { date: currentYear + added - count + index + 1 };
    });

    return values.reverse();
}
