import React from 'react';
import { useTranslate } from 'react-translate.ts';
import { TooltipLabel } from '../TooltipLabel';

export function AddButton({ onAdd }) {
    const translate = useTranslate();

    return (
        <TooltipLabel label={translate('common.actions.add')}>
            <div>
                <div className="add-button" onClick={onAdd}>
                    <img src="/img/icons/plus.svg" alt="+" />
                </div>
            </div>
        </TooltipLabel>
    );
}
