import { RequestService } from './RequestService';

export const ProfileService = {
    getProfileImage,
    getProfileCompleteness,
    getProfileType,
    saveProfileType,
};

function getProfileImage(profileId) {
    return RequestService.get(`profiles/${profileId}/images/latest`);
}

function getProfileCompleteness(profileId) {
    return RequestService.get(`api/profiles/${profileId}/completeness`);
}

function getProfileType(profileId) {
    return RequestService.get(`profiles/${profileId}/type`);
}

/**
 *
 * @param {{
 * profileId: number;
 * profileType: "COLLEGE" | "UNIVERSITY" | "PROFESSIONAL" ;
 * jobRoleId: number ;
 * industryId: number;
 * universityId: number;
 * locationId: number;
 * universityDegreeTitleId: number;
 * universityDegreeFieldId: number;
 * universityDegreeTypeId: number;
 * description: string;
 * grade: number;
 * startYear: number;
 * endYear: number;
 * }} payload
 */
function saveProfileType(payload) {
    return RequestService.post(`profiles/${payload.profileId}/type`, payload);
}
