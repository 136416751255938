import BaseAdminJob from "../BaseAdminJob/BaseAdminJob";
import { CandidatesActive } from "../CompanyCandidates/components";
import AdminJobActions from "../../_actions/AdminJobActions";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";

export default function AdminJobKanban(props: { id: number }) {
    const { id } = props;

    const { data: job } = AdminJobSelectors.useGetEdit();
    AdminJobActions.useGetJobPost(id);

    return (
        <BaseAdminJob id={id} heading={job?.title}>
            <CandidatesActive
                jobPostId={id}
                onCardClick={console.log}
                showUnlock={false}
                onReject={console.log}
                onAccept={console.log}
                setRejectedCandidate={console.log}
                unlockState={{}}
                rejectedCandidate={null}
                unlock={null}
                hideUnlock={false}
            />
        </BaseAdminJob>
    );
}
