import { PropsWithChildren } from "react";
import { Translate } from "react-translate.ts";
import { IntegratedDialog } from "../../../../common/components";
import { FormButtons } from "./FormButtons";

export type ProfileEditProps = {
    onClose?: () => void;
    heading?: string;
    subHeading?: string;
    isWide?: boolean;
    formButtonsProps?: any;
    formButtons?: any;
    forwardRef?: any;
    isLoading?: boolean;
};

export function ProfileEdit(props: PropsWithChildren<ProfileEditProps>) {
    const {
        children,
        onClose,
        heading,
        subHeading,
        isWide,
        formButtonsProps,
        formButtons,
        forwardRef,
        isLoading,
    } = props;
    return render();
    function render() {
        return (
            <IntegratedDialog
                isLoading={isLoading}
                onClose={onClose}
                forwardRef={forwardRef}
            >
                <div
                    className={`profile-edit flex flex-column${
                        isWide ? " wide" : ""
                    }`}
                >
                    <div className="w100 text-center flex flex-column padding-top-20 padding-bottom-60">
                        <h3>
                            <Translate textOnly>{heading}</Translate>
                        </h3>
                        {subHeading ? (
                            <span className="text-secondary font-15 padding-top-10">
                                <Translate textOnly>{subHeading}</Translate>
                            </span>
                        ) : null}
                    </div>
                    {children}
                    <div className="w100">
                        {formButtons === undefined ? (
                            <FormButtons {...formButtonsProps} />
                        ) : (
                            formButtons
                        )}
                    </div>
                </div>
            </IntegratedDialog>
        );
    }
}
