import { PropsWithChildren, useMemo } from "react";
import { generatePath } from "react-router-dom";

import AdminEdit, { AdminEditProps } from "../AdminEdit/AdminEdit";
import {
    ADMIN_JOB_EDIT_DETAIL_PATH,
    ADMIN_JOB_EDIT_KANBAN_PATH,
} from "../../../common/_constants/route.constants";
import { useTranslate } from "react-translate.ts";

interface BaseAdminJobProps extends Partial<AdminEditProps> {
    id: number;
}

export default function BaseAdminJob(
    props: PropsWithChildren<BaseAdminJobProps>
) {
    const translate = useTranslate();
    const { id, heading, lastSavedTimestamp, children, ...rest } = props;

    const links = useMemo(
        () => [
            {
                to: generatePath(ADMIN_JOB_EDIT_KANBAN_PATH, { id }),
                label: translate("admin.jobs.edit.links.candidates"),
            },
            {
                to: generatePath(ADMIN_JOB_EDIT_DETAIL_PATH, { id }),
                label: translate("admin.jobs.edit.links.details"),
            },
        ],
        []
    );

    return (
        <AdminEdit
            heading={heading}
            lastSavedTimestamp={lastSavedTimestamp}
            links={links}
            previewLink={translate("admin.jobs.edit.links.public")}
            {...rest}
        >
            {children}
        </AdminEdit>
    );
}
