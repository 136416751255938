import { combineReducers } from "redux";
import {
    STATE_FAIL,
    STATE_LOADING,
    STATE_SUCCESS,
} from "../../../../common/_constants/state.constants";

import {
    JOB_FILTER_CHANGE_SALARY,
    JOB_FILTER_CHANGE_REMOTE,
    JOB_FILTER_CHANGE_CURRENCY,
    JOB_FILTER_CHANGE_BENEFIT,
    JOB_FILTER_CHANGE_EMPLOYMENT,
    JOB_FILTER_CHANGE_LOCATIONS,
    JOB_FILTER_CHANGE_ROLES,
    JOB_FILTER_CLEAR,
    GET_JOB_FILTERS_SUCCESS,
    GET_JOB_FILTERS,
    GET_JOB_FILTERS_FAIL, JOB_FILTER_CHANGE_TITLE
} from "../../../_constants";

export default combineReducers({
    salary: reducer([null, null], JOB_FILTER_CHANGE_SALARY),
    remote: reducer(false, JOB_FILTER_CHANGE_REMOTE),
    currency: reducer(
        null, //{ id: 29, code: "CZK", symbol: "Kč" },
        JOB_FILTER_CHANGE_CURRENCY
    ),
    benefits: benefits, // reducer([], JOB_FILTER_CHANGE_BENEFIT),
    employment: reducer([], JOB_FILTER_CHANGE_EMPLOYMENT),
    locations: reducer(null, JOB_FILTER_CHANGE_LOCATIONS),
    roles: reducer("", JOB_FILTER_CHANGE_ROLES),
    employmentType,
    title: reducer("", JOB_FILTER_CHANGE_TITLE),
    state,
});

function employmentType(state = [], action) {
    switch (action.type) {
        case GET_JOB_FILTERS_SUCCESS:
            const { employmentTypeDTOS } = action.payload;
            return employmentTypeDTOS.map((item) => ({
                ...item,
                checked: false,
            }));
        case JOB_FILTER_CHANGE_EMPLOYMENT:
            return action.payload;
        case JOB_FILTER_CLEAR:
            return state.map((item) => ({ ...item, checked: false }));
        default:
            return state;
    }
}

function benefits(state = [], action) {
    switch (action.type) {
        case GET_JOB_FILTERS_SUCCESS:
            const { jobBenefitDTOS } = action.payload;
            return jobBenefitDTOS.map((item) => ({
                ...item,
                checked: false,
            }));
        case JOB_FILTER_CHANGE_BENEFIT:
            return action.payload;
        case JOB_FILTER_CLEAR:
            return state.map((item) => ({ ...item, checked: false }));
        default:
            return state;
    }
}

function reducer(initState, type) {
    return (state = initState, action) => {
        switch (action.type) {
            case type:
                const payload = action.payload;
                return payload === null || payload === undefined
                    ? initState
                    : payload;
            case JOB_FILTER_CLEAR:
                return initState;
            default:
                return state;
        }
    };
}

function state(state = null, action) {
    switch (action.type) {
        case GET_JOB_FILTERS:
            return STATE_LOADING;
        case GET_JOB_FILTERS_SUCCESS:
            return STATE_SUCCESS;
        case GET_JOB_FILTERS_FAIL:
            return STATE_FAIL;
        default:
            return state;
    }
}
