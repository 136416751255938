import { cx } from "@emotion/css";
import { Translate, useTranslate } from "react-translate.ts";

import Skeleton from "../../../../common/components/Skeleton/Skeleton";
import OptionsMenu from "../../../../common/components/OptionsMenu/OptionsMenu";
import { Panel } from "../../../../common/components/Panel/Panel";
import ITeamMember from "../../../../common/types/ITeamMember";
import styles from "./AdminTeamListItem.module.scss";
import TeamConstants from "../../../../common/_constants/TeamConstants";
import ListItem from "../../../../common/components/list/ListItem/ListItem";

interface AdminTeamListItemProps {
    member?: ITeamMember;
    isLoading?: boolean;
    onDelete?: (member: ITeamMember) => void;
}

export default function AdminTeamListItem(props: AdminTeamListItemProps) {
    const { member, isLoading, onDelete } = props;

    const translate = useTranslate("admin.team");

    const isOwner = member?.status === TeamConstants.OWNER;

    return (
        <ListItem className={styles.item}>
            <div className={styles.side}>
                <div className={styles.imageWrap}>
                    {!isLoading ? (
                        <img
                            src={member?.imgUrl || "/img/avatar.svg"}
                            className={styles.image}
                            alt=""
                        />
                    ) : (
                        <Skeleton className={styles.image} />
                    )}
                </div>
                <div>
                    {!isLoading ? (
                        <div className={styles.name}>
                            {member?.firstName} {member?.lastName}
                        </div>
                    ) : (
                        <Skeleton
                            className={styles.name}
                            height={21}
                            width={140}
                        />
                    )}
                    {!isLoading ? (
                        member?.position && (
                            <div className={styles.position}>
                                {member.position}
                            </div>
                        )
                    ) : (
                        <Skeleton height={11} width={100} />
                    )}
                </div>
            </div>
            {!isLoading && (
                <div className={styles.side}>
                    {member?.status && (
                        <div
                            className={cx(
                                styles.status,
                                isOwner && styles.status__owner
                            )}
                        >
                            {translate(`status.${member.status}`)}
                        </div>
                    )}
                    <OptionsMenu
                        style={{
                            visibility: isOwner ? "hidden" : null,
                        }}
                    >
                        {!isOwner && (
                            <OptionsMenu.Option
                                onClick={handleDelete}
                                iconProps={{ name: "trash-light" }}
                            >
                                <Translate textOnly>common.actions.delete</Translate>
                            </OptionsMenu.Option>
                        )}
                    </OptionsMenu>
                </div>
            )}
        </ListItem>
    );

    function handleDelete() {
        onDelete && onDelete(member);
    }
}
