import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import { Translate, useTranslate } from "react-translate.ts";
import { cx } from "@emotion/css";

import { BackButton, ScrollToTop } from "../../../common/components";
import { JobSearchSelectors } from "../../_selectors";
import { JobActions } from "../../_actions";
import { BREAKPOINT_LG } from "../../../common/_constants/theme.constants";
import {
    JOB_DETAIL_PATH,
    JOB_LIST_PATH,
} from "../../../common/_constants/route.constants";
import { useAddClassToMain } from "../../../common/hooks/useAddClassToMain";
import { WrappedJobDetail } from "../../components/JobDetail/parts/WrappedJobDetail";
import styles from "./JobListPage.module.scss";
import { JobList } from "../../../common/components/JobList/JobList";
import { JobFilters } from "./Filters/JobFilters";
import { JobFilterActions } from "../../_actions";
import { useBreakpoint } from "../../../common/hooks/useBreakpoint";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import BaseListLayout from "../../../common/components/layout/BaseListLayout/BaseListLayout";
import usePage from "../../../common/hooks/usePage";
import Pagination from "../../../common/components/misc/Pagination/Pagination";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export default function JobListPage() {
    const { profileId, activeFilters } = useSelector(mapState);

    const dispatch = useDispatch();

    useAddClassToMain(["full-size"]);

    const page = usePage();

    useEffect(() => {
        dispatch(JobFilterActions.getFilters());
    }, [dispatch]);

    useEffect(() => {
        if (profileId) {
            dispatch(JobActions.getCards(page, activeFilters, profileId));
        }
    }, [dispatch, page, activeFilters, profileId]);

    const isSmallScreen = useBreakpoint(BREAKPOINT_LG);

    return (
        <div>
            <ScrollToTop />
            <Content page={page} isSmallScreen={isSmallScreen} />
        </div>
    );
}

type PageProps = {
    page: number;
};

type ContentProps = {
    isSmallScreen: boolean;
} & PageProps;

function Content(props: ContentProps) {
    const { isSmallScreen } = props;
    const { elements, isLoading } = useSelector(mapState);
    const renderToHeader = useRenderToHeader();
    const history = useHistory();
    const translate = useTranslate();
    const dispatch = useDispatch();

    const searchProps = {
        onSearch: (search: string) =>
            dispatch(JobFilterActions.changeTitle(search)),
        placeholder: translate("candidate.jobs.filters.jobTitle"),
    };
    const filtersProps = {
        content: <JobFilters />,
    };

    const params = useParams<{ jobPostId?: string }>();
    const { jobPostId } = params;

    if (isSmallScreen) {
        return (
            <CandidatePageLayout>
                <Switch>
                    <Route path={JOB_DETAIL_PATH}>
                        {renderToHeader(
                            <BackButton onClick={() => history.goBack()} />
                        )}
                        <Detail />
                    </Route>
                    <Route path={JOB_LIST_PATH}>
                        <BaseListLayout
                            filtersProps={filtersProps}
                            searchProps={searchProps}
                        >
                            {elements <= 0 && !isLoading ? (
                                <NoJobs />
                            ) : (
                                <div className={styles.list}>
                                    <Jobs id={parseInt(jobPostId) || null} />
                                </div>
                            )}
                        </BaseListLayout>
                    </Route>
                </Switch>
            </CandidatePageLayout>
        );
    }

    return (
        <CandidatePageLayout>
            <BaseListLayout
                filtersProps={filtersProps}
                searchProps={searchProps}
            >
                <div className={styles.content}>
                    <div className={cx(styles.content__list, "nice-scroll")}>
                        <Route path={JOB_LIST_PATH}>
                            <Jobs id={parseInt(jobPostId) || null} />
                        </Route>
                    </div>
                    <div className={cx(styles.content__detail, "nice-scroll")}>
                        <Route path={JOB_DETAIL_PATH}>
                            <Detail />
                        </Route>
                    </div>
                </div>
            </BaseListLayout>
        </CandidatePageLayout>
    );
}

function Jobs(props: { id: number }) {
    const { cards, isLoading, pages } = useSelector(mapState);
    return (
        <>
            <JobList jobs={cards} isLoading={isLoading} />
            <Pagination
                pages={pages}
                path={props.id ? JOB_DETAIL_PATH : JOB_LIST_PATH}
                pathArgs={props.id ? { jobPostId: props.id } : {}}
            />
        </>
    );
}

function NoJobs() {
    return (
        <Translate className={styles.empty}>
            candidate.jobs.cards.noJobs
        </Translate>
    );
}

function Detail() {
    const params = useParams<{ jobPostId: string }>();
    return <WrappedJobDetail jobPostId={parseInt(params.jobPostId, 10)} />;
}

function mapState(state: any) {
    const { pagination, filters, activeFilters } = state.candidate.jobs.search;
    const { locationCityDTOS } = filters.data;
    const { locations, roles } = activeFilters;

    return {
        profileId: state.common.auth.user.profileId,
        cards: JobSearchSelectors.getCards(state),
        // favourites: JobSearchSelectors.getFavourites(state),
        favouritesCount: JobSearchSelectors.getFavouritesCount(state),
        isLoading: JobSearchSelectors.isLoading(state),
        activeFilters,
        locationCityDTOS,
        locations,
        roles,
        ...pagination,
    };
}
