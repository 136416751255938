import { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { Formik, Form } from "formik";

import {
    PROFILE_PATH,
    HERO_ONBOARDING_PATH,
    ONBOARDING_PATH,
    ADMIN_PROFILE_PATH,
} from "../../../_constants/route.constants";
import { AuthActions } from "../../../_actions";
import { AuthSelectors } from "../../../_selectors/AuthSelectors";
import { MainState } from "../../../../root.reducer";
import Button from "../../../components/buttons/Button/Button";
import Field from "../../../components/input/Field/Field";
import FormInput from "../../../components/input/FormInput";
import PasswordInput from "../../../components/input/PasswordInput/PasswordInput";
import styles from "./LoginPage.module.scss";
import AuthPageLayout from "../../../components/layout/AuthPageLayout/AuthPageLayout";
import { Loading } from "../../../components";

const EMAIL_FIELD = "email";
const PASS_FIELD = "pass";

type Values = {
    [EMAIL_FIELD]: string;
    [PASS_FIELD]: string;
};

const initialValues = {
    [EMAIL_FIELD]: "",
    [PASS_FIELD]: "",
};

export default function LoginPage() {
    const { isSigned, isAdmin, isActive, isHero, loading } =
        useSelector(mapState);
    const history = useHistory();
    const historyRef = useRef(history);
    const dispatch = useDispatch();
    const translate = useTranslate("common.auth.login");

    useEffect(() => {
        if (isSigned) {
            if (isHero) {
                historyRef.current.push(HERO_ONBOARDING_PATH);
            } else if (isActive) {
                historyRef.current.push(
                    isAdmin ? ADMIN_PROFILE_PATH : PROFILE_PATH
                );
            } else {
                historyRef.current.push(ONBOARDING_PATH);
            }
        }
    }, [isSigned, isAdmin, isActive, isHero]);

    if (loading) {
        return <Loading />;
    }

    return (
        <AuthPageLayout>
            <h2 className={styles.header}>{translate("header")}</h2>
            <Formik<Values> initialValues={initialValues} onSubmit={login}>
                <LoginForm translate={translate} />
            </Formik>
            <div className={styles.register}>
                <span>{`${translate("no-account")} `}</span>
                <Link to="/sign-up" data-test="sign-up">
                    {translate("sign-up")}
                </Link>
            </div>
        </AuthPageLayout>
    );

    function login(values: Values) {
        const { email, pass } = values;
        dispatch(AuthActions.login(email.trim(), pass));
    }
}

function mapState(state: MainState) {
    const { loading } = state.common.auth;

    return {
        isSigned: AuthSelectors.isSigned(state),
        loading,
        isAdmin: AuthSelectors.isAdmin(state),
        isActive: AuthSelectors.isActive(state),
        isHero: AuthSelectors.isHero(state),
    };
}

function LoginForm(props: { translate: (key: string) => string }) {
    const { translate } = props;
    const translateForm = (key: string) => translate("form." + key);

    return (
        <Form>
            <Field label={translateForm("email.label")}>
                <FormInput
                    name={EMAIL_FIELD}
                    placeholder={translateForm("email.placeholder")}
                    autoComplete="email"
                    data-test="email"
                />
            </Field>
            <Field label={translateForm("pass.label")}>
                <PasswordInput
                    name={PASS_FIELD}
                    placeholder={translateForm("pass.placeholder")}
                    autoComplete="current-password"
                    useRules={false}
                    data-test="password"
                />
            </Field>
            <div className={styles.forgot}>
                <div />
                <Link to="/forgot-password" data-test="forgot-password">
                    {translate("forgot")}
                </Link>
            </div>
            <div className="control flex justify-center">
                <Button type="submit" data-test="submit">
                    {translate("submit")}
                </Button>
            </div>
        </Form>
    );
}
