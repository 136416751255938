import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Translate, useTranslate } from "react-translate.ts";
import { useDispatch } from "react-redux";

import styles from "./Overview.module.scss";
import Button from "../../../../common/components/buttons/Button/Button";
import FileInput, {
    FileInputRef,
} from "../../../../common/components/input/FileInput/FileInput";
import AdminEdit from "../../AdminEdit/AdminEdit";
import Field from "../../../../common/components/input/Field/Field";
import { SVGIcon } from "../../../../common/components";
import ICompanySize from "../../../../common/types/ICompanySize";
import IIndustry from "../../../../common/types/IIndustry";
import FormInput from "../../../../common/components/input/FormInput";
import FormTextArea from "../../../../common/components/input/FormTextArea";
import { AutocompleteService } from "../../../../candidate/_services";
import FormMultiSelect from "../../../../common/components/input/MultiSelect/FormMultiSelect";
import FormSelect from "../../../../common/components/input/FormSelect";
import CompanyProfileActions from "../../../../common/_actions/CompanyProfileActions";
import ILocation from "../../../../common/types/ILocation";
import IAddress from "../../../../common/types/IAddress";
import UploadButton from "../../AdminJobEdit/parts/UploadButton";
import { NumberSchema, ObjectSchema, StringSchema } from "yup";
import FormField from "../../../../common/components/input/FormField";
import {
    INTEGER_FORM_FIELD,
    NUMBER_FORM_FIELD,
    REQUIRED_FORM_FIELD,
} from "../../../../common/_constants/validation.constants";

interface OverviewProps extends Values {
    id: number;
    isLoading?: boolean;
    saving: boolean;
}

type Values = {
    name: string;
    description: string;
    url: string;
    size: ICompanySize;
    industries: IIndustry[];
    [FOUNDED_FIELD]: string;
    [HEADQUARTERS_FIELD]: ILocation;
};

const NAME_FIELD = "name";
const DESC_FIELD = "description";
const URL_FIELD = "url";
const SIZE_FIELD = "size";
const INDUSTRY_FIELD = "industries";
const FOUNDED_FIELD = "founded";
const HEADQUARTERS_FIELD = "headquarters";

export default function Overview(props: OverviewProps) {
    const {
        id,
        name,
        description,
        url,
        size,
        industries,
        founded,
        headquarters,
        isLoading,
        saving,
    } = props;

    const initialValues = {
        [NAME_FIELD]: name,
        [DESC_FIELD]: description,
        [URL_FIELD]: url,
        [SIZE_FIELD]: size,
        [INDUSTRY_FIELD]: industries,
        [FOUNDED_FIELD]: founded,
        [HEADQUARTERS_FIELD]: headquarters,
    };
    const dispatch = useDispatch();
    const uploadInputRef = useRef<FileInputRef>({});
    const translate = useTranslate("admin.profile.overview");
    const translateForm = useTranslate("admin.profile.overview.form");

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="overview"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={createValidationSchema}
            >
                <Form>
                    <div className={styles.container}>
                        <div className={styles.logo}>
                            <SVGIcon name="gallery" light />
                            <Button onClick={openDialog} inverted>
                                {translate("upload")}
                            </Button>
                            <FileInput
                                forwardRef={uploadInputRef}
                                accept="image/*"
                            />
                        </div>
                        <FormField
                            name={NAME_FIELD}
                            label={translateForm("name.label")}
                            required
                        >
                            <FormInput
                                name={NAME_FIELD}
                                defaultValue={name}
                                placeholder={translateForm("name.placeholder")}
                            />
                        </FormField>
                        <FormField
                            name={DESC_FIELD}
                            label={translateForm("about.label")}
                            required
                        >
                            <FormTextArea
                                name={DESC_FIELD}
                                defaultValue={description}
                                placeholder={translateForm("about.placeholder")}
                            />
                        </FormField>
                        <SizeField />
                        <FormField
                            name={URL_FIELD}
                            label={translateForm("web.label")}
                            required
                        >
                            <FormInput
                                name={URL_FIELD}
                                defaultValue={url}
                                placeholder={translateForm("web.placeholder")}
                            />
                        </FormField>
                        <FormField
                            name={FOUNDED_FIELD}
                            label={translateForm("founded.label")}
                            required
                        >
                            <FormInput
                                type="number"
                                name={FOUNDED_FIELD}
                                defaultValue={founded}
                                placeholder={translateForm(
                                    "founded.placeholder"
                                )}
                            />
                        </FormField>
                        <FormField
                            label={translateForm("headquarters.label")}
                            name={HEADQUARTERS_FIELD}
                            required
                        >
                            <FormSelect<IAddress>
                                name={HEADQUARTERS_FIELD}
                                loadOptions={AutocompleteService.cities}
                                placeholder={translateForm(
                                    "headquarters.placeholder"
                                )}
                                getOptionLabel={(option) =>
                                    option.text || option.title
                                }
                                searchable
                            />
                        </FormField>
                        <FormField
                            name={INDUSTRY_FIELD}
                            label={translateForm("industry.label")}
                        >
                            <FormMultiSelect<IIndustry>
                                name={INDUSTRY_FIELD}
                                placeholder={translateForm(
                                    "industry.placeholder"
                                )}
                                getOptionLabel={(option) => option.name}
                                loadOptions={AutocompleteService.industries}
                            />
                        </FormField>
                        <UploadButton sending={saving} />
                    </div>
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function createValidationSchema() {
        return new ObjectSchema({
            [NAME_FIELD]: new StringSchema().required(REQUIRED_FORM_FIELD),
            [DESC_FIELD]: new StringSchema().required(REQUIRED_FORM_FIELD),
            [URL_FIELD]: new StringSchema().required(REQUIRED_FORM_FIELD),
            [SIZE_FIELD]: new ObjectSchema().required(REQUIRED_FORM_FIELD).nullable(),
            [FOUNDED_FIELD]: new NumberSchema()
                .typeError(NUMBER_FORM_FIELD)
                .integer(INTEGER_FORM_FIELD)
                .required(REQUIRED_FORM_FIELD),
            [HEADQUARTERS_FIELD]: new ObjectSchema().required(REQUIRED_FORM_FIELD).nullable(),
        });
    }

    function SizeField() {
        const [state, setState] = useState({ data: [], loading: false });

        useEffect(() => {
            getData();
        }, []);

        return (
            <>
                <FormField
                    name={SIZE_FIELD}
                    label={translateForm("size.label")}
                    required
                >
                    <FormSelect<ICompanySize>
                        name={SIZE_FIELD}
                        getOptionLabel={(option) => option.name}
                        options={state.data}
                        placeholder={translateForm("size.placeholder")}
                        loading={state.loading}
                    />
                </FormField>
            </>
        );

        async function getData() {
            setState({ ...state, loading: true });

            const data = await AutocompleteService.sizes("");

            setState((prev) => ({ ...prev, data, loading: false }));
        }
    }

    function handleSubmit(values: Values) {
        if (isLoading) {
            return;
        }

        const { size, industries, ...rest } = values;

        dispatch(
            CompanyProfileActions.save({
                id,
                sizeId: size.id,
                industryIds: industries.map((item) => item.id),
                ...rest,
            })
        );
    }

    function openDialog() {
        if (uploadInputRef?.current?.openDialog) {
            uploadInputRef.current.openDialog();
        }
    }
}
