import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingOverlay } from '../../../../common/components';
import { ApplicationsTrackingActions } from '../../../_actions';
import { ApplicationCard } from './ApplicationCard';

export function Rejected({ profileId, onCardClick }) {
    const { isLoading, rejected } = useSelector(mapState);

    const getRejected = mapActions(useDispatch());

    useEffect(() => {
        if (profileId) {
            getRejected(profileId);
        }
    }, [profileId]);
    return (
        <div>
            <LoadingOverlay isLoading={isLoading} />
            <div id="applications-tracking--grid" className="rejected">
                {rejected.map((card, key) => {
                    return (
                        <ApplicationCard
                            key={key}
                            application={card}
                            onClick={() => onCardClick && onCardClick(card)}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function mapState(state) {
    const { loading, rejected } = state.candidate.jobs.tracking;
    return {
        isLoading: loading,
        rejected,
    };
}

function mapActions(dispatch) {
    return (profileId) =>
        void dispatch(
            ApplicationsTrackingActions.getRejectedApplications(profileId)
        );
}
