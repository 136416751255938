import { GET_PROFILE_IMAGE } from '../_constants/actions/profile-actions.constants';

export const GET_PROFILE_COMPLETENESS = 'GET_PROFILE_COMPLETENESS';
export const GET_PROFILE_COMPLETENESS_SUCCESS =
    'GET_PROFILE_COMPLETENESS_SUCCESS';
export const GET_PROFILE_COMPLETENESS_FAIL = 'GET_PROFILE_COMPLETENESS_FAIL';

export const GET_PROFILE_TYPE = 'GET_PROFILE_TYPE';
export const GET_PROFILE_TYPE_SUCCESS = 'GET_PROFILE_TYPE_SUCCESS';
export const GET_PROFILE_TYPE_FAIL = 'GET_PROFILE_TYPE_FAIL';

export const SAVE_PROFILE_TYPE = 'SAVE_PROFILE_TYPE';
export const SAVE_PROFILE_TYPE_SUCCESS = 'SAVE_PROFILE_TYPE_SUCCESS';
export const SAVE_PROFILE_TYPE_FAIL = 'SAVE_PROFILE_TYPE_FAIL';

export const ProfileActions = {
    getProfileImage,
    getProfileCompleteness,
    getProfileCompletenessSuccess,
    getProfileCompletenessFail,
    getProfileType,
    getProfileTypeSuccess,
    getProfileTypeFail,
    saveProfileType,
    saveProfileTypeSuccess,
    saveProfileTypeFail,
};

function getProfileImage(profileId) {
    return { type: GET_PROFILE_IMAGE, payload: profileId };
}

function getProfileCompleteness(profileId) {
    return { type: GET_PROFILE_COMPLETENESS, payload: profileId };
}

function getProfileCompletenessSuccess(payload) {
    return { type: GET_PROFILE_COMPLETENESS_SUCCESS, payload };
}

function getProfileCompletenessFail(error) {
    return { type: GET_PROFILE_COMPLETENESS_FAIL, error };
}

function getProfileType(profileId) {
    return { type: GET_PROFILE_TYPE, payload: profileId };
}

function getProfileTypeSuccess(payload) {
    return { type: GET_PROFILE_TYPE_SUCCESS, payload };
}

function getProfileTypeFail(error) {
    return { type: GET_PROFILE_TYPE_FAIL, error };
}

/**
 *
 * @param {profileId: number, profileType: "COLLEGE" | "UNIVERSITY" | "PROFESSIONAL", payload: {
 * jobRoleId: number ;
 * industryId: number;
 * universityId: number;
 * locationId: number;
 * universityDegreeTitleId: number;
 * universityDegreeFieldId: number;
 * universityDegreeTypeId: number;
 * description: string;
 * grade: number;
 * startYear: number;
 * endYear: number;
 * }} payload
 */
function saveProfileType(profileId, profileType, payload) {
    return {
        type: SAVE_PROFILE_TYPE,
        payload: { profileId, profileType, ...payload },
    };
}

function saveProfileTypeSuccess(payload) {
    return { type: SAVE_PROFILE_TYPE_SUCCESS, payload };
}

function saveProfileTypeFail(error) {
    return { type: SAVE_PROFILE_TYPE_FAIL, error };
}
