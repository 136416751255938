import React, { useEffect, useState } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";
import * as Yup from "yup";

import { AutocompleteService } from "../../../../../candidate/_services";
import {
    AsyncSelect,
    FormInput,
    IntegratedDialog,
    OnboardingButtons,
    OnboardingForm,
    ProfileTypeSelect,
} from "../../../../components";
import { OnboardingActions } from "../../../../_actions";
import { ProfileActions } from "../../../../_actions/ProfileActions";
import {
    PROFILE_TYPE_PROFESSIONAL,
    PROFILE_TYPE_UNIVERSITY,
} from "../../../../_constants/profile.constants";
import { AuthSelectors, UserProfileSelectors } from "../../../../_selectors";
import OnboardingProfilePicture from "../../../../components/onboarding/OnboardingProfilePicture/OnboardingProfilePicture";
import OnboardingSelectors from "../../../../_selectors/OnboardingSelectors";
import { OnboardingImageEdit } from "../OnboardingImageEdit";
import styles from "./OnboardingPersonal.module.scss";
import OnboardingLayout from "../OnboardingLayout/OnboardingLayout";
import FormField from "../../../../components/input/FormField";

const FIELDS = {
    firstName: "firstName",
    lastName: "lastName",
    city: "city",
    profileType: "profileType",
    industry: "industry",
    jobRole: "jobRole",
    degreeField: "degreeField",
    degreeTitle: "degreeTitle",
    image: "image",
};

export default function OnboardingPersonal() {
    const dispatch = useDispatch();

    const {
        isSigned,
        profileId,
        uuid,
        firstName,
        lastName,
        city,
        profileType,
        jobRole,
        image,
        degreeField,
        degreeTitle,
    } = useSelector(mapState);

    const data = {
        [FIELDS.firstName]: firstName,
        [FIELDS.lastName]: lastName,
        [FIELDS.city]: city,
        [FIELDS.profileType]: profileType,
        [FIELDS.industry]: null,
        [FIELDS.jobRole]: jobRole,
        [FIELDS.degreeField]: degreeField,
        [FIELDS.degreeTitle]: degreeTitle,
        [FIELDS.image]: image,
    };

    useEffect(() => {
        if (isSigned && profileId) {
            dispatch(ProfileActions.getProfileType(profileId));
        }
    }, [isSigned, profileId, dispatch]);

    return (
        <OnboardingLayout
            imageProps={{
                src: "/img/onboarding/personal.svg",
            }}
            color="yellow"
        >
            <Formik
                initialValues={data}
                onSubmit={submit}
                validationSchema={getValidation}
                validateOnMount
                enableReinitialize
            >
                <Content image={image} />
            </Formik>
        </OnboardingLayout>
    );

    function submit(values) {
        const first = values[FIELDS.firstName];
        const last = values[FIELDS.lastName];
        const type = values[FIELDS.profileType];
        const city = values[FIELDS.city];
        const jobRole = values[FIELDS.jobRole];
        const degreeField = values[FIELDS.degreeField];
        const degreeTitle = values[FIELDS.degreeTitle];

        dispatch(
            OnboardingActions.savePersonal(
                uuid,
                first,
                last,
                type,
                city.placeId || city.id,
                jobRole && jobRole.id,
                degreeField && degreeField.id,
                degreeTitle && degreeTitle.id
            )
        );
    }

    function getValidation() {
        return Yup.object().shape({
            [FIELDS.firstName]: Yup.string().required().nullable(),
            [FIELDS.lastName]: Yup.string().required().nullable(),
            [FIELDS.city]: Yup.object().nullable().required(),
            [FIELDS.image]: Yup.string().nullable(),
            [FIELDS.profileType]: Yup.string().nullable().required(),
            [FIELDS.jobRole]: Yup.object()
                .nullable()
                .when(FIELDS.profileType, {
                    is: (value) => value === PROFILE_TYPE_PROFESSIONAL,
                    then: Yup.object().required(),
                }),
            [FIELDS.degreeField]: Yup.object()
                .nullable()
                .when(FIELDS.profileType, {
                    is: (value) => value === PROFILE_TYPE_UNIVERSITY,
                    then: Yup.object().required(),
                }),
            [FIELDS.degreeTitle]: Yup.object()
                .nullable()
                .when(FIELDS.profileType, {
                    is: (value) => value === PROFILE_TYPE_UNIVERSITY,
                    then: Yup.object().required(),
                }),
        });
    }
}

function Content(props) {
    const translate = useTranslate("common.onboarding.personal");
    const translateForm = (key) => translate("form." + key);

    const { submitForm } = useFormikContext();

    const [dialogOpen, setDialogOpen] = useState();

    const [{ value }, { error, touched }] = useField(FIELDS.image);

    return (
        <>
            {dialogOpen && (
                <IntegratedDialog>
                    <OnboardingImageEdit
                        originalImageUrl={props.image}
                        onClose={closeImageEdit}
                    />
                </IntegratedDialog>
            )}
            <OnboardingForm>
                <div className={styles.title}>
                    <OnboardingProfilePicture
                        onClick={openImageEdit}
                        image={value}
                        hasError={!!error && touched}
                    />
                    <h1 className="font-light">{translate("title")}</h1>
                </div>
                <h6 className="font-book">{translate("subtitle")}</h6>
                <InnerForm translate={translateForm} />
            </OnboardingForm>
            <OnboardingButtons>
                <OnboardingButtons.Next onClick={submitForm} />
            </OnboardingButtons>
        </>
    );

    function openImageEdit() {
        setDialogOpen(true);
    }

    function closeImageEdit() {
        setDialogOpen(false);
    }
}

function InnerForm(props) {
    const { translate } = props;
    const dispatch = useDispatch();

    const [{ value }, , { setValue }] = useField(FIELDS.profileType);

    return (
        <Form>
            <div className={styles.credentials}>
                <FormField
                    name={FIELDS.firstName}
                    label={translate("firstName.label")}
                    required
                    hideError
                >
                    <FormInput
                        name={FIELDS.firstName}
                        type="text"
                        placeholder={translate("firstName.placeholder")}
                        onChange={(e) =>
                            changeValue(FIELDS.firstName, e.target.value)
                        }
                    />
                </FormField>
                <FormField
                    name={FIELDS.lastName}
                    label={translate("lastName.label")}
                    required
                    hideError
                >
                    <FormInput
                        name={FIELDS.lastName}
                        type="text"
                        placeholder={translate("lastName.placeholder")}
                        onChange={(e) =>
                            changeValue(FIELDS.lastName, e.target.value)
                        }
                    />
                </FormField>
            </div>
            <CityField />
            <ProfileTypeSelect
                // renderCollege={renderCollege}
                renderUniversity={() => <UniversityField />}
                renderJobRole={() => <JobRoleField />}
                onChange={(value) => {
                    setValue(value);
                    changeValue(FIELDS.profileType, value);
                }}
                value={value}
            />
        </Form>
    );

    function changeValue(field, value) {
        dispatch(OnboardingActions.changePersonalValue(field, value));
    }

    function CityField() {
        const [{ value, onBlur }, { error, touched }, { setValue }] = useField(
            FIELDS.city
        );

        return (
            <FormField
                className={styles["city-field"]}
                label={translate("city.label")}
                required
                hideError
            >
                <AsyncSelect
                    loadOptions={AutocompleteService.cities}
                    value={value}
                    onChange={(value) => {
                        setValue(value);
                        changeValue(FIELDS.city, value);
                    }}
                    hasError={!!error && touched}
                    onBlur={onBlur}
                    getOptionValue={(option) => option.placeId}
                    getOptionLabel={(option) => option.title || option.text}
                    placeholder={translate("city.placeholder")}
                />
            </FormField>
        );
    }

    // function renderCollege() {
    //     const [{ value, onBlur }, _, { setValue }] = useField(FIELDS.industry);

    //     return (
    //         <div>
    //             <AsyncSelect
    //                 loadOptions={AutocompleteService.industries}
    //                 defaultOptions
    //                 getOptionValue={(option) => option.id}
    //                 getOptionLabel={(option) => option.name}
    //                 value={value}
    //                 onChange={setValue}
    //                 onBlur={onBlur}
    //             />
    //         </div>
    //     );
    // }

    function UniversityField() {
        const [degreeFieldProps, degreeFieldMeta, degreeFieldHelpers] =
            useField(FIELDS.degreeField);
        const [degreeTitleProps, degreeTitleMeta, degreeTitleHelpers] =
            useField(FIELDS.degreeTitle);

        return (
            <>
                <FormField
                    label={translate("degreeTitle.label")}
                    required
                    hideError
                >
                    <AsyncSelect
                        loadOptions={AutocompleteService.degreeTitles}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        value={degreeTitleProps.value}
                        onBlur={degreeTitleProps.onBlur}
                        placeholder={translate("degreeTitle.placeholder")}
                        hasError={
                            degreeFieldMeta.error && degreeFieldMeta.touched
                        }
                        onChange={(value) => {
                            degreeTitleHelpers.setValue(value);
                            changeValue(FIELDS.degreeTitle, value);
                        }}
                    />
                </FormField>
                <FormField
                    label={translate("degreeField.label")}
                    required
                    hideError
                >
                    <AsyncSelect
                        loadOptions={AutocompleteService.degreeFields}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        value={degreeFieldProps.value}
                        onBlur={degreeFieldProps.onBlur}
                        placeholder={translate("degreeField.placeholder")}
                        hasError={
                            degreeTitleMeta.error && degreeTitleMeta.touched
                        }
                        onChange={(value) => {
                            degreeFieldHelpers.setValue(value);
                            changeValue(FIELDS.degreeField, value);
                        }}
                    />
                </FormField>
            </>
        );
    }

    function JobRoleField() {
        const [{ value, onBlur }, { error, touched }, { setValue }] = useField(
            FIELDS.jobRole
        );

        return (
            <FormField label={translate("jobRole.label")} required hideError>
                <AsyncSelect
                    loadOptions={AutocompleteService.jobRoles}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    value={value}
                    onChange={(value) => {
                        setValue(value);
                        changeValue(FIELDS.jobRole, value);
                    }}
                    onBlur={onBlur}
                    placeholder={translate("jobRole.placeholder")}
                    hasError={error && touched}
                />
            </FormField>
        );
    }
}

function mapState(state) {
    const {
        firstName,
        lastName,
        city,
        jobRole,
        profileType,
        degreeField,
        degreeTitle,
    } = OnboardingSelectors.getPersonal(state);
    return {
        isSigned: AuthSelectors.isSigned(state),
        profileId: AuthSelectors.getProfileId(state),
        uuid: AuthSelectors.getUUID(state),
        firstName,
        lastName,
        city,
        jobRole,
        profileType,
        image: UserProfileSelectors.getImage(state),
        degreeField,
        degreeTitle,
    };
}
