export function resolveFit(fit) {
    if (fit >= 0.75) {
        return { message: 'job-match.excellent', color: '#00C162' }; //#00CF3C #1FD1A1 #2DD58A #00C162
    } else if (fit >= 0.5) {
        return { message: 'job-match.very-good', color: '#00C162' };
    } else if (fit >= 0.25) {
        return { message: 'job-match.average', color: '#FCF84D' };
    }
    return { message: 'job-match.bad', color: '#fe3464' };
}
