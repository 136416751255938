import {
    useRef,
    useEffect,
    PropsWithChildren,
    useState,
    HTMLAttributes,
} from "react";
import { cx } from "@emotion/css";

import styles from "./OptionsMenu.module.scss";
import { SVGIcon, SVGIconProps } from "..";

interface OptionsMenuProps
    extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {}

export default function OptionsMenu(props: OptionsMenuProps) {
    const { children, ...rest } = props;

    const [isActive, setIsActive] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        document.addEventListener("click", close);

        return () => document.removeEventListener("click", close);
    }, []);

    return (
        <div {...rest}>
            <div
                ref={ref}
                className={cx(styles.button, isActive && styles.button__active)}
                onClick={toggle}
            >
                <div />
            </div>
            <div
                className={cx(styles.menu, isActive && styles.menu__active)}
                onClick={(ev) => ev.stopPropagation()}
            >
                <ul>{children}</ul>
            </div>
        </div>
    );

    function toggle() {
        setIsActive((prev) => !prev);
    }

    function close(ev: MouseEvent) {
        const target = ev.target;

        if (target === ref.current || target === ref.current?.firstChild) {
            return;
        }

        setIsActive(false);
    }
}

interface OptionProps extends HTMLAttributes<HTMLLIElement> {
    iconProps?: SVGIconProps;
}

function Option(props: PropsWithChildren<OptionProps>) {
    const { children, iconProps, ...rest } = props;
    return (
        <li className={styles.option} {...rest}>
            <button>
                {iconProps && <SVGIcon {...iconProps} />}
                <span>{children}</span>
            </button>
        </li>
    );
}

OptionsMenu.Option = Option;
