import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { BaseRoute } from "../routes/BaseRoute";
import {
    HERO_ONBOARDING_PATH,
    NOT_ALLOWED_PATH,
} from "../../_constants/route.constants";
import { AuthSelectors } from "../../_selectors/AuthSelectors";
import { PROFILE_PATH_INFO } from "../../../candidate/_constants/route.constants";

export function OnboardingRoute(props) {
    const { path, children, ...rest } = props;

    const history = useHistory();
    const from = useLocation();
    const pathname = from.pathname;

    const { isSigned, user, userStatus, isActive, isHero, isAdmin } =
        useSelector(mapState);

    useEffect(() => {
        if (!isSigned) {
            history.push(NOT_ALLOWED_PATH, { from });
        } else if (isHero) {
            history.push(HERO_ONBOARDING_PATH, { from });
        } else if (isActive) {
            history.push(PROFILE_PATH_INFO, { from });
        }
    }, [isActive, isSigned, isHero]);

    return (
        <BaseRoute path={path} {...rest}>
            {children}
        </BaseRoute>
    );
}

function mapState(state) {
    return {
        user: AuthSelectors.getUser(state),
        isSigned: AuthSelectors.isSigned(state),
        isAdmin: AuthSelectors.isAdmin(state),
        isActive: AuthSelectors.isActive(state),
        isHero: AuthSelectors.isHero(state),
        userStatus: AuthSelectors.userStatus(state),
    };
}
