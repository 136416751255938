import { useSelector } from "react-redux";
import { formatDate } from "../../_helpers";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";

type FormatedDateProps = {
    date?: string;
    format?: string;
    placeholder?: string;
};

export function FormatedDate(props: FormatedDateProps) {
    const { date, format, placeholder = "" } = props;

    const locale = useSelector(LanguageSelectors.getLocale);

    if (!date) {
        return <>{placeholder}</>;
    }

    return <>{formatDate(date, format, locale)}</>;
}
