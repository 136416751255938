import Header from "../../../../common/components/nav/Header/Header";
import { useSelector } from "react-redux";
import { AuthSelectors } from "../../../../common/_selectors";

export default function CandidateHeader(props: { className?: string }) {
    const { className } = props;
    const email = useSelector(AuthSelectors.getUserEmail);

    return (
        <Header
            className={className}
            showMainMenu
            showNotifications
            menuContent={<MenuContent email={email} />}
        />
    );
}

function MenuContent(props: { email: string }) {
    return (
        <div id="admin-main-menu">
            <div id="admin-main-menu--profile--text">
                <div>
                    <strong className="font-11">{props.email}</strong>
                </div>
            </div>
        </div>
    );
}
