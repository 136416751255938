import { OrderedMap } from 'immutable';

import {
    GET_CANDIDATE_CARDS_SAVED_SUCCESS,
    SET_FAVOURTIE_CANDIDATE_SUCCESS,
    UNSET_FAVOURTIE_CANDIDATE_SUCCESS,
} from '../../../_constants/actions/candidates.constants';

export default function (state = OrderedMap(), action) {
    switch (action.type) {
        case GET_CANDIDATE_CARDS_SAVED_SUCCESS:
            return OrderedMap(
                action.payload.content.map((item) => [item.profile.id, item])
            );
        case SET_FAVOURTIE_CANDIDATE_SUCCESS:
            return setFavourite(state, action.payload);
        case UNSET_FAVOURTIE_CANDIDATE_SUCCESS:
            return unsetFavourite(state, action.payload);
        default:
            return state;
    }
}

function setFavourite(state, { candidate, profileId }) {
    const newCandidate = Object.assign({}, candidate, {
        isFavourite: true,
    });

    return state.set(profileId, newCandidate);
}

function unsetFavourite(state, { profileId }) {
    return state.remove(profileId);
}
