import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExperienceItem } from './ExperienceItem';
import { resolveLogoUrl } from '../../../../common/_helpers';
import { ProfileInformationActions } from '../../../../candidate/_actions/ProfileInformationActions';
import { AuthSelectors } from '../../../_selectors';

export function EducationItem({ item, onEdit, displayOnly }) {
    const actions = mapActions(useDispatch());
    const profileId = useSelector(AuthSelectors.getProfileId);

    const {
        id,
        university,
        universityDegreeField,
        startYear,
        endYear,
        description,
        locations,
    } = item;
    const logoUrl = resolveLogoUrl(university.logoUrl, university.url);
    const location =
        locations && locations.length > 0 ? locations[0].text : null;

    const props = {
        logoUrl,
        title: university.name,
        name: universityDegreeField.name,
        started: startYear.toString(10),
        ended: endYear.toString(10),
        dateFormat: 'YYYY',
        description,
        location,
        onEdit,
        onDelete: deleteItem,
        displayOnly,
        translatePrefix: 'candidate.profile.delete.education',
    };

    return <ExperienceItem {...props} />;

    function deleteItem() {
        actions.deleteItem(id, profileId);
    }
}

function mapActions(dispatch) {
    return {
        deleteItem: (id, profileId) =>
            dispatch(ProfileInformationActions.deleteEducation(id, profileId)),
    };
}
