import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { ObjectSchema, StringSchema } from "yup";

import Field from "../../../../common/components/input/Field/Field";
import FormCheckbox from "../../../../common/components/input/FormCheckbox";
import FormDateInput from "../../../../common/components/input/FormDateInput";
import FormInput from "../../../../common/components/input/FormInput";
import FormSelect from "../../../../common/components/input/FormSelect";
import FormTextArea from "../../../../common/components/input/FormTextArea";
import LocationsEdit from "../../../../common/components/LocationsEdit/LocationsEdit";
import IAddress from "../../../../common/types/IAddress";
import ISelectType from "../../../../common/types/ISelectType";
import AdminJobActions from "../../../_actions/AdminJobActions";
import AdminJobSelectors from "../../../_selectors/AdminJobSelectors";
import AdminEdit from "../../AdminEdit/AdminEdit";
import UploadButton from "./UploadButton";

type DetailsEditPanelProps = {
    jobPostId: number;
};

const TITLE_FIELD = "title";
const TYPE_FIELD = "employmentType";
const DESC_FIELD = "description";
const LOCATION_FIELD = "locations";
const DEADLINE_FIELD = "deadline";
const START_DATE_FIELD = "startDate";
const REMOTE_FIELD = "remote";

type Values = {
    [TITLE_FIELD]: string;
    [TYPE_FIELD]: ISelectType;
    [DESC_FIELD]: string;
    [LOCATION_FIELD]: IAddress[];
    [START_DATE_FIELD]: moment.Moment;
    [DEADLINE_FIELD]: moment.Moment;
    [REMOTE_FIELD]: boolean;
};

export default function DetailsEditPanel(props: DetailsEditPanelProps) {
    const { jobPostId } = props;
    const translate = useTranslate("admin.jobs.edit.details");

    const { data } = AdminJobSelectors.useGetEdit();
    const dispatch = useDispatch();

    const initialValues: Values = {
        title: data?.title,
        employmentType: data?.employmentType,
        description: data?.description || "",
        locations: data?.locations || [],
        startDate: data?.startDate && moment(data.startDate),
        deadline: data?.applicationDate && moment(data.applicationDate),
        remote: data?.remote,
    };

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="details"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={getValidationSchema}
                enableReinitialize
            >
                <Form>
                    <InnerForm />
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function getValidationSchema() {
        return new ObjectSchema({
            [TITLE_FIELD]: new StringSchema().required(),
            [DESC_FIELD]: new StringSchema(),
        });
    }

    function submit(values: Values) {
        const { startDate, deadline, ...rest } = values;
        const payload = {
            id: jobPostId,
            startDate: startDate && moment.utc(startDate).format(),
            applicationDate: deadline && moment.utc(deadline).format(),
            ...rest,
        };
        dispatch(AdminJobActions.editJob(payload));
    }
}

function InnerForm() {
    const { sending } = AdminJobSelectors.useGetEdit();
    const dispatch = useDispatch();
    const translate = useTranslate("admin.jobs.edit.details.form");

    const { data: employmentOptions, loading } =
        AdminJobSelectors.useGetEmploymentTypes();

    useEffect(() => {
        if (!employmentOptions) {
            dispatch(AdminJobActions.getEmploymentTypes());
        }
    }, [dispatch, employmentOptions]);

    return (
        <>
            <Field label={translate("jobTitle.label")} required>
                <FormInput
                    name={TITLE_FIELD}
                    placeholder={translate("jobTitle.placeholder")}
                />
            </Field>
            <Field label={translate("type.label")} required>
                <FormSelect
                    name={TYPE_FIELD}
                    placeholder={translate("type.placeholder")}
                    options={employmentOptions}
                    getOptionLabel={(option) => option.name}
                    loading={loading}
                />
            </Field>
            <Field label={translate("deadline.label")}>
                <FormDateInput name={DEADLINE_FIELD} />
            </Field>
            <Field label={translate("start.label")}>
                <FormDateInput name={START_DATE_FIELD} />
            </Field>
            <Field label={translate("location.label")}>
                <LocationsEdit
                    name={LOCATION_FIELD}
                    placeholder={translate("location.placeholder")}
                    isLoading={false}
                />
            </Field>
            <Field>
                <FormCheckbox
                    name={REMOTE_FIELD}
                    label={translate("remote.label")}
                />
            </Field>
            <Field label={translate("description.label")}>
                <FormTextArea
                    name={DESC_FIELD}
                    placeholder={translate("description.placeholder")}
                />
            </Field>
            <UploadButton sending={sending} />
        </>
    );
}
