import { OrderedMap } from 'immutable';

import {
    GET_CANDIDATE_CARDS_SUCCESS,
    INVITE_CANDIDATE_SUCCESS,
    SET_FAVOURTIE_CANDIDATE_SUCCESS,
    UNSET_FAVOURTIE_CANDIDATE_SUCCESS,
} from '../../../_constants/actions/candidates.constants';

export default function (state = OrderedMap(), action) {
    switch (action.type) {
        case GET_CANDIDATE_CARDS_SUCCESS:
            return OrderedMap(
                action.payload.content.map((item) => [item.profile.id, item])
            );
        case INVITE_CANDIDATE_SUCCESS:
            return invite(state, action.payload);
        case SET_FAVOURTIE_CANDIDATE_SUCCESS:
            return setFavourite(state, action.payload, true);
        case UNSET_FAVOURTIE_CANDIDATE_SUCCESS:
            return setFavourite(state, action.payload, false);
        default:
            return state;
    }
}

function invite(state, { profileId }) {
    const candidate = state.get(profileId);
    const newCandidate = Object.assign({}, candidate, {
        jobApplicationStatus: 'INVITED',
    });

    return state.set(profileId, newCandidate);
}

function setFavourite(state, { profileId }, isFavourite) {
    const candidate = state.get(profileId);
    const newCandidate = Object.assign({}, candidate, {
        isFavourite,
    });

    return state.set(profileId, newCandidate);
}
