import React from 'react';
import { createClass } from 'classname-helper';

import { useDropdown } from '../../hooks/useDropdown';

export function DropdownMenu({
    isOpen,
    children,
    onClose,
    arrowRight,
    isFullscreen,
    className,
    hasBackdrop = false,
    ...rest
}) {
    const menuRef = useDropdown(isOpen, onClose);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div
                ref={(ref) =>
                    hasBackdrop
                        ? (menuRef.current = null)
                        : (menuRef.current = ref)
                }
                className={createClassName(isFullscreen, className)}
                {...rest}
            >
                <i
                    className="dropdown-menu--arrow"
                    style={{ right: arrowRight }}
                />
                <div className="dropdown-menu--close" onClick={onClose}>
                    <img src="/img/icons/close-primary.svg" alt="X" />
                </div>
                <div className="dropdown-menu--content">{children}</div>
            </div>
            {hasBackdrop ? (
                <div className="dropdown-menu__backdrop" onClick={onClose} />
            ) : null}
        </>
    );
}

function createClassName(isFullscreen, className) {
    return createClass({
        'dropdown-menu': true,
        fullscreen: isFullscreen,
        [className]: !!className,
    });
}
