import { createSelector } from 'reselect';

import { ArrayHelper } from '../../common/_helpers/ArrayHelper';

const loading = state => state.admin.jobs.loading;

const list = state => state.admin.jobs.list;

const isLoading = createSelector(loading, list, (loading, list) => {
    return loading && ArrayHelper.isEmpty(list);
});

export const JobSelectors = {
    isLoading
};
