import ProfileInformationConstants from '../_constants/actions/ProfileInformationConstants';

export const ProfileInformationActions = {
    updateAboutMe,

    addEducation,
    updateEducation,
    deleteEducation,

    newJobExperience,
    updateJobExperience,
    deleteJobExperience,

    // saveInterests,
    addInterest,
    updateInterest,
    deleteInterest,

    saveLanguages,
    addLanguage,
    updateLanguage,
    deleteLanguage,
    revertLanguageChanges,

    saveSkills,
    addSkill,
    updateSkill,
    deleteSkill,
    revertSkillChanges,
};

function addEducation(education, profileId) {
    return {
        type: ProfileInformationConstants.ADD_EDUCATION,
        payload: { education, profileId },
    };
}

function updateEducation(education, profileId) {
    return {
        type: ProfileInformationConstants.UPDATE_EDUCATION,
        payload: { education, profileId },
    };
}

function deleteEducation(educationId, profileId) {
    return {
        type: ProfileInformationConstants.DELETE_EDUCATION,
        payload: { educationId, profileId },
    };
}

function newJobExperience(experience, profileId) {
    return {
        type: ProfileInformationConstants.ADD_EXPERIENCE,
        payload: { experience, profileId },
    };
}

function updateJobExperience(experience, id, profileId) {
    return {
        type: ProfileInformationConstants.UPDATE_EXPERIENCE,
        payload: { experience: { ...experience, id }, profileId },
    };
}

function deleteJobExperience(experienceId, profileId) {
    return {
        type: ProfileInformationConstants.DELETE_EXPERIENCE,
        payload: { experienceId, profileId },
    };
}

function updateAboutMe(id, content, profileId) {
    return {
        type: ProfileInformationConstants.UPDATE_ABOUT_ME,
        payload: { id, content, profileId },
    };
}

// function saveInterests(interests) {
//     return {
//         type: ProfileInformationConstants.SAVE_INTERESTS,
//         payload: interests,
//     };
// }

function addInterest(content, profileId) {
    return {
        type: ProfileInformationConstants.ADD_INTEREST,
        payload: { interest: { content }, profileId },
    };
}

function updateInterest(interest, profileId) {
    return {
        type: ProfileInformationConstants.UPDATE_INTEREST,
        payload: { interest, profileId },
    };
}

function deleteInterest(interestId, profileId) {
    return {
        type: ProfileInformationConstants.DELETE_INTEREST,
        payload: { interestId, profileId },
    };
}

function saveLanguages(languages, profileId) {
    return {
        type: ProfileInformationConstants.SAVE_LANGUAGES,
        payload: { languages, profileId },
    };
}

function addLanguage(language) {
    return {
        type: ProfileInformationConstants.ADD_LANGUAGE,
        payload: language,
    };
}

function updateLanguage(language) {
    return {
        type: ProfileInformationConstants.UPDATE_LANGUAGE,
        payload: language,
    };
}

function deleteLanguage(languageId) {
    return {
        type: ProfileInformationConstants.DELETE_LANGUAGE,
        payload: languageId,
    };
}

function revertLanguageChanges(oldLanguages) {
    return {
        type: ProfileInformationConstants.REVERT_LANGUAGE_CHANGES,
        payload: oldLanguages,
    };
}

function saveSkills(skills, profileId) {
    return {
        type: ProfileInformationConstants.SAVE_SKILLS,
        payload: { skills, profileId },
    };
}

function addSkill(skill) {
    return { type: ProfileInformationConstants.ADD_SKILL, payload: skill };
}

function updateSkill(skill) {
    return {
        type: ProfileInformationConstants.UPDATE_SKILL,
        payload: skill,
    };
}

function deleteSkill(skillId) {
    return { type: ProfileInformationConstants.DELETE_SKILL, payload: skillId };
}

function revertSkillChanges(oldSkills) {
    return {
        type: ProfileInformationConstants.REVERT_SKILL_CHANGES,
        payload: oldSkills,
    };
}
