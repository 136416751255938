import { Fragment } from "react";
import { useTranslate } from "react-translate.ts";

const TOKEN_LENGTH = 6;

export function TokenValidator({ onValidation, className, autoValidate }) {
    const translate = useTranslate("common.auth.verification.input");

    return (
        <Fragment>
            <label>{translate("label")}</label>
            <input
                type="text"
                className={`input ${className}`}
                onChange={onChange}
                placeholder={translate("placeholder")}
            />
        </Fragment>
    );

    function onChange(event) {
        const value = event.target.value.trim();

        if (autoValidate && value.length === TOKEN_LENGTH) {
            onValidation(value);
        }
    }
}
