import { GET_DECLINED_CARDS_SUCCESS } from '../../../_constants/actions/candidateTracking.constants';

export default function(state = [], action) {
    switch (action.type) {
        case GET_DECLINED_CARDS_SUCCESS:
            return action.payload || state;
        default:
            return state;
    }
}
