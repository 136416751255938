import { useDispatch } from "react-redux";
import { Translate, useTranslate } from "react-translate.ts";

import AdminEdit from "../AdminEdit/AdminEdit";
import LocationsEditPanel from "../AdminEdit/parts/LocationsEditPanel";
import BenefitsEditPanel from "../AdminEdit/parts/BenefitsEditPanel";
import Gallery from "./parts/Gallery";
import Overview from "./parts/Overview";
import Video from "./parts/Video";
import ICompanySize from "../../../common/types/ICompanySize";
import IIndustry from "../../../common/types/IIndustry";
import ILocation from "../../../common/types/ILocation";
import AdminProfileEditActions from "../../_actions/AdminProfileEditActions";
import IImage from "../../../common/types/IImage";
import IUploadState from "../../../common/types/IUploadState";
import { LoadingOverlay } from "../../../common/components";
import IBenefit from "../../../common/types/IBenefit";
import CompanyProfileActions from "../../../common/_actions/CompanyProfileActions";
import CompanyProfileSelectors from "../../../common/_selectors/CompanyProfileSelectors";

type MediaProps = {
    progress: number;
    url: string | null;
    fileName: string | null;
    uploading: boolean;
};

type AdminProfileEditProps = {
    companyId: number;
    name: string;
    description: string;
    url: string;
    size: ICompanySize;
    industries: IIndustry[];
    locations: ILocation[];
    isLoading?: boolean;
    video: MediaProps;
    images: IImage[];
    imageUploadState: IUploadState[];
    benefits: IBenefit[];
    founded: string;
    headquarters: ILocation;
    videoUrl: string;
    published: boolean;
    saving: boolean;
};

export default function AdminProfileEdit(props: AdminProfileEditProps) {
    const {
        companyId,
        name,
        description,
        url,
        size,
        industries,
        locations,
        isLoading,
        video,
        images,
        imageUploadState,
        benefits,
        founded,
        headquarters,
        videoUrl,
        published,
        saving,
    } = props;
    const dispatch = useDispatch();
    const pending = CompanyProfileSelectors.useGetPending();
    const translate = useTranslate("admin.profile");
    const translateLinks = useTranslate("admin.profile.links");

    const links = [
        { to: "#overview", label: translateLinks("overview"), active: true },
        { to: "#locations", label: translateLinks("locations") },
        { to: "#video", label: translateLinks("video") },
        { to: "#gallery", label: translateLinks("gallery") },
        { to: "#benefits", label: translateLinks("benefits") },
    ];

    return (
        <AdminEdit
            heading={translate("title")}
            buttonProps={{
                disabled: saving || isLoading,
                children: saving ? (
                    <>
                        <Translate textOnly>common.actions.saving</Translate>...
                    </>
                ) : (
                    translate(published ? "unpublish.button" : "publish.button")
                ),
            }}
            links={links}
            previewLink={translateLinks("public")}
            useHashLinks
            onSave={handleSave}
        >
            {isLoading ? (
                <div style={{ height: 600 }}>
                    <LoadingOverlay isLoading={isLoading} isFull />
                </div>
            ) : (
                <>
                    <Overview
                        id={companyId}
                        name={name}
                        description={description}
                        url={url}
                        size={size}
                        industries={industries}
                        founded={founded}
                        headquarters={headquarters}
                        isLoading={isLoading}
                        saving={saving}
                    />
                    <LocationsEditPanel
                        id={companyId}
                        locations={locations}
                        isLoading={isLoading}
                        saving={saving}
                    />
                    <Video
                        onAdd={uploadVideo}
                        onSave={saveVideoUrl}
                        videoUrl={videoUrl}
                        saving={saving}
                        {...video}
                    />
                    <Gallery
                        onAdd={uploadImage}
                        onDelete={deleteImage}
                        images={images}
                        uploadState={imageUploadState}
                    />
                    <BenefitsEditPanel
                        benefits={benefits || []}
                        anchor="benefits"
                        onSubmit={(values) => {
                            dispatch(
                                CompanyProfileActions.saveBenefit({
                                    companyId,
                                    ...values,
                                })
                            );
                        }}
                        onDelete={deleteBenefit}
                        pending={pending.benefits}
                    />
                </>
            )}
        </AdminEdit>
    );

    function handleSave() {
        if (published) {
            unpublish();
        } else {
            publish();
        }
    }

    function publish() {
        dispatch(CompanyProfileActions.publish({ companyId }));
    }

    function unpublish() {
        dispatch(CompanyProfileActions.unpublish({ companyId }));
    }

    function saveVideoUrl(url: string) {
        dispatch(CompanyProfileActions.saveVideo({ companyId, url }));
    }

    function deleteBenefit(id: number) {
        dispatch(CompanyProfileActions.deleteBenefit({ companyId, id }));
    }

    function uploadVideo(file: File) {
        const formData = new FormData();
        formData.append("file", file);
        dispatch(
            AdminProfileEditActions.uploadVideo({
                id: companyId,
                data: formData,
                fileName: file.name,
            })
        );
    }

    function uploadImage(file: File) {
        const formData = new FormData();
        formData.append("file", file);
        dispatch(
            AdminProfileEditActions.uploadImage({
                id: companyId,
                data: formData,
                fileName: file.name,
            })
        );
    }

    function deleteImage(imageId: number) {
        dispatch(
            AdminProfileEditActions.deleteImage({
                companyId: companyId,
                mediaId: imageId,
            })
        );
    }
}
