import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { Flex } from '../../..';
import { Base } from '../Base';
import { Links } from './Links';
import { CompanyProfileRoutes } from './CompanyProfileRoutes';

export function DesktopLayout({ company, onFollow, displayOnly = true }) {
    const translate = useTranslate();
    const { id } = company;

    return (
        <Flex>
            <Flex
                className="w33 padding-right-30"
                alignContent="start"
                wrap="wrap"
            >
                <Base
                    company={company}
                    onFollow={onFollow}
                    translate={translate}
                    displayOnly={displayOnly}
                />
            </Flex>
            <Flex className="w66" direction="column">
                <Links id={id} translate={translate} company={company} />
                <CompanyProfileRoutes
                    company={company}
                    displayOnly={displayOnly}
                />
            </Flex>
        </Flex>
    );
}
