import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClass } from "classname-helper";
import { ProfileActions } from "../../_actions/ProfileActions";
import {
    PROFILE_TYPE_COLLEGE,
    PROFILE_TYPE_PROFESSIONAL,
    PROFILE_TYPE_UNIVERSITY,
} from "../../_constants/profile.constants";
import { SVGIcon } from "../SVGIcon";
import { AuthSelectors, UserProfileSelectors } from "../../_selectors";

const SCHOOL_IMAGE = "school";
const UNI_IMAGE = "uni";
const PROFI_IMAGE = "profi";

export function ProfileTypeSelect(props) {
    const {
        onSubmit,
        renderCollege,
        renderUniversity,
        renderJobRole,
        onChange,
        value,
    } = props;

    const { profileId, profileType } = useSelector(mapState);

    const [selected, setSelected] = useState(profileType && profileType.type);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!profileId) {
            return;
        }

        if (!profileType) {
            dispatch(ProfileActions.getProfileType(profileId));
        }
    }, [dispatch, profileId, profileType]);

    return (
        <div className="profile-type-select">
            <div className="options">
                {!!renderCollege ? (
                    <Option
                        name="College Student"
                        value={PROFILE_TYPE_COLLEGE}
                        selected={value || selected}
                        onSelect={handleChange}
                        icon={SCHOOL_IMAGE}
                        className={SCHOOL_IMAGE}
                    />
                ) : null}
                <Option
                    name="University Student"
                    value={PROFILE_TYPE_UNIVERSITY}
                    selected={value || selected}
                    onSelect={handleChange}
                    icon={UNI_IMAGE}
                    className={UNI_IMAGE}
                />
                <Option
                    name="Experienced"
                    value={PROFILE_TYPE_PROFESSIONAL}
                    selected={value || selected}
                    onSelect={handleChange}
                    icon={PROFI_IMAGE}
                    className={PROFI_IMAGE}
                />
            </div>
            <OptionSwitch
                selected={value || selected}
                onSubmit={onSubmit}
                profileType={profileType}
            />
        </div>
    );

    function handleChange(option) {
        setSelected(option);

        onChange && onChange(option);
    }

    function OptionSwitch(props) {
        const { selected, profileType } = props;

        switch (selected) {
            case PROFILE_TYPE_COLLEGE:
                return renderCollege && renderCollege(profileType);
            case PROFILE_TYPE_UNIVERSITY:
                return renderUniversity && renderUniversity(profileType);
            case PROFILE_TYPE_PROFESSIONAL:
                return renderJobRole && renderJobRole(profileType);
            default:
                return null;
        }
    }
}

function Option(props) {
    const { icon, name, selected, onSelect, value, className } = props;

    return (
        <div
            className={createClass({
                option: true,
                selected: selected === value,
                "font-book": true,
            })}
            onClick={() => onSelect(value)}
        >
            <div className="check">
                <SVGIcon name="check" />
            </div>
            <div className={`image ${className}`}>
                <img src={"/img/career-paths/" + icon + ".svg"} />
            </div>
            <div>{name}</div>
        </div>
    );
}

function mapState(state) {
    return {
        profileType: UserProfileSelectors.getType(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
