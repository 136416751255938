import { jobRoleConstants } from '../../_constants';

const initialState = { isLoading: false, isLoaded: false };
export default function jobRole(state = initialState, action) {
    switch (action.type) {
        case jobRoleConstants.JOB_ROLE_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case jobRoleConstants.JOB_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        case jobRoleConstants.JOB_ROLE_ERROR:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}
