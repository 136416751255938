import { useState } from 'react';

export function useSwitch(initial = false) {
    const [isOn, setIsOn] = useState(initial);

    return [
        isOn,
        () => setIsOn(true), // on
        () => setIsOn(false), // off
        () => setIsOn(!isOn) // toggle
    ];
}
