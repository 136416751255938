import React from "react";
import { NavLink } from "react-router-dom";
import { TooltipLabel } from "../../TooltipLabel";
import styles from "./SideBar.module.scss";

export type SideBarProps = {
    links: SideBarLink[];
};

type SideBarLink = {
    to: string;
    label: string;
    icon?: string;
    size?: number;
};

export default function SideBar(props: SideBarProps) {
    const { links } = props;
    return (
        <div className={styles.sidebar}>
            {/*<img src="/img/logo_simple.svg" alt="" />*/}
            <div className={styles.links}>
                {links.map((link, index) => {
                    return <Link key={index} link={link} />;
                })}
            </div>
        </div>
    );
}

type LinkProps = {
    link: SideBarLink;
};

function Link(props: LinkProps) {
    const {
        link: { to, icon, label, size },
    } = props;
    return (
        <TooltipLabel label={label} anchor={{ x: "right", y: "middle" }}>
            <div className={styles.link}>
                <NavLink to={to} activeClassName={styles.active}>
                    <div className={styles.link__inner}>
                        <div className={styles.link__icon}>
                            <i
                                className={`icon-${icon}`}
                                style={{ fontSize: size }}
                            />
                        </div>
                        <div>{label}</div>
                    </div>
                </NavLink>
            </div>
        </TooltipLabel>
    );
}
