import { CSSObjectWithLabel, GroupBase } from "react-select";
import Async, { AsyncProps } from "react-select/async";
import { StylesProps } from "react-select/dist/declarations/src/styles";

import { useReactSelectTheme } from "../../hooks";
import { BORDER_COLOR, PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from "../../_constants/theme.constants";

export interface AsyncSelectProps<Option, IsMulti extends boolean>
    extends AsyncProps<Option, IsMulti, GroupBase<Option>> {
    forwardRef?: any;
    hasError?: boolean;
    noBorders?: boolean;
    transparent?: boolean;
    noDropdownIndicator?: boolean;
    customStyles?: {
        [K in keyof StylesProps<
            Option,
            IsMulti,
            GroupBase<Option>
        >]?: CSSObjectWithLabel;
    };
}

export function AsyncSelect<Option, IsMulti extends boolean>(
    props: AsyncSelectProps<Option, IsMulti>
) {
    const {
        defaultOptions,
        noOptionsMessage,
        customStyles = {},
        forwardRef,
        hasError,
        noDropdownIndicator = false,
        isSearchable = true,
        ...rest
    } = props;
    const theme = useReactSelectTheme();
    
    return (
        <Async
            ref={forwardRef}
            theme={theme}
            styles={{
                container: () => ({ width: "100%" }),
                control: (style, state) => ({
                    ...style,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: state.isFocused
                        ? `${PRIMARY_COLOR} !important`
                        : BORDER_COLOR,
                    minHeight: 0,
                    boxShadow: "none",
                    transition: "border-color 0.08s ease",
                    "&:hover": {
                        borderColor: "#bfbfbf",
                    },
                    cursor: !isSearchable ? "pointer" : "text",
                    borderRadius: 0,
                    padding: "16px 20px",
                }),
                valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                }),
                input: (style) => ({
                    ...style,
                    margin: 0,
                    padding: 0,
                }),
                placeholder: (style) => ({
                    ...style,
                    margin: 0,
                    color: SECONDARY_TEXT_COLOR,
                    opacity: 1
                }),
                indicatorSeparator: () => ({ display: "none" }),
                indicatorsContainer: (style) => ({
                    ...style,
                    "& > div": { padding: 0 },
                    "& svg": { color: PRIMARY_COLOR },
                }),
                singleValue: (style) => ({ ...style, margin: 0 }),
            }}
            noOptionsMessage={noOptionsMessage ? noOptionsMessage : () => null}
            components={
                !defaultOptions || noDropdownIndicator
                    ? {
                          DropdownIndicator: () => null,
                      }
                    : null
            }
            defaultOptions={defaultOptions}
            isSearchable={isSearchable}
            {...rest}
        />
    );
}
