import { useWindowState } from '../misc/WindowStateProvider';

/**
 *
 * @param {{renderMobile: () => ReactElement, renderDesktop?: () => ReactElement}} props
 */
export function ResponsiveContainer({
    breakPoint = 767,
    renderMobile,
    renderDesktop,
    children
}) {
    const { width } = useWindowState();
    return width <= breakPoint
        ? renderMobile && renderMobile()
        : (renderDesktop && renderDesktop()) || children;
}
