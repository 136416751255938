import List from "../../../../common/components/list/List/List";
import ITeamMember from "../../../../common/types/ITeamMember";
import AdminTeamListItem from "../AdminTeamListItem/AdminTeamListItem";

interface AdminTeamListProps {
    members: ITeamMember[];
    isLoading: boolean;
    updating: number[];
    onDelete: (member: ITeamMember) => void;
}

export default function AdminTeamList(props: AdminTeamListProps) {
    const { members, isLoading, updating, onDelete } = props;

    return (
        <List>
            {isLoading && members.length <= 0
                ? Array.from(new Array(5), (_, i) => (
                      <AdminTeamListItem key={i} isLoading />
                  ))
                : members.map((item) => (
                      <AdminTeamListItem
                          key={item.id}
                          member={item}
                          isLoading={isLoading || updating.includes(item.id)}
                          onDelete={onDelete}
                      />
                  ))}
        </List>
    );
}
