import { cx } from "@emotion/css";
import { PropsWithChildren } from "react";
import styles from "./List.module.scss";

type ListProps = {
    className?: string;
};

export default function List(props: PropsWithChildren<ListProps>) {
    const { children, className } = props;

    return <div className={cx(styles.list, className)}>{children}</div>;
}
