import { Formik, Form, useFormikContext } from "formik";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
    Checkbox,
    FormInput,
    OnboardingButtons,
    OnboardingForm,
} from "../../../components";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import HeroOnboardingSelectors from "../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { HeroBackButton } from "../components/HeroBackButton";
import OnboardingLayout from "../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";

const FIELDS = {
    positions: "positions",
    positions2: "positions2",
    positions3: "positions3",
};

export function HeroPositions() {
    const dispatch = useDispatch();

    const { data } = useSelector(mapState);

    return (
        <OnboardingLayout
            color="yellow"
            imageProps={{ src: "/img/heroes/onboard/positions.svg" }}
        >
            <Formik
                initialValues={data}
                onSubmit={submit}
                validationSchema={getValidation}
                validateOnMount
                enableReinitialize
            >
                <Content />
            </Formik>
        </OnboardingLayout>
    );

    function submit(values) {
        const positions = values[FIELDS.positions];
        const positions2 = values[FIELDS.positions2];
        const positions3 = values[FIELDS.positions3];

        dispatch(
            HeroOnboardingActions.Positions.saveData(
                positions,
                positions2,
                positions3
            )
        );
    }

    function getValidation() {
        return Yup.object().shape({
            positions: Yup.string().required(),
        });
    }
}

function mapState(state) {
    return {
        data: HeroOnboardingSelectors.Positions.getData(state),
        saved: HeroOnboardingSelectors.Positions.isSaved(state),
    };
}

function Content() {
    const translate = useTranslate("common.heroOnboarding.steps.positions");

    const { submitForm, isValid } = useFormikContext();

    return (
        <>
            <OnboardingForm>
                <h1 className="font-light">{translate("title")}</h1>
                <InnerForm />
            </OnboardingForm>
            <OnboardingButtons>
                <HeroBackButton />
                <OnboardingButtons.Next
                    onClick={submitForm}
                    disabled={!isValid}
                />
            </OnboardingButtons>
        </>
    );
}

function InnerForm(props) {
    const translate = useTranslate(
        "common.heroOnboarding.steps.positions.form"
    );

    const moreOptions = useSelector(
        HeroOnboardingSelectors.Positions.hasMoreOptions
    );

    const dispatch = useDispatch();

    const checkMoreOptions = (value) =>
        dispatch(HeroOnboardingActions.Positions.setMoreOptions(value));

    return (
        <Form>
            <FormInput
                name={FIELDS.positions}
                type="text"
                required
                placeholder={translate("positions.placeholder")}
                label={translate("positions.label")}
            />
            <Checkbox onChange={checkMoreOptions} isChecked={moreOptions}>
                {translate("moreOptions.label")}
            </Checkbox>
            {moreOptions ? (
                <>
                    <FormInput
                        name={FIELDS.positions2}
                        type="text"
                        placeholder={translate("positions2.placeholder")}
                        label={translate("positions2.label")}
                    />
                    <FormInput
                        name={FIELDS.positions3}
                        type="text"
                        placeholder={translate("positions3.placeholder")}
                        label={translate("positions3.label")}
                    />
                </>
            ) : null}
        </Form>
    );
}
