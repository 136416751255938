import { useEffect } from "react";
import { Link, useRouteMatch, generatePath, useParams } from "react-router-dom";
import usePage from "../../../hooks/usePage";

import { SVGIcon } from "../../SVGIcon";
import styles from "./Pagination.module.scss";

type BaseProps = {
    pathArgs?: { [key: string]: any };
    path?: string;
};

type PaginationProps = {
    pages: number;
    maxPages?: number;
} & BaseProps;

export default function Pagination(props: PaginationProps) {
    const { pages, maxPages = 5, pathArgs = {}, path } = props;
    const page = usePage();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    useEffect(() => {
        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = "manual";
        }

        return () => {
            if (window.history.scrollRestoration) {
                window.history.scrollRestoration = "auto";
            }
        };
    }, []);

    const centerPage = Math.floor(maxPages / 2);
    const match = useRouteMatch();

    const currentPages = pages < maxPages ? pages : maxPages;
    if (pages <= 1) {
        return null;
    }

    return (
        <div className={styles.pagination}>
            {page > 1 && (
                <Link
                    to={generatePath(path || match.path, {
                        page: page - 1,
                        ...pathArgs,
                    })}
                    className={styles.arrow}
                >
                    <SVGIcon name="chevron-left" />
                </Link>
            )}
            <ul>
                {Array.from(new Array(currentPages + 1), (_, index) => {
                    if (index === 0) {
                        return null;
                    }

                    let key = index;
                    if (pages > maxPages && page > centerPage) {
                        key += page - 1 - centerPage;
                    }

                    return <Step key={key} index={key} page={page} path={path} pathArgs={pathArgs} />;
                })}
            </ul>
            {page < pages && (
                <Link
                    to={generatePath(path || match.path, {
                        page: page + 1,
                        ...pathArgs,
                    })}
                    className={styles.arrow}
                >
                    <SVGIcon name="chevron-right" />
                </Link>
            )}
        </div>
    );
}

type StepProps = {
    index: number;
    page: number;
} & BaseProps;

function Step(props: StepProps) {
    const match = useRouteMatch();
    const { page, index, pathArgs = {}, path } = props;

    const isActive = page === index;

    return (
        <li className={isActive && styles.active}>
            <Link to={generatePath(path || match.path, { page: index, ...pathArgs })}>
                {index}
            </Link>
        </li>
    );
}
