import { createSelector } from "reselect";
import {
    ASYNC_STATE_LOADING,
    ASYNC_STATE_FAIL,
} from "../_constants/async.constants";

const detail = (state) => state.candidate.jobs.detail;

const getData = createSelector(detail, ({ data }) => data);
const getState = createSelector(detail, ({ state }) => state);

const isCurrent = (state, jobPostId) =>
    state.candidate.jobs.detail.data.id === parseInt(jobPostId, 10);

const isLoading = createSelector(
    isCurrent,
    getState,
    (isCurrent, currentState) =>
        !isCurrent && currentState === ASYNC_STATE_LOADING
);

const hasFailed = createSelector(
    getState,
    (currentState) => currentState === ASYNC_STATE_FAIL
);

const getApplicationStatus = createSelector(
    detail,
    ({ applicationStatus }) => applicationStatus
);

const hasApplied = createSelector(
    getApplicationStatus,
    (status) => status === "APPLIED"
);

const hasShortlisted = createSelector(
    getApplicationStatus,
    (status) => status === "SHORTLISTED"
);

const isInvited = createSelector(
    getApplicationStatus,
    (status) => status === "INVITED"
);

const hasNotApplied = createSelector(
    getApplicationStatus,
    (status) => status === "NOT_APPLIED"
);

const getApplicationState = createSelector(
    detail,
    (detail) => detail.applicationState
);

const isApplicationLoading = createSelector(
    detail,
    ({ applicationLoading }) => applicationLoading
);

export const JobDetailSelectors = {
    getData,
    hasApplied,
    hasShortlisted,
    isInvited,
    isLoading,
    hasFailed,
    isApplicationLoading,
    hasNotApplied,
    getApplicationStatus,
    getApplicationState,
};
