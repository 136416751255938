import React, { MutableRefObject } from "react";
import { cx } from "@emotion/css";

import styles from "./Input.module.scss";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    forwardRef?: MutableRefObject<HTMLInputElement | null>;
}

export default function Input(props: InputProps) {
    const {
        error,
        forwardRef,
        className,
        type = "text",
        onChange,
        ...rest
    } = props;
    const isNumber = type === "number";
    return (
        <input
            type={isNumber ? "text" : type}
            ref={forwardRef}
            className={cx(
                styles.input,
                error && styles.input__error,
                className
            )}
            inputMode={isNumber ? "numeric" : undefined}
            pattern={isNumber ? "[0-9]*" : undefined}
            onChange={handleChange}
            {...rest}
        />
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        if (isNumber) {
            const numeric = value.replace(/\D/g, "");
            event.target.value = numeric && parseInt(numeric, 10).toString();
        }

        onChange && onChange(event);
    }
}
