import React from 'react';
import { createClass } from 'classname-helper';

import { useDropdown } from '../../hooks';

export function FilterDropdown({ children, label, isOpen, close, toggle }) {
    const ref = useDropdown(isOpen, close);

    return (
        <div
            className={createClass({ 'filter-dropdown': true, open: isOpen })}
            ref={ref}
            onClick={toggle}
        >
            <span>{label}</span>
            <i className="filter-dropdown--icon" />
            <div
                className="filter-dropdown--content"
                onClick={(event) => event.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
}
