import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SoftSkillsSelectors from '../../../_selectors/SoftSkillsSelectors';
import { AuthSelectors } from '../../../../common/_selectors/AuthSelectors';
import { ComparisonChart } from '../../../../common/components/charts/ComparisonChart';
import SoftSkillsActions from '../../../_actions/SoftSkillsActions';
import { useTranslate } from 'react-translate.ts';
import { BackButton, BasePageLayout } from '../../../../common/components';
import { SOFT_SKILL_TEST_PATH } from '../../../../common/_constants/route.constants';
import { useRenderToHeader } from '../../../../common/hooks/useRenderToHeader';

export function SkillsComparison(props) {
    const { name } = props;
    const { profileId, comparison } = useSelector((state) =>
        mapState(state, name)
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId && name) {
            dispatch(SoftSkillsActions.getComparison(profileId, name));
        }
    }, [dispatch, profileId]);

    const translate = useTranslate('candidate.softSkillsTest.compare');

    const renderToHeader = useRenderToHeader();

    const backButton = renderToHeader(
        <BackButton link={SOFT_SKILL_TEST_PATH + '/' + name} />
    );

    if (!comparison) {
        return backButton; // TODO LOADING
    }

    return (
        <>
            {backButton}
            <BasePageLayout
                heading={translate('heading')}
                // withShareButton={false}
            >
                <ComparisonChart data={comparison} />
            </BasePageLayout>
        </>
    );
}

function mapState(state, name) {
    return {
        comparison: SoftSkillsSelectors.getComparison(name)(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
