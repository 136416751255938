import React from "react";
import { Translate } from "react-translate.ts";

import {
    SoftSkillsMatchChart,
    JobMatch,
    ProfileItemCard,
} from "../../../common/components";

export function SoftSkills({ fit, match }: any) {
    return (
        <ProfileItemCard
            label="candidate.profile.softSkills.title"
            extraContent={<ExtraContent fit={fit} />}
        >
            {/*// @ts-ignore*/}
            <SoftSkillsMatchChart
                skills={match?.skillScores?.softSkills?.mop}
            />
            {/*// @ts-ignore*/}
            <SoftSkillsMatchChart
                skills={match?.skillScores?.softSkills?.kako}
            />
        </ProfileItemCard>
    );
}

function ExtraContent({ fit }: { fit: number }) {
    return (
        <JobMatch
            fit={fit}
            label={<Translate>common.jobs.match.softSkills</Translate>}
            size="small"
        />
    );
}
