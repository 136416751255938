import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import { Translate } from "react-translate.ts";

import FileDrop, { FileDropProps } from "../../input/FileDrop/FileDrop";
import { FileInputRef } from "../../input/FileInput/FileInput";
import { addEventHandler, removeEventHandler } from "../../../_helpers";
import styles from "./MediaDrop.module.scss";

interface MediaDropProps extends FileDropProps {
    onFilesChange: (files: FileList | null) => void;
}

export default function MediaDrop(props: MediaDropProps) {
    const { text, onFilesChange, ...rest } = props;
    const inputRef = useRef<FileInputRef>({});

    useEffect(() => {
        if (inputRef.current?.element) {
            addEventHandler(
                inputRef.current.element,
                "change",
                onChange
            );
        }

        return () => {
            if (inputRef.current?.element) {
                removeEventHandler(
                    inputRef.current.element,
                    "change",
                    onChange
                );
            }
            inputRef.current = {};
        };
    }, []);

    return (
        <FileDrop
            {...rest}
            inputRef={inputRef}
            text={
                <span>
                    {text}
                    <span className={styles.link} onClick={openDialog}>
                        <Translate textOnly>common.actions.browse</Translate>
                    </span>
                </span>
            }
            style={{ height: 320 }}
        />
    );

    function openDialog() {
        if (inputRef.current?.openDialog) {
            inputRef.current.openDialog();
        }
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        onFilesChange(event.target.files);
    }
}
