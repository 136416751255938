import { createSelector } from 'reselect';

const loading = state => state.candidate.jobs.search.filters.loading;
const filters = state => state.candidate.jobs.search.filters.data;
const activeFilters = state => state.candidate.jobs.search.activeFilters;

const benefits = createSelector(
    activeFilters,
    activeFilters => activeFilters.benefits
);

const isLoading = createSelector(
    loading,
    filters,
    (loading, filters) => loading && Object.keys(filters).length === 0
);

const getBenefits = createSelector(benefits, benefits =>
    benefits.valueSeq().toArray()
);

export const JobFilterSelectors = {
    isLoading,
    getBenefits
};
