import { PropsWithChildren } from "react";

import { JobMatch } from "../../../../common/components";
import { Panel } from "../../../../common/components/Panel/Panel";
import styles from "./BaseSkills.module.css";

type BaseSkillsProps = {
    heading: string;
    match: number;
    label: JSX.Element;
    isLoading: boolean;
};

export function BaseSkills(props: PropsWithChildren<BaseSkillsProps>) {
    const { children, heading, match = 0, label, isLoading } = props;

    return (
        <Panel>
            <Panel.Heading>{heading}</Panel.Heading>
            {match ? <JobMatch fit={match} isAnimationActive={!isLoading}>
                {label}
            </JobMatch> : null}
            <div className={styles.skills}>{children}</div>
        </Panel>
    );
}
