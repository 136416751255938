import { FieldHookConfig, useField } from "formik";
import TextArea, { TextAreaProps } from "./TextArea/TextArea";

interface FormTextAreaProps<T extends string> extends TextAreaProps {
    fieldConfig?: FieldHookConfig<T>;
}

export default function FormTextArea<T extends string>(
    props: FormTextAreaProps<T>
) {
    const { name, fieldConfig = {}, ...rest } = props;
    const [field] = useField<T>({ ...fieldConfig, name });

    return <TextArea {...field} onChange={field.onChange} {...rest} />;
}
