import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import { InlineLinks } from "../../../links";
import {
    COMPANY_DETAIL_LOCATION_PATH,
    COMPANY_DETAIL_JOBS_PATH,
    COMPANY_DETAIL_GALLERY_PATH,
    COMPANY_DETAIL_BENEFITS_PATH,
} from "../../../../_constants/route.constants";

export function Links(props) {
    const { locations } = props.company;
    const translate = useTranslate("common.company.profile.links");
    const { url } = useRouteMatch();

    return (
        <InlineLinks>
            <NavLink exact to={`${url}`}>
                {translate("overview")}
            </NavLink>
            {locations?.length > 0 && (
                <NavLink to={`${url}${COMPANY_DETAIL_LOCATION_PATH}`}>
                    {translate("locations")}
                </NavLink>
            )}
            <NavLink to={`${url}${COMPANY_DETAIL_JOBS_PATH}`}>
                {translate("jobs")}
            </NavLink>
            <NavLink to={`${url}${COMPANY_DETAIL_GALLERY_PATH}`}>
                {translate("gallery")}
            </NavLink>
            <NavLink to={`${url}${COMPANY_DETAIL_BENEFITS_PATH}`}>
                {translate("benefits")}
            </NavLink>
            {/*<NavLink to={`${url}${COMPANY_DETAIL_PATHS_PATH}`}>*/}
            {/*    {translate('paths')}*/}
            {/*</NavLink>*/}
            {/*<NavLink to={`${url}${COMPANY_DETAIL_TEAM_PATH}`}>
                {translate('team')}
            </NavLink>*/}
        </InlineLinks>
    );
}
