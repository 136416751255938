export const GET_PUBLIC_INTERPRETATION = 'GET_PUBLIC_INTERPRETATION';
export const GET_PUBLIC_INTERPRETATION_SUCCESS =
    'GET_PUBLIC_INTERPRETATION_SUCCESS';
export const GET_PUBLIC_INTERPRETATION_FAIL = 'GET_PUBLIC_INTERPRETATION_FAIL';

export const PublicActions = {
    getInterpretation,
    getInterpretationSuccess,
    getInterpretationFail,
};

/**
 *
 * @param {string} test
 * @param {string?} uuid
 */
function getInterpretation(test, uuid) {
    return { type: GET_PUBLIC_INTERPRETATION, payload: { test, uuid } };
}

function getInterpretationSuccess(payload) {
    return { type: GET_PUBLIC_INTERPRETATION_SUCCESS, payload };
}

function getInterpretationFail(error) {
    return { type: GET_PUBLIC_INTERPRETATION_FAIL, error };
}
