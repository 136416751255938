import { combineReducers } from 'redux';
import {
    GET_INTERPRETATION_DETAIL_SUCCESS,
    GET_INTERPRETATION_DETAIL,
    GET_INTERPRETATION_DETAIL_FAIL,
} from '../_actions/InterpretationActions';

const baseState = {
    loaded: false,
    profileId: null,
    profiles: null,
};

const defaultMopCategories = {
    activity: null,
    perception: null,
    setting: null,
    decisions: null,
};

const defaultState = {
    MOP: {
        ...baseState,
        skillInterpretations: defaultMopCategories,
        softSkills: defaultMopCategories,
    },
    KAKO: {
        ...baseState,
        skillInterpretations: {
            all: null,
        },
        softSkills: {
            expert: null,
            manager: null,
            salesman: null,
            templayer: null,
        },
    },
    PRESS: {
        ...baseState,
        skillInterpretations: null,
        softSkills: null,
    },
    ABS: {
        ...baseState,
        skillInterpretations: null,
        softSkills: null,
    },
    NUM: {
        ...baseState,
        skillInterpretations: null,
        softSkills: null,
    },
    HOFIK: {
        ...baseState,
        skillInterpretations: null,
        softSkills: null,
    }
};

const detail = (state = defaultState, action) => {
    if (action.type === GET_INTERPRETATION_DETAIL_SUCCESS) {
        const { name, data } = action.payload;

        return Object.assign({}, state, {
            [name]: Object.assign({}, data, { loaded: true }),
        });
    }

    return state;
};

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_INTERPRETATION_DETAIL:
            return true;
        case GET_INTERPRETATION_DETAIL_SUCCESS:
        case GET_INTERPRETATION_DETAIL_FAIL:
            return false;
        default:
            return state;
    }
};

export default combineReducers({ detail, loading });
