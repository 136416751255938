import { RequestService } from '../../common/_services';

export const JobsService = {
    getJobs,
    getCompanyJobs
};

/**
 * @param {number} companyId
 */
function getJobs(companyId) {
    const url = 'api/job-posts/autocomplete';
    return RequestService.get(`${url}?companyId=${companyId}`);
}

function getCompanyJobs(companyId) {
    const url = `api/companies/${companyId}/job-cards`;
    return RequestService.get(url);
}
