import { useMemo } from "react";
import { Link, generatePath } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import List from "../../../../common/components/list/List/List";
import ListItem from "../../../../common/components/list/ListItem/ListItem";
import ICompany from "../../../../common/types/ICompany";
import { COMPANY_DETAIL_PATH } from "../../../../common/_constants/route.constants";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";
import styles from "./CompanyList.module.scss";

type CompanyListProps = {
    companies: ICompany[];
};

export default function CompanyList(props: CompanyListProps) {
    const { companies } = props;
    const items = useMemo(
        () =>
            companies.map((company, index) => (
                <Item key={index} company={company} />
            )),
        [companies]
    );
    return <List>{items}</List>;
}

function Item(props: { company: ICompany }) {
    const { id, name, logoUrl, size, industries } = props.company;
    const items = useMemo(
        () => industries.map((item) => item.name).join(" | "),
        [industries]
    );
    const translate = useTranslate();
    const link = generatePath(COMPANY_DETAIL_PATH, { id });
    return (
        <ListItem className={styles.item}>
            <Link to={link} />
            <div className={styles.item__logo}>
                <img src={logoUrl} alt="" />
            </div>
            <div>
                <div className={styles.item__name}>{name}</div>
                <div className={styles.item__info}>
                    {!ArrayHelper.isEmpty(industries) && <div>{items}</div>}
                    {size && (
                        <div>
                            {translate("common.company.profile.size.value", {
                                size: size.name,
                            })}
                        </div>
                    )}
                </div>
            </div>
        </ListItem>
    );
}
