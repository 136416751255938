import { useEffect, useState } from "react";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useTranslate } from "react-translate.ts";
import { useDispatch } from "react-redux";

import Field from "../../../common/components/input/Field/Field";
import styles from "./BenefitsEdit.module.scss";
import BaseEdit from "../BaseEdit/BaseEdit";
import AdminJobService from "../../_services/AdminJobService";
import ISelectType from "../../../common/types/ISelectType";
import FormSelect from "../../../common/components/input/FormSelect";
import FormInput from "../../../common/components/input/FormInput";
import ICurrency from "../../../common/types/ICurrency";
import AdminProfileActions from "../../_actions/AdminProfileAction";
import AdminProfileSelectors from "../../_selectors/AdminProfileSelectors";
import AdminProfileEditActions from "../../_actions/AdminProfileEditActions";
import IMetric from "../../../common/types/IMetric";
import IMetricType from "../../../common/types/IMetricType";
import IAdminBenefit from "../../../common/types/IAdminBenefit";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import IBenefit from "../../../common/types/IBenefit";
import FormField from "../../../common/components/input/FormField";
import { ObjectSchema } from "yup";
import { REQUIRED_FORM_FIELD } from "../../../common/_constants/validation.constants";

const CATEGORY_FIELD = "category";
const CURRENCY_FIELD = "currency";
const VALUE_FIELD = "value";
const METRIC_FIELD = "metric";
const METRIC_TYPE_FIELD = "metricType";

export type BenefitsEditProps = {
    benefits: IBenefit[];
    onSubmit: (values: Values) => void;
    onDelete: (id: number) => void;
    pending: number[];
};

type Values = {
    [CATEGORY_FIELD]: ISelectType;
    [CURRENCY_FIELD]: ICurrency;
    [VALUE_FIELD]: number;
    [METRIC_FIELD]: IMetric;
    [METRIC_TYPE_FIELD]: IMetricType;
};

const DEFAULT_VALUES: Values = {
    [CATEGORY_FIELD]: undefined,
    [CURRENCY_FIELD]: undefined,
    [VALUE_FIELD]: undefined,
    [METRIC_FIELD]: undefined,
    [METRIC_TYPE_FIELD]: undefined,
};

export default function BenefitsEdit(props: BenefitsEditProps) {
    const { onSubmit } = props;

    const [isVisible, setIsVisible] = useState(false);

    return (
        <Formik
            initialValues={DEFAULT_VALUES}
            onSubmit={submit}
            validationSchema={createValidation()}
            enableReinitialize
        >
            <Content
                {...props}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        </Formik>
    );

    function submit(values: Values) {
        onSubmit(values);
    }

    function createValidation() {
        return new ObjectSchema({
            [CATEGORY_FIELD]: new ObjectSchema().required(REQUIRED_FORM_FIELD).nullable(),
        });
    }
}

interface ContentProps extends BenefitsEditProps {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
}

function Content(props: ContentProps) {
    const { benefits, isVisible, setIsVisible, onDelete, pending } = props;
    const translate = useTranslate("admin.edit.benefits");

    const formik = useFormikContext();
    return (
        <BaseEdit<IBenefit>
            cols={["60px", "5fr", "3fr"]}
            head={[
                <div />,
                <div>{translate("head.title")}</div>,
                <div>{translate("head.value")}</div>,
            ]}
            createItem={(benefit) => {
                const isLoading = pending.includes(benefit.id);
                if (isLoading) {
                    return <Skeleton height={18} count={2} width="80%" />;
                }

                return (
                    <>
                        <div className={styles.image}>
                            <img src={benefit.logoUrl} alt="" />
                        </div>
                        <div data-test="benefits-edit-name">{benefit.name}</div>
                        <div>{benefit.formattedValue}</div>
                    </>
                );
            }}
            data={benefits}
            title={translate("title")}
            isVisible={isVisible}
            onEdit={openEditDialog}
            onDelete={handleDelete}
            onCreate={openNewDialog}
            onSave={save}
            onCancel={cancel}
            onClose={cancel}
            createButtonText={translate("create")}
            getItemName={(item) => item?.name}
        >
            <InnerForm isVisible={isVisible} />
        </BaseEdit>
    );

    function handleDelete(item: IAdminBenefit) {
        onDelete && onDelete(item.id);
    }

    async function save() {
        await formik.submitForm();

        if (formik.isValid) {
            closeDialog();
        }
    }

    function cancel() {
        closeDialog();
    }

    function openNewDialog() {
        formik.setValues(DEFAULT_VALUES);
        setIsVisible(true);
    }

    function openEditDialog(values: IBenefit) {
        const { value, currency, id, name, metricTypePair, metricPair } =
            values;
        formik.setValues({
            value,
            currency,
            category: { id, name: name.trim() },
            metricType: metricTypePair,
            metric: metricPair,
        });

        setIsVisible(true);
    }

    function closeDialog() {
        formik.setValues(DEFAULT_VALUES);
        formik.setErrors({});
        formik.setTouched({});
        setIsVisible(false);
    }
}

function InnerForm(props: { isVisible: boolean }) {
    const { isVisible } = props;
    const dispatch = useDispatch();
    const translate = useTranslate("admin.edit.benefits.form");

    const { data: currencies, loading: currenciesLoading } =
        AdminProfileSelectors.useGetCurrencies();
    const { data: metrics, loading: metricsLoading } =
        AdminProfileSelectors.useGetMetrics();
    const { data: metricTypes, loading: metricTypesLoading } =
        AdminProfileSelectors.useGetMetricTypes();

    useEffect(() => {
        if (!isVisible) {
            return;
        }

        if (!currencies) {
            dispatch(AdminProfileActions.getCurrencies());
        }

        if (!metrics) {
            dispatch(AdminProfileEditActions.getBenefitMetrics());
        }

        if (!metricTypes) {
            dispatch(AdminProfileEditActions.getBenefitMetricTypes());
        }
    }, [dispatch, currencies, metrics, metricTypes, isVisible]);

    const [metricTypeField] = useField<IMetricType>(METRIC_TYPE_FIELD);

    return (
        <Form>
            <FormField
                name={CATEGORY_FIELD}
                label={translate("category.label")}
                required
            >
                <FormSelect<ISelectType>
                    name={CATEGORY_FIELD}
                    placeholder={translate("category.placeholder")}
                    loadOptions={AdminJobService.searchBenefits}
                    searchable
                    getOptionLabel={(option) => option.name}
                    data-test="benefits-edit-benefit"
                    preload
                />
            </FormField>
            <Field label={translate("metric.label")}>
                <FormSelect<IMetricType>
                    name={METRIC_TYPE_FIELD}
                    placeholder={translate("metric.placeholder")}
                    options={metricTypes}
                    loading={metricTypesLoading}
                    getOptionLabel={(option) => option.name}
                    data-test="benefits-edit-metric-type"
                />
            </Field>

            {metricTypeField?.value && <Field.Divider />}

            {metricTypeField?.value?.id === "FREQUENCY" && (
                <Field label={translate("frequency.label")}>
                    <FormSelect<IMetric>
                        name={METRIC_FIELD}
                        placeholder={translate("frequency.placeholder")}
                        options={metrics}
                        loading={metricsLoading}
                        getOptionLabel={(option) => option.name}
                        data-test="benefits-edit-metric"
                    />
                </Field>
            )}

            {metricTypeField?.value?.id === "MONEY" && (
                <Field label={translate("currency.label")}>
                    <FormSelect<ICurrency>
                        name={CURRENCY_FIELD}
                        placeholder={translate("currency.placeholder")}
                        options={currencies}
                        loading={currenciesLoading}
                        getOptionLabel={(option) =>
                            `${option.code} ${option.symbol}`
                        }
                        data-test="benefits-edit-currency"
                    />
                </Field>
            )}
            {(metricTypeField?.value?.id === "MONEY" ||
                metricTypeField?.value?.id === "QUANTITY") && (
                <Field label={translate("value.label")}>
                    <FormInput
                        name={VALUE_FIELD}
                        type="number"
                        placeholder={translate("value.placeholder")}
                        data-test="benefits-edit-value"
                    />
                </Field>
            )}
        </Form>
    );
}
