import React, { CSSProperties } from "react";
import cx from "classnames";

import { BREAKPOINT_LG } from "../../../_constants/theme.constants";
import { MathHelper, renderEmptyElements } from "../../../_helpers";
import { useWindowState } from "../../misc";
import InterpretationPanel from "./../InterpretationPanel/InterpretationPanel";
import styles from "./InterpretationPersonalities.module.scss";

type GetBubbleColor = (section: string) => "teal" | "blue" | "red" | "yellow";

type InterpretationPersonalitiesProps = {
    profiles: any[];
    isLoading: boolean;
    heading: string;
    subheading: string;
    extraContent?: any;
    id?: string;
    numOfElements?: number;
    getBubbleColor: GetBubbleColor;
};

export default function InterpretationPersonalities(
    props: InterpretationPersonalitiesProps
) {
    const {
        profiles,
        isLoading,
        extraContent,
        id = "personality",
        heading,
        subheading,
        numOfElements = 4,
        getBubbleColor,
    } = props;

    const screenWidth = useWindowState().width;

    let personalities = profiles ? [...profiles] : [];
    if (personalities && screenWidth < BREAKPOINT_LG) {
        personalities.reverse();
    }

    return (
        <InterpretationPanel className={styles.panel} id={id}>
            <InterpretationPanel.Inner>
                <div>
                    <h2>{heading}</h2>
                    <p className={styles["main-text"]}>{subheading}</p>
                    {extraContent}
                    <div
                        className={styles.personalities}
                        style={
                            {
                                "--columns": `repeat(${numOfElements}, 1fr)`,
                            } as CSSProperties
                        }
                    >
                        {personalities
                            ? personalities.map((item, index) => (
                                  <Personality
                                      value={item.value}
                                      index={index}
                                      isEmpty={item.isEmpty}
                                      name={item.name}
                                      section={item.section}
                                      imageUrl={item.imageUrl}
                                      description={item.description}
                                      isLoading={isLoading}
                                      getBubbleColor={getBubbleColor}
                                  />
                              ))
                            : Array.from(
                                  new Array(numOfElements),
                                  (val, index) => (
                                      <Personality
                                          value={0}
                                          isEmpty={true}
                                          index={index}
                                      />
                                  )
                              )}
                    </div>
                </div>
            </InterpretationPanel.Inner>
        </InterpretationPanel>
    );
}

type PersonalityProps = {
    value: number;
    index: number;
    isEmpty: boolean;
    name?: string;
    section?: string;
    imageUrl?: string;
    description?: string;
    isLoading?: boolean;
    getBubbleColor?: GetBubbleColor;
};

function Personality(props: PersonalityProps) {
    const {
        value = 0,
        index,
        isEmpty,
        name,
        section,
        imageUrl,
        description,
        isLoading,
        getBubbleColor,
    } = props;
    return (
        <div
            key={index}
            className={cx(
                styles.personalities__item,
                styles[getBubbleColor?.(section)],
                index === 3 && styles.big,
                isEmpty && isLoading && styles.empty
            )}
        >
            <div className={styles.personalities__item__bubble}>
                {imageUrl ? <img src={imageUrl} alt="" /> : null}
            </div>
            <div className={styles.personalities__item__value}>
                {MathHelper.round(value, 0)}
                <sup>%</sup>
            </div>
            <div className={styles.personalities__item__title}>
                <strong>{name}</strong>
            </div>
            {description && (
                <div
                    className={cx(
                        styles.personalities__item__description,
                        "font-book"
                    )}
                >
                    {description}
                </div>
            )}
            <div className={styles.personalities__item__bars}>
                {renderEmptyElements(
                    "div",
                    isLoading ? 1 : Math.ceil(value / 25),
                    {
                        className: styles.personalities__item__bar,
                    }
                )}
            </div>
        </div>
    );
}
