import { useEffect } from "react";
import { createClass } from "classname-helper";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Link, useLocation, generatePath } from "react-router-dom";

import { Flex } from "../";

export function Pagination(props) {
    const { pages, onPageChange, page, maxPages = 5 } = props;

    if (page < 0) {
        page = 0;
    }

    const location = useLocation();

    useEffect(() => void window.scrollTo(0, 0), [page]);

    useEffect(() => {
        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = "manual";
        }

        return () => {
            if (window.history.scrollRestoration) {
                window.history.scrollRestoration = "auto";
            }
        };
    }, []);

    if (pages <= 1) {
        return null;
    }

    return (
        <Flex
            className="pagination"
            alignItems="center"
            justifyContent="center"
        >
            {page > 0 ? (
                <Link to={createPath(location, page - 1)}>
                    <ArrowBackIos
                        fontSize="small"
                        color="primary"
                        // onClick={prevStep}
                    />
                </Link>
            ) : null}
            <Flex alignItems="center" className="padding-x-10">
                {renderSteps(page, pages, onPageChange, maxPages)}
            </Flex>
            {page < pages - 1 ? (
                <Link to={createPath(location, page + 1)}>
                    <ArrowForwardIos
                        fontSize="small"
                        color="primary"
                        // onClick={nextStep}
                    />
                </Link>
            ) : null}
        </Flex>
    );

    function nextStep() {
        changePage(page + 1, pages, onPageChange);
    }

    function prevStep() {
        changePage(page - 1, pages, onPageChange);
    }
}

function changePage(newPage, pages, onPageChange) {
    const currentPage = Math.max(0, Math.min(pages - 1, newPage));
    onPageChange(currentPage);
}

function renderSteps(page, pages, onPageChange, maxPages) {
    const hasMorePagesThanMax = pages > maxPages;
    const center = Math.floor(maxPages / 2);

    return Array.from(
        new Array(!hasMorePagesThanMax ? pages : maxPages),
        (_, index) => {
            let key = index;

            if (hasMorePagesThanMax && page > center) {
                key += page - center;
            }
            return <Step {...{ key, index: key, page, pages, onPageChange }} />;
        }
    );
}

function Step({ page, index, pages, onPageChange }) {
    const location = useLocation();

    const isActive = page === index;
    const onClick = () => changePage(index, pages, onPageChange);
    const className = createClass({
        "pagination-step": true,
        "is-active": isActive,
    });

    return (
        <div key={index} className={className}>
            <Link to={createPath(location, index)}>{index + 1}</Link>
        </div>
    );
}

function createPath(location, page) {
    const query = new URLSearchParams(location.search);

    query.set("page", page);

    return generatePath(location.pathname + "?" + query.toString());
}
