import { combineReducers } from "redux";

import { ReducerHelpers } from "../../candidate/_helpers";
import {
    ONBOARDING_EDUCATION_BEGIN,
    ONBOARDING_EDUCATION_SUCCESS,
    ONBOARDING_EDUCATION_ERROR,
} from "../_constants/actions/onboarding.constants";
import OnboardingConstants from "../_constants/actions/OnboardingConstants";
import { GET_CURRENT_SUCCESS } from "../_constants/actions/user.constants";
import {
    ONBOARDING_PERSONAL_DETAILS_STATUS,
    ONBOARDING_USER_STATUS,
    ONBOARDING_HARD_SKILLS_STATUS,
    ONBOARDING_TEST_STATUS,
} from "../_constants/user.constants";
import { StorageService } from "../_services";
import { GET_INTERPRETATION } from "../../candidate/_constants/actions/skill.contants";
import ProfileConstants from "../../candidate/_constants/actions/ProfileConstants";
import {
    GET_PROFILE_TYPE,
    GET_PROFILE_TYPE_SUCCESS,
} from "../_actions/ProfileActions";
import { ArrayHelper } from "../_helpers/ArrayHelper";
import { PROFILE_TYPE_UNIVERSITY } from "../_constants/profile.constants";
import SoftSkillsConstants from "../../candidate/_constants/actions/SoftSkillsConstants";

function personal(
    state = {
        firstName: null,
        lastName: null,
        city: null,
        jobRole: null,
        profileType: PROFILE_TYPE_UNIVERSITY,
        degreeField: null,
        degreeTitle: null,
    },
    action
) {
    switch (action.type) {
        case OnboardingConstants.SAVE_PERSONAL_SUCCESS:
        case ProfileConstants.GET_PROFILE_SUCCESS:
            const { firstName, lastName, locationCities, jobExperience } =
                action.payload;
            return {
                ...state,
                firstName,
                lastName,
                city: ArrayHelper.tryGet(locationCities, 0),
                jobRole: ArrayHelper.tryGet(jobExperience, 0),
            };
        case GET_PROFILE_TYPE_SUCCESS:
            const {
                jobRoleTitle,
                jobRoleId,
                degreeTitleName,
                degreeTitleId,
                degreeFieldName,
                degreeFieldId,
                type,
            } = action.payload;
            return {
                ...state,
                profileType: type || PROFILE_TYPE_UNIVERSITY,
                jobRole:
                    jobRoleTitle && jobRoleId
                        ? { id: jobRoleId, name: jobRoleTitle }
                        : null,
                degreeTitle:
                    degreeTitleName && degreeTitleId
                        ? { id: degreeTitleId, name: degreeTitleName }
                        : null,
                degreeField:
                    degreeFieldName && degreeFieldId
                        ? { id: degreeFieldId, name: degreeFieldName }
                        : null,
            };
        case OnboardingConstants.CHANGE_PERSONAL_VALUE:
            const { field, value } = action.payload;
            return {
                ...state,
                [field]: value,
            };
        default:
            return state;
    }
}

function education(
    state = { data: null, loading: false, error: null },
    action
) {
    switch (action.type) {
        case ONBOARDING_EDUCATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ONBOARDING_EDUCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case ONBOARDING_EDUCATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}

function test(state = null, action) {
    if (action.type === OnboardingConstants.GET_TEST_SUCCESS) {
        return action.payload;
    }

    return state;
}

function testStarted(state = false, action) {
    if (action.type === OnboardingConstants.START_TEST) {
        return true;
    }

    return state;
}

const lastStatus = StorageService.getLocal(
    "onboarding_status",
    ONBOARDING_PERSONAL_DETAILS_STATUS
);

function step(state = ONBOARDING_USER_STATUS[lastStatus], action) {
    switch (action.type) {
        case GET_CURRENT_SUCCESS:
            const status = action.payload.userStatus;

            if (!status) {
                return 0;
            }

            const step = ONBOARDING_USER_STATUS[status.status];

            return step || 0;
        case OnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return 1;
        case OnboardingConstants.SAVE_HARD_SKILLS_SUCCESS:
            return 2;
        case OnboardingConstants.ADD_STEP:
            return validateStep(state + action.payload);
        case OnboardingConstants.CHANGE_STEP:
            return validateStep(action.payload);
        default:
            return state;
    }

    function validateStep(step) {
        if (step < 0) {
            return 0;
        } else if (step > 3) {
            return 3;
        }

        return step;
    }
}

function stepsCompleted(state = [], action) {
    switch (action.type) {
        case GET_CURRENT_SUCCESS:
            return action.payload.stepsCompleted;
        case OnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return [...state, ONBOARDING_PERSONAL_DETAILS_STATUS];
        case OnboardingConstants.SAVE_HARD_SKILLS_SUCCESS:
            return [...state, ONBOARDING_HARD_SKILLS_STATUS];
        case SoftSkillsConstants.COMPLETE_TEST_SUCCESS:
            return [...state, ONBOARDING_TEST_STATUS];
        default:
            return state;
    }
}

const loading = (state, action) =>
    ReducerHelpers.loading(state, action, [
        OnboardingConstants.SAVE_HARD_SKILLS,
        OnboardingConstants.SAVE_PERSONAL,
        OnboardingConstants.GET_TEST,
        ProfileConstants.UPLOAD_PHOTO,
        GET_PROFILE_TYPE,
        GET_INTERPRETATION,
        // HeroProfileConstants.PUBLISH,
        // HeroProfileConstants.GET_SOFT_SKILLS_RESULTS,
    ]);

export default combineReducers({
    personal,
    education,
    test,
    step,
    loading,
    stepsCompleted,
    testStarted,
});
