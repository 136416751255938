import React from 'react';
import { useTranslate } from 'react-translate.ts';
import { addClass } from 'classname-helper';

export function CandidateActionPanel({
    onInvite,
    onReject,
    onSwap,
    onSave,
    onAccept,
    isSaved,
    size,
    status,
    withAccept = false,
    withoutSave = false,
}) {
    const inviteDisabled = status !== 'NOT_APPLIED';
    const rejectDisabled =
        status === 'NOT_APPLIED' ||
        status === 'INVITED' ||
        status === 'DECLINED';
    const acceptDisabled = status === 'ACCEPTED';

    const translate = useTranslate('admin.candidate.actions');
    return (
        <div className={addClass('candidate-actions', size)}>
            {withAccept ? (
                <button
                    className="candidate-actions--button accept"
                    title={
                        acceptDisabled
                            ? translate('acceptDisabled')
                            : translate('accept')
                    }
                    onClick={(event) => void handleClick(event, onAccept)}
                    disabled={acceptDisabled}
                >
                    <img src="/img/icons/check-primary.svg" alt="" />
                </button>
            ) : (
                <button
                    className="candidate-actions--button invite"
                    title={
                        inviteDisabled
                            ? translate('inviteDisabled')
                            : translate('invite')
                    }
                    onClick={(event) => void handleClick(event, onInvite)}
                    disabled={inviteDisabled}
                >
                    <img src="/img/icons/email.svg" alt="" />
                </button>
            )}
            <button
                className="candidate-actions--button"
                title={translate('save')}
                onClick={(event) => void handleClick(event, onSave)}
                disabled={withoutSave}
            >
                <img
                    src={`/img/icons/${isSaved ? 'star-full' : 'star'}.svg`}
                    alt=""
                />
            </button>
            {/*<button
                className="candidate-actions--button"
                title={translate('swap')}
                onClick={(event) => void handleClick(event, onSwap)}
            >
                <img src="/img/icons/swap.svg" alt="" />
            </button>*/}
            <button
                className="candidate-actions--button"
                title={rejectDisabled ? null : translate('reject')}
                onClick={(event) => void handleClick(event, onReject)}
                disabled={rejectDisabled}
            >
                <img src="/img/icons/reject.svg" alt="" />
            </button>
            {renderLabels()}
        </div>
    );
    function renderLabels() {
        if (size === 'small') {
            return null;
        }

        return (
            <>
                <div className="candidate-actions--label">
                    {translate(
                        withAccept
                            ? acceptDisabled
                                ? 'acceptDisabled'
                                : 'accept'
                            : inviteDisabled
                            ? 'inviteDisabled'
                            : 'invite'
                    )}
                </div>
                <div className="candidate-actions--label">
                    {translate('save')}
                </div>
                {/* <div>{translate('swap')}</div> */}
                <div className="candidate-actions--label">
                    {rejectDisabled ? null : translate('reject')}
                </div>
            </>
        );
    }
}

function handleClick(event, action) {
    event.stopPropagation();

    action();
}
