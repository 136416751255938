import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'react-translate.ts';

import { PROFILE_PATH_INFO } from '../../../../_constants/route.constants';
import { ProfileEdit } from '../ProfileEdit';
import {
    DeleteButton,
    ConfirmButton,
    EditButton,
    Button,
} from '../../../../../common/components';
import { ProfileInformationActions } from '../../../../_actions/ProfileInformationActions';
import { AuthSelectors } from '../../../../../common/_selectors/AuthSelectors';
import ProfileInformationSelectors from '../../../../_selectors/ProfileInformationSelectors';
import ProfileSelectors from '../../../../_selectors/ProfileSelectors';

export function InterestEdit() {
    const history = useHistory();
    const { interests, profileId, isUpdating } = useSelector(mapState);
    const [newValue, setNewValue] = useState('');
    const dispatch = useDispatch();
    const [editing, setEditing] = useState({ index: null, value: '' });

    const translateActions = useTranslate('common.actions');

    return (
        <ProfileEdit
            onClose={close}
            heading="candidate.profile.information.interest.edit"
            formButtonsProps={{ onCancel: close, onConfirm: close }}
            isLoading={isUpdating}
            // formButtons={null}
        >
            {interests.map(renderInput)}
            <div className="w100 flex align-items-center justify-space-between">
                <input
                    className="input w75 margin-right-10"
                    value={newValue}
                    onChange={changeNewValue}
                    onKeyPress={addInterestOnEnter}
                />
                <div className="w15">
                    <Button variant="secondary" onClick={addInterest}>
                        {translateActions('add')}
                    </Button>
                </div>
            </div>
            {/* <FormButtons cancelOnly={true} onCancel={close} /> */}
        </ProfileEdit>
    );

    function renderInput(interest, index) {
        const { id, content } = interest;
        const isDisabled = editing.index !== index;
        return (
            <div
                key={index}
                className="w100 flex align-items-center justify-space-between padding-bottom-20"
            >
                <input
                    className="input w75 margin-right-10"
                    value={!isDisabled ? editing.value : content}
                    onChange={onChange}
                    disabled={isDisabled}
                />
                <div className="w15">
                    <DeleteButton onClick={deleteInterest} />
                    {renderButtons()}
                </div>
            </div>
        );

        function renderButtons() {
            if (!isDisabled) {
                return <ConfirmButton onClick={updateInterest} />;
            }

            return (
                <EditButton
                    onClick={() => setEditing({ index, value: content })}
                    disabled={isDisabled && editing.index !== null}
                />
            );
        }

        function onChange({ target }) {
            setEditing({ ...editing, value: target.value });
        }

        function deleteInterest() {
            dispatch(ProfileInformationActions.deleteInterest(id, profileId));
        }

        function updateInterest() {
            const item = { id, content: editing.value };
            dispatch(ProfileInformationActions.updateInterest(item, profileId));
            setEditing({ index: null, value: '' });
        }
    }

    function close() {
        history.push(PROFILE_PATH_INFO);
    }

    function changeNewValue({ target }) {
        setNewValue(target.value);
    }

    function addInterestOnEnter(event) {
        const code = event.keyCode || event.which;
        if (code === 13) {
            addInterest();
        }
    }

    function addInterest() {
        dispatch(ProfileInformationActions.addInterest(newValue, profileId));
        setNewValue('');
    }
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        interests: ProfileInformationSelectors.getInterests(state),
        isUpdating: ProfileSelectors.isUpdating(state),
    };
}
