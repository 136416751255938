import React, { useState, Fragment } from "react";
import { Translate } from "react-translate.ts";

import { Button, Grid } from "../";

const BENEFITS_LIMIT = 6;

export function BenefitsList({ benefits, limit }) {
    const benefitsLimit = limit || BENEFITS_LIMIT;
    const [isOpen, setIsOpen] = useState(false);

    if (!benefits || !Array.isArray(benefits)) {
        return null;
    }

    const count = benefits.length;
    const isBenefitsOverLimit = count > benefitsLimit;

    const visibleBenefits =
        isBenefitsOverLimit && !isOpen
            ? benefits.slice(0, benefitsLimit)
            : benefits;

    return render();
    function render() {
        return (
            <Fragment>
                <Grid
                    min="250px"
                    max="1fr"
                    columnGap={40}
                    rowGap={25}
                    className="padding-bottom-20"
                >
                    {visibleBenefits.map(renderBenefit)}
                </Grid>
                {renderButton()}
            </Fragment>
        );
    }

    function renderBenefit(
        { name, formattedValue, logoUrl, description },
        index
    ) {
        return (
            <Grid key={index} columnGap={21} size="auto 1fr">
                <img
                    src={logoUrl}
                    style={{ minWidth: 35, height: 35 }}
                    alt=""
                />
                <div>
                    <strong>
                        {name + (formattedValue ? " - " + formattedValue : "")}
                    </strong>
                    <p className="font-13 text-secondary padding-y-10">
                        {description}
                    </p>
                </div>
            </Grid>
        );
    }

    function renderButton() {
        if (isOpen || !isBenefitsOverLimit) {
            return null;
        }

        return (
            <Button variant="tertiary" onClick={onClick}>
                <Translate textOnly>common.actions.seeAll</Translate>
                {` (${benefits.length})`}
            </Button>
        );

        function onClick() {
            setIsOpen(true);
        }
    }
}
