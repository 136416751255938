import { createSelector } from "reselect";
import { STATE_LOADING } from "../../_constants/state.constants";

const advice = (state) => state.common.heroes.onboarding.steps.advice;

const getData = createSelector(advice, (advice) => advice.data);

const isComplete = createSelector(advice, (advice) => advice.complete);

const isSaved = createSelector(advice, (advice) => advice.saved);

const getQuestions = createSelector(advice, (advice) => advice.questions);

const getVideo = createSelector(advice, (advice) => advice.video);
const isVideoUploading = createSelector(
    advice,
    (advice) => advice.videoLoading === STATE_LOADING
);
const getVideoUploadProgress = createSelector(
    advice,
    (advice) => advice.videoProgress
);
const videoInfoSeen = createSelector(advice, (advice) => advice.videoInfoSeen);

const isRecordedVideoUploading = createSelector(
    advice,
    (advice) => advice.recordedVideoLoading === STATE_LOADING
);

const Advice = {
    getData,
    isComplete,
    isSaved,
    getQuestions,
    getVideo,
    isVideoUploading,
    getVideoUploadProgress,
    videoInfoSeen,
    isRecordedVideoUploading,
};

export default Advice;
