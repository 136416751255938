import { combineReducers } from 'redux';
import ProfileConstants from '../../_constants/actions/ProfileConstants';

import ProfileInformationConstants from '../../_constants/actions/ProfileInformationConstants';
import { ReducerHelpers } from '../../_helpers/ReducerHelpers';

import information from './information.reducer';
import personal from './personal.reducer';

function id(state = null, action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        return action.payload.id;
    }

    return state;
}

function uuid(state = null, action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        return action.payload.userUUID;
    }

    return state;
}

function headerTitle(state = null, action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        return action.payload.headerTitle;
    }

    return state;
}

const updateState = (state = null, action) =>
    ReducerHelpers.loading(state, action, [
        ProfileInformationConstants.UPDATE_ABOUT_ME,
        ProfileInformationConstants.ADD_EDUCATION,
        ProfileInformationConstants.UPDATE_EDUCATION,
        ProfileInformationConstants.DELETE_EDUCATION,
        ProfileInformationConstants.ADD_EXPERIENCE,
        ProfileInformationConstants.UPDATE_EXPERIENCE,
        ProfileInformationConstants.DELETE_EXPERIENCE,
        ProfileInformationConstants.ADD_INTEREST,
        ProfileInformationConstants.UPDATE_INTEREST,
        ProfileInformationConstants.DELETE_INTEREST,
        ProfileInformationConstants.SAVE_LANGUAGES,
        ProfileInformationConstants.SAVE_SKILLS,
        ProfileConstants.UPLOAD_PHOTO,
    ]);

const state = (state = null, action) =>
    ReducerHelpers.loading(state, action, ProfileConstants.GET_PROFILE);

export default combineReducers({
    information,
    personal,
    updateState,
    state,
    id,
    uuid,
    headerTitle,
});
