import React from "react";
// @ts-ignore
import NetworkDiagram from "career-path-graph-component";

import SvelteWrapper from "../../../../candidate/pages/CareerPaths/components/CareerPathsMap/components/SvelteWrapper";

type PathsDiagramProps = {
    map: any;
    centerText?: string;
    centerImage?: string;
    onNodeSelect?: (node: any) => void;
    onNodeHover?: (node: any, x: any, ref: any) => void;
    matchScoreEnabled?: boolean;
    highlightedPathIndex?: number;
    hoveredPathIndex?: number;
    usePan?: boolean;
    categories?: any;
    onCategorySelect?: () => void;
    selectedCategory?: number;
    selectedSubcategory?: number;
    id: string;
    zoom?: number;
    minZoom?: number;
    maxZoom?: number;
};

export default function PathsDiagram(props: PathsDiagramProps) {
    const {
        id,
        map,
        centerText,
        centerImage,
        onNodeSelect,
        onNodeHover,
        matchScoreEnabled,
        highlightedPathIndex,
        hoveredPathIndex,
        usePan,
        categories,
        onCategorySelect,
        selectedCategory,
        selectedSubcategory,
        zoom,
        minZoom,
        maxZoom
    } = props;

    return (
        <SvelteWrapper
            id={id}
            svelte={NetworkDiagram}
            data={{
                data: map,
                onNodeSelect,
                onNodeHover,
                matchScoreEnabled,
                highlightedPathIndex,
                hoveredPathIndex,
                usePan,
                categories,
                onCategorySelect,
                centerImage,
                centerText,
                selectedCategory,
                selectedSubcategory,
                zoom,
                minZoom,
                maxZoom
            }}
        />
    );
}
