import { useState } from "react";
import { useTranslate } from "react-translate.ts";
import { Form, Formik } from "formik";

import MediaEditItem from "../../../../common/components/media/MediaEditItem/MediaEditItem";
import { SVGIcon } from "../../../../common/components";
import Field from "../../../../common/components/input/Field/Field";
import AdminEdit from "../../AdminEdit/AdminEdit";
import MediaDrop from "../../../../common/components/media/MediaDrop/MediaDrop";
import styles from "./Video.module.scss";
import MediaUploadProgress from "../../../../common/components/media/MediaUploadProgress/MediaUploadProgress";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog";
import FormInput from "../../../../common/components/input/FormInput";
import UploadButton from "../../AdminJobEdit/parts/UploadButton";

const URL_FIELD = "url";

type VideoProps = {
    onAdd: (file: File) => void;
    progress: number;
    videoUrl: string | null;
    url: string | null;
    fileName: string | null;
    uploading: boolean;
    onSave: (url: string) => void;
    saving: boolean;
};

type Values = {
    [URL_FIELD]: string;
};

export default function Video(props: VideoProps) {
    const { progress, videoUrl, url, fileName, uploading, onAdd, onSave, saving } = props;
    const [dialogVisible, setDialogVisible] = useState(false);
    const translate = useTranslate("admin.profile.video");

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="video"
        >
            <ConfirmDialog
                title={translate("delete.title")}
                onConfirm={console.log}
                onCancel={closeDialog}
                onClose={closeDialog}
                isVisible={dialogVisible}
            >
                {fileName &&
                    translate("delete.message", {
                        name: `${fileName}`,
                    })}
            </ConfirmDialog>
            <MediaDrop
                image={<SVGIcon name="film" light />}
                text={translate("dropbox")}
                onFileDrop={handleFiles}
                accept="video/*"
                onFilesChange={handleFiles}
                className={styles.drop}
            />
            {uploading && (
                <MediaUploadProgress
                    className={styles.upload}
                    progress={progress}
                    name={fileName}
                />
            )}
            {url && fileName && (
                <MediaEditItem
                    name={fileName}
                    onDelete={() => setDialogVisible(true)}
                />
            )}
            <div className={styles.separator}>or</div>
            <Formik<Values>
                initialValues={{ url: videoUrl || "" }}
                onSubmit={submit}
            >
                <Form>
                    <Field
                        label={
                            <>
                                <SVGIcon
                                    name="youtube"
                                    style={{ fontSize: 28 }}
                                />
                                <SVGIcon
                                    name="vimeo"
                                    style={{ fontSize: 22 }}
                                />
                                <span>{translate("embed")}</span>
                            </>
                        }
                    >
                        <FormInput
                            name={URL_FIELD}
                            placeholder="https://www.youtube.com/"
                        />
                    </Field>
                    <UploadButton sending={saving} />
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function submit(values: Values) {
        onSave(values.url);
    }

    function handleFiles(files: FileList) {
        if (files.length > 0) {
            onAdd(files[0]);
        }
    }

    function closeDialog() {
        setDialogVisible(false);
    }
}
