import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import { resolveLogoUrl } from "../../../common/_helpers/logoUrlResolver";
import { ADMIN_PROFILE_PATH } from "../../../common/_constants/route.constants";
import { AuthSelectors } from "../../../common/_selectors/AuthSelectors";
import { UserProfileSelectors } from "../../../common/_selectors/UserProfileSelectors";

export function AdminMainMenu() {
    const { src, email } = useSelector(mapState);
    const translate = useTranslate("admin.header.mainMenu");

    return (
        <div id="admin-main-menu">
            <div id="admin-main-menu--profile">
                <div id="admin-main-menu--profile--logo">
                    <img src={src || "/img/avatag.svg"} alt="company logo" />
                </div>
                <div id="admin-main-menu--profile--text">
                    <div>
                        <strong className="font-11">{email}</strong>
                    </div>
                    <div className="font-9">
                        <Link
                            to={ADMIN_PROFILE_PATH}
                            className="text-secondary"
                        >
                            {translate("companyProfile")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapState(state) {
    const logoUrl = UserProfileSelectors.getImage(state);

    return {
        src: resolveLogoUrl(logoUrl),
        email: AuthSelectors.getUserEmail(state),
    };
}
