import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ICurrency from "../../common/types/ICurrency";
import CurrencyService from "../../common/_services/CurrencyService";

const NAME = "ADMIN_PROFILE";

const getCurrencies = createAsyncThunk(
    `${NAME}/getCurrencies`,
    CurrencyService.getCurrencies
);

type State = {
    currencies: {
        data: ICurrency[];
        loading: boolean;
    };

};

const initialState: State = {
    currencies: {
        data: null,
        loading: false,
    },

};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrencies.pending, (state) => {
            state.currencies.loading = true;
        });
        builder.addCase(getCurrencies.fulfilled, (state, action) => {
            state.currencies.loading = false;
            state.currencies.data = action.payload;
        });
        builder.addCase(getCurrencies.rejected, (state) => {
            state.currencies.loading = false;
        });
    },
});

const AdminProfileActions = {
    getCurrencies,
};

export default AdminProfileActions;
export const AdminProfileReducer = slice.reducer;
