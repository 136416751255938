import { takeLatest } from 'redux-saga/effects';

import InterpretationActions, {
    GET_INTERPRETATION_DETAIL,
} from '../_actions/InterpretationActions';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { InterpretationService } from '../_services/InterpretationService';

function* getInterpretation(action) {
    yield SagaHelpers.handleRequest({
        request: [InterpretationService.getInterpretation, action.payload],
        actions: [
            (payload) =>
                InterpretationActions.getInterpretationSuccess(
                    action.payload.name,
                    payload
                ),
            InterpretationActions.getInterpretationFail,
        ],
    });
}

export default [takeLatest(GET_INTERPRETATION_DETAIL, getInterpretation)];
