import React from "react";
import { Link } from "react-router-dom";

import { HardSkillsList } from "./HardSkillsList";
import { PROFILE_HARD_SKILLS_EDIT_PATH } from "../../../../_constants/route.constants";
import {
    EditButton,
    AddButton,
    FlexContainer,
    FlexItem,
} from "../../../../../common/components";
import { ArrayHelper } from "../../../../../common/_helpers/ArrayHelper";
import { Translate } from "react-translate.ts";

export function HardSkillsContent({ skills }) {
    if (!skills) {
        return null;
    }

    const hasSkills = !ArrayHelper.isEmpty(skills);

    return (
        <div
            className="information-content padding-bottom-60"
            id="profile__hard-skills"
        >
            <div className="profile-content-list">
                <div className="profile-content-list-inner">
                    <FlexContainer
                        spacing={2}
                        justify="space-between"
                        alignItems="center"
                        className="border-bottom"
                    >
                        <FlexItem>
                            <h4>
                                <Translate textOnly>
                                    candidate.profile.hardSkills.title
                                </Translate>
                            </h4>
                        </FlexItem>
                        <FlexItem>
                            <Link to={PROFILE_HARD_SKILLS_EDIT_PATH}>
                                {hasSkills ? <EditButton /> : <AddButton />}
                            </Link>
                        </FlexItem>
                    </FlexContainer>
                    <FlexContainer
                        spacing={2}
                        justify="space-between"
                        alignItems="center"
                        className="padding-y-20"
                    >
                        <FlexItem size={12}>
                            <HardSkillsList skills={skills} />
                        </FlexItem>
                    </FlexContainer>
                </div>
            </div>
        </div>
    );
}
