import { PropsWithChildren } from "react";
import DeleteButton from "../../buttons/DeleteButton/DeleteButton";

import styles from "./MediaEditItem.module.scss";

export type MediaEditItemProps = {
    name: string;
    onDelete: () => void;
};
export default function MediaEditItem(
    props: PropsWithChildren<MediaEditItemProps>
) {
    const { name, onDelete, children } = props;
    return (
        <div className={styles.item}>
            <div className={styles.info}>
                <div className={styles.icon}>{children}</div>
                <div className={styles.name}>{name}</div>
            </div>
            <div>
                <DeleteButton onClick={onDelete} />
            </div>
        </div>
    );
}
