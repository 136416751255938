import { combineReducers } from "redux";
import { ReducerHelpers } from "../../../../../candidate/_helpers";

import HeroOnboardingConstants from "../../../../_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../../_constants/actions/HeroProfileConstants";

const videoLoading = (state, action) =>
    ReducerHelpers.loading(state, action, [
        HeroOnboardingConstants.UPLOAD_VIDEO,
    ]);

const recordedVideoLoading = (state, action) =>
    ReducerHelpers.loading(state, action, [
        HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO,
    ]);

export default combineReducers({
    data,
    complete,
    saved,
    questions,
    video,
    videoLoading,
    videoProgress,
    videoInfoSeen,
    recordedVideoLoading,
});

function data(state = [], action) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_ADVICE_SUCCESS:
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            if (action.payload && action.payload.answers) {
                return action.payload.answers;
            }
        default:
            return state;
    }
}

function saved(state = false, action) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_ADVICE:
        case HeroOnboardingConstants.SAVE_ADVICE_FAIL:
            return false;
        case HeroOnboardingConstants.SAVE_ADVICE_SUCCESS:
            return true;
        default:
            return state;
    }
}

function complete(state = false, action) {
    if (action.type === HeroOnboardingConstants.SAVE_ADVICE_SUCCESS) {
        return true;
    }

    return state;
}

function questions(state = [], action) {
    if (action.type === HeroOnboardingConstants.GET_QUESTIONS_SUCCESS) {
        return action.payload;
    }

    return state;
}

function video(state = { videoProvider: null, videoUrl: null }, action) {
    switch (action.type) {
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            const { videoProvider, videoUrl } = action.payload;
            return { videoProvider, videoUrl };
        case HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO_SUCCESS:
        case HeroOnboardingConstants.UPLOAD_VIDEO_SUCCESS:
            return { ...state, videoUrl: action.payload };
        default:
            return state;
    }
}

function videoProgress(state = { loaded: 0, total: 0 }, action) {
    switch (action.type) {
        case HeroOnboardingConstants.UPLOAD_VIDEO:
            return { ...state, total: action.payload.get("file").size };
        case HeroOnboardingConstants.VIDEO_UPLOAD_PROGRESS:
            return action.payload;
        default:
            return state;
    }
}

function videoInfoSeen(state = false, action) {
    if (action.type === HeroOnboardingConstants.VIDEO_INFO_SEEN) {
        return true;
    }

    return state;
}
