export const NOTIFICATION_PUSH = 'NOTIFICATION_PUSH';
export const NOTIFICATION_POP = 'NOTIFICATION_POP';

export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_WARNING = 'warning';
export const NOTIFICATION_TYPE_MESSAGE = 'info';
export const NOTIFICATION_TYPE_SUCCESS = 'success';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATIONS_VIEWS = 'GET_NOTIFICATIONS_VIEWS';
export const GET_NOTIFICATIONS_VIEWS_SUCCESS =
    'GET_NOTIFICATIONS_VIEWS_SUCCESS';
export const GET_NOTIFICATIONS_VIEWS_FAIL = 'GET_NOTIFICATIONS_VIEWS_FAIL';

export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const GET_NOTIFICATIONS_COUNT_SUCCESS =
    'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_FAIL = 'GET_NOTIFICATIONS_COUNT_FAIL';

export const GET_NOTIFICATIONS_VIEWS_COUNT = 'GET_NOTIFICATIONS_VIEWS_COUNT';
export const GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS =
    'GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_VIEWS_COUNT_FAIL =
    'GET_NOTIFICATIONS_VIEWS_COUNT_FAIL';

export const NOTIFICATIONS_CHANGE_PAGE = 'NOTIFICATIONS_CHANGE_PAGE';
