import {
    GET_APPLICATIONS,
    GET_APPLICATIONS_ARCHIVED,
    GET_APPLICATIONS_REJECTED,
    APPLICATIONS_ACCEPT,
    APPLICATIONS_REJECT,
} from '../_constants/actions/applicationsTracking.constants';
import { TRACKING_DECLINED } from '../../admin/_constants/actions/tracking.constants';

export const ApplicationsTrackingActions = {
    getApplications,
    getArchivedApplications,
    getRejectedApplications,
    acceptJob,
    rejectJob,
};

function getApplications(profileId) {
    return { type: GET_APPLICATIONS, payload: { profileId } };
}

function getArchivedApplications(profileId) {
    return { type: GET_APPLICATIONS_ARCHIVED, payload: { profileId } };
}

function getRejectedApplications(profileId) {
    return { type: GET_APPLICATIONS_REJECTED, payload: { profileId } };
}

function acceptJob(jobId, jobPostId, profileId) {
    return {
        type: APPLICATIONS_ACCEPT,
        payload: {
            id: jobId,
            oldStatus: 'INVITED',
            newStatus: 'ACCEPTED',
            jobPostId,
            profileId,
        },
    };
}

function rejectJob(jobId, jobPostId, profileId) {
    return {
        type: APPLICATIONS_REJECT,
        payload: {
            id: jobId,
            oldStatus: 'INVITED',
            newStatus: TRACKING_DECLINED,
            jobPostId,
            profileId,
        },
    };
}
