import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollIntoView() {
    const { hash } = useLocation();
    useEffect(() => {
        scroll();
    }, [hash]);

    function scroll() {
        const element = hash ? document.getElementById(hash.replace('#', '')) : null;
        if (element) {
            const bodyRect = document.body.getBoundingClientRect();
            const rect = element.getBoundingClientRect();
            window.scrollTo(0, rect.top - bodyRect.top);
        }
    }

    return null;
}
