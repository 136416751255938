import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { ShareButton } from './buttons/ShareButton';
import { InlineLinks } from './links';

/**
 *
 * @param {{
 *   heading?: string,
 *   links?: { path: string, label: string, exact?: boolean, count?: number, iconName?: React.Element }[],
 *   extraLinksContent?: React.Element
 * }} props
 */
export function BasePageLayout(props) {
    const {
        children,
        extraContent,
        extraLinksContent,
        headerContent,
        linksContent = null,
        links,
        id,
        heading,
        subHeading,
        useHashLinks,
        withShareButton = false,
        forwardRef,
        contentProps,
        onShare,
        shareButtonProps,
        ...restProps
    } = props;

    return (
        <div
            className="base-page-layout"
            id={id}
            ref={forwardRef}
            {...restProps}
        >
            <div className="base-page-layout__head">
                {heading ? <h3>{heading}</h3> : null}
                {subHeading ? <h5>{subHeading}</h5> : null}
                {headerContent}
            </div>
            {renderLinks()}
            <div className="base-page-layout__content" {...contentProps}>
                {children}
            </div>
            {extraContent}
        </div>
    );

    function renderLinks() {
        if (linksContent) {
            return renderControls(linksContent);
        }

        if (!links) {
            return null;
        }

        return renderControls(
            links.map((link, index) =>
                useHashLinks ? (
                    <NavHashLink
                        key={index}
                        isActive={() => index === 0}
                        to={link.path}
                        exact={link.exact}
                        className={link.className}
                        smooth
                    >
                        {renderIcon(link.iconName)}
                        {link.label}
                    </NavHashLink>
                ) : (
                    <NavLink
                        key={index}
                        to={link.path}
                        exact={link.exact}
                        className={link.className}
                    >
                        {renderIcon(link.iconName)}
                        {link.label}
                        {link.count !== null && link.count !== undefined ? (
                            <span className="link-bubble">{link.count}</span>
                        ) : null}
                    </NavLink>
                )
            )
        );
    }

    function renderControls(content) {
        return (
            <div className="base-page-layout__controls">
                <InlineLinks>{content}</InlineLinks>
                {extraLinksContent}
                {withShareButton ? (
                    <ShareButton onClick={onShare} {...shareButtonProps} />
                ) : null}
            </div>
        );
    }
}

function renderIcon(name) {
    if (!name) {
        return null;
    }

    return <i className={'icon-' + name} />;
}
