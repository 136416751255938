import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate.ts';

import { LoadingOverlay, ConfirmDialog } from '../../../../common/components';
import {
    TRACKING_APPLIED,
    TRACKING_INVITED,
    TRACKING_ACCEPTED,
    TRACKING_DECLINED,
    TRACKING_SHORTLISTED,
} from '../../../../admin/_constants/actions/tracking.constants';
import { StatusColumn } from './StatusColumn';
import { DeleteDrop } from '../../../../admin/components';
import { ApplicationsTrackingSelectors } from '../../../_selectors/ApplicationsTrackingSelectors';
import { ApplicationsTrackingActions } from '../../../_actions';

export function Active({ profileId }) {
    const [rejected, setRejected] = useState(null);

    const { isLoading, applied, shortlisted, accepted, invited } = useSelector(
        mapState
    );

    const { rejectJob, getApplications, acceptJob } = mapActions(useDispatch());

    const [visualState, setVisualState] = useState({
        showDelete: false,
        cardType: null,
    });

    const translate = useTranslate('candidate.jobs.tracking');

    useEffect(() => {
        if (profileId) {
            getApplications(profileId);
        }
    }, [profileId]);

    const isConfirmVisible = !!rejected;

    return (
        <div>
            <ConfirmDialog
                isOpen={isConfirmVisible}
                onCancel={() => setRejected(null)}
                onConfirm={() => {
                    const { id, jobPost } = rejected;
                    rejectJob(id, jobPost.jobPostId, profileId);
                    setRejected(null);
                }}
                label={'candidate.jobs.tracking.reject.label'}
                confirmText={'candidate.jobs.tracking.reject.button'}
                title={'candidate.jobs.tracking.reject.title'}
            />
            <LoadingOverlay isLoading={isLoading} />
            <div id="applications-tracking--grid">
                <StatusColumn
                    label={translate('applied')}
                    type={TRACKING_APPLIED}
                    cards={applied}
                />
                <StatusColumn
                    label={translate('shortlist')}
                    type={TRACKING_SHORTLISTED}
                    cards={shortlisted}
                />
                <StatusColumn
                    label={translate('invited')}
                    type={TRACKING_INVITED}
                    onCardDragStart={onCardDragStart}
                    onCardDragEnd={handleDrop}
                    cards={invited}
                    dragAccept={TRACKING_INVITED}
                    canDrag
                    canDrop
                />
                <StatusColumn
                    label={translate('accepted')}
                    type={TRACKING_ACCEPTED}
                    isHighlighted={visualState.cardType === TRACKING_INVITED}
                    cards={accepted}
                    accept={[TRACKING_INVITED, TRACKING_ACCEPTED]}
                    dragAccept={[TRACKING_INVITED, TRACKING_ACCEPTED]}
                    canDrop
                    canDrag
                />
            </div>
            <DeleteDrop
                isVisible={visualState.showDelete}
                type={TRACKING_DECLINED}
                accept={TRACKING_INVITED}
            />
        </div>
    );

    function onCardDragStart(item) {
        setVisualState({ showDelete: true, cardType: item.type });
    }

    function handleDrop(item, dropResult) {
        setVisualState({ showDelete: false, cardType: null });

        if (!dropResult) {
            return;
        }

        const type = dropResult.type;

        if (type === TRACKING_DECLINED) {
            setRejected(item.payload);
            return;
        }
        const { id, jobPost } = item.payload;
        acceptJob(id, jobPost.jobPostId, profileId);
    }
}

function mapState(state) {
    return {
        isLoading: state.candidate.jobs.tracking.loading,
        applied: ApplicationsTrackingSelectors.getApplied(state),
        shortlisted: ApplicationsTrackingSelectors.getShortlisted(state),
        accepted: ApplicationsTrackingSelectors.getAccepted(state),
        invited: ApplicationsTrackingSelectors.getInvited(state),
    };
}

function mapActions(dispatch) {
    return {
        rejectJob: (id, jobPostId, profileId) =>
            void dispatch(
                ApplicationsTrackingActions.rejectJob(id, jobPostId, profileId)
            ),
        getApplications: (profileId) =>
            void dispatch(
                ApplicationsTrackingActions.getApplications(profileId)
            ),
        acceptJob: (id, jobPostId, profileId) =>
            void dispatch(
                ApplicationsTrackingActions.acceptJob(id, jobPostId, profileId)
            ),
    };
}
