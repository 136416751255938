import moment from "moment";
import ICompanyJob from "../../common/types/ICompanyJob";
import ICurrency from "../../common/types/ICurrency";
import IIdealQualification from "../../common/types/IIdealQualification";
import ILanguage from "../../common/types/ILanguage";
import ILanguageLevel from "../../common/types/ILanguageLevel";
import IMetric from "../../common/types/IMetric";
import IMetricType from "../../common/types/IMetricType";
import ISelectType from "../../common/types/ISelectType";
import ISkill from "../../common/types/ISkill";
import ISkillWeightType from "../../common/types/ISkillWeightType";
import RequestService2 from "../../common/_services/RequestService2";
import ISalaryType from "../../common/types/ISalaryType";

const ENDPOINT = "job-posts";

const AdminJobService = {
    createJob,
    editJob,
    editSalary,
    getJobs,
    getJobPost,
    getEmploymentTypes,
    getSalaryTypes,
    searchBenefits,
    saveBenefit,
    deleteBenefit,
    getWorkloads,
    saveSkills,
    deleteSkill,
    publishJob,
    unpublishJob,
    saveLanguage,
    deleteLanguage,
    saveQualification,
    deleteQualification,
    saveJobExperience,
    saveInfo,
};

async function createJob() {
    return await RequestService2.put(ENDPOINT);
}

export type EditJobPayload = Partial<ICompanyJob>;

type EditJobBody = {
    id: number;
    title: string;
    companyId: number;
    description: string;
    // typeId: number;
    employmentTypeId: number;
    applicationDate: string;
    startDate: string;
    remote?: boolean;
    jobCategoryIds: number[];
    locationIds: string[];
};

async function editJob(payload: Partial<EditJobPayload>) {
    const { startDate, applicationDate, employmentType, locations, ...rest } =
        payload;

    const body: Partial<EditJobBody> = {
        startDate: startDate && moment.utc(startDate).format(),
        applicationDate: applicationDate && moment.utc(applicationDate).format(),
        employmentTypeId: employmentType?.id,
        locationIds: locations.map((value) => value.placeId || value.id),
        ...rest,
    };
    return await RequestService2.put<Partial<EditJobBody>>(ENDPOINT, body);
}

type EditSalaryPayload = {
    id: number;
    salaryRange: {
        currency: ICurrency;
        type: ISalaryType;
        amountMax: number;
        amountMin: number;
    };
    workLoadMetric: string;
    workLoadMin: number;
    workLoadMax: number;
};

async function editSalary(payload: EditSalaryPayload) {
    const {
        id,
        salaryRange: { currency, type, ...salaryRest },
        workLoadMetric,
        workLoadMin,
        workLoadMax,
    } = payload;

    const body = {
        id,
        salaryRange: {
            currencyId: currency.id,
            typeId: type.id,
            ...salaryRest,
            workload: { id: workLoadMetric, name: workLoadMetric },
            workLoadMin,
            workLoadMax,
        },
    };
    return await RequestService2.put(`${ENDPOINT}/salary-ranges`, body);
}

async function getJobs(companyId: number) {
    return await RequestService2.get<any[]>(
        `api/companies/${companyId}/job-cards`
    );
}

async function getJobPost(payload: { jobPostId: number }) {
    const { jobPostId } = payload;
    return await RequestService2.get<ICompanyJob>(`${ENDPOINT}/${jobPostId}`);
}

async function getEmploymentTypes() {
    return await RequestService2.get<any[]>("employment-types");
}

async function getSalaryTypes() {
    return await RequestService2.get<any[]>("salary-types");
}

async function searchBenefits(search: string) {
    return (
        await RequestService2.get(
            `${ENDPOINT}/${0}/benefits/autocomplete?search=${search}`
        )
    ).data;
}

export type SaveBenefitPayload = {
    id?: number;
    jobPostId: number;
    category: ISelectType;
    featured?: boolean;
    metricType: IMetricType;
    metric?: IMetric;
    value?: number;
    currency?: ICurrency;
    orderIndex?: number;
};

async function saveBenefit(payload: SaveBenefitPayload) {
    const { jobPostId, metric, metricType, currency, category, ...rest } =
        payload;
    return await RequestService2.put(`${ENDPOINT}/${jobPostId}/benefits`, {
        jobPostId,
        metric: metric?.id,
        metricType: metricType?.id,
        currencyId: currency?.id,
        jobBenefitId: category?.id,
        ...rest,
    });
}

async function deleteBenefit(payload: { jobPostId: number; id: number }) {
    const { jobPostId, id } = payload;
    return await RequestService2.delete(
        `${ENDPOINT}/${jobPostId}/benefits/${id}`
    );
}

async function getWorkloads() {
    return await RequestService2.get("workload-metrics");
}

type SaveSkillsBase = {
    id?: number;
    jobPostId: number;
    weightType?: ISkillWeightType;
    timeYearCountMin?: number;
    timeYearCountMax?: number;
    levelMin: number;
    levelMax: number;
};

type SaveSkillsPayload = {
    skills: ISkill[];
} & SaveSkillsBase;

type SaveSkillsBody = {
    skillIds: number[];
} & SaveSkillsBase;

async function saveSkills(payload: SaveSkillsPayload) {
    const { jobPostId, skills, ...rest } = payload;

    const skillIds = skills.map((skill) => skill.id);

    return await RequestService2.put<SaveSkillsBody, { id: number }>(
        `${ENDPOINT}/${jobPostId}/skills`,
        { skillIds, jobPostId, ...rest }
    );
}

type DeleteSkillPayload = {
    jobPostId: number;
    skillGroupId: number;
};

async function deleteSkill(payload: DeleteSkillPayload) {
    const { skillGroupId, jobPostId } = payload;

    return await RequestService2.delete(
        `${ENDPOINT}/${jobPostId}/skills/${skillGroupId}`
    );
}

type SaveLanguageBase = {
    id?: number;
    weightType: ISkillWeightType;
};

type SaveLanguagePayload = {
    language: ILanguage;
    levelMin: ILanguageLevel;
    levelMax: ILanguageLevel;
    jobPostId: number;
} & SaveLanguageBase;

type SaveLanguageBody = {
    languageId: number;
    levelMin: number;
    levelMax: number;
} & SaveLanguageBase;

async function saveLanguage(payload: SaveLanguagePayload) {
    const { jobPostId, language, levelMin, levelMax, ...rest } = payload;

    return await RequestService2.put<SaveLanguageBody, { id: number }>(
        `${ENDPOINT}/${jobPostId}/languages`,
        {
            languageId: language.id,
            levelMax: levelMax?.id,
            levelMin: levelMin?.id,
            ...rest,
        }
    );
}

type DeleteLanguagePayload = {
    jobPostId: number;
    id: number;
};

async function deleteLanguage(payload: DeleteLanguagePayload) {
    const { id, jobPostId } = payload;

    return await RequestService2.delete(
        `${ENDPOINT}/${jobPostId}/languages/${id}`
    );
}

type PublishJobPayload = {
    jobPostId: number;
};

async function publishJob(payload: PublishJobPayload) {
    const { jobPostId } = payload;

    return await RequestService2.post(`${ENDPOINT}/${jobPostId}/publish`);
}

async function unpublishJob(payload: PublishJobPayload) {
    const { jobPostId } = payload;

    return await RequestService2.post(`${ENDPOINT}/${jobPostId}/unpublish`);
}

interface SaveQualificationPayload extends Partial<IIdealQualification> {
    jobPostId: number;
}

type SaveQualificationBody = {
    type?: string;
    universityDegreeFieldCategoryIds: number[];
    universityDegreeFieldIds: number[];
    educationYearMin?: number;
    educationYearMax?: number;
    educationLevelMin: number;
    educationLevelMax: number;
    educationCompleted?: boolean;
};

async function saveQualification(payload: SaveQualificationPayload) {
    const {
        jobPostId,
        universityDegreeFieldCategories,
        universityDegreeFields,
        educationLevelMax,
        educationLevelMin,
        universityDegreeTitles,
        ...rest
    } = payload;

    return await RequestService2.put<SaveQualificationBody, { id: number }>(
        `${ENDPOINT}/${jobPostId}/qualifications/items`,
        {
            universityDegreeFieldCategoryIds:
                universityDegreeFieldCategories?.map((item) => item.id) || [],
            universityDegreeFieldIds:
                universityDegreeFields?.map((item) => item.id) || [],
            educationLevelMax: educationLevelMax?.id,
            educationLevelMin: educationLevelMin?.id,
            ...rest,
        }
    );
}

type DeleteQualificationPayload = {
    jobPostId: number;
    id: number;
};

async function deleteQualification(payload: DeleteQualificationPayload) {
    const { id, jobPostId } = payload;

    return await RequestService2.delete(
        `${ENDPOINT}/${jobPostId}/qualifications/items/${id}`
    );
}

type SaveJobExperiencePayload = {
    jobPostId: number;
    jobExperienceYearMin: number;
    jobExperienceYearMax: number;
};

async function saveJobExperience(payload: SaveJobExperiencePayload) {
    const { jobPostId, jobExperienceYearMax, jobExperienceYearMin } = payload;

    return await RequestService2.put(
        `${ENDPOINT}/${jobPostId}/ideal-candidates`,
        { jobExperienceYearMax, jobExperienceYearMin }
    );
}

type SaveInfoPayload = {
    jobPostId: number;
    jobOtherInfos: {
        id?: number;
        description: string;
        lang: string;
        order: number;
    }[];
};

async function saveInfo(payload: SaveInfoPayload) {
    const { jobPostId, jobOtherInfos } = payload;

    return await RequestService2.put(`${ENDPOINT}/other-infos`, {
        jobPostId,
        jobOtherInfos,
    });
}

export default AdminJobService;
