import React from "react";
import { useTranslate } from "react-translate.ts";
import {
    RadarChart,
    PolarGrid,
    Radar,
    Dot,
    Tooltip,
    PolarAngleAxis,
    ResponsiveContainer,
    Customized,
} from "recharts";

import {
    PRIMARY_COLOR,
    BREAKPOINT_SM,
    BREAKPOINT_XS,
} from "../../../../common/_constants/theme.constants";
import { Button, useWindowState } from "../../../../common/components";
import { useRouteMatch, Link } from "react-router-dom";
import { resolveSectionColor } from "../../Interpretation/resolveSectionColor";
import { BaseDiagnostic } from "./BaseDiagnostic";
import { BaseTooltip } from "../../../../common/components/charts/BaseTooltip";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export function BaseMop(props) {
    const {
        name,
        extraChartContent,
        interpretationContentEmpty,
        interpretationContentProcessed,
        getStatus,
        getInterpretation,
        isMobile,
        withInfo = true
    } = props;

    const translateMop = useTranslate(TRANSLATE_KEY + "." + name);
    const translate = useTranslate(TRANSLATE_KEY);

    const routeMatch = useRouteMatch();

    const { width } = useWindowState();

    // const height = isMobile ? 360 : 420;
    // const radius = isMobile ? 90 : 170;
    const { height, radius } = resolveChartSize(width);

    return (
        <BaseDiagnostic
            id="soft-skills-test--mop"
            name={name}
            contentUnprocessed={renderEmptyChart}
            contentProcessed={renderChart}
            interpretationContentEmpty={interpretationContentEmpty}
            interpretationContentProcessed={interpretationContentProcessed}
            getStatus={getStatus}
            getInterpretation={getInterpretation}
            isMobile={isMobile}
        />
    );

    function renderEmptyChart(interpretation) {
        const data = createChartData(interpretation.softSkills);

        return (
            <>
                <div id="soft-skills-test--mop__chart">
                    <ResponsiveContainer
                        aspect={1.4}
                        width="100%"
                        height="100%"
                        maxHeight={height}
                    >
                        <RadarChart
                            width={isMobile ? 550 : 650}
                            height={height}
                            outerRadius={radius}
                            data={data}
                        >
                            <Customized
                                component={(props) => {
                                    if (
                                        ArrayHelper.isEmpty(
                                            props.formatedGraphicalItems
                                        )
                                    ) {
                                        return null;
                                    }

                                    const formatedGraphicalItems =
                                        props.formatedGraphicalItems[0];
                                    if (
                                        !formatedGraphicalItems.props ||
                                        !formatedGraphicalItems.props.points
                                    ) {
                                        return null;
                                    }

                                    const points =
                                        formatedGraphicalItems.props.points;

                                    const lines = [];
                                    const n = points.length;
                                    for (let i = 0; i < n; i++) {
                                        const point = points[i];
                                        let nextPoint = null;
                                        if (i < n - 1) {
                                            nextPoint = points[i + 1];
                                        } else {
                                            nextPoint = points[0];
                                        }

                                        lines.push(
                                            <line
                                                key={i}
                                                x1={point.x}
                                                y1={point.y}
                                                x2={nextPoint.x}
                                                y2={nextPoint.y}
                                                stroke="#e7e6e6"
                                                strokeWidth={1}
                                            />
                                        );
                                    }
                                    return lines;
                                }}
                            />
                            <Radar
                                legendType="none"
                                dataKey="border"
                                fill="#fff"
                                fillOpacity={0}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={(props) => {
                                    return (
                                        <Dot
                                            {...props}
                                            r={4}
                                            fill={PRIMARY_COLOR}
                                            fillOpacity={1}
                                        />
                                    );
                                }}
                                activeDot={false}
                            />
                            {isMobile ? null : (
                                <PolarAngleAxis
                                    dataKey="name"
                                    tickLine={{ size: 20 }}
                                    tick={{ fill: "#293140", fontSize: 12 }}
                                />
                            )}
                        </RadarChart>
                    </ResponsiveContainer>
                    <div id="soft-skills-test--mop__chart__content">
                        <div>{translateMop("startTitle")}</div>
                        {!isMobile ? (
                            <Link to={routeMatch.path + "/test"}>
                                <Button>{translateMop("start")}</Button>
                            </Link>
                        ) : null}
                    </div>
                    {isMobile ? (
                        <Link to={routeMatch.path + "/test"}>
                            <Button style={{ width: "100%" }}>
                                {translateMop("start")}
                            </Button>
                        </Link>
                    ) : null}
                </div>
                {withInfo && <p dangerouslySetInnerHTML={{ __html: translateMop("info") }} />}
            </>
        );
    }

    function renderChart(interpretation) {
        const data = createChartData(interpretation.softSkills);

        return (
            <>
                <div id="soft-skills-test--mop__chart">
                    <ResponsiveContainer
                        aspect={1}
                        width="100%"
                        height="100%"
                        maxHeight={height}
                    >
                        <RadarChart
                            width={isMobile ? 550 : 650}
                            height={height}
                            outerRadius={radius}
                            data={data}
                        >
                            <PolarGrid stroke="#e7e6e6" gridType="polygon" />
                            <Radar
                                name="You"
                                dataKey="value"
                                stroke={PRIMARY_COLOR}
                                fillOpacity={1}
                                strokeWidth={2}
                                shape={(props) => {
                                    const { points } = props;
                                    const firstPoint = points && points[0];
                                    const { cx, cy } = firstPoint;
                                    const shape = [];

                                    points.forEach((point, index) => {
                                        let nextPoint;
                                        if (index < points.length - 1) {
                                            nextPoint = points[index + 1];
                                        } else {
                                            nextPoint = firstPoint;
                                        }
                                        const { x, y, payload } = point;
                                        const color = resolveSectionColor(
                                            payload.category
                                        );
                                        const polygon = (
                                            <polygon
                                                key={index}
                                                points={`${cx},${cy} ${x},${y} ${nextPoint.x},${nextPoint.y} ${cx},${cy}`}
                                                fill={color}
                                                stroke={color}
                                                strokeWidth={1}
                                            />
                                        );

                                        shape.push(polygon);
                                    });

                                    return shape;
                                }}
                                dot={(props) => {
                                    const { strokeWidth, payload } = props;
                                    const { category } = payload.payload;

                                    return (
                                        <Dot
                                            {...props}
                                            r={strokeWidth * 2}
                                            strokeWidth={strokeWidth}
                                            stroke="#fff"
                                            fill={resolveSectionColor(category)}
                                            fillOpacity={1}
                                        />
                                    );
                                }}
                            />
                            <Radar
                                legendType="none"
                                dataKey="border"
                                fill="#fff"
                                fillOpacity={0}
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={(props) => {
                                    const { payload } = props;
                                    const { category } = payload.payload;

                                    return (
                                        <Dot
                                            {...props}
                                            r={4}
                                            fill={resolveSectionColor(category)}
                                            fillOpacity={1}
                                        />
                                    );
                                }}
                                activeDot={false}
                            />
                            <Tooltip
                                isAnimationActive={false}
                                content={(props) => {
                                    if (!props.payload || !props.payload[0]) {
                                        return null;
                                    }
                                    const value =
                                        props.payload[0].payload.value;

                                    return (
                                        <BaseTooltip
                                            isAbsolute={false}
                                            withArrow={false}
                                            title={translate("you")}
                                            value={value + "%"}
                                        />
                                    );
                                }}
                            />
                            <PolarAngleAxis
                                dataKey={isMobile ? "index" : "name"}
                                tickLine={{ size: isMobile ? 16 : 20 }}
                                tick={{ fill: "#293140", fontSize: 12 }}
                            />
                        </RadarChart>
                    </ResponsiveContainer>
                </div>
                {isMobile ? renderLegend(data) : null}
                {extraChartContent && extraChartContent(interpretation)}
            </>
        );
    }
}

function renderLegend(items) {
    return (
        <div id="soft-skills-test--mop__chart__item-legend">
            {items.map((item, key) => {
                return (
                    <div key={key}>
                        {key + 1}. {item.name}
                    </div>
                );
            })}
        </div>
    );
}

function createChartData(input) {
    if (!input) {
        return [];
    }

    const keys = Object.keys(input);
    let index = 1;
    return keys.reduce((prev, key) => {
        if (!input[key]) {
            return prev;
        }

        return [...prev, ...input[key].map(map)];

        function map(item) {
            const result = {
                value: item.value,
                border: 100,
                name: item.skill.name,
                category: item.skill.section,
                index: index,
            };
            index++;
            return result;
        }
    }, []);
}

function resolveChartSize(screenWidth) {
    if (screenWidth < BREAKPOINT_XS) {
        return { height: 360, radius: 100 };
    } else if (screenWidth >= BREAKPOINT_XS && screenWidth < BREAKPOINT_SM) {
        return { height: 400, radius: 130 };
    }

    return { height: 420, radius: 170 };
}
