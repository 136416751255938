import { Translate } from 'react-translate.ts';
import { Button } from '../../../../common/components';

export function FormButtons({
    onCancel,
    onConfirm,
    cancelText,
    confirmText,
    withCancel = true,
    cancelOnly = false,
    confirmDisabled,
    prepend
}) {
    return render();
    function render() {
        return (
            <div className="flex justify-center padding-top-40">
                {prepend}
                {withCancel ? (
                    <Button
                        onClick={onCancel}
                        variant="secondary"
                        className="margin-right-5"
                        isThin
                    >
                        <Translate textOnly>
                            {cancelText || 'common.actions.cancel'}
                        </Translate>
                    </Button>
                ) : null}
                {!cancelOnly ? (
                    <Button
                        onClick={onConfirm}
                        className="margin-left-5"
                        isThin
                        disabled={confirmDisabled}
                    >
                        <Translate textOnly>
                            {confirmText || 'common.actions.apply'}
                        </Translate>
                    </Button>
                ) : null}
            </div>
        );
    }
}
