export const ONBOARDING_PERSONAL_BEGIN = 'ONBOARDING_PERSONAL_BEGIN';
export const ONBOARDING_PERSONAL_SUCCESS = 'ONBOARDING_PERSONAL_SUCCESS';
export const ONBOARDING_PERSONAL_ERROR = 'ONBOARDING_PERSONAL_ERROR';

export const ONBOARDING_EDUCATION_BEGIN = 'ONBOARDING_EDUCATION_BEGIN';
export const ONBOARDING_EDUCATION_SUCCESS = 'ONBOARDING_EDUCATION_SUCCESS';
export const ONBOARDING_EDUCATION_ERROR = 'ONBOARDING_EDUCATION_ERROR';

export const ONBOARDING_TEST_BEGIN = 'ONBOARDING_TEST_BEGIN';
export const ONBOARDING_TEST_SUCCESS = 'ONBOARDING_TEST_SUCCESS';
export const ONBOARDING_TEST_ERROR = 'ONBOARDING_TEST_ERROR';

export const ONBOARDING_SKIP_BEGIN = 'ONBOARDING_SKIP_BEGIN';
export const ONBOARDING_SKIP_SUCCESS = 'ONBOARDING_SKIP_SUCCESS';
export const ONBOARDING_SKIP_ERROR = 'ONBOARDING_SKIP_ERROR';

export const ONBOARDING_GO_BACK = 'ONBOARDING_GO_BACK';
