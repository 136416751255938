import { profileConstants } from '../../_constants';

const initialState = {
    isLoading: false,
    isLoaded: false,
    hasBeenEdited: false
};

export default function profile(state = initialState, action) {
    switch (action.type) {
        case profileConstants.MY_PROFILE_BEGIN:
            return { ...state, isLoading: true };
        case profileConstants.MY_PROFILE_SUCCESS:
            return { isLoading: false, isLoaded: true };
        case profileConstants.MY_PROFILE_FAILURE:
            return { ...state, isLoading: false };
        case profileConstants.PHOTO_UPLOAD_BEGIN:
            return { isLoading: true, isLoaded: false, hasBeenEdited: false };
        case profileConstants.PHOTO_UPLOAD_SUCCESS:
            return { ...state, isLoading: false, hasBeenEdited: true };
        case profileConstants.PHOTO_UPLOAD_ERROR:
            return { ...state, isLoading: false, hasBeenEdited: false };
        default:
            return state;
    }
}
