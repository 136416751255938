import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { NotificationActions } from "../common/_actions";

const errorNotificationMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (action.error?.message) {
            api.dispatch(NotificationActions.pushError(action.error.message));
        }

        return next(action);
    };

export default errorNotificationMiddleware;
