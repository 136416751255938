import {
    GET_TRACKING_CARDS,
    GET_TRACKING_CARDS_SUCCESS,
    GET_TRACKING_CARDS_FAIL,
    CHANGE_TRACKING_STATE,
    CHANGE_TRACKING_STATE_SUCCESS,
    CHANGE_TRACKING_STATE_FAIL,
    GET_ARCHIVED_CARDS,
    REJECT_CANDIDATE,
    GET_ARCHIVED_CARDS_SUCCESS,
    REJECT_CANDIDATE_SUCCESS,
    GET_ARCHIVED_CARDS_FAIL,
    REJECT_CANDIDATE_FAIL,
    GET_DECLINED_CARDS_SUCCESS,
    GET_DECLINED_CARDS_FAIL,
    GET_DECLINED_CARDS
} from '../../../_constants/actions/candidateTracking.constants';

export default function(state = false, action) {
    switch (action.type) {
        case GET_TRACKING_CARDS:
        case GET_ARCHIVED_CARDS:
        case GET_DECLINED_CARDS:
        case CHANGE_TRACKING_STATE:
        case REJECT_CANDIDATE:
            return true;
        case GET_TRACKING_CARDS_SUCCESS:
        case GET_ARCHIVED_CARDS_SUCCESS:
        case GET_DECLINED_CARDS_SUCCESS:
        case CHANGE_TRACKING_STATE_SUCCESS:
        case REJECT_CANDIDATE_SUCCESS:
            return false;
        case GET_TRACKING_CARDS_FAIL:
        case GET_ARCHIVED_CARDS_FAIL:
        case GET_DECLINED_CARDS_FAIL:
        case CHANGE_TRACKING_STATE_FAIL:
        case REJECT_CANDIDATE_FAIL:
            return false;
        default:
            return state;
    }
}
