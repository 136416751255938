import { cx } from "@emotion/css";
import moment from "moment";
import { generatePath, Link } from "react-router-dom";
import { Translate, useTranslate } from "react-translate.ts";
import OptionsMenu from "../../../../common/components/OptionsMenu/OptionsMenu";

import { Panel } from "../../../../common/components/Panel/Panel";
import { ADMIN_JOB_EDIT_DETAIL_PATH } from "../../../../common/_constants/route.constants";
import { PRIMARY_COLOR } from "../../../../common/_constants/theme.constants";
import { formatDate } from "../../../../common/_helpers";
import styles from "./AdminJobListItem.module.scss";

type AdminJobListItemProps = {
    id: number;
    name: string;
    status: "ACTIVE" | "DRAFT";
    category: string;
    jobType: string;
    location: string;
    created: string;
    candidates: number;
    // applications: Applications;
    applied: number;
    invited: number;
};

type Applications = {
    applied: number;
    shortlist?: number;
    invited: number;
    accepted?: number;
};

export default function AdminJobListItem(props: AdminJobListItemProps) {
    const {
        id,
        name,
        status,
        category,
        jobType,
        location,
        created,
        candidates,
        applied,
        invited,
        // applications,
    } = props;

    const translate = useTranslate("admin.jobs.list");

    return (
        <Panel
            className={styles.item}
            style={{ marginBottom: 26 }}
            data-test="job-list-item"
        >
            <Link to={generatePath(ADMIN_JOB_EDIT_DETAIL_PATH, { id })} />
            <div className={styles.head}>
                <div className={styles.name}>{name || translate("newJob")}</div>
                <div className={styles.optionsWrapper}>
                    <div
                        className={cx(
                            styles.status,
                            status === "ACTIVE" ? styles.status__active : null
                        )}
                    >
                        {translate(`status.${status}`)}
                    </div>
                    <OptionsMenu className={styles.options}>
                        <OptionsMenu.Option
                            onClick={handleDelete}
                            iconProps={{ name: "trash-light" }}
                        >
                            <Translate textOnly>
                                common.actions.delete
                            </Translate>
                        </OptionsMenu.Option>
                    </OptionsMenu>
                </div>
            </div>
            <div className={styles.info}>
                {category ? <div>{category}</div> : null}
                {jobType ? <div>{jobType}</div> : null}
                {location ? <div>{location}</div> : null}
            </div>
            <Applications applications={{ applied, invited }} />
            <div className={styles.stats}>
                {created ? (
                    <div>
                        {translate("created", {
                            date: formatDate(created, "LL"),
                        })}
                    </div>
                ) : null}
                {candidates > 0 ? (
                    <>
                        <div>&middot;</div>
                        <div>{translate("total", { count: candidates })}</div>
                    </>
                ) : null}
            </div>
        </Panel>
    );

    function handleDelete() {
        console.log("DELETE");
    }
}

function Applications(props: { applications: Applications }) {
    const translate = useTranslate("admin.candidate.tracking");

    return (
        <div className={styles.applications}>
            <AppItem
                value={props.applications?.applied}
                className={styles.primary}
                isFirst
                label={translate("new")}
            />
            <AppItem
                value={props.applications?.shortlist}
                label={translate("shortlist")}
            />
            <AppItem
                value={props.applications?.invited}
                label={translate("sent-offers")}
            />
            <AppItem
                value={props.applications?.accepted}
                label={translate("accepted")}
            />
        </div>
    );

    function AppItem(props: {
        value: number;
        label: string;
        className?: string;
        isFirst?: boolean;
    }) {
        const { value, label, className = styles.secondary, isFirst } = props;
        return (
            <div className={cx(styles.app, value > 0 ? className : null)}>
                <div
                    className={cx(
                        styles.app__top,
                        isFirst ? styles.start : null
                    )}
                />
                <div
                    className={cx(
                        styles.app__bottom,
                        isFirst ? styles.start : null
                    )}
                />
                <div className={styles.app__content}>
                    <div className={styles.app__content__value}>
                        {value || 0}
                    </div>
                    <div>{label}</div>
                </div>
            </div>
        );
    }
}
