import { useEffect, useRef } from 'react';

import { addEventHandler, removeEventHandler } from '../_helpers';
import { ESC_KEY } from '../_constants/input.constants';

/**
 * @param {boolean} isOpen
 * @param {() => void} onClose
 */
export function useDropdown(isOpen, onClose) {
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            addEvents();
        } else {
            removeEvents();
        }

        return () => removeEvents();
    }, [isOpen]);

    return dropdownRef;

    function handleClose(event) {
        const current = dropdownRef.current;

        if (!current || !event.target) {
            return;
        }

        const parent = current.parentNode;

        if (current.contains(event.target) || parent.contains(event.target)) {
            return;
        }

        onClose();
    }

    function handleKeys(event) {
        const key = event.which || event.keyCode;

        if (key === ESC_KEY) {
            onClose();
        }
    }

    function addEvents() {
        addEventHandler(window, 'click', handleClose);
        addEventHandler(window, 'keydown', handleKeys);
    }

    function removeEvents() {
        removeEventHandler(window, 'click', handleClose);
        removeEventHandler(window, 'keydown', handleKeys);
    }
}
