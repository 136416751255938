export const ESC_KEY = 27;
export const ENTER_KEY = 13;
export const SPACEBAR_KEY = 32;
export const TAB_KEY = 9;

export const ENTER = "Enter";
export const ESCAPE = "Escape";
export const TAB = 'Tab';

export const ARROW_UP = "ArrowUp";
export const ARROW_DOWN = "ArrowDown";

export const PASSWORD_TYPE = "password";
export const TEXT_TYPE = "text";
