import React from 'react';
import { useSelector } from 'react-redux';

import { BaseNotificationMenu } from './BaseNotificationMenu';
import { NotificationSelectors } from '../../_selectors/NotificationSelectors';
import { NotificationActions } from '../../_actions';

export function NotificationMenu(props) {
    const { isOpen, onClose } = props;
    const { list, isLoading } = useSelector(mapState);

    return (
        <BaseNotificationMenu
            isOpen={isOpen}
            onClose={onClose}
            action={NotificationActions.getNotifications}
            list={list}
            isLoading={isLoading}
            arrowRight={35}
            id="notification-menu"
        />
    );
}

function mapState(state) {
    return {
        list: NotificationSelectors.getHistory(state),
        isLoading: NotificationSelectors.isHistoryLoading(state),
    };
}
