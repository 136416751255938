import { combineReducers } from "redux";
import SoftSkillsConstants from "../../../_constants/actions/SoftSkillsConstants";

const defaultState = {
    MOP: null,
};

const data = (state = defaultState, action) => {
    if (action.type === SoftSkillsConstants.GET_COMPARISON_SUCCESS) {
        const { test, data } = action.payload;
        return Object.assign({}, state, { [test]: data });
    }

    return state;
};

export default combineReducers({ data });
