import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Map } from "immutable";

import { Button, FlexContainer, FlexItem } from "..";
import {
    HardSkillProcessCategories,
    SkillSearch,
} from "../../../candidate/components";
import { SkillEdit } from "../../../candidate/pages/Profile/parts/SkillEdit";
import { ProfileInformationActions } from "../../../candidate/_actions/ProfileInformationActions";
import ProfileInformationSelectors from "../../../candidate/_selectors/ProfileInformationSelectors";
import { ArrayHelper } from "../../_helpers/ArrayHelper";
import { AuthSelectors } from "../../_selectors";
import { Translate } from "react-translate.ts";

let oldSkills = null;

export function HardSkillsEditor() {
    const history = useHistory();
    const location = useLocation();

    const { skills, hardSkills } = useSelector(mapState);
    const { addSkill, updateSkill, deleteSkill } = mapDispatch(useDispatch());

    const [showList, setShowList] = useState(false);
    const [resetState, setResetState] = useState({
        business: false,
        it: false,
    });
    const [validation, setValidation] = useState({
        isValid: false,
        errors: Map(),
    });

    useEffect(() => {
        oldSkills = skills;

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return (
        <div className="hard-skills-editor">
            <div className="skills-search">
                <SkillSearch onSelect={onSkillSelect} className="w100" />
                <div className="text-center padding-20 text-secondary">
                    <Translate textOnly>common.misc.or</Translate>
                </div>
                <Button
                    variant="secondary"
                    className="flex-shrink-0"
                    onClick={() => setShowList(!showList)}
                >
                    <Translate textOnly>
                        {!showList
                            ? "candidate.profile.hardSkills.edit.choose"
                            : "candidate.profile.hardSkills.edit.hide"}
                    </Translate>
                </Button>
            </div>
            {renderBusinessList()}
            {renderITList()}
            {renderSelectedSkills()}
        </div>
    );

    function renderSelectedSkills() {
        let actualSkills = hardSkills;
        if (!!location.state && location.state.action === "ADD_HARD_SKILL") {
            actualSkills.push(location.state.skill);
        }

        if (ArrayHelper.isEmpty(actualSkills)) {
            return null;
        }

        return (
            <div>
                <Heading>
                    candidate.profile.hardSkills.edit.selectedSkills
                </Heading>
                <FlexItem size={12}>
                    <FlexContainer
                        justify="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <FlexItem size={12} sm={5}>
                            <div>
                                <FlexContainer
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <span>
                                        <Translate textOnly>
                                            candidate.profile.hardSkills.edit.title.name
                                        </Translate>
                                    </span>
                                </FlexContainer>
                            </div>
                        </FlexItem>
                        <FlexItem size={12} sm={7}>
                            <FlexContainer spacing={1} alignItems="center">
                                <FlexItem key={1} size={12} sm={6}>
                                    <Translate textOnly>
                                        candidate.profile.hardSkills.edit.title.years
                                    </Translate>
                                </FlexItem>
                                <FlexItem key={2} size={12} sm={6}>
                                    <Translate textOnly>
                                        candidate.profile.hardSkills.edit.title.level
                                    </Translate>
                                </FlexItem>
                                {/* <FlexItem key={3} size={12} sm={4}>
                                    <Translate textOnly>
                                        candidate.profile.hardSkills.edit.title.interest
                                    </Translate>
                                </FlexItem> */}
                            </FlexContainer>
                        </FlexItem>
                    </FlexContainer>
                </FlexItem>
                <FlexContainer>{actualSkills.map(renderSkill)}</FlexContainer>
            </div>
        );
    }

    function renderBusinessList() {
        if (!showList) {
            return null;
        }

        return (
            <div className="padding-bottom-40">
                <Heading>
                    candidate.profile.hardSkills.edit.businessSkills
                </Heading>
                <HardSkillProcessCategories
                    selected={hardSkills.map((skill) => skill.skill.id)}
                    onSkillSelect={onSkillSelect}
                    parentId={13}
                    onCategoryOpen={() =>
                        setResetState({ business: false, it: true })
                    }
                    shouldReset={resetState.business}
                />
            </div>
        );
    }

    function renderITList() {
        if (!showList) {
            return null;
        }

        return (
            <div className="padding-bottom-40">
                <Heading>candidate.profile.hardSkills.edit.itSkills</Heading>
                <HardSkillProcessCategories
                    selected={hardSkills.map((skill) => skill.skill.id)}
                    onSkillSelect={onSkillSelect}
                    parentId={2}
                    onCategoryOpen={() =>
                        setResetState({ business: true, it: false })
                    }
                    shouldReset={resetState.it}
                />
            </div>
        );
    }

    function renderSkill(skill, index) {
        const { timeYearCount, interest, level } = skill;
        const { name, id } = skill.skill;
        const data = [
            {
                value: timeYearCount,
                placeholder: "candidate.profile.hardSkills.edit.select.years.0",
                customRange: yearsRange,
                valueName: "timeYearCount",
            },
            {
                value: level,
                placeholder: "candidate.profile.hardSkills.edit.select.level.0",
                valueName: "level",
            },
            // {
            //     value: interest,
            //     placeholder:
            //         "candidate.profile.hardSkills.edit.select.interest",
            //     customRange: interestRange,
            //     valueName: "interest",
            // },
        ];

        const skillErrors = validation.errors.get(skill.skill.id);
        return (
            <SkillEdit
                id={name}
                key={index}
                skill={skill}
                name={name}
                skillId={id}
                data={data}
                onUpdate={onUpdate}
                onDelete={onDelete}
                errors={skillErrors}
            />
        );
    }

    function onUpdate(skill) {
        registerAlert();
        updateSkill(skill);

        const skillErrors = validateSkill(skill);
        const isValid = Object.keys(skillErrors).length < 1;
        const newErrors = validation.errors.set(skill.skill.id, skillErrors);

        setValidation({ isValid, errors: newErrors });
    }

    function onDelete(id) {
        registerAlert();
        deleteSkill(id);
    }

    function onSkillSelect(skill) {
        registerAlert();
        const { value, name, id } = skill;
        const index = hardSkills.findIndex((value) => value.skill.id === id);

        if (index >= 0) {
            deleteSkill(id);
        } else {
            addSkill({
                interest: 0,
                timeYearCount: 0,
                level: 0,
                skill: { id: value || id, name, type: "hard" },
            });
            history.replace(`${location.pathname}#${name}`);
            setResetState({ business: true, it: true });
        }
    }

    function registerAlert() {
        window.onbeforeunload = () => {
            return "confirmation";
        };
    }
}

function validateSkill({ timeYearCount, level, interest }) {
    let errors = {};
    if (timeYearCount < 1) {
        errors.timeYearCount = "required";
    }

    if (level < 1) {
        errors.level = "required";
    }

    if (interest < 1) {
        errors.interest = "required";
    }

    return errors;
}

function mapDispatch(dispatch) {
    return {
        addSkill: (skill) =>
            dispatch(ProfileInformationActions.addSkill(skill)),
        updateSkill: (skill) =>
            dispatch(ProfileInformationActions.updateSkill(skill)),
        deleteSkill: (skill) =>
            dispatch(ProfileInformationActions.deleteSkill(skill)),
    };
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        skills: ProfileInformationSelectors.getSkills(state),
        hardSkills: ProfileInformationSelectors.getHardSkillsList(state),
    };
}

function Heading({ children }) {
    return (
        <h5 className="padding-bottom-10">
            <Translate textOnly>{children}</Translate>
        </h5>
    );
}

const yearsRange = [
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.1
            </Translate>
        ),
        value: 1,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.2
            </Translate>
        ),
        value: 2,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.3
            </Translate>
        ),
        value: 3,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.4
            </Translate>
        ),
        value: 4,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.5
            </Translate>
        ),
        value: 5,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.6
            </Translate>
        ),
        value: 6,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.7
            </Translate>
        ),
        value: 7,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.8
            </Translate>
        ),
        value: 8,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.9
            </Translate>
        ),
        value: 9,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.years.10
            </Translate>
        ),
        value: 10,
    },
];

const interestRange = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
];
