import { useTranslate } from "react-translate.ts";

import ErrorPageLayout from "../components/layout/ErrorPageLayout/ErrorPageLayout";

export function NotFound() {
    const translate = useTranslate("common.404");



    return (
        <ErrorPageLayout head={translate("header")} text={translate("text")} />
    );
}


