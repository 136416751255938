import { cx } from "@emotion/css";
import { Link, generatePath } from "react-router-dom";

import { FormatMoneyRange } from "..";
import { MatchPie } from "../../../candidate/components/MatchPie/MatchPie";
import { JOB_DETAIL_PATH } from "../../_constants/route.constants";
import Button from "../buttons/Button/Button";
import Skeleton from "../Skeleton/Skeleton";
import styles from "./JobCard.module.scss";

type JobCardProps = {
    id?: number;
    name?: string;
    salaryMin?: number;
    salaryMax?: number;
    currency?: string;
    match?: number;
    isLoading?: boolean;
};

export default function JobCard(props: JobCardProps) {
    const { id, name, salaryMin, salaryMax, currency, match, isLoading } =
        props;

    const button = (
        <Button.Secondary fontSize={13} corners="round" disabled={isLoading}>
            More Details
        </Button.Secondary>
    );

    return (
        <div className={cx(styles.card, "font-heavy")}>
            <div className={styles.content}>
                <div className={styles.name}>
                    {!isLoading ? name : <Skeleton width={125} height={14} />}
                </div>
                {!isLoading ? (
                    (salaryMin || salaryMax) && currency && <span className={styles.salary}>
                        <FormatMoneyRange
                            min={salaryMin}
                            max={salaryMax}
                            currency={currency}
                        />
                    </span>
                ) : (
                    <Skeleton width={150} height={24} />
                )}
                <div className={styles.match}>
                    <MatchPie match={match || 0} isAnimationActive />
                </div>
            </div>
            <div>
                {id && !isLoading ? (
                    <Link to={generatePath(JOB_DETAIL_PATH, { jobPostId: id })}>
                        {button}
                    </Link>
                ) : (
                    button
                )}
            </div>
        </div>
    );
}
