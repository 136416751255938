import { combineReducers } from "redux";
import {
    STATE_FAIL,
    STATE_LOADING,
    STATE_SUCCESS,
} from "../../../../common/_constants/state.constants";

import {
    GET_JOB_FILTERS,
    GET_JOB_FILTERS_SUCCESS,
    GET_JOB_FILTERS_FAIL,
    GET_JOB_SALARY_RANGE_SUCCESS,
} from "../../../_constants";

export default combineReducers({ data, loading, salaryRange });

function data(
    state = {
        currencyDTOS: [],
    },
    { type, payload }
) {
    switch (type) {
        case GET_JOB_FILTERS_SUCCESS:
            return payload;
        default:
            return state;
    }
}

function salaryRange(
    state = {
        amountMin: null,
        amountMax: null,
    },
    action
) {
    if (action.type === GET_JOB_SALARY_RANGE_SUCCESS) {
        return action.payload;
    }

    return state;
}

function loading(state = false, action) {
    switch (action.type) {
        case GET_JOB_FILTERS:
            return true;
        case GET_JOB_FILTERS_SUCCESS:
        case GET_JOB_FILTERS_FAIL:
            return false;
        default:
            return state;
    }
}
