import { combineReducers } from 'redux';
import { List } from 'immutable';

import { ReducerHelpers } from '../../_helpers';
import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';

function data(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.GET_MAP_PATHS_SUCCESS:
            return action.payload.routing;
        case CareerPathsConstants.SET_ROUTING_DATA:
            return action.payload;
        default:
            return state;
    }
}

const points = (state = List([null, null]), action) => {
    switch (action.type) {
        case CareerPathsConstants.SET_POINT:
            return state.set(action.payload.index, action.payload.data);
        case CareerPathsConstants.ADD_POINT:
            const emptyIndex = state.indexOf(null);
            if (emptyIndex !== -1) {
                return state.set(emptyIndex, action.payload);
            }
            return state.set(1, action.payload);
        case CareerPathsConstants.REMOVE_POINT:
            const index = action.payload;

            if (state.size === 2 && (index === 0 || index === 1)) {
                return state.set(index, null);
            }

            return state.delete(index);
        case CareerPathsConstants.CLEAR_POINTS:
            return List([null, null]);
        default:
            return state;
    }
};

const activeRouteSearch = (state = false, action) => {
    switch (action.type) {
        case CareerPathsConstants.SET_ACTIVE_ROUTE_SEARCH:
            return action.payload;
        case CareerPathsConstants.CLEAR_POINTS:
            return false;
        default:
            return state;
    }
};

function selectedPath(state = { path: null, index: 0 }, action) {
    if (action.type === CareerPathsConstants.SET_SELECTED_PATH) {
        return action.payload;
    }

    return state;
}

const state = (state, action) =>
    ReducerHelpers.loading(state, action, CareerPathsConstants.GET_MAP_PATHS);

export default combineReducers({
    data,
    state,
    points,
    activeRouteSearch,
    selectedPath,
});
