import { useTranslate } from "react-translate.ts";
import { useDispatch } from "react-redux";

import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import { HeroBackButton } from "../components/HeroBackButton";
import { OnboardingButtons, OnboardingForm } from "../../../components";
import OnboardingLayout from "../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";

export function HeroVideoInfo() {
    const dispatch = useDispatch();
    const translate = useTranslate("common.heroOnboarding.steps.video");

    return (
        <OnboardingLayout
            color="red"
            imageProps={{ src: "/img/heroes/onboard/video.svg" }}
        >
            <OnboardingForm className="skills">
                <h1 className="font-light">{translate("title")}</h1>
                <div className="skills__subtitle font-book">
                    {translate("subtitle")}
                </div>
                <div className="skills__hints">
                    <div className="skills__hints__title">
                        {translate("hints.title")}
                    </div>
                    <ul className="font-book">
                        {(translate("hints.steps") || []).map(renderStep)}
                    </ul>
                </div>
                <div className="font-heavy">{translate("thanks")}</div>
            </OnboardingForm>
            <OnboardingButtons>
                <HeroBackButton />
                <OnboardingButtons.Next
                    onClick={() =>
                        dispatch(HeroOnboardingActions.Video.checkInfo())
                    }
                />
            </OnboardingButtons>
        </OnboardingLayout>
    );

    function renderStep(item: any, i: number) {
        return (
            <li key={i}>
                <div className="skills__hints__index font-light">{i + 1}</div>{" "}
                {item}
            </li>
        );
    }
}
