import moment from "moment";
import { cx } from "@emotion/css";
import {
    NavLink,
    generatePath,
    useLocation,
    useRouteMatch,
    useHistory,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { JOB_DETAIL_PATH } from "../../_constants/route.constants";
import { FormatedDate, FormatMoneyRange } from "..";
import IJobCard from "../../types/IJobCard";
import styles from "./JobList.module.scss";
import { MatchPie } from "../../../candidate/components/MatchPie/MatchPie";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";
import Skeleton from "../Skeleton/Skeleton";
import usePage from "../../hooks/usePage";
import { useEffect } from "react";
import { ArrayHelper } from "../../_helpers/ArrayHelper";

type JobListProps = {
    jobs: IJobCard[];
    isLoading: boolean;
};

export function JobList(props: JobListProps) {
    const { jobs, isLoading } = props;

    const locale = useSelector(LanguageSelectors.getLocale);

    const routeMatch = useRouteMatch();
    const history = useHistory();
    const page = usePage();
    const location = useLocation();

    useEffect(() => {
        if (routeMatch.isExact && !ArrayHelper.isEmpty(jobs)) {
            const firstJob = jobs[0];
            history.replace({
                pathname: generatePath(JOB_DETAIL_PATH, {
                    page,
                    jobPostId: firstJob.jobPostId,
                }),
                search: location.search,
            });
        }
    }, [routeMatch, jobs]);

    return (
        <div className={styles.list}>
            {!isLoading
                ? jobs.map((job, i) => (
                      <JobItem key={i} job={job} locale={locale} />
                  ))
                : Array.from(new Array(4), (_, i) => (
                      <JobItem key={i} isLoading={isLoading} />
                  ))}
        </div>
    );
}

type JobItemProps = {
    job?: IJobCard;
    locale?: string;
    isLoading?: boolean;
};

function JobItem(props: JobItemProps) {
    const { job, locale, isLoading } = props;
    const location = useLocation();
    const page = usePage();

    return (
        <div className={styles.item}>
            {job?.jobPostId ? (
                <NavLink
                    to={{
                        pathname: generatePath(JOB_DETAIL_PATH, {
                            page,
                            jobPostId: job.jobPostId,
                        }),
                        search: location.search,
                    }}
                    activeClassName={styles.active}
                />
            ) : null}
            <div className={styles.item__icon}>
                {!isLoading ? (
                    <img src={job?.companyLogoUrl} alt="" />
                ) : (
                    <Skeleton />
                )}
            </div>
            <div>
                <div className={cx(styles.item__created, "font-heavy")}>
                    {!isLoading ? (
                        <>
                            {locale && job?.createdDate
                                ? moment(job.createdDate)
                                      .locale(locale)
                                      .fromNow()
                                : null}
                        </>
                    ) : (
                        <Skeleton width={70} height={10} />
                    )}
                </div>
                <div className={styles.item__title}>
                    {!isLoading ? (
                        <span>{job?.title}</span>
                    ) : (
                        <Skeleton height={14} width={130} />
                    )}
                    {/* <span>Nova</span> */}
                </div>
                <div className={styles.item__attribs}>
                    {!isLoading ? (
                        <>
                            <div>{job?.companyName}</div>
                            {job?.location?.city && (
                                <div>{job?.location.city}</div>
                            )}
                            {job?.employmentType && (
                                <div>{job?.employmentType}</div>
                            )}
                        </>
                    ) : (
                        <Skeleton width={95} height={10} />
                    )}
                </div>
                <div className={cx(styles.item__pills, "font-book")}>
                    {!isLoading ? (
                        <>
                            {job &&
                                (job.salaryMin || job.salaryMax) &&
                                job.currency && (
                                    <div className={styles.item__pill}>
                                        <FormatMoneyRange
                                            min={job.salaryMin}
                                            max={job.salaryMax}
                                            currency={job.currency}
                                        />
                                    </div>
                                )}
                            {job?.startDate && (
                                <div className={styles.item__pill}>
                                    <span>Start </span>
                                    <span>
                                        <FormatedDate
                                            date={job.startDate}
                                            format="L"
                                        />
                                    </span>
                                </div>
                            )}
                        </>
                    ) : (
                        <Skeleton height={20} width={250} />
                    )}
                </div>
            </div>
            <div className={styles.item__match}>
                <MatchPie match={job?.jobMatchScore || 0} isAnimationActive />
            </div>
        </div>
    );
}
