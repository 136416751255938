import React from "react";

export function Loading({
    className = "loading",
    isLoading = true,
    withIcon = true,
    style = {},
}) {
    return (
        <div
            className={className}
            style={{ visibility: isLoading ? "visible" : "hidden", ...style }}
        >
            <div>
                {withIcon ? <div className="main-logo" alt="Kareer" /> : null}
                <div className="loading-bar" />
            </div>
        </div>
    );
}
