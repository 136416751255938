import { GET_JOB_ROLE } from '../_constants';

export const GET_JOB_ROLE_MONITORING = 'GET_JOB_ROLE_MONITORING';
export const GET_JOB_ROLE_MONITORING_SUCCESS =
    'GET_JOB_ROLE_MONITORING_SUCCESS';
export const GET_JOB_ROLE_MONITORING_FAIL = 'GET_JOB_ROLE_MONITORING_FAIL';

export const JobRoleActions = {
    getJobRole,
    getJobRoleMonitoring,
    getJobRoleMonitoringSuccess,
    getJobRoleMonitoringFail,
};

function getJobRole(id) {
    return { type: GET_JOB_ROLE, payload: id };
}

function getJobRoleMonitoring(jobRoleId) {
    return { type: GET_JOB_ROLE_MONITORING, payload: jobRoleId };
}

function getJobRoleMonitoringSuccess(data, jobRoleId) {
    return {
        type: GET_JOB_ROLE_MONITORING_SUCCESS,
        payload: { data, jobRoleId },
    };
}

function getJobRoleMonitoringFail(error) {
    return { type: GET_JOB_ROLE_MONITORING_FAIL, error };
}
