import React, { useState } from 'react';

import { KanbanCard } from '../../../admin/components';
import { TrackingMatch } from './TrackingMatch';
import { TrackingTime } from './TrackingTime';
import { TrackingProgress } from './TrackingProgress';
import { TRACKING_ACCEPTED } from '../../../admin/_constants/actions/tracking.constants';
import { createClass } from 'classname-helper';
import { useTranslate } from 'react-translate.ts';

export function TrackingCard({
    image,
    heading,
    subHeadings,
    acceptedContent,
    canDrag,
    params,
    isNew,
    hover,
    accept,
    isHighlighted,
    ...rest
}) {
    const cardComponent = (
        <CardComponent
            {...{
                image,
                heading,
                subHeadings,
                acceptedContent,
                params,
                isNew,
                ...rest,
            }}
        />
    );

    if (canDrag === true) {
        return (
            <KanbanCard
                canDrag={true}
                hover={hover}
                accept={accept}
                isHighlighted={isHighlighted}
                {...rest}
            >
                {cardComponent}
            </KanbanCard>
        );
    }

    return cardComponent;
}

function CardComponent({
    image,
    heading,
    subHeadings,
    params,
    acceptedContent,
    isNew,
    ...rest
}) {
    const translate = useTranslate();
    return (
        <div
            className={createClass({ 'tracking-card': true, new: isNew })}
            {...rest}
        >
            <div className="tracking-card--label">
                {translate('common.misc.new')}
            </div>
            <div className="tracking-card--header">
                <div className="tracking-card--image">
                    <img src={image || '/img/avatar.svg'} alt="" />
                </div>
                <div>
                    <div>
                        <strong className="font-15">{heading}</strong>
                    </div>
                    <div>
                        {subHeadings.map((sub, key) => (
                            <div key={key} className="text-secondary font-11">
                                {sub}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Bottom params={params} acceptedContent={acceptedContent} />
        </div>
    );
}

function Bottom({ params, acceptedContent }) {
    const { score, status, responseTimeDeadline, events, createdDate } = params;

    const [addDaysVisible, setAddDaysVisible] = useState(false);

    let result = null;

    switch (status) {
        case 'APPLIED':
        case 'SHORTLISTED':
        case 'INVITED':
            result = (
                <TrackingMatch match={score}>
                    <TrackingTime
                        endDate={responseTimeDeadline}
                        onTimeChange={showAddDaysButton}
                    />
                </TrackingMatch>
            );
            break;
        case 'ACCEPTED':
            result = acceptedContent;
            break;
        default:
            result = <TrackingMatch match={score} />;
    }

    return (
        <>
            {result}
            {status !== TRACKING_ACCEPTED ? (
                <TrackingProgress
                    deadlineDate={responseTimeDeadline}
                    createdDate={createdDate}
                    eventDate={
                        events && events.length > 0 && events[events.length - 1]
                    }
                    eventNumber={events && events.length}
                    status={status}
                    addDaysVisible={addDaysVisible}
                />
            ) : null}
        </>
    );

    function showAddDaysButton(days) {
        if (days < 1 && !addDaysVisible) {
            setAddDaysVisible(true);
        } else if (addDaysVisible) {
            setAddDaysVisible(false);
        }
    }
}
