import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { SkillMatchBar } from '../../../../../common/components/charts/SkillMatchBar';
import { useSelector } from 'react-redux';
import { LanguageSelectors } from '../../../../../common/_selectors/LanguageSelectors';

export function HardSkillsList({ skills }) {
    const translateLevels = useTranslate('common.skills.level');

    const steps = [
        translateLevels(1),
        translateLevels(2),
        translateLevels(3),
        translateLevels(4),
    ];

    return skills.length ? (
        <div className="progress-chart">
            <div className="progress-chart--grid">
                {skills.map((item, key) => {
                    const { level, timeYearCount, skill } = item;
                    return (
                        <SkillMatchBar
                            key={key}
                            level={level - 1}
                            yearsOfExperience={timeYearCount}
                            name={skill.name}
                            steps={steps}
                        />
                    );
                })}
            </div>
        </div>
    ) : (
        <Placeholder />
    );
}

function Placeholder() {
    const translate = useTranslate('candidate.profile.hardSkills');
    const list = translate('list');

    const locale = useSelector(LanguageSelectors.getLocale);

    return (
        <div
            id="profile__hard-skills__placeholder"
            className="text-secondary font-book"
        >
            <p>{translate('placeholder')}</p>
            <div id="profile__hard-skills__placeholder__title">
                {translate('title')}
            </div>
            <div id="profile__hard-skills__placeholder__desc">
                {translate('description')}
            </div>
            <p>{translate('listTitle')}</p>
            <ul>
                {list.map((value, key) => (
                    <li key={key}>{value}</li>
                ))}
            </ul>
            {locale !== 'en' ? (
                <p>
                    <strong>{translate('hint')}</strong>
                </p>
            ) : null}
        </div>
    );
}
