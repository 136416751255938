export const GET_JOB_CARDS = 'GET_JOB_CARDS';
export const GET_JOB_CARDS_SUCCESS = 'GET_JOB_CARDS_SUCCESS';
export const GET_JOB_CARDS_FAIL = 'GET_JOB_CARDS_FAIL';

export const SET_JOB_FAVOURITE = 'SET_JOB_FAVOURITE';
export const SET_JOB_FAVOURITE_SUCCESS = 'SET_JOB_FAVOURITE_SUCCESS';
export const SET_JOB_FAVOURITE_FAIL = 'SET_JOB_FAVOURITE_FAIL';

export const UNSET_JOB_FAVOURITE = 'UNSET_JOB_FAVOURITE';
export const UNSET_JOB_FAVOURITE_SUCCESS = 'UNSET_JOB_FAVOURITE_SUCCESS';
export const UNSET_JOB_FAVOURITE_FAIL = 'UNSET_JOB_FAVOURITE_FAIL';

export const JOB_CARDS_CHANGE_PAGE = 'JOB_CARDS_CHANGE_PAGE';

export const GET_JOB_FILTERS = 'GET_JOB_FILTERS';
export const GET_JOB_FILTERS_SUCCESS = 'GET_JOB_FILTERS_SUCCESS';
export const GET_JOB_FILTERS_FAIL = 'GET_JOB_FILTERS_FAIL';

export const GET_JOB_SALARY_RANGE = 'GET_JOB_SALARY_RANGE';
export const GET_JOB_SALARY_RANGE_SUCCESS = 'GET_JOB_SALARY_RANGE_SUCCESS';
export const GET_JOB_SALARY_RANGE_FAIL = 'GET_JOB_SALARY_RANGE_FAIL';

export const JOB_FILTER_CHANGE_SALARY = 'JOB_FILTER_CHANGE_SALARY';
export const JOB_FILTER_CHANGE_REMOTE = 'JOB_FILTER_CHANGE_REMOTE';
export const JOB_FILTER_CHANGE_CURRENCY = 'JOB_FILTER_CHANGE_CURRENCY';
export const JOB_FILTER_CHANGE_BENEFIT = 'JOB_FILTER_CHANGE_BENEFIT';
export const JOB_FILTER_CHANGE_EMPLOYMENT = 'JOB_FILTER_CHANGE_EMPLOYMENT';
export const JOB_FILTER_CHANGE_LOCATIONS = 'JOB_FILTER_CHANGE_LOCATIONS';
export const JOB_FILTER_CHANGE_ROLES = 'JOB_FILTER_CHANGE_ROLES';
export const JOB_FILTER_CHANGE_TITLE = 'JOB_FILTER_CHANGE_TITLE';
export const JOB_FILTER_CLEAR = 'JOB_FILTER_CLEAR';
