import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import {
    VERIFY_PATH,
    LOGIN_PATH,
    VERIFY_TOKEN_PATH,
    NOT_ALLOWED_PATH,
    ONBOARDING_PATH,
    HERO_ONBOARDING_PATH,
} from "../../_constants/route.constants";
import { VERIFICATION_PENDING_STATUS } from "../../_constants/user.constants";
import { AuthSelectors } from "../../_selectors/AuthSelectors";
import { AuthHelpers } from "../../_helpers";
import { BaseRoute } from "./BaseRoute";

export function PrivateRoute({
    component: Component,
    render,
    allowedRoles,
    ...rest
}) {
    const history = useHistory();
    const from = useLocation();
    const pathname = from.pathname;

    const { isSigned, user, userStatus, isActive, isHero, isAdmin } =
        useSelector(mapState);

    useEffect(() => {
        if (!isSigned && !isActive) {
            history.push(LOGIN_PATH, { from });
            return;
        } else if (isSigned && isHero) {
            history.push(HERO_ONBOARDING_PATH, { from });
            return;
        } else if (isSigned && !isActive) {
            history.push(ONBOARDING_PATH, { from });
            return;
        }

        if (allowedRoles) {
            const isAllowed = AuthHelpers.hasUserRole(user, allowedRoles);
            if (!isAllowed) {
                history.push(NOT_ALLOWED_PATH, { from });
                return;
            }
        }
        switch (userStatus) {
            case VERIFICATION_PENDING_STATUS:
                if (
                    pathname !== VERIFY_PATH &&
                    pathname !== VERIFY_TOKEN_PATH
                ) {
                    history.push(VERIFY_PATH, { from });
                }
                return;
            default:
                return;
        }
    }, [from, isSigned, user, isActive, isHero]);

    return (
        <BaseRoute
            {...rest}
            render={(props) => {
                if (!render) {
                    return <Component {...props} />;
                }

                return render(props);
            }}
        />
    );
}

function mapState(state) {
    return {
        user: AuthSelectors.getUser(state),
        isSigned: AuthSelectors.isSigned(state),
        isAdmin: AuthSelectors.isAdmin(state),
        isActive: AuthSelectors.isActive(state),
        isHero: AuthSelectors.isHero(state),
        userStatus: AuthSelectors.userStatus(state),
    };
}
