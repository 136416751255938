import {
    CREATE_PAYMENT,
    ACCEPT_PAYMENT,
    GET_WALLET,
    CHANGE_CREDIT_CURRENCY,
    GET_CREDIT_PRICE,
    GET_CURRENCIES,
    GET_COMPANY_CURRENCY,
} from '../_constants/actions/wallet.constants';

export const WalletActions = {
    createPayment,
    getWallet,
    changeCurrency,
    getPrice,
    getCurrencies,
    acceptPayment,
    getCompanyCurrency
};

function createPayment(quantity, currency, amount) {
    return { type: CREATE_PAYMENT, payload: { quantity, currency, amount } };
}

function acceptPayment(sessionId) {
    return { type: ACCEPT_PAYMENT, payload: { sessionId } };
}

function getWallet() {
    return { type: GET_WALLET };
}

function changeCurrency(currency) {
    return { type: CHANGE_CREDIT_CURRENCY, payload: currency };
}

function getPrice(currency) {
    return { type: GET_CREDIT_PRICE, payload: currency };
}

function getCurrencies(currency) {
    return { type: GET_CURRENCIES, payload: currency };
}

function getCompanyCurrency(companyId) {
    return { type: GET_COMPANY_CURRENCY, payload: companyId };
}
