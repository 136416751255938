import {
    GET_CANDIDATE_CARDS_SUCCESS,
    GET_CANDIDATE_CARDS_FAIL,
    GET_CANDIDATE_CARDS,
    GET_CANDIDATE_PROFILE,
    GET_CANDIDATE_PROFILE_SUCCESS,
    GET_CANDIDATE_PROFILE_FAIL,
    CANDIDATE_CARDS_CHANGE_PAGE,
    GET_CANDIDATE_PROFILE_MATCH,
    GET_CANDIDATE_PROFILE_MATCH_SUCCESS,
    GET_CANDIDATE_PROFILE_MATCH_FAIL,
    INVITE_CANDIDATE,
    SET_FAVOURTIE_CANDIDATE,
    UNSET_FAVOURTIE_CANDIDATE,
    GET_CANDIDATE_CARDS_SAVED,
} from '../_constants/actions/candidates.constants';

export const CandidateActions = {
    getCards,
    getCardsSuccess,
    getCardsFail,
    getProfile,
    getProfileSuccess,
    getProfileFail,
    getProfileMatch,
    getProfileMatchSuccess,
    getProfileMatchFail,
    changePage,
    inviteCandidate,
    setFavouriteCandidate,
    unsetFavouriteCandidate,
    getFavourites,
};

function getCards() {
    return { type: GET_CANDIDATE_CARDS };
}

function getCardsSuccess(payload) {
    return { type: GET_CANDIDATE_CARDS_SUCCESS, payload };
}

function getCardsFail(error) {
    return { type: GET_CANDIDATE_CARDS_FAIL, error };
}

function getProfile(profileId, jobPostId) {
    return { type: GET_CANDIDATE_PROFILE, payload: { profileId, jobPostId } };
}

function getProfileSuccess(payload) {
    return { type: GET_CANDIDATE_PROFILE_SUCCESS, payload };
}

function getProfileFail(error) {
    return { type: GET_CANDIDATE_PROFILE_FAIL, error };
}

function changePage(page) {
    return { type: CANDIDATE_CARDS_CHANGE_PAGE, payload: page };
}

/**
 *
 * @param {number} profileId
 * @param {number} jobPostId
 */
function getProfileMatch(profileId, jobPostId) {
    return {
        type: GET_CANDIDATE_PROFILE_MATCH,
        payload: { profileId, jobPostId },
    };
}

function getProfileMatchSuccess(payload) {
    return { type: GET_CANDIDATE_PROFILE_MATCH_SUCCESS, payload };
}

function getProfileMatchFail(error) {
    return { type: GET_CANDIDATE_PROFILE_MATCH_FAIL, error };
}

function inviteCandidate(profileId, jobPostId) {
    return { type: INVITE_CANDIDATE, payload: { profileId, jobPostId } };
}

function getFavourites() {
    return { type: GET_CANDIDATE_CARDS_SAVED };
}

function setFavouriteCandidate(candidate, profileId, jobPostId) {
    return {
        type: SET_FAVOURTIE_CANDIDATE,
        payload: {
            candidate,
            profileId,
            jobPostId,
        },
    };
}

function unsetFavouriteCandidate(candidate, profileId, jobPostId) {
    return {
        type: UNSET_FAVOURTIE_CANDIDATE,
        payload: {
            candidate,
            profileId,
            jobPostId,
        },
    };
}
