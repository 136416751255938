import { CSSProperties } from "react";

import { resolveFit } from "../../_helpers/resolveFit";
import styles from "./ProgressBar.module.scss";

type ProgressBarProps = {
    progress: number;
    height?: number;
    fillColor?: string;
};

export function ProgressBar(props: ProgressBarProps) {
    const {
        height,
        fillColor = resolveFit(props.progress).color,
        progress = 0,
    } = props;

    return (
        <div className={styles.bar} style={{ "--height": height } as CSSProperties}>
            <div
                className={styles.fill}
                style={{ background: fillColor, width: `${progress}%` }}
            />
        </div>
    );
}
