import { PropsWithChildren } from "react";

import Search, { SearchProps } from "../../input/Search/Search";
import FiltersWrapper from "../FiltersWrapper/FiltersWrapper";
import styles from "./FiltersBar.module.scss";

interface FiltersBarProps<SearchOption> {
    searchProps?: SearchProps<SearchOption>;
}

export default function FiltersBar<SearchOption>(
    props: PropsWithChildren<FiltersBarProps<SearchOption>>
) {
    const { children, searchProps } = props;

    return (
        <div className={styles.wrapper}>
            {searchProps ? (
                <div className={styles.search}>
                    <Search {...searchProps} />
                </div>
            ) : <div />}
            <FiltersWrapper>{children}</FiltersWrapper>
        </div>
    );
}
