import React from 'react';
import { Check } from '@material-ui/icons';
import { addClass } from 'classname-helper';
import { useTranslate } from 'react-translate.ts';

export function ConfirmButton({ className, onClick }) {
    const translate = useTranslate();

    return (
        <div
            className={addClass('add-button', className)}
            onClick={onClick}
            title={translate('common.actions.apply')}
        >
            <Check />
        </div>
    );
}
