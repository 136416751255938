import { profileConstants } from '../_constants';
import {GAUtils} from "../../common/_helpers/GAUtils";

const contactsState = {
    loading: {
        email: false,
        phone: false,
        address: false,
        verification: false
    }
};
const initialState = {
    profile: {
        skills: [],
        languages: [],
        about: { content: '', id: 0 },
        interests: [],
        educations: [],
        jobExperience: []
    },
    current: [],
    jobsExperience: [],
    jobsRoles: [],
    loading: false,
    error: null,
    languages: {
        loading: false,
        error: null,
        languages: [],
        levels: []
    },
    contacts: contactsState
};

export function profile(state = initialState, action) {
    switch (action.type) {
        case profileConstants.MY_PROFILE_BEGIN:
            return { ...state, loading: true, error: null };
        case profileConstants.MY_PROFILE_SUCCESS:
            return { ...state, loading: false, profile: action.profile };
        case profileConstants.MY_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                profile: []
            };
        case profileConstants.JOB_EXPERIENCE_BEGIN:
            return { ...state, loading: true, error: null };
        case profileConstants.JOB_EXPERIENCE_SUCCESS:
            return { ...state, loading: false, jobsExperience: action.jobs };
        case profileConstants.JOB_EXPERIENCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                jobsExperience: []
            };
        case profileConstants.JOB_ROLES_BEGIN:
            return { ...state, loading: true, error: null };
        case profileConstants.JOB_ROLES_SUCCESS:
            return { ...state, loading: false, jobsRoles: action.list };
        case profileConstants.JOB_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                jobsRoles: []
            };
        case profileConstants.SKILLS_SAVE_BEGIN:
            return { ...state, loading: true };
        case profileConstants.SKILLS_SAVE_SUCCESS:
            GAUtils.event('Profile', 'HARD_SKILL_CREATED');
            return saveSkills();
        case profileConstants.SKILLS_SAVE_FAILURE:
            return { ...state, loading: false, error: action.error };
        case profileConstants.ADD_SKILL:
        case profileConstants.UPDATE_SKILL:
        case profileConstants.DELETE_SKILL:
        case profileConstants.REVERT_SKILLS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    skills: skills(state.profile.skills, action)
                }
            };
        case profileConstants.ADD_LANGUAGE:
        case profileConstants.UPDATE_LANGUAGE:
        case profileConstants.DELETE_LANGUAGE:
        case profileConstants.REVERT_LANGUAGES:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    languages: profileLanguages(state.profile.languages, action)
                }
            };
        case profileConstants.LOAD_LANGUAGES:
        case profileConstants.LOAD_LANGUAGES_SUCCESS:
        case profileConstants.LOAD_LANGUAGES_FAIL:
        case profileConstants.LOAD_LANGUAGE_LEVELS:
        case profileConstants.LOAD_LANGUAGE_LEVELS_SUCCESS:
        case profileConstants.LOAD_LANGUAGE_LEVELS_FAIL:
            return { ...state, languages: languages(state.languages, action) };

        case profileConstants.UPDATE_ABOUT_ME_SUCCESS:
            return {
                ...state,
                profile: { ...state.profile, about: action.payload }
            };

        case profileConstants.ADD_INTEREST_SUCCESS:
        case profileConstants.UPDATE_INTEREST_SUCCESS:
        case profileConstants.DELETE_INTEREST_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    interests: interests(state.profile.interests, action)
                }
            };

        case profileConstants.ADD_EDUCATION_SUCCESS:
        case profileConstants.UPDATE_EDUCATION_SUCCESS:
        case profileConstants.DELETE_EDUCATION_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    educations: educations(state.profile.educations, action)
                }
            };
        case profileConstants.ADD_EXPERIENCE_SUCCESS:
        case profileConstants.UPDATE_EXPERIENCE_SUCCESS:
        case profileConstants.DELETE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    jobExperience: experiences(
                        state.profile.jobExperience,
                        action
                    )
                }
            };
        case profileConstants.UPDATE_EMAIL_SUCCESS:
        case profileConstants.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    emails: [action.payload]
                }
            };
        case profileConstants.UPDATE_EMAIL:
        case profileConstants.UPDATE_EMAIL_SUCCESS:
        case profileConstants.UPDATE_EMAIL_FAIL:
        case profileConstants.VERIFY_EMAIL:
        case profileConstants.VERIFY_EMAIL_SUCCESS:
        case profileConstants.VERIFY_EMAIL_FAIL:
        case profileConstants.UPDATE_PHONE:
        case profileConstants.UPDATE_PHONE_SUCCESS:
        case profileConstants.UPDATE_PHONE_FAIL:
        case profileConstants.UPDATE_ADDRESS:
        case profileConstants.UPDATE_ADDRESS_SUCCESS:
        case profileConstants.UPDATE_ADDRESS_FAIL:
            return {
                ...state,
                contacts: contacts(state.contacts, action)
            };
        default:
            return state;
    }

    function contacts(state = contactsState, action) {
        const loading = state.loading;
        switch (action.type) {
            case profileConstants.UPDATE_EMAIL:
                return {
                    ...state,
                    loading: { ...loading, email: true }
                };
            case profileConstants.UPDATE_EMAIL_SUCCESS:
                return {
                    ...state,
                    loading: {
                        ...loading,
                        email: false
                    }
                };
            case profileConstants.UPDATE_EMAIL_FAIL:
                return {
                    ...state,
                    loading: { ...loading, email: false }
                };
            case profileConstants.VERIFICATION_EMAIL:
                return {
                    ...state,
                    loading: { ...loading, verification: true }
                };
            case profileConstants.VERIFICATION_EMAIL_SUCCESS:
                return {
                    ...state,
                    loading: {
                        ...loading,
                        verification: false
                    }
                };
            case profileConstants.VERIFICATION_EMAIL_FAIL:
                return {
                    ...state,
                    loading: { ...loading, verification: false }
                };
            case profileConstants.UPDATE_PHONE:
                return {
                    ...state,
                    loading: { ...loading, phone: true }
                };
            case profileConstants.UPDATE_PHONE_SUCCESS:
                return {
                    ...state,
                    loading: { ...loading, phone: false }
                };
            case profileConstants.UPDATE_PHONE_FAIL:
                return {
                    ...state,
                    loading: { ...loading, phone: false }
                };
            case profileConstants.UPDATE_ADDRESS:
                return {
                    ...state,
                    loading: { ...loading, address: true }
                };
            case profileConstants.UPDATE_ADDRESS_SUCCESS:
                return {
                    ...state,
                    loading: { ...loading, address: false }
                };
            case profileConstants.UPDATE_ADDRESS_FAIL:
                return {
                    ...state,
                    loading: { ...loading, address: false }
                };

            default:
                return state;
        }
    }

    function saveSkills() {
        const softSkills = filterSoftSkills(state.profile.skills);
        const newSkills = [...softSkills, ...action.payload.skills];
        return {
            ...state,
            loading: false,
            profile: { ...state.profile, skills: newSkills }
        };
    }
}

function skills(state = [], { type, payload }) {
    switch (type) {
        case profileConstants.ADD_SKILL:
            return addSkill(payload.skill);
        case profileConstants.UPDATE_SKILL:
            return updateSkill(payload.skill);
        case profileConstants.DELETE_SKILL:
            return deleteSkill(payload.id);
        case profileConstants.REVERT_SKILLS:
            return payload.oldSkills;
        default:
            return state;
    }

    function addSkill(skill) {
        const hasSkill = !!state.find(item => item.skill.id === skill.skill.id);
        return hasSkill ? state : [...state, skill];
    }

    function updateSkill(skill) {
        return state.map(mapper);

        function mapper(item) {
            if (item.skill.id !== skill.skill.id) {
                return item;
            }

            return skill;
        }
    }

    function deleteSkill(skillId) {
        return state.filter(item => item.skill.id !== skillId);
    }
}

function profileLanguages(state = [], { type, payload }) {
    switch (type) {
        case profileConstants.ADD_LANGUAGE:
            return addLanguage(payload);
        case profileConstants.UPDATE_LANGUAGE:
            return updateLanguage(payload);
        case profileConstants.DELETE_LANGUAGE:
            return deleteLanguage(payload);
        case profileConstants.REVERT_LANGUAGES:
            return payload;
        default:
            return state;
    }

    function addLanguage(language) {
        const hasLanguage = !!state.find(
            item => item.language.id === language.language.id
        );
        return hasLanguage ? state : [...state, language];
    }

    function updateLanguage(language) {
        return state.map(mapper);

        function mapper(item) {
            if (item.language.id !== language.language.id) {
                return item;
            }

            return language;
        }
    }

    function deleteLanguage(languageId) {
        return state.filter(item => item.language.id !== languageId);
    }
}

function languages(state = [], { type, payload, error }) {
    switch (type) {
        case profileConstants.LOAD_LANGUAGES:
            return { ...state, loading: true, error: null };
        case profileConstants.LOAD_LANGUAGES_SUCCESS:
            return { ...state, loading: false, languages: payload };
        case profileConstants.LOAD_LANGUAGES_FAIL:
            return { ...state, loading: false, error };
        case profileConstants.LOAD_LANGUAGE_LEVELS:
            return { ...state, loading: true, error: null };
        case profileConstants.LOAD_LANGUAGE_LEVELS_SUCCESS:
            return { ...state, loading: false, levels: payload };
        case profileConstants.LOAD_LANGUAGE_LEVELS_FAIL:
            return { ...state, loading: false, error };
        default:
            return state;
    }
}

function interests(state = [], { type, payload }) {
    switch (type) {
        case profileConstants.ADD_INTEREST_SUCCESS:
            return [...state, payload];
        case profileConstants.UPDATE_INTEREST_SUCCESS:
            return update(payload);
        case profileConstants.DELETE_INTEREST_SUCCESS:
            return state.filter(item => item.id !== payload);
        default:
            return state;
    }

    function update(interest) {
        return state.map(mapper);

        function mapper(item) {
            if (item.id !== interest.id) {
                return item;
            }

            return interest;
        }
    }
}

function educations(state = [], { type, payload }) {
    switch (type) {
        case profileConstants.ADD_EDUCATION_SUCCESS:
            GAUtils.event('Profile', 'EDUCATION_CREATED');
            return [...state, payload];
        case profileConstants.UPDATE_EDUCATION_SUCCESS:
            return update(payload);
        case profileConstants.DELETE_EDUCATION_SUCCESS:
            return state.filter(item => item.id !== payload);
        default:
            return state;
    }

    function update(education) {
        return state.map(mapper);

        function mapper(item) {
            if (item.id !== education.id) {
                return item;
            }

            return education;
        }
    }
}

function experiences(state = [], { type, payload }) {
    switch (type) {
        case profileConstants.ADD_EXPERIENCE_SUCCESS:
            GAUtils.event('Profile', 'JOB_EXPERIENCE_CREATED');
            return [...state, payload];
        case profileConstants.UPDATE_EXPERIENCE_SUCCESS:
            return update(payload);
        case profileConstants.DELETE_EXPERIENCE_SUCCESS:
            return state.filter(item => item.id !== payload);
        default:
            return state;
    }

    function update(experience) {
        return state.map(mapper);

        function mapper(item) {
            if (item.id !== experience.id) {
                return item;
            }

            return experience;
        }
    }
}

function filterSoftSkills(skills) {
    return skills.filter(item => item.skill.type === 'soft');
}
