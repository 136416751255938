import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { Panel } from './Panel';
import { ArrayHelper } from '../../../_helpers/ArrayHelper';

export function Gallery({ images, heading }) {
    const hasImages = !ArrayHelper.isEmpty(images);

    const [state, setState] = useState({ isOpen: false, index: 0 });

    if (!hasImages) {
        return null;
    }

    return (
        <Panel heading={heading}>
            <div className="company-profile--gallery">
                {images.map((image, index) => (
                    <div>
                        <img
                            className="clickable"
                            style={{ width: '100%' }}
                            key={index}
                            src={image.publicUrl}
                            alt=""
                            onClick={() => setState({ isOpen: true, index })}
                        />
                    </div>
                ))}
            </div>
            <ImageModal images={images} state={state} setState={setState} />
        </Panel>
    );
}

function ImageModal({ images, state, setState }) {
    const { isOpen, index } = state;
    const count = images.length;
    const nextIndex = (index + 1) % count;
    const prevIndex = (index + count - 1) % count;

    if (!isOpen) {
        return null;
    }

    return (
        <Lightbox
            mainSrc={images[index].publicUrl}
            nextSrc={images[nextIndex].publicUrl}
            prevSrc={images[prevIndex].publicUrl}
            onCloseRequest={close}
            onMoveNextRequest={next}
            onMovePrevRequest={previous}
        />
    );

    function next() {
        setState({ ...state, index: nextIndex });
    }

    function previous() {
        setState({ ...state, index: prevIndex });
    }

    function close() {
        setState({ isOpen: false, index: 0 });
    }
}
