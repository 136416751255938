import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AsyncSelect, Button } from '../../../../../common/components';
import { ProfileActions } from '../../../../../common/_actions/ProfileActions';
import { AuthSelectors } from '../../../../../common/_selectors';
import { AutocompleteService } from '../../../../_services';

export function JobRoleForm(props) {
    const { onSubmit } = props;
    const { profileId } = useSelector(mapState);

    const dispatch = useDispatch();

    const [value, setValue] = useState(null);

    return (
        <>
            <div className="form">
                <div className="subtitle">
                    Type your current or last job role. More job roles will be
                    alailable soon
                </div>
                <div>
                    <AsyncSelect
                        loadOptions={AutocompleteService.jobRoles}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        value={value}
                        onChange={(value) => setValue(value)}
                    />
                </div>
            </div>
            <Button onClick={submit} disabled={!value}>
                Show career map
            </Button>
        </>
    );

    function submit() {
        if (value === null) {
            return;
        }

        onSubmit && onSubmit();

        dispatch(
            ProfileActions.saveProfileType(profileId, 'PROFESSIONAL', {
                jobRoleId: value.id,
            })
        );
    }
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
    };
}
