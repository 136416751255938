import { combineReducers } from "redux";

import SoftSkillsConstants from "../../../_constants/actions/SoftSkillsConstants";
import { UNPROCESSED_STATUS } from "../../../_constants/testStatus.constants";

const status = {
    status: UNPROCESSED_STATUS,
    requestId: null,
    questionCount: 0,
};

const defaultState = {
    MOP: status,
    KAKO: status,
    HOFIK: status,
    DZZV2: status,
    PRESS: status,
    ABS: status,
    NUM: status,
};

const list = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SoftSkillsConstants.GET_STATUSES_SUCCESS:
            return payload;
        case SoftSkillsConstants.GET_STATUS_SUCCESS:
        case SoftSkillsConstants.COMPLETE_TEST_SUCCESS:
            return Object.assign({}, state, { [payload.test]: payload });
        default:
            return state;
    }
};

export default combineReducers({ list });
