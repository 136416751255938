import { takeLatest } from "@redux-saga/core/effects";

import HeroListConstants from "../../common/_constants/actions/HeroListConstants";
import HeroProfileConstants from "../../common/_constants/actions/HeroProfileConstants";
import { SagaHelpers } from "../../common/_helpers";
import HeroProfileService from "../_services/HeroProfileService";

function* getProfile(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.getProfile, action.payload],
        actions: [
            HeroProfileConstants.GET_PROFILE_SUCCESS,
            HeroProfileConstants.GET_PROFILE_FAIL,
        ],
    });
}

function* getProfiles(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.getProfiles, action.payload],
        actions: [
            HeroListConstants.GET_TOP_MATCHES_SUCCESS,
            HeroListConstants.GET_TOP_MATCHES_FAIL,
        ],
    });
}

export default [
    takeLatest(HeroProfileConstants.GET_PROFILE, getProfile),
    takeLatest(HeroListConstants.GET_TOP_MATCHES, getProfiles),
];
