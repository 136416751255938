import { combineReducers } from 'redux';
import {
    GET_APPLICATIONS,
    GET_APPLICATIONS_SUCCESS,
    GET_APPLICATIONS_FAIL,
    GET_APPLICATIONS_ARCHIVED,
    GET_APPLICATIONS_ARCHIVED_SUCCESS,
    GET_APPLICATIONS_ARCHIVED_FAIL,
    GET_APPLICATIONS_REJECTED,
    GET_APPLICATIONS_REJECTED_SUCCESS,
    GET_APPLICATIONS_REJECTED_FAIL,
} from '../../../_constants/actions/applicationsTracking.constants';

import cards from './cards.reducer';
import archived from './archived.reducer';

export default combineReducers({ cards, rejected, archived, loading });

function loading(state = false, action) {
    switch (action.type) {
        case GET_APPLICATIONS:
        case GET_APPLICATIONS_ARCHIVED:
        case GET_APPLICATIONS_REJECTED:
            return true;
        case GET_APPLICATIONS_SUCCESS:
        case GET_APPLICATIONS_ARCHIVED_SUCCESS:
        case GET_APPLICATIONS_REJECTED_SUCCESS:
            return false;
        case GET_APPLICATIONS_FAIL:
        case GET_APPLICATIONS_ARCHIVED_FAIL:
        case GET_APPLICATIONS_REJECTED_FAIL:
            return false;
        default:
            return state;
    }
}

function rejected(state = [], action) {
    if (action.type === GET_APPLICATIONS_REJECTED_SUCCESS) {
        return action.payload || state;
    }

    return state;
}
