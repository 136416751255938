import React from "react";

import InterpretationContent from "../../../../../common/components/interpretation/InterpretationContent/InterpretationContent";
import InterpretationPersonalities from "../../../../../common/components/interpretation/InterpretationPersonalities/InterpretationPersonalities";
import Discover from "../../../../../common/components/interpretation/Discover/Discover";
import { useTranslate } from "react-translate.ts";
import InterpretationSummary from "../../../../../common/components/interpretation/InterpretationSummary/InterpretationSummary";

type MopInterpretationContentProps = {
    isLoading?: boolean;
    profiles: any;
    skills: any;
    interpretations: any;
    showLess?: boolean;
    onShowMore?: () => void;
};

export default function MopInterpretationContent(
    props: MopInterpretationContentProps
) {
    const {
        isLoading,
        profiles,
        skills,
        interpretations,
        showLess,
        onShowMore,
    } = props;
    const translate = useTranslate("candidate.interpretation");
    console.log(interpretations);
    return (
        <InterpretationContent>
            <InterpretationPersonalities
                profiles={profiles}
                isLoading={isLoading}
                heading={translate("mop.headings.main")}
                subheading={translate("subHeading")}
                getBubbleColor={(section) => {
                    switch (section) {
                        case "setting":
                            return "teal";
                        case "perception":
                            return "blue";
                        case "decisions":
                            return "red";
                        case "activity":
                            return "yellow";
                    }
                }}
            />
            {skills && <Discover softSkills={skills} />}
            <InterpretationContent.More
                showLess={showLess}
                onShowMore={onShowMore}
            >
                <InterpretationSummary
                    heading={translate("mop.headings.summary")}
                    items={Object.entries(interpretations).map(
                        (entry: [key: string, value: any]) => {
                            const [key, value] = entry;
                            const overall = value?.overall?.[0];

                            return {
                                name: translate("mop.headings." + key),
                                imageName: key,
                                title: overall?.title,
                                description: overall?.description,
                                development: value?.development,
                                risks: value?.risks,
                                advantages: value?.advantages,
                            };
                        }
                    )}
                />
            </InterpretationContent.More>
        </InterpretationContent>
    );
}
