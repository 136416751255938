import React from 'react';

import { Flex } from '../layout';
import { BasePageLayout } from '../BasePageLayout';


export function TrackingLayout(props) {
    const { extraContent, children, ...restProps } = props;
    return (
        <BasePageLayout {...restProps}>
            <Flex direction="column" id="tracking-layout">
                {extraContent}
                {children}
            </Flex>
        </BasePageLayout>
    );
}
