import { createSelector } from "reselect";

const positions = (state) => state.common.heroes.onboarding.steps.positions;

const getData = createSelector(positions, (positions) => positions.data);

const isComplete = createSelector(positions, (positions) => positions.complete);

const hasMoreOptions = createSelector(
    positions,
    (positions) => positions.moreOptions
);

const isSaved = createSelector(positions, (positions) => positions.saved);

const Positions = {
    hasMoreOptions,
    getData,
    isComplete,
    isSaved,
};

export default Positions;
