import React from 'react';
import { createClass } from 'classname-helper';
import { useTranslate } from 'react-translate.ts';
import { TooltipLabel } from '../TooltipLabel';

export function EditButton({ onClick, className, disabled, ...rest }) {
    const translate = useTranslate();

    return (
        <TooltipLabel label={translate('common.actions.edit')}>
            <div
                className={createClass({
                    'edit-button': true,
                    [className]: !!className,
                    disabled,
                })}
                onClick={handleClick}
                // title={translate('common.actions.edit')}
                {...rest}
            >
                <img src="/img/icons/edit.svg" alt="" />
            </div>
        </TooltipLabel>
    );

    function handleClick(...args) {
        if (disabled) {
            return null;
        }

        onClick && onClick(...args);
    }
}
