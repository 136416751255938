import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import {useTranslate} from "react-translate.ts";

export function MapLoading() {
    const translate = useTranslate('candidate.mapLoading');
    return (
        <div className="career-map-fail">
            <div>
                <div className="image" style={{ margin: 0 }}>
                    <Player
                        autoplay
                        loop
                        src="/img/career-paths/loading-anim.json"
                        style={{ width: '390px', height: '300px' }}
                    />
                </div>
                <div className="title font-book">{translate('title')}</div>
                {/*<div className="subtitle">{translate('subtitle')}*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
