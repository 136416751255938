export const UserConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    CURRENT_BEGIN: 'CURRENT_BEGIN',
    CURRENT_SUCCESS: 'CURRENT_SUCCESS',
    CURRENT_FAILURE: 'CURRENT_FAILURE',

    SIGNUP_BEGIN: 'SIGNUP_BEGIN',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_ERROR: 'SIGNUP_ERROR',

    VERIFY_BEGIN: 'VERIFY_BEGIN',
    VERIFY_SUCCESS: 'VERIFY_SUCCESS',
    VERIFY_ERROR: 'VERIFY_ERROR',

    FORGOT_PASSWORD_BEGIN: 'FORGOT_PASSWORD_BEGIN',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
};

export const GET_CURRENT = 'GET_CURRENT';
export const GET_CURRENT_SUCCESS = 'GET_CURRENT_SUCCESS';
export const GET_CURRENT_FAIL = 'GET_CURRENT_FAIL';
