import { useTranslate } from "react-translate.ts";
import Button from "../../../../common/components/buttons/Button/Button";

export default function UploadButton(props: { sending: boolean }) {
    const translate = useTranslate("common.actions");
    return (
        <Button type="submit" inverted disabled={props.sending}>
            {props.sending ? `${translate("saving")}...` : translate("save")}
        </Button>
    );
}
