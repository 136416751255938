import React from "react";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";

import { SVGIcon, TooltipLabel } from "../../../../../../common/components";
import { CareerPathsActions } from "../../../../../_actions";
import { UserProfileSelectors } from "../../../../../../common/_selectors/UserProfileSelectors";
import { PROFILE_TYPE_COLLEGE } from "../../../../../../common/_constants/profile.constants";

const ZOOM_SPEED = 0.1;

export function Controls(props) {
    const { onZoom, useResetButton = true } = props;
    const profileType = useSelector((state) =>
        UserProfileSelectors.getType(state)
    );

    const dispatch = useDispatch();

    const translateTooltip = useTranslate(
        "candidate.careerPaths.controls.tooltips"
    );

    return (
        <div id="career-paths__controls">
            <TooltipLabel label={translateTooltip("zoomIn")}>
                <div>
                    <button onClick={zoomIn}>
                        <SVGIcon name="plus" />
                    </button>
                </div>
            </TooltipLabel>
            <TooltipLabel label={translateTooltip("zoomOut")}>
                <div>
                    <button onClick={zoomOut}>
                        <SVGIcon name="minus" />
                    </button>
                </div>
            </TooltipLabel>
            {useResetButton ? (
                <TooltipLabel label={translateTooltip("reset")}>
                    <div>
                        <button onClick={getDefaultMap}>
                            <SVGIcon name="compass" light />
                        </button>
                    </div>
                </TooltipLabel>
            ) : null}
        </div>
    );

    function getDefaultMap() {
        if (!profileType || useResetButton === false) {
            return;
        }

        dispatch(CareerPathsActions.getDefaultMap(profileType));
        dispatch(CareerPathsActions.clearPoints());
        // dispatch(CareerPathsActions.setInputValue(''));
        dispatch(CareerPathsActions.setSearchSelected(null));
        dispatch(CareerPathsActions.setRoutingData(null));

        let defaultIndustry = null;
        if (profileType.type === PROFILE_TYPE_COLLEGE) {
            defaultIndustry = {
                id: profileType.industryId,
                name: profileType.industryName,
            };
        }
        dispatch(CareerPathsActions.setIndustry(defaultIndustry));
    }

    function zoomIn() {
        onZoom(ZOOM_SPEED);
    }

    function zoomOut() {
        onZoom(-ZOOM_SPEED);
    }
}
