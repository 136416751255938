import React, { ReactNode, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useTranslate } from "react-translate.ts";

import SoftSkillsSelectors from "../../../../_selectors/SoftSkillsSelectors";
import {
    SOFT_SKILL_TEST_MOP_PATH,
    SOFT_SKILL_TEST_KAKO_PATH,
    SOFT_SKILL_TEST_PRESS_PATH,
    SOFT_SKILL_TEST_CULTURE_PATH,
    SOFT_SKILL_TEST_ABSTRACT_PATH,
    SOFT_SKILL_TEST_NUMERIC_PATH,
} from "../../../../_constants/route.constants";
import SoftSkillsActions from "../../../../_actions/SoftSkillsActions";
import { SOFT_SKILLS_TAB, ALL_TAB, PERFORMANCE_TAB } from "../../SoftSkillTest";
import { SVGIcon } from "../../../../../common/components";
import TranslationConstants from "../../../../../common/_constants/TranslationConstants";
import styles from "./DashboardLinks.module.scss";

const PROCESSED_STATUS = "PROCESSED";

type DashboardLinksProps = {
    profileId: number;
    selectedTab: number;
};

export default function DashboardLinks(props: DashboardLinksProps) {
    const { profileId, selectedTab } = props;
    const statuses = useSelector(SoftSkillsSelectors.getStatuses);
    const translate = useTranslate(TranslationConstants.softSkillsTest);

    const { MOP, KAKO, HOFIK, PRESS, ABS, NUM } = statuses;

    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(SoftSkillsActions.getStatuses(profileId));
        }
    }, [dispatch, profileId]);

    return <div className={styles.links}>{renderLinks()}</div>;

    function renderLinks() {
        const links = [
            {
                link: SOFT_SKILL_TEST_MOP_PATH,
                status: MOP,
                icon: "mop",
                label: translate("mop.title"),
            },
            {
                link: SOFT_SKILL_TEST_CULTURE_PATH,
                status: HOFIK,
                icon: "culture-fit",
                label: translate("hofik.title"),
            },
            {
                link: SOFT_SKILL_TEST_KAKO_PATH,
                status: KAKO,
                icon: "kako",
                label: translate("kako.title"),
            },
            {
                link: SOFT_SKILL_TEST_PRESS_PATH,
                status: PRESS,
                icon: "match",
                label: translate("press.title"),
            },
            {
                link: SOFT_SKILL_TEST_ABSTRACT_PATH,
                status: ABS,
                icon: "abstrakt-test",
                label: translate("abs.title"),
            },
            {
                link: SOFT_SKILL_TEST_NUMERIC_PATH,
                status: NUM,
                icon: "numbers",
                label: translate("num.title"),
            },
        ];
        const softSkillsLinks = links
            .slice(0, 3)
            .map((item, index) =>
                renderLink(
                    item.status,
                    item.link,
                    item.icon,
                    item.label,
                    index + 1
                )
            );
        const performanceLinks = links
            .slice(3, 6)
            .map((item, index) =>
                renderLink(
                    item.status,
                    item.link,
                    item.icon,
                    item.label,
                    index + 4
                )
            );

        switch (selectedTab) {
            case ALL_TAB:
                return (
                    <>
                        {softSkillsLinks}
                        {performanceLinks}
                    </>
                );
            case SOFT_SKILLS_TAB:
                return softSkillsLinks;
            case PERFORMANCE_TAB:
                return performanceLinks;
            default:
                return null;
        }
    }
}

function renderLink(
    status: any,
    link: string,
    icon: string,
    label: string,
    index: number
) {
    return (
        <Link
            link={link}
            count={status?.questionCount}
            icon={icon}
            status={status?.status}
            unlocked={status?.unlocked}
            index={index}
        >
            {label}
        </Link>
    );
}

type LinkProps = {
    link: string;
    icon: string;
    count: number;
    children: ReactNode;
    status: string;
    unlocked: boolean;
    disabled?: boolean;
    index: number;
};

function Link(props: LinkProps) {
    const { link, icon, count, children, status, disabled, index, unlocked } =
        props;
    const translate = useTranslate(TranslationConstants.softSkillsTest);

    if (!status) {
        return null;
    }

    const isComplete = status === PROCESSED_STATUS;

    const statusIcon = resolveIcon();

    function resolveIcon() {
        if (!unlocked) {
            return <SVGIcon name="lock" />;
        }

        if (isComplete) {
            return <img src="/img/icons/checkmark.svg" alt="" />;
        }

        return index;
    }

    return (
        <div className={styles.link}>
            <NavLink
                to={link}
                className={cx(
                    disabled && styles.disabled,
                    !unlocked && styles.locked
                )}
                isActive={(match) => match && match.isExact && !disabled}
            >
                <div className={styles.link__icon}>
                    <i className={`icon-${icon}`} />
                </div>
                <div className={styles.link__label}>{children}</div>
                <div className={styles.link__count}>
                    {translate("info.count", { count })}
                </div>
                {index && (
                    <div
                        className={cx(
                            styles.link__check,
                            isComplete && unlocked && styles.processed,
                            !unlocked && styles.locked
                        )}
                    >
                        {statusIcon}
                    </div>
                )}
            </NavLink>
        </div>
    );
}
