import { formatDate } from "../../../_helpers";
import Button from "../../buttons/Button/Button";
import { VideoPlayer } from "../../misc/VideoPlayer";
import { Panel } from "../../Panel/Panel";
import styles from "./MainPanel.module.scss";
import Skeleton from "../../../components/Skeleton/Skeleton";

type MainPanelProps = {
    description: string;
    founded: string;
    employees: string;
    headquarters: string;
    url: string;
    industries: { name: string }[];
    videoUrl: string;
    videoProvider: string;
    isLoading: boolean;
};

export default function MainPanel(props: MainPanelProps) {
    const {
        description,
        founded,
        employees,
        headquarters,
        url,
        industries,
        videoUrl,
        videoProvider,
        isLoading,
    } = props;

    const [main, second] = industries || [];

    return (
        <Panel className={styles.main}>
            <Panel.Heading>Overview</Panel.Heading>
            <div className={styles.description}>
                {!isLoading ? (
                    <p>{description}</p>
                ) : (
                    <Skeleton count={6} height={14} marginBottom={8} />
                )}
            </div>
            <div className={styles.info}>
                <Info
                    title="Company Type"
                    value={main?.name}
                    isLoading={isLoading}
                />
                <Info title="Annual Revenue" isLoading={isLoading} />
                <Info
                    title="ADDITIONAL ACTIVITIES"
                    value={second?.name}
                    isLoading={isLoading}
                />
                <Info
                    title="HEADQUARTES"
                    value={headquarters}
                    isLoading={isLoading}
                />
                <Info
                    title="FOUNDED"
                    value={founded}
                    isLoading={isLoading}
                />
                <Info
                    title="Employees"
                    value={employees}
                    isLoading={isLoading}
                />
                <Info title="Rating" isLoading={isLoading} />
                {url ? (
                    <Info
                        title="Website"
                        value={<a href={url}>{url}</a>}
                        isLoading={isLoading}
                    />
                ) : null}
            </div>
            <Button disabled={isLoading}>Follow Company</Button>
            <div className={styles.video}>
                {!isLoading ? (
                    <VideoPlayer
                        videoUrl={videoUrl}
                        videoProvider={videoProvider}
                    />
                ) : (
                    <Skeleton height={450} />
                )}
            </div>
        </Panel>
    );
}

function Info(props: {
    title: string;
    value?: string | JSX.Element;
    isLoading: boolean;
}) {
    const { title, value, isLoading } = props;

    if (!value && !isLoading) {
        return null;
    }

    return (
        <div className={styles.info__item}>
            <div className={styles.info__item__title}>
                {!isLoading ? title : <Skeleton width={80} height={9} />}
            </div>
            <div className={styles.info__item__value}>
                {!isLoading ? value : <Skeleton width={150} height={14} />}
            </div>
        </div>
    );
}
