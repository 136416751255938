import AdminJobListItem from "../AdminJobListItem/AdminJobListItem";
import styles from "./AdminJobList.module.scss";

type JobListProps = {
    jobs: any[];
};

export default function AdminJobList(props: JobListProps) {
    const { jobs } = props;

    return (
        <>
            {jobs.map((item, i) => (
                <AdminJobListItem
                    key={i}
                    id={item.id}
                    name={item.title}
                    status={item.entityStatus}
                    created={item.createdDate}
                    category={item.category}
                    jobType={item.employmentType}
                    location={item.location}
                    candidates={item.candidates}
                    applied={item.applied}
                    invited={item.invited}
                />
            ))}
        </>
    );
}
