import { useTranslate } from "react-translate.ts";
import ConfirmDialog from "../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog";

type TeamInviteDialogProps = {
    onConfirm: () => void;
    onCancel: () => void;
    isVisible: boolean;
};

export default function TeamInviteDialog(props: TeamInviteDialogProps) {
    const { onConfirm, onCancel, isVisible } = props;

    const translate = useTranslate("admin.team.invite");

    return (
        <ConfirmDialog
            title={translate("title")}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onClose={onCancel}
            isVisible={isVisible}
        ></ConfirmDialog>
    );
}
