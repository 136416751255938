import { FieldHookConfig, useField } from "formik";
import Select from "./Select/Select";
import useYearOptions from "./useYearOptions";
import { YearSelectProps } from "./YearSelect";

export interface FormYearSelectProps extends YearSelectProps {
    fieldConfig?: FieldHookConfig<number>;
}

export default function FormYearSelect(props: FormYearSelectProps) {
    const {
        addedYears,
        yearCount,
        startYear,
        name,
        fieldConfig = {},
        ...rest
    } = props;
    const [field, meta, helper] = useField<number>({ ...fieldConfig, name });

    const currentYear = startYear || new Date().getFullYear();

    const options = useYearOptions(currentYear, addedYears, yearCount);
    const { error, touched } = meta;
    const hasError = touched && !!error;

    return (
        <Select<number>
            name={field.name}
            value={field.value}
            onSelect={helper.setValue}
            hasError={hasError}
            options={options}
            getOptionLabel={option => option.toString()}
            {...rest}
        />
    );
}
