import { takeLatest } from "redux-saga/effects";

import {
    GET_COMPANY_DETAIL,
    GET_COMPANY_DETAIL_SUCCESS,
    GET_COMPANY_DETAIL_FAIL,
} from "../_constants/actions/company-profile.constants";
import { handleAsyncAction } from "../_helpers/handleAsyncAction";
import { SagaHelpers } from "../_helpers/SagaHelpers";
import {
    GET_COMPANY_CURRENCY,
    GET_COMPANY_CURRENCY_FAIL,
    GET_COMPANY_CURRENCY_SUCCESS,
} from "../../admin/_constants/actions/wallet.constants";
import CompanyProfileService from "../_services/CompanyProfileService";

function* getDetail(action) {
    yield handleAsyncAction(
        CompanyProfileService.getCompanyProfile,
        action.payload,
        (payload) => ({ type: GET_COMPANY_DETAIL_SUCCESS, payload }),
        (error) => ({ type: GET_COMPANY_DETAIL_FAIL, error })
    );
}

function* getCurrency(action) {
    yield SagaHelpers.handleRequest({
        request: [CompanyProfileService.getCompanyCurrency, action.payload],
        actions: [
            (payload) => ({ type: GET_COMPANY_CURRENCY_SUCCESS, payload }),
            (error) => ({ type: GET_COMPANY_CURRENCY_FAIL, error }),
        ],
    });
}

export default [
    takeLatest(GET_COMPANY_DETAIL, getDetail),
    takeLatest(GET_COMPANY_CURRENCY, getCurrency),
];
