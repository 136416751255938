import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseInterpretation } from "./BaseInterpretation";
import { SkillActions } from "../../_actions/SkillActions";
import { Loading, PrimaryRadarChart } from "../../../common/components";
import { Translate } from "react-translate.ts";

export function KakoInterpretation() {
    const { isLoading, skills, interpretations } = useSelector(mapState);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(SkillActions.getSoftSkillsInterpretation("kako"));
    }, [dispatch]);

    return render();
    function render() {
        if (isLoading) {
            return <Loading />;
        }

        if (!skills || !interpretations) {
            return null;
        }

        const { drivers, barriers } = interpretations;

        const links = [
            {
                href: "#advantages",
                label: "candidate.profile.softSkills.kako.links.advantages",
            },
            {
                href: "#risks",
                label: "candidate.profile.softSkills.kako.links.risks",
            },
        ];
        const image = "/img/soft-skills/picture-softskill-kako.png";
        const title = "candidate.profile.softSkills.kako.title";

        return (
            <BaseInterpretation
                className="kako"
                title={title}
                image={image}
                links={links}
                description={null}
                descriptions={[]}
                renderChart={renderChart}
            >
                <div id="advantages-risks-lists">
                    <div id="advantages">
                        <h4>
                            <Translate textOnly>
                                candidate.profile.softSkills.kako.links.advantages
                            </Translate>
                        </h4>
                        <ul id="advantages-list">
                            {drivers.map((item, index) => (
                                <li key={index} className="with-checkmark">
                                    {item.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div id="risks">
                        <h4>
                            <Translate textOnly>
                                candidate.profile.softSkills.kako.links.risks
                            </Translate>
                        </h4>
                        <ul id="risks-list">{barriers.map(renderListItem)}</ul>
                    </div>
                </div>
            </BaseInterpretation>
        );
    }

    function renderListItem(item, index) {
        return <li key={index}>{item.description}</li>;
    }

    function renderChart() {
        return <PrimaryRadarChart data={skills} />;
    }

    function mapState(state) {
        const { isLoading, interpretation } = state.candidate.softSkills;
        if (!interpretation) {
            return { isLoading, skills: null, interpretations: null };
        }

        const { softSkills, skillInterpretations } = interpretation;
        const interpretations = skillInterpretations.kako;
        const skills = softSkills.kako;

        return {
            isLoading,
            skills: skills.map(({ skill, value }) => ({
                name: skill.name,
                value,
            })),
            interpretations,
        };
    }
}
