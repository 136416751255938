import { takeLatest, call } from 'redux-saga/effects';

import { handleAsyncAction, history } from '../../common/_helpers';
import { CandidateTrackingService } from '../_services';
import {
    GET_TRACKING_CARDS_SUCCESS,
    GET_TRACKING_CARDS_FAIL,
    GET_TRACKING_CARDS,
    CHANGE_TRACKING_STATE,
    CHANGE_TRACKING_STATE_SUCCESS,
    CHANGE_TRACKING_STATE_FAIL,
    REJECT_CANDIDATE,
    REJECT_CANDIDATE_SUCCESS,
    REJECT_CANDIDATE_FAIL,
    UNLOCK_CANDIDATE,
    UNLOCK_CANDIDATE_SUCCESS,
    UNLOCK_CANDIDATE_FAIL,
    GET_ARCHIVED_CARDS_SUCCESS,
    GET_ARCHIVED_CARDS_FAIL,
    GET_ARCHIVED_CARDS,
    GET_DECLINED_CARDS_SUCCESS,
    GET_DECLINED_CARDS_FAIL,
    GET_DECLINED_CARDS,
} from '../_constants/actions/candidateTracking.constants';
import { TRACKING_ACCEPTED } from '../_constants/actions/tracking.constants';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { ADMIN_WALLET_PAY_PATH } from '../_constants/route.constants';

function* getTrackingCards(action) {
    yield handleAsyncAction(
        CandidateTrackingService.getTrackingCards,
        action.payload,
        (payload) => ({ type: GET_TRACKING_CARDS_SUCCESS, payload }),
        (error) => ({ type: GET_TRACKING_CARDS_FAIL, error }),
        {
            error: 'Response.error',
        }
    );
}

function* getArchivedCards(action) {
    yield handleAsyncAction(
        CandidateTrackingService.getArchivedCards,
        action.payload,
        (payload) => ({ type: GET_ARCHIVED_CARDS_SUCCESS, payload }),
        (error) => ({ type: GET_ARCHIVED_CARDS_FAIL, error }),
        {
            error: 'Response.error',
        }
    );
}

function* getDeclinedCards(action) {
    yield SagaHelpers.handleRequest({
        request: [CandidateTrackingService.getDeclinedCards, action.payload],
        actions: [
            (payload) => ({ type: GET_DECLINED_CARDS_SUCCESS, payload }),
            (error) => ({ type: GET_DECLINED_CARDS_FAIL, error }),
        ],
        messages: {
            fail: 'Response.error',
        },
    });
}

function* changeState(action) {
    yield handleAsyncAction(
        CandidateTrackingService.changeState,
        action.payload,
        () => ({
            type: CHANGE_TRACKING_STATE_SUCCESS,
            payload: action.payload,
        }),
        (error) => ({ type: CHANGE_TRACKING_STATE_FAIL, error }),
        {
            error: 'candidate.tracking.change.fail',
        }
    );
}

function* rejectCandidate(action) {
    yield SagaHelpers.handleRequest({
        request: [CandidateTrackingService.rejectCandidate, action.payload],
        actions: [
            () => ({ type: REJECT_CANDIDATE_SUCCESS, payload: action.payload }),
            (error) => ({ type: REJECT_CANDIDATE_FAIL, error }),
        ],
        messages: {
            success: 'admin.notify.tracking.reject.success',
            fail: 'admin.notify.tracking.reject.fail',
        },
    });
}

function* unlockCandidate(action) {
    yield SagaHelpers.handleRequest({
        preRequest: function* () {
            const credits = action.payload.credits;

            if (credits <= 0) {
                yield call(history.push, ADMIN_WALLET_PAY_PATH);
                throw new Error();
            }
        },
        request: [
            CandidateTrackingService.changeState,
            { ...action.payload, newStatus: TRACKING_ACCEPTED },
        ],
        actions: [
            (payload) => ({
                type: UNLOCK_CANDIDATE_SUCCESS,
                payload: { ...payload, oldStatus: action.payload.oldStatus },
            }),
            (error) => ({ type: UNLOCK_CANDIDATE_FAIL, error }),
        ],
        messages: {
            success: 'admin.notify.tracking.accept.success',
            fail: 'admin.notify.tracking.accept.fail',
        },
    });
}

export default [
    takeLatest(GET_TRACKING_CARDS, getTrackingCards),
    takeLatest(GET_ARCHIVED_CARDS, getArchivedCards),
    takeLatest(GET_DECLINED_CARDS, getDeclinedCards),
    takeLatest(CHANGE_TRACKING_STATE, changeState),
    takeLatest(REJECT_CANDIDATE, rejectCandidate),
    takeLatest(UNLOCK_CANDIDATE, unlockCandidate),
];
