import { RequestService } from '../../common/_services/RequestService';

export const InterpretationService = {
    getInterpretation,
};

function getInterpretation(payload) {
    const { profileId, name } = payload;

    return RequestService.get(`api/profiles/${profileId}/soft-skills/${name}`);
}
