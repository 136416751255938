import { followingConstants } from '../_constants/actions/following.constants';
import { FollowingService } from '../_services/following.service';
import {
    NOTIFICATION_TYPE_ERROR,
    NOTIFICATION_TYPE_SUCCESS
} from '../../common/_constants/actions/notification.constants';
import { NotificationActions } from '../../common/_actions';

export const followingActions = {
    followCompany
};

function followCompany(companyId) {
    return dispatch => {
        dispatch({ type: followingConstants.FOLLOWING_BEGIN });

        FollowingService.followCompany(companyId).then(
            response => {
                dispatch({
                    type: followingConstants.FOLLOWING_SUCCESS,
                    following: response.data
                });
                dispatch(
                    NotificationActions.push(
                        'company-profile.follow.success',
                        NOTIFICATION_TYPE_SUCCESS
                    )
                );
            },
            response => {
                dispatch({
                    type: followingConstants.FOLLOWING_ERROR,
                    error: response.error
                });
                dispatch(
                    NotificationActions.push(
                        'company-profile.follow.error',
                        NOTIFICATION_TYPE_ERROR
                    )
                );
            }
        );
    };
}
