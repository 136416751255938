import {GET_COMPANY_JOB_CARDS, GET_COMPANY_JOBS} from '../_constants/actions/jobs.constants';

export const JobsActions = {
    getCompanyJobs,
    getCompanyJobCards
};

/**
 * @param {number} companyId
 */
function getCompanyJobs(companyId) {
    return { type: GET_COMPANY_JOBS, payload: companyId };
}

function getCompanyJobCards(companyId) {
    return { type: GET_COMPANY_JOB_CARDS, payload: companyId };
}
