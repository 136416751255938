import { jobRoleConstants } from '../../_constants';

const initialState = { isLoading: false, isLoaded: false };
export default function jobRoles(state = initialState, action) {
    switch (action.type) {
        case jobRoleConstants.JOB_ROLES_BEGIN:
        case jobRoleConstants.JOB_ROLE_CARDS_BEGIN:
        case jobRoleConstants.JOB_ROLE_INDUSTRIES:
        case jobRoleConstants.JOB_ROLE_INDUSTRY_CATEGORIES:
            return {
                ...state,
                isLoading: true
            };
        case jobRoleConstants.JOB_ROLES_SUCCESS:
        case jobRoleConstants.JOB_ROLE_CARDS_SUCCESS:
        case jobRoleConstants.JOB_ROLE_INDUSTRIES_SUCCESS:
        case jobRoleConstants.JOB_ROLE_INDUSTRY_CATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        case jobRoleConstants.JOB_ROLES_ERROR:
        case jobRoleConstants.JOB_ROLE_CARDS_FAIL:
        case jobRoleConstants.JOB_ROLE_INDUSTRIES_FAIL:
        case jobRoleConstants.JOB_ROLE_INDUSTRY_CATEGORIES_FAIL:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}
