import { combineReducers } from 'redux';

import search from './search/search.reducer';
import profile from './profile/';
import candidateTracking from './tracking/candidateTracking.reducer';

export default combineReducers({
    search,
    profile,
    candidateTracking,
});
