import { takeLatest } from 'redux-saga/effects';
import { GAUtils } from '../_helpers/GAUtils';
import { UserConstants } from '../_constants/actions/user.constants';
import { history, SagaHelpers } from '../_helpers';
import { AuthService, UserService } from '../_services';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN,
    LOGOUT,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
    VERIFY,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP,
    SIGNUP_WITHOUT_TOKEN,
    SIGNUP_WITHOUT_TOKEN_SUCCESS,
    SIGNUP_WITHOUT_TOKEN_FAIL,
} from '../_constants/actions/auth.constants';

function* login(action) {
    yield SagaHelpers.handleRequest({
        request: [AuthService.login, action.payload],
        actions: [
            (payload) => ({ type: LOGIN_SUCCESS, payload: payload.user }),
            (error) => ({ type: LOGIN_FAIL, error }),
        ],
        messages: { fail: 'common.auth.notifications.login.fail' },
        onSuccess: (response) => {
            GAUtils.event('User', 'USER_LOGIN');
            const { accessToken, user } = response.data;
            UserService.setAccessToken(accessToken);
            UserService.setProfileId(user.profileId);
            UserService.setUser(user);
        },
    });
}

function* verify(action) {
    yield SagaHelpers.handleRequest({
        request: [AuthService.verifyUser, action.payload],
        actions: [
            (payload) => ({ type: VERIFY_SUCCESS, payload: payload }),
            (error) => ({ type: VERIFY_FAIL, error }),
        ],
        messages: { fail: 'common.auth.notifications.verification.fail' },
        onSuccess: (response) => {
            GAUtils.event('User', 'USER_VERIFIED');
            UserService.setUser(response.data);
        },
    });
}

function* signup(action) {
    yield SagaHelpers.handleRequest({
        request: [AuthService.signup, action.payload],
        actions: [
            (payload) => ({ type: SIGNUP_SUCCESS, payload: payload.user }),
            (error) => ({ type: SIGNUP_FAIL, error }),
        ],
        messages: { fail: 'common.auth.notifications.signUp.fail' },
        onSuccess: (response) => {
            GAUtils.event('User', 'USER_SIGNED_UP');
            const { accessToken, user } = response.data;
            UserService.setAccessToken(accessToken);
            UserService.setProfileId(user.profileId);
            UserService.setUser(user);
        },
    });
}

function* signupWithoutToken(action) {
    yield SagaHelpers.handleRequest({
        request: [AuthService.signupWithoutToken, action.payload],
        actions: [
            (payload) => ({
                type: SIGNUP_WITHOUT_TOKEN_SUCCESS,
                payload: payload.user,
            }),
            (error) => ({ type: SIGNUP_WITHOUT_TOKEN_FAIL, error }),
        ],
        messages: {
            success: 'common.auth.notifications.signUp.success',
            fail: 'common.auth.notifications.signUp.fail',
        },
        onSuccess: () => {
            history.replace('/login');
        },
    });
}

function* logout() {
    yield AuthService.logout();
}

function* resetPassword(action) {
    yield SagaHelpers.handleRequest({
        request: [UserService.resetPassword, action.payload],
        actions: [
            (payload) => ({
                type: UserConstants.RESET_PASSWORD_SUCCESS,
                payload,
            }),
            (error) => ({ type: UserConstants.RESET_PASSWORD_ERROR, error }),
        ],
        messages: {
            success: 'common.auth.notifications.reset.success',
            fail: 'common.auth.notifications.reset.fail',
        },
        onSuccess: (response) => {
            GAUtils.event('User', 'PASSWORD_RESET');
        },
    });
}

export default [
    takeLatest(UserConstants.RESET_PASSWORD, resetPassword),
    takeLatest(LOGIN, login),
    takeLatest(LOGOUT, logout),
    takeLatest(VERIFY, verify),
    takeLatest(SIGNUP, signup),
    takeLatest(SIGNUP_WITHOUT_TOKEN, signupWithoutToken),
];
