import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { AutocompleteService } from "../../../candidate/_services";
import RangeField from "../../../common/components/input/RangeField/RangeField";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import { IIdealSkill } from "../../../common/types/ISkillScore";
import { SKILL_WEIGHT_TYPE_PREFERRED } from "../../../common/_constants/actions/skill.constants";
import AdminJobActions from "../../_actions/AdminJobActions";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import { ObjectSchema } from "yup";
import { Formik, useFormikContext } from "formik";

import BaseEdit from "../BaseEdit/BaseEdit";
import FormInput from "../../../common/components/input/FormInput";
import FormSelect from "../../../common/components/input/FormSelect";
import FormField from "../../../common/components/input/FormField";

export type SoftSkillsEditProps = {
    skills: IIdealSkill[];
    jobPostId: number;
};

type Values = Partial<IIdealSkill>;

const SKILLS_FIELD = "skills";
const TYPE_FIELD = "weightType";
const LEVEL_MIN_FIELD = "levelMin";
const LEVEL_MAX_FIELD = "levelMax";

const DEFAULT_VALUES: Values = {
    [SKILLS_FIELD]: [undefined],
    [LEVEL_MIN_FIELD]: "" as unknown as number,
    [LEVEL_MAX_FIELD]: "" as unknown as number,
    [TYPE_FIELD]: SKILL_WEIGHT_TYPE_PREFERRED,
};

export default function SoftSkillsEdit(props: SoftSkillsEditProps) {
    const { skills, jobPostId } = props;

    const dispatch = useDispatch();

    const [isVisible, setIsVisible] = useState(false);
    return (
        <Formik
            initialValues={DEFAULT_VALUES}
            onSubmit={submit}
            validationSchema={createValidation()}
            enableReinitialize
        >
            <Inner
                skills={skills}
                jobPostId={jobPostId}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        </Formik>
    );

    function submit(values: Values) {
        const { id, skills, levelMin, levelMax, weightType } = values;
        dispatch(
            AdminJobActions.saveSkills({
                id,
                jobPostId,
                skills: [skills as any],
                levelMax,
                levelMin,
                weightType,
            })
        );
    }

    function createValidation() {
        return new ObjectSchema({
            skills: new ObjectSchema().required().nullable(),
        });
    }
}

type InnerProps = SoftSkillsEditProps & {
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
};

function Inner(props: InnerProps) {
    const { skills, jobPostId, isVisible, setIsVisible } = props;
    const translate = useTranslate("admin.edit.skills.soft");
    const translateForm = useTranslate("admin.edit.skills.soft.form");
    const pending = AdminJobSelectors.useGetPending();
    const dispatch = useDispatch();
    const formik = useFormikContext();

    return (
        <BaseEdit<IIdealSkill>
            cols={["3fr", "2fr"]}
            head={[
                <div>{translate("table.skill")}</div>,
                <div>{translate("table.range")}</div>,
            ]}
            data={skills || []}
            createItem={(item) => {
                const { id, skills, levelMax, levelMin } = item;
                const isLoading = pending.skills.includes(id);

                if (isLoading) {
                    return <Skeleton height={18} count={2} width="80%" />;
                }

                return (
                    <>
                        <div data-test="soft-skills-edit-name">
                            {skills?.map((skill) => skill.name).join(" | ")}
                        </div>
                        <div>{renderLevelRange(levelMin, levelMax)}</div>
                    </>
                );
            }}
            title={translate("title")}
            isVisible={isVisible}
            onEdit={openEditDialog}
            onCreate={openNewDialog}
            onSave={save}
            onCancel={cancel}
            onClose={cancel}
            onDelete={deleteSkill}
            createButtonText={translate("create")}
            getItemName={(item) => item?.skills?.[0]?.name}
        >
            <FormField
                name={SKILLS_FIELD}
                label={translateForm("skill.label")}
                required
            >
                <FormSelect<{ name: string }>
                    name={SKILLS_FIELD}
                    placeholder={translateForm("skill.placeholder")}
                    loadOptions={AutocompleteService.softSkills}
                    getOptionLabel={(option) => option.name}
                    searchable
                    preload
                    data-test="soft-skills-edit-skill"
                />
            </FormField>
            <RangeField
                label={translateForm("range.label")}
                from={
                    <FormInput
                        name={LEVEL_MIN_FIELD}
                        type="number"
                        placeholder={translateForm("range.placeholder.min")}
                        data-test="soft-skills-edit-level-min"
                        defaultValue={""}
                    />
                }
                to={
                    <FormInput
                        name={LEVEL_MAX_FIELD}
                        type="number"
                        placeholder={translateForm("range.placeholder.max")}
                        data-test="soft-skills-edit-level-max"
                        defaultValue={""}
                    />
                }
            />
        </BaseEdit>
    );

    async function save() {
        await formik.submitForm();

        if (formik.isValid) {
            closeDialog();
        }
    }

    function cancel() {
        closeDialog();
    }

    function openNewDialog() {
        formik.setValues(DEFAULT_VALUES);
        setIsVisible(true);
    }

    function openEditDialog(values: IIdealSkill) {
        formik.setValues(values);
        setIsVisible(true);
    }

    function closeDialog() {
        formik.setValues(DEFAULT_VALUES);
        formik.setErrors({});
        formik.setTouched({});
        setIsVisible(false);
    }

    function deleteSkill(skill: IIdealSkill) {
        dispatch(
            AdminJobActions.deleteSkill({
                jobPostId,
                skillGroupId: skill.id,
            })
        );
    }
}

function renderLevelRange(levelMin: number, levelMax: number) {
    const parts = [];
    if (levelMin) {
        parts.push(levelMin.toString());
    }
    if (levelMax) {
        parts.push(levelMax.toString());
    }

    if (levelMin && levelMax) {
        parts.splice(1, 0, " - ");
    }

    return parts.join("");
}
