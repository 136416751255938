import { HTMLAttributes, PropsWithChildren } from "react";

export function InlineLinks(
    props: PropsWithChildren<HTMLAttributes<HTMLDivElement>>
) {
    const { id, children } = props;
    return (
        <div id={id} className="inline-links">
            <nav>{children}</nav>
        </div>
    );
}
