import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MainMenu } from "./MainMenu";
import { useSwitch } from "../../hooks/useSwitch";
import { UserProfileSelectors } from "../../_selectors/UserProfileSelectors";
import { ProfileActions } from "../../_actions/ProfileActions";
import { AuthSelectors } from "../../_selectors/AuthSelectors";
import { TooltipLabel } from "../TooltipLabel";
import { cx } from "@emotion/css";

export function MainMenuButton(props) {
    const {
        children,
        profileId,
        withSignButton = true,
        title,
        className,
    } = props;
    const [isOpen, , close, toggle] = useSwitch();
    const { imageUrl, isSigned } = useSelector(mapState);

    const dispatch = useDispatch();
    const getProfileImage = (id) =>
        void dispatch(ProfileActions.getProfileImage(id));

    useEffect(() => {
        if (isSigned && profileId) {
            getProfileImage(profileId);
        }
    }, [isSigned, profileId]);

    return (
        <TooltipLabel label={title}>
            <div>
                <div
                    className={cx("header-nav__button", className)}
                    id="main-menu-button"
                    onClick={toggle}
                    data-test="main-menu-button"
                >
                    <img src={imageUrl || "/img/avatar.svg"} alt="" />
                </div>
                <MainMenu
                    isOpen={isOpen}
                    onClose={close}
                    withButton={withSignButton}
                >
                    {children}
                </MainMenu>
            </div>
        </TooltipLabel>
    );
}

function mapState(state) {
    return {
        imageUrl: UserProfileSelectors.getImage(state),
        isSigned: AuthSelectors.isSigned(state),
    };
}
