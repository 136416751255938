export const PRIMARY_COLOR = "#fe3464";
export const SECONDARY_COLOR = "#0b2551";
export const BORDER_COLOR = "#e6e6e6";
export const SURFACE_COLOR = "#fff";
export const BACKGROUND_COLOR = "#f6f7f9";
export const TRANSPARENT_COLOR = "#00000000";
export const SECONDARY_TEXT_COLOR = "#8b92ae"

export const PROGRESS_STRONG_COLOR = "#00d78f";
export const PROGRESS_GOOD_COLOR = "#4a90e2";
export const PROGRESS_FAIR_COLOR = "#ffbb00";
export const PROGRESS_BAD_COLOR = "#fe3464";

export const BREAKPOINT_XS = 400;
export const BREAKPOINT_SM = 600;
export const BREAKPOINT_MD = 900;
export const BREAKPOINT_LG = 1200;

export const HEADER_HEIGHT = 46;

export const RED_COLOR_CHART = [
    "#fe0d46",
    "#fe2055",
    "#fe3464",
    "#fe4873",
    "#fe5b82",
    "#fe6f91",
    "#fe82a0",
    "#fe96af",
    "#ffa9bd",
    "#ffbdcc",
    "#ffe4ea",
    "#ededed",
];

export const BLUE_COLOR_CHART = [
    "#2C82BE",
    "#3388C4",
    "#398FCA",
    "#4095D0",
    "#469BD6",
    "#4DA2DC",
    "#53A8E2",
    "#59B1E6",
    "#5FBAEA",
    "#65C3EF",
    "#6ACBF3",
    "#70D4F7",
    "#76DDFB",
    "#87E0FB",
    "#98E2FA",
    "#A9E5FA",
    "#B9E7F9",
    "#CAEAF9",
    "#DBECF8",
    "#E1EFF9",
    "#E7F2FA",
    "#EDF6FC",
    "#F3F9FD",
    "#F9FCFE",
];
