import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { HEROES_PROFILE_PATH } from "../../../../candidate/_constants/route.constants";
import IHero from "../../../types/IHero";
import { JobMatch } from "../../jobs";
import List from "../../list/List/List";
import ListItem from "../../list/ListItem/ListItem";
import styles from "./HeroList.module.scss";

type HeroListProps = {
    heroes: IHero[];
};

export function HeroList(props: HeroListProps) {
    const { heroes } = props;

    const items = useMemo(
        () => heroes.map((hero, index) => <Item key={index} hero={hero} />),
        [heroes]
    );

    return <List>{items}</List>;
}

function Item(props: { hero: IHero }) {
    const { hero } = props;

    const {
        id,
        originalImageUrl,
        firstName,
        lastName,
        experiences,
        matchScore,
    } = hero;

    const items = useMemo(
        () =>
            experiences.map(
                (item, index) =>
                    item.title && (
                        <div key={index} className={styles.exp}>
                            {item.title}
                        </div>
                    )
            ),
        [experiences]
    );

    return (
        <ListItem className={styles.item}>
            <Link to={generatePath(HEROES_PROFILE_PATH, { id })} />
            <div className={styles.info}>
                <div className={styles.image}>
                    <img src={originalImageUrl} alt="" />
                </div>
                <div>
                    <div
                        className={styles.name}
                    >{`${firstName} ${lastName}`}</div>
                    <div className={styles.titles}>{items}</div>
                </div>
            </div>
            <div className={styles.match}>
                <JobMatch
                    fit={matchScore && matchScore.overallScore}
                    size="medium"
                />
            </div>
        </ListItem>
    );
}
