import React from "react";
import { JobMatch } from "../../../../common/components";

export function Matches(props) {
    const { match = [], title } = props;
    return (
        <>
            <h5>{title}</h5>
            <div className="matches">
                {match.map((item, i) => (
                    <MatchItem
                        match={item.score}
                        title={item.section}
                        text={item.info}
                        key={i}
                    />
                ))}
            </div>
        </>
    );
}

function MatchItem(props) {
    const { match, title, text } = props;
    return (
        <div className="item">
            <JobMatch fit={match} size="medium" />
            <div>
                <div className="item__title">{title}</div>
                <div className="item__text font-book">{text}</div>
            </div>
        </div>
    );
}
