import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate.ts';

import {
    FilterDropdown,
    Checkbox,
    Button,
} from '../../../../common/components';
import { CandidateSearchActions } from '../../../_actions';
import { useSwitch } from '../../../../common/hooks';

/**
 * @param {{
 *      filters: Map<number, string>;
 *      setFilters: (newFilters: Map<number, string>) => any;
 * }} props
 */
export function SortPanel({ filters, onApply }) {
    const setFilter = mapActions(useDispatch());
    const translate = useTranslate('admin.hire.filters');
    const [isOpen, , close, toggle] = useSwitch();
    return (
        <div id="admin-hire--filters">
            <FilterDropdown
                label={translate('overall')}
                isOpen={isOpen}
                close={close}
                toggle={toggle}
            >
                <Filter filter="calculatorHardSkills" />
                <Filter filter="calculatorSoftSkills" />
                <Filter filter="calculatorLanguages" />
                <Filter filter="calculatorQualifications" />
                <Button
                    isWide
                    onClick={() => {
                        onApply();
                        close();
                    }}
                >
                    {translate('apply')}
                </Button>
            </FilterDropdown>
        </div>
    );

    function Filter({ filter }) {
        return (
            <Checkbox
                onChange={(value) =>
                    setFilter('calculators', filters.set(filter, value))
                }
                isChecked={filters.get(filter)}
            >
                {translate(filter)}
            </Checkbox>
        );
    }
}

function mapActions(dispatch) {
    return (key, value) =>
        dispatch(CandidateSearchActions.setFilter(key, value));
}
