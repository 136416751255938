import { PropsWithChildren } from "react";
import FiltersBar from "../../filters/FiltersBar/FiltersBar";

import { SearchProps } from "../../input/Search/Search";
import styles from "./BaseListLayout.module.scss";

type BaseListLayoutProps<SearchOption> = {
    title?: string;
    headingProps?: { content?: JSX.Element; count?: string | JSX.Element };
    searchProps?: SearchProps<SearchOption>;
    filtersProps?: { content?: JSX.Element };
};

export default function BaseListLayout<SearchOption>(
    props: PropsWithChildren<BaseListLayoutProps<SearchOption>>
) {
    const {
        title,
        children,
        headingProps = {},
        filtersProps = {},
        searchProps,
    } = props;
    return (
        <div>
            <div className={styles.head}>
                <div>
                    {title && <h1>{title}</h1>}
                    {headingProps.count ? (
                        <span className={styles.count}>
                            {headingProps.count}
                        </span>
                    ) : null}
                </div>
                {headingProps.content}
            </div>
            <FiltersBar searchProps={searchProps}>
                {filtersProps.content}
            </FiltersBar>
            {children}
        </div>
    );
}
