import { createSelector } from 'reselect';

const information = (state) => state.candidate.profileNew.information;

const getAboutMe = createSelector(information, (info) => info.about);

const getInterests = createSelector(information, (info) => [
    ...info.interests.values(),
]);

const getExperiences = createSelector(
    information,
    (info) => info.jobExperience
);
const getExperiencesList = createSelector(getExperiences, (experiences) => [
    ...experiences.values(),
]);

const getEducations = createSelector(information, (info) => info.educations);
const getEducationsList = createSelector(getEducations, (educations) => [
    ...educations.values(),
]);

const getLanguagesList = createSelector(information, (info) => [
    ...info.languages.values(),
]);

const getSkills = createSelector(information, (info) => info.skills);
const getHardSkillsList = createSelector(getSkills, (skills) => [
    ...skills.filter((item) => filterSkills(item, 'hard')),
]);
const getSoftSkillsList = createSelector(getSkills, (skills) => [
    ...skills.filter((item) => filterSkills(item, 'soft')),
]);

const ProfileInformationSelectors = {
    getInterests,
    getEducations,
    getExperiences,
    getAboutMe,
    getExperiencesList,
    getEducationsList,
    getLanguagesList,
    getHardSkillsList,
    getSoftSkillsList,
    getSkills,
};

export default ProfileInformationSelectors;

function filterSkills(item, type) {
    return item.skill.type === type;
}
