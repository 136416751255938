import React from 'react';
import { addClass } from 'classname-helper';

export function Grid({
    children,
    className,
    min,
    max,
    size,
    repeat,
    gap,
    rowGap,
    columnGap
}) {
    return (
        <div
            className={addClass('grid', className)}
            style={createStyle({
                min,
                max,
                gap,
                size,
                rowGap,
                columnGap,
                repeat
            })}
        >
            {children}
        </div>
    );
}

function createStyle({ max, min, size, gap, rowGap, columnGap, repeat }) {
    const minMax = `repeat(auto-fill, minmax(min(${min}, 100%), ${max}))`;
    const template = size
        ? repeat
            ? `repeat(${repeat}, ${size})`
            : size
        : minMax;

    let style = { gridTemplateColumns: template };

    if (gap) {
        style.gap = gap;
    } else {
        if (columnGap) {
            style.columnGap = columnGap;
        }

        if (rowGap) {
            style.rowGap = rowGap;
        }
    }

    return style;
}
