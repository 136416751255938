import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";

import GalleryEdit from "../../../../common/components/GalleryEdit/GalleryEdit";
import IImage from "../../../../common/types/IImage";
import IUploadState from "../../../../common/types/IUploadState";
import AdminEdit from "../../AdminEdit/AdminEdit";
import styles from "./Gallery.module.scss";

type GalleryProps = {
    onAdd: (file: File) => void;
    onDelete: (id: number) => void;
    images: IImage[];
    uploadState: IUploadState[];
};

export default function Gallery(props: GalleryProps) {
    const { images, uploadState, onAdd, onDelete } = props;
    const translate = useTranslate("admin.profile.image");
    const dispatch = useDispatch();

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="gallery"
        >
            <GalleryEdit
                onAdd={onAdd}
                onDelete={onDelete}
                images={images}
                uploadState={uploadState}
            />
        </AdminEdit.Panel>
    );
}
