import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import { BaseInterpretation } from "../../BaseInterpretation";
import {
    BackButton,
    HeadTitle,
    Loading,
} from "../../../../../common/components";
import InterpretationSelectors from "../../../../_selectors/InterpretationSelectors";
import InterpretationActions from "../../../../_actions/InterpretationActions";
import { AuthSelectors } from "../../../../../common/_selectors";
import { InterpretationShare } from "../../InterpretationShare";
import { SOFT_SKILL_TEST_MOP_PATH } from "../../../../_constants/route.constants";
import { useRenderToHeader } from "../../../../../common/hooks/useRenderToHeader";
import InterpretationContent from "../../../../../common/components/interpretation/InterpretationContent/InterpretationContent";
import InterpretationSummaryList from "../../../../../common/components/interpretation/InterpretationSummary/InterpretationSummaryList/InterpretationSummaryList";
import styles from "./HofikInterpretation.module.scss";
import InterpretationPersonalities from "../../../../../common/components/interpretation/InterpretationPersonalities/InterpretationPersonalities";
import InterpretationSummary from "../../../../../common/components/interpretation/InterpretationSummary/InterpretationSummary";
import HofikSoftSkills from "../HofikSoftSkills/HofikSoftSkills";

const NAME = "hofik";

export function HofikInterpretation() {
    const dispatch = useDispatch();
    const translate = useTranslate("candidate.interpretation.hofik");

    const profileId = useSelector(AuthSelectors.getProfileId);
    const data = useSelector(InterpretationSelectors.getInterpretation(NAME));

    const isLoading = useSelector(
        InterpretationSelectors.getInterpretationLoading(NAME)
    );
    const renderToHeader = useRenderToHeader();
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    useEffect(() => {
        if (profileId) {
            dispatch(InterpretationActions.getInterpretation(profileId, NAME));
        }
    }, [profileId, dispatch]);

    if (isLoading) {
        return <Loading />;
    }

    const { skillInterpretations, profiles, softSkills } = data;

    if (!skillInterpretations) {
        return null;
    }
    const allInterpretations = skillInterpretations.all;
    const companyInterpretation =
        allInterpretations?.["hofik-company-interpretation"];

    return (
        <BaseInterpretation
            links={[
                {
                    path: "#overall",
                    label: translate("links.overall"),
                },
                {
                    path: "#summary",
                    label: translate("links.summary"),
                },
                {
                    path: "#company",
                    label: translate("links.company"),
                },
            ]}
        >
            <HeadTitle title={translate("headings.main")} />
            <InterpretationShare
                isOpen={shareDialogOpen}
                close={() => setShareDialogOpen(false)}
            />
            {renderToHeader(<BackButton link={SOFT_SKILL_TEST_MOP_PATH} />)}
            <InterpretationContent>
                <InterpretationPersonalities
                    profiles={profiles}
                    isLoading={isLoading}
                    heading={translate("headings.main")}
                    subheading={translate("subheading")}
                    numOfElements={3}
                    getBubbleColor={(section) => {
                        switch (section) {
                            case "values":
                                return "blue";
                            case "culture":
                                return "red";
                            case "position":
                                return "yellow";
                        }
                    }}
                />

                <HofikSoftSkills
                    heading={translate("headings.culture")}
                    items={softSkills?.culture}
                />

                <HofikSoftSkills
                    heading={translate("headings.position")}
                    items={softSkills?.position}
                />

                <HofikSoftSkills
                    heading={translate("headings.values")}
                    items={softSkills?.values}
                />

                <InterpretationSummary
                    heading={translate("headings.personal")}
                    items={
                        allInterpretations?.overall?.map(
                            (item: any, index: number) => ({
                                imageName: `hofik/${index + 1}`,
                                title: item.title,
                                description: item.description,
                            })
                        ) ?? []
                    }
                    showList={false}
                >
                    <div className={styles.content}>
                        <div className={styles.grid}>
                            <InterpretationSummaryList
                                label={translate("list.advantages")}
                                items={allInterpretations?.advantages}
                            />
                            <InterpretationSummaryList
                                label={translate("list.risks")}
                                items={allInterpretations?.risks}
                            />
                        </div>
                        <InterpretationSummaryList
                            label={translate("list.development")}
                            items={allInterpretations?.development}
                        />
                        <InterpretationSummary.Separator />
                    </div>
                </InterpretationSummary>

                <InterpretationSummary
                    id="company"
                    heading={translate("headings.company")}
                    items={[
                        {
                            imageName: "hofik/company",
                            title: companyInterpretation?.[0]?.description,
                            description: [
                                companyInterpretation?.[1]?.description,
                                companyInterpretation?.[2]?.description,
                            ],
                        },
                    ]}
                    showList={false}
                >
                    <InterpretationSummaryList
                        items={allInterpretations?.["hofik-company-advantages"]}
                    />
                </InterpretationSummary>
            </InterpretationContent>
        </BaseInterpretation>
    );
}
