import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "../root.reducer";
import rootSaga from "../root.saga";
import errorNotificationMiddleware from "../middleware/errorNotificationMiddleware";
import concurrencyMiddleware from "../middleware/concurrencyMiddleware";
import cookieConfigMiddleware from "../middleware/cookieConfigMiddleware";

const loggerMiddleware = createLogger({ collapsed: true });
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        let middleware: any[] = getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: { ignoredPaths: ["candidate.careerPaths.detail.selectedNode"] },
        })
            .concat(thunkMiddleware)
            .concat(sagaMiddleware)
            .concat(errorNotificationMiddleware)
            .concat(concurrencyMiddleware)
            .concat(cookieConfigMiddleware);

        if (process.env.NODE_ENV !== "production") {
            middleware = middleware.concat(loggerMiddleware);
        }

        return middleware;
    },
});

sagaMiddleware.run(rootSaga);
