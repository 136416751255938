import React from "react";
import { Loading } from "./Loading";
import { cx } from "@emotion/css";

// import style from "./LoadingOverlay.module.scss";

type LoadingOverlayProps = {
    isLoading: boolean;
    withIcon?: boolean;
    isFull?: boolean;
};

export function LoadingOverlay(props: LoadingOverlayProps) {
    const { isLoading, withIcon, isFull } = props;

    return (
        <Loading
            className={cx("loading-overlay", isFull && "full")}
            isLoading={isLoading}
            withIcon={withIcon}
        />
    );
}
