import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    BORDER_COLOR,
    SURFACE_COLOR
} from '../_constants/theme.constants';

export function MuiThemeProvider({ children }) {
    const theme = createTheme({
        palette: {
            primary: {
                main: PRIMARY_COLOR
            },
            secondary: {
                main: SECONDARY_COLOR
            }
        },
        overrides: {
            MuiList: {
                root: {
                    background: SURFACE_COLOR
                }
            },
            MuiInputBase: {
                root: {
                    border: `1px solid ${BORDER_COLOR}`
                }
            },
            MuiSelect: {
                root: {
                    background: '#fff',
                    padding: '10px 30px 10px 14px',
                    color: SECONDARY_COLOR
                },
                select: {
                    '&:focus': {
                        backgroundColor: '#fff'
                    }
                },
                icon: {
                    color: PRIMARY_COLOR
                }
            },
            MuiMenuItem: {
                root: {
                    color: SECONDARY_COLOR
                }
            }
        }
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
