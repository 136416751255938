import { BenefitsGrid } from "../../../../common/components/BenefitsGrid/BenefitsGrid";

import { Panel } from "../../../../common/components/Panel/Panel";
import IBenefit from "../../../../common/types/IBenefit";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";

export function Benefits(props: {
    heading: string;
    benefits: IBenefit[];
    isLoading: boolean;
}) {
    const { heading, benefits, isLoading } = props;

    if(ArrayHelper.isEmpty(benefits)) {
        return null;
    }

    return (
        <Panel>
            <Panel.Heading>{heading}</Panel.Heading>
            <BenefitsGrid benefits={benefits} isLoading={isLoading} />
        </Panel>
    );
}
