import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "react-translate.ts";

import {
    HERO_ONBOARDING_PATH,
    LOGIN_PATH,
    ONBOARDING_PATH,
    PROFILE_PATH_INFO,
} from "../../../_constants/route.constants";
import BasicPageLayout from "../BasicPageLayout/BasicPageLayout";
import { ScrollToTop } from "../../scroll";
import Button from "../../buttons/Button/Button";
import { MainState } from "../../../../root.reducer";
import { AuthSelectors } from "../../../_selectors";
import styles from "./ErrorPageLayout.module.scss";
import Header from "../../nav/Header/Header";
import CandidateHeader from "../../../../candidate/components/header/CandidateHeader/CandidateHeader";
import CompanyHeader from "../../../../admin/components/nav/CompanyHeader/CompanyHeader";
import CandidateSideBar from "../../../../candidate/components/nav/CandidateSideBar/CandidateSideBar";
import CompanySideBar from "../../../../admin/components/nav/CompanySideBar/CompanySideBar";
import CandidateMobileNav from "../../../../candidate/components/nav/CandidateMobileNav/CandidateMobileNav";
import CompanyMobileNav from "../../../../admin/components/nav/CompanyMobileNav/CompanyMobileNav";

type ErrorPageLayoutProps = {
    head: string;
    text: string;
};

export default function ErrorPageLayout(props: ErrorPageLayoutProps) {
    const { head, text } = props;
    const { isSigned, isActive, isHero, isStudent, isAdmin } =
        useSelector(mapState);
    const to = resolveRoute(isSigned, isActive, isHero);

    return (
        <BasicPageLayout
            header={getHeader(isStudent, isAdmin, isHero)}
            sideBar={getSidebar(isStudent, isAdmin, isHero, isActive)}
            mobileBar={getMobileNav(isStudent, isAdmin, isHero, isActive)}
        >
            <ScrollToTop />
            <div className={styles.layout}>
                <h2>{head}</h2>
                <div className={styles.layout__text}>{text}</div>
                <Link to={to}>
                    <Button>
                        <Translate textOnly>common.404.link</Translate>
                    </Button>
                </Link>
            </div>
        </BasicPageLayout>
    );
}

function getSidebar(
    isStudent: boolean,
    isAdmin: boolean,
    isHero: boolean,
    isActive: boolean
) {
    if (!isActive) {
        return null;
    }

    if (isStudent || isHero) {
        return <CandidateSideBar />;
    } else if (isAdmin) {
        return <CompanySideBar />;
    }

    return null;
}

function getMobileNav(
    isStudent: boolean,
    isAdmin: boolean,
    isHero: boolean,
    isActive: boolean
) {
    if (!isActive) {
        return null;
    }

    if (isStudent || isHero) {
        return <CandidateMobileNav />;
    } else if (isAdmin) {
        return <CompanyMobileNav />;
    }

    return null;
}

function getHeader(isStudent: boolean, isAdmin: boolean, isHero: boolean) {
    if (isStudent || isHero) {
        return <CandidateHeader />;
    } else if (isAdmin) {
        return <CompanyHeader />;
    }

    return <Header />;
}

function resolveRoute(isSigned: boolean, isActive: boolean, isHero: boolean) {
    if (isSigned) {
        if (!isActive) {
            return isHero ? HERO_ONBOARDING_PATH : ONBOARDING_PATH;
        }

        return PROFILE_PATH_INFO;
    }

    return LOGIN_PATH;
}

function mapState(state: MainState) {
    return {
        isSigned: AuthSelectors.isSigned(state),
        isActive: AuthSelectors.isActive(state),
        isHero: AuthSelectors.isHero(state),
        isAdmin: AuthSelectors.isAdmin(state),
        isStudent: AuthSelectors.isStudent(state),
    };
}
