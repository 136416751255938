import React from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { List } from "immutable";

import { FiltersButton } from "../../../../common/components/buttons/FiltersButton";
import { AsyncSelect, Select, SelectYear } from "../../../../common/components";
import { AutocompleteService } from "../../../../candidate/_services";
import { CandidateSearchActions } from "../../../_actions";

export function Filters(props) {
    const { filters, changeFilter, onApply } = props;

    const selectStyles = {
        menu: (styles) => ({ ...styles, zIndex: 1501 }),
    };

    const translate = useTranslate();
    const translateMisc = useTranslate("common.misc");

    const dispatch = useDispatch();
    const resetFilters = () => dispatch(CandidateSearchActions.resetFilters());

    const clearOptionText = translate("clear");

    const createSelect = (
        name,
        loadOptions,
        translatePath = "admin.hire.filters"
    ) => {
        return {
            label: translate(name + ".label"),
            input: (
                <AsyncSelect
                    customStyles={selectStyles}
                    value={filters[name].toArray()}
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.id}
                    onChange={(...args) => filtersChange(name, ...args)}
                    isMulti
                    placeholder={translate(
                        translatePath + "." + name + ".placeholder"
                    )}
                    loadOptions={loadOptions}
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                    loadingMessage={() =>
                        translate(translatePath + "." + name + ".placeholder")
                    }
                />
            ),
        };
    };

    return (
        <FiltersButton
            onApply={onApply}
            onReset={() => {
                resetFilters();
                onApply();
            }}
            filters={[
                createSelect("skills", AutocompleteService.hardSkills),
                createSelect(
                    "university",
                    AutocompleteService.universities,
                    "common.onboarding.education.form.university"
                ),
                {
                    component: renderGraduation(),
                },
                {
                    component: renderExperience(),
                },
            ]}
        />
    );

    function renderExperience() {
        const experience = filters.experience;
        const [min, max] = experience;

        const options = Array.from(new Array(31), (_, index) => {
            return {
                value: index,
                label: translateMisc("yearsCount", { count: index }),
            };
        });
        return (
            <div className="filters-button__inline-input">
                {renderFilter(
                    min,
                    (option) => [option, ...experience.slice(1)],
                    "labelFrom"
                )}
                {renderFilter(
                    max,
                    (option) => [...experience.slice(0, 1), option],
                    "labelTo"
                )}
            </div>
        );

        function renderFilter(value, onChange, labelKey) {
            return (
                <div>
                    <div className="filters-button__filter__label">
                        {translate("experience." + labelKey)}
                    </div>
                    <div className="filters-button__filter__input">
                        <Select
                            styles={selectStyles}
                            value={value}
                            onChange={(option) =>
                                changeFilter("experience", onChange(option))
                            }
                            options={options}
                            defaultValue={null}
                            yearCount={30}
                            placeholder={translate("experience.placeholder")}
                            clearOption={{ label: clearOptionText }}
                            isSearchable={false}
                        />
                    </div>
                </div>
            );
        }
    }

    function renderGraduation() {
        const graduation = filters.graduation;
        const [min, max] = graduation;

        return (
            <div className="filters-button__inline-input">
                {renderFilter(
                    min,
                    (option) => [option, ...graduation.slice(1)],
                    "labelFrom"
                )}
                {renderFilter(
                    max,
                    (option) => [...graduation.slice(0, 1), option],
                    "labelTo"
                )}
            </div>
        );

        function renderFilter(value, onChange, labelKey) {
            return (
                <div>
                    <div className="filters-button__filter__label">
                        {translate("graduation." + labelKey)}
                    </div>
                    <div className="filters-button__filter__input">
                        <SelectYear
                            styles={selectStyles}
                            value={value}
                            onChange={(option) =>
                                changeFilter("graduation", onChange(option))
                            }
                            defaultValue={null}
                            yearCount={30}
                            placeholder={translate("graduation.placeholder")}
                            clearOption
                            clearOptionText={clearOptionText}
                        />
                    </div>
                </div>
            );
        }
    }

    function filtersChange(name, value, { action, option }) {
        switch (action) {
            case "select-option":
                changeFilter(name, filters[name].push(option));
                break;
            case "remove-value":
                changeFilter(name, List(value));
                break;
            case "clear":
                changeFilter(name, List());
                break;
            default:
                break;
        }
    }
}
