import { useState } from "react";
import { Formik, Form, useFormikContext, useField } from "formik";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
    FormInput,
    IntegratedDialog,
    OnboardingButtons,
    OnboardingForm,
} from "../../../../components";
import HeroOnboardingSelectors from "../../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import HeroOnboardingActions from "../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import { HeroImageEdit } from "../../components/HeroImageEdit";
import OnboardingProfilePicture from "../../../../components/onboarding/OnboardingProfilePicture/OnboardingProfilePicture";
import OnboardingLayout from "../../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";
import styles from './HeroPersonal.module.scss';

const FIELDS = {
    firstName: "firstName",
    lastName: "lastName",
    intro: "introduction",
    linkedin: "linkedInUrl",
    image: "image",
};

export function HeroPersonal() {
    const dispatch = useDispatch();
    const data = useSelector(HeroOnboardingSelectors.Personal.getData);

    return (
        <OnboardingLayout
            color="red"
            imageProps={{ src: "/img/heroes/onboard/personal.svg" }}
        >
            <Formik
                initialValues={data}
                onSubmit={submit}
                validationSchema={getValidation}
                validateOnMount
                enableReinitialize
            >
                <Content />
            </Formik>
        </OnboardingLayout>
    );

    function submit(values) {
        const first = values[FIELDS.firstName];
        const last = values[FIELDS.lastName];
        const intro = values[FIELDS.intro];
        const image = values[FIELDS.image];
        const linkedin = values[FIELDS.linkedin];

        dispatch(
            HeroOnboardingActions.Personal.saveData(
                first,
                last,
                intro,
                image,
                linkedin
            )
        );
    }

    function getValidation() {
        return Yup.object().shape({
            [FIELDS.firstName]: Yup.string().required(),
            [FIELDS.lastName]: Yup.string().required(),
            [FIELDS.intro]: Yup.string().required(),
            [FIELDS.image]: Yup.string().required(),
            [FIELDS.linkedin]: Yup.string().nullable().url("Must be valid url"),
        });
    }
}

function Content() {
    const translate = useTranslate("common.heroOnboarding.steps.personal");
    const translateForm = useTranslate(
        "common.heroOnboarding.steps.personal.form"
    );

    const { submitForm } = useFormikContext();

    const [dialogOpen, setDialogOpen] = useState();

    const [{ value }, { error, touched }] = useField(FIELDS.image);

    return (
        <>
            {dialogOpen && (
                <IntegratedDialog>
                    <HeroImageEdit
                        originalImageUrl={value}
                        onClose={closeImageEdit}
                    />
                </IntegratedDialog>
            )}
            <OnboardingForm>
                <div className={styles.title}>
                    <OnboardingProfilePicture
                        image={value}
                        hasError={!!error && touched}
                        onClick={openImageEdit}
                        className={styles.avatar}
                    />
                    <h1 className="font-light">{translate("title")}</h1>
                </div>
                <InnerForm translate={translateForm} />
            </OnboardingForm>
            <OnboardingButtons>
                {/* <Button variant="secondary" onClick={openImageEdit}>
                    {social}
                </Button> */}
                <OnboardingButtons.Next onClick={submitForm} />
            </OnboardingButtons>
        </>
    );

    function openImageEdit() {
        setDialogOpen(true);
    }

    function closeImageEdit() {
        setDialogOpen(false);
    }
}

function InnerForm(props) {
    const { translate } = props;
    const dispatch = useDispatch();

    return (
        <Form>
            <FormInput
                name={FIELDS.firstName}
                type="text"
                required
                placeholder={translate("firstName.placeholder")}
                label={translate("firstName.label")}
                onChange={(e) => changeValue(FIELDS.firstName, e)}
            />
            <FormInput
                name={FIELDS.lastName}
                type="text"
                required
                placeholder={translate("lastName.placeholder")}
                label={translate("lastName.label")}
                onChange={(e) => changeValue(FIELDS.lastName, e)}
            />
            <FormInput
                name={FIELDS.intro}
                type="textarea"
                rows={4}
                resize={false}
                required
                placeholder={translate("intro.placeholder")}
                label={translate("intro.label")}
                onChange={(e) => changeValue(FIELDS.intro, e)}
            />
            <FormInput
                name={FIELDS.linkedin}
                type="text"
                placeholder={translate("linkedin.placeholder")}
                label={translate("linkedin.label")}
                onChange={(e) => changeValue(FIELDS.linkedin, e)}
            />
        </Form>
    );

    function changeValue(field, e) {
        dispatch(
            HeroOnboardingActions.Personal.changeValue(field, e.target.value)
        );
    }
}
