import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { followingActions } from "../../../candidate/_actions/following.action";
import { Error, Loading, useWindowState } from "..";
import { BREAKPOINT_SM } from "../../_constants/theme.constants";
import CompanyProfileActions from "../../_actions/CompanyProfileActions";
import CompanyProfileSelectors from "../../_selectors/CompanyProfileSelectors";

import { DesktopLayout } from "./parts/Desktop/DesktopLayout";
import { MobileLayout } from "./parts/Mobile/MobileLayout";
import { MainState } from "../../../root.reducer";

type CompanyProfileProps = {
    companyId: number;
    displayOnly?: boolean;
};

export default function CompanyProfile(props: CompanyProfileProps) {
    const { companyId, displayOnly = false } = props;
    const { companyProfile, isLoading } = useSelector(mapState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(CompanyProfileActions.getDetail(companyId));
    }, [companyId, dispatch]);

    const { width } = useWindowState();

    if (isLoading) {
        return <Loading />;
    }

    if (!companyProfile) {
        return <Error message="Company profile not found" />;
    }

    return (
        <div className="company-profile">
            <div className="container">
                <div className="container-inner">
                    {width <= BREAKPOINT_SM ? (
                        <MobileLayout
                            company={companyProfile}
                            onFollow={follow}
                            displayOnly={displayOnly}
                        />
                    ) : (
                        <DesktopLayout
                            company={companyProfile}
                            onFollow={follow}
                            displayOnly={displayOnly}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    function follow() {
        followCompany(companyProfile.id);
    }


    function followCompany(companyId: number) {
        dispatch(followingActions.followCompany(companyId));
    }
}

function mapState(state: MainState) {
    return {
        companyProfile: CompanyProfileSelectors.getDetailData(state),
        isLoading: CompanyProfileSelectors.isLoading(state),
    };
}
