import { useEffect } from "react";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";

import HeroOnboardingSelectors from "../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import { HeroTestInterpretation } from "./HeroTestInterpretation/HeroTestInterpretation";
import { AuthSelectors } from "../../../_selectors";
import HeroTest from "./HeroTest/HeroTest";
import { OnboardingButtons, OnboardingForm } from "../../../components";
import { HeroBackButton } from "../components/HeroBackButton";
import OnboardingLayout from "../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";

export function HeroSoftSkills() {
    const dispatch = useDispatch();
    const translate = useTranslate("common.heroOnboarding.steps.skills");

    const { started, complete } = useSelector(mapState);

    const heroProfileId = useSelector(AuthSelectors.getHeroProfileId);

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroOnboardingActions.Skills.getStatus(heroProfileId));
        }
    }, [heroProfileId, dispatch]);

    if (complete) {
        return <HeroTestInterpretation />;
    }

    if (started) {
        return <HeroTest />;
    }

    return (
        <OnboardingLayout
            color="blue"
            imageProps={{ src: "/img/onboarding/skills.png" }}
        >
            <OnboardingForm className="skills">
                <h1 className="font-light">{translate("title")}</h1>
                <div className="skills__subtitle font-book">
                    {translate("subtitle")}
                </div>
                <div className="skills__hints">
                    <div className="skills__hints__title">
                        {translate("hints.title")}
                    </div>
                    <ul className="font-book">
                        {(translate("hints.steps") || []).map(renderStep)}
                    </ul>
                </div>
                <div className="skills__time font-book">
                    {translate("time")}
                </div>
            </OnboardingForm>
            <OnboardingButtons>
                <HeroBackButton />
                <OnboardingButtons.Next
                    onClick={startTest}
                    content={translate("start")}
                />
            </OnboardingButtons>
        </OnboardingLayout>
    );

    function renderStep(item, i) {
        return (
            <li key={i}>
                <div className="skills__hints__index font-light">{i + 1}</div>{" "}
                {item}
            </li>
        );
    }

    function startTest() {
        dispatch(HeroOnboardingActions.Skills.startTest());
    }
}

function mapState(state) {
    return {
        started: HeroOnboardingSelectors.Skills.hasStarted(state),
        complete: HeroOnboardingSelectors.Skills.isComplete(state),
    };
}
