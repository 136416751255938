import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { OnboardingRoute, PrivateRoute } from "./common/components";
import { NotFound, PublicInterpretation, Onboarding } from "./common/pages";
import {
    LOGIN_PATH,
    SIGNUP_PATH,
    FORGOT_PASSWORD_PATH,
    VERIFY_PATH,
    ONBOARDING_PATH,
    FORGOT_PASSWORD_TOKEN_PATH,
    ADMIN_JOBS_PATH,
    ADMIN_PROFILE_PATH,
    ADMIN_CANDIDATES_PATH,
    ADMIN_HIRE_PATH,
    PUBLIC_INTERPRETATION_PATH,
    HERO_ONBOARDING_PATH,
    TEST_COMPLETE_REDIRECT_PATH,
    NOT_ALLOWED_PATH,
    HERO_SIGNUP_PATH,
    ADMIN_JOB_EDIT_PATH,
    ADMIN_TEAM_PATH,
    JOB_LIST_PATH,
    JOB_DETAIL_PATH,
    COMPANY_DETAIL_PATH,
    COMPANY_LIST_PATH,
} from "./common/_constants/route.constants";

import {
    Profile,
    SoftSkillTest,
    Interpretations,
    JobRole,
    Dashboard,
} from "./candidate/pages";
import {
    PROFILE_PATH,
    SOFT_SKILL_TEST_PATH,
    PROFILE_PATH_INFO,
    INTERPRETATION_PATH,
    DASHBOARD_PATH,
    APPLICATIONS_TRACKING_PATH,
    JOB_ROLE_PATH,
    CAREER_PATHS_PATH,
    SOFT_SKILL_TEST_PARAM_PATH,
    HEROES_PROFILE_PATH,
    CAREER_SEARCH_PATH,
    JOB_ROLES_PAGE_PATH,
    HEROES_LIST_PATH,
} from "./candidate/_constants/route.constants";
import {
    ROLE_USER,
    ROLE_HR_ADMIN,
    ROLE_HERO,
} from "./common/_constants/role.constants";
import { AuthSelectors } from "./common/_selectors";
import { ADMIN_WALLET_PATH } from "./admin/_constants/route.constants";
import { VERIFY_TOKEN_PATH } from "./common/_constants/route.constants";
import { BaseRoute } from "./common/components/routes/BaseRoute";
import { useTranslate } from "react-translate.ts";
import { HeroOnboarding } from "./common/pages/HeroOnboarding/HeroOnboarding";
import { CompleteRedirect } from "./candidate/pages/Tests/components/CompleteRedirect";
import { NotAllowed } from "./common/pages/NotAllowed";
import { HeroSignUp } from "./common/pages/HeroOnboarding/steps/HeroSignUp";
import { CareerSearch } from "./candidate/pages/CareerSearch/CareerSearch";
import CompanyListPage from "./candidate/pages/CompanyListPage/CompanyListPage";
import HeroListPage from "./candidate/pages/HeroListPage/HeroListPage";
import JobRoleListPage from "./candidate/pages/JobRoleListPage/JobRoleListPage";
import JobListPage from "./candidate/pages/JobListPage/JobListPage";
import CompanyProfilePage from "./candidate/pages/CompanyProfilePage/CompanyProfilePage";
import ForgotPasswordPage from "./common/pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import LoginPage from "./common/pages/Auth/LoginPage/LoginPage";
import AdminJobListPage from "./admin/pages/AdminJobListPage/AdminJobListPage";
import HeroProfilePage from "./candidate/pages/HeroProfilePage/HeroProfilePage";
import ApplicationsTrackingPage from "./candidate/pages/ApplicationsTrackingPage/ApplicationsTrackingPage";
import CareerPathsPage from "./candidate/pages/CareerPathsPage/CareerPathsPage";
import AdminProfilePage from "./admin/pages/AdminProfilePage/AdminProfilePage";
import CompanyJobPage from "./admin/pages/CompanyJobPage/CompanyJobPage";
import CompanyCandidatesPage from "./admin/pages/CompanyCandidatesPage/CompanyCandidatesPage";
import CompanyTeamPage from "./admin/pages/CompanyTeamPage/CompanyTeamPage";
import CompanyWalletPage from "./admin/pages/CompanyWalletPage/CompanyWalletPage";
import { AdminHire } from "./admin/pages/Hire/AdminHire";
import SignupPage from "./common/pages/Auth/SignupPage/SignupPage";
import VerificationPage from "./common/pages/Auth/VerificationPage/VerificationPage";

export function Routes() {
    const isAdmin = useSelector((state) => AuthSelectors.isAdmin(state));
    const { isHero, isSigned } = useSelector(mapState);

    const translate = useTranslate("common.titles");

    return (
        <Switch>
            <Route>
                <Switch>
                    <BaseRoute path={LOGIN_PATH} title={translate("signIn")}>
                        <LoginPage />
                    </BaseRoute>
                    <BaseRoute path={SIGNUP_PATH} title={translate("signUp")}>
                        <SignupPage />
                    </BaseRoute>
                    <BaseRoute
                        path={FORGOT_PASSWORD_TOKEN_PATH}
                        title={translate("forgot")}
                    >
                        <ForgotPasswordPage />
                    </BaseRoute>
                    <BaseRoute
                        path={FORGOT_PASSWORD_PATH}
                        title={translate("forgot")}
                    >
                        <ForgotPasswordPage />
                    </BaseRoute>
                    <Route path={PUBLIC_INTERPRETATION_PATH}>
                        <PublicInterpretation />
                    </Route>
                    <Route exact path={TEST_COMPLETE_REDIRECT_PATH}>
                        <CompleteRedirect />
                    </Route>

                    <BaseRoute path={HERO_SIGNUP_PATH}>
                        <HeroSignUp />
                    </BaseRoute>
                    {isSigned && isHero ? (
                        <OnboardingRoute exact path={HERO_ONBOARDING_PATH}>
                            <HeroOnboarding />
                        </OnboardingRoute>
                    ) : null}

                    <OnboardingRoute path={ONBOARDING_PATH}>
                        <Onboarding />
                    </OnboardingRoute>
                    <OnboardingRoute
                        exact
                        path={VERIFY_PATH}
                        title={translate("verify")}
                    >
                        <VerificationPage />
                    </OnboardingRoute>
                    <PrivateRoute
                        path={VERIFY_TOKEN_PATH}
                        title={translate("verify")}
                    >
                        <VerificationPage />
                    </PrivateRoute>

                    <PrivateRoute path={DASHBOARD_PATH} allowedRole={ROLE_USER}>
                        <Dashboard />
                    </PrivateRoute>

                    <PrivateRoute path={HEROES_PROFILE_PATH}>
                        <HeroProfilePage />
                    </PrivateRoute>
                    <PrivateRoute path={HEROES_LIST_PATH}>
                        <HeroListPage />
                    </PrivateRoute>

                    <PrivateRoute
                        path={COMPANY_DETAIL_PATH}
                        allowedRoles={[ROLE_USER]}
                    >
                        <CompanyProfilePage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={COMPANY_LIST_PATH}
                        allowedRoles={[ROLE_USER]}
                        title={translate("companies")}
                    >
                        <CompanyListPage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={APPLICATIONS_TRACKING_PATH}
                        allowedRoles={[ROLE_USER]}
                        title={translate("tracking")}
                    >
                        <ApplicationsTrackingPage />
                    </PrivateRoute>

                    {/** ADMIN ROUTES */}
                    <PrivateRoute
                        path={ADMIN_HIRE_PATH}
                        allowedRoles={[ROLE_HR_ADMIN]}
                        title={translate("forgot")}
                    >
                        <AdminHire />
                    </PrivateRoute>
                    <PrivateRoute
                        path={ADMIN_CANDIDATES_PATH}
                        allowedRoles={[ROLE_HR_ADMIN]}
                        title={translate("forgot")}
                    >
                        <CompanyCandidatesPage />
                    </PrivateRoute>
                    {/*<PrivateRoute*/}
                    {/*    path={ADMIN_WALLET_PATH}*/}
                    {/*    allowedRoles={[ROLE_HR_ADMIN]}*/}
                    {/*>*/}
                    {/*    <CompanyWalletPage />*/}
                    {/*</PrivateRoute>*/}

                    <PrivateRoute
                        path={CAREER_PATHS_PATH}
                        allowedRoles={[ROLE_USER, ROLE_HERO]}
                        title={translate("careerPaths")}
                    >
                        <CareerPathsPage />
                    </PrivateRoute>

                    {/*<PrivateRoute*/}
                    {/*    path={CAREER_SEARCH_PATH}*/}
                    {/*    allowedRoles={[ROLE_USER]}*/}
                    {/*>*/}
                    {/*    <CareerSearch />*/}
                    {/*</PrivateRoute>*/}
                    <PrivateRoute
                        path={SOFT_SKILL_TEST_PARAM_PATH}
                        allowedRoles={[ROLE_USER, ROLE_HERO]}
                    >
                        <SoftSkillTest />
                    </PrivateRoute>

                    <PrivateRoute
                        exact
                        path={SOFT_SKILL_TEST_PATH}
                        allowedRoles={[ROLE_USER, ROLE_HERO]}
                    >
                        <SoftSkillTest />
                    </PrivateRoute>

                    <PrivateRoute
                        path={INTERPRETATION_PATH}
                        allowedRoles={[ROLE_USER, ROLE_HERO]}
                    >
                        <Interpretations />
                    </PrivateRoute>
                    {/*<PrivateRoute*/}
                    {/*    path={JOB_ROLE_PATH}*/}
                    {/*    allowedRoles={[ROLE_USER, ROLE_HERO]}*/}
                    {/*>*/}
                    {/*    <JobRole />*/}
                    {/*</PrivateRoute>*/}
                    {/*<PrivateRoute*/}
                    {/*    path={JOB_ROLES_PAGE_PATH}*/}
                    {/*    allowedRoles={[ROLE_USER, ROLE_HERO]}*/}
                    {/*    title={translate("jobRoles")}*/}
                    {/*>*/}
                    {/*    <JobRoleListPage />*/}
                    {/*</PrivateRoute>*/}
                    <PrivateRoute
                        path={ADMIN_TEAM_PATH}
                        allowedRoles={[ROLE_HR_ADMIN]}
                        title={translate("team")}
                    >
                        <CompanyTeamPage />
                    </PrivateRoute>

                    {isAdmin ? (
                        <PrivateRoute
                            path={ADMIN_PROFILE_PATH}
                            allowedRoles={[ROLE_HR_ADMIN]}
                        >
                            <AdminProfilePage />
                        </PrivateRoute>
                    ) : (
                        <PrivateRoute
                            path={PROFILE_PATH}
                            render={(props) =>
                                props.match.isExact ? (
                                    <Redirect to={PROFILE_PATH_INFO} />
                                ) : (
                                    <Profile {...props} />
                                )
                            }
                            allowedRoles={[ROLE_USER, ROLE_HERO]}
                        />
                    )}

                    {isAdmin ? (
                        <>
                            <PrivateRoute
                                exact
                                path={ADMIN_JOBS_PATH}
                                allowedRoles={[ROLE_HR_ADMIN]}
                                title={translate("adminJobs")}
                            >
                                <AdminJobListPage />
                            </PrivateRoute>
                            <PrivateRoute
                                path={ADMIN_JOB_EDIT_PATH}
                                allowedRoles={[ROLE_HR_ADMIN]}
                            >
                                <CompanyJobPage />
                            </PrivateRoute>
                        </>
                    ) : (
                        <PrivateRoute
                            path={[JOB_DETAIL_PATH, JOB_LIST_PATH]}
                            allowedRoles={[ROLE_USER]}
                            title={translate("jobs")}
                        >
                            <JobListPage />
                        </PrivateRoute>
                    )}

                    <Route path={NOT_ALLOWED_PATH}>
                        <NotAllowed />
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </Route>
        </Switch>
    );
}

function mapState(state) {
    return {
        isHero: AuthSelectors.isHero(state),
        isActive: AuthSelectors.isActive(state),
        isSigned: AuthSelectors.isSigned(state),
    };
}
