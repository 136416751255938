import {
    PROFILE_TYPE_COLLEGE,
    PROFILE_TYPE_UNIVERSITY,
} from "../../common/_constants/profile.constants";
import { CareerPathsConstants } from "../_constants/actions/CareerPathsConstants";

export const CareerPathsActions = {
    getDefaultMap,
    getMap,
    getMapPaths,
    getTooltip,
    getDetail,
    getCollegeData,
    setRoutingData,
    setSelectedNode,
    setInputValue,
    setActiveSearch,
    setActiveRouteSearch,
    setSelectedPath,
    setPoint,
    addPoint,
    removePoint,
    clearPoints,
    setSearchSelected,
    setCountry,
    setIndustry,
    getCategories,
    setSelectedCategory,
};

function getDefaultMap(profileType, categoryId, subcategoryId) {
    switch (profileType.type) {
        case PROFILE_TYPE_COLLEGE:
            return getMap({
                industryId: profileType.industryId,
                profileType: profileType.type,
                categoryId,
                subcategoryId,
            });
        case PROFILE_TYPE_UNIVERSITY:
            return getMap({
                degreeFieldId: profileType.degreeFieldId,
                profileType: profileType.type,
                categoryId,
                subcategoryId,
            });
        default:
            return getMap({
                jobRoleId: profileType.jobRoleId,
                categoryId,
                subcategoryId,
            });
    }
}

function getMap(payload) {
    return { type: CareerPathsConstants.GET_MAP, payload };
}

function getMapPaths(startJobRoleId, endJobRoleId, categoryId, subcategoryId) {
    return {
        type: CareerPathsConstants.GET_MAP_PATHS,
        payload: { startJobRoleId, endJobRoleId, categoryId, subcategoryId },
    };
}

function getTooltip(jobRoleId) {
    return {
        type: CareerPathsConstants.GET_MAP_TOOLTIP,
        payload: jobRoleId,
    };
}

function getDetail(jobRoleId) {
    return {
        type: CareerPathsConstants.GET_MAP_DETAIL,
        payload: jobRoleId,
    };
}

function getCollegeData() {
    return { type: CareerPathsConstants.GET_COLLEGE_DATA };
}

function setRoutingData(payload) {
    return { type: CareerPathsConstants.SET_ROUTING_DATA, payload };
}

function setSelectedNode(node) {
    return { type: CareerPathsConstants.SET_SELECTED_NODE, payload: node };
}

function setInputValue(payload) {
    return { type: CareerPathsConstants.SET_INPUT_VALUE, payload };
}

function setActiveSearch(payload) {
    return { type: CareerPathsConstants.SET_ACTIVE_SEARCH, payload };
}

function setActiveRouteSearch(payload) {
    return { type: CareerPathsConstants.SET_ACTIVE_ROUTE_SEARCH, payload };
}

function setSelectedPath(path, index) {
    return {
        type: CareerPathsConstants.SET_SELECTED_PATH,
        payload: { path, index },
    };
}

function setPoint(index, data) {
    return {
        type: CareerPathsConstants.SET_POINT,
        payload: { index, data },
    };
}

function addPoint(node) {
    return {
        type: CareerPathsConstants.ADD_POINT,
        payload: node,
    };
}

function removePoint(index) {
    return {
        type: CareerPathsConstants.REMOVE_POINT,
        payload: index,
    };
}

function clearPoints() {
    return {
        type: CareerPathsConstants.CLEAR_POINTS,
    };
}

function setSearchSelected(node) {
    return {
        type: CareerPathsConstants.SET_SEARCH_SELECT,
        payload: node,
    };
}

function setCountry(country) {
    return {
        type: CareerPathsConstants.SET_COUNTRY,
        payload: country,
    };
}

function setIndustry(industry) {
    return {
        type: CareerPathsConstants.SET_INDUSTRY,
        payload: industry,
    };
}

function getCategories(jobRoleId) {
    return {
        type: CareerPathsConstants.GET_CATEGORIES,
        payload: jobRoleId,
    };
}

function setSelectedCategory(categoryId, type) {
    return {
        type: CareerPathsConstants.SET_SELECTED_CATEGORY,
        payload: { categoryId, type },
    };
}
