import { cx } from "@emotion/css";
import { ReactElement, useEffect, useRef } from "react";

import styles from "./Checkbox.module.scss";

export type CheckboxProps = {
    name?: string;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    label?: string | ReactElement;
    className?: string;
    required?: boolean;
};

export default function Checkbox(props: CheckboxProps) {
    const {
        checked = false,
        label,
        onChange,
        className,
        required,
        ...rest
    } = props;
    const ref = useRef<HTMLInputElement>(null);

    const labelComponent = (
        <label className={cx(styles.label, required && styles.required)}>
            {label}
        </label>
    );

    useEffect(() => {
        if (ref.current) {
            ref.current.checked = checked;
        }
    }, [checked]);

    return (
        <div
            className={cx(styles.checkbox, className)}
            onClick={handleChange}
            {...rest}
        >
            <input ref={ref} type="checkbox" className={styles.input} />
            {labelComponent}
        </div>
    );

    function handleChange() {
        onChange && onChange(!checked);
    }
}
