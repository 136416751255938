import { combineReducers } from 'redux';
import {
    GET_CANDIDATE_PROFILE,
    GET_CANDIDATE_PROFILE_MATCH,
    GET_CANDIDATE_PROFILE_SUCCESS,
    GET_CANDIDATE_PROFILE_MATCH_SUCCESS,
    GET_CANDIDATE_PROFILE_FAIL,
    GET_CANDIDATE_PROFILE_MATCH_FAIL,
} from '../../../_constants/actions/candidates.constants';

import data from './data.reducer';

export default combineReducers({ loading, data });

function loading(state = false, action) {
    switch (action.type) {
        case GET_CANDIDATE_PROFILE:
        case GET_CANDIDATE_PROFILE_MATCH:
            return true;
        case GET_CANDIDATE_PROFILE_SUCCESS:
        case GET_CANDIDATE_PROFILE_MATCH_SUCCESS:
            return false;
        case GET_CANDIDATE_PROFILE_FAIL:
        case GET_CANDIDATE_PROFILE_MATCH_FAIL:
            return false;
        default:
            return state;
    }
}
