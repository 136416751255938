import { cx } from "@emotion/css";
import { HTMLAttributes, MutableRefObject, PropsWithChildren } from "react";

import styles from "./SelectMenu.module.scss";

interface SelectMenuProps extends HTMLAttributes<HTMLDivElement> {
    isVisible: boolean;
    forwardRef: MutableRefObject<HTMLDivElement>;
}

export default function SelectMenu(props: PropsWithChildren<SelectMenuProps>) {
    const { forwardRef, className, isVisible, children, ...rest } = props;

    if (!isVisible) {
        return null;
    }

    return (
        <div ref={forwardRef} className={cx(styles.menu, className)} {...rest}> 
            <ul>{children}</ul>
        </div>
    );
}

interface ItemProps extends HTMLAttributes<HTMLLIElement> {
    hovered: boolean;
    selected?: boolean;
}

function Item(props: PropsWithChildren<ItemProps>) {
    const { hovered, selected, className, children, ...rest } = props;
    return (
        <li
            className={cx(
                styles.menu__item,
                hovered && styles.menu__item__hovered,
                selected && styles.menu__item__selected,
                className
            )}
            {...rest}
        >
            {children}
        </li>
    );
}

SelectMenu.Item = Item;
