import { createSelector } from 'reselect';
const wallet = (state) => state.admin.wallet;

const isLoading = createSelector(
    wallet,
    ({ loading }) => loading.isLoading && !loading.isLoaded
);

const getPay = createSelector(wallet, ({ pay }) => pay);

const isPaymentLoading = createSelector(getPay, (pay) => pay.loading);

const getData = createSelector(wallet, ({ data }) => data);

const getCompanyCurrency = createSelector(
    wallet,
    ({ companyCurrency }) => companyCurrency
);

export const WalletSelectors = {
    isLoading,
    getPay,
    isPaymentLoading,
    getData,
    getCompanyCurrency,
};
