import { HTMLAttributes } from "react";
import { cx } from "@emotion/css";

import Button, { ButtonProps } from "../../buttons/Button/Button";
import Dialog, { DialogProps } from "../Dialog/Dialog";
import styles from "./ConfirmDialog.module.scss";
import { useTranslate } from "react-translate.ts";

export interface ConfirmDialogProps extends DialogProps {
    title?: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    controlsProps?: HTMLAttributes<HTMLDivElement>;
    titleProps?: HTMLAttributes<HTMLDivElement>;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
    const translate = useTranslate("common.actions");
    const {
        title,
        children,
        onConfirm,
        onCancel,
        style,
        confirmButtonProps = { children: translate("confirm") },
        cancelButtonProps = { children: translate("cancel") },
        controlsProps = {},
        titleProps = {},
        className,
        ...rest
    } = props;

    return (
        <Dialog data-test="confirm-dialog" {...rest}>
            <div className={cx(styles.container, className)} style={style}>
                {title ? (
                    <div
                        {...titleProps}
                        className={cx(styles.title, titleProps.className)}
                    >
                        {title}
                    </div>
                ) : null}
                <div className={styles.content}>{children}</div>
                <div
                    {...controlsProps}
                    className={cx(styles.controls, controlsProps.className)}
                >
                    <Button
                        variant="secondary"
                        onClick={onCancel}
                        data-test="confirm-dialog-cancel-button"
                        {...cancelButtonProps}
                    >
                        {cancelButtonProps.children}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        data-test="confirm-dialog-confirm-button"
                        {...confirmButtonProps}
                    >
                        {confirmButtonProps.children}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
