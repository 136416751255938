const SET_POINT = "CAREER_PATHS__SET_POINT";
const ADD_POINT = "CAREER_PATHS__ADD_POINT";
const REMOVE_POINT = "CAREER_PATHS__REMOVE_POINT";
const CLEAR_POINTS = "CAREER_PATHS__CLEAR_POINTS";

const SET_SELECTED_NODE = "CAREER_PATHS__SET_SELECTED_NODE";

const SET_INPUT_VALUE = "CAREER_PATHS__SET_INPUT_VALUE";
const SET_ACTIVE_SEARCH = "CAREER_PATHS__SET_ACTIVE_SEARCH";
const SET_SEARCH_SELECT = "CAREER_PATHS__SET_SEARCH_SELECT";

const SET_ACTIVE_ROUTE_SEARCH = "CAREER_PATHS__SET_ACTIVE_ROUTE_SEARCH";
const SET_SELECTED_PATH = "CAREER_PATHS__SET_SELECTED_PATH";
const SET_ROUTING_DATA = "CAREER_PATHS__SET_ROUTING_DATA";

const SET_COUNTRY = "CAREER_PATHS__SET_COUNTRY";
const SET_INDUSTRY = "CAREER_PATHS__SET_INDUSTRY";

const GET_MAP = "CAREER_PATHS__GET_MAP";
const GET_MAP_SUCCESS = "CAREER_PATHS__GET_MAP_SUCCESS";
const GET_MAP_FAIL = "CAREER_PATHS__GET_MAP_FAIL";

const GET_MAP_PATHS = "CAREER_PATHS__GET_MAP_PATHS";
const GET_MAP_PATHS_SUCCESS = "CAREER_PATHS__GET_MAP_PATHS_SUCCESS";
const GET_MAP_PATHS_FAIL = "CAREER_PATHS__GET_MAP_PATHS_FAIL";

const GET_MAP_TOOLTIP = "CAREER_PATHS__GET_MAP_TOOLTIP";
const GET_MAP_TOOLTIP_SUCCESS = "CAREER_PATHS__GET_MAP_TOOLTIP_SUCCESS";
const GET_MAP_TOOLTIP_FAIL = "CAREER_PATHS__GET_MAP_TOOLTIP_FAIL";

const GET_MAP_DETAIL = "CAREER_PATHS__GET_MAP_DETAIL";
const GET_MAP_DETAIL_SUCCESS = "CAREER_PATHS__GET_MAP_DETAIL_SUCCESS";
const GET_MAP_DETAIL_FAIL = "CAREER_PATHS__GET_MAP_DETAIL_FAIL";

const GET_COLLEGE_DATA = "CAREER_PATHS__GET_COLLEGE_DATA";
const GET_COLLEGE_DATA_SUCCESS = "CAREER_PATHS__GET_COLLEGE_DATA_SUCCESS";
const GET_COLLEGE_DATA_FAIL = "CAREER_PATHS__GET_COLLEGE_DATA_FAIL";

export const CareerPathsConstants = {
    GET_MAP,
    GET_MAP_SUCCESS,
    GET_MAP_FAIL,
    GET_MAP_PATHS,
    GET_MAP_PATHS_SUCCESS,
    GET_MAP_PATHS_FAIL,
    GET_MAP_TOOLTIP,
    GET_MAP_TOOLTIP_SUCCESS,
    GET_MAP_TOOLTIP_FAIL,
    GET_MAP_DETAIL,
    GET_MAP_DETAIL_SUCCESS,
    GET_MAP_DETAIL_FAIL,
    GET_COLLEGE_DATA,
    GET_COLLEGE_DATA_SUCCESS,
    GET_COLLEGE_DATA_FAIL,
    SET_POINT,
    ADD_POINT,
    REMOVE_POINT,
    CLEAR_POINTS,
    SET_SELECTED_NODE,
    SET_INPUT_VALUE,
    SET_ACTIVE_SEARCH,
    SET_ACTIVE_ROUTE_SEARCH,
    SET_SELECTED_PATH,
    SET_SEARCH_SELECT,
    SET_COUNTRY,
    SET_INDUSTRY,
    SET_ROUTING_DATA,
    GET_CATEGORIES: "CAREER_PATHS__GET_CATEGORIES",
    GET_CATEGORIES_SUCCESS: "CAREER_PATHS__GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAIL: "CAREER_PATHS__GET_CATEGORIES_FAIL",
    SET_SELECTED_CATEGORY: "CAREER_PATHS__SELECTED_CATEGORY",
};
