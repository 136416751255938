import { useDispatch, useSelector } from "react-redux";
import { PropsWithChildren, useEffect } from "react";
import { useTranslate } from "react-translate.ts";
import { cx } from "@emotion/css";

import { Button, Loading } from "../../../../components";
import HeroOnboardingActions from "../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import HeroOnboardingSelectors from "../../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { AuthSelectors } from "../../../../_selectors";
import HeroProfileActions from "../../../../_actions/HeroProfileActions";
import { VideoPlayer } from "../../../../components/misc/VideoPlayer";
import HeroProfileSelectors from "../../../../../candidate/_selectors/HeroProfileSelectors";
import { MainState } from "../../../../../root.reducer";
import styles from "./HeroReview.module.scss";

export function HeroReview() {
    const dispatch = useDispatch();

    const {
        heroProfileId,
        personal,
        advice,
        positions,
        skills,
        video,
        isLoading,
        isPublished,
    } = useSelector(mapState);

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroProfileActions.getSkills(heroProfileId));
        }
    }, [heroProfileId, dispatch]);

    const translate = useTranslate("common.heroOnboarding.steps.review");
    const translateSteps = useTranslate("common.heroOnboarding.steps");

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={styles.review}>
            <div className={styles.title}>
                <h1>{translate("title")}</h1>
                <Button variant="black" onClick={publish}>
                    {isPublished
                        ? translate("unpublish")
                        : translate("publish")}
                </Button>
            </div>
            <div className={styles.cards}>
                <Card
                    className={styles.personal}
                    title={translateSteps("personal.title")}
                    step={0}
                >
                    <Card.Value
                        title={translateSteps("personal.form.firstName.label")}
                    >
                        {personal.firstName}
                    </Card.Value>
                    <Card.Value
                        title={translateSteps("personal.form.lastName.label")}
                    >
                        {personal.lastName}
                    </Card.Value>
                    {/* <Card.Value title={signupTrans.email.label}></Card.Value> */}
                    <Card.Value
                        title={translateSteps("personal.form.intro.label")}
                    >
                        {personal.introduction}
                    </Card.Value>
                </Card>
                <Card title={translateSteps("positions.title")} step={1}>
                    <Card.Value
                        title={translateSteps("positions.form.positions.label")}
                    >
                        {positions.positions}
                    </Card.Value>
                    <Card.Value
                        title={translateSteps(
                            "positions.form.positions2.label"
                        )}
                    >
                        {positions.positions2}
                    </Card.Value>
                    <Card.Value
                        title={translateSteps(
                            "positions.form.positions3.label"
                        )}
                    >
                        {positions.positions3}
                    </Card.Value>
                </Card>
                <Card
                    className={styles.skills}
                    title={translateSteps("skills.title")}
                >
                    {skills.map((item: any, i: number) => (
                        <Card.Value key={i} title={item.skill.name}>
                            {Math.round(item.level)}%
                        </Card.Value>
                    ))}
                </Card>
                <Card
                    className={styles.advice}
                    title={translateSteps("advice.title")}
                    step={3}
                >
                    {advice.map((item: any, i: number) => (
                        <Card.Value key={i} title={item.question.text}>
                            {item.answer}
                        </Card.Value>
                    ))}
                    {video?.videoUrl && video.videoProvider && (
                        <VideoPlayer
                            videoProvider={video.videoProvider}
                            videoUrl={video.videoUrl}
                        />
                    )}
                </Card>
            </div>
        </div>
    );

    function publish() {
        if (isPublished) {
            return dispatch(HeroProfileActions.unpublish(heroProfileId));
        }

        dispatch(HeroProfileActions.publish(heroProfileId));
    }
}

type CardProps = {
    title: string;
    step?: number;
    className?: string;
};

function Card(props: PropsWithChildren<CardProps>) {
    const { title, step, children, className } = props;
    const translate = useTranslate("common.heroOnboarding.steps.review");

    const dispatch = useDispatch();

    return (
        <div className={styles.card}>
            <div className={cx(styles.card__menu, "font-heavy")}>
                <div className={styles.card__title}>{title}</div>
                {step !== undefined && (
                    <span
                        className={styles.link}
                        onClick={() =>
                            dispatch(HeroOnboardingActions.changeStep(step))
                        }
                    >
                        <span className={styles["edit-icon"]}>
                            <img src="/img/heroes/onboard/edit.svg" alt="" />
                        </span>
                        <span>{translate("edit")}</span>
                    </span>
                )}
            </div>
            <div className={cx(styles.card__content, className)}>
                {children}
            </div>
        </div>
    );
}

Card.Value = CardValue;

function CardValue(props: PropsWithChildren<{ title: string }>) {
    const { title, children } = props;

    return (
        <div className={styles.card__value}>
            <div className={styles.card__value__title}>{title}</div>
            <div className={styles.card__value__value}>{children}</div>
        </div>
    );
}

function mapState(state: MainState) {
    return {
        heroProfileId: AuthSelectors.getHeroProfileId(state),
        personal: HeroOnboardingSelectors.Personal.getData(state),
        advice: HeroOnboardingSelectors.Advice.getData(state),
        positions: HeroOnboardingSelectors.Positions.getData(state),
        skills: HeroOnboardingSelectors.Skills.getSkills(state),
        video: HeroOnboardingSelectors.Advice.getVideo(state),
        isLoading: HeroOnboardingSelectors.isLoading(state),
        isPublished: HeroProfileSelectors.isPublished(state),
    };
}
