export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const VERIFY = 'VERIFY';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const SIGNUP_WITHOUT_TOKEN = 'SIGNUP_WITHOUT_TOKEN';
export const SIGNUP_WITHOUT_TOKEN_SUCCESS = 'SIGNUP_WITHOUT_TOKEN_SUCCESS';
export const SIGNUP_WITHOUT_TOKEN_FAIL = 'SIGNUP_WITHOUT_TOKEN_FAIL';

export const LOGOUT = 'LOGOUT';
