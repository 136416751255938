import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translate.ts';

import { Flex, Label, TrackingMatch } from '../../../../common/components';
import { UnlockDialog } from './UnlockDialog';
import { CandidateTrackingActions } from '../../../_actions';
import { TrackingCard } from '../../../../common/components/tracking/TrackingCard';

export function CandidateCard({
    candidate,
    canDrag,
    hover,
    accept,
    isHighlighted,
    ...rest
}) {
    const {
        basicInfo,
        jobPost,
        unlocked,
        status,
        responseTimeDeadline,
        events,
        createdDate,
        scores,
        viewStatus,
    } = candidate;

    return (
        <TrackingCard
            image={basicInfo.imageUrl}
            heading={
                <Name
                    id={basicInfo.id}
                    name={basicInfo.firstName + ' ' + basicInfo.lastName}
                    isLocked={!unlocked}
                />
            }
            subHeadings={[jobPost?.title, jobPost?.locationCity?.text]}
            params={{
                responseTimeDeadline,
                score: scores.jobMatchScore,
                status,
                events,
                createdDate,
            }}
            canDrag={canDrag}
            acceptedContent={<AcceptedContent candidate={candidate} />}
            isNew={viewStatus !== 'VIEWED'}
            hover={hover}
            accept={accept}
            isHighlighted={isHighlighted}
            {...rest}
        />
    );
}

function Name({ id, name, isLocked }) {
    if (isLocked) {
        return (
            <Translate params={{ id }} textOnly>
                admin.candidate.locked.name
            </Translate>
        );
    }

    return name;
}

function AcceptedContent({ candidate }) {
    const { id, scores, unlocked, basicInfo } = candidate;
    const unlock = mapActions(useDispatch());
    const [showUnlock, setShowUnlock] = useState(false);

    const unlockDialog = (
        <UnlockDialog
            isVisible={showUnlock}
            isConfirmed={unlocked}
            onConfirm={() => unlock(id)}
        >
            <CandidateCard candidate={candidate} canDrag={false} />
        </UnlockDialog>
    );

    if (!unlocked) {
        return (
            <Fragment>
                {unlockDialog}
                <TrackingMatch match={scores.jobMatchScore}>
                    <Label
                        size="small"
                        className="uppercase font-heavy clickable"
                        onClick={() => setShowUnlock(true)}
                    >
                        <Translate textOnly>
                            admin.candidate.locked.status
                        </Translate>
                    </Label>
                </TrackingMatch>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {unlockDialog}
            <TrackingMatch match={scores.jobMatchScore}>
                <Flex direction="column" className="tracking-card--contact">
                    <div className="font-10">{basicInfo.phoneNumber}</div>
                    <div className="font-10">{basicInfo.email}</div>
                </Flex>
            </TrackingMatch>
        </Fragment>
    );
}

function mapActions(dispatch) {
    return (candidateId) =>
        dispatch(CandidateTrackingActions.unlockCandidate(candidateId));
}
