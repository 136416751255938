import jobRoles from './loading/jobRoles.reducer';
import jobRole from './loading/jobRole.reducer';
import test from './loading/test.reducer';
import profile from './loading/profile.reducer';

import { combineReducers } from 'redux';

export default combineReducers({
    jobRoles,
    jobRole,
    test,
    profile
});
