import React, { useRef } from 'react';
import { createClass } from 'classname-helper';

import { useMeasurements } from '../../hooks/useMeasurements';

/**
 *
 * @param {
 * {
 *   withArrow: boolean,
 *   arrowDirection: "top" | "bottom"
 * }
 * } props
 */
export function BaseTooltip(props) {
    const {
        className,
        children,
        title,
        value,
        panels,
        isAbsolute = true,
        withArrow = true,
        arrowDirection,
        parentRef,
        shouldPreventClipping = true,
        style,
        light = false,
        forwardRef,
        ...rest
    } = props;
    const ref = useRef(null);

    const parentMeasurement = useMeasurements(parentRef);
    const measurement = useMeasurements(forwardRef || ref);

    const position = {
        x: parentMeasurement.width / 2 - measurement.width / 2,
        y: parentMeasurement.height / 2 + 9,
    };

    const shouldShowAtBottom = parentMeasurement.top < measurement.height;
    const _arrowDirection = !!arrowDirection
        ? arrowDirection
        : shouldPreventClipping
        ? shouldShowAtBottom
            ? 'top'
            : 'bottom'
        : 'bottom';

    return (
        <div
            className={createClass({
                tooltip: true,
                absolute: isAbsolute,
                light: light,
                [className]: !!className,
            })}
            style={{
                left: position.x,
                [_arrowDirection]: position.y,
                ...style,
            }}
            ref={forwardRef || ref}
            {...rest}
        >
            {title ? <div className="tooltip__title">{title}</div> : null}
            {value ? <div className="tooltip__value">{value}</div> : null}
            {panels &&
                panels.map((panel, index) => {
                    return (
                        <div className="tooltip__panel" key={index}>
                            <div className="tooltip__panel__title">
                                {panel.label}
                            </div>
                            <div className="tooltip__panel__value">
                                {panel.value}
                            </div>
                        </div>
                    );
                })}
            {children}
            {withArrow ? (
                <div
                    className={createClass({
                        tooltip__arrow: true,
                        ['arrow-' + _arrowDirection]: true,
                    })}
                />
            ) : null}
        </div>
    );
}
