import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./SliderMark.module.scss";

type SliderMarkProps = {
    size: number;
    position: number;
    label: string;
    disabled: boolean;
};

export default function SliderMark(props: SliderMarkProps) {
    const { size = 14, position = 0, label, disabled = false } = props;

    return (
        <>
            <div
                className={cx(styles.mark, disabled && styles.disabled)}
                style={
                    {
                        left: `${position}%`,
                        "--size": `${size}px`,
                    } as CSSProperties
                }
            />
            {label && (
                <div
                    className={cx(styles.label, disabled && "disabled")}
                    style={{ left: `${position}%` }}
                >
                    {label}
                </div>
            )}
        </>
    );
}
