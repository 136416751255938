import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import { PROFILE_PATH_INFO } from "../../../../_constants/route.constants";
import { FormButtons } from "../FormButtons";
import { ProfileEdit } from "../ProfileEdit";
import { ProfileInformationActions } from "../../../../_actions/ProfileInformationActions";
import { AuthSelectors } from "../../../../../common/_selectors";
import ProfileInformationSelectors from "../../../../_selectors/ProfileInformationSelectors";
import EducationForm from "../../../../../common/components/form/forms/EducationForm";

export function EducationEdit() {
    const history = useHistory();
    const params = useParams();
    const id = parseInt(params.id, 10);
    const dispatch = useDispatch();
    const { education, profileId } = useSelector((state) =>
        mapState(state, id)
    );
    const isNew = !education;

    return render();
    function render() {
        return (
            <ProfileEdit
                onClose={close}
                formButtons={null}
                heading={
                    !education
                        ? "candidate.profile.information.education.add"
                        : "candidate.profile.information.education.edit"
                }
            >
                <EducationForm
                    style={{ marginTop: -20 }}
                    className="profile-edit"
                    values={getInitialValues()}
                    onSubmit={submit}
                >
                    <Form />
                </EducationForm>
            </ProfileEdit>
        );
    }

    function Form() {
        const { submitForm } = useFormikContext();
        return (
            <FormButtons
                onCancel={close}
                onConfirm={submitForm}
                confirmText="common.actions.save"
            />
        );
    }

    function getInitialValues() {
        if (isNew) {
            return null;
        }

        const {
            university,
            universityDegreeField,
            universityDegreeTitle,
            universityDegreeType,
            locations,
            startYear,
            endYear,
        } = education;

        return {
            university,
            universityDegreeField,
            universityDegreeTitle,
            universityDegreeType,
            location: locations[0],
            startYear,
            endYear,
        };
    }

    function submit(values) {
        const {
            university,
            universityDegreeField,
            universityDegreeTitle,
            universityDegreeType,
            location,
            startYear,
            endYear,
        } = values;

        const data = {
            universityId: university.id,
            universityDegreeFieldId: universityDegreeField.id,
            universityDegreeTitleId: universityDegreeTitle.id,
            universityDegreeTypeId: universityDegreeType.id,
            locationId: location.placeId,
            startYear: startYear,
            endYear: endYear,
            grade: "",
            description: "",
        };

        if (isNew) {
            dispatch(ProfileInformationActions.addEducation(data, profileId));
        } else {
            dispatch(
                ProfileInformationActions.updateEducation(
                    {
                        ...data,
                        id: education.id,
                    },
                    profileId
                )
            );
        }

        close();
    }

    function close() {
        history.push(PROFILE_PATH_INFO);
    }
}

function mapState(state, educationId) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        education:
            ProfileInformationSelectors.getEducations(state).get(educationId),
    };
}
