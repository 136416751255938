import React, { useRef } from 'react';

import { useMeasurements } from '../../hooks';
import { SVGHelper } from '../../_helpers';
import { PRIMARY_COLOR } from '../../_constants/theme.constants';

export function RadialProgressChart(props) {
    const {
        fill = PRIMARY_COLOR,
        background = '#ffeaef',
        progress = 0,
        thickness,
        startAngle = 0,
        extraContent = () => {},
    } = props;
    const ref = useRef(null);
    const measurements = useMeasurements(ref);
    const { height, width, aspect } = measurements;
    const center = { x: width / 2, y: height / 2 };
    const radius = Math.abs((aspect > 1 ? height : width) / 2 - thickness);

    return (
        <div ref={ref} className="radial-progress-chart">
            <svg height={height} width={width}>
                <g>
                    <circle
                        cx={center.x}
                        cy={center.y}
                        r={radius}
                        stroke={background}
                        strokeWidth={thickness}
                        fill="none"
                    />
                    <path
                        d={SVGHelper.describeArc(
                            center.x,
                            center.y,
                            radius,
                            startAngle,
                            360 * progress + startAngle
                        )}
                        fill="none"
                        stroke={fill}
                        strokeWidth={thickness}
                    />
                </g>
                {extraContent &&
                    extraContent({ ...measurements, radius }, center)}
            </svg>
        </div>
    );
}
