import { PRIMARY_COLOR } from "../../../common/_constants/theme.constants";

export function resolveSectionColor(section) {
    switch (section) {
        case 'perception':
        case 'teamplayer':
            return '#64638e';
        case 'setting':
        case 'salesman':
            return '#85d8f0';
        case 'activity':
        case 'manager':
            return '#f6db83';
        case 'decisions':
        case 'expert':
            return '#f2afa5';
        case "all":
            return PRIMARY_COLOR;
        default:
            return null;
    }
}
