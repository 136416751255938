import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const languages = (state) => state.common.languages;

const getLocale = createSelector(languages, (languages) => languages.locale);

const getLanguages = createSelector(languages, (languages) => languages.list);

const getLanguageLevels = createSelector(
    languages,
    (languages) => languages.levels
);

const getAllowedLocales = createSelector(
    languages,
    (languages) => languages.allowedLocales
);

function useGetLocale() {
    return useSelector(getLocale);
}
export const LanguageSelectors = {
    getLocale,
    getLanguages,
    getLanguageLevels,
    getAllowedLocales,
    useGetLocale
};
