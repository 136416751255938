export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/sign-up";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const FORGOT_PASSWORD_TOKEN_PATH = "/forgot-password/:token";
export const RESET_PASSWORD_PATH = "/reset-password";

export const VERIFY_PATH = "/verify-email";
export const VERIFY_TOKEN_PATH = "/verify-email/:token?";
export const ONBOARDING_PATH = "/onboarding";
export const ONBOARDING_PERSONAL_PATH = `${ONBOARDING_PATH}/personal`;
export const ONBOARDING_TEST_PATH = `${ONBOARDING_PATH}/test`;

export const TEST_INFO_PATH = "/test-info";
export const DASHBOARD_PATH = "/dashboard";
export const CANDIDATES_PATH = "/candidates";
export const CAREER_PLANNER_PATH = "/career-planner";

export const COMPANY_PATH = "/companies";
export const COMPANY_LIST_PATH = `${COMPANY_PATH}/:page(\\d+)?`;
export const COMPANY_DETAIL_PATH = `${COMPANY_PATH}/detail/:id(\\d+)`;

export const JOBS_PATH = "/jobs";
export const JOB_LIST_PATH = `${JOBS_PATH}/:page(\\d+)?`
export const JOB_DETAIL_PATH = `${JOB_LIST_PATH}/detail/:jobPostId(\\d+)`;
export const JOB_DETAIL_COMPANY_PATH = `${JOB_DETAIL_PATH}/company`;

export const JOB_ACTIVITIES_PATH = "/jobs-activities";
export const SOFT_SKILL_TEST_PATH = "/soft-skill-tests";
export const WELCOME_PATH = "/welcome";

export const PROFILE_PATH = "/profile";
export const PROFILE_PATH_INFO = "/profile/information";
export const PROFILE_SOFT_SKILLS_PATH = "/profile/soft-skills";
export const PROFILE_HARD_SKILLS_PATH = "/profile/hard-skills";
export const PROFILE_HARD_SKILLS_EDIT_PATH = "/profile/hard-skills/edit";
export const PROFILE_PREFERENCES_PATH = "/profile/preferences";
export const PROFILE_CONTACT_PATH = "/profile/contact";

export const PROFILE_ABOUT_ME_PATH = "/profile/information/about-me";
export const PROFILE_EXPERIENCE_PATH = "/profile/information/experience";
export const PROFILE_EXPERIENCE_EDIT_PATH =
    "/profile/information/experience/:id?";

export const PROFILE_EDUCATION_PATH = "/profile/information/education";
export const PROFILE_EDUCATION_EDIT_PATH =
    "/profile/information/education/:id?";
export const PROFILE_LANGUAGES_PATH = "/profile/information/languages";
export const PROFILE_INTERESTS_PATH = "/profile/information/interests";

export const PROFILE_AVATAR_EDIT_PATH = "/profile/upload-photo";

export const INTERPRETATION_PATH = "/interpretation";
export const INTERPRETATION_MOP_PATH =
    SOFT_SKILL_TEST_PATH + "/mop" + INTERPRETATION_PATH;
export const INTERPRETATION_HOFIK_PATH =
    SOFT_SKILL_TEST_PATH + "/hofik" + INTERPRETATION_PATH;
export const INTERPRETATION_KAKO_PATH =
    SOFT_SKILL_TEST_PATH + "/kako" + INTERPRETATION_PATH;

// ADMIN ROUTES
export const ADMIN_HIRE_PATH = "/hire";
export const ADMIN_PROFILE_PATH = "/profile";
export const ADMIN_PROFILE_PREVIEW_PATH = `${ADMIN_PROFILE_PATH}/preview`;
export const ADMIN_CANDIDATES_PATH = "/candidates";
export const ADMIN_JOBS_PATH = "/jobs";
export const ADMIN_JOB_EDIT_PATH = `${ADMIN_JOBS_PATH}/edit/:id(\\d+)`;
export const ADMIN_JOB_EDIT_DETAIL_PATH = `${ADMIN_JOB_EDIT_PATH}/detail`;
export const ADMIN_JOB_EDIT_KANBAN_PATH = `${ADMIN_JOB_EDIT_PATH}/candidates`;
export const ADMIN_JOB_PREVIEW_PATH = `${ADMIN_JOB_EDIT_PATH}/preview`;

export const ADMIN_TEAM_PATH = '/team';

export const COMPANY_DETAIL_LOCATION_PATH = `/location`;
export const COMPANY_DETAIL_JOBS_PATH = `/jobs`;
export const COMPANY_DETAIL_GALLERY_PATH = `/gallery`;
export const COMPANY_DETAIL_BENEFITS_PATH = `/benefits`;
export const COMPANY_DETAIL_TEAM_PATH = `/team`;
export const COMPANY_DETAIL_PATHS_PATH = `/paths`;

export const CONTACT_PATH = "/contact";

export const PUBLIC_INTERPRETATION_PATH = "/soft-skills/:test/:uuid?";

export const HERO_SIGNUP_PATH = "/hero-signup";

export const HERO_ONBOARDING_PATH = "/hero-onboarding";

export const NOT_ALLOWED_PATH = "/403";

export const TEST_COMPLETE_REDIRECT_PATH =
    "/soft-skill-tests/complete/:requestId";

export const HEADER_FORBIDDEN_ROUTES = [
    "/",
    // LOGIN_PATH,
    // SIGNUP_PATH,
    // FORGOT_PASSWORD_PATH,
    // FORGOT_PASSWORD_TOKEN_PATH,
    ONBOARDING_PATH,
    HERO_ONBOARDING_PATH,
    HERO_SIGNUP_PATH,
];

export const SIDEBAR_FORBIDDEN_ROUTES = [
    "/",
    // LOGIN_PATH,
    // SIGNUP_PATH,
    // FORGOT_PASSWORD_PATH,
    // FORGOT_PASSWORD_TOKEN_PATH,
    ONBOARDING_PATH,
    VERIFY_PATH,
    VERIFY_TOKEN_PATH,
    HERO_ONBOARDING_PATH,
    HERO_SIGNUP_PATH,
];

export const USER_HEADER_FORBIDDEN_ROUTES = [
    "/",
    ONBOARDING_PATH,
    HERO_ONBOARDING_PATH,
];
