import IJobCard from "../../../types/IJobCard";
import JobCard from "../../JobCard/JobCard";
import { Panel } from "../../Panel/Panel";
import styles from "./JobGrid.module.scss";

export default function JobGrid(props: {
    jobs: IJobCard[];
    isLoading: boolean;
}) {
    const { jobs, isLoading } = props;

    return (
        <Panel>
            <Panel.Heading>Job Offers</Panel.Heading>
            <div className={styles.grid}>
                {!isLoading
                    ? jobs?.map((job, i) => (
                          <JobCard
                              key={i}
                              name={job.title}
                              id={job.jobPostId}
                              currency={job.currency}
                              salaryMax={job.salaryMax}
                              salaryMin={job.salaryMin}
                              match={job.jobMatchScore}
                          />
                      ))
                    : Array.from(new Array(3), (_, i) => (
                          <JobCard key={i} isLoading={isLoading} />
                      ))}
            </div>
        </Panel>
    );
}
