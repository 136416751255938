import { takeLatest } from 'redux-saga/effects';

import {
    GET_COMPANY_JOBS,
    GET_COMPANY_JOBS_SUCCESS,
    GET_COMPANY_JOBS_FAIL, GET_COMPANY_JOB_CARDS_SUCCESS, GET_COMPANY_JOB_CARDS_FAIL, GET_COMPANY_JOB_CARDS
} from '../_constants/actions/jobs.constants';
import { JobsService } from '../_services';
import { handleAsyncAction } from '../../common/_helpers';

function* getJobs(action) {
    yield handleAsyncAction(
        JobsService.getJobs,
        action.payload,
        payload => ({ type: GET_COMPANY_JOBS_SUCCESS, payload }),
        error => ({ type: GET_COMPANY_JOBS_FAIL, error })
    );
}

function* getCompanyJobs(action) {
    yield handleAsyncAction(
        JobsService.getCompanyJobs,
        action.payload,
        payload => ({ type: GET_COMPANY_JOB_CARDS_SUCCESS, payload }),
        error => ({ type: GET_COMPANY_JOB_CARDS_FAIL, error })
    );
}

export default [
    takeLatest(GET_COMPANY_JOBS, getJobs),
    takeLatest(GET_COMPANY_JOB_CARDS, getCompanyJobs)
];
