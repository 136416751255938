import React from 'react';
import { useTranslate } from 'react-translate.ts';

import {
    TRACKING_APPLIED,
    TRACKING_SHORTLISTED,
    TRACKING_INVITED,
    TRACKING_ACCEPTED,
} from '../../../_constants/actions/tracking.constants';
import { LoadingOverlay } from '../../../../common/components';
import { CandidateColumn } from './CandidateColumn';

export function InactiveCards({
    isLoading,
    jobPostId,
    cardSelector,
    onCardClick,
}) {
    const translate = useTranslate('admin.candidate.tracking');
    return (
        <>
            <LoadingOverlay isLoading={isLoading} />
            <div id="tracking-layout__grid">
                <CandidateColumn
                    label={translate('new')}
                    type={TRACKING_APPLIED}
                    canDrag={false}
                    jobPostId={jobPostId}
                    onCardClick={onCardClick}
                    cardSelector={cardSelector}
                />
                <CandidateColumn
                    label={translate('shortlist')}
                    type={TRACKING_SHORTLISTED}
                    canDrag={false}
                    jobPostId={jobPostId}
                    onCardClick={onCardClick}
                    cardSelector={cardSelector}
                />
                <CandidateColumn
                    label={translate('sent-offers')}
                    type={TRACKING_INVITED}
                    canDrag={false}
                    onCardClick={onCardClick}
                    cardSelector={cardSelector}
                />
                <CandidateColumn
                    label={translate('accepted')}
                    type={TRACKING_ACCEPTED}
                    canDrag={false}
                    onCardClick={onCardClick}
                    cardSelector={cardSelector}
                />
            </div>
        </>
    );
}
