// @ts-ignore
import CookieManagerComponent from "cookie-manager-component/dist/cookie-manager-component";
import "cookie-manager-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";

import SvelteWrapper from "../../../candidate/pages/CareerPaths/components/CareerPathsMap/components/SvelteWrapper";
import styles from "./CookieManager.module.scss";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";
import CookieConfigSelectors from "../../_selectors/CookieConfigSelectors";
import CookieConfigActions from "../../_actions/CookieConfigActions";

export default function CookieManager() {
    const locale = LanguageSelectors.useGetLocale();
    const hasConsent = useSelector(CookieConfigSelectors.hasConsent);
    const dispatch = useDispatch();

    if (hasConsent) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <SvelteWrapper
                svelte={CookieManagerComponent}
                data={{
                    locale,
                    onSubmit: submit,
                }}
            />
        </div>
    );

    function submit(values: number) {
        dispatch(CookieConfigActions.setConfig(values));
        dispatch(CookieConfigActions.setConsent());
    }
}
