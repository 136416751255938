import { RequestService } from '.';

export const PublicService = {
    getPublicInterpretation,
};

function getPublicInterpretation(payload) {
    const { uuid, test } = payload;

    if (!!uuid) {
        return RequestService.get(`api/public/${uuid}/soft-skills/${test}`);
    } else {
        return RequestService.get('api/public/soft-skills/' + test);
    }
}
