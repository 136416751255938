import { createSelector } from 'reselect';

const cards = (state) => state.candidate.companies.list.cards;

const loading = (state) => state.candidate.companies.list.loading;

const filter = (state) => state.candidate.companies.list.filter;

const pagination = (state) => state.candidate.companies.list.pagination;

const getCards = createSelector(cards, (cards) => cards);

const getPagination = createSelector(pagination, (pagination) => pagination);

const getCardsCount = createSelector(
    getPagination,
    (pagination) => pagination.elements
);

const isLoading = createSelector(loading, (loading) => loading);

const getFilter = createSelector(filter, (filter) => filter);

export const CompanyListSelectors = {
    getCards,
    getCardsCount,
    isLoading,
    getFilter,
    getPagination,
};
