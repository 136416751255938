import {
    GET_COMPANY_JOBS_SUCCESS,
    GET_COMPANY_JOBS,
    GET_COMPANY_JOBS_FAIL,
    GET_COMPANY_JOB_CARDS_SUCCESS,
    GET_COMPANY_JOB_CARDS,
    GET_COMPANY_JOB_CARDS_FAIL
} from '../../_constants/actions/jobs.constants';
import { combineReducers } from 'redux';

export default combineReducers({ list, cards, loading });

function list(state = [], action) {
    switch (action.type) {
        case GET_COMPANY_JOBS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

function cards(state = [], action) {
    switch (action.type) {
        case GET_COMPANY_JOB_CARDS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

function loading(state = false, action) {
    switch (action.type) {
        case GET_COMPANY_JOBS:
        case GET_COMPANY_JOB_CARDS:
            return true;
        case GET_COMPANY_JOBS_SUCCESS:
        case GET_COMPANY_JOB_CARDS_SUCCESS:
            return false;
        case GET_COMPANY_JOBS_FAIL:
        case GET_COMPANY_JOB_CARDS_FAIL:
            return false;
        default:
            return state;
    }
}
