import IID from "../../../types/IID";
import { Panel } from "../../Panel/Panel";
import styles from "./Gallery.module.scss";
import Skeleton from "../../../components/Skeleton/Skeleton";

type GalleryProps = {
    images: (IID & { url: string; publicUrl: string })[];
    isLoading: boolean;
};

export default function Gallery(props: GalleryProps) {
    const { images, isLoading } = props;

    return (
        <Panel>
            <Panel.Heading>Gallery</Panel.Heading>
            <div className={styles.grid}>
                {!isLoading ? (
                    images?.map((image, i) => (
                        <div className={styles.image} key={i}>
                            <img
                                src={image.publicUrl}
                                alt=""
                                // onClick={() => setState({ isOpen: true, index })}
                            />
                        </div>
                    ))
                ) : (
                    <Skeleton width={250} height={200} count={6} />
                )}
            </div>
        </Panel>
    );
}
