import { takeLatest } from 'redux-saga/effects';

import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import InviteFriendsConstants from '../_constants/actions/InviteFriendsConstants';
import InviteFriendsService from '../_services/InviteFriendsService';

function* getInvites(action) {
    yield SagaHelpers.handleRequest({
        request: [InviteFriendsService.getInvites, action.payload],
        actions: [
            InviteFriendsConstants.GET_INVITES_SUCCESS,
            InviteFriendsConstants.GET_INVITES_FAIL,
        ],
    });
}

function* sendInvites(action) {
    yield SagaHelpers.handleRequest({
        request: [InviteFriendsService.sendInvites, action.payload],
        actions: [
            InviteFriendsConstants.SEND_INVITES_SUCCESS,
            InviteFriendsConstants.SEND_INVITES_FAIL,
        ],
    });
}

function* sendInvite(action) {
    yield SagaHelpers.handleRequest({
        request: [InviteFriendsService.sendInvite, action.payload],
        actions: [
            InviteFriendsConstants.SEND_INVITE_SUCCESS,
            InviteFriendsConstants.SEND_INVITE_FAIL,
        ],
    });
}

export default [
    takeLatest(InviteFriendsConstants.GET_INVITES, getInvites),
    takeLatest(InviteFriendsConstants.SEND_INVITES, sendInvites),
    takeLatest(InviteFriendsConstants.SEND_INVITE, sendInvite),
];
