import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";
import ProfileInformationSelectors from "../../../../candidate/_selectors/ProfileInformationSelectors";

import { OnboardingButtons, OnboardingForm } from "../../../components";
import { HardSkillsEditor } from "../../../components/skills/HardSkillsEditor";
import { OnboardingActions } from "../../../_actions";
import { AuthSelectors } from "../../../_selectors";
import OnboardingLayout from "./OnboardingLayout/OnboardingLayout";

export function OnboardingHardSkills() {
    const dispatch = useDispatch();

    const { skills, profileId } = useSelector(mapState);

    return (
        <OnboardingLayout
            color="green"
            imageProps={{ src: "/img/onboarding/hard-skills.svg" }}
        >
            <Content onSubmit={submit} />
        </OnboardingLayout>
    );

    function submit() {
        if (!validate() || !profileId) {
            return;
        }

        const items = skills.map((item) => ({
            level: item.level,
            timeYearCount: item.timeYearCount,
            skillId: item.skill.id,
            profileId,
        }));
        dispatch(OnboardingActions.saveHardSkills(items));
    }

    function validate() {
        for (const i in skills) {
            const item = skills[i];
            if (
                !item.level ||
                !item.timeYearCount ||
                !item.skill ||
                !item.skill.id
            ) {
                return false;
            }
        }

        return true;
    }
}

function Content(props) {
    const { onSubmit } = props;
    const translate = useTranslate("common.onboarding.hardSkills");
    const dispatch = useDispatch();

    return (
        <>
            <OnboardingForm>
                <h1 className="font-light">{translate("title")}</h1>
                <h6 className="font-book">{translate("subtitle")}</h6>
                <HardSkillsEditor />
            </OnboardingForm>
            <OnboardingButtons>
                <OnboardingButtons.Back
                    onClick={() => dispatch(OnboardingActions.addStep(-1))}
                />
                <OnboardingButtons.Next onClick={onSubmit} />
            </OnboardingButtons>
        </>
    );
}

function mapState(state) {
    return {
        uuid: AuthSelectors.getUUID(state),
        skills: ProfileInformationSelectors.getHardSkillsList(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
