import { Panel } from "../../../../common/components/Panel/Panel";
import PathsDiagram from "../../../../common/components/paths/PathsDiagram/PathsDiagram";
import styles from "./JobPaths.module.scss";
import { Translate } from "react-translate.ts";

type JobPathsProps = {
    map: any;
    categories: [];
};

const ZOOM = 0.5;

export default function JobPaths(props: JobPathsProps) {
    const { map, categories } = props;
    return (
        <Panel>
            <Panel.Heading>
                <Translate textOnly>
                    candidate.jobDetail.headings.paths
                </Translate>
            </Panel.Heading>
            <div className={styles.wrapper}>
                <PathsDiagram
                    map={map}
                    categories={categories}
                    centerText={map.title}
                    id={"career-map-diagram"}
                    zoom={ZOOM}
                    minZoom={ZOOM}
                    maxZoom={ZOOM}
                    onCategorySelect={() => {}}
                />
            </div>
        </Panel>
    );
}
