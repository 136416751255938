import React from 'react';
import { Translate } from 'react-translate.ts';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Flex } from '../layout';
import { DropdownMenu } from './DropdownMenu';
import { AuthActions } from '../../_actions';
import { AuthSelectors } from '../../_selectors/AuthSelectors';
import { LOGIN_PATH } from '../../_constants/route.constants';

export function MainMenu({ children, isOpen, onClose, withButton = true }) {
    const isSigned = useSelector(mapState);

    return (
        <DropdownMenu
            className="nav-menu"
            id="main-menu"
            isOpen={isOpen}
            onClose={onClose}
        >
            {children}
            {withButton ? isSigned ? <SignOut /> : <Login /> : null}
        </DropdownMenu>
    );
}

function mapState(state) {
    return AuthSelectors.isSigned(state);
}

function Login() {
    return (
        <div>
            <Link to={LOGIN_PATH}>
                <AuthLink label="common.header.nav.login" icon="logout" />
            </Link>
        </div>
    );
}

function SignOut() {
    const dispatch = useDispatch();
    const logout = () => dispatch(AuthActions.logout());

    return (
        <AuthLink
            onClick={logout}
            label="common.header.nav.sign-out"
            icon="logout"
        />
    );
}

function AuthLink({ onClick, label, icon }) {
    return (
        <Flex id="sign-out" alignItems="center" onClick={onClick} data-test="sign-out-button">
            <img id="sign-out--icon" src={`/img/icons/${icon}.svg`} alt="" />
            <Translate textOnly>{label}</Translate>
        </Flex>
    );
}
