import { combineReducers } from 'redux';

import {
    GET_JOB_CARDS,
    GET_JOB_CARDS_FAIL,
    GET_JOB_CARDS_SUCCESS
} from '../../../_constants';
import pagination from './pagination.reducer';
import cards from './cards.reducer';
import activeFilters from './activeFilters.reducer';
import filters from './filters.reducer';

export default combineReducers({
    cards,
    loading,
    pagination,
    activeFilters,
    filters
});

function loading(state = false, action) {
    switch (action.type) {
        case GET_JOB_CARDS:
            return true;
        case GET_JOB_CARDS_SUCCESS:
            return false;
        case GET_JOB_CARDS_FAIL:
            return false;
        default:
            return state;
    }
}
