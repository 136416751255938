import React from 'react';
import { createClass, addClass } from 'classname-helper';

/**
 *
 * @param {{
 *      imageUrl: string;
 *      withIcon: boolean;
 *      size: 'tiny' | 'small' | 'medium';
 *      isBlured: boolean;
 * }} props
 */
export function ProfilePicture({ imageUrl, withIcon, size, isBlured }) {
    return (
        <div className={createClassName({ size, isBlured })}>
            <img src={imageUrl || '/img/avatar.svg'} alt="" />
            {withIcon ? (
                <div
                    id="profile-picture-icon"
                    className={!!imageUrl ? 'has-image' : ''}
                />
            ) : null}
        </div>
    );
}

function createClassName({ size, isBlured }) {
    let result = createClass({
        'profile-picture': true,
        blured: isBlured,
    });

    return addClass(result, size ? `size-${size}` : '');
}
