import { useEffect, useRef } from "react";

export function useAddClassToMain(classList) {
    const mainElement = useRef(null);

    useEffect(() => {
        if (!mainElement.current) {
            mainElement.current = document.getElementsByTagName("main")[0];
        }
    });

    useEffect(() => {
        if (!mainElement.current) {
            return;
        }

        for (const i in classList) {
            const className = classList[i];
            mainElement.current.classList.add(className);
        }

        return () => {
            for (const i in classList) {
                const className = classList[i];
                mainElement.current.classList.remove(className);
            }
        };
    }, [mainElement.current, classList]);
}
