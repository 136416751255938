import { RequestService } from "../../common/_services/RequestService";

const DashboardService = {
    getData,
};

function getData(profileId) {
    return RequestService.get("api/summaries/" + profileId);
}

export default DashboardService;
