import { combineReducers } from "redux";
import HeroOnboardingConstants from "../../../../common/_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../../common/_constants/actions/HeroProfileConstants";

import { ReducerHelpers } from "../../../_helpers/ReducerHelpers";

const defaultState = {
    firstName: "",
    lastName: "",
    id: null,
    originalImageUrl: "",
    imageUrl: "",
    introduction: "",
    matchScore: {
        attributeScores: [],
        overallScore: 0,
        sectionScores: [],
    },
    publishDate: null,
    status: "DRAFT",
    videoUrl: null,
    videoProvider: null,
    experiences: [],
    answers: [],
    stepsCompleted: [],
};

function data(state = defaultState, action) {
    switch (action.type) {
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            return action.payload;
        case HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS:
        case HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS:
            return { ...state, ...action.payload };
        case HeroOnboardingConstants.UPLOAD_VIDEO_SUCCESS:
        case HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO_SUCCESS:
            return { ...state, videoUrl: action.payload };
        default:
            return state;
    }
}

function published(state = false, action) {
    switch (action.type) {
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            return !!action.payload.publishDate;
        case HeroProfileConstants.PUBLISH_SUCCESS:
            return true;
        case HeroProfileConstants.UNPUBLISH_SUCCESS:
            return false;
        default:
            return state;
    }
}

const loading = (state = null, action) =>
    ReducerHelpers.loading(state, action, [HeroProfileConstants.GET_PROFILE]);

export default combineReducers({ data, loading, published });
