import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const profile = (state: MainState) => state.admin.profile;
const profileEdit = (state: MainState) => state.admin.profileEdit;

const getVideo = createSelector(profileEdit, (profile) => profile.video);
const getImages = createSelector(profileEdit, (profile) => profile.images.list);
const getImageUploadState = createSelector(profileEdit, (profile) =>
    Object.values(profile.images.upload)
);

const getCurrencies = createSelector(profile, (profile) => profile.currencies);
const getMetrics = createSelector(
    profileEdit,
    (state) => state.benefits.metric
);
const getMetricTypes = createSelector(
    profileEdit,
    (state) => state.benefits.metricType
);
const getBenefits = createSelector(profileEdit, (profileEdit) => profileEdit.benefits);

const AdminProfileSelectors = {
    getVideo,
    getImages,
    getImageUploadState,
    getCurrencies,
    getMetrics,
    getMetricTypes,
    getBenefits,
    useGetCurrencies,
    useGetMetrics,
    useGetMetricTypes,
};

function useGetCurrencies() {
    return useSelector(getCurrencies);
}

function useGetMetrics() {
    return useSelector(getMetrics);
}

function useGetMetricTypes() {
    return useSelector(getMetricTypes);
}

export default AdminProfileSelectors;
