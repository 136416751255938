import { takeLatest } from 'redux-saga/effects';

import {
    GET_PUBLIC_INTERPRETATION,
    PublicActions,
} from '../_actions/PublicActions';
import { SagaHelpers } from '../_helpers';
import { PublicService } from '../_services/PublicService';

function* getInterpretation(action) {
    yield SagaHelpers.handleRequest({
        request: [PublicService.getPublicInterpretation, action.payload],
        actions: [
            PublicActions.getInterpretationSuccess,
            PublicActions.getInterpretationFail,
        ],
    });
}

export default [takeLatest(GET_PUBLIC_INTERPRETATION, getInterpretation)];
