import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";

import { AutocompleteService } from "../../../candidate/_services";
import RangeField from "../../../common/components/input/RangeField/RangeField";
import ISkill from "../../../common/types/ISkill";
import { IIdealSkill } from "../../../common/types/ISkillScore";
import AdminJobActions from "../../_actions/AdminJobActions";
import SkillsEdit from "../SkillsEdit/SkillsEdit";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import { SKILL_WEIGHT_TYPE_PREFERRED } from "../../../common/_constants/actions/skill.constants";
import { Formik, useFormikContext } from "formik";
import { ArraySchema, ObjectSchema } from "yup";
import FormMultiSelect from "../../../common/components/input/MultiSelect/FormMultiSelect";
import FormField from "../../../common/components/input/FormField";
import FormSelect from "../../../common/components/input/FormSelect";

export type HardSkillsEditProps = {
    skills: IIdealSkill[];
    jobPostId: number;
};

type Values = Partial<IIdealSkill>;

const LEVEL_OPTIONS = [1, 2, 3, 4];
const YEAR_OPTIONS = Array.from(new Array(21), (_, i) => i);

const TYPE_FIELD = "weightType";
const SKILLS_FIELD = "skills";
const YEAR_MIN_FIELD = "timeYearCountMin";
const YEAR_MAX_FIELD = "timeYearCountMax";
const LEVEL_MIN_FIELD = "levelMin";
const LEVEL_MAX_FIELD = "levelMax";

const DEFAULT_VALUES: Values = {
    [SKILLS_FIELD]: [],
    [YEAR_MIN_FIELD]: undefined,
    [YEAR_MAX_FIELD]: undefined,
    [LEVEL_MIN_FIELD]: undefined,
    [LEVEL_MAX_FIELD]: undefined,
    [TYPE_FIELD]: SKILL_WEIGHT_TYPE_PREFERRED,
};

export default function HardSkillsEdit(props: HardSkillsEditProps) {
    const { skills, jobPostId } = props;

    const [isVisible, setIsVisible] = useState(false);

    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={DEFAULT_VALUES}
            onSubmit={submit}
            enableReinitialize
            validationSchema={createValidation}
        >
            <Inner
                skills={skills}
                jobPostId={jobPostId}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
            />
        </Formik>
    );

    function createValidation() {
        return new ObjectSchema({
            skills: new ArraySchema().required().min(1),
        });
    }

    function submit(values: Values) {
        const {
            id,
            skills,
            timeYearCountMax,
            timeYearCountMin,
            levelMin,
            levelMax,
            weightType,
        } = values;

        dispatch(
            AdminJobActions.saveSkills({
                id,
                jobPostId,
                skills,
                timeYearCountMax,
                timeYearCountMin,
                levelMax,
                levelMin,
                weightType,
            })
        );
    }
}

type InnerProps = HardSkillsEditProps & {
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
};

function Inner(props: InnerProps) {
    const { skills, jobPostId, isVisible, setIsVisible } = props;
    const translate = useTranslate("admin.edit.skills.hard");
    const translateSkills = useTranslate("admin.edit.skills");
    const translateLevel = useTranslate("common.skills.level");

    const pending = AdminJobSelectors.useGetPending();
    const dispatch = useDispatch();
    const formik = useFormikContext();

    return (
        <SkillsEdit<IIdealSkill>
            skillName={translate("skillName")}
            data={skills || []}
            cols={["2fr", "2fr", "1fr"]}
            head={[
                <div>{translateSkills("table.level")}</div>,
                <div>{translateSkills("table.years")}</div>,
            ]}
            createItem={(item) => {
                const {
                    id,
                    skills,
                    levelMax,
                    levelMin,
                    timeYearCountMax,
                    timeYearCountMin,
                } = item;
                const isLoading = pending.skills.includes(id);
                if (isLoading) {
                    return <Skeleton height={18} count={3} width="80%" />;
                }

                return (
                    <>
                        <div data-test="hard-skill-name">
                            {createName(skills)}
                        </div>
                        <div data-test="hard-skill-level">
                            {levelMin && translateLevel(levelMin.toString())}
                            {levelMax !== null && levelMax !== undefined && (
                                <> - {translateLevel(levelMax.toString())}</>
                            )}
                        </div>
                        <div data-test="hard-skill-years">
                            {timeYearCountMin}{" "}
                            {timeYearCountMax !== null &&
                                timeYearCountMax !== undefined && (
                                    <> - {timeYearCountMax}</>
                                )}
                        </div>
                    </>
                );
            }}
            title={translate("title")}
            isVisible={isVisible}
            onEdit={openEditDialog}
            onCreate={openNewDialog}
            onSave={save}
            onDelete={deleteSkill}
            onCancel={cancel}
            onClose={cancel}
            createButtonText={translate("create")}
            getItemName={(item) => createName(item?.skills)}
            valueName={TYPE_FIELD}
        >
            <FormField
                name={SKILLS_FIELD}
                label={translate("form.skill.label")}
                required
            >
                <FormMultiSelect<ISkill>
                    name={SKILLS_FIELD}
                    placeholder={translate("form.skill.placeholder")}
                    loadOptions={AutocompleteService.hardSkills}
                    getOptionLabel={(option) => option.name}
                    data-test="hard-skills-edit-skill"
                />
            </FormField>
            <RangeField
                label={translate("form.years.label")}
                from={
                    <FormSelect
                        name={YEAR_MIN_FIELD}
                        placeholder={translate("form.years.placeholder.min")}
                        options={YEAR_OPTIONS}
                        getOptionLabel={getYearOptionLabel}
                        data-test="hard-skills-edit-years-min"
                    />
                }
                to={
                    <FormSelect
                        name={YEAR_MAX_FIELD}
                        placeholder={translate("form.years.placeholder.max")}
                        options={YEAR_OPTIONS}
                        getOptionLabel={getYearOptionLabel}
                        data-test="hard-skills-edit-years-max"
                    />
                }
            />
            <RangeField
                label={translate("form.level.label")}
                from={
                    <FormSelect
                        name={LEVEL_MIN_FIELD}
                        placeholder={translate("form.level.placeholder.min")}
                        options={LEVEL_OPTIONS}
                        getOptionLabel={getLevelOptionLabel}
                        data-test="hard-skills-edit-level-min"
                    />
                }
                to={
                    <FormSelect
                        name={LEVEL_MAX_FIELD}
                        placeholder={translate("form.level.placeholder.max")}
                        options={LEVEL_OPTIONS}
                        getOptionLabel={getLevelOptionLabel}
                        data-test="hard-skills-edit-level-max"
                    />
                }
            />
        </SkillsEdit>
    );

    function getLevelOptionLabel(level: number) {
        return translateLevel(level.toString());
    }

    function getYearOptionLabel(year: number) {
        return year.toString();
    }

    function cancel() {
        closeDialog();
    }

    function openNewDialog() {
        formik.setValues(DEFAULT_VALUES);
        setIsVisible(true);
    }

    function openEditDialog(skill: IIdealSkill) {
        formik.setValues(skill);
        setIsVisible(true);
    }

    function closeDialog() {
        formik.setValues(DEFAULT_VALUES);
        formik.setErrors({});
        formik.setTouched({});
        setIsVisible(false);
    }

    async function save() {
        await formik.submitForm();

        if (formik.isValid) {
            closeDialog();
        }
    }
    function deleteSkill(skill: IIdealSkill) {
        dispatch(
            AdminJobActions.deleteSkill({
                jobPostId,
                skillGroupId: skill.id,
            })
        );
    }
}

function createName(skills: ISkill[]) {
    return skills?.map((skill) => skill.name).join(" | ");
}
