import { Translate } from "react-translate.ts";
import { ListCard, Flex } from "../layout";

export function ProfileItemCard({ children, label, extraContent }) {
    return render();
    function render() {
        return (
            <ListCard
                header={<Header label={label} extraContent={extraContent} />}
            >
                {children}
            </ListCard>
        );
    }
}

function Header({ label, extraContent }) {
    return (
        <Flex justifyContent="space-between" alignItems="center" spacing={3}>
            <Label label={label} />
            {extraContent}
        </Flex>
    );
}

function Label({ label }) {
    return (
        <div>
            <h4>{renderLabel()}</h4>
        </div>
    );

    function renderLabel() {
        if (typeof label === "string") {
            return <Translate textOnly>{label}</Translate>;
        }

        if (typeof label === "function") {
            return label();
        }

        return label;
    }
}
