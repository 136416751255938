import React from 'react';
import { Radar, Dot } from 'recharts';
import { BaseRadarChart } from '../../components';
import { PRIMARY_COLOR } from '../../_constants/theme.constants';

export function PrimaryRadarChart({ data, height }) {
    return (
        <BaseRadarChart data={data} height={height} withLegend={false}>
            <Radar
                dataKey="value"
                stroke={PRIMARY_COLOR}
                fill="#5577FF"
                fillOpacity={0.1}
                strokeWidth={3}
                dot={props => {
                    const { strokeWidth } = props;
                    return (
                        <Dot
                            {...props}
                            r={strokeWidth * 2}
                            strokeWidth={strokeWidth}
                            stroke="#fff"
                            fillOpacity={1}
                            fill={PRIMARY_COLOR}
                        />
                    );
                }}
            />
        </BaseRadarChart>
    );
}
