import { List } from "immutable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-translate.ts";

import Checkbox from "../../../../../common/components/input/Checkbox/Checkbox";
import { JobFilterActions } from "../../../../_actions";
import styles from "./EmploymentFilters.module.css";
import { useFiltersContext } from "./FiltersContext";
import { FailedFilters } from "./FailedFilters";
import { ArrayHelper } from "../../../../../common/_helpers/ArrayHelper";
import { useFreshState } from "../../../../../common/hooks/useFreshState";

type Filter = {
    id: number;
    name: string;
    checked: boolean;
};

type EmploymentFiltersProps = {
    filters: Filter[];
    isLoading: boolean;
    hasFailed: boolean;
};

export function EmploymentFilters(props: EmploymentFiltersProps) {
    const { filters, isLoading, hasFailed } = props;

    if (isLoading) {
        return (
            <div>
                <Translate textOnly>common.misc.loading</Translate>
                <span>...</span>
            </div>
        );
    }

    if (hasFailed || ArrayHelper.isEmpty(filters)) {
        return <FailedFilters />;
    }

    return <FiltersList filters={filters} />;
}

function FiltersList(props: { filters: Filter[] }) {
    const { filters } = props;
    const dispatch = useDispatch();
    const [state, setState] = useFreshState(List(filters));
    const { addConfirmEvent, removeConfirmEvent } = useFiltersContext();

    useEffect(() => {
        addConfirmEvent(save);

        return () => removeConfirmEvent(save);
    }, []);

    return (
        <ul className={styles.list}>
            {state.current.map((item, index) => (
                <li key={index}>
                    <Checkbox
                        checked={item.checked}
                        label={item.name}
                        onChange={(checked) => {
                            const item = state.current.get(index) as Filter;
                            setState(
                                state.current.set(index, { ...item, checked })
                            );
                        }}
                    />
                </li>
            ))}
        </ul>
    );

    function save() {
        dispatch(
            JobFilterActions.changeEmploymentType(state.current.toArray())
        );
    }
}
