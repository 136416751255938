import { combineReducers } from 'redux';

import current from './current.reducer';
import history from './history.reducer';
import views from './views.reducer';
import {
    GET_NOTIFICATIONS_VIEWS_SUCCESS,
    GET_NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_CHANGE_PAGE,
} from '../../_constants/actions/notification.constants';

export default combineReducers({ current, history, views, pagination });

function pagination(
    state = {
        elements: 0,
        page: 0,
        pages: 0,
    },
    action
) {
    switch (action.type) {
        case GET_NOTIFICATIONS_VIEWS_SUCCESS:
        case GET_NOTIFICATIONS_SUCCESS:
            return success(action.payload);
        case NOTIFICATIONS_CHANGE_PAGE:
            return { ...state, page: action.payload };
        default:
            return state;
    }
}

function success(payload) {
    const { pageable, totalElements, totalPages } = payload;

    return {
        page: pageable.pageNumber,
        elements: totalElements,
        pages: totalPages,
    };
}
