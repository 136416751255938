import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { NumberSchema, ObjectSchema } from "yup";

import FormInput from "../../../../common/components/input/FormInput";
import RangeField from "../../../../common/components/input/RangeField/RangeField";
import AdminJobActions from "../../../_actions/AdminJobActions";
import AdminJobSelectors from "../../../_selectors/AdminJobSelectors";
import AdminEdit from "../../AdminEdit/AdminEdit";
import UploadButton from "./UploadButton";

type JobExperiencePanelProps = {
    jobPostId: number;
};

const JOB_EXPERIENCE_MAX = "jobExperienceYearMax";
const JOB_EXPERIENCE_MIN = "jobExperienceYearMin";

type Values = {
    [JOB_EXPERIENCE_MAX]: number;
    [JOB_EXPERIENCE_MIN]: number;
};

export default function JobExperiencePanel(props: JobExperiencePanelProps) {
    const { jobPostId } = props;
    const translate = useTranslate("admin.jobs.edit.experience");

    const { data } = AdminJobSelectors.useGetEdit();
    const dispatch = useDispatch();

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="details"
        >
            <Formik<Values>
                initialValues={{
                    jobExperienceYearMax:
                        data?.idealCandidate?.jobExperienceYearMax,
                    jobExperienceYearMin:
                        data?.idealCandidate?.jobExperienceYearMin,
                }}
                onSubmit={submit}
                validationSchema={getValidationSchema}
                enableReinitialize
            >
                <Form>
                    <RangeField
                        label={translate("form.range.label")}
                        from={
                            <FormInput
                                type="number"
                                name={JOB_EXPERIENCE_MIN}
                                placeholder={translate(
                                    "form.range.placeholder.min"
                                )}
                            />
                        }
                        to={
                            <FormInput
                                type="number"
                                name={JOB_EXPERIENCE_MAX}
                                placeholder={translate(
                                    "form.range.placeholder.max"
                                )}
                            />
                        }
                    />
                    <UploadButton sending={false} />
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function submit(values: Values) {
        dispatch(
            AdminJobActions.saveJobExperience({
                jobPostId,
                ...values,
            })
        );
    }

    function getValidationSchema() {
        return new ObjectSchema({
            [JOB_EXPERIENCE_MIN]: new NumberSchema().nullable(),
            [JOB_EXPERIENCE_MIN]: new NumberSchema().nullable(),
        });
    }
}
