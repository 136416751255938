import React from 'react';
import { Translate } from 'react-translate.ts';

import { ArrayHelper } from '../../../_helpers/ArrayHelper';
import { BenefitsList } from '../../jobs';
import { Panel } from './Panel';

export function Benefits({ benefits, heading }) {
    return (
        <Panel heading={heading}>
            <Inner benefits={benefits} />
        </Panel>
    );
}

function Inner({ benefits }) {
    const hasBenefits = !ArrayHelper.isEmpty(benefits);

    if (!hasBenefits) {
        return <Translate>common.company.profile.benefits.noData</Translate>;
    }

    return <BenefitsList benefits={benefits} />;
}
