import { combineReducers } from "redux";

import { CareerPathsConstants } from "../../_constants/actions/CareerPathsConstants";
import { ReducerHelpers } from "../../_helpers/ReducerHelpers";

function data(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.GET_MAP_SUCCESS:
            return action.payload.map;
        case CareerPathsConstants.GET_MAP_PATHS_SUCCESS:
            return action.payload.map;
        default:
            return state;
    }
}

const state = (state, action) =>
    ReducerHelpers.loading(state, action, [
        CareerPathsConstants.GET_MAP,
        CareerPathsConstants.GET_MAP_PATHS,
        CareerPathsConstants.GET_DEPARTMENTS,
    ]);

export default combineReducers({ data, state });
