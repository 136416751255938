import IBenefit from "../../types/IBenefit";
import IID from "../../types/IID";
import IJobCard from "../../types/IJobCard";
import ILocation from "../../types/ILocation";
import { BenefitsGrid } from "../BenefitsGrid/BenefitsGrid";
import { Panel } from "../Panel/Panel";
import styles from "./CompanyDetailContent.module.scss";
import Gallery from "./parts/Gallery";
import JobGrid from "./parts/JobGrid";
import Locations from "./parts/Locations";
import MainPanel from "./parts/MainPanel";

type CompanyDetailContentProps = {
    description: string;
    founded: string;
    industries: { name: string; description: string }[];
    location: ILocation;
    employees: string;
    url: string;
    videoId: string;
    videoProvider: string;
    jobs: IJobCard[];
    locations: ILocation[];
    benefits: IBenefit[];
    images: (IID & { url: string; publicUrl: string })[];
    isLoading: boolean;
};

export default function CompanyDetailContent(props: CompanyDetailContentProps) {
    const {
        description,
        founded,
        industries,
        employees,
        url,
        location,
        videoId,
        videoProvider,
        jobs,
        locations,
        benefits = [],
        images,
        isLoading,
    } = props;

    return (
        <div className={styles.content}>
            <MainPanel
                description={description}
                founded={founded}
                industries={industries}
                employees={employees}
                url={url}
                headquarters={location?.text}
                videoUrl={videoId}
                videoProvider={videoProvider}
                isLoading={isLoading}
            />
            <JobGrid jobs={jobs} isLoading={isLoading} />
            <Locations
                locations={locations}
                headquarterLocation={location}
                isLoading={isLoading}
            />
            <Gallery images={images} isLoading={isLoading} />
            <Panel>
                <Panel.Heading>Benefits</Panel.Heading>
                <BenefitsGrid benefits={benefits} isLoading={isLoading} />
            </Panel>
        </div>
    );
}
