import React from 'react';

export function FlexItem({
    children,
    size,
    xxs,
    xs,
    sm,
    md,
    lg,
    textAlign,
    alignSelf,
    alignContent,
    justifySelf,
    style,
    className,
    ...rest
}) {
    return (
        <div
            className={`flex-item ${getSize()} ${className ? className : ''}`}
            style={{
                alignSelf,
                alignContent,
                textAlign,
                justifySelf,
                ...style
            }}
            {...rest}
        >
            {children}
        </div>
    );

    function getSize() {
        return `${size ? `flex-item-${size}` : ''} ${getBreakpointClasses()}`;
    }

    function getBreakpointClassName(columnSize, breakpoint) {
        return columnSize ? `flex-item-${columnSize}-${breakpoint}` : '';
    }

    function getBreakpointClasses() {
        return [
            getBreakpointClassName(xxs, 'xxs'),
            getBreakpointClassName(xs, 'xs'),
            getBreakpointClassName(sm, 'sm'),
            getBreakpointClassName(md, 'md'),
            getBreakpointClassName(lg, 'lg')
        ].join(' ');
    }
}
