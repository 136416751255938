import React from "react";
import { SoftSkillsChart } from "./SoftSkillsChart";
import { BREAKPOINT_SM } from "../../_constants/theme.constants";
import { useWindowState } from "../misc";

export function SoftSkillsMatchChart({
    skills,
    skillsClass,
    displayOnly = true,
}) {
    const chartData = createChartDataset(skills);
    const { width } = useWindowState();
    const isMobile = width < BREAKPOINT_SM;

    const height = isMobile ? 300 : 450;
    const radius = isMobile ? 100 : 150;

    if (!skills) {
        return null;
    }

    return (
        <div className={`skills ${skillsClass || ""}`}>
            <SoftSkillsChart
                data={chartData}
                ratio={0.5}
                height={height}
                radius={radius}
                displayOnly={displayOnly}
            />
        </div>
    );
}

function createChartDataset(skills) {
    return (
        skills &&
        skills.map((item, index) => {
            const { levelMin, levelMax } = item.idealSkill;
            const idealMin = levelMin || 0;
            const idealMax = levelMax || 100;
            return {
                id: item.idealSkill.skills.map((skill) => skill.id),
                name: `${item.idealSkill.skills.map((skill) => skill.name)}`,
                idealMin,
                idealMax,
                you: item.actualSkill ? item.actualSkill.level : 0,
                border: 100,
                match: item.skillMatchScore,
                index: index + 1,
            };
        })
    );
}
