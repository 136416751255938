import { combineReducers } from "redux";

import { CareerPathsConstants } from "../../_constants/actions/CareerPathsConstants";

function data(state = [], action) {
    switch (action.type) {
        case CareerPathsConstants.GET_CATEGORIES_SUCCESS:
            return action.payload.categories;
        default:
            return state;
    }
}

function selectedCategoryId(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.SET_SELECTED_CATEGORY:
            const { categoryId, type } = action.payload;
            return type === "category" ? categoryId : state;
        case CareerPathsConstants.GET_CATEGORIES_SUCCESS:
            if(!state && action.payload?.categories?.[0]?.id) {
                return action.payload.categories[0].id;
            }
            return state;
        default:
            return state;
    }
}

function selectedSubcategoryId(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.SET_SELECTED_CATEGORY:
            const { categoryId, type } = action.payload;
            return type === "subcategory" ? categoryId : state;
        case CareerPathsConstants.GET_MAP_SUCCESS:
            return action.payload.departmentSubcategoryId;
        default:
            return state;
    }
}

export default combineReducers({
    data,
    selectedCategoryId,
    selectedSubcategoryId,
});
