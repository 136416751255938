import { useHistory, useParams } from "react-router-dom";

import { BackButton } from "../../../common/components";
import CompanyProfile from "../../../common/components/company-profile/CompanyProfile";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export default function CompanyProfilePage() {
    const params = useParams<{ id: string }>();
    const history = useHistory();
    const renderToHeader = useRenderToHeader();

    return (
        <CandidatePageLayout>
            {renderToHeader(<BackButton onClick={history.goBack} />)}
            <CompanyProfile
                companyId={parseInt(params.id)}
                displayOnly={false}
            />
        </CandidatePageLayout>
    );
}
