import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import InterpretationActions from "../../../../../candidate/_actions/InterpretationActions";

import { AuthSelectors } from "../../../../_selectors";
import OnboardingSelectors from "../../../../_selectors/OnboardingSelectors";
import InterpretationSelectors from "../../../../../candidate/_selectors/InterpretationSelectors";
import MopInterpretationContent
    from "../../../../../candidate/pages/Interpretation/Mop/MopInterpretationContent/MopInterpretationContent";

export function OnboardingInterpretation() {
    const { profileId, results, isLoading } = useSelector(mapState);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(InterpretationActions.getInterpretation(profileId, "mop"));
        }
    }, [dispatch, profileId]);

    return (
        <div id="interpretation">
            {/* <NextButton /> */}
            <MopInterpretationContent
                isLoading={isLoading}
                profiles={results.profiles}
                interpretations={results.skillInterpretations}
                skills={results.softSkills}
            />
            {/* <NextButton /> */}
        </div>
    );

    // function NextButton() {
    //     return (
    //         <Link to={DASHBOARD_PATH}>
    //             <OnboardingButtons.Next />
    //         </Link>
    //     );
    // }
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        results: InterpretationSelectors.getInterpretation("mop")(state),
        isLoading: OnboardingSelectors.isLoading(state),
    };
}
