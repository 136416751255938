import React, { useEffect, useState, useRef } from 'react';
import { Translate, useTranslate } from 'react-translate.ts';

import { BaseDialog, Button, Checkbox } from '../../../common/components';
import { BaseDialogBox } from '../../../common/components/dialogs/BaseDialogBox';
import { GAUtils } from "../../../common/_helpers/GAUtils";

export function InterpretationShare(props) {
    const { isOpen, close } = props;

    const location = window.location;
    const link = location.origin + location.pathname;

    const inputRef = useRef(null);

    const translate = useTranslate('candidate.interpretation.share');

    const [isPublic, setIsPublic] = useState(true);
    const [skipped, setSkipped] = useState(false);

    const fbAppId = '555185784933130';

    const fbInitialized = useRef(false);

    useEffect(() => {
        if (fbInitialized.current) {
            return;
        }

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: fbAppId,
                autoLogAppEvents: true,
                xfbml: false,
                version: 'v9.0',
            });

            fbInitialized.current = true;
        };
    });

    return (
        <BaseDialog
            isVisible={isOpen}
            onClose={close}
            id="interpretation-share"
        >
            <BaseDialogBox onClose={close}>
                {isPublic || skipped ? <Share /> : <Consent />}
            </BaseDialogBox>
        </BaseDialog>
    );

    function Consent() {
        const translateConsent = translate('consent');
        return (
            <>
                <div
                    id="interpretation__share__consent__title"
                    className="font-book"
                >
                    {translateConsent.title}
                </div>
                <Checkbox
                    value={isPublic}
                    onChange={(value) => setIsPublic(value)}
                >
                    {translateConsent.confirm}
                </Checkbox>
                <div
                    id="interpretation__share__hint"
                    title={translateConsent.hintContent}
                >
                    {translateConsent.hint}
                </div>
                <Button
                    variant="secondary"
                    onClick={() => setSkipped(true)}
                    isThin
                >
                    {translateConsent.skip}
                </Button>
            </>
        );
    }

    function Share() {
        return (
            <>
                <div id="interpretation-share__icon">
                    <img src="/img/icons/share-gift.svg" alt="" />
                </div>
                <div id="interpretation-share__title">{translate('title')}</div>
                <div id="interpretation-share__desc" className="font-book">
                    {translate('description')}
                </div>
                <div id="interpretation-share__copy">
                    <input
                        ref={inputRef}
                        id="interpretation-share__copy__input"
                        value={link}
                        readOnly
                    />
                    <button
                        id="interpretation-share__copy__button"
                        onClick={copyLink}
                    >
                        <Translate textOnly>common.actions.copy</Translate>
                    </button>
                </div>
                <button
                    className="interpretation-share-button"
                    onClick={openMail}
                >
                    <i className="icon-envelope" />
                    {translate('email')}
                </button>
                <button
                    className="interpretation-share-button"
                    onClick={shareOnFB}
                >
                    <img src="/img/logo/fb.svg" alt="" />
                    {translate('fb')}
                </button>
                <button
                    onClick={shareOnLinkIn}
                    className="interpretation-share-button"
                >
                    <img src="/img/logo/linkedIn.svg" alt="" />
                    {translate('linkin')}
                </button>
            </>
        );
    }

    function shareOnFB() {
        if (!window.FB) {
            return;
        }

        window.FB.ui(
            {
                method: 'share',
                href: link,
            }
            // function (response) {
            //     console.log({ response });
            // }
        );
        GAUtils.event('Diagnostics', 'TEST_SHARED_FB');
    }

    function shareOnLinkIn() {
        if (!window.IN) {
            return;
        }

        window.IN.UI.Share().params({ url: link }).place();
        GAUtils.event('Diagnostics', 'TEST_SHARED_LI');
    }

    function openMail() {
        window.location.href = createMailTo(
            'AI and data-driven career centre of the future',
            'Hello, \n\nI\'m sending you a link to Kareer. You can discover your ideal career path, matching job roles and test your soft skills. Everything is for free! Can\'t wait to see you there!'
        );
        GAUtils.event('Diagnostics', 'TEST_SHARED_EMAIL');
    }

    function createMailTo(subject, body) {
        return `mailto:?subject=${subject}&body=${body + ' \n\n' + link}`;
    }

    function copyLink() {
        inputRef.current.select();
        inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/

        document.execCommand('copy');
        GAUtils.event('Diagnostics', 'TEST_SHARED_COPY_LINK');
    }
}
