import React from 'react';

export function InterpretationShareButtons() {
    return (
        <div className="interpretation-share-buttons">
            <button className="interpretation-share-buttons__info">
                Vediet viac
            </button>
            <button className="interpretation-share-buttons__test">
                Spraviť soft-skills analýzu
            </button>
        </div>
    );
}
