import React from "react";
import { cx } from "@emotion/css";

import { SVGIcon } from "../../index";
import styles from "./OnboardingProfilePicture.module.scss";

type OnboardingProfilePictureProps = {
    image: string;
    onClick: () => void;
    hasError: boolean;
    className?: string;
};

export default function OnboardingProfilePicture(props: OnboardingProfilePictureProps) {
    const { image, onClick, hasError, className } = props;

    return (
        <div
            className={cx(styles.pic, hasError && styles.error, className)}
            onClick={onClick}
        >
            <img src={image || "/img/avatar.svg"} alt="" />
            {!image && (
                <div className={styles.badge}>
                    <SVGIcon name="plus" />
                </div>
            )}
        </div>
    );
}
