import { StorageService } from './StorageService';
import { RequestService } from './RequestService';
import { ACTIVE_STATUS } from '../_constants/user.constants';

const ACCESS_TOKEN = 'accessToken';
const PROFILE_ID = 'profileId';
const USER_DATA = 'userData';

export const UserService = {
    current,
    generateNewVerificationCode,
    forgotPassword,
    resetPassword,
    isLoggedIn,
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    getProfileId,
    setProfileId,
    getUser,
    setUser,
    getUserStatus,
    setUserStatus,
    isUserActive,
    clearUserData,
};

function current() {
    return RequestService.get('users/current');
}

function generateNewVerificationCode() {
    return RequestService.post(`users/generate-verification-token`).then(
        (response) => {
            return response;
        }
    );
}

function forgotPassword(username) {
    return RequestService.post('users/generate-password-reset-token', {
        username,
    });
}

/**
 *
 * @param {{token: string, password: string}} payload
 */
function resetPassword(payload) {
    return RequestService.post('users/password-reset', payload);
}

function isLoggedIn() {
    return !!StorageService.getLocal(ACCESS_TOKEN);
}

function getAccessToken() {
    return StorageService.getLocal(ACCESS_TOKEN);
}

function setAccessToken(token) {
    StorageService.setLocal(ACCESS_TOKEN, token);
}

function removeAccessToken() {
    StorageService.removeLocal(ACCESS_TOKEN);
}

function getProfileId() {
    return StorageService.getLocal(PROFILE_ID);
}

function setProfileId(id) {
    StorageService.setLocal(PROFILE_ID, id);
}

function getUser() {
    return StorageService.getLocal(USER_DATA);
}

function setUser(user) {
    StorageService.setLocal(USER_DATA, user);
}

function setUserStatus(userStatus) {
    const user = getUser();
    setUser({ ...user, userStatus });
}

function getUserStatus() {
    const user = getUser();

    if (!user || !user.userStatus || !isLoggedIn()) {
        return null;
    }

    return user.userStatus.status;
}

function isUserActive() {
    const status = getUserStatus();

    return status && status === ACTIVE_STATUS;
}

function clearUserData() {
    StorageService.removeLocal(USER_DATA);
    StorageService.removeLocal(PROFILE_ID);
    StorageService.removeLocal(ACCESS_TOKEN);
}
