import React, { useState } from "react";
import { cx } from "@emotion/css";
import { Translate } from "react-translate.ts";

import { Flex } from "../../../../../common/components";
import Button from "../../../../../common/components/buttons/Button/Button";
import { Panel } from "../../../../../common/components/Panel/Panel";

const RESPONSIBILITIES_LIMIT = 6;

type JobResponsibilitiesProps = {
    responsibilities: [];
    employmentType?: string;
    employmentTitle?: string;
    colour?: string;
    thickness?: number;
};

export default function JobResponsibilities(props: JobResponsibilitiesProps) {
    const { responsibilities } = props;
    const [value, setValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    if (!responsibilities || !Array.isArray(responsibilities)) {
        return null;
    }
    const count = responsibilities.length;
    const isResponsibilitiesOverLimit = count > RESPONSIBILITIES_LIMIT;

    const visibleResponsibilities =
        isResponsibilitiesOverLimit && !isOpen
            ? responsibilities.slice(0, RESPONSIBILITIES_LIMIT)
            : responsibilities;

    return (
        <Panel>
            <Panel.Heading>
                <Translate textOnly>
                    candidate.jobs.detail.responsibility.title
                </Translate>
            </Panel.Heading>
            <div className="flex flex-wrap">
                <div className="job-responsibility w100">
                    <div className="job-responsibility--cards">
                        {visibleResponsibilities.map(renderResponsibility)}
                    </div>
                    <div className="flex flex-wrap padding-top-20">
                        {renderButton()}
                    </div>
                </div>
                {/*<div className="job-responsibility-chart w100 w50-md">*/}
                {/*    <div id="job-detail--responsibilities--match">*/}
                {/*        <JobMatchBig*/}
                {/*            responsibilities={responsibilities}*/}
                {/*            employmentName={employmentType}*/}
                {/*            employmentTitle={employmentTitle}*/}
                {/*            colour={colour}*/}
                {/*            thickness={thickness}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Panel>
    );

    function renderResponsibility(
        { name, description, workLoad }: any,
        index: number
    ) {
        const isActive = index === value;
        return (
            <Flex
                key={index}
                className={cx("card font-book", isActive && "is-active")}
                onClick={onChange}
            >
                <Flex className="w100">
                    <div className="card--index font-heavy">{index + 1}.</div>
                    <div className="card--text">
                        <div className="card--title font-21">{name}</div>
                        <p>{description}</p>
                    </div>
                </Flex>
                <div className="card--value text-right">{workLoad}%</div>
            </Flex>
        );

        function onChange() {
            if (index === value) {
                setValue(null);
            } else {
                setValue(index);
            }
        }
    }

    function renderButton() {
        if (isOpen || !isResponsibilitiesOverLimit) {
            return null;
        }

        return (
            <Button onClick={onClick}>
                <Translate textOnly>
                    candidate.jobs.detail.responsibility.more
                </Translate>
                {` (${responsibilities.length - RESPONSIBILITIES_LIMIT})`}
            </Button>
        );

        function onClick() {
            setIsOpen(true);
        }
    }
}
