import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        scroll();
    }, [pathname]);

    function scroll() {
        window.scrollTo(0, 0);
    }

    return null;
}
