import React, { PropsWithChildren } from "react";
import { Close } from "@material-ui/icons";
import { addClass } from "classname-helper";
import { useTranslate } from "react-translate.ts";

import { TooltipLabel } from "../TooltipLabel";
import { LoadingOverlay } from "../loading";

export type IntegratedDialogProps = {
    onClose: () => void;
    forwardRef?: any;
    className?: string;
    isLoading?: boolean;
};

export function IntegratedDialog(
    props: PropsWithChildren<IntegratedDialogProps>
) {
    const translate = useTranslate();
    const {
        children,
        onClose,
        forwardRef,
        className,
        isLoading = false,
        ...rest
    } = props;

    return (
        <div
            className={addClass("integrated-dialog", className)}
            ref={forwardRef}
            {...rest}
        >
            <LoadingOverlay isLoading={isLoading} />
            <div className="integrated-dialog-inner">
                <TooltipLabel label={translate("common.actions.cancel")}>
                    <div id="close-dialog">
                        <Close onClick={onClose} fontSize="large" />
                    </div>
                </TooltipLabel>
                {children}
            </div>
        </div>
    );
}
