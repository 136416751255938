import styles from "./AdditionalInfo.module.scss";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";
import { IOtherJobInfo } from "../../../../common/types/IJobDetail";
import { Panel } from "../../../../common/components/Panel/Panel";

type AdditionalInfoProps = {
    heading: string;
    list: IOtherJobInfo[];
};

export function AdditionalInfo(props: AdditionalInfoProps) {
    const { heading, list } = props;

    if(ArrayHelper.isEmpty(list)) {
        return null;
    }

    return (
        <Panel>
            <Panel.Heading>{heading}</Panel.Heading>
            <ul className={styles.list}>
                {list.map((item, i) => (
                    <li key={i}>
                        {item.description}
                    </li>
                ))}
            </ul>
        </Panel>
    );
}
