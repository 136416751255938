import { useState } from "react";
import { useField } from "formik";
import zxcvbn from "zxcvbn";
import { cx } from "@emotion/css";
import { useTranslate } from "react-translate.ts";

import styles from "./PasswordInput.module.scss";
import Input, { InputProps } from "../Input/Input";
import { PASSWORD_TYPE, TEXT_TYPE } from "../../../_constants/input.constants";
import { SVGIcon } from "../../SVGIcon";

const STRENGTH_WEAK = "weak";
const STRENGTH_FAIR = "fair";
const STRENGTH_GOOD = "good";
const STRENGTH_STRONG = "strong";

interface PasswordInputProps extends InputProps {
    useRules?: boolean;
}

export default function PasswordInput(props: PasswordInputProps) {
    const { useRules = true, ...rest } = props;
    const [type, setType] = useState(PASSWORD_TYPE);
    const [visibleRules, setVisibleRules] = useState(false);
    const [{ value, onChange, name }, { error, touched }] = useField({
        name: props.name,
        validate: (value) => (useRules ? validate(value) : null),
    });
    const translate = useTranslate("common.password-input");

    return (
        <div className={styles.wrapper}>
            <Input
                {...rest}
                name={name}
                error={touched && !!error}
                onChange={onChange}
                value={value}
                type={type}
                onFocus={showRules}
            />
            <ToggleButton
                password={value}
                onClick={toggle}
                visible={!isPassword(type)}
            />
            {useRules && (
                <>
                    <StrengthBar
                        password={value}
                        visible={visibleRules}
                        translate={translate}
                    />
                    <Rules
                        visible={visibleRules}
                        password={value}
                        hasError={touched && !!error}
                        translate={translate}
                    />
                </>
            )}
        </div>
    );

    function toggle() {
        setType((prev) => (prev === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE));
    }

    function showRules() {
        setVisibleRules(true);
    }
}

type StrengthBarProps = {
    visible: boolean;
    password: string;
    translate: (key: string) => string;
};

function StrengthBar(props: StrengthBarProps) {
    const { visible, password, translate } = props;
    if (!visible) {
        return null;
    }

    const score = calculateScore(password);
    const strength = getStrength(score);
    return (
        <div className={styles.strength}>
            <strong>
                {translate("strength.label")}
                {": "}
            </strong>
            <span>{translate(`strength.${strength}`)}</span>
        </div>
    );
}

type ToggleButtonProps = {
    visible: boolean;
    password: string;
    onClick: () => void;
};

function ToggleButton(props: ToggleButtonProps) {
    const { password, visible, onClick } = props;
    return (
        <div
            className={cx(styles.toggle, visible && styles.toggle__active)}
            onClick={onClick}
            style={{ display: password.length > 0 ? "inherit" : "none" }}
        >
            <SVGIcon name="view" />
        </div>
    );
}

type RulesProps = {
    visible: boolean;
    password: string;
    hasError: boolean;
    translate: (key: string) => string;
};

function Rules(props: RulesProps) {
    const { visible, password, hasError } = props;
    const translate = (key: string) => props.translate("rules." + key);
    if (!visible) {
        return null;
    }

    const [hasLower, hasUpper, hasNumber, hasMin] = resolveRules(password);

    return (
        <div className={cx(styles.rules, hasError && styles.rules__error)}>
            <ul>
                <li className={getRuleClassName(hasLower)}>
                    {translate("lower")}
                </li>
                <li className={getRuleClassName(hasUpper)}>
                    {translate("upper")}
                </li>
            </ul>
            <ul>
                <li className={getRuleClassName(hasNumber)}>
                    {translate("number")}
                </li>
                <li className={getRuleClassName(hasMin)}>{translate("min")}</li>
            </ul>
        </div>
    );
}

function calculateScore(password: string) {
    return zxcvbn(password).score;
}

function getStrength(score: number) {
    if (score >= 4) {
        return STRENGTH_STRONG;
    }

    if (score >= 3) {
        return STRENGTH_GOOD;
    }

    if (score >= 2) {
        return STRENGTH_FAIR;
    }

    return STRENGTH_WEAK;
}

function getRuleClassName(condition: boolean) {
    return condition && styles.done;
}

function validate(password: string) {
    let error;

    const rules = resolveRules(password);

    if (rules.includes(false)) {
        error = "error";
    }

    return error;
}

function resolveRules(password: string) {
    const rules = new Array(4).fill(false);

    // lowercase character
    if (password.match(/(?=[a-z]).*/)) {
        rules[0] = true;
    }

    // uppercase character
    if (password.match(/(?=[A-Z]).*/)) {
        rules[1] = true;
    }

    // number character
    if (password.match(/(?=[0-9]).*/)) {
        rules[2] = true;
    }

    // special character
    // if (password.match(/(?=.?[#?!@$%^&*-]).*/)) {
    //     rules[3] = true;
    // }

    // minimum characters
    if (password.match(/(?=.{8,}).*/)) {
        rules[3] = true;
    }

    return rules;
}

function isPassword(type: string) {
    return type === PASSWORD_TYPE;
}
