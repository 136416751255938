import React from "react";
import styles from "./InterpretationSummaryList.module.scss";

type InterpretationSummaryListProps = {
    label?: string;
    items: { description: string }[];
};

export default function InterpretationSummaryList(
    props: InterpretationSummaryListProps
) {
    const { label, items } = props;

    if (!items) {
        return null;
    }

    return (
        <div className={styles.list}>
            {label && <div className={styles.label}>{label}</div>}
            <ul>
                {items.map((item, index) => (
                    <li className="font-book" key={index}>
                        {item.description}
                    </li>
                ))}
            </ul>
        </div>
    );
}
