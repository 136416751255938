import { ReactNode } from "react";
import cx from "classnames";

import styles from "./InterpretationPanel.module.scss";

type InterpretationPanelProps = {
    className?: string;
    children?: ReactNode;
    id?: string;
};

export default function InterpretationPanel(props: InterpretationPanelProps) {
    const { children, className, id } = props;

    return (
        <div id={id} className={className}>
            {children}
        </div>
    );
}

function Inner(props: InterpretationPanelProps) {
    const { children, className } = props;

    return <div className={cx(styles.inner, className)}>{children}</div>;
}

function Subtitle(props: InterpretationPanelProps) {
    const { children, className } = props;

    return <div className={cx(styles.subtitle, className)}>{children}</div>;
}

InterpretationPanel.Inner = Inner;
InterpretationPanel.Subtitle = Subtitle;
