import { cx } from "@emotion/css";
import { Translate } from "react-translate.ts";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { DialogProps } from "../Dialog/Dialog";
import styles from "./EditDialog.module.scss";

export interface EditDialogProps extends DialogProps {
    title?: string;
    onSave?: () => void;
    onCancel?: () => void;
}

export default function EditDialog(props: EditDialogProps) {
    const { children, onSave, onCancel, className, ...rest } = props;

    return (
        <ConfirmDialog
            {...rest}
            className={cx(styles.dialog, className)}
            onConfirm={onSave}
            onCancel={onCancel}
            confirmButtonProps={{ children: <Translate textOnly>common.actions.saveChanges</Translate> }}
            controlsProps={{ className: styles.controls }}
            titleProps={{ className: styles.title }}
        >
            {children}
        </ConfirmDialog>
    );
}
