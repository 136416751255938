import {
    GET_CANDIDATE_CARDS_SUCCESS,
    GET_CANDIDATE_CARDS_FAIL,
    GET_CANDIDATE_CARDS
} from '../../../_constants/actions/candidates.constants';

export default function(state = false, action) {
    switch (action.type) {
        case GET_CANDIDATE_CARDS:
            return true;
        case GET_CANDIDATE_CARDS_SUCCESS:
        case GET_CANDIDATE_CARDS_FAIL:
            return false;
        default:
            return state;
    }
}
