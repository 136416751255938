import CookieConfigActions from "../common/_actions/CookieConfigActions";
import CookieConfigService from "../common/_services/CookieConfigService";

const cookieConfigMiddleware = () => (next: any) => (action: any) => {
    if (CookieConfigActions.setConfig.match(action)) {
        CookieConfigService.setConfig(action.payload);
    } else if (CookieConfigActions.setConsent.match(action)) {
        CookieConfigService.setConsent();
    }
    return next(action);
};

export default cookieConfigMiddleware;
