import { RequestService } from '../../common/_services/RequestService';

export const WalletService = {
    createPayment,
    acceptPayment,
    getWallet,
    getPrice,
    getCurrencies
};

function createPayment({ quantity, currency, amount }) {
    return RequestService.post('checkout/sessions', {
        amount,
        currency,
        quantity
    });
}

function acceptPayment(sessionId) {
    return RequestService.post('checkout/success-workaround', { sessionId });
}

function getWallet() {
    return RequestService.get('e-wallet');
}

function getPrice(currency) {
    return RequestService.get(`prices?currency=${currency}`);
}

function getCurrencies() {
    return RequestService.get(`currencies/default`);
}
