import { combineReducers } from "redux";

import { GET_PROFILE_IMAGE_SUCCESS } from "../_constants/actions/profile-actions.constants";
import {
    GET_PROFILE_COMPLETENESS_SUCCESS,
    GET_PROFILE_TYPE_SUCCESS,
    SAVE_PROFILE_TYPE_SUCCESS,
} from "../_actions/ProfileActions";
import ProfileConstants from "../../candidate/_constants/actions/ProfileConstants";

const completenessState = {
    completeness: null,
};

const completeness = (state = completenessState, action) => {
    switch (action.type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return { ...state, completeness: action.payload.completed };
        case GET_PROFILE_COMPLETENESS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({ image, completeness, type });

function image(state = null, action) {
    switch (action.type) {
        case GET_PROFILE_IMAGE_SUCCESS:
            return action.payload.url;
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return action.payload.imageUrl;
        case ProfileConstants.UPLOAD_PHOTO_SUCCESS:
            return action.payload;
    }

    return state;
}

function type(state = null, action) {
    switch (action.type) {
        case GET_PROFILE_TYPE_SUCCESS:
            return action.payload;
        case SAVE_PROFILE_TYPE_SUCCESS:
            const { profileType, ...rest } = action.payload;
            return Object.assign({}, state, { type: profileType, ...rest });
        default:
            return state;
    }
}
