import { takeLatest } from "redux-saga/effects";

import { OnboardingService } from "../_services/OnboardingService";
import { SagaHelpers } from "../_helpers/SagaHelpers";
import {
    ONBOARDING_EDUCATION_BEGIN,
    ONBOARDING_EDUCATION_ERROR,
    ONBOARDING_EDUCATION_SUCCESS,
} from "../_constants/actions/onboarding.constants";
import OnboardingConstants from "../_constants/actions/OnboardingConstants";

function* education(action) {
    yield SagaHelpers.handleRequest({
        request: [OnboardingService.education, action.payload],
        actions: [
            (payload) => ({
                type: ONBOARDING_EDUCATION_SUCCESS,
                payload,
            }),
            (error) => ({
                type: ONBOARDING_EDUCATION_ERROR,
                error,
            }),
        ],
    });
}

function* savePersonal(action) {
    yield SagaHelpers.handleRequest({
        request: [OnboardingService.savePersonal, action.payload],
        action: OnboardingConstants.SAVE_PERSONAL,
    });
}

function* saveHardSkills(action) {
    yield SagaHelpers.handleRequest({
        request: [OnboardingService.saveHardSkills, action.payload],
        action: OnboardingConstants.SAVE_HARD_SKILLS,
    });
}

export default [
    takeLatest(ONBOARDING_EDUCATION_BEGIN, education),
    takeLatest(OnboardingConstants.SAVE_PERSONAL, savePersonal),
    takeLatest(OnboardingConstants.SAVE_HARD_SKILLS, saveHardSkills),
];
