import { RequestService } from '../../common/_services';
import { TRACKING_DECLINED } from '../_constants/actions/tracking.constants';

export const CandidateTrackingService = {
    getTrackingCards,
    getArchivedCards,
    getDeclinedCards,
    changeState,
    rejectCandidate,
    unlockCandidate,
};

/**
 * @param {number} jobPostId
 */
function getTrackingCards(jobPostId) {
    return RequestService.get('candidates/tracking-cards', [
        { name: 'jobPostId', value: jobPostId === 0 ? null : jobPostId },
    ]);
}

/**
 * @param {number} jobPostId
 */
function getArchivedCards(jobPostId) {
    return RequestService.get('candidates/tracking-cards', [
        { name: 'jobPostId', value: jobPostId === 0 ? null : jobPostId },
        { name: 'archived', value: true },
    ]);
}

/**
 * @param {number} jobPostId
 */
function getDeclinedCards(jobPostId) {
    return RequestService.get('candidates/tracking-cards/DECLINED', [
        { name: 'jobPostId', value: jobPostId === 0 ? null : jobPostId },
    ]);
}

/**
 * @param {{candidateId: number, newStatus: string, jobPostId: string, profileId: string}} payload
 */
function changeState({ candidateId, newStatus, jobPostId, profileId }) {
    const data = {
        profileId,
        id: candidateId,
        status: newStatus,
        jobPostId,
    };
    return RequestService.put(
        `api/profiles/${profileId}/job-applications`,
        data
    );
}

/**
 * @param {{candidateId: number, jobPostId: string, profileId: string}} payload
 */
function rejectCandidate({ candidateId, jobPostId, profileId }) {
    const data = {
        profileId,
        id: candidateId,
        status: TRACKING_DECLINED,
        jobPostId,
    };
    return RequestService.put(
        `api/profiles/${profileId}/job-applications`,
        data
    );
}

/**
 * @param {number} candidateId
 */
function unlockCandidate(candidateId) {
    return true;
}
