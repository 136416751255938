import { useEffect, useRef, useState } from "react";

export default function useTimer(
    startTime: number,
    modifier: number = 1,
    interval: number = 1000
) {
    const [time, setTime] = useState(startTime);
    const timer = useRef<any>(null);

    useEffect(() => {
        setTime(startTime);
    }, [startTime]);

    function start() {
        let expected = Date.now() + interval;

        timer.current = setTimeout(step, interval);

        function step() {
            let drift = Date.now() - expected;

            expected += interval;
            const actualTime = Math.max(0, interval - drift);
            setTime((prev) => prev + actualTime * modifier);
            timer.current = setTimeout(step, actualTime);
        }
    }

    function stop() {
        clearTimeout(timer.current);
        timer.current = null;
        setTime(startTime);
    }

    return { time, start, stop };
}
