import { takeLatest } from 'redux-saga/effects';

import { SagaHelpers } from '../../common/_helpers';
import CareerSearchConstants from '../_constants/actions/CareerSearchConstants';
import CareerSearchService from '../_services/CareerSearhcService';

function* getJobRoles() {
    yield SagaHelpers.handleRequest({
        request: [CareerSearchService.getJobRoles],
        actions: [
            CareerSearchConstants.GET_JOB_ROLES_SUCCESS,
            CareerSearchConstants.GET_JOB_ROLES_FAIL,
        ],
    });
}


export default [
    takeLatest(CareerSearchConstants.GET_JOB_ROLES, getJobRoles),
];
