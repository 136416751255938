import { StorageService } from './StorageService';
import { RequestService } from './RequestService';

const LOCALE = 'locale';
const DEFAULT_LOCALE = 'en';

export const LanguageService = {
    changeLocale,
    getCurrentLocale,
    getLanguages,
    getLanguageLevels,
};

function getCurrentLocale() {
    return (
        StorageService.getLocal(LOCALE) || DEFAULT_LOCALE // resolveLocales(navigator.languages)
    );
}

function changeLocale(locale) {
    StorageService.setLocal(LOCALE, locale);
}

function getLanguages() {
    return RequestService.get('languages');
}

function getLanguageLevels() {
    return RequestService.get('language-levels');
}

function resolveLocales(locales) {
    for (const i in locales) {
        const locale = locales[i];
        if (locale === 'sk' || locale === 'sk-SK') {
            return 'sk';
        }

        if (locale === 'cs' || locale === 'cs-CZ') {
            return 'cs';
        }

        if (locale === 'en' || locale.match(/^en{1}/)) {
            return DEFAULT_LOCALE;
        }
    }

    return DEFAULT_LOCALE;
}
