import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

import styles from "./Video.module.scss";

type VideoProps = {
    videoId: string;
    videoProvider: string;
};

export default function Video(props: VideoProps) {
    const { videoId, videoProvider } = props;

    return <div className={styles.video}>{render(videoId, videoProvider)}</div>;
}

function render(videoId: string, videoProvider: string) {
    switch (videoProvider) {
        case "youtube":
            return renderYoutube(videoId);
        case "vimeo":
            return renderVimeo(videoId);
        case "image":
            return renderImage(videoId);
        case "video":
            return renderVideo(videoId);
        default:
            return null;
    }
}

function renderYoutube(videoId: string) {
    return (
        <YouTube videoId={videoId} opts={{ width: "100%", height: "404px" }} />
    );
}

function renderVimeo(videoId: string) {
    return (
        <Vimeo
            video={videoId}
            autoplay={false}
            responsive={true}
            height={"404px"}
        />
    );
}

function renderImage(url: string) {
    return <img width="100%" src={url} alt="" />;
}

function renderVideo(url: string) {
    return <video src={url} controls />;
}
