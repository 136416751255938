import { useTranslate } from "react-translate.ts";
import { cx } from "@emotion/css";

import { IQualificationRequirements } from "../../../../common/types/IJobDetail";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";
import { BaseSkills } from "./BaseSkills";
import styles from "./QualificationsList.module.scss";
import Skeleton from "../../../../common/components/Skeleton/Skeleton";

type QualificationsListProps = {
    match: number;
    heading: string;
    qualifications: IQualificationRequirements[];
    isLoading: boolean;
};

export function QualificationsList(props: QualificationsListProps) {
    const { heading, match, qualifications, isLoading } = props;
    const translate = useTranslate();

    return (
        <BaseSkills
            heading={heading}
            match={match}
            label={translate("common.jobs.match.qualification")}
            isLoading={isLoading}
        >
            {ArrayHelper.isEmpty(qualifications) && !isLoading ? (
                <p className="text-secondary">
                    {translate(
                        "candidate.jobs.noQualificationRequirements"
                    )}
                </p>
            ) : (
                <ul className={cx(styles.list, "font-book")}>
                    {!isLoading ? (
                        qualifications?.map((item, i) => (
                            <Item key={i} qualification={item} />
                        ))
                    ) : (
                        <Skeleton
                            height={16}
                            width="80%"
                            marginBottom={16}
                            count={3}
                        />
                    )}
                </ul>
            )}
        </BaseSkills>
    );
}

function Item(props: { qualification: IQualificationRequirements }) {
    const qualification = props.qualification;
    const { satisfied } = qualification;
    const text = useTranslateQualification(qualification);

    return (
        <li className={cx(styles.item, satisfied ? styles.checked : "")}>
            {satisfied ? (
                <img
                    className={styles.icon}
                    src="/img/icons/check-primary.svg"
                    alt=""
                />
            ) : (
                <div />
            )}
            <span>{text}</span>
        </li>
    );
}

const EDUCATION_DEGREE_FIELDS = "EDUCATION_DEGREE_FIELDS";
const EDUCATION_DEGREE_FIELD_CATEGORIES = "EDUCATION_DEGREE_FIELD_CATEGORIES";
const JOB_EXPERIENCE = "JOB_EXPERIENCE";
const EDUCATION_LEVEL = "EDUCATION_LEVEL";
const EDUCATION_LEVEL_YEAR = "EDUCATION_LEVEL_YEAR";

function useTranslateQualification(qualification: IQualificationRequirements) {
    const translate = useTranslate();
    const translateYears = (count: number) =>
        translate("common.misc.yearsCount", { count });
    const translateEducation = (level: number, context: string[] = []) =>
        translate(
            "common.misc.education." +
                { 1: "bachelor", 2: "master", 3: "phd" }[level],
            { context: ["adj", "neutral", ...context] }
        );
    const { code, params } = qualification;

    switch (code) {
        case EDUCATION_DEGREE_FIELDS:
            return translate(
                "candidate.jobs.detail.qualifications.text." +
                    EDUCATION_DEGREE_FIELDS,
                { value: params.map((item) => item) }
            );
        case EDUCATION_DEGREE_FIELD_CATEGORIES:
            return translate(
                "candidate.jobs.detail.qualifications.text." +
                    EDUCATION_DEGREE_FIELD_CATEGORIES,
                { value: params.map((item) => item) }
            );
        case JOB_EXPERIENCE:
            const [minY, maxY] = params;
            const minYears = translateYears(parseInt(minY));
            const maxYears = translateYears(parseInt(maxY));
            return translate(
                `candidate.jobs.detail.qualifications.text.${JOB_EXPERIENCE}`,
                { min: minYears, max: maxYears },
                {
                    context: `${validateParam(minY) && "min"}${
                        validateParam(maxY) && "max"
                    }`,
                }
            );
        case EDUCATION_LEVEL:
            const [minEdu, maxEdu, completed] = params;
            // console.log(minEdu)
            const minEducation = minEdu;
            const maxEducation = translateEducation(parseInt(maxEdu));
            // console.log(completed)
            return translate(
                `candidate.jobs.detail.qualifications.text.${EDUCATION_LEVEL}`,
                {
                    min: minEducation,
                    max: maxEducation,
                    completed: completed
                        ? translate("common.misc.education.completed")
                        : "",
                },
                {
                    capitalize: true,
                    context: `${validateParam(minEdu) ? "min" : ""}${
                        validateParam(maxEdu) ? "max" : ""
                    }`,
                }
            );
        case EDUCATION_LEVEL_YEAR:
            const [minEduLevel, maxEduLevel, minLevel, maxLevel] = params;
            const context = ["genitiv"];
            const maxEducationLevel = translateEducation(
                parseInt(maxEduLevel),
                context
            );
            const minEducationLevel = minEduLevel
                ? translateEducation(parseInt(minEduLevel), context)
                : maxEducationLevel;
            const translateCount = (count: number) =>
                translate("common.misc.count", { count }, { ordinal: true });
            const minCount = translateCount(parseInt(minLevel));
            const maxCount = translateCount(parseInt(maxLevel));

            return translate(
                `candidate.jobs.detail.qualifications.text.${EDUCATION_LEVEL}`,
                {
                    min: minEducationLevel || maxEducationLevel,
                    max: maxEducationLevel,
                    minLevel: minCount,
                    maxLevel: maxCount,
                },
                {
                    context: `${validateParam(minLevel) && "min"}${
                        validateParam(maxLevel) && "max"
                    }${
                        validateParam(minEduLevel) &&
                        validateParam(maxEduLevel) &&
                        "full"
                    }`,
                }
            );
    }

    function validateParam(param: string) {
        return param !== null && param !== undefined && param !== "null";
    }
}
