import produce, { Draft } from "immer";
import { useState } from "react";

import { SVGIcon } from "../..";
import Select from "../Select/Select";
import styles from "./MultiSelect.module.scss";

export interface MultiSelectProps<T> {
    value?: T[];
    placeholder?: string;
    onChange?: (values: T[]) => void;
    loadOptions: (search: string) => Promise<T[]>;
    getOptionLabel: (option: T) => string;
    disabled?: boolean;
    preload?: boolean;
    name?: string;
    error?: boolean;
}

export default function MultiSelect<T>(props: MultiSelectProps<T>) {
    const {
        value = [],
        loadOptions,
        getOptionLabel,
        onChange,
        placeholder,
        disabled,
        preload,
        name,
        error,
        ...rest
    } = props;

    const [text, setText] = useState("");
    return (
        <div className={styles.container} {...rest}>
            {value?.length > 0 ? (
                <div className={styles.bar}>
                    {value.map((item, i) => (
                        <Item
                            key={i}
                            item={item}
                            onRemove={removeItem}
                            disabled={disabled}
                        />
                    ))}
                </div>
            ) : null}
            <div className={styles.input}>
                <Select<T>
                    name={name}
                    placeholder={placeholder}
                    loadOptions={loadOptions}
                    getOptionLabel={getOptionLabel}
                    onSelect={(option) => {
                        addItem(option);
                        setText("");
                    }}
                    disabled={disabled}
                    value={null}
                    searchable
                    text={text}
                    onChange={setText}
                    preload={preload}
                    error={error}
                />
            </div>
        </div>
    );

    function addItem(item: T) {
        onChange &&
            onChange(
                produce(value, (draft) => {
                    draft.push(item as any);
                })
            );
    }

    function removeItem(item: T) {
        const index = value.findIndex((value) => value === item);

        if (index !== -1) {
            onChange &&
                onChange(
                    produce(value, (draft) => {
                        draft.splice(index, 1);
                    })
                );
        }
    }

    type ItemProps = {
        item: T;
        onRemove: (item: T) => void;
        disabled: boolean;
    };

    function Item(props: ItemProps) {
        const { item, onRemove, disabled } = props;
        return (
            <div className={styles.item}>
                <div>{getOptionLabel(item)}</div>
                <div className={styles.item__close}>
                    <button
                        onClick={handleRemove}
                        style={{ cursor: disabled && "default" }}
                    >
                        <SVGIcon name="close" />
                    </button>
                </div>
            </div>
        );

        function handleRemove() {
            if (disabled) {
                return;
            }

            onRemove(item);
        }
    }
}
