import React from 'react';
import { Translate } from 'react-translate.ts';

import { ArrayHelper } from '../../../_helpers/ArrayHelper';
import { JobList } from '../..';
import { Panel } from './Panel';

export function Jobs({ jobs, displayOnly = true, heading }) {
    return (
        <Panel heading={heading}>
            <Inner />
        </Panel>
    );

    function Inner() {
        const hasJobs = !ArrayHelper.isEmpty(jobs);

        if (!hasJobs) {
            return <Translate>common.company.profile.jobs.noData</Translate>;
        }

        return <JobList jobs={jobs} displayOnly={displayOnly} />;
    }
}
