import { Link } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import styles from "./BackButton.module.scss";

type BackButtonProps = {
    link?: string;
    onClick?: () => void;
};

export function BackButton(props: BackButtonProps) {
    const { link, onClick } = props;

    const translate = useTranslate();

    const content = (
        <>
            <img
                className={styles.button__arrow}
                src="/img/icons/arrow-up.svg"
                alt=""
            />
            <strong>{translate("common.actions.back")}</strong>
        </>
    );

    if (!!link) {
        return (
            <Link to={link} className={styles.button} onClick={onClick}>
                {content}
            </Link>
        );
    }

    return (
        <button className={styles.button} onClick={onClick}>
            {content}
        </button>
    );
}
