import { createSelector } from "reselect";

import { STATE_LOADING } from "../../common/_constants/state.constants";

const profile = (state) => state.candidate.heroes.profile;

const getProfile = createSelector(profile, (profile) => profile.data);

const getFirstName = createSelector(getProfile, (data) => data.firstName);

const getName = createSelector(
    getProfile,
    (data) => `${data.firstName} ${data.lastName}`
);

const getImages = createSelector(getProfile, (data) => ({
    imageUrl: data.imageUrl,
    originalImageIUrl: data.originalImageIUrl,
}));

const getInfo = createSelector(getProfile, (data) => data.info);

const getAdvice = createSelector(getProfile, (data) => data.answers);

const getMatch = createSelector(getProfile, (data) => data.match);

const getMatches = createSelector(getProfile, (data) => data.matches);

const getVideo = createSelector(getProfile, (data) => data.video);

const isLoading = createSelector(
    profile,
    (profile) => profile.loading === STATE_LOADING
);

const getSteps = createSelector(getProfile, (data) => data.stepsCompleted);

const isPublished = createSelector(profile, (profile) => profile.published);

const HeroProfileSelectors = {
    getProfile,
    getInfo,
    getAdvice,
    getMatch,
    getMatches,
    getVideo,
    isLoading,
    getName,
    getImages,
    getSteps,
    getFirstName,
    isPublished,
};

export default HeroProfileSelectors;
