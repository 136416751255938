import { createSlice } from "@reduxjs/toolkit";
import CookieConfigService from "../_services/CookieConfigService";

const NAME = "COOKIE_CONFIG";

type State = {
    config: number;
    consent: boolean;
};

const initialState: State = {
    config: CookieConfigService.getConfig(),
    consent: CookieConfigService.hasConsent(),
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setConfig: (state, action) => {
            state.config = action.payload;
        },
        setConsent: (state) => {
            state.consent = true;
        },
    },
});

const CookieConfigActions = slice.actions;

export default CookieConfigActions;
export const CookieConfigReducer = slice.reducer;
