import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { generatePath, useParams } from "react-router";
import { Translate, useTranslate } from "react-translate.ts";

import BenefitsEditPanel from "../AdminEdit/parts/BenefitsEditPanel";
import HardSkillsEditPanel from "./parts/HardSkillsEditPanel";
import SoftSkillsEditPanel from "./parts/SoftSkillsEditPanel";
import LanguageEditPanel from "./parts/LanguageEditPanel";
import DetailsEditPanel from "./parts/DetailsEditPanel";
import QualificationEditPanel from "./parts/QualificationEditPanel";
import InfoEditPanel from "./parts/InfoEditPanel/InfoEditPanel";
import BaseAdminJob from "../BaseAdminJob/BaseAdminJob";
import AdminJobActions from "../../_actions/AdminJobActions";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import SalaryEditPanel from "./parts/SalaryEditPanel";
import { LoadingOverlay } from "../../../common/components";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import JobExperiencePanel from "./parts/JobExperiencePanel";
import { ADMIN_JOB_PREVIEW_PATH } from "../../../common/_constants/route.constants";

export default function AdminJobEdit(props: { id: number }) {
    const translate = useTranslate("admin.jobs.edit");

    const params = useParams<{ id: string }>();
    const jobPostId = parseInt(params.id, 10);
    const { data: job, loading, sending } = AdminJobSelectors.useGetEdit();
    const skills = job?.idealCandidate?.skills;
    AdminJobActions.useGetJobPost(jobPostId);
    const dispatch = useDispatch();
    const pending = AdminJobSelectors.useGetPending();

    const hardSkills = useMemo(
        () => skills?.filter((item) => item.skills?.[0]?.type === "hard"),
        [skills]
    );
    const softSkills = useMemo(
        () => skills?.filter((item) => item.skills?.[0]?.type === "soft"),
        [skills]
    );
    return (
        <BaseAdminJob
            id={props.id}
            heading={
                !loading ? (
                    job?.title ? (
                        translate("title", {
                            title: job.title,
                        })
                    ) : (
                        <Translate>admin.jobs.list.newJob</Translate>
                    )
                ) : (
                    <Skeleton height={36} width={350} />
                )
            }
            buttonProps={{ children: translate("publish") }}
            onSave={publishJob}
            previewPath={generatePath(ADMIN_JOB_PREVIEW_PATH, {
                id: jobPostId,
            })}
        >
            {loading ? (
                <div style={{ height: 600 }}>
                    <LoadingOverlay isLoading={loading} isFull />
                </div>
            ) : (
                <>
                    <DetailsEditPanel jobPostId={jobPostId} />
                    <SalaryEditPanel jobPostId={jobPostId} />
                    <JobExperiencePanel jobPostId={jobPostId} />
                    <QualificationEditPanel
                        qualifications={
                            job?.idealCandidate?.qualificationItems || []
                        }
                        jobPostId={jobPostId}
                    />
                    <HardSkillsEditPanel
                        skills={hardSkills || []}
                        jobPostId={jobPostId}
                    />
                    <SoftSkillsEditPanel
                        skills={softSkills}
                        jobPostId={jobPostId}
                    />
                    <LanguageEditPanel
                        languages={job?.idealCandidate?.languages || []}
                        jobPostId={jobPostId}
                    />
                    <BenefitsEditPanel
                        benefits={job?.jobBenefits || []}
                        anchor="benefits"
                        onSubmit={(values) => {
                            dispatch(
                                AdminJobActions.saveBenefit({
                                    jobPostId,
                                    ...values,
                                })
                            );
                        }}
                        onDelete={deleteBenefit}
                        pending={pending.benefits}
                    />
                    <InfoEditPanel
                        jobPostId={jobPostId}
                        info={job?.jobOtherInfos}
                        loading={sending}
                    />
                </>
            )}
        </BaseAdminJob>
    );

    function deleteBenefit(id: number) {
        dispatch(AdminJobActions.deleteBenefit({ jobPostId, id }));
    }

    function publishJob() {
        dispatch(AdminJobActions.publishJob({ jobPostId }));
    }

    function unpublishJob() {
        dispatch(AdminJobActions.unpublishJob({ jobPostId }));
    }
}
