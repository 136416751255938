import React from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";

import { Button } from "../../../../../../common/components";
import { CareerPathsActions } from "../../../../../_actions";
import { CAREER_PATHS_PATH } from "../../../../../_constants/route.constants";

export function MapFail() {
    const dispatch = useDispatch();

    const { industries, profileType } = useSelector(mapState);
    const translate = useTranslate("candidate.mapFail");

    return (
        <div className="career-map-fail">
            <div>
                <div className="image">
                    <Player
                        autoplay
                        loop
                        src="/img/career-paths/fail-anim.json"
                        style={{ width: "330px", height: "320px" }}
                    />
                    {/* <img src="/img/career-paths/files.svg" alt="" /> */}
                </div>
                <div
                    className="title font-book"
                    dangerouslySetInnerHTML={{ __html: translate("title") }}
                />
                <div className="subtitle">{translate("subtitle")}</div>
                <Link to={CAREER_PATHS_PATH}>
                    <Button onClick={reset}>Show industry</Button>
                </Link>
            </div>
        </div>
    );

    function reset() {
        dispatch(CareerPathsActions.setIndustry({ id: 1, name: "IT" }));
        dispatch(CareerPathsActions.setSearchSelected(null));
        dispatch(CareerPathsActions.setInputValue(""));
        dispatch(CareerPathsActions.setSelectedNode(null));
        dispatch(CareerPathsActions.setActiveSearch(false));
        dispatch(CareerPathsActions.setActiveRouteSearch(false));
    }
}

function mapState(state) {
    return {};
}
