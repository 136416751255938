import { MutableRefObject, useRef, useState } from "react";

export function useFreshState<T extends any>(
    initialValue: T
): [MutableRefObject<T>, (value: T) => void] {
    const [state, setState] = useState<T>(initialValue);
    const ref = useRef<T>(state);

    return [
        ref,
        (value: T) => {
            ref.current = value;
            setState(value);
        },
    ];
}
