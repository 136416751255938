import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';

const defaultState = {
    industry: null,
    country: { id: 1, name: 'GB' },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CareerPathsConstants.SET_INDUSTRY:
            return Object.assign({}, state, { industry: action.payload });
        case CareerPathsConstants.SET_COUNTRY:
            return Object.assign({}, state, { country: action.payload });
        default:
            return state;
    }
};
