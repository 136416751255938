import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { NumberSchema, ObjectSchema } from "yup";

import Field from "../../../../common/components/input/Field/Field";
import FormInput from "../../../../common/components/input/FormInput";
import FormSelect from "../../../../common/components/input/FormSelect";
import RangeField from "../../../../common/components/input/RangeField/RangeField";
import ICurrency from "../../../../common/types/ICurrency";
import IID from "../../../../common/types/IID";
import AdminJobActions from "../../../_actions/AdminJobActions";
import AdminProfileActions from "../../../_actions/AdminProfileAction";
import AdminJobSelectors from "../../../_selectors/AdminJobSelectors";
import AdminProfileSelectors from "../../../_selectors/AdminProfileSelectors";
import AdminEdit from "../../AdminEdit/AdminEdit";
import UploadButton from "./UploadButton";

type SalaryEditPanelProps = {
    jobPostId: number;
};

const CURRENCY_FIELD = "currency";
const MIN_SALARY_FIELD = "minSalary";
const MAX_SALARY_FIELD = "maxSalary";

const WORKLOAD_FIELD = "workload";
const MIN_WORK_FIELD = "workLoadMin";
const MAX_WORK_FIELD = "workLoadMax";

const TYPE_FIELD = "type";

type Values = {
    [CURRENCY_FIELD]: ICurrency;
    [MIN_SALARY_FIELD]: number;
    [MAX_SALARY_FIELD]: number;
    [TYPE_FIELD]: IID & { name: string };
    [WORKLOAD_FIELD]: { id: string; name: string };
    [MIN_WORK_FIELD]: number;
    [MAX_WORK_FIELD]: number;
};

export default function SalaryEditPanel(props: SalaryEditPanelProps) {
    const { jobPostId } = props;
    const translate = useTranslate("admin.jobs.edit.salary");

    const { data, loading } = AdminJobSelectors.useGetEdit();
    const dispatch = useDispatch();

    const initialValues: Values = {
        [CURRENCY_FIELD]: data?.salaryRange?.currency,
        [MIN_SALARY_FIELD]: data?.salaryRange?.amountMin,
        [MAX_SALARY_FIELD]: data?.salaryRange?.amountMax,
        [TYPE_FIELD]: data?.salaryRange?.type,
        [WORKLOAD_FIELD]: {
            id: data?.workLoadMetric,
            name: data?.workLoadMetric,
        },
        [MIN_WORK_FIELD]: data?.workLoadMin,
        [MAX_WORK_FIELD]: data?.workLoadMax,
    };

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={getValidationSchema}
            >
                <Form>
                    <InnerForm />
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function getValidationSchema() {
        return new ObjectSchema({
            [MIN_SALARY_FIELD]: new NumberSchema(),
            [MAX_SALARY_FIELD]: new NumberSchema(),
            [MIN_WORK_FIELD]: new NumberSchema(),
            [MAX_WORK_FIELD]: new NumberSchema(),
            [CURRENCY_FIELD]: new ObjectSchema({
                id: new NumberSchema(),
            })
                .nullable()
                .required(),
        });
    }

    function submit(values: Values) {
        const { minSalary, maxSalary, currency, type, workload, ...rest } =
            values;
        const payload = {
            id: jobPostId,
            salaryRange: {
                currency,
                type,
                amountMax: maxSalary,
                amountMin: minSalary,
            },
            workLoadMetric: workload?.id,
            ...rest,
        };
        dispatch(AdminJobActions.editSalary(payload));
    }
}

function InnerForm() {
    const dispatch = useDispatch();
    const translate = useTranslate("admin.jobs.edit.salary.form");

    const { sending } = AdminJobSelectors.useGetEdit();

    const { data: currencies, loading: currenciesLoading } =
        AdminProfileSelectors.useGetCurrencies();

    const { data: salaryOptions, loading: salariesLoading } =
        AdminJobSelectors.useGetSalaryTypes();

    const { data: workloads, loading: workloadsLoading } =
        AdminJobSelectors.useGetWorkloads();

    useEffect(() => {
        if (!currencies) {
            dispatch(AdminProfileActions.getCurrencies());
        }

        if (!salaryOptions) {
            dispatch(AdminJobActions.getSalaryTypes());
        }

        if (!workloads) {
            dispatch(AdminJobActions.getWorkloads());
        }
    }, [dispatch, currencies, salaryOptions, workloads]);

    return (
        <>
            <Field label={translate("currency.label")}>
                <FormSelect<ICurrency>
                    name={CURRENCY_FIELD}
                    placeholder={translate("currency.placeholder")}
                    options={currencies}
                    loading={currenciesLoading}
                    getOptionLabel={(option) =>
                        `${option.code} ${option.symbol}`
                    }
                />
            </Field>
            <Field label={translate("type.label")}>
                <FormSelect
                    name={TYPE_FIELD}
                    placeholder={translate("type.placeholder")}
                    options={salaryOptions}
                    loading={salariesLoading}
                    getOptionLabel={(option) => option.name}
                />
            </Field>
            <RangeField
                label={translate("range.label")}
                from={
                    <FormInput
                        name={MIN_SALARY_FIELD}
                        placeholder="1300"
                        type="number"
                    />
                }
                to={
                    <FormInput
                        name={MAX_SALARY_FIELD}
                        placeholder="3200"
                        type="number"
                    />
                }
            />

            <Field.Divider />

            <Field label={translate("workload.label")}>
                <FormSelect
                    name={WORKLOAD_FIELD}
                    placeholder={translate("workload.placeholder")}
                    options={workloads}
                    loading={workloadsLoading}
                    getOptionLabel={(option) => option.name}
                />
            </Field>
            <RangeField
                label={translate("workloadRange.label")}
                from={
                    <FormInput
                        name={MIN_WORK_FIELD}
                        placeholder="20"
                        type="number"
                    />
                }
                to={
                    <FormInput
                        name={MAX_WORK_FIELD}
                        placeholder="40"
                        type="number"
                    />
                }
            />
            <UploadButton sending={sending} />
        </>
    );
}
