import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";

import IBaseState from "../../common/types/IBaseState";
import {
    STATE_FAIL,
    STATE_LOADING,
    STATE_SUCCESS,
} from "../../common/_constants/state.constants";

export const ReducerHelpers = {
    loading,
    pending,
    fulfilled,
    rejected,
};

function loading(state: any = null, action: any, type: any) {
    if (Array.isArray(type)) {
        for (let i in type) {
            const current = type[i];

            const result = reduce(current);

            if (result) {
                return result;
            }
        }

        return state;
    }

    return reduce(type) || state;

    function reduce(_type: any) {
        switch (action.type) {
            case _type:
                return STATE_LOADING;
            case `${_type}_SUCCESS`:
                return STATE_SUCCESS;
            case `${_type}_FAIL`:
                return STATE_FAIL;
            default:
                return null;
        }
    }
}

function pending<T>(state: WritableDraft<IBaseState<T>>) {
    state.loading = true;
}

function fulfilled<T>(
    state: WritableDraft<IBaseState<T>>,
    action: PayloadAction<{ status: number; data: T }>
) {
    state.loading = false;
    if (action.payload) {
        (state.data as T) = action.payload.data;
    }
    state.timestamp = Date.now();
}

function rejected<T>(state: WritableDraft<IBaseState<T>>) {
    state.loading = false;
}
