import IError from "../../common/types/IError";
import RequestService2 from "../../common/_services/RequestService2";

const CompanyService = {
    getList
};

export type GetCompanyListPayload = {
    filters: { title: string };
    page: number;
};

async function getList(payload: GetCompanyListPayload, onFail: (error: IError) => void) {
    const { filters, page } = payload;
    const { title } = filters;

    try {

        const response =  await RequestService2.get("api/companies", {
            page,
            title,
            size: 10
        });

        return response.data;
    } catch(ex) {
        onFail({message: ex, status: 0});
    }
}

export default CompanyService;
