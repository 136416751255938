const HeroProfileConstants = {
    PUBLISH: "HERO__PUBLISH",
    PUBLISH_SUCCESS: "HERO__PUBLISH_SUCCESS",
    PUBLISH_FAIL: "HERO__PUBLISH_FAIL",

    UNPUBLISH: "HERO__UNPUBLISH",
    UNPUBLISH_SUCCESS: "HERO__UNPUBLISH_SUCCESS",
    UNPUBLISH_FAIL: "HERO__UNPUBLISH_FAIL",

    GET_PROFILE: "HERO__GET_PROFILE",
    GET_PROFILE_SUCCESS: "HERO__GET_PROFILE_SUCCESS",
    GET_PROFILE_FAIL: "HERO__GET_PROFILE_FAIL",

    GET_PROFILE_PICTURE: "HERO__GET_PROFILE_PICTURE",
    GET_PROFILE_PICTURE_SUCCESS: "HERO__GET_PROFILE_PICTURE_SUCCESS",
    GET_PROFILE_PICTURE_FAIL: "HERO__GET_PROFILE_PICTURE_FAIL",

    SAVE_PROFILE_PICTURE: "HERO__SAVE_PROFILE_PICTURE",
    SAVE_PROFILE_PICTURE_SUCCESS: "HERO__SAVE_PROFILE_PICTURE_SUCCESS",
    SAVE_PROFILE_PICTURE_FAIL: "HERO__SAVE_PROFILE_PICTURE_FAIL",

    GET_SKILLS: "HERO__GET_SKILLS",
    GET_SKILLS_SUCCESS: "HERO__GET_SKILLS_SUCCESS",
    GET_SKILLS_FAIL: "HERO__GET_SKILLS_FAIL",

    GET_SOFT_SKILLS_RESULTS: "HERO__GET_SOFT_SKILLS_RESULTS",
    GET_SOFT_SKILLS_RESULTS_SUCCESS: "HERO__GET_SOFT_SKILLS_RESULTS_SUCCESS",
    GET_SOFT_SKILLS_RESULTS_FAIL: "HERO__GET_SOFT_SKILLS_RESULTS_FAIL",
};

export default HeroProfileConstants;
