import { PropsWithChildren } from "react";
import BasicPageLayout from "../../../../common/components/layout/BasicPageLayout/BasicPageLayout";
import CompanyHeader from "../../nav/CompanyHeader/CompanyHeader";
import CompanyMobileNav from "../../nav/CompanyMobileNav/CompanyMobileNav";
import CompanySideBar from "../../nav/CompanySideBar/CompanySideBar";

type CompanyPageLayoutProps = PropsWithChildren<{
    headerProps?: any;
}>;

export default function CompanyPageLayout(props: CompanyPageLayoutProps) {
    const { children } = props;
    return (
        <BasicPageLayout
            header={<CompanyHeader />}
            sideBar={<CompanySideBar />}
            mobileBar={<CompanyMobileNav />}
        >
            {children}
        </BasicPageLayout>
    );
}
