import { RequestService } from '../../common/_services';

const ProfileInformationService = {
    updateAboutMe,
    addExperience,
    updateExperience,
    deleteExperience,
    addEducation,
    updateEducation,
    deleteEducation,
    addInterest,
    updateInterest,
    deleteInterest,
    saveLanguages,
    saveSkills,
};

export default ProfileInformationService;

function updateAboutMe(payload) {
    const { profileId, ...rest } = payload;

    return RequestService.put(`profiles/${profileId}/abouts`, {
        ...rest,
    });
}

function addExperience(payload) {
    const { experience, profileId } = payload;

    return RequestService.post(`profiles/${profileId}/job-experience`, {
        ...experience,
        profileId,
    });
}

function updateExperience(payload) {
    const { experience, profileId } = payload;

    return RequestService.put(
        `profiles/${profileId}/job-experience/${experience.id}`,
        { ...experience, profileId }
    );
}

function deleteExperience(payload) {
    const { experienceId, profileId } = payload;

    return RequestService.delete(
        `api/profiles/${profileId}/job-experience/${experienceId}`
    );
}

function addEducation(payload) {
    const { education, profileId } = payload;

    return RequestService.post(`profiles/${profileId}/education`, {
        ...education,
        profileId,
    });
}

function updateEducation(payload) {
    const { education, profileId } = payload;

    return RequestService.put(
        `profiles/${profileId}/education/${education.id}`,
        {
            ...education,
            profileId,
        }
    );
}

function deleteEducation(payload) {
    const { educationId, profileId } = payload;

    return RequestService.delete(
        `profiles/${profileId}/education/${educationId}`
    );
}

function addInterest(payload) {
    const { interest, profileId } = payload;
    return RequestService.post(`profiles/${profileId}/interests`, interest);
}

function updateInterest(payload) {
    const { interest, profileId } = payload;
    return RequestService.put(
        `profiles/${profileId}/interests/${interest.id}`,
        interest
    );
}

function deleteInterest(payload) {
    const { interestId, profileId } = payload;
    return RequestService.delete(
        `profiles/${profileId}/interests/${interestId}`
    );
}

function saveLanguages(payload) {
    const { languages, profileId } = payload;

    const params = languages.map((item) => ({
        languageId: item.language.id,
        languageLevelId: item.level.id,
    }));
    return RequestService.post(`profiles/${profileId}/languages`, params);
}

function saveSkills(payload) {
    const { skills, profileId } = payload;

    return RequestService.post(
        `profiles/${profileId}/skills`,
        skills.map(mapper)
    );

    function mapper({ timeYearCount, level, interest, skill }) {
        return { timeYearCount, level, interest, skillId: skill.id, profileId };
    }
}
