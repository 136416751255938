import { Link } from "react-router-dom";
import { Translate, useTranslate } from "react-translate.ts";
import { useSelector } from "react-redux";
import { createClass } from "classname-helper";

import { ProfileItemCard } from "../ProfileItemCard";
import { EditButton } from "../../buttons";
import { JobMatch } from "../../jobs";
import ProfileInformationSelectors from "../../../../candidate/_selectors/ProfileInformationSelectors";

export function AboutMe({ match, displayOnly = false }) {
    const about = useSelector(ProfileInformationSelectors.getAboutMe);

    const hasContent = about && about.content;
    const text = hasContent ? (
        about.content
    ) : (
        <Translate textOnly>
            candidate.profile.information.aboutMe.edit.subheading
        </Translate>
    );

    return (
        <ProfileItemCard
            label="candidate.profile.information.aboutMe.title"
            extraContent={
                <ExtraContent displayOnly={displayOnly} match={match} />
            }
        >
            <p className={createClass({ "text-secondary": !hasContent })}>
                {text}
            </p>
        </ProfileItemCard>
    );
}

function ExtraContent({ displayOnly, match }) {
    const translate = useTranslate();

    if (displayOnly) {
        return (
            <JobMatch
                fit={match}
                label={translate("common.jobs.match.overall")}
                size="small"
            />
        );
    }

    return (
        <Link to="/profile/information/about-me/edit">
            <EditButton />
        </Link>
    );
}
