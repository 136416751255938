import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    COMPLETED_STATUS,
    PROCESSED_STATUS,
} from "../../../../../candidate/_constants/testStatus.constants";
import { MainState } from "../../../../../root.reducer";
import {
    Loading,
    OnboardingButtons,
} from "../../../../components";
import HeroOnboardingActions from "../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import HeroProfileActions from "../../../../_actions/HeroProfileActions";
import { AuthSelectors } from "../../../../_selectors";
import HeroOnboardingSelectors from "../../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import styles from './HeroTestInterpretation.module.scss';
import MopInterpretationContent
    from "../../../../../candidate/pages/Interpretation/Mop/MopInterpretationContent/MopInterpretationContent";

export function HeroTestInterpretation() {
    const { heroProfileId, results, isLoading, status } = useSelector(mapState);
    const dispatch = useDispatch();
    
    useEffect(() => {
        let intervalTest: any = null;
        let intervalStatus: any = null;
        let timeout: any = null;
        if (heroProfileId && status === COMPLETED_STATUS) {
            getTest();
            timeout = setTimeout(getStatus);
            intervalTest = setInterval(getTest, 8000);
            intervalStatus = setInterval(getStatus, 10000);
        } else if (status === PROCESSED_STATUS) {
            cleanup();
        }

        function getTest() {
            dispatch(HeroOnboardingActions.Skills.getTest(heroProfileId));
        }

        function getStatus() {
            dispatch(HeroOnboardingActions.Skills.getStatus(heroProfileId));
        }

        function cleanup() {
            clearInterval(intervalTest);
            intervalTest = null;
            clearInterval(intervalStatus);
            intervalStatus = null;
            clearTimeout(timeout);
            timeout = null;
        }

        return cleanup;
    }, [heroProfileId, dispatch, status]);

    useEffect(() => {
        if (heroProfileId && status === PROCESSED_STATUS) {
            dispatch(
                HeroProfileActions.getSoftSkillsResults(heroProfileId, "mop")
            );
        }
    }, [dispatch, heroProfileId, status]);

    if (isLoading || status !== PROCESSED_STATUS) {
        return <Loading />;
    }

    return (
        <div className={styles.container}>
            <NextButton />
            <MopInterpretationContent
                isLoading={isLoading || !results}
                profiles={results.profiles}
                interpretations={results.skillInterpretations}
                skills={results.softSkills}
            />
            <NextButton />
        </div>
    );

    function NextButton() {
        return (
            <OnboardingButtons.Next
                onClick={() => dispatch(HeroOnboardingActions.addStep(1))}
            />
        );
    }
}

function mapState(state: MainState) {
    return {
        heroProfileId: AuthSelectors.getHeroProfileId(state),
        results: HeroOnboardingSelectors.Skills.getResults(state),
        isLoading: HeroOnboardingSelectors.isLoading(state),
        status: HeroOnboardingSelectors.Skills.getStatus(state),
    };
}
