import { cx } from "@emotion/css";

import IBenefit from "../../types/IBenefit";
import Skeleton from "../Skeleton/Skeleton";
import styles from "./BenefitsGrid.module.scss";

type BenefitsGrid = {
    benefits: IBenefit[];
    isLoading: boolean;
};

export function BenefitsGrid(props: BenefitsGrid) {
    const { benefits, isLoading } = props;

    return (
        <div className={styles.grid}>
            {!isLoading
                ? benefits.map((benefit, i) => (
                      <Benefit
                          key={i}
                          name={benefit.name}
                          logoUrl={benefit.logoUrl}
                          description={benefit.description}
                      />
                  ))
                : Array.from(new Array(4), (_, i) => (
                      <Benefit key={i} isLoading={isLoading} />
                  ))}
        </div>
    );
}

type BenefitProps = {
    name?: string;
    description?: string;
    logoUrl?: string;
    isLoading?: boolean;
};

function Benefit(props: BenefitProps) {
    const { name, description, logoUrl, isLoading } = props;
    return (
        <div className={styles.item}>
            <div className={styles.icon}>
                {!isLoading ? <img src={logoUrl} alt="" /> : <Skeleton />}
            </div>
            <div>
                <div className={cx(styles.name, "font-book")}>
                    {!isLoading ? name : <Skeleton width={180} height={19} />}
                </div>
                <div className={cx(styles.description, "font-light")}>
                    {!isLoading ? (
                        description
                    ) : (
                        <Skeleton
                            width={230}
                            height={14}
                            marginBottom={6}
                            count={3}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
