import React from 'react';

import { MenuItem, Select, InputBase } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { Flex } from '../layout';
import { Translate } from 'react-translate.ts';

export function SmallSelect({
    options,
    label,
    children,
    getOptionLabel,
    getOptionValue,
    value,
    onChange,
    defaultValue,
    ...rest
}) {
    const input = <InputBase {...rest} />;
    return (
        <Flex direction="column" className="small-select">
            <Label label={label} />
            <Select
                value={value === null || value === undefined ? '' : value}
                onChange={onChange}
                defaultValue={defaultValue}
                input={input}
                IconComponent={KeyboardArrowDown}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                }}
            >
                {children}
                {options.map((option, key) => {
                    const value = getOptionValue
                        ? getOptionValue(option)
                        : option.value;
                    const label = getOptionLabel
                        ? getOptionLabel(option)
                        : option.label;
                    return (
                        <MenuItem key={key} value={value}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </Flex>
    );
}

function Label({ label }) {
    if (!label) {
        return null;
    }

    return (
        <Translate className="text-secondary font-14 margin-bottom-5">
            {label}
        </Translate>
    );
}
