import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IJobRole from "../../common/types/IJobRole";

import IPaginationState from "../../common/types/IPaginationState";
import { NotificationActions } from "../../common/_actions";
import JobRoleService2, {
    GetJobRoleListPayload,
} from "../_services/JobRoleService2";

const NAME = "JOB_ROLES";

const getList = createAsyncThunk(
    `${NAME}/getList`,
    (payload: GetJobRoleListPayload, thunk) =>
        JobRoleService2.getJobRoleList(payload, () =>
            thunk.dispatch(
                NotificationActions.pushError("Unable to load job roles")
            )
        )
);
const getIndustryCategories = createAsyncThunk(
    `${NAME}/getIndustryCategories`,
    (_, thunk) =>
        JobRoleService2.getIndustryCategories(() =>
            thunk.dispatch(
                NotificationActions.pushError("Unable to load industries")
            )
        )
);

type FiltersPayload = {
    industryIds: number[];
    title: string;
};

type State = {
    list: {
        data: IJobRole[];
        loading: boolean;
        fail: boolean;
        pagination: IPaginationState;
        filters: FiltersPayload;
    };
    industries: {
        data: [];
        loading: boolean;
        fail: boolean;
        active: number[];
    };
};

const initialState: State = {
    list: {
        data: [],
        loading: false,
        fail: false,
        pagination: {
            page: 0,
            pages: 0,
            elements: 0,
        },
        filters: {
            title: null,
            industryIds: [],
        },
    },
    industries: {
        data: [],
        loading: false,
        fail: false,
        active: [],
    },
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<Partial<FiltersPayload>>) => {
            state.list.filters = { ...state.list.filters, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getList.pending, (state, action) => {
            state.list.loading = true;
            state.list.fail = false;
        });
        builder.addCase(getList.fulfilled, (state, action) => {
            const { content, totalElements, totalPages, pageable } =
                action.payload;
            state.list.data = content;
            state.list.pagination = {
                elements: totalElements,
                pages: totalPages,
                page: pageable.pageNumber,
            };
            state.list.loading = false;
        });
        builder.addCase(getList.rejected, (state) => {
            state.list.loading = false;
            state.list.fail = true;
        });

        builder.addCase(getIndustryCategories.pending, (state, action) => {
            state.industries.loading = true;
            state.industries.fail = false;
        });
        builder.addCase(getIndustryCategories.fulfilled, (state, action) => {
            state.industries.data = action.payload.content;
            state.industries.loading = false;
        });
        builder.addCase(getIndustryCategories.rejected, (state) => {
            state.industries.loading = false;
            state.industries.fail = true;
        });
    },
});

const JobRoleListActions = {
    getList,
    getIndustryCategories,
    ...slice.actions,
};

export default JobRoleListActions;
export const JobRoleListReducer = slice.reducer;
