import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";

const Personal = {
    saveData,
    setImage,
    changeValue,
};

function saveData(firstName, lastName, introduction, imageUrl, linkedInUrl) {
    return {
        type: HeroOnboardingConstants.SAVE_PERSONAL,
        payload: { firstName, lastName, introduction, imageUrl, linkedInUrl },
    };
}

function setImage(convertedImage, originalImage, imageFileName) {
    return {
        type: HeroOnboardingConstants.SET_PERSONAL_IMAGE,
        payload: { convertedImage, originalImage, imageFileName },
    };
}

function changeValue(field, value) {
    return {
        type: HeroOnboardingConstants.CHANGE_PERSONAL_VALUE,
        payload: { field, value },
    };
}

export default Personal;
