import { cx } from "@emotion/css";

import styles from "./VideoIcon.module.scss";

type VideoIconProps = {
    src: string;
    className?: string;
};

export default function VideoIcon(props: VideoIconProps) {
    const { src, className } = props;
    return (
        <div className={cx(styles.icon, className)}>
            <img src={src} alt="" />
        </div>
    );
}
