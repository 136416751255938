import React from "react";

import { ProfileTabs } from "../";
import { RadialProgressChart } from "../../../../../common/components/charts/RadialProgressChart";
import { MathHelper } from "../../../../../common/_helpers";
import { useTranslate } from "react-translate.ts";
import { PROFILE_AVATAR_EDIT_PATH } from "../../../../../common/_constants/route.constants";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserProfileSelectors } from "../../../../../common/_selectors/UserProfileSelectors";
import ProfileSelectors from "../../../../_selectors/ProfileSelectors";

export function ProfileBasicMobile() {
    const { name, headerTitle, percentage, completeness, imageUrl } =
        useSelector(mapState);

    const translate = useTranslate("common.profile");

    return (
        <div id="profile-basic-mobile">
            <div id="profile-basic-mobile__avatar">
                <div id="profile-basic-mobile__avatar__image">
                    <Link to={PROFILE_AVATAR_EDIT_PATH} />
                    <img src={imageUrl || "/img/avatar.svg"} alt="" />
                </div>
                <RadialProgressChart
                    progress={completeness}
                    thickness={5}
                    background="#eaeaea"
                />
            </div>
            <div id="profile-basic-mobile__completion" className="font-book">
                {translate("completed", { completed: percentage })}
            </div>
            <div id="profile-basic-mobile__name">{name}</div>
            <div id="profile-basic-mobile__experience" className="font-book">
                {headerTitle}
            </div>
            <ProfileTabs />
        </div>
    );
}

function mapState(state) {
    return {
        percentage: UserProfileSelectors.getCompletenessPercentage(state),
        completeness: UserProfileSelectors.getProfileCompleteness(state),
        name: ProfileSelectors.getFullName(state),
        imageUrl: ProfileSelectors.getPhoto(state).imageUrl,
        headerTitle: ProfileSelectors.getHeaderTitle(state),
    };
}
