import { useTranslate } from "react-translate.ts";
import { FormatMoneyRange } from "../../../../common/components";
import Skeleton from "../../../../common/components/Skeleton/Skeleton";
import IBenefit from "../../../../common/types/IBenefit";
import styles from "./FloatingCardContent.module.scss";

type FloatingCardContentProps = {
    currency: string;
    salaryMin: number;
    salaryMax?: number;
    payRaise?: string;
    benefits?: IBenefit[];
    stats?: string[];
    isLoading: boolean;
};

export default function FloatingCardContent(props: FloatingCardContentProps) {
    const {
        salaryMin,
        salaryMax,
        currency,
        payRaise,
        benefits,
        stats,
        isLoading,
    } = props;

    const translate = useTranslate("candidate.jobs.detail.fixedPanel");

    return (
        <>
            <div className={styles.salary}>
                {!isLoading ? (
                    (salaryMin || salaryMax) && currency ? (
                        <FormatMoneyRange
                            min={salaryMin}
                            max={salaryMax}
                            currency={currency}
                        />
                    ) : (
                        translate("noSalary")
                    )
                ) : (
                    <Skeleton height={30} width={250} />
                )}
            </div>
            <div className={styles.raise}>
                {!isLoading ? (
                    payRaise && (
                        <>{translate("payRaise", { value: payRaise })}</>
                    )
                ) : (
                    <Skeleton height={13} width={130} />
                )}
            </div>
            <div className={styles.stats}>
                {stats?.map((item, i) => (
                    <div key={i} className={styles.stats__item}>
                        {item}
                    </div>
                ))}
            </div>
            <div className={styles.benefits}>
                {!isLoading ? (
                    <ul>
                        {benefits?.map((item, i) => (
                            <li key={i}>{item.name}</li>
                        ))}
                    </ul>
                ) : (
                    <Skeleton
                        count={4}
                        width={190}
                        height={14}
                        marginBottom={14}
                    />
                )}
            </div>
        </>
    );
}
