import React from 'react';
import { useTranslate, Translate } from 'react-translate.ts';

import { Button, JobMatch } from '../../../../common/components';
import { formatDate } from '../../../../common/_helpers/formatDate';
import { MathHelper } from '../../../../common/_helpers/MathHelper';
import { CandidateActionPanel } from '../../../components';
import { ArrayHelper } from '../../../../common/_helpers/ArrayHelper';

export function CandidateItem({
    candidate,
    onActionClick,
    onDetailClick,
    onInvite,
    onFavourite,
    onReject,
    onSwap,
}) {
    const translate = useTranslate('admin.hire.card');

    const {
        profile,
        scores,
        jobApplicationStatus,
        unlocked,
        isFavourite,
    } = candidate;
    const {
        id,
        firstName,
        lastName,
        headerTitle,
        location,
        jobExperience,
        educations,
        imageUrl,
    } = profile;

    const hasLocations = !!location;

    return (
        <div
            className="candidate-item"
            onClick={() => onDetailClick(candidate.profile.id)}
        >
            <div className="candidate-item--info">
                <div className="candidate-item--info--image">
                    <img src={imageUrl || '/img/avatar.svg'} alt="" />
                </div>
                <div>
                    <div className="candidate-item--info--name">
                        <Name
                            id={id}
                            name={firstName + ' ' + lastName}
                            isLocked={!unlocked}
                        />
                        {jobApplicationStatus !== 'NOT_APPLIED' ? (
                            <span className="candidate-item--info--status">
                                {translate(`status.${jobApplicationStatus}`, {
                                    title: 'SWD',
                                })}
                            </span>
                        ) : (
                            <div />
                        )}
                    </div>
                    <div className="candidate-item--info--title">
                        {headerTitle}
                    </div>
                    {hasLocations ? (
                        <div className="candidate-item--info--location">
                            {location}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="candidate-item--center">
                <div className="candidate-item--records">
                    <Records label="history">
                        {(() => {
                            if (ArrayHelper.isEmpty(jobExperience)) {
                                return null;
                            }

                            const {
                                title,
                                started,
                                ended,
                                company,
                            } = jobExperience[0];

                            return (
                                <Experience
                                    title={title}
                                    institution={company.name}
                                    start={formatDate(started, 'YYYY')}
                                    end={
                                        ended
                                            ? formatDate(ended, 'YYYY')
                                            : translate('present')
                                    }
                                />
                            );
                        })()}
                    </Records>
                    <Records label="education">
                        {(() => {
                            if (ArrayHelper.isEmpty(educations)) {
                                return null;
                            }

                            const {
                                universityDegreeTitle,
                                universityDegreeField,
                                startYear,
                                endYear,
                                university,
                            } = educations[0];

                            return (
                                <Experience
                                    title={`${universityDegreeTitle.title} ${universityDegreeField.name}`}
                                    institution={university.name}
                                    start={startYear}
                                    end={endYear || translate('present')}
                                />
                            );
                        })()}
                    </Records>
                </div>
                <Matches scores={scores} />
            </div>
            <Match scores={scores} status={jobApplicationStatus} />
            <Actions
                onDetail={() => onDetailClick}
                onInvite={() => handleActionClick(onInvite)}
                onSave={() => handleActionClick(onFavourite)}
                onReject={() => handleActionClick(onReject)}
                isSaved={isFavourite}
                status={jobApplicationStatus}
            />
        </div>
    );

    function handleActionClick(action) {
        onActionClick(candidate.profile.id);
        action(candidate);
    }
}

function Actions({
    onDetail,
    onInvite,
    onReject,
    onSwap,
    onSave,
    isSaved,
    status,
}) {
    const translate = useTranslate('admin.hire.card.actions');

    return (
        <div className="candidate-item--actions">
            <CandidateActionPanel
                onInvite={onInvite}
                onSave={onSave}
                onReject={onReject}
                onSwap={() => {}}
                isSaved={isSaved}
                size="small"
                status={status}
            />
            <div>
                <Button variant="secondary" onClick={onDetail}>
                    {translate('detail')}
                </Button>
            </div>
        </div>
    );
}

function Records({ label, children }) {
    return (
        <div className="candidate-item--records--item">
            <span className="candidate-item--records--item--label">
                <Translate textOnly>{`admin.hire.card.${label}`}</Translate>:
            </span>
            <div className="candidate-item--records--item--text">
                {children}
            </div>
        </div>
    );
}

function Experience({ title, institution, start, end }) {
    return (
        <div>
            {`${title}, ${institution} `}
            <span className="candidate-item--records--item--dot">•</span>
            <span className="candidate-item--records--item--date">{` ${start} - ${end}`}</span>
        </div>
    );
}

function Match({ scores }) {
    const { jobMatchScore } = scores;

    return (
        <div className="candidate-item--match">
            <div>
                <JobMatch
                    fit={jobMatchScore}
                    label={
                        <Translate textOnly>
                            common.jobs.match.overall
                        </Translate>
                    }
                    size="medium"
                />
            </div>
        </div>
    );
}

function Matches({ scores }) {
    const {
        jobMatchQualificationScore,
        jobMatchHardSkillScore,
        jobMatchSoftSkillScore,
        jobMatchLanguageScore,
    } = scores;

    return (
        <div className="candidate-item--matches">
            <MatchItem fit={jobMatchQualificationScore} label="qualification" />
            <MatchItem fit={jobMatchHardSkillScore} label="hardSkills" />
            <MatchItem fit={jobMatchSoftSkillScore} label="softSkills" />
            <MatchItem fit={jobMatchLanguageScore} label="language" />
        </div>
    );
}

function MatchItem({ label, fit }) {
    const translate = useTranslate('admin.hire.card.match');

    return (
        <div className="candidate-item--matches--item">
            <span>{translate(label)}</span>
            <strong>{MathHelper.round(fit * 100)}%</strong>
        </div>
    );
}

function Name({ id, name, isLocked }) {
    if (isLocked) {
        return (
            <Translate params={{ id }}>admin.candidate.locked.name</Translate>
        );
    }

    return <div>{name}</div>;
}
