import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, useFormikContext, useField } from "formik";
import { useTranslate } from "react-translate.ts";

import { OnboardingButtons, OnboardingForm } from "../../../components";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import HeroOnboardingSelectors from "../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { HeroBackButton } from "../components/HeroBackButton";
import TextArea from "../../../components/input/TextArea/TextArea";
import FormField from "../../../components/input/FormField";
import { MainState } from "../../../../root.reducer";
import OnboardingLayout from "../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";

export function HeroAdvice() {
    const dispatch = useDispatch();

    const { data, questions } = useSelector(mapState);

    useEffect(() => {
        dispatch(HeroOnboardingActions.Advice.getQuestions());
    }, [dispatch]);

    const initialValues = reduceQuestions(questions, data);

    return (
        <OnboardingLayout
            color="green"
            imageProps={{ src: "/img/heroes/onboard/advice.svg" }}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validate={(values) => {
                    const errors: any = {};

                    for (const i in values) {
                        const field = values[i];
                        const value = field.value;
                        if (
                            value === undefined ||
                            value === null ||
                            value.trim() === ""
                        ) {
                            errors[field.id] = "required";
                        }
                    }

                    return errors;
                }}
                validateOnMount
                enableReinitialize
            >
                <Content />
            </Formik>
        </OnboardingLayout>
    );

    function submit(values: any) {
        dispatch(HeroOnboardingActions.Advice.saveData(Object.values(values)));
    }
}

function reduceQuestions(questions: any, data: any) {
    return questions.reduce((acc: any, value: any) => {
        const itemIndex = data.findIndex(
            (item: any) => item.question.id === value.id
        );
        if (itemIndex >= 0) {
            const item = data[itemIndex];
            acc[value.id] = { ...item.question, value: item.answer };
        } else {
            acc[value.id] = value;
        }

        return acc;
    }, {});
}

function Content() {
    const translate = useTranslate("common.heroOnboarding.steps.advice");

    const { submitForm, isValid } = useFormikContext();

    return (
        <>
            <OnboardingForm>
                <h1 className="font-light">{translate("title")}</h1>
                <InnerForm />
            </OnboardingForm>
            <OnboardingButtons>
                <HeroBackButton />
                <OnboardingButtons.Next
                    onClick={submitForm}
                    disabled={!isValid}
                />
            </OnboardingButtons>
        </>
    );
}

function InnerForm() {
    const translate = useTranslate("common.heroOnboarding.steps.advice.form");

    const { values } = useFormikContext();
    const items = Object.values(values);

    return (
        <Form>
            {items.map((item, i) => (
                <QuestionInput
                    key={i}
                    item={item}
                    placeholder={translate("placeholder")}
                />
            ))}
        </Form>
    );
}

function QuestionInput(props: any) {
    const { item, placeholder } = props;
    const name = item.id;
    const [field, _, helpers] = useField(name);

    return (
        <FormField name={name} label={item.text} required>
            <TextArea
                name={name}
                value={field.value.value}
                onChange={(event: any) => {
                    const value = event.target.value;
                    const newQuestion = Object.assign({}, field.value, {
                        value,
                    });
                    helpers.setValue(newQuestion);
                }}
                rows={4}
                placeholder={placeholder}
            />
        </FormField>
    );
}

function mapState(state: MainState) {
    return {
        data: HeroOnboardingSelectors.Advice.getData(state),
        questions: HeroOnboardingSelectors.Advice.getQuestions(state),
    };
}
