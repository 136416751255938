import React, { Fragment, useState, useEffect } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import { SkillActions } from '../../_actions/SkillActions';
import {
    FlexContainer,
    FlexItem,
    ResponsiveContainer,
    Checkbox
} from '../../../common/components';

export function HardSkillProcessCategories({
    selected,
    onSkillSelect,
    parentId,
    onCategoryOpen,
    shouldReset
}) {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        categoryId: null,
        categoryIndex: null
    });

    const actions = {
        getCategories: () => dispatch(SkillActions.getCategories()),
        getSkills: categoryId => dispatch(SkillActions.getSkills(categoryId))
    };
    const { categories, skills } = useSelector(mapState);

    useEffect(() => {
        actions.getCategories();
    }, []);

    useEffect(() => {
        if (state.categoryId) {
            actions.getSkills(state.categoryId);
        }
    }, [state.categoryId]);

    useEffect(() => {
        if (shouldReset) {
            reset();
        }
    }, [shouldReset]);

    return render();
    function render() {
        return (
            <FlexContainer spacing={2}>
                {categories ? categories.map(renderCategory) : null}
            </FlexContainer>
        );
    }

    function renderCategory(category, index) {
        const isActive = category.id === state.categoryId;

        const arrowClass = 'text-primary font-heavy';
        return (
            <Fragment key={index}>
                <FlexItem size={12} sm={4} className="relative">
                    <div
                        className={`hard-skill-button${
                            isActive ? ' is-active' : ''
                        }`}
                        onClick={selectCategory}
                    >
                        <span>{category.name}</span>
                        {isActive ? (
                            <KeyboardArrowUp className={arrowClass} />
                        ) : (
                            <KeyboardArrowDown className={arrowClass} />
                        )}
                    </div>
                </FlexItem>
                <ResponsiveContainer renderMobile={() => renderSkillsMobile()}>
                    {renderSkills()}
                </ResponsiveContainer>
            </Fragment>
        );

        function selectCategory() {
            if (state.categoryId === category.id) {
                reset();
            } else {
                onCategoryOpen();
                setState({ categoryId: category.id, categoryIndex: index });
            }
        }

        function renderSkillsMobile() {
            const { categoryIndex } = state;

            if (!skills || categoryIndex === null || index !== categoryIndex) {
                return null;
            }

            return (
                <div id="hard-skills-list-container">
                    {skills.map(renderSkill)}
                </div>
            );
        }

        function renderSkills() {
            if (!skills || state.categoryIndex === null) {
                return null;
            }

            const categoryIndex = state.categoryIndex + 3;
            const maxIndex = categories.size;
            const modulo = categoryIndex - (categoryIndex % 3);
            const targetIndex =
                categoryIndex > maxIndex ? maxIndex - 1 : modulo - 1;

            if (index !== targetIndex) {
                return null;
            }

            return (
                <div
                    id="hard-skills-list-container"
                    className={getBorderClassName()}
                >
                    {skills.map(renderSkill)}
                </div>
            );

            function getBorderClassName() {
                const indexModulo = categoryIndex % 3;

                switch (indexModulo) {
                    case 0:
                        return 'left-corner-hard';
                    case 2:
                        return 'right-corner-hard';
                    default:
                        return '';
                }
            }
        }
    }

    function renderSkill(skill, index) {
        const isChecked = selected && selected.find(id => id === skill.id);
        return (
            <div key={index} className="w100 w25-sm padding-10">
                <Checkbox onChange={onChange} isChecked={isChecked}>
                    {/*<img className="skill-icon" src={skill.imageUrl} alt="" />*/}
                    <label>{skill.name}</label>
                </Checkbox>
            </div>
        );

        function onChange() {
            onSkillSelect(skill, index);
        }
    }

    function reset() {
        setState({ categoryId: null, categoryIndex: null });
    }

    /**
     *
     * @param {*} state
     * @return {{categories: List, skills: Array}}
     */
    function mapState(state) {
        const { categories, skills } = state.candidate.skills;
        return {
            categories: categories.filter(
                category => category.parentId === parentId
            ),
            skills
        };
    }
}
