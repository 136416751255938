const ProfileConstants = {
    GET_PROFILE: 'PROFILE__GET_PROFILE',
    GET_PROFILE_SUCCESS: 'PROFILE__GET_PROFILE_SUCCESS',
    GET_PROFILE_FAIL: 'PROFILE__GET_PROFILE_FAIL',
    UPLOAD_PHOTO: 'PROFILE__UPLOAD_PHOTO',
    UPLOAD_PHOTO_SUCCESS: 'PROFILE__UPLOAD_PHOTO_SUCCESS',
    UPLOAD_PHOTO_FAIL: 'PROFILE__UPLOAD_PHOTO_FAIL',
};

export default ProfileConstants;
