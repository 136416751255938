import React, { useState } from 'react';
import { useTranslate } from 'react-translate.ts';

import { ConfirmDialog } from '../../../common/components/dialogs/ConfirmDialog';
import { TooltipLabel } from '../TooltipLabel';

export function DeleteButton({ onClick, onDelete, translatePrefix }) {
    const [dialogVisible, setDialogVisible] = useState(false);
    const translate = useTranslate();

    return (
        <>
            <TooltipLabel label={translate('common.actions.delete')}>
                <div className="delete-button" onClick={handleDelete}>
                    <img src="/img/icons/delete.svg" alt="" />
                </div>
            </TooltipLabel>
            <ConfirmDialog
                isOpen={dialogVisible}
                onConfirm={_delete}
                onCancel={close}
                label={`${translatePrefix}.label`}
                confirmText={`${translatePrefix}.button`}
                title={`${translatePrefix}.title`}
            />
        </>
    );

    function handleDelete(...args) {
        if (onDelete) {
            open();
        }

        onClick && onClick(...args);
    }

    function open() {
        setDialogVisible(true);
    }

    function close() {
        setDialogVisible(false);
    }

    function _delete() {
        close();
        onDelete();
    }
}
