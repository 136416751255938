import { PropsWithChildren, useMemo, useState } from "react";
import { cx } from "@emotion/css";

import { SVGIcon } from "../..";
import { useFiltersContext } from "../../../../candidate/pages/JobListPage/Filters/parts/FiltersContext";
import ActionDropdown from "../../dropdown/ActionDropdown/ActionDropdown";

import styles from "./FiltersWrapper.module.scss";
import { Translate, useTranslate } from "react-translate.ts";

type FiltersWrapperProps = {};

export default function FiltersWrapper(
    props: PropsWithChildren<FiltersWrapperProps>
) {
    const { children } = props;

    return <div className={styles.wrapper}>{children}</div>;
}

type BaseFilterProps = {
    label: string | JSX.Element;
    active?: boolean;
};

interface FilterButtonProps extends BaseFilterProps {
    count?: number;
    onCancel?: () => void;
    onConfirm?: () => void;
}

function FilterButton(props: PropsWithChildren<FilterButtonProps>) {
    const {
        label,
        children,
        count,
        active,
        onCancel,
        onConfirm: _onConfirm,
    } = props;
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);

    const icon = useMemo(
        () => (isOpen ? "chevron-right" : "chevron-down"),
        [isOpen]
    );

    const { onConfirm } = useFiltersContext();

    return (
        <div
            className={cx(styles.filter, active ? styles.filter__active : null)}
        >
            <button className={styles.pill} onClick={toggle}>
                <span>{label}</span>
                {count ? (
                    <div className={styles.badge}>
                        <div>
                            {count <= 9 ? (
                                count
                            ) : (
                                <>
                                    9<sub>+</sub>
                                </>
                            )}
                        </div>
                    </div>
                ) : null}
                <SVGIcon name={icon} />
            </button>
            {isOpen ? (
                <ActionDropdown
                    onClose={close}
                    onCancel={close}
                    onConfirm={confirm}
                    confirmButtonProps={{
                        label: translate("common.actions.show"),
                    }}
                >
                    {children}
                </ActionDropdown>
            ) : null}
        </div>
    );

    function toggle() {
        setIsOpen((prev) => !prev);
    }

    function confirm() {
        if (_onConfirm) {
            _onConfirm();
        } else {
            onConfirm();
        }
        setIsOpen(false);
    }

    function close() {
        onCancel && onCancel();
        setIsOpen(false);
    }
}

interface FilterCheckProps extends BaseFilterProps {
    onChange?: (active: boolean) => void;
}

function FilterCheck(props: FilterCheckProps) {
    const { label, active, onChange } = props;

    return (
        <div
            className={cx(styles.filter, active ? styles.filter__active : null)}
        >
            <button className={styles.pill} onClick={toggle}>
                {label}
            </button>
        </div>
    );

    function toggle() {
        onChange(!active);
    }
}

FiltersWrapper.Button = FilterButton;
FiltersWrapper.Check = FilterCheck;
