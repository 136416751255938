import { RequestService } from "./RequestService";
import { StorageService } from "./StorageService";
import NotificationHelpers from "../_helpers/NotificationHelpers";

const NOTIFICATIONS_KEY = "notifications";

export const NotificationService = {
    getNotifications,
    getNotificationViews,
    getNotificationsCount,
    getNotificationViewsCount,
    getLocalNotifications,
    addLocalNotification,
    removeLocalNotification,
    clearLocalNotifications,
};

function getNotifications(page) {
    return RequestService.get(`notifications`, [{ name: "page", value: page }]);
}

function getNotificationViews(page) {
    return RequestService.get("notifications", [
        { name: "page", value: page },
        { name: "types", value: "CANDIDATE_PROFILE_VIEW" },
    ]);
}

function getNotificationsCount() {
    return RequestService.get(`notifications/count`);
}

function getNotificationViewsCount() {
    return RequestService.get(
        `notifications/count?types=CANDIDATE_PROFILE_VIEW`
    );
}

function getLocalNotifications() {
    const notifications = StorageService.getLocal(NOTIFICATIONS_KEY) || [];

    return notifications.filter((value) => !!value);
}

function addLocalNotification(notification) {
    const notifications = getLocalNotifications();

    StorageService.setLocal(
        NOTIFICATIONS_KEY,
        NotificationHelpers.addNotification(notification, notifications)
    );
}

function removeLocalNotification(timestamp) {
    const notifications = getLocalNotifications();

    StorageService.setLocal(
        NOTIFICATIONS_KEY,
        NotificationHelpers.removeNotification(timestamp, notifications)
    );
}

function clearLocalNotifications() {
    StorageService.removeLocal(NOTIFICATIONS_KEY);
}
