import { PropsWithChildren } from "react";
import {
    NavLink,
    generatePath,
    Switch,
    Route,
    useLocation,
} from "react-router-dom";

import IBenefit from "../../../common/types/IBenefit";
import ICompany from "../../../common/types/ICompany";
import {
    IJobApplicationStatus,
    IOtherJobInfo,
    IQualificationRequirements,
} from "../../../common/types/IJobDetail";
import ILanguageScore from "../../../common/types/ILanguageScore";
import ILocation from "../../../common/types/ILocation";
import styles from "./JobDetail.module.scss";
import JobDetailContent from "../JobDetailContent/JobDetailContent";
import { Panel } from "../../../common/components/Panel/Panel";
import {
    JOB_DETAIL_COMPANY_PATH,
    JOB_DETAIL_PATH,
} from "../../../common/_constants/route.constants";
import CompanyDetailContent from "../../../common/components/CompanyDetailContent/CompanyDetailContent";
import IJobCard from "../../../common/types/IJobCard";
import IID from "../../../common/types/IID";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { AuthSelectors, JobDetailSelectors } from "../../../common/_selectors";
import { JobDetailActions } from "../../../common/_actions";
import ActiveButton from "../../../common/components/buttons/ActiveButton/ActiveButton";
import { IJobMatchSkillScores } from "../../../common/types/IJobMatch";
import usePage from "../../../common/hooks/usePage";
import { useTranslate } from "react-translate.ts";
import Button from "../../../common/components/buttons/Button/Button";

type CompanyDetail = {
    description: string;
    followed: boolean;
    founded: string;
    headquarterLocation: ILocation;
    images: (IID & { url: string; publicUrl: string })[];
    industries: { name: string; description: string }[];
    benefits: IBenefit[];
    jobs: IJobCard[];
    locations: ILocation[];
    logoUrl: string;
    name: string;
    url: string;
    videoId: string;
    videoProvider: string;
    employees: string;
};

type JobDetailProps = {
    jobPostId: number;
    title: string;
    company: ICompany; // { name: string; description: string; icon: string; };
    location: ILocation;
    employmentType: string;
    match: number;
    isFavourite: boolean;
    hardSkillsMatch: number;
    softSkillsMatch: number;
    qualificationsMatch: number;
    languagesMatch: number;
    skills: IJobMatchSkillScores;
    languages: ILanguageScore[];
    jobBenefits: IBenefit[];
    applicationStatus: IJobApplicationStatus;
    payRaise: string;
    startDate: string;
    qualifications: IQualificationRequirements[];
    info: IOtherJobInfo[];
    salaryMin: number;
    salaryMax: number;
    currency: string;
    companyDetail: CompanyDetail;
    isLoading: boolean;
    onApply: () => void;
    hasApplied: boolean;
    hasShortlisted: boolean;
    isInvited: boolean;
    companyIsLoading: boolean;
    displayOnly?: boolean;
    description: string;
    responsibilities: [];
    applyUrl?: string;
    careerMap: any;
};

export function JobDetail(props: JobDetailProps) {
    const {
        jobPostId,
        title,
        company,
        location,
        employmentType,
        match,
        isFavourite,
        languages,
        jobBenefits,
        hardSkillsMatch,
        softSkillsMatch,
        qualificationsMatch,
        languagesMatch,
        skills,
        qualifications,
        info,
        salaryMin,
        salaryMax,
        currency,
        payRaise,
        companyDetail,
        isLoading,
        applicationStatus,
        companyIsLoading,
        displayOnly = false,
        description,
        responsibilities,
        applyUrl,
        careerMap,
    } = props;

    const {
        founded,
        headquarterLocation,
        images,
        industries,
        benefits,
        jobs,
        locations,
        url,
        videoId,
        videoProvider,
        employees,
    } = companyDetail;

    const profileId = useSelector(AuthSelectors.getProfileId);
    const dispatch = useDispatch();
    const page = usePage();
    const routeLocation = useLocation();
    const translate = useTranslate("candidate.jobs.detail");

    return (
        <div>
            <HeadPanel
                isLoading={isLoading}
                title={title}
                onApply={apply}
                onAccept={accept}
                status={applicationStatus}
                displayOnly={displayOnly}
                applyUrl={applyUrl}
            >
                {company?.name && <div>{company?.name}</div>}
                {location?.city && <div>{location?.city}</div>}
                {employmentType && <div>{employmentType}</div>}
            </HeadPanel>
            {!displayOnly && (
                <Panel className={styles.linkspanel}>
                    <div className={styles.links}>
                        <NavLink
                            exact
                            to={{
                                pathname: generatePath(JOB_DETAIL_PATH, {
                                    jobPostId,
                                    page,
                                }),
                                search: routeLocation.search,
                            }}
                            className={styles.links__item}
                            activeClassName={styles.links__item__active}
                        >
                            Job Details
                        </NavLink>
                        <NavLink
                            to={{
                                pathname: generatePath(
                                    JOB_DETAIL_COMPANY_PATH,
                                    {
                                        jobPostId,
                                        page,
                                    }
                                ),
                                search: routeLocation.search,
                            }}
                            className={styles.links__item}
                            activeClassName={styles.links__item__active}
                        >
                            Company Overview
                        </NavLink>
                    </div>
                </Panel>
            )}
            <Switch>
                {!displayOnly && (
                    <Route exact path={JOB_DETAIL_COMPANY_PATH}>
                        <CompanyDetailContent
                            description={companyDetail?.description}
                            founded={founded}
                            url={url}
                            industries={industries}
                            employees={employees}
                            location={headquarterLocation}
                            videoId={videoId}
                            videoProvider={videoProvider}
                            jobs={jobs}
                            benefits={benefits}
                            locations={locations}
                            images={images}
                            isLoading={companyIsLoading}
                        />
                    </Route>
                )}
                <Route>
                    <JobDetailContent
                        company={company}
                        location={location}
                        match={match}
                        isFavourite={isFavourite}
                        languages={languages}
                        benefits={jobBenefits}
                        hardSkillsMatch={hardSkillsMatch}
                        softSkillsMatch={softSkillsMatch}
                        qualificationsMatch={qualificationsMatch}
                        languagesMatch={languagesMatch}
                        skills={skills}
                        qualifications={qualifications}
                        info={info}
                        salaryMin={salaryMin}
                        salaryMax={salaryMax}
                        currency={currency}
                        payRaise={payRaise}
                        isLoading={isLoading}
                        startDate=""
                        description={description}
                        responsibilities={responsibilities}
                        careerMap={careerMap}
                    />
                </Route>
            </Switch>
            {!displayOnly && (
                <HeadPanel
                    isLoading={isLoading}
                    title={translate("apply.title")}
                    onApply={apply}
                    onAccept={accept}
                    status={applicationStatus}
                    displayOnly={displayOnly}
                    applyUrl={applyUrl}
                >
                    <div>{translate("apply.subtitle")}</div>
                </HeadPanel>
            )}
        </div>
    );

    function apply() {
        dispatch(JobDetailActions.applyToJob(jobPostId, profileId));
        // openInvite();
    }

    function accept() {
        dispatch(JobDetailActions.acceptJob(jobPostId, profileId));
    }
}

function HeadPanel(
    props: PropsWithChildren<{
        title: string;
        isLoading: boolean;
        onApply: () => void;
        onAccept: () => void;
        status: IJobApplicationStatus;
        displayOnly: boolean;
        applyUrl?: string;
    }>
) {
    const {
        title,
        children,
        isLoading,
        onApply,
        onAccept,
        status,
        displayOnly,
        applyUrl,
    } = props;
    const { applicationState } = useSelector(mapState);

    const hasApplied = status === "APPLIED";
    const isDisabled =
        hasApplied ||
        status === "DECLINED" ||
        status === "ACCEPTED" ||
        status === "SHORTLISTED";
    const hasSuccess =
        hasApplied || status === "ACCEPTED" || status === "SHORTLISTED";
    const translate = useTranslate("candidate.jobs.detail.buttons");

    return (
        <Panel className={styles.headpanel}>
            <div>
                <Panel.Heading className={styles.headpanel__title}>
                    {!isLoading ? title : <Skeleton height={24} width={160} />}
                </Panel.Heading>
                <div className={styles.attribs}>
                    {!isLoading ? (
                        children
                    ) : (
                        <Skeleton height={16} width={200} />
                    )}
                </div>
            </div>
            {!displayOnly
                ? applyUrl && (
                      <a
                          href={applyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleApplication}
                      >
                          <Button>{translate("applyUrl")}</Button>
                      </a>
                  )
                : !isLoading && (
                      <ActiveButton
                          fontSize={16}
                          onClick={handleApplication}
                          minWidth={80}
                          height={16}
                          states={resolveButtonStates()}
                          state={applicationState}
                          disabled={isDisabled}
                          success={hasSuccess}
                          fail={status === "DECLINED"}
                      >
                          {resolveButtonText()}
                      </ActiveButton>
                  )}
        </Panel>
    );

    function resolveButtonStates() {
        switch (status) {
            case "NOT_APPLIED":
                return [
                    translate("apply"),
                    translate("applying"),
                    translate("applied"),
                ];
            case "INVITED":
                return ["inv_accept", "accepting", "inv_accepted"];
            default:
                return [];
        }
    }

    function resolveButtonText() {
        switch (status) {
            case "SHORTLISTED":
                return translate("SHORTLISTED");
            case "DECLINED":
                return translate("declined");
            case "ACCEPTED":
                return translate("accepted");
            case "APPLIED":
                return translate("applied");
            default:
                return "";
        }
    }

    function handleApplication() {
        switch (status) {
            case "APPLIED":
            case "DECLINED":
            case "ACCEPTED":
            case "SHORTLISTED":
                return;
            case "NOT_APPLIED":
                onApply();
                break;
            case "INVITED":
                onAccept();
                break;
        }
    }
}

function mapState(state: any) {
    return {
        isApplicationLoading: JobDetailSelectors.isApplicationLoading(state),
        applicationState: JobDetailSelectors.getApplicationState(state),
    };
}
