import { combineReducers } from "redux";
import { COMPLETED_STATUS } from "../../../../../candidate/_constants/testStatus.constants";

import HeroOnboardingConstants from "../../../../_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../../_constants/actions/HeroProfileConstants";

export default combineReducers({
    started,
    complete,
    test,
    status,
    results,
    skills,
});

function test(state = null, action) {
    if (action.type === HeroOnboardingConstants.GET_TEST_SUCCESS) {
        return action.payload;
    }

    return state;
}

function status(state = null, action) {
    if (action.type === HeroOnboardingConstants.GET_STATUS_SUCCESS) {
        return action.payload.status;
    }
    if (action.type === HeroOnboardingConstants.COMPLETE_TEST_SUCCESS) {
        return COMPLETED_STATUS;
    }

    return state;
}

function started(state = false, action) {
    if (action.type === HeroOnboardingConstants.START_TEST) {
        return true;
    }

    return state;
}

function results(
    state = { profiles: [], skillInterpretations: {}, softSkills: [] },
    action
) {
    if (action.type === HeroProfileConstants.GET_SOFT_SKILLS_RESULTS_SUCCESS) {
        return action.payload;
    }

    return state;
}

function skills(state = [], action) {
    if (action.type === HeroProfileConstants.GET_SKILLS_SUCCESS) {
        return action.payload;
    }

    return state;
}

function complete(state = false, action) {
    // if (action.type === HeroOnboardingConstants.SAVE_ADVICE_SUCCESS) {
    //     return true;
    // }

    return state;
}
