import React, { useEffect } from 'react';
import { Translate } from 'react-translate.ts';

import { LoadingOverlay } from '../loading/LoadingOverlay';

import { NotificationMenuItem } from './NotificationMenuItem';
import { useWindowState } from '../misc';
import { BREAKPOINT_SM } from '../../_constants/theme.constants';
import { ArrayHelper } from '../../_helpers/ArrayHelper';
import { NotificationActions } from '../../_actions';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationSelectors } from '../../_selectors/NotificationSelectors';
import { BaseNavMenu } from './BaseNavMenu';

export function BaseNotificationMenu(props) {
    const { isOpen, onClose, action, list, isLoading, arrowRight, id } = props;

    const { page, pages } = useSelector((state) =>
        NotificationSelectors.getPagination(state)
    );
    const { width } = useWindowState();

    const isFullscreen = width < BREAKPOINT_SM;

    const dispatch = useDispatch();
    const changePage = (newPage) => {
        if (newPage >= 0 && newPage <= pages) {
            dispatch(NotificationActions.changePage(newPage));
        }
    };

    useEffect(() => {
        if (isOpen) {
            dispatch(action(page));
        }
    }, [isOpen]);

    const arrowImg = '/img/icons/arrow-up.svg';

    return (
        <BaseNavMenu
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            arrowRight={arrowRight}
            isFullscreen={isFullscreen}
        >
            <LoadingOverlay
                isLoading={isLoading}
                withIcon={false}
                isFull={true}
            />
            <div id={id + '--list'}>
                {ArrayHelper.isEmpty(list) ? (
                    <span>
                        <Translate textOnly>
                            common.notification.empty
                        </Translate>
                    </span>
                ) : null}
                <ul>
                    {list.map((notification, key) => (
                        <NotificationMenuItem
                            key={key}
                            notification={notification}
                        />
                    ))}
                </ul>
            </div>
            <div className="page-controls">
                <button onClick={prev} disabled={page === 0}>
                    <img src={arrowImg} alt="" />
                </button>
                <button
                    onClick={next}
                    disabled={pages === 0 || page === pages - 1}
                >
                    <img src={arrowImg} alt="" />
                </button>
            </div>
        </BaseNavMenu>
    );

    function prev() {
        changePage(page - 1);
    }

    function next() {
        changePage(page + 1);
    }
}
