import { RequestService } from "../../common/_services/RequestService";

const HeroProfileService = {
    getProfile,
    getProfiles,
};

const BASE_PATH = "heroes/profiles";

function getProfile(id) {
    return RequestService.get(BASE_PATH + "/" + id);
}

function getProfiles() {
    return RequestService.get(BASE_PATH);
}

export default HeroProfileService;
