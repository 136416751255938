import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import InterpretationActions from "../../_actions/InterpretationActions";
import InterpretationSelectors from "../../_selectors/InterpretationSelectors";
import { AuthSelectors } from "../../../common/_selectors";
import { SOFT_SKILL_TEST_MOP_PATH } from "../../_constants/route.constants";
import { BackButton, HeadTitle } from "../../../common/components";
import { BaseInterpretation } from "./BaseInterpretation";
import { InterpretationShare } from "./InterpretationShare";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import MopInterpretationContent from "./Mop/MopInterpretationContent/MopInterpretationContent";

const TRANSLATE_KEY = "candidate.interpretation";

export function MopInterpretation() {
    const { isLoading, interpretation, profileId } = useSelector(mapState);
    const { skillInterpretations, softSkills, profiles } = interpretation;

    const dispatch = useDispatch();

    const translateMop = useTranslate(TRANSLATE_KEY + ".mop");
    const translateMopHeadings = useTranslate(TRANSLATE_KEY + ".mop.headings");

    const routeMatch = useRouteMatch();
    const renderToHeader = useRenderToHeader();
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    useEffect(() => {
        if (profileId) {
            dispatch(InterpretationActions.getInterpretation(profileId, "mop"));
        }
    }, [dispatch, profileId]);

    return (
        <BaseInterpretation
            onShare={() => setShareDialogOpen(true)}
            links={[
                {
                    path: routeMatch.path + "#personality",
                    label: translateMop("links.base"),
                },
                {
                    path: routeMatch.path + "#discover",
                    label: translateMopHeadings("discover"),
                },
                {
                    path: routeMatch.path + "#summary",
                    label: translateMopHeadings("summary"),
                },
            ]}
        >
            <HeadTitle title={translateMopHeadings("main")} />
            <InterpretationShare
                isOpen={shareDialogOpen}
                close={() => setShareDialogOpen(false)}
            />
            {renderToHeader(<BackButton link={SOFT_SKILL_TEST_MOP_PATH} />)}
            <MopInterpretationContent
                isLoading={isLoading}
                profiles={profiles}
                skills={softSkills}
                interpretations={skillInterpretations}
            />
        </BaseInterpretation>
    );
}

function mapState(state) {
    return {
        isLoading:
            InterpretationSelectors.getInterpretationLoading("MOP")(state),
        interpretation: InterpretationSelectors.getInterpretation("MOP")(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
