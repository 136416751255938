import { call, put } from "redux-saga/effects";

import { NotificationActions } from "../_actions/NotificationActions";

export const SagaHelpers = {
    handleRequest,
};

export function* handleRequest({
    request,
    actions,
    messages = {},
    onSuccess,
    onFail,
    preRequest,
    action,
    notifyError = true,
}) {
    const [func, payload] = request;
    const [successAction, failAction] = actions || [];
    const { success, fail, info, warning } = messages;

    try {
        if (preRequest) {
            yield preRequest();
        }

        const response = yield call(func, payload);
        if (onSuccess) {
            yield onSuccess(response, payload);
        }

        if (!successAction) {
            yield put({ type: action + "_SUCCESS", payload: response.data });
        } else if (typeof successAction === "string") {
            yield put({ type: successAction, payload: response.data });
        } else {
            yield put(successAction(response.data));
        }

        if (success) {
            yield put(NotificationActions.pushSuccess(success));
        }
        if (warning) {
            yield put(NotificationActions.pushWarning(warning));
        }
        if (info) {
            yield put(NotificationActions.pushMessage(info));
        }
    } catch (error) {
        if (onFail) {
            yield onFail();
        }

        if (!failAction) {
            yield put({ type: action + "_FAIL", error });
        } else if (typeof failAction === "string") {
            yield put({ type: failAction, error });
        } else {
            yield put(failAction(error));
        }

        if ((error || fail) && notifyError) {
            const message = !!error
                ? typeof error === "string"
                    ? error
                    : error.message || error.error
                : fail;

            yield put(NotificationActions.pushError(message));
        }
    }
}
