import { combineReducers } from 'redux';
import {
    GET_JOB_ROLE_MONITORING,
    GET_JOB_ROLE_MONITORING_SUCCESS,
} from '../../../_actions';
import { ReducerHelpers } from '../../../_helpers';

const state = (state = null, action) =>
    ReducerHelpers.loading(state, action, GET_JOB_ROLE_MONITORING);

export default combineReducers({
    data,
    state,
    currentJobRoleId,
    loadedJobRoleId,
});

function data(state = null, action) {
    if (action.type === GET_JOB_ROLE_MONITORING_SUCCESS) {
        return action.payload.data;
    }

    return state;
}

function currentJobRoleId(state = null, action) {
    if (action.type === GET_JOB_ROLE_MONITORING) {
        return action.payload;
    }

    return state;
}

function loadedJobRoleId(state = null, action) {
    if (action.type === GET_JOB_ROLE_MONITORING_SUCCESS) {
        return action.payload.jobRoleId;
    }

    return state;
}
