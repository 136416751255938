import { RequestService } from '../../common/_services';

export const IndustryService = {
    getIndustries,
    getIndustryCategories,
};

function getIndustries() {
    return RequestService.get('api/industries');
}

function getIndustryCategories() {
    return RequestService.get('api/industry-categories');
}
