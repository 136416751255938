import { takeLatest } from "redux-saga/effects";

import { SagaHelpers } from "../../common/_helpers/SagaHelpers";
import { SoftSkillsService } from "../_services/SoftSkillsService";
import SoftSkillsConstants from "../_constants/actions/SoftSkillsConstants";

function* getComparison(action) {
    yield SagaHelpers.handleRequest({
        request: [SoftSkillsService.getComparison, action.payload],
        actions: [
            (payload) => ({
                type: SoftSkillsConstants.GET_COMPARISON_SUCCESS,
                payload: { test: action.payload.test, data: payload },
            }),
            SoftSkillsConstants.GET_COMPARISON_FAIL,
        ],
    });
}

function* getStatuses(action) {
    yield SagaHelpers.handleRequest({
        request: [SoftSkillsService.getStatuses, action.payload],
        actions: [
            SoftSkillsConstants.GET_STATUSES_SUCCESS,
            SoftSkillsConstants.GET_STATUSES_FAIL,
        ],
    });
}

function* getStatus(action) {
    yield SagaHelpers.handleRequest({
        request: [SoftSkillsService.getStatus, action.payload],
        actions: [
            SoftSkillsConstants.GET_STATUS_SUCCESS,
            SoftSkillsConstants.GET_STATUS_FAIL,
        ],
    });
}

function* getTest(action) {
    yield SagaHelpers.handleRequest({
        request: [SoftSkillsService.getTest, action.payload],
        actions: [
            SoftSkillsConstants.GET_TEST_SUCCESS,
            SoftSkillsConstants.GET_TEST_FAIL,
        ],
    });
}

function* completeTest(action) {
    yield SagaHelpers.handleRequest({
        request: [SoftSkillsService.completeTest, action.payload],
        actions: [
            SoftSkillsConstants.COMPLETE_TEST_SUCCESS,
            SoftSkillsConstants.COMPLETE_TEST_FAIL,
        ],
    });
}

export default [
    takeLatest(SoftSkillsConstants.GET_COMPARISON, getComparison),
    takeLatest(SoftSkillsConstants.GET_STATUSES, getStatuses),
    takeLatest(SoftSkillsConstants.GET_STATUS, getStatus),
    takeLatest(SoftSkillsConstants.GET_TEST, getTest),
    takeLatest(SoftSkillsConstants.COMPLETE_TEST, completeTest),
];
