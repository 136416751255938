import { takeLatest, put, call } from 'redux-saga/effects';

import { SagaHelpers } from '../_helpers';
import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_VIEWS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAIL,
    GET_NOTIFICATIONS_VIEWS_FAIL,
    GET_NOTIFICATIONS_VIEWS_SUCCESS,
    GET_NOTIFICATIONS_COUNT_SUCCESS,
    GET_NOTIFICATIONS_COUNT_FAIL,
    GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS,
    GET_NOTIFICATIONS_VIEWS_COUNT_FAIL,
    GET_NOTIFICATIONS_COUNT,
    GET_NOTIFICATIONS_VIEWS_COUNT,
    NOTIFICATION_PUSH,
    NOTIFICATION_POP,
} from '../_constants/actions/notification.constants';
import { NotificationService } from '../_services';
import { NotificationActions } from '../_actions/NotificationActions';

function* getNotifications(action) {
    yield SagaHelpers.handleRequest({
        request: [NotificationService.getNotifications, action.payload],
        actions: [
            (payload) => ({ type: GET_NOTIFICATIONS_SUCCESS, payload }),
            (error) => ({ type: GET_NOTIFICATIONS_FAIL, error }),
        ],
        messages: { fail: 'Failed to fetch notifications' },
        onSuccess: function* () {
            yield put(NotificationActions.getNotificationsCount());
        },
    });
}

function* getNotificationViews(action) {
    yield SagaHelpers.handleRequest({
        request: [NotificationService.getNotificationViews, action.payload],
        actions: [
            (payload) => ({ type: GET_NOTIFICATIONS_VIEWS_SUCCESS, payload }),
            (error) => ({ type: GET_NOTIFICATIONS_VIEWS_FAIL, error }),
        ],
        messages: { fail: 'Failed to fetch notifications' },
        onSuccess: function* () {
            yield put(NotificationActions.getNotificationViewsCount());
        },
    });
}

function* getNotificationsCount(action) {
    yield SagaHelpers.handleRequest({
        request: [NotificationService.getNotificationsCount, action.payload],
        actions: [
            (payload) => ({ type: GET_NOTIFICATIONS_COUNT_SUCCESS, payload }),
            (error) => ({ type: GET_NOTIFICATIONS_COUNT_FAIL, error }),
        ],
        messages: { fail: 'Failed to fetch notifications' },
    });
}

function* getNotificationViewsCount(action) {
    yield SagaHelpers.handleRequest({
        request: [
            NotificationService.getNotificationViewsCount,
            action.payload,
        ],
        actions: [
            (payload) => ({
                type: GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS,
                payload,
            }),
            (error) => ({ type: GET_NOTIFICATIONS_VIEWS_COUNT_FAIL, error }),
        ],
        messages: { fail: 'Failed to fetch notifications' },
    });
}

function* pushNotification(action) {
    yield call(NotificationService.addLocalNotification, action.payload);
}

function* popNotification(action) {
    yield call(NotificationService.removeLocalNotification, action.payload);
}

export default [
    takeLatest(GET_NOTIFICATIONS, getNotifications),
    takeLatest(GET_NOTIFICATIONS_VIEWS, getNotificationViews),
    takeLatest(GET_NOTIFICATIONS_COUNT, getNotificationsCount),
    takeLatest(GET_NOTIFICATIONS_VIEWS_COUNT, getNotificationViewsCount),
    takeLatest(NOTIFICATION_PUSH, pushNotification),
    takeLatest(NOTIFICATION_POP, popNotification),
];
