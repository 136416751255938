import React, { useEffect, useRef, useState } from "react";
import { createClass } from "classname-helper";

import { addEventHandler } from "../_helpers/addEventHandler";
import { removeEventHandler } from "../_helpers/removeEventHandler";

const DELAY_MS = 250;
const OFFSET = 12;

export function useTooltipLabel(label, options = {}) {
    const { anchorY, anchorX } = options;

    const ref = useRef(null);
    const tooltip = useRef(null);
    const timer = useRef(null);
    const [state, setState] = useState({
        visible: false,
        position: { top: 0, left: 0 },
    });
    const visibleRef = useRef(state.visible);

    useEffect(() => {
        visibleRef.current = state.visible;
    }, [state.visible]);

    const { visible, position } = state;

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        addEventHandler(ref.current, "mouseover", startTimer);
        addEventHandler(ref.current, "mouseout", hide);

        return () => {
            if (!ref.current) {
                return;
            }

            removeEventHandler(ref.current, "mouseover", startTimer);
            removeEventHandler(ref.current, "mouseout", hide);
        };
    }, [ref.current]);

    const element = (
        <div
            ref={tooltip}
            className={createClass({
                "tooltip-label": true,
                visible,
            })}
            style={{ top: position.top, left: position.left }}
        >
            <div
                className="tooltip-label__arrow"
                style={{ top: position.arrowTop, left: position.arrowLeft }}
            />
            {label}
        </div>
    );

    return [ref, element];

    function startTimer() {
        if (!visibleRef.current) {
            timer.current = setTimeout(show, DELAY_MS);
        }
    }

    function show() {
        if (!ref.current) {
            return;
        }

        setState({
            visible: true,
            position: calculatePosition(),
        });
    }

    function hide() {
        setState({ visible: false, position: calculatePosition() });

        clearTimeout(timer.current);
    }

    function calculateAnchor(top, left, arrowTop, arrowLeft) {
        let x = left,
            y = top,
            arrowX = arrowLeft,
            arrowY = arrowTop;

        const anchorRect = ref.current.getBoundingClientRect();

        const tooltipRect = tooltip.current.getBoundingClientRect();

        if (anchorY === "middle") {
            y =
                anchorRect.top +
                (anchorRect.height / 2 - tooltipRect.height / 2);
            arrowY = y + tooltipRect.height / 2 - 4;
        }

        if (anchorX === "right") {
            x = anchorRect.right + OFFSET;
            arrowX = x - 4;
        }

        return { x, y, arrowX, arrowY };
    }

    function calculatePosition() {
        if (!ref.current) {
            return { top: 0, left: 0 };
        }

        const body = document.body;
        const bodyRect = body.getBoundingClientRect();

        const anchorRect = ref.current.getBoundingClientRect();

        const tooltipRect = tooltip.current.getBoundingClientRect();

        let top = anchorRect.bottom + OFFSET;
        let arrowTop = top - 4;

        if (top + tooltipRect.height + OFFSET + 8 >= bodyRect.height - OFFSET) {
            top = anchorRect.top - tooltipRect.height - OFFSET;
            arrowTop = top + tooltipRect.height - 4;
        }

        let left =
            anchorRect.left + anchorRect.width / 2 - tooltipRect.width / 2;

        const arrowLeft = anchorRect.left + anchorRect.width / 2 - 4;

        if (left <= OFFSET) {
            left = OFFSET;
        } else {
            const rightClipDistance =
                left + tooltipRect.width - bodyRect.width + OFFSET;
            if (rightClipDistance > 0) {
                left = left - rightClipDistance;
            }
        }

        const { x, y, arrowX, arrowY } = calculateAnchor(
            top,
            left,
            arrowTop,
            arrowLeft
        );

        return { top: y, left: x, arrowLeft: arrowX, arrowTop: arrowY };
    }
}
