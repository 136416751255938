import { createSelector } from "reselect";

import { STATE_LOADING } from "../_constants/state.constants";
import SoftSkillsSelectors from "../../candidate/_selectors/SoftSkillsSelectors";
import { PROCESSED_STATUS } from "../../candidate/_constants/testStatus.constants";

const onboarding = (state) => state.common.onboarding;

const getPersonal = createSelector(
    onboarding,
    (onboarding) => onboarding.personal
);

const getName = createSelector(getPersonal, (personal) => personal.firstName);

const isLoading = createSelector(
    onboarding,
    (onboarding) => onboarding.loading === STATE_LOADING
);

const getCurrentStep = createSelector(
    onboarding,
    (onboarding) => onboarding.step
);

const getStepsCompleted = createSelector(
    onboarding,
    (onboarding) => onboarding.stepsCompleted
);

const hasTestStarted = createSelector(
    onboarding,
    (onboarding) => onboarding.testStarted
);

const getTestStatus = (state) => SoftSkillsSelectors.getStatus(state, "MOP");

const isTestComplete = createSelector(
    getTestStatus,
    (status) => status && status === PROCESSED_STATUS
);

const OnboardingSelectors = {
    getCurrentStep,
    isLoading,
    getStepsCompleted,
    hasTestStarted,
    getTestStatus,
    isTestComplete,
    getPersonal,
    getName,
};

export default OnboardingSelectors;
