import React from 'react';
import { BaseProgressBar } from './BaseProgressBar';

export function ProgressBar(props) {
    const { ideal, maxValue = 1, range } = props;

    return (
        <BaseProgressBar {...props}>
            <IdealMarker {...{ ideal, maxValue, range }} />
        </BaseProgressBar>
    );
}

function IdealMarker({ ideal, maxValue, range }) {
    if (!ideal || !range) {
        return null;
    }

    const min = range.min * 10;
    const max = range.max * 10;

    const left = getBarPercentage(min, maxValue);
    const width = getBarPercentage(max - min, maxValue);

    return (
        <div
            className="progress-ideal-marker"
            style={{ left: left + '%', width: width + '%' }}
        />
    );
}

const getBarPercentage = (value, maxValue) =>
    Math.round((value / maxValue) * 100);
