export const ArrayHelper = {
    toObject,
    fromObject,
    isEmpty,
    isOutOfBounds,
    remove,
    exists,
    tryGet,
};

function fromObject(obj) {
    return Object.keys(obj).map((value) => {
        return value;
    });
}

function toObject(array, getKey) {
    if (isEmpty(array)) {
        return {};
    }

    return array.reduce((accumulator, current) => {
        accumulator[getKey(current)] = current;
        return accumulator;
    }, {});
}

/**
 * @param {Array} array
 */
function isEmpty(array) {
    if (!Array.isArray(array)) {
        return true;
    }

    return array.length === 0;
}

function isOutOfBounds(array, index) {
    return index < 0 || isEmpty(array) || index >= array.length;
}

/**
 * Remove item at index and return new array
 * @param {Array} array
 */
function remove(array, index) {
    if (isOutOfBounds(array, index)) {
        return array;
    }

    const count = array.length;

    if (count === 1) {
        return [];
    }

    return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
}

function exists(array, index) {
    return !isOutOfBounds(array) && !!array[index];
}

function tryGet(array, index) {
    if (!exists(array, index)) {
        return null;
    }

    return array[index];
}
