import {
    Switch,
    Route,
    Redirect,
    useParams,
    generatePath,
} from "react-router-dom";
import { WrappedJobDetail } from "../../../candidate/components/JobDetail/parts/WrappedJobDetail";
import { BackButton } from "../../../common/components";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import {
    ADMIN_JOB_EDIT_DETAIL_PATH,
    ADMIN_JOB_EDIT_KANBAN_PATH,
    ADMIN_JOB_PREVIEW_PATH,
} from "../../../common/_constants/route.constants";
import AdminJobEdit from "../../components/AdminJobEdit/AdminJobEdit";
import AdminJobKanban from "../../components/AdminJobKanban/AdminJobKanban";
import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";

export default function CompanyJobPage() {
    const params = useParams<{ id: string }>();

    const id = parseInt(params.id, 10) || null;
    const renderToHeader = useRenderToHeader();

    return (
        <CompanyPageLayout>
            <Switch>
                <Route exact path={ADMIN_JOB_EDIT_KANBAN_PATH}>
                    <AdminJobKanban id={id} />
                </Route>
                <Route exact path={ADMIN_JOB_EDIT_DETAIL_PATH}>
                    <AdminJobEdit id={id} />
                </Route>
                <Route exact path={ADMIN_JOB_PREVIEW_PATH}>
                    {renderToHeader(
                        <BackButton
                            link={generatePath(ADMIN_JOB_EDIT_DETAIL_PATH, {
                                id,
                            })}
                        />
                    )}
                    <WrappedJobDetail jobPostId={id} displayOnly />
                </Route>
                <Route>
                    <Redirect
                        to={generatePath(ADMIN_JOB_EDIT_DETAIL_PATH, { id })}
                    />
                </Route>
            </Switch>
        </CompanyPageLayout>
    );
}
