import { takeLatest } from 'redux-saga/effects';

import {
    GET_CURRENT,
    GET_CURRENT_SUCCESS,
    GET_CURRENT_FAIL,
} from '../_constants/actions/user.constants';
import { SagaHelpers } from '../_helpers';
import { UserService } from '../_services';

function* current() {
    yield SagaHelpers.handleRequest({
        request: [UserService.current],
        actions: [
            (payload) => ({ type: GET_CURRENT_SUCCESS, payload: payload }),
            (error) => ({ type: GET_CURRENT_FAIL, error }),
        ],
        messages: { fail: 'Failed to get current' },
        onSuccess: (response) => {
            const user = response.data;
            UserService.setProfileId(user.profileId);
            UserService.setUser(user);
        },
    });
}

export default [takeLatest(GET_CURRENT, current)];
