import { combineReducers } from "redux";

import { LanguageService } from "../_services/LanguageService";
import LanguageConstants from "../_constants/actions/LanguageConstants";
import { LOGOUT } from "../_constants/actions/auth.constants";

export default combineReducers({ locale, list, levels, allowedLocales });

function list(state = [], action) {
    if (action.type === LanguageConstants.GET_LANGUAGES_SUCCESS) {
        return action.payload;
    }

    return state;
}

function levels(state = [], action) {
    if (action.type === LanguageConstants.GET_LEVELS_SUCCESS) {
        return action.payload;
    }

    return state;
}

function locale(state = LanguageService.getCurrentLocale(), action) {
    switch (action.type) {
        case LanguageConstants.CHANGE_LOCALE:
            return action.payload;
        case LOGOUT:
            return "en";
        default:
            return state;
    }
}

function allowedLocales(state = [], action) {
    switch (action.type) {
        case LanguageConstants.SET_ALLOWED:
            return action.payload;
        default:
            return state;
    }
}
