import { useTranslate } from "react-translate.ts";
import { Panel, PanelProps } from "../../../../common/components/Panel/Panel";
import AdminEdit from "../../AdminEdit/AdminEdit";
import LanguageEdit, {
    LanguageEditProps,
} from "../../LanguageEdit/LanguageEdit";

export default function LanguageEditPanel(
    props: LanguageEditProps & PanelProps
) {
    const { languages, jobPostId, ...rest } = props;
    const translate = useTranslate("admin.jobs.edit.skills.lang");

    return (
        <Panel anchor="language" data-test="lang-edit-panel" {...rest}>
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <LanguageEdit languages={languages} jobPostId={jobPostId} />
        </Panel>
    );
}
