import { css, cx } from "@emotion/css";
import styles from "./LoadingSpinner.module.scss";

export default function LoadingSpinner(props: {
    isVisible?: boolean;
    className?: string;
}) {
    const { isVisible, className } = props;

    return (
        <div
            className={cx(
                styles.spinner,
                isVisible && css({ display: "block" }),
                className
            )}
        >
            <div className={styles.container}>
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
}
