import { cx } from "@emotion/css";
import { useTranslate } from "react-translate.ts";

import { PRIMARY_COLOR } from "../../../_constants/theme.constants";
import { ProgressBar } from "../../ProgressBar/ProgressBar";
import styles from "./MediaUploadProgress.module.scss";

type MediaUploadProgressProps = {
    name: string;
    progress: number;
    className?: string;
};

export default function MediaUploadProgress(props: MediaUploadProgressProps) {
    const { progress, name, className } = props;
    const translate = useTranslate("common.misc");

    return (
        <div className={cx(styles.container, className)}>
            <div className={styles.text}>
                <span className={styles.name}>{name}</span>
                <span className={styles.uploading}>
                    {translate("uploading")}...
                </span>
            </div>
            <ProgressBar progress={progress} fillColor={PRIMARY_COLOR} />
        </div>
    );
}
