import React from "react";
import { Translate, useTranslate } from "react-translate.ts";

import { ArrayHelper } from "../../_helpers/ArrayHelper";
import { JobMatchChart } from "../jobs";
import { MathHelper } from "../../_helpers/MathHelper";
import { SkillMatchBar } from "./SkillMatchBar";

/**
 *
 * @param {{
 *      items: {name: string, score: number}[]
 * }} props
 */
export function ProgressChart({ items, additionalItems, noYears }) {
    const requiredItems =
        !ArrayHelper.isEmpty(items) && items.filter((item) => item.isRequired);
    const optionalItems =
        !ArrayHelper.isEmpty(items) && items.filter((item) => !item.isRequired);

    return (
        <div>
            {!ArrayHelper.isEmpty(requiredItems)
                ? renderChart(requiredItems, "common.misc.required")
                : null}
            {!ArrayHelper.isEmpty(optionalItems)
                ? renderChart(optionalItems, "common.misc.optional")
                : null}
            {!ArrayHelper.isEmpty(additionalItems) ? (
                <AdditionalChart
                    items={additionalItems}
                    heading="common.misc.additional"
                />
            ) : null}
        </div>
    );

    function renderChart(currentItems, heading) {
        if (!currentItems) {
            return null;
        }

        return (
            <Section heading={heading}>
                {currentItems.map((item, index) => (
                    <MatchItem key={index} {...item} noYears={noYears} />
                ))}
            </Section>
        );
    }
}

function AdditionalChart(props) {
    const { items, heading } = props;
    const translate = useTranslate("common.skills.level");

    if (!items) {
        return null;
    }
    const steps = [translate(1), translate(2), translate(3), translate(4)];
    return (
        <Section heading={heading}>
            {items.map((item, index) => (
                <SkillMatchBar
                    key={index}
                    steps={steps}
                    level={item.level - 1}
                    {...item}
                />
            ))}
        </Section>
    );
}

function Section(props) {
    const { children, heading } = props;

    return (
        <div className="progress-chart">
            <div className="progress-chart--heading">
                <strong>
                    <Translate textOnly>{heading}</Translate>:
                </strong>
            </div>
            <div className="progress-chart--grid">{children}</div>
        </div>
    );
}

function MatchItem(props) {
    const {
        name,
        score,
        level,
        levelRange,
        yearsOfExperience,
        yearsOfExperienceRange,
        overrideLevelLabel,
        noYears,
    } = props;

    const translateMisc = useTranslate("common.misc");
    const translateLevels = useTranslate("common.skills.level");

    const levelMax = levelRange.max
        ? overrideLevelLabel
            ? levelRange.max
            : translateLevels(levelRange.max)
        : null;

    return (
        <div className="progress-chart--match-item">
            <div className="progress-chart--match-item--chart">
                <div className="progress-chart--match-item--value">
                    {MathHelper.round(score * 100, 0)}%
                </div>
                <JobMatchChart fit={score} innerRadius={20} />
            </div>
            <div className="font-book">
                <div className="progress-chart--item--name">{name}</div>
                <div className="progress-chart--item--info">
                    <span>
                        {translateMisc("level")}{" "}
                        {level
                            ? overrideLevelLabel
                                ? level
                                : translateLevels(level)
                            : translateLevels(0)}{" "}
                    </span>
                    {levelRange ? (
                        <span>
                            ({" "}
                            {translateMisc(
                                `experienceRequired${levelMax && "Max"}`,
                                {
                                    min: levelRange.min
                                        ? overrideLevelLabel
                                            ? levelRange.min
                                            : translateLevels(levelRange.min)
                                        : translateLevels(0),
                                    max: levelMax,
                                }
                            )}{" "}
                            )
                        </span>
                    ) : null}
                </div>
                <div className="progress-chart--item--info">
                    {noYears ? null : (
                        <span>
                            {translateMisc("yearsOfExperience", {
                                count: yearsOfExperience || 0,
                            })}{" "}
                        </span>
                    )}
                    {yearsOfExperienceRange ? (
                        <span>
                            ({" "}
                            {translateMisc(
                                `experienceRequired${
                                    yearsOfExperienceRange.max && "Max"
                                }`,
                                {
                                    min: yearsOfExperienceRange.min,
                                    max: yearsOfExperienceRange.max,
                                }
                            )}{" "}
                            )
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
