import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeroOnboardingSelectors from "../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { HeroAdvice } from "./steps/HeroAdvice";
import { HeroPersonal } from "./steps/HeroPersonal/HeroPersonal";
import { HeroPositions } from "./steps/HeroPositions";
import { HeroSoftSkills } from "./steps/HeroSoftSkills";
import { HeroReview } from "./steps/HeroReview/HeroReview";
import { Loading, LoadingOverlay } from "../../components";
import { AuthSelectors } from "../../_selectors/AuthSelectors";
import HeroProfileActions from "../../_actions/HeroProfileActions";
import { HeroVideo } from "./steps/HeroVideo/HeroVideo";
import { HeroLinks } from "./components/HeroLinks";
import HeroProfileSelectors from "../../../candidate/_selectors/HeroProfileSelectors";
import { MainState } from "../../../root.reducer";
import OnboardingPageLayout from "../../components/layout/OnboardingPageLayout/OnboardingPageLayout";
import styles from "./HeroOnboaring.module.scss";

export function HeroOnboarding() {
    const { isLoading, isProfileLoading, heroProfileId, step } =
        useSelector(mapState);

    const dispatch = useDispatch();

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroProfileActions.getProfile(heroProfileId));
        }
    }, [heroProfileId, dispatch]);

    if (isProfileLoading) {
        return <Loading />;
    }

    return (
        <OnboardingPageLayout headerProps={{ className: styles.header }}>
            <HeroLinks />
            <LoadingOverlay isLoading={isLoading} />
            {renderStep()}
        </OnboardingPageLayout>
    );

    function renderStep() {
        switch (step) {
            case 0:
                return <HeroPersonal />;
            case 1:
                return <HeroPositions />;
            case 2:
                return <HeroSoftSkills />;
            case 3:
                return <HeroAdvice />;
            case 4:
                return <HeroVideo />;
            case 5:
                return <HeroReview />;
            default:
                return <HeroPersonal />;
        }
    }
}

function mapState(state: MainState) {
    return {
        isLoading: HeroOnboardingSelectors.isLoading(state),
        isSigned: AuthSelectors.isSigned(state),
        userStatus: AuthSelectors.userStatus(state),
        heroProfileId: AuthSelectors.getHeroProfileId(state),
        isActive: AuthSelectors.isActive(state),
        step: HeroOnboardingSelectors.getCurrentStep(state),
        isProfileLoading: HeroProfileSelectors.isLoading(state),
    };
}
