import { createSelector } from "reselect";

import { STATE_LOADING } from "../../common/_constants/state.constants";

const dashboard = (state) => state.candidate.dashboard;

const getData = createSelector(dashboard, (dashboard) => dashboard.data);

const isLoading = createSelector(
    dashboard,
    getData,
    (dashboard, data) => !getData && dashboard.state === STATE_LOADING
);

export const DashboardSelectors = {
    getData,
    isLoading,
};
