import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Loading } from "../../../../common/components";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import { SOFT_SKILL_TEST_PATH } from "../../../../common/_constants/route.constants";
import { AuthSelectors } from "../../../../common/_selectors/AuthSelectors";

export function CompleteRedirect() {
    const dispatch = useDispatch();
    const { profileId } = useSelector((state) => mapState(state));
    const { requestId } = useParams();

    useEffect(() => {
        if (profileId && requestId) {
            dispatch(SoftSkillsActions.completeTest(profileId, requestId));
        }
    }, [profileId, requestId]);

    if (!requestId) {
        return <Redirect to={SOFT_SKILL_TEST_PATH} />;
    }

    return <Loading style={{ zIndex: 999, top: -49 }} />;
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
    };
}
