import { combineReducers } from 'redux';

import {
    GET_PUBLIC_INTERPRETATION,
    GET_PUBLIC_INTERPRETATION_FAIL,
    GET_PUBLIC_INTERPRETATION_SUCCESS,
} from '../../../../_actions/PublicActions';

const data = (state = null, action) => {
    if (action.type === GET_PUBLIC_INTERPRETATION_SUCCESS) {
        return action.payload;
    }

    return state;
};

const loading = (state = false, action) => {
    switch (action.type) {
        case GET_PUBLIC_INTERPRETATION:
            return true;
        case GET_PUBLIC_INTERPRETATION_SUCCESS:
        case GET_PUBLIC_INTERPRETATION_FAIL:
            return false;
        default:
            return state;
    }
};

export default combineReducers({ data, loading });
