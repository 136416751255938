import { useTranslate } from 'react-translate.ts';

import { Flex } from '../../../../common/components';
import { Grid } from '../../layout';
import styles from './BaseInfo.module.scss';

export function BaseInfo({ company }) {
    const translate = useTranslate('common.company.profile');
    const infoData = getInfoData(company, translate);

    return (
        <Grid rowGap={40} columnGap={96} min="20%" max="1fr">
            {infoData.map((info, key) => (
                <Info
                    key={key}
                    value={info.value}
                    label={info.label}
                    translate={translate}
                />
            ))}
        </Grid>
    );
}

function getInfoData(company, translate) {
    const { url, founded, headquarterLocation, size, industries } = company;
    return [
        {
            label: 'founded',
            value: founded
        },
        {
            label: 'size.label',
            value: size ? translate('size.value', { size: size.name }) : null
        },
        {
            label: 'hq',
            value: headquarterLocation && headquarterLocation.text
        },
        {
            label: 'website',
            value: <a className={styles.web} href={url}>{url}</a>
        },
        {
            label: 'industry',
            value:
                industries &&
                industries.map(industry => industry.name).join(', ')
        }
    ];
}

function Info({ label, value, translate }) {
    return (
        <Flex direction="column">
            <div className="font-9 text-secondary font-light uppercase margin-bottom-5">
                {translate(label)}
            </div>
            <strong className="font-14">{value}</strong>
        </Flex>
    );
}
