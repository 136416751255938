import { FieldHookConfig, useField } from "formik";
import DateInput from "./DateInput/DateInput";
import styles from "./Input/Input.module.scss";

interface FormDateInputProps<T extends string> {
    name: string;
    fieldConfig?: FieldHookConfig<T>;
    className?: string;
}

export default function FormDateInput<T extends string>(
    props: FormDateInputProps<T>
) {
    const { name, fieldConfig = {}, className } = props;
    const [field, meta, helper] = useField<T>({ ...fieldConfig, name });

    const { error, touched } = meta;
    const hasError = touched && !!error;

    return (
        <div className={styles.wrapper}>
            <DateInput value={field.value} onChange={helper.setValue} />
            {hasError && <div className={styles.error}>{error}</div>}
        </div>
    );
}
