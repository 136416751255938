import { CSSProperties, useState } from "react";
import { Translate, useTranslate } from "react-translate.ts";
import DeleteButton from "../buttons/DeleteButton/DeleteButton";
import EditButton from "../buttons/EditButton/EditButton";
import ConfirmDialog from "../dialogs/ConfirmDialog/ConfirmDialog";
import Table, { TableProps } from "../Table/Table";
import styles from "./EditTable.module.scss";

export interface EditTableProps<T = any> extends TableProps<T> {
    editColSize?: string;
    onEdit?: (item: T, index: number) => void;
    onDelete?: (item: T, index: number) => void;
    getItemName?: (item: T) => string;
}

export default function EditTable<T>(props: EditTableProps<T>) {
    const {
        head,
        data,
        cols,
        onEdit,
        onDelete,
        createItem,
        editColSize = "1fr",
        getItemName,
    } = props;
    const translate = useTranslate("common.dialog.delete");

    const [state, setState] = useState({
        dialogVisible: false,
        item: null,
        index: null,
    });

    return (
        <div>
            <ConfirmDialog
                onCancel={hideDialog}
                onConfirm={handleConfirm}
                isVisible={state.dialogVisible}
                onClose={hideDialog}
                confirmButtonProps={{
                    children: (
                        <Translate textOnly>common.actions.delete</Translate>
                    ),
                }}
            >
                {getItemName
                    ? translate("item", {
                          item: getItemName(state.item),
                      })
                    : translate("label")}
            </ConfirmDialog>
            <Table<T>
                head={[...head, <div />]}
                data={data}
                createItem={(item, i) => (
                    <>
                        {createItem(item, i)}
                        <div
                            className={styles.buttons}
                            data-test="edit-table-buttons"
                        >
                            {onEdit ? (
                                <span>
                                    <EditButton
                                        onClick={() => onEdit(item, i)}
                                        data-test="edit-button"
                                    />
                                </span>
                            ) : null}
                            {onDelete ? (
                                <span>
                                    <DeleteButton
                                        onClick={showDialog(item, i)}
                                        data-test="delete-button"
                                    />
                                </span>
                            ) : null}
                        </div>
                    </>
                )}
                cols={cols ? [...cols, editColSize] : undefined}
                style={{ "--row-height": "77px" } as CSSProperties}
                data-test="edit-table"
            />
        </div>
    );

    function showDialog(item: T, index: number) {
        return () => setState({ dialogVisible: true, item, index });
    }

    function hideDialog() {
        setState((prev) => ({ ...prev, dialogVisible: false }));
    }

    function handleConfirm() {
        onDelete && onDelete(state.item, state.index);

        hideDialog();
    }
}
