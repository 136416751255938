import { useTranslate } from "react-translate.ts";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Button } from "../../../../common/components";
import { GroupBarChart } from "./GroupBarChart";
import { BaseMop } from "./BaseMop";
import { resolveSectionColor } from "../../Interpretation/resolveSectionColor";
import { InterpretationInfo } from "./InterpretationInfo";
import { InterpretationLanguages } from "./InterpretationLanguages";
import { SOFT_SKILL_TEST_COMPARE_KAKO_PATH } from "../../../_constants/route.constants";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import InterpretationActions from "../../../_actions/InterpretationActions";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import { BREAKPOINT_SM } from "../../../../common/_constants/theme.constants";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export function Kako() {
    const dispatch = useDispatch();

    const translate = useTranslate(TRANSLATE_KEY);
    const translateInterpretation = useTranslate(
        TRANSLATE_KEY + ".kako.interpretation"
    );

    const isMobile = useBreakpoint(BREAKPOINT_SM);

    return (
        <BaseMop
            name="kako"
            extraChartContent={(interpretation) =>
                interpretation.profiles ? (
                    <div>
                        <GroupBarChart
                            data={interpretation.profiles.map((item) => ({
                                name: item.name,
                                color: resolveSectionColor(item.section),
                                value: item.value,
                                description: item.description,
                            }))}
                        />
                    </div>
                ) : null
            }
            interpretationContentEmpty={renderInterpretationEmpty}
            interpretationContentProcessed={renderInterpretationProcessed}
            getStatus={getStatus}
            getInterpretation={getInterpretation}
            isMobile={isMobile}
        ></BaseMop>
    );

    function getStatus(profileId, name) {
        dispatch(SoftSkillsActions.getStatus(profileId, name));
    }

    function getInterpretation(profileId, name) {
        dispatch(InterpretationActions.getInterpretation(profileId, name));
    }

    function renderInterpretationEmpty(_, __, status) {
        return (
            <>
                <h5>{translate("kako.title")}</h5>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translateInterpretation("info"),
                    }}
                />
                <p
                    dangerouslySetInnerHTML={{
                        __html: translateInterpretation("info2"),
                    }}
                />
                <InterpretationInfo
                    time={translate("kako.duration")}
                    // tested="viac ako 20 000"
                    count={status.questionCount}
                />
                <InterpretationLanguages languages={["cs", "sk", "en", "pl"]} />
                <div className="interpretation--languages">
                    <div className="interpretation--languages--item"></div>
                </div>
                <div className="interpretation--icon">
                    <img src="/img/soft-skills/work-together.svg" alt="" />
                </div>
            </>
        );
    }

    function renderInterpretationProcessed(interpretation) {
        if (
            !interpretation.skillInterpretations ||
            !interpretation.skillInterpretations.all
        ) {
            return null;
        }

        const { drivers, barriers } = interpretation.skillInterpretations.all;

        return (
            <>
                <h5>{translateInterpretation("heading")}</h5>
                <div>
                    {renderList("drivers", drivers)}
                    {renderList("barriers", barriers)}
                </div>
                <div className="interpretation--actions">
                    <Link to={SOFT_SKILL_TEST_COMPARE_KAKO_PATH}>
                        <Button variant="secondary">
                            {translate("actions.compare")}
                        </Button>
                    </Link>
                </div>
            </>
        );

        function renderList(title, items) {
            return (
                <div>
                    <h6>{translateInterpretation(title)}</h6>
                    <ul>
                        {items.map((item) => (
                            <li key={item.orderIndex}>{item.description}</li>
                        ))}
                    </ul>
                </div>
            );
        }
    }
}
