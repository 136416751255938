import React from 'react';
import moment from 'moment';
import { InputWrapper } from './InputWrapper';

const MIN_MONTH = 1;
const MAX_MONTH = 12;
const MIN_YEAR = 1;
const MAX_YEAR = 3000;

export function MonthInput({
    value,
    label,
    wrapperProps,
    onChange,
    disabled,
    ...rest
}) {
    const date = moment(value);
    date.day(1);

    const { month, year } = destructureDate();
    return (
        <InputWrapper label={label} {...wrapperProps}>
            <div className={`input${disabled ? ' disabled' : ''}`}>
                <div className="flex h100 align-items-center">
                    <select
                        onChange={handleMonthChange}
                        value={month + 1}
                        disabled={disabled}
                        {...rest}
                    >
                        {Array.from(new Array(12), renderOption)}
                    </select>
                    <div className="padding-x-10">-</div>
                    <input
                        type="number"
                        onChange={handleYearChange}
                        value={year}
                        className="w100"
                        min={MIN_YEAR}
                        max={MAX_YEAR}
                        disabled={disabled}
                        {...rest}
                    />
                </div>
            </div>
        </InputWrapper>
    );

    function renderOption(value, index) {
        const monthName = moment().month(index).format('MMMM');
        return (
            <option key={index} value={index + 1}>
                {monthName}
            </option>
        );
    }

    function clampValue(value, min, max) {
        return Math.max(min, Math.min(value, max));
    }

    function handleYearChange({ target }) {
        const currentValue = clampValue(target.value, MIN_YEAR, MAX_YEAR);
        onChange && onChange(date.year(currentValue).format());
    }

    function handleMonthChange({ target }) {
        const currentValue = clampValue(target.value, MIN_MONTH, MAX_MONTH);
        onChange && onChange(date.month(currentValue - 1).format());
    }

    function destructureDate() {
        return { month: date.month(), year: date.year() };
    }
}
