export const ImageHelpers = {
    convertImageToBase64,
    loadImageToBase64
};

async function loadImageToBase64(response) {
    const buffer = await response.arrayBuffer();
    const base64Flag = 'data:image/jpeg;base64, ';
    const base64Image = await convertImageToBase64(buffer);

    const imageData = base64Flag + base64Image;

    return imageData;
}

async function convertImageToBase64(buffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach(byte => (binary += String.fromCharCode(byte)));

    return window.btoa(binary);
}
