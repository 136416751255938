import React from "react";
import { useTranslate } from "react-translate.ts";

export function VideoLoading() {
    const translate = useTranslate("common.heroOnboarding.steps.video.loading");
    return (
        <div id="hero-video-loading">
            <div className="loader" />
            <div className="title">{translate("title")}</div>
            <div className="font-book">{translate("subtitle")}</div>
        </div>
    );
}
