import { FieldHookConfig, useField } from "formik";
import MultiSelect, { MultiSelectProps } from "./MultiSelect";

interface FormMultiSelectProps<T> extends MultiSelectProps<T> {
    fieldConfig?: FieldHookConfig<T>;
    name?: string;
}

export default function FormMultiSelect<T>(props: FormMultiSelectProps<T>) {
    const { name, fieldConfig = {}, ...rest } = props;
    const [field, _, helpers] = useField<T[]>({ ...fieldConfig, name });

    return (
        <MultiSelect<T>
            {...field}
            value={field.value}
            onChange={handleChange}
            {...rest}
        />
    );

    function handleChange(value: T[]) {
        helpers.setValue(value)
    }
}
