import React from "react";
import { cx } from "@emotion/css";

import inputStyles from "../Input/Input.module.scss";
import styles from "./TextArea.module.scss";

export interface TextAreaProps
    extends React.AllHTMLAttributes<HTMLTextAreaElement> {
    lines?: number;
}

export default function TextArea(props: TextAreaProps) {
    const { lines = 10, className } = props;

    return (
        <textarea
            className={cx(inputStyles.input, styles.area, className)}
            style={{ resize: "none" }}
            rows={lines}
            {...props}
        />
    );
}
