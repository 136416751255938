import { useTranslate } from "react-translate.ts";

import IBenefit from "../../../common/types/IBenefit";
import ICompany from "../../../common/types/ICompany";
import {
    IOtherJobInfo,
    IQualificationRequirements,
} from "../../../common/types/IJobDetail";
import ILanguageScore from "../../../common/types/ILanguageScore";
import ILocation from "../../../common/types/ILocation";
import { AdditionalInfo } from "./parts/AdditionalInfo";
import { HardSkillsList } from "./parts/HardSkillsList";
import { LanguagesList } from "./parts/LanguagesList";
import { LocationMap } from "./parts/LocationMap";
import { MainPanel } from "./parts/MainPanel";
import { Benefits } from "./parts/Benefits";
import { QualificationsList } from "./parts/QualificationsList";
import { SoftSkills } from "./parts/SoftSkills";
import styles from "./JobDetailContent.module.scss";
import FloatingCardContent from "./parts/FloatingCardContent";
import { useMemo } from "react";
import { Panel } from "../../../common/components/Panel/Panel";
import { useBreakpoint } from "../../../common/hooks/useBreakpoint";
import { BREAKPOINT_LG } from "../../../common/_constants/theme.constants";
import { IJobMatchSkillScores } from "../../../common/types/IJobMatch";
import JobResponsibilities from "./parts/JobResponsibilities/JobResponsibilities";
import JobPaths from "./JobPaths/JobPaths";

type JobDetailContentProps = {
    company: ICompany; // { name: string; description: string; icon: string; };
    location: ILocation;
    match: number;
    isFavourite: boolean;
    hardSkillsMatch: number;
    softSkillsMatch: number;
    qualificationsMatch: number;
    languagesMatch: number;
    skills: IJobMatchSkillScores;
    languages: ILanguageScore[];
    benefits: IBenefit[];
    // applicationStatus: "APPLIED";
    payRaise: string;
    startDate: string;
    qualifications: IQualificationRequirements[];
    info: IOtherJobInfo[];
    salaryMin: number;
    salaryMax: number;
    currency: string;
    isLoading: boolean;
    description: string;
    responsibilities: [];
    careerMap: any;
};

export default function JobDetailContent(props: JobDetailContentProps) {
    const {
        company,
        location,
        match,
        languages,
        benefits,
        hardSkillsMatch,
        softSkillsMatch,
        qualificationsMatch,
        languagesMatch,
        skills,
        qualifications,
        info,
        salaryMin,
        salaryMax,
        currency,
        payRaise,
        isLoading,
        description,
        responsibilities,
        careerMap
    } = props;

    const featuredBenefits = useMemo(
        () => benefits.filter((item) => item.featured),
        [benefits]
    );

    const translate = useTranslate("candidate.jobDetail.headings");
    const translateFixedPanel = useTranslate(
        "candidate.jobs.detail.fixedPanel"
    );

    const isMobile = useBreakpoint(BREAKPOINT_LG);

    return (
        <>
            <MainPanel
                name={company?.name}
                street={location?.street}
                city={location?.city}
                description={description}
                icon={company?.logoUrl}
                match={match}
                salaryMin={salaryMin}
                salaryMax={salaryMax}
                currency={currency}
                payRaise={payRaise}
                benefits={featuredBenefits}
                stats={[]}
                isLoading={isLoading}
            />
            {isMobile && (
                <Panel>
                    <Panel.Heading>
                        {translateFixedPanel("heading")}
                    </Panel.Heading>
                    <FloatingCardContent
                        payRaise={payRaise}
                        salaryMin={salaryMin}
                        salaryMax={salaryMax}
                        currency={currency}
                        isLoading={isLoading}
                        benefits={featuredBenefits}
                        stats={[]}
                    />
                </Panel>
            )}
            {responsibilities?.length > 0 && <JobResponsibilities
                responsibilities={responsibilities}
                // employmentType={}
                // employmentTitle={}
                // colour={}
                // thickness={}
            />}
            {careerMap && <JobPaths map={careerMap.map} categories={careerMap.categories} />}
            <QualificationsList
                heading={translate("qualification")}
                match={qualificationsMatch}
                qualifications={qualifications}
                isLoading={isLoading}
            />
            <HardSkillsList
                heading={translate("hardSkills")}
                match={hardSkillsMatch}
                skills={skills?.hardSkills}
                isLoading={isLoading}
            />
            {skills?.softSkills &&
                Object.values(skills.softSkills).map((score, index) => (
                    <SoftSkills
                        key={index}
                        heading={translate("softSkills")}
                        match={softSkillsMatch}
                        skills={score}
                        isLoading={isLoading}
                    />
                ))}
            <LanguagesList
                heading={translate("languages")}
                match={languagesMatch}
                languages={languages}
                isLoading={isLoading}
            />
            <Benefits
                heading={translate("benefit")}
                benefits={benefits}
                isLoading={isLoading}
            />
            <AdditionalInfo heading={translate("info")} list={info} />
            <div className={styles.map}>
                <LocationMap location={location} />
            </div>
        </>
    );
}
