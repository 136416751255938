import { createSelector } from "reselect";

const personal = (state) => state.common.heroes.onboarding.steps.personal;

const getData = createSelector(personal, (personal) => personal.data);

const isComplete = createSelector(personal, (personal) => personal.complete);

const getImage = createSelector(personal, (personal) => personal.image);

const isSaved = createSelector(personal, (personal) => personal.saved);

const Personal = {
    getData,
    isComplete,
    getImage,
    isSaved,
};

export default Personal;
