import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
    Button,
    Checkbox,
    Loading,
    SVGIcon,
} from "../../../common/components";
import { Section } from "./components/Section";
import { PROFILE_PATH_INFO } from "../../_constants/route.constants";
import { HardSkill } from "../../../common/components/skills/HardSkill";
import { DashboardSelectors } from "../../_selectors/DashboardSelectors";
import DashboardActions from "../../_actions/DashboardActions";
import { AuthSelectors } from "../../../common/_selectors";
import { HeroList } from "../../../common/components/heroes/HeroList/HeroList";
import { useTranslate } from "react-translate.ts";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";
import { MainState } from "../../../root.reducer";
import { UserProfileSelectors } from "../../../common/_selectors";
import MopInterpretationContent from "../Interpretation/Mop/MopInterpretationContent/MopInterpretationContent";

const LINKS = [
    { title: "links.profile", to: "#profile" },
    { title: "links.heroes", to: "#heroes" },
    { title: "links.skills", to: "#skills" },
    { title: "links.roles", to: "#job-roles" },
    { title: "links.path", to: "#path" },
    // { title: "Companies", to: "#companies" },
];

export function Dashboard() {
    const { data, isLoading, profileId } = useSelector(mapState);
    const translate = useTranslate("candidate.dashboard");
    const [showLess, setShowLess] = useState(true);

    const dispatch = useDispatch();
    useEffect(() => {
        if (profileId) {
            dispatch(DashboardActions.getData(profileId));
        }
    }, [dispatch, profileId]);

    return <CandidatePageLayout>{render()}</CandidatePageLayout>;

    function render() {
        if (!data || isLoading) {
            return <Loading />;
        }

        return (
            <div id="dashboard">
                {/* <div className="title font-heavy">
                Hello {firstName}, this is your dashboard
            </div> */}
                {/* <BaseInfo /> */}
                <div id="dashboard__heading">
                    <div className="heading">{translate("title")}</div>
                    <div className="subtext">{translate("subtitle")}</div>
                </div>
                <div id="dashboard__links">
                    {LINKS.map((item, i) => (
                        <div className="link">
                            <a
                                href={item.to}
                                className={i === 0 ? "active" : null}
                            >
                                {translate(item.title)}
                            </a>
                        </div>
                    ))}
                </div>
                <Section anchor="profile">
                    <div id="interpretation">
                        <MopInterpretationContent
                            profiles={data?.softSkillResult?.profiles}
                            interpretations={
                                data?.softSkillResult?.skillInterpretations
                            }
                            skills={data?.softSkillResult?.softSkills}
                            isLoading={isLoading}
                            showLess={showLess}
                            onShowMore={() => setShowLess(false)}
                        />
                    </div>
                </Section>
                <Section
                    anchor="heroes"
                    title={translate("heroes.title")}
                    subTitle={translate("heroes.subtitle")}
                    linkProps={{
                        label: translate("heroes.link"),
                        to: "/heroes",
                    }}
                >
                    <HeroList heroes={data?.heroes || []} />
                </Section>
                {/*<Section*/}
                {/*    anchor="skills"*/}
                {/*    title={translate("skills.title")}*/}
                {/*    subTitle={translate("skills.subtitle")}*/}
                {/*>*/}
                {/*    <div className="hard-skills">*/}
                {/*        <HardSkills*/}
                {/*            skills={*/}
                {/*                data?.missingHardSkills?.filter(*/}
                {/*                    (item: any) =>*/}
                {/*                        item.idealSkill.weightType ===*/}
                {/*                        "REQUIRED"*/}
                {/*                ) || []*/}
                {/*            }*/}
                {/*            type={translate("required")}*/}
                {/*        />*/}
                {/*        <HardSkills*/}
                {/*            skills={*/}
                {/*                data?.missingHardSkills?.filter(*/}
                {/*                    (item: any) =>*/}
                {/*                        item.idealSkill.weightType !==*/}
                {/*                        "REQUIRED"*/}
                {/*                ) || []*/}
                {/*            }*/}
                {/*            type={translate("prefered")}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Section>*/}
                {/*<Section*/}
                {/*    anchor="job-roles"*/}
                {/*    title={translate("roles.title")}*/}
                {/*    subTitle={translate("roles.subtitle")}*/}
                {/*    linkProps={{*/}
                {/*        label: translate("roles.link"),*/}
                {/*        to: "/job-roles",*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <JobRoleList*/}
                {/*        isLoading={isLoading}*/}
                {/*        roles={data?.jobRoles || []}*/}
                {/*    />*/}
                {/*</Section>*/}
                {/*<Section*/}
                {/*    anchor="path"*/}
                {/*    title={translate("path.title")}*/}
                {/*    subTitle={translate("path.subtitle")}*/}
                {/*    linkProps={{*/}
                {/*        label: translate("path.link"),*/}
                {/*        to: "/career-paths",*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <DiagramContent*/}
                {/*        map={data?.careerMap.map}*/}
                {/*        // paths={paths}*/}
                {/*        isLoading={isLoading}*/}
                {/*        zoom={0.5}*/}
                {/*        matchScoreEnabled*/}
                {/*        hoveredPathIndex={0}*/}
                {/*        displayOnly*/}
                {/*        minZoom={0.5}*/}
                {/*        maxZoom={0.5}*/}
                {/*        profileImage={image}*/}
                {/*        profileName={data?.careerMap.map.title}*/}
                {/*    />*/}
                {/*</Section>*/}
                {/*<Section*/}
                {/*    anchor="companies"*/}
                {/*    title="Companies suitable for you"*/}
                {/*    subTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry"*/}
                {/*    linkProps={{ label: "All Companies", to: "/companies" }}*/}
                {/*>*/}
                {/*    <CompanyList companies={companies} />*/}
                {/*</Section>*/}
                <div id="dashboard__footer">
                    <div className="text font-heavy">
                        {translate("footer.title")}
                    </div>
                    <div className="share"></div>
                    <div className="subtext">
                        {translate("footer.subtitle")}
                    </div>
                </div>
            </div>
        );
    }
}

function HardSkills(props: { skills: any[]; type: any }) {
    const { skills, type } = props;

    return (
        <div className="hard-skills__list">
            <div className="title">
                Missing Skills:
                <span className="type">{type}</span>
            </div>
            <div className="grid">
                {skills.map((item, i) => {
                    const { actualSkill, idealSkill, skillMatchScore } = item;

                    const skill = idealSkill && idealSkill.skill;

                    return (
                        <HardSkill
                            key={i}
                            match={skillMatchScore}
                            name={skill && skill.name}
                            level={actualSkill && actualSkill.level}
                            minLevel={idealSkill && idealSkill.levelMin}
                            maxLevel={idealSkill && idealSkill.levelMax}
                            years={actualSkill && actualSkill.timeYearCount}
                            minYears={idealSkill && idealSkill.timeYearCountMin}
                            maxYears={idealSkill && idealSkill.timeYearCountMax}
                        />
                    );
                })}
            </div>
        </div>
    );
}

function HightlightItem(props: {
    count: number;
    color: string;
    title: string;
    link: string;
}) {
    const { count, color, title, link } = props;

    return (
        <div className={"item " + color}>
            <Link to={link} />
            <div className="count">{count}</div>
            <div className="title">{title}</div>
            {/* @ts-ignore*/}
            <SVGIcon name="chevron" />
        </div>
    );
}

function BaseInfo() {
    return (
        <div id="dashboard__base-info">
            <ItemPersonal />
            <ItemTodos />
            <ItemHighlight />
        </div>
    );
}

function ItemPersonal() {
    const {
        // @ts-ignore
        firstName,
        // @ts-ignore
        lastName,
        // @ts-ignore
        image,
        // @ts-ignore
        position,
        // @ts-ignore
        education,
        // @ts-ignore
        location,
        // @ts-ignore
        personality,
        // @ts-ignore
        skills,
    } = useSelector(mapState);

    return (
        <div className="item personal">
            <Link to={PROFILE_PATH_INFO} className="edit-link">
                <Button>Edit your profile</Button>
            </Link>
            <div className="image">
                <img src={image} alt="" />
            </div>
            <div className="name">
                {firstName} {lastName}
            </div>
            <div className="info">
                <div className="item">{position}</div>
                <div className="item">{education}</div>
                <div className="item">{location}</div>
                <div className="item">{personality}</div>
            </div>
            <div className="skills">
                <div className="title font-heavy">Top skills</div>
                <div className="items">
                    {skills.map((item: any, i: number) => (
                        <div key={i} className="item">
                            {item.title}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

function ItemTodos() {
    // @ts-ignore
    const { todos } = useSelector(mapState);

    return (
        <div className="item todos">
            <div className="title">Your upcoming to-dos</div>
            <ul>
                {todos.map((item: any, i: number) => (
                    <li key={i}>
                        {/* @ts-ignore*/}
                        <Checkbox></Checkbox>
                        <div>
                            <div className="title">{item.title}</div>
                            <div className="subtitle">{item.sub}</div>
                        </div>
                        <div className="arrow">
                            {/* @ts-ignore*/}
                            <SVGIcon name="chevron" />
                        </div>
                    </li>
                ))}
            </ul>
            <Link to="/todos" className="more">
                View all 5 to-dos assigned to me
            </Link>
        </div>
    );
}

function ItemHighlight() {
    const {} = useSelector(mapState);

    return (
        <div className="item highlight">
            <HightlightItem
                color="blue"
                count={5}
                title="Top Job Roles for you"
                link="/job-roles"
            />
            <HightlightItem
                color="green"
                count={14}
                title="Skills Gap"
                link="/job-roles"
            />
            <HightlightItem
                color="yellow"
                count={4}
                title="Similar Heroes"
                link="/heroes"
            />
        </div>
    );
}

function mapState(state: MainState) {
    return {
        isLoading: DashboardSelectors.isLoading(state as never),
        profileId: AuthSelectors.getProfileId(state),
        data: DashboardSelectors.getData(state),
        image: UserProfileSelectors.getImage(state),
    };
}
