import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AvatarEdit } from "../../../../candidate/pages/Profile/parts/avatar/AvatarEdit";
import HeroOnboardingSelectors from "../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import HeroProfileActions from "../../../_actions/HeroProfileActions";
import { AuthSelectors } from "../../../_selectors/AuthSelectors";

export function HeroImageEdit(props) {
    const { onClose, originalImageUrl } = props;
    const { imageFileName, heroProfileId } = useSelector(mapState);

    const dispatch = useDispatch();

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroProfileActions.getProfilePicture(heroProfileId));
        }
    }, [heroProfileId, dispatch]);

    return (
        <AvatarEdit
            imageFileName={imageFileName}
            originalImageUrl={originalImageUrl}
            onSave={save}
            onClose={onClose}
        />
    );

    function save(convertedImage, originalImage, imageFileName) {
        dispatch(
            HeroProfileActions.saveProfilePicture(
                convertedImage,
                originalImage,
                imageFileName,
                heroProfileId
            )
        );
        onClose();
    }
}

function mapState(state) {
    return {
        ...HeroOnboardingSelectors.Personal.getImage(state),
        heroProfileId: AuthSelectors.getHeroProfileId(state),
    };
}
