import React from 'react';
import { PieChart, Pie } from 'recharts';
import { resolveMatch } from '../../_helpers';

/**
 * @param {{
 *  match: number;
 *  size?: "small" | "tiny" | "big";
 *  color?: string;
 *  isAnimationActive?: boolean;
 * }} props
 */
export function MatchChart({
    match,
    size,
    color,
    isAnimationActive = false,
    chartProps = {},
    ...rest
}) {
    const [outerRadius, innerRadius] = resolveSize(size);
    const data = [
        { value: match, fill: resolveMatch(match).color },
        { value: 1 - match, fill: '#E5E6E8' }
    ];
    const width = outerRadius * 2;
    return (
        <div {...rest}>
            <PieChart width={width} height={width}>
                <Pie
                    data={data}
                    dataKey="value"
                    startAngle={90}
                    innerRadius={chartProps.innerRadius || innerRadius}
                    outerRadius={chartProps.outerRadius || outerRadius}
                    endAngle={-270}
                    paddingAngle={7}
                    fill="#82ca9d"
                    isAnimationActive={isAnimationActive}
                />
            </PieChart>
        </div>
    );
}

function resolveSize(size) {
    switch (size) {
        case 'tiny':
            return [18, 6];
        case 'small':
            return [20, 10];
        case 'big':
            return [36, 18];
        default:
            return [30, 15];
    }
}
