export const MathHelper = {
    round,
    floor,
    polarToCartesian,
    clamp,
};

/**
 *
 * @param {number} value
 * @param {number} min
 * @param {number} max
 */
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

function round(value, decimal) {
    if (!decimal) {
        return Math.round(value);
    }

    const pow = Math.pow(10, decimal);
    return Math.round((value + Number.EPSILON) * pow) / pow;
}

function floor(value, decimal) {
    if (!decimal) {
        return Math.floor(value);
    }

    const pow = Math.pow(10, decimal);
    return Math.floor((value + Number.EPSILON) * pow) / pow;
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
}
