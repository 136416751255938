import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ICompanyDetail from "../types/ICompanyDetail";
import CompanyProfileService from "../_services/CompanyProfileService";
import { NotificationActions } from ".";
import AdminProfileEditService from "../../admin/_services/AdminProfileEditService";
import IBenefit from "../types/IBenefit";
import ISaveState from "../types/ISaveState";

const NAME = "COMPANY_PROFILE";
const GET_DETAIL_ACTION = `${NAME}/getDetail`;

type State = {
    detail: ISaveState<ICompanyDetail>;
    pending: {
        benefits: number[];
    };
};

const initialState: State = {
    detail: {
        loading: false,
        saving: false,
        data: {
            id: null,
            currency: null,
            description: "",
            domain: "",
            followed: false,
            followerCount: null,
            founded: null,
            headquarterLocation: null,
            images: [],
            industries: [],
            jobBenefits: [],
            jobCount: null,
            jobs: [],
            locations: [],
            logoUrl: null,
            name: "",
            size: null,
            url: null,
            videoId: null,
            videoProvider: null,
            viewCount: null,
            published: false,
            videoUrl: null,
        },
    },
    pending: {
        benefits: [],
    },
};

const getDetail = createAsyncThunk(
    GET_DETAIL_ACTION,
    async (companyId: number, thunkApi) => {
        try {
            const response = await CompanyProfileService.getCompanyProfile(
                companyId
            );

            return response.data as ICompanyDetail;
        } catch (ex) {
            thunkApi.dispatch(
                NotificationActions.pushError("Unable to load profile")
            );
        }
    }
);
const save = createAsyncThunk(
    `${NAME}/save`,
    async (
        payload: Parameters<typeof AdminProfileEditService.save>[0],
        thunk
    ) => {
        try {
            const response = await AdminProfileEditService.save(payload);
            thunk.dispatch(
                NotificationActions.pushSuccess(
                    "admin.profile.overview.success"
                )
            );

            return response;
        } catch (ex) {
            throw ex;
        }
    }
);

const saveBenefit = createAsyncThunk(
    `${NAME}/saveBenefit`,
    AdminProfileEditService.saveBenefit
);
const deleteBenefit = createAsyncThunk(
    `${NAME}/deleteBenefit`,
    AdminProfileEditService.deleteBenefit
);

const publish = createAsyncThunk(
    `${NAME}/publish`,
    async (
        payload: Parameters<typeof AdminProfileEditService.publish>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.publish(payload);
        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.publish.success")
        );

        return response;
    }
);
const unpublish = createAsyncThunk(
    `${NAME}/unpublish`,
    async (
        payload: Parameters<typeof AdminProfileEditService.unpublish>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.unpublish(payload);
        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.unpublish.success")
        );

        return response;
    }
);
const saveVideo = createAsyncThunk(
    `${NAME}/saveVideo`,
    async (
        payload: Parameters<typeof AdminProfileEditService.saveVideo>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.saveVideo(payload);
        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.video.successLink")
        );

        return response;
    }
);

const saveLocations = createAsyncThunk(
    `${NAME}/saveLocations`,
    async (
        payload: Parameters<typeof AdminProfileEditService.saveLocations>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.saveLocations(payload);
        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.locations.success")
        );

        return response;
    }
);

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDetail.pending, (state) => {
            state.detail.loading = true;
        });
        builder.addCase(getDetail.fulfilled, (state, action) => {
            state.detail.data = action.payload;
            state.detail.loading = false;
        });
        builder.addCase(getDetail.rejected, (state) => {
            state.detail.loading = false;
        });

        builder.addCase(save.pending, (state) => {
            state.detail.saving = true;
        });
        builder.addCase(save.fulfilled, (state, action) => {
            state.detail.saving = false;
            state.detail.data = { ...state.detail.data, ...action.payload };
        });
        builder.addCase(save.rejected, (state) => {
            state.detail.saving = false;
        });

        builder.addCase(saveLocations.pending, (state) => {
            state.detail.saving = true;
        });
        builder.addCase(saveLocations.fulfilled, (state, action) => {
            state.detail.saving = false;
            state.detail.data = { ...state.detail.data, ...action.payload };
        });
        builder.addCase(saveLocations.rejected, (state) => {
            state.detail.saving = false;
        });

        builder.addCase(saveBenefit.pending, (state, action) => {
            const id = action.meta.arg.category?.id;
            const benefits = state.detail.data?.jobBenefits || [];
            const index = benefits.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.pending.benefits.push(id);
            }
        });
        builder.addCase(saveBenefit.fulfilled, (state, action) => {
            const { meta, payload } = action;
            const id = payload?.data?.jobBenefitId;
            const { category, metricType, value, currency, metric } = meta.arg;
            const benefit: IBenefit = {
                id,
                name: category.name,
                metricTypePair: metricType,
                metricPair: metric,
                value,
                currency,
                formattedValue: payload?.data?.formattedValue,
                logoUrl: payload?.data?.logoUrl,
                description: "",
                featured: null,
            };

            const benefits = state.detail.data?.jobBenefits || [];
            const index = benefits.findIndex((item) => {
                return item.id === id;
            });

            if (index >= 0) {
                benefits[index] = benefit;
            } else {
                benefits.push(benefit);
            }

            const pending = state.pending.benefits;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(saveBenefit.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.pending.benefits;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(deleteBenefit.pending, (state, action) => {
            const id = action.meta.arg.id;
            const benefits = state.detail.data?.jobBenefits || [];
            const index = benefits?.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.pending.benefits.push(id);
            }
        });
        builder.addCase(deleteBenefit.fulfilled, (state, action) => {
            const id = action.meta.arg.id;
            const benefits = state.detail.data?.jobBenefits || [];
            const index = benefits?.findIndex((item) => item.id === id);

            if (index >= 0) {
                benefits.splice(index, 1);
            }

            const pending = state.pending.benefits;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(deleteBenefit.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.pending.benefits;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });
        builder.addCase(publish.pending, (state) => {
            state.detail.saving = true;
        });
        builder.addCase(publish.fulfilled, (state) => {
            state.detail.saving = false;
            state.detail.data.published = true;
        });
        builder.addCase(publish.rejected, (state) => {
            state.detail.saving = false;
        });

        builder.addCase(unpublish.pending, (state) => {
            state.detail.saving = true;
        });
        builder.addCase(unpublish.fulfilled, (state) => {
            state.detail.saving = false;
            state.detail.data.published = false;
        });
        builder.addCase(unpublish.rejected, (state) => {
            state.detail.saving = false;
        });
        builder.addCase(saveVideo.pending, (state) => {
            state.detail.saving = true;
        });
        builder.addCase(saveVideo.fulfilled, (state, action) => {
            state.detail.saving = false;
            state.detail.data.videoUrl = action.meta.arg.url;
        });
        builder.addCase(saveVideo.rejected, (state) => {
            state.detail.saving = false;
        });
    },
});

const CompanyProfileActions = {
    getDetail,
    save,
    saveBenefit,
    deleteBenefit,
    publish,
    unpublish,
    saveVideo,
    saveLocations,
};

export default CompanyProfileActions;

export const CompanyProfileReducer = slice.reducer;
