import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslate } from "react-translate.ts";
import { Formik, Form, useFormikContext } from "formik";
import { MixedSchema, ObjectSchema, StringSchema } from "yup";
import { cx } from "@emotion/css";

import {
    ONBOARDING_PATH,
    VERIFY_PATH,
} from "../../../_constants/route.constants";
import { VERIFICATION_PENDING_STATUS } from "../../../_constants/user.constants";
import {
    FormCheckbox,
    Loading,
    OnboardingButtons,
    OnboardingForm,
} from "../../../components";
import PasswordInput from "../../../components/input/PasswordInput/PasswordInput";
import { AuthSelectors } from "../../../_selectors";
import { AuthActions } from "../../../_actions";
import Field from "../../../components/input/Field/Field";
import FormInput from "../../../components/input/FormInput";
import { MainState } from "../../../../root.reducer";
import SignupConsent from "../../../components/SignupConsent/SignupConsent";
import OnboardingPageLayout from "../../../components/layout/OnboardingPageLayout/OnboardingPageLayout";
import styles from "./SignupPage.module.scss";
import OnboardingLayout from "../Onboarding/OnboardingLayout/OnboardingLayout";

const FIELDS = {
    email: "email",
    pass: "password",
    consent: "consent",
    marketing: "marketing"
};

export default function SignupPage() {
    const history = useHistory();

    const dispatch = useDispatch();
    const { loading, isSigned, userStatus } = useSelector(mapState);

    const translate = useTranslate("common.onboarding.signUp");
    const translateForm = (key: string) => translate("form." + key);

    const translateError = useTranslate("common.input.field.error");

    useEffect(() => {
        if (isSigned) {
            history.replace(ONBOARDING_PATH);
        }
    }, [isSigned, history]);

    if (isSigned && userStatus === VERIFICATION_PENDING_STATUS) {
        return <Redirect to={VERIFY_PATH} />;
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <OnboardingPageLayout>
            <OnboardingLayout
                color="light-blue"
                imageProps={{
                    src: "/img/onboarding/register.png",
                    className: styles.image,
                }}
                rightSideContent={
                    <div className={styles.quote}>
                        <div>{translate("quote")}</div>
                        <div className={cx(styles.name, "font-light")}>
                            {translate("quoteName")}
                        </div>
                    </div>
                }
            >
                <Formik
                    initialValues={{
                        [FIELDS.email]: "",
                        [FIELDS.pass]: "",
                        [FIELDS.consent]: false,
                        [FIELDS.marketing]: false
                    }}
                    isInitialValid={false}
                    onSubmit={submit}
                    validationSchema={getValidation}
                    validateOnMount
                >
                    <Content
                        translate={translate}
                        translateForm={translateForm}
                    />
                </Formik>
            </OnboardingLayout>
        </OnboardingPageLayout>
    );

    function submit(values: any) {
        const email = values[FIELDS.email];
        const pass = values[FIELDS.pass];
        const marketing = values[FIELDS.marketing];
        dispatch(
            AuthActions.signup({ username: email, password: pass, marketingConsent: marketing } as any)
        );
    }

    function getValidation() {
        const name = translateForm("email.label");
        return new ObjectSchema({
            [FIELDS.email]: new StringSchema()
                .required(
                    translateError("required", {
                        name,
                    })
                )
                .email(
                    translateError("email", {
                        name,
                    })
                ),
            [FIELDS.pass]: new StringSchema().required(),
            [FIELDS.consent]: new MixedSchema().oneOf([true]),
            [FIELDS.marketing]: new MixedSchema().oneOf([true, false]),
        });
    }
}

function Content(props: any) {
    const { translate, translateForm } = props;

    const { submitForm, isValid } = useFormikContext();

    return (
        <>
            <OnboardingForm>
                <h1 className="font-light">{translate("title")}</h1>
                <h6 className="font-book">{translate("subtitle")}</h6>
                <InnerForm translate={translateForm} />
            </OnboardingForm>
            <OnboardingButtons>
                <OnboardingButtons.Next
                    // link={HERO_PERSONAL_PATH}
                    disabled={!isValid}
                    onClick={submitForm}
                />
            </OnboardingButtons>
        </>
    );
}

function InnerForm(props: any) {
    const { translate } = props;

    return (
        <Form>
            <Field label={translate("email.placeholder")} required>
                <FormInput
                    name={FIELDS.email}
                    placeholder={translate("email.placeholder")}
                />
            </Field>
            <Field label={translate("pass.label")} required>
                <PasswordInput
                    name={FIELDS.pass}
                    placeholder={translate("pass.placeholder")}
                />
            </Field>
            <FormCheckbox name={FIELDS.consent} defaultChecked={false}>
                <SignupConsent />
            </FormCheckbox>
            <FormCheckbox name={FIELDS.marketing} defaultChecked={false}>
                {translate(FIELDS.marketing)}
            </FormCheckbox>
        </Form>
    );
}

function mapState(state: MainState) {
    const { loading } = state.common.auth;

    return {
        isSigned: AuthSelectors.isSigned(state),
        loading,
        userStatus: AuthSelectors.userStatus(state),
    };
}
