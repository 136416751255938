import { PROFILE_PATH } from "../../../../candidate/_constants/route.constants";
import {
    ADMIN_CANDIDATES_PATH,
    ADMIN_HIRE_PATH,
    ADMIN_TEAM_PATH,
    JOBS_PATH,
} from "../../../../common/_constants/route.constants";
import MobileNav from "../../../../common/components/nav/MobileNav/MobileNav";
import { ADMIN_WALLET_PATH } from "../../../_constants/route.constants";

export default function CompanyMobileNav() {
    return (
        <MobileNav
            links={[
                {
                    to: JOBS_PATH,
                    icon: "bag-light",
                },
                {
                    to: ADMIN_CANDIDATES_PATH,
                    icon: "candidate",
                },
                {
                    to: PROFILE_PATH,
                    icon: "buildings",
                },
                {
                    to: ADMIN_TEAM_PATH,
                    icon: "team",
                },
                // {
                //     to: ADMIN_WALLET_PATH,
                //     icon: "bar-chart",
                // },
                {
                    to: ADMIN_HIRE_PATH,
                    icon: "head",
                },
            ]}
        />
    );
}
