export const GET_CANDIDATE_CARDS = 'GET_CANDIDATE_CARDS';
export const GET_CANDIDATE_CARDS_SUCCESS = 'GET_CANDIDATE_CARDS_SUCCESS';
export const GET_CANDIDATE_CARDS_FAIL = 'GET_CANDIDATE_CARDS_FAIL';

export const GET_CANDIDATE_CARDS_SAVED = 'GET_CANDIDATE_CARDS_SAVED';
export const GET_CANDIDATE_CARDS_SAVED_SUCCESS =
    'GET_CANDIDATE_CARDS_SAVED_SUCCESS';
export const GET_CANDIDATE_CARDS_SAVED_FAIL = 'GET_CANDIDATE_CARDS_SAVED_FAIL';

export const CANDIDATE_CARDS_CHANGE_PAGE = 'CANDIDATE_CARDS_CHANGE_PAGE';
export const CANDIDATE_CARDS_SAVED_CHANGE_PAGE = 'CANDIDATE_CARDS_CHANGE_PAGE';

export const GET_CANDIDATE_PROFILE = 'GET_CANDIDATE_PROFILE';
export const GET_CANDIDATE_PROFILE_SUCCESS = 'GET_CANDIDATE_PROFILE_SUCCESS';
export const GET_CANDIDATE_PROFILE_FAIL = 'GET_CANDIDATE_PROFILE_FAIL';

export const GET_CANDIDATE_PROFILE_MATCH = 'GET_CANDIDATE_PROFILE_MATCH';
export const GET_CANDIDATE_PROFILE_MATCH_SUCCESS =
    'GET_CANDIDATE_PROFILE_MATCH_SUCCESS';
export const GET_CANDIDATE_PROFILE_MATCH_FAIL =
    'GET_CANDIDATE_PROFILE_MATCH_FAIL';

export const INVITE_CANDIDATE = 'INVITE_CANDIDATE';
export const INVITE_CANDIDATE_SUCCESS = 'INVITE_CANDIDATE_SUCCESS';
export const INVITE_CANDIDATE_FAIL = 'INVITE_CANDIDATE_FAIL';

export const SET_FAVOURTIE_CANDIDATE = 'SET_FAVOURTIE_CANDIDATE';
export const SET_FAVOURTIE_CANDIDATE_SUCCESS =
    'SET_FAVOURTIE_CANDIDATE_SUCCESS';
export const SET_FAVOURTIE_CANDIDATE_FAIL = 'SET_FAVOURTIE_CANDIDATE_FAIL';

export const UNSET_FAVOURTIE_CANDIDATE = 'UNSET_FAVOURTIE_CANDIDATE';
export const UNSET_FAVOURTIE_CANDIDATE_SUCCESS =
    'UNSET_FAVOURTIE_CANDIDATE_SUCCESS';
export const UNSET_FAVOURTIE_CANDIDATE_FAIL = 'UNSET_FAVOURTIE_CANDIDATE_FAIL';
