import React from "react";
import { formatMoney, useFormatMoneyRange } from "../../_helpers";

type FormatMoneyCommonProps = {
    currency: string;
    locale?: string;
};

interface FormatMoneyProps extends FormatMoneyCommonProps {
    value: number;
}

export function FormatMoney(props: FormatMoneyProps) {
    const { value, locale, currency, ...rest } = props;
    return <span {...rest}>{formatMoney(value, currency, locale)}</span>;
}

interface FormatMoneyRangeProps extends FormatMoneyCommonProps {
    min: number;
    max: number;
    frequency?: string;
}

export function FormatMoneyRange(props: FormatMoneyRangeProps) {
    const { min, max, currency, locale, frequency } = props;
    const formatMoneyRange = useFormatMoneyRange(locale);
    return <>{formatMoneyRange(min, max, currency, frequency)}</>;
}
