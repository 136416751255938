import { cx } from "@emotion/css";

import { SVGIcon } from "../..";
import { LocationMap } from "../../../../candidate/components/JobDetailContent/parts/LocationMap";
import ILocation from "../../../types/ILocation";
import { Panel } from "../../Panel/Panel";
import Skeleton from "../../Skeleton/Skeleton";
import styles from "./Locations.module.scss";

type LocationsProps = {
    locations: ILocation[];
    headquarterLocation: ILocation;
    isLoading: boolean;
};

export default function Locations(props: LocationsProps) {
    const { locations, headquarterLocation, isLoading } = props;

    return (
        <Panel>
            <Panel.Heading>Location</Panel.Heading>
            <div className={styles.grid}>
                {!isLoading
                    ? locations?.map((location, i) => (
                          <Location key={i} location={location} />
                      ))
                    : Array.from(new Array(4), (_, i) => (
                          <Location key={i} isLoading={isLoading} />
                      ))}
            </div>
            <div className={styles.map}>
                {!isLoading ? (
                    <LocationMap location={headquarterLocation} height={350} />
                ) : (
                    <Skeleton height={350} />
                )}
            </div>
        </Panel>
    );
}

function Location(props: { location?: ILocation; isLoading?: boolean }) {
    const { location, isLoading } = props;

    return (
        <div className={styles.location}>
            <div
                className={cx(
                    styles.icon,
                    isLoading ? styles.icon__load : null
                )}
            >
                <SVGIcon name="location-pin" />
            </div>
            <div>
                <div className={cx(styles.city, "font-heavy")}>
                    {!isLoading ? (
                        location?.city
                    ) : (
                        <Skeleton width={110} height={14} />
                    )}
                </div>
                <div className={cx(styles.address, "font-book")}>
                    {(() => {
                        if (!isLoading) {
                            return (
                                <>
                                    {location?.street} {location?.streetNumber},{" "}
                                    {location?.postcode}
                                </>
                            );
                        }

                        return (
                            <Skeleton
                                width={150}
                                height={14}
                                count={2}
                                marginBottom={2}
                            />
                        );
                    })()}
                </div>
            </div>
        </div>
    );
}
