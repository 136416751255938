import React from "react";
import { Link } from "react-router-dom";

import { Button } from "../../../../common/components/buttons/Button";

export function Section(props) {
    const { title, subTitle, linkProps, info, children, anchor } = props;

    return (
        <div className="section">
            <div id={anchor} className="anchor" />
            {title ? (
                <div className="section__header">
                    <div>
                        <div className="title">{title}</div>
                        <div className="subtitle font-book">{subTitle}</div>
                    </div>
                    {!!linkProps ? (
                        <div>
                            <Link to={linkProps.to}>
                                <Button>{linkProps.label}</Button>
                            </Link>
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className="section__content">{children}</div>
        </div>
    );
}
