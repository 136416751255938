import IHero from "../types/IHero";
import IPageableResponse from "../types/IPageableResponse";
import RequestService2 from "./RequestService2";

const BASE_PATH = "heroes/profiles";

const HeroService = {
    getList,
    getProfile,
};

export type GetListPayload = {
    page: number;
};

async function getList(payload: GetListPayload) {
    const { page } = payload;
    try {
        const response = await RequestService2.get<IPageableResponse<IHero>>(
            BASE_PATH,
            { page: page - 1 }
        );

        return response.data;
    } catch (ex) {
        throw ex;
    }
}

async function getProfile(id: number) {
    try {
        const response = await RequestService2.get<IHero>(`${BASE_PATH}/${id}`);

        return response.data;
    } catch (ex) {
        throw ex;
    }
}

export default HeroService;
