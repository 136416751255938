import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'react-translate.ts';

import { LoadingOverlay } from '../../../../common/components';
import {
    TRACKING_APPLIED,
    TRACKING_INVITED,
    TRACKING_ACCEPTED,
    TRACKING_SHORTLISTED,
} from '../../../../admin/_constants/actions/tracking.constants';
import { StatusColumn } from './StatusColumn';
import { ApplicationsTrackingActions } from '../../../_actions';

export function Archived({ profileId }) {
    const { isLoading, applied, accepted, invited, shortlisted } = useSelector(
        mapState
    );

    const getApplications = mapActions(useDispatch());

    const translate = useTranslate('candidate.jobs.tracking');

    useEffect(() => {
        if (profileId) {
            getApplications(profileId);
        }
    }, [profileId]);

    return (
        <div>
            <LoadingOverlay isLoading={isLoading} />
            <div id="applications-tracking--grid">
                <StatusColumn
                    label={translate('applied')}
                    type={TRACKING_INVITED}
                    cards={invited}
                />
                <StatusColumn
                    label={translate('shortlist')}
                    type={TRACKING_SHORTLISTED}
                    cards={shortlisted}
                />
                <StatusColumn
                    label={translate('invited')}
                    type={TRACKING_APPLIED}
                    cards={applied}
                />
                <StatusColumn
                    label={translate('accepted')}
                    type={TRACKING_ACCEPTED}
                    accept={TRACKING_APPLIED}
                    cards={accepted}
                />
            </div>
        </div>
    );
}

function mapState(state) {
    const { loading, archived } = state.candidate.jobs.tracking;
    return {
        isLoading: loading,
        applied: archived[TRACKING_APPLIED],
        accepted: archived[TRACKING_ACCEPTED],
        invited: archived[TRACKING_INVITED],
        shortlisted: archived[TRACKING_SHORTLISTED],
    };
}

function mapActions(dispatch) {
    return (profileId) =>
        void dispatch(
            ApplicationsTrackingActions.getArchivedApplications(profileId)
        );
}
