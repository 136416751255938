import CareerSearchConstants from "../_constants/actions/CareerSearchConstants"

const CareerSearchActions = {
    getJobRoles
}

function getJobRoles() {
    return { type: CareerSearchConstants.GET_JOB_ROLES } 
}

export default CareerSearchActions;