import { PropsWithChildren } from "react";

import { TooltipLabel } from "../../TooltipLabel";
import styles from "./ActionButton.module.scss";

export type ActionButtonProps = {
    onClick: () => void;
    label?: string;
};

export default function ActionButton(
    props: PropsWithChildren<ActionButtonProps>
) {
    const { onClick, label, children, ...rest } = props;
    return (
        <TooltipLabel label={label}>
            <button className={styles.button} onClick={onClick} {...rest}>
                {children}
            </button>
        </TooltipLabel>
    );
}
