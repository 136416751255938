import { takeLatest } from '@redux-saga/core/effects';

import LanguageConstants from '../_constants/actions/LanguageConstants';
import { SagaHelpers } from '../_helpers';
import { LanguageService } from '../_services/LanguageService';

function* getLanguages() {
    yield SagaHelpers.handleRequest({
        request: [LanguageService.getLanguages],
        actions: [
            LanguageConstants.GET_LANGUAGES_SUCCESS,
            LanguageConstants.GET_LANGUAGES_FAIL,
        ],
    });
}

function* getLevels() {
    yield SagaHelpers.handleRequest({
        request: [LanguageService.getLanguageLevels],
        actions: [
            LanguageConstants.GET_LEVELS_SUCCESS,
            LanguageConstants.GET_LEVELS_FAIL,
        ],
    });
}

export default [
    takeLatest(LanguageConstants.GET_LANGUAGES, getLanguages),
    takeLatest(LanguageConstants.GET_LEVELS, getLevels),
];
