import { createSelector } from "reselect";

import Personal from "./HeroOnboardingPersonalSelectors";
import Positions from "./HeroOnboardingPositionsSelectors";
import Skills from "./HeroOnboardingSkillsSelectors";
import Advice from "./HeroOnboardingAdviceSelectors";
import { STATE_LOADING } from "../../_constants/state.constants";

const onboarding = (state) => state.common.heroes.onboarding;

const isLoading = createSelector(
    onboarding,
    (onboarding) => onboarding.loading === STATE_LOADING
);

const getCurrentStep = createSelector(
    onboarding,
    (onboarding) => onboarding.steps.current
);

const HeroOnboardingSelectors = {
    Personal,
    Positions,
    Skills,
    Advice,
    getCurrentStep,
    isLoading,
};

export default HeroOnboardingSelectors;
