import { combineReducers } from 'redux';

import {
    GET_CANDIDATE_CARDS_SUCCESS,
    CANDIDATE_CARDS_CHANGE_PAGE,
    GET_CANDIDATE_CARDS_SAVED_SUCCESS,
} from '../../../_constants/actions/candidates.constants';

const initialState = {
    elements: 0,
    page: 0,
    pages: 0,
};

const cards = (state = initialState, action) => {
    return createReducer(state, action, GET_CANDIDATE_CARDS_SUCCESS);
};

const saved = (state = initialState, action) => {
    return createReducer(state, action, GET_CANDIDATE_CARDS_SAVED_SUCCESS);
};

export default combineReducers({ cards, saved });

function createReducer(state, action, actionName) {
    switch (action.type) {
        case actionName:
            return getCardsSuccess(action.payload);
        case CANDIDATE_CARDS_CHANGE_PAGE:
            return { ...state, page: action.payload };
        default:
            return state;
    }
}

function getCardsSuccess(payload) {
    const { pageable, totalElements, totalPages } = payload;

    return {
        page: pageable.pageNumber,
        elements: totalElements,
        pages: totalPages,
    };
}
