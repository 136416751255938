import {
    GET_SKILLS_BEGIN,
    GET_SKILLS_SUCCESS,
    GET_SKILL_CATEGORIES_ERROR,
    GET_SKILL_CATEGORIES_SUCCESS,
    GET_SKILL_CATEGORIES_BEGIN,
    GET_SKILLS_ERROR,
    GET_SOFT_SKILLS,
    GET_INTERPRETATION,
    GET_INTERPRETATION_SUCCESS,
    GET_INTERPRETATION_FAIL
} from '../_constants/actions/skill.contants';
import { SkillService } from '../_services';

export const SkillActions = {
    getCategories,
    getSkills,
    getSoftSkills,
    getSoftSkillsInterpretation,
    getSoftSkillsInterpretationSuccess,
    getSoftSkillsInterpretationFail
};

function getCategories() {
    return dispatch => {
        dispatch({ type: GET_SKILL_CATEGORIES_BEGIN });

        SkillService.getCategories()
            .then(response =>
                dispatch({
                    type: GET_SKILL_CATEGORIES_SUCCESS,
                    payload: { categories: response.data }
                })
            )
            .catch(response =>
                dispatch({
                    type: GET_SKILL_CATEGORIES_ERROR,
                    error: response.error
                })
            );
    };
}

function getSkills(categoryId) {
    return dispatch => {
        dispatch({ type: GET_SKILLS_BEGIN });

        SkillService.getSkills(categoryId)
            .then(response =>
                dispatch({
                    type: GET_SKILLS_SUCCESS,
                    payload: { skills: response.data }
                })
            )
            .catch(response =>
                dispatch({ type: GET_SKILLS_ERROR, error: response.error })
            );
    };
}

function getSoftSkills() {
    return { type: GET_SOFT_SKILLS };
}

/**
 *
 * @param {string} name
 */
function getSoftSkillsInterpretation(name) {
    return { type: GET_INTERPRETATION, payload: name };
}

function getSoftSkillsInterpretationSuccess(interpretation) {
    return { type: GET_INTERPRETATION_SUCCESS, payload: interpretation };
}

/**
 *
 * @param {string} error
 */
function getSoftSkillsInterpretationFail(error) {
    return { type: GET_INTERPRETATION_FAIL, error };
}
