import React from 'react';
import { addClass } from 'classname-helper';

export function ListCard({ children, header, className }) {
    return render();
    function render() {
        return (
            <div className={addClass('list-card', className)}>
                {header ? (
                    <div className="list-card-header">{header}</div>
                ) : null}
                {children}
            </div>
        );
    }
}
