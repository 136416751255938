import { RequestService, UserService } from '../../common/_services';

export const SkillService = {
    getCategories,
    getSkills,
    getSoftSkills,
    getSoftSkillsInterpretation
};

function getCategories() {
    return RequestService.get('skill-categories');
}

function getSkills(categoryId) {
    return RequestService.get(`skills?categoryId=${categoryId}`);
}

function getSoftSkills() {
    const profileId = UserService.getProfileId();
    return RequestService.get(`api/profiles/${profileId}/soft-skills`);
}

/**
 *
 * @param {string} name
 */
function getSoftSkillsInterpretation(name) {
    const profileId = UserService.getProfileId();
    return RequestService.get(`api/profiles/${profileId}/soft-skills/${name}`);
}
