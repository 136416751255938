import React from "react";
import ReactQuestionnaire from "@tcc-online/react-questionnaire";
import { Theme } from "@tcc-online/react-theme-provider";
import { IQuestionnaire } from "@tcc-online/react-questionnaire/build/types";

export interface TccQuestionnaireProps {
    questionnaireId: number;
    locale: string;
    refreshToken: string;
    endpoint?: string;
    onLogout?: () => void;
    onTimerStart?: (timerLimit: number, questionnaireId: number) => void;
    showOutputReport?: boolean;
    onQuestionnaireLoad?: (questionnaire: IQuestionnaire) => void;
    onLoadingStart?: () => void;
    onLoadingEnd?: () => void;
    timeBeforeLoadingShows?: number;
    onQuestionnaireClosed?: (payload: {
        displayResults: boolean;
        reportId?: number;
        questionnaireId: number;
    }) => void;
    theme?: Theme;
    overrideTheme?: boolean;
}

export default function TccQuestionnaire(props: TccQuestionnaireProps) {
    const { endpoint = process.env.REACT_APP_API_ENDPOINT || "", timeBeforeLoadingShows = 4000, ...restProps } = props;

    const { REACT_APP_SENTRY_DSN } = process.env;

    return (
        <ReactQuestionnaire
            {...restProps}
            apiConfig={{
                endpoint: endpoint,
                sentryOptions: {
                    dsn: REACT_APP_SENTRY_DSN,
                },
            }}
            timeBeforeLoadingShows={timeBeforeLoadingShows}
        />
    );
}
