import { PropsWithChildren } from "react";
import { useTranslate } from "react-translate.ts";
import { SVGIcon } from "../..";
import Button from "../../buttons/Button/Button";
import BaseDropdown from "../BaseDropdown/BaseDropdown";

import styles from "./ActionDropdown.module.scss";

type ActionDropdownProps = {
    onClose?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    cancelButtonProps?: ButtonProps;
    confirmButtonProps?: ButtonProps;
};

type ButtonProps = { label?: string };

export default function ActionDropdown(
    props: PropsWithChildren<ActionDropdownProps>
) {
    const translate = useTranslate("common.actions");
    const {
        children,
        onClose,
        onConfirm,
        onCancel,
        cancelButtonProps = { label: translate("cancel") },
        confirmButtonProps = { label: translate("confirm") },
    } = props;

    return (
        <BaseDropdown onBackdropClick={onClose}>
            {onClose ? (
                <button className={styles.close} onClick={onClose}>
                    <SVGIcon name="close" />
                </button>
            ) : null}
            <div className={styles.content}>{children}</div>
            {onConfirm || onCancel ? (
                <div className={styles.actions}>
                    <div className={styles.actions__buttons}>
                        {onCancel ? (
                            <Button variant="secondary" onClick={onCancel}>
                                {cancelButtonProps.label}
                            </Button>
                        ) : null}
                        {onConfirm ? (
                            <Button onClick={onConfirm}>
                                {confirmButtonProps.label}
                            </Button>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </BaseDropdown>
    );
}
