import React, { useRef, useEffect } from "react";

type SvelteWrapperProps = {
    id?: string;
    svelte: any;
    data: any;
};

export default function SvelteWrapper(props: SvelteWrapperProps) {
    const { svelte, data, ...rest } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const component = useRef<any>();

    useEffect(() => {
        component.current = new svelte({
            target: ref.current,
            props: data
        });

        return () => {
            component.current.$destroy();
            component.current = null;
        };
    }, [ref]);

    useEffect(() => {
        if (component.current) {
            component.current.$set(data);
        }
    }, [ref, data]);

    return <div ref={ref} {...rest} />;
}
// export class SvelteWrapper extends React.Component {
//     constructor(props) {
//         super();

//         this.container = React.createRef();
//         this.instance = null;
//         this.div = React.createElement('div', {
//             ref: this.container,
//             id: props.id,
//             className: props.className,
//             children: props.children,
//         });
//     }

//     componentDidMount() {
//         const { this: Constructor, data } = this.props;

//         this.instance = new Constructor({
//             target: this.container.current,
//             props: data,
//         });
//     }

//     componentDidUpdate() {
//         this.instance.$$set(this.props.data);
//     }

//     componentWillUnmount() {
//         this.instance.$$destroy();
//     }

//     render() {
//         return this.div;
//     }
// }
