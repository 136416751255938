import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Switch, Router } from "react-router-dom";

import setup from "./setup";

// import 'moment/locale/cs';
// import 'moment/locale/sk';
import "./theme/sass/app.scss";

import { WindowStateProvider } from "./common/components";
import { App } from "./App";
import { store } from "./store/store";

import { history } from "./common/_helpers";
import reportWebVitals from "./reportWebVitals";

setup();

let render;
if (window.location.hostname === "localhost") {
    render = ReactDOM.render;
} else {
    render = ReactDOM.hydrate;
}

render(
    <WindowStateProvider>
        <Provider store={store}>
            <Router history={history}>
                <Switch>
                    <Redirect exact from="/" to="/profile/information" />
                    <App />
                </Switch>
            </Router>
        </Provider>
    </WindowStateProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
