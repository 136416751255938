import { all } from "redux-saga/effects";

import common from "./common/_sagas/index";
import candidate from "./candidate/_sagas/index";
import admin from "./admin/_sagas/index";

function* rootSaga() {
  yield all([...common, ...candidate, ...admin]);
}

export default rootSaga;
