import React from "react";
import { Translate } from "react-translate.ts";

import { FormatedDate } from "../../../../common/components";

export function InformationDate({ started, ended, format }) {
    const currentFormat = format || "MMM YYYY";
    return (
        <span>
            <FormatedDate date={started} format={currentFormat} /> -{" "}
            {renderEndDate(ended)}
        </span>
    );

    function renderEndDate(date) {
        if (!date) {
            return <Translate textOnly>common.misc.date.present</Translate>;
        } else {
            return <FormatedDate date={ended} format={currentFormat} />;
        }
    }
}
