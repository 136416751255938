import { StorageService } from "./StorageService";

const CONFIG_KEY = "cookie-config";
const CONSENT_KEY = "cookie-consent";

const CookieConfigService = {
    getConfig,
    setConfig,
    setConsent,
    hasConsent,
};

function getConfig() {
    return parseInt(StorageService.getLocal(CONFIG_KEY, 0));
}

function setConfig(value: number) {
    StorageService.setLocal(CONFIG_KEY, value.toString());
}

function setConsent() {
    StorageService.setLocal(CONSENT_KEY, "1");
}

function hasConsent() {
    return StorageService.hasLocal(CONSENT_KEY);
}

export default CookieConfigService;
