import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { OnboardingLinks } from "../../../components";
import { UserProfileSelectors } from "../../../_selectors";
import { ArrayHelper } from "../../../_helpers/ArrayHelper";
import { ONBOARDING_USER_STATUS } from "../../../_constants/user.constants";
import OnboardingSelectors from "../../../_selectors/OnboardingSelectors";
import { OnboardingActions } from "../../../_actions";
import ProfileSelectors from "../../../../candidate/_selectors/ProfileSelectors";

export function Links() {
    const { step, stepsCompleted, imageUrl, name } = useSelector(mapState);

    const dispatch = useDispatch();

    const steps = [
        { name: "Personal Details" },
        { name: "Hard skills" },
        { name: "Personality Test" },
    ];

    let nextStepIsValid = false;
    const statuses = Object.keys(ONBOARDING_USER_STATUS);
    if (statuses[step]) {
        if (
            !ArrayHelper.isEmpty(stepsCompleted) &&
            stepsCompleted.find((item) => item.status === statuses[step])
        ) {
            nextStepIsValid = true;
        }
    }

    return (
        <OnboardingLinks
            steps={steps}
            currentStep={step}
            nextStepIsValid={nextStepIsValid}
            imageUrl={imageUrl}
            name={name}
            onForward={() => dispatch(OnboardingActions.addStep(1))}
            onBack={() => dispatch(OnboardingActions.addStep(-1))}
        />
    );
}

function mapState(state) {
    return {
        step: OnboardingSelectors.getCurrentStep(state),
        stepsCompleted: OnboardingSelectors.getStepsCompleted(state),
        imageUrl: UserProfileSelectors.getImage(state),
        name: ProfileSelectors.getFirstName(state),
    };
}
