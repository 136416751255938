import { Translate } from "react-translate.ts";
import IBenefit from "../../../../common/types/IBenefit";
import styles from "./FloatingCard.module.scss";
import FloatingCardContent from "./FloatingCardContent";

type FloatingCardProps = {
    currency: string;
    salaryMin: number;
    salaryMax?: number;
    payRaise?: string;
    benefits?: IBenefit[];
    stats?: string[];
    isLoading: boolean;
};

export default function FloatingCard(props: FloatingCardProps) {
    return (
        <div className={styles.card}>
            <Translate className={styles.title}>
                candidate.jobs.detail.fixedPanel.heading
            </Translate>
            <FloatingCardContent {...props} />
        </div>
    );
}
