const JobDetailConstants = {
    JOB_DETAIL_BEGIN: "JOB_DETAIL_BEGIN",
    JOB_DETAIL_SUCCESS: "JOB_DETAIL_SUCCESS",
    JOB_DETAIL_ERROR: "JOB_DETAIL_ERROR",

    JOB_DETAIL_APPLY_BEGIN: "JOB_DETAIL_APPLY_BEGIN",
    JOB_DETAIL_APPLY_SUCCESS: "JOB_DETAIL_APPLY_SUCCESS",
    JOB_DETAIL_APPLY_ERROR: "JOB_DETAIL_APPLY_ERROR",

    JOB_DETAIL__CHANGE_APPLICATION_STATE:
        "JOB_DETAIL__CHANGE_APPLICATION_STATE",
};

export default JobDetailConstants;

export const GET_JOB_DETAIL = "GET_JOB_DETAIL";
export const GET_JOB_DETAIL_SUCCESS = "GET_JOB_DETAIL_SUCCESS";
export const GET_JOB_DETAIL_FAIL = "GET_JOB_DETAIL_FAIL";

export const JOB_DETAIL_APPLY = "JOB_DETAIL_APPLY";
export const JOB_DETAIL_APPLY_SUCCESS = "JOB_DETAIL_APPLY_SUCCESS";
export const JOB_DETAIL_APPLY_FAIL = "JOB_DETAIL_APPLY_FAIL";

export const JOB_DETAIL_ACCEPT = "JOB_DETAIL_ACCEPT";
export const JOB_DETAIL_ACCEPT_SUCCESS = "JOB_DETAIL_ACCEPT_SUCCESS";
export const JOB_DETAIL_ACCEPT_FAIL = "JOB_DETAIL_ACCEPT_FAIL";
