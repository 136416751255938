import * as api from "../../types/api";
import { LanguageService, UserService } from "./";
import { GAUtils } from "../_helpers/GAUtils";
import { AuthService } from "./AuthService";
import { ArrayHelper } from "../_helpers/ArrayHelper";

export const RequestService = {
    get,
    post,
    put,
    delete: _delete,
    handleResponse,
};

function get(path, urlParams, headers = {}) {
    const requestOptions = { method: "GET", headers };
    return fetchResource(createUrl(path, urlParams), requestOptions);

    function createUrl(path, urlParams) {
        if (!urlParams) {
            return path;
        }

        const params = urlParams.filter((param) => {
            let value;
            if (Array.isArray(param)) {
                value = param[1];
            } else {
                value = param.value;
            }

            if (value === null || value === undefined) {
                return false;
            }

            if (Array.isArray(value) && ArrayHelper.isEmpty(value)) {
                return false;
            }

            return true;
        });

        if (params.length === 0) {
            return path;
        }

        const result = params.map((param) => {
            let value, name;
            if (Array.isArray(param)) {
                name = param[0];
                value = param[1];
            } else {
                name = param.name;
                value = param.value;
            }
            return `${name}=${value}`;
        });
        return `${path}?${result.join("&")}`;
    }
}

function post(path, data, requestOptions, options = { rawData: false }) {
    GAUtils.event("Request", "POST SENT " + path);
    const _requestOptions = {
        body: options.rawData === true ? data : JSON.stringify(data),
        ...requestOptions,
        method: "POST",
    };
    return fetchResource(path, _requestOptions);
}

function put(path, data) {
    GAUtils.event("Request", "PUT SENT " + path);
    const requestOptions = { method: "PUT", body: JSON.stringify(data) };
    return fetchResource(path, requestOptions);
}

function _delete(path) {
    GAUtils.event("Request", "DELETE SENT " + path);
    const requestOptions = { method: "DELETE" };
    return fetchResource(path, requestOptions);
}

function fetchResource(path, requestOptions) {
    requestOptions.headers = Object.assign(
        {},
        {
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getAccessToken()}`,
            "Accept-Language": LanguageService.getCurrentLocale(),
        },
        requestOptions.headers
    );

    return fetch(`${api.endpoint}/${path}`, requestOptions).then(
        handleResponse
    );
}

function handleResponse(response) {
    GAUtils.event(
        "Response",
        "Received  " + response.status + " " + new URL(response.url).pathname
    );

    if (!response.ok) {
        if (response.status === 401) {
            AuthService.unauthorizeUser();
        }
    } else {
        if (response.status === 204) {
            return Promise.resolve({
                status: response.status,
                ok: true,
            });
        }
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
        return response
            .json()
            .then((data) => {
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject({
                        status: response.status,
                        error: error.toString(),
                        ok: false,
                    });
                }

                return Promise.resolve({
                    status: response.status,
                    data,
                    ok: true,
                });
            })
            .catch((response) => {
                if (typeof response === "string") {
                    return Promise.reject({
                        status: 500,
                        error: response.toString(),
                        ok: false,
                    });
                }

                const { error, status } = response;
                return Promise.reject({
                    status,
                    error: error ? error.toString() : error,
                    ok: false,
                });
            });
    }

    return response
        .text()
        .then((text) => {
            if (!response.ok) {
                const error = response.statusText;
                return Promise.reject({
                    status: response.status,
                    error: error.toString(),
                    ok: false,
                });
            }

            return Promise.resolve({
                status: response.status,
                data: text,
                ok: true,
            });
        })
        .catch((response) => {
            if (typeof response === "string") {
                return Promise.reject({
                    status: 500,
                    error: response.toString(),
                    ok: false,
                });
            }

            const { error, status } = response;
            return Promise.reject({
                status,
                error: error.toString(),
                ok: false,
            });
        });
}
