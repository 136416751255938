import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import { UserActions } from "../../../_actions/UserActions";
import { AuthActions } from "../../../_actions";
import {
    ACTIVE_STATUS,
    VERIFICATION_PENDING_STATUS,
} from "../../../_constants/user.constants";
import {
    Loading,
    TokenValidator,
    Button,
    OnboardingForm,
} from "../../../components";
import { AuthSelectors } from "../../../_selectors";
import {
    DASHBOARD_PATH,
    ONBOARDING_PATH,
} from "../../../_constants/route.constants";
import OnboardingPageLayout from "../../../components/layout/OnboardingPageLayout/OnboardingPageLayout";
import styles from "./VerificationPage.module.scss";
import OnboardingLayout from "../Onboarding/OnboardingLayout/OnboardingLayout";
import { MainState } from "../../../../root.reducer";

export default function VerificationPage() {
    const dispatch = useDispatch();
    const { user, loading, signed, userStatus } = useSelector(mapState);
    const { token } = useParams<{ token: string }>();
    const history = useHistory();
    const translate = useTranslate("common.onboarding.verify");

    useEffect(() => {
        if (token) {
            verifyUser(token);
        }
    }, [token]);

    useEffect(() => {
        if (signed && userStatus === ACTIVE_STATUS) {
            history.push(DASHBOARD_PATH);
        }
    }, [history, signed, userStatus]);

    if (loading) {
        return <Loading />;
    }

    if (userStatus !== VERIFICATION_PENDING_STATUS) {
        return <Redirect to={ONBOARDING_PATH} />;
    }

    // if (token) {
    //     return (
    //         <h2>
    //             <Translate textOnly>auth.verification.loading</Translate>
    //         </h2>
    //     );
    // }

    return (
        <OnboardingPageLayout>
            <OnboardingLayout
                color="red"
                imageProps={{ src: "/img/onboarding/verification.svg" }}
            >
                <OnboardingForm>
                    <h1 className="font-light">{translate("title")}</h1>
                    <div className={styles.email}>{user.username}</div>
                    <h6 className={styles.h}>{translate("subtitle")}</h6>
                    <TokenValidator
                        onValidation={verifyUser}
                        className="w100 margin-bottom-40"
                        autoValidate
                    />
                    <Button
                        variant="white"
                        onClick={generateVerificationToken}
                        isPill
                    >
                        {translate("generate")}
                    </Button>
                </OnboardingForm>
            </OnboardingLayout>
        </OnboardingPageLayout>
    );

    function generateVerificationToken() {
        dispatch(UserActions.generateVerificationToken());
    }

    function verifyUser(token: string) {
        dispatch(AuthActions.verifyUser(token));
    }
}

function mapState(state: MainState) {
    const { user, loading } = state.common.auth;

    return {
        user,
        loading,
        userStatus: AuthSelectors.userStatus(state),
        signed: AuthSelectors.isSigned(state),
    };
}
