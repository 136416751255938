import React from "react";
import {
    Dot,
    Radar,
    Tooltip,
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
} from "recharts";
import { useTranslate } from "react-translate.ts";

import {
    PRIMARY_COLOR,
    BACKGROUND_COLOR,
    BREAKPOINT_SM,
} from "../../_constants/theme.constants";
import { useWindowState } from "../misc";
import { ArrayHelper } from "../../_helpers/ArrayHelper";
import { MathHelper } from "../../_helpers";
import { BaseTooltip } from "./BaseTooltip";

/**
 *
 * @param {{data: any[]; height?: number | string; width?: number | string; }} props
 */
export function SoftSkillsChart({
    data,
    height = "100%",
    width = "100%",
    maxHeight,
    radius,
    displayOnly = true,
    idealMaxKey = "idealMax",
    idealMinKey = "idealMin",
    valueKey = "you",
}) {
    const windowWidth = useWindowState().width;
    const isMobile = windowWidth < BREAKPOINT_SM;

    const translate = useTranslate("common.misc");

    return (
        <div className="soft-skills-radar" style={{ width, height, maxHeight }}>
            <ResponsiveContainer
                width="100%"
                height={height}
                maxHeight={maxHeight}
            >
                <RadarChart
                    data={data}
                    isAnimationActive={false}
                    height={height}
                    outerRadius={radius}
                >
                    <Radar
                        dataKey={idealMaxKey}
                        name="Ideal Max"
                        fill={PRIMARY_COLOR}
                        fillOpacity={0.15}
                        strokeWidth={0}
                        activeDot={false}
                        isAnimationActive={false}
                    />
                    <Radar
                        dataKey={idealMinKey}
                        name="Ideal Min"
                        fill={BACKGROUND_COLOR}
                        fillOpacity={1}
                        strokeWidth={0}
                        activeDot={false}
                        isAnimationActive={false}
                    />
                    <PolarGrid gridType="circle" stroke="#e7e6e6" />
                    <Radar
                        name="You"
                        dataKey={valueKey}
                        stroke={PRIMARY_COLOR}
                        fill={PRIMARY_COLOR}
                        fillOpacity={0}
                        strokeWidth={3}
                        dot={(props) => {
                            const { strokeWidth } = props;
                            return (
                                <Dot
                                    {...props}
                                    r={strokeWidth * 2.15}
                                    strokeWidth={strokeWidth}
                                    stroke="#fff"
                                    fillOpacity={1}
                                />
                            );
                        }}
                        isAnimationActive={false}
                    />
                    <Radar
                        legendType="none"
                        dataKey="border"
                        fill="#fff"
                        fillOpacity={0}
                        strokeWidth={2}
                        isAnimationActive={false}
                        dot={(props) => {
                            const { strokeWidth } = props;
                            return (
                                <Dot
                                    {...props}
                                    r={strokeWidth * 1.5}
                                    strokeWidth={strokeWidth}
                                    stroke="#ccc"
                                    fillOpacity={1}
                                />
                            );
                        }}
                        activeDot={false}
                    />
                    <Tooltip
                        isAnimationActive={false}
                        content={(props) => {
                            const { payload } = props;
                            const data =
                                payload &&
                                !ArrayHelper.isEmpty(payload) &&
                                payload[0].payload;
                            const { idealMin, idealMax, you, match } = data
                                ? data
                                : {};

                            return (
                                <BaseTooltip
                                    isAbsolute={false}
                                    withArrow={false}
                                    title={`${translate(
                                        "match"
                                    )} ${MathHelper.round(match * 100, 0)}%`}
                                    panels={[
                                        {
                                            label: translate(
                                                displayOnly
                                                    ? "candidateScore"
                                                    : "yourScore"
                                            ),
                                            value: you + "%",
                                        },
                                        {
                                            label: translate("requiredScore"),
                                            value: `${idealMin}-${idealMax}%`,
                                        },
                                    ]}
                                />
                            );
                        }}
                    />
                    <PolarAngleAxis
                        dataKey={isMobile ? "index" : "name"}
                        tickLine={{ size: isMobile ? 12 : 20 }}
                        tick={{ fill: "#293140", fontSize: isMobile ? 10 : 14 }}
                    />
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );
}
