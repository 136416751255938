import React, { PropsWithChildren } from "react";

import Header, { HeaderProps } from "../../nav/Header/Header";
import styles from "./OnboardingPageLayout.module.scss";

type OnboardingPageLayoutProps = PropsWithChildren<{
    headerProps?: HeaderProps;
}>;

export default function OnboardingPageLayout(props: OnboardingPageLayoutProps) {
    const { children, headerProps = {} } = props;

    return (
        <div className={styles.page}>
            <Header showMainMenu showProfileProgress={false} {...headerProps} />
            <main>{children}</main>
        </div>
    );
}
