import { useRef, useEffect, useState } from 'react';

export function useAnimateValue(start, add = 1, interval = 1000) {
    const timer = useRef(null);
    const [value, setValue] = useState(start);

    useEffect(() => {
        timer.current = setInterval(animate, interval);

        return () => clearInterval(timer.current);
    }, [start, add, interval, value, setValue]);

    return value;

    function animate() {
        setValue(value + add);
    }
}
