import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation,
    Redirect,
    useParams,
} from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import {
    SOFT_SKILL_TEST_PATH,
    SOFT_SKILL_TEST_MOP_PATH,
    SOFT_SKILL_TEST_KAKO_PATH,
    SOFT_SKILL_TEST_PRESS_PATH,
    SOFT_SKILL_TEST_ABSTRACT_PATH,
    SOFT_SKILL_TEST_COMPARE_MOP_PATH,
    SOFT_SKILL_TEST_COMPARE_KAKO_PATH,
    SOFT_SKILL_TEST_COMPARE_ABSTRACT_PATH,
    SOFT_SKILL_TEST_COMPARE_PRESS_PATH,
    SOFT_SKILL_TEST_COMPARE_NUMERIC_PATH,
    SOFT_SKILL_TEST_NUMERIC_PATH,
    SOFT_SKILL_TEST_PARAM_PATH, SOFT_SKILL_TEST_CULTURE_PATH, SOFT_SKILL_TEST_COMPARE_CULTURE_PATH
} from "../../_constants/route.constants";
import { Mop } from "./components/Mop";
import { Press } from "./components/Press";
import { Kako } from "./components/Kako";
import { Abstract } from "./components/Abstract";
import DashboardLinks from "./components/DashboardLinks/DashboardLinks";
import { AuthSelectors } from "../../../common/_selectors";
import { INTERPRETATION_HOFIK_PATH, INTERPRETATION_MOP_PATH } from "../../../common/_constants/route.constants";
import { MopInterpretation } from "../Interpretation/MopInterpretation";
import { SkillsComparison } from "./components/SkillsComparison";
import { BREAKPOINT_SM } from "../../../common/_constants/theme.constants";
import {
    useWindowState,
    BasePageLayout,
    ScrollToTop,
} from "../../../common/components";
import { Numeric } from "./components/Numeric";
import { CompleteRedirect } from "./components/CompleteRedirect";
import { createClass } from "classname-helper";
import { InterpretationShare } from "../Interpretation/InterpretationShare";
import { InviteFriendsDialog } from "./components/InviteFriendsDialog";
import SoftSkillsSelectors from "../../_selectors/SoftSkillsSelectors";
import SoftSkillsActions from "../../_actions/SoftSkillsActions";
import InterpretationActions from "../../_actions/InterpretationActions";
import UserBasicPageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";
import Hofik from "./components/Hofik";
import { HofikInterpretation } from "../Interpretation/Hofik/HofikInterpretation/HofikInterpretation";
import TranslationConstants from "../../../common/_constants/TranslationConstants";

export const ALL_TAB = 0;
export const SOFT_SKILLS_TAB = 1;
export const PERFORMANCE_TAB = 2;

export function SoftSkillTest() {
    const dispatch = useDispatch();
    const params = useParams();
    const { profileId, status } = useSelector((state) =>
        mapState(state, params.test)
    );
    const translate = useTranslate(TranslationConstants.softSkillsTest);


    const isLocked = status && status.unlocked === false;

    const match = useRouteMatch();
    const { pathname } = useLocation();

    const windowState = useWindowState();
    const isMobile = windowState.width < BREAKPOINT_SM;

    const [selectedTab, setSelectedTab] = useState(ALL_TAB);

    const [shareDialogOpen, setShareDialogOpen] = useState(isLocked);

    useEffect(() => {
        if (isLocked) {
            setShareDialogOpen(isLocked);
        }
    }, [status, isLocked]);

    if (!isMobile && pathname === SOFT_SKILL_TEST_PATH) {
        return <Redirect to={SOFT_SKILL_TEST_MOP_PATH} />;
    }

    return (
        <UserBasicPageLayout>
            <ScrollToTop />
            <Switch>
                <Route path={INTERPRETATION_MOP_PATH}>
                    <MopInterpretation />
                </Route>
                <Route path={INTERPRETATION_HOFIK_PATH}>
                    <HofikInterpretation />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_MOP_PATH}>
                    <SkillsComparison name="mop" />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_KAKO_PATH}>
                    <SkillsComparison name="kako" />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_ABSTRACT_PATH}>
                    <SkillsComparison name="abs" />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_PRESS_PATH}>
                    <SkillsComparison name="press" />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_NUMERIC_PATH}>
                    <SkillsComparison name="num" />
                </Route>
                <Route path={SOFT_SKILL_TEST_COMPARE_CULTURE_PATH}>
                    <SkillsComparison name="hofik" />
                </Route>
                <Route path={match.path + "/complete/:requestId"}>
                    <CompleteRedirect />
                </Route>
                <Route path={SOFT_SKILL_TEST_PARAM_PATH}>
                    <BasePageLayout
                        id="soft-skills-test"
                        heading={
                            isMobile && !match.isExact
                                ? null
                                : translate("heading")
                        }
                        linksContent={isMobile ? null : <Links />}
                        contentProps={{
                            style: {
                                display:
                                    isMobile && !match.isExact ? "none" : null,
                            },
                        }}
                        withShareButton={!!status}
                        onShare={() => setShareDialogOpen(true)}
                        shareButtonProps={{
                            label: isLocked ? translate("inviteFriends") : null,
                        }}
                    >
                        {status && status.unlocked ? (
                            <InterpretationShare
                                isOpen={shareDialogOpen}
                                close={() => setShareDialogOpen(false)}
                            />
                        ) : (
                            <InviteFriendsDialog
                                isOpen={shareDialogOpen}
                                close={() => setShareDialogOpen(false)}
                            />
                        )}

                        <DashboardLinks
                            profileId={profileId}
                            selectedTab={selectedTab}
                        />
                        <Switch>
                            <Route path={SOFT_SKILL_TEST_MOP_PATH}>
                                <Mop
                                    isMobile={isMobile}
                                    getStatus={getMopStatus}
                                    getInterpretation={getMopInterpretation}
                                />
                            </Route>
                            <Route path={SOFT_SKILL_TEST_KAKO_PATH}>
                                <Kako />
                            </Route>
                            <Route path={SOFT_SKILL_TEST_PRESS_PATH}>
                                <Press />
                            </Route>
                            <Route path={SOFT_SKILL_TEST_ABSTRACT_PATH}>
                                <Abstract />
                            </Route>
                            <Route path={SOFT_SKILL_TEST_NUMERIC_PATH}>
                                <Numeric />
                            </Route>
                            <Route path={SOFT_SKILL_TEST_CULTURE_PATH}>
                                <Hofik />
                            </Route>
                            <Route>
                                <Redirect to={SOFT_SKILL_TEST_MOP_PATH} />
                            </Route>
                        </Switch>
                    </BasePageLayout>
                </Route>
                <Route>
                    <Redirect to={SOFT_SKILL_TEST_MOP_PATH} />
                </Route>
            </Switch>
        </UserBasicPageLayout>
    );

    function getMopStatus(profileId, name) {
        dispatch(SoftSkillsActions.getStatus(profileId, name));
    }

    function getMopInterpretation(profileId, name) {
        dispatch(InterpretationActions.getInterpretation(profileId, name));
    }

    function Links() {
        const translateLinks = useTranslate(TranslationConstants.softSkillsTest + ".links");

        return (
            <>
                {renderItem(translateLinks("all"), ALL_TAB)}
                {renderItem(translateLinks("softSkills"), SOFT_SKILLS_TAB)}
                {renderItem(translateLinks("performance"), PERFORMANCE_TAB)}
            </>
        );

        function renderItem(label, index) {
            const isActive = index === selectedTab;
            return (
                <div
                    className={createClass({
                        "nav-link": true,
                        active: isActive,
                    })}
                    onClick={() => setSelectedTab(index)}
                >
                    {label}
                </div>
            );
        }
    }
}

function mapState(state, testName) {
    return {
        skills: state.candidate.softSkills.skills,
        profileId: AuthSelectors.getProfileId(state),
        status: SoftSkillsSelectors.getStatus(state, testName),
    };
}
