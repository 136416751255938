import { cx } from "@emotion/css";
import { Translate } from "react-translate.ts";

import { JobMatch } from "../../../../common/components";
import { Panel } from "../../../../common/components/Panel/Panel";
import Skeleton from "../../../../common/components/Skeleton/Skeleton";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import IBenefit from "../../../../common/types/IBenefit";
import { BREAKPOINT_LG } from "../../../../common/_constants/theme.constants";
import FloatingCard from "./FloatingCard";
import styles from "./MainPanel.module.scss";

type MainPanelProps = {
    name: string;
    street: string;
    city: string;
    match: number;
    description: string;
    icon: string;
    currency: string;
    salaryMin: number;
    salaryMax?: number;
    payRaise?: string;
    benefits?: IBenefit[];
    stats?: string[];
    isLoading: boolean;
};

export function MainPanel(props: MainPanelProps) {
    const {
        name,
        street,
        city,
        match,
        description,
        icon,
        salaryMin,
        salaryMax,
        currency,
        payRaise,
        benefits,
        stats,
        isLoading,
    } = props;

    const isMobile = useBreakpoint(BREAKPOINT_LG);

    return (
        <Panel>
            {!isMobile && (
                <FloatingCard
                    salaryMin={salaryMin}
                    salaryMax={salaryMax}
                    currency={currency}
                    payRaise={payRaise}
                    benefits={benefits}
                    stats={stats}
                    isLoading={isLoading}
                />
            )}
            <div className={styles.content}>
                <div className={styles.brand}>
                    <div className={styles.icon}>
                        {!isLoading ? <img src={icon} alt="" /> : <Skeleton />}
                    </div>
                    <div>
                        <div className={styles.name}>
                            {!isLoading ? (
                                name
                            ) : (
                                <Skeleton height={24} width={100} />
                            )}
                        </div>
                        <div className={cx(styles.location, "font-book")}>
                            {(() => {
                                if (!isLoading) {
                                    return (
                                        <>
                                            {street ? (
                                                <span>{street}, </span>
                                            ) : null}
                                            <span>{city}</span>
                                        </>
                                    );
                                }

                                return <Skeleton height={15} width={180} />;
                            })()}
                        </div>
                    </div>
                </div>
                <div>
                    <JobMatch fit={match} isAnimationActive={!isLoading}>
                        <Translate>common.jobs.match.overall</Translate>
                    </JobMatch>
                </div>
                <div className={styles.description}>
                    {!isLoading ? (
                        <p>{description}</p>
                    ) : (
                        <Skeleton count={6} height={14} marginBottom={10} />
                    )}
                </div>
            </div>
        </Panel>
    );
}
