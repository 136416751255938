import * as api from '../../types/api';

export function resolveLogoUrl(logoUrl, organisationUrl) {
    const url = organisationUrl
        ? organisationUrl.replace(/(^\w+:|^)\/\//, '')
        : null;
    return logoUrl
        ? logoUrl
        : url
        ? api.endpoint + '/logos/' + url
        : '/img/icons/company.svg';

    // "//logo.clearbit.com/" + url;
}
