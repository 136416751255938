import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { BasePress } from './BasePress';
import { MeterBar } from './MeterBar';

export function Numeric() {
    const translateNum = useTranslate('candidate.softSkillsTest.num');
    const translate = useTranslate('candidate.softSkillsTest');

    return <BasePress name="num" icon="numeric" extraContent={renderPanels} />;

    function renderPanels(interpretation, isLoading) {
        if (
            isLoading ||
            !interpretation.softSkills ||
            !interpretation.softSkills.practical ||
            !interpretation.softSkills.operations
        ) {
            return null;
        }

        const skills = interpretation.softSkills;
        const { operations, practical } = skills;

        return (
            <div className="num-panels">
                {renderItem(operations, 'operations')}
                {renderItem(practical, 'practical')}
            </div>
        );

        function renderItem(items, name) {
            const title = translateNum('titles.' + name);
            return (
                <div className="soft-skills-test-layout--panel">
                    <div className="num-panels__big-meter">
                        <h5>{title}</h5>
                        <MeterBar value={items[0].value / 100} />
                    </div>
                    {items[1] ? (
                        <div>
                            <h5>
                                {translate('titles.speed')} ( {title} )
                            </h5>
                            <MeterBar value={items[1].value / 100} />
                        </div>
                    ) : null}
                    {items[2] ? (
                        <div>
                            <h5>
                                {translate('titles.quality')} ( {title} )
                            </h5>
                            <MeterBar value={items[2].value / 100} />
                        </div>
                    ) : null}
                </div>
            );
        }
    }
}
