import { RequestService } from "./RequestService";

const BASE_URL = "heroes/profiles";

const HeroProfileService = {
    publish,
    unpublish,
    getSkills,
    getSoftSkillsResults,
    getProfilePicture,
    saveProfilePicture,
};

function publish(heroProfileId) {
    return RequestService.put(`${BASE_URL}/${heroProfileId}/publish`);
}

function unpublish(heroProfileId) {
    return RequestService.put(`${BASE_URL}/${heroProfileId}/unpublish`);
}

function getSkills(heroProfileId) {
    return RequestService.get(`${BASE_URL}/${heroProfileId}/skills`);
}

function getSoftSkillsResults(payload) {
    const { heroProfileId, name } = payload;
    return RequestService.get(
        `${BASE_URL}/${heroProfileId}/soft-skills/${name}`
    );
}

function getProfilePicture(heroProfileId) {
    return RequestService.get(`${BASE_URL}/${heroProfileId}/images/latest`);
}

function saveProfilePicture(payload) {
    const { file, originalFile, fileName, heroProfileId } = payload;

    return RequestService.post(`${BASE_URL}/${heroProfileId}/images`, {
        file,
        originalFile,
        fileName,
    });
}

export default HeroProfileService;
