import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthSelectors } from "../../../_selectors";
import { HERO_USER_STATUS } from "../../../_constants/user.constants";
import { OnboardingLinks } from "../../../components/onboarding/OnboardingLinks";
import HeroOnboardingSelectors from "../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { ArrayHelper } from "../../../_helpers/ArrayHelper";
import HeroProfileSelectors from "../../../../candidate/_selectors/HeroProfileSelectors";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import { useTranslate } from "react-translate.ts";

export function HeroLinks() {
    const { step, stepsCompleted, profile, name, image } =
        useSelector(mapState);
    const dispatch = useDispatch();
    const translate = useTranslate("common.heroOnboarding.links");

    const steps = [
        { name: translate("personal") },
        { name: translate("positions") },
        { name: translate("skills") },
        { name: translate("advice") },
        { name: translate("video") },
        { name: translate("review") },
    ];

    let nextStepIsValid = false;
    const statuses = Object.keys(HERO_USER_STATUS);
    if (statuses[step]) {
        if (
            !ArrayHelper.isEmpty(stepsCompleted) &&
            stepsCompleted.find((item) => item.status === statuses[step])
        ) {
            nextStepIsValid = true;
        }
    }

    return (
        <OnboardingLinks
            steps={steps}
            currentStep={step}
            nextStepIsValid={nextStepIsValid}
            onForward={() => dispatch(HeroOnboardingActions.addStep(1))}
            onBack={() => dispatch(HeroOnboardingActions.addStep(-1))}
            profile={profile}
            name={name}
            imageUrl={image}
        />
    );
}

function mapState(state) {
    return {
        isSigned: AuthSelectors.isSigned(state),
        step: HeroOnboardingSelectors.getCurrentStep(state),
        stepsCompleted: HeroProfileSelectors.getSteps(state),
        profile: HeroProfileSelectors.getProfile(state),
        name: HeroProfileSelectors.getFirstName(state),
        image: HeroOnboardingSelectors.Personal.getData(state).image,
    };
}
