import { AnyAction, Dispatch } from "redux";

const actions: { [key: string]: any } = {};

function isPendingAction(action: any) {
    return action.meta.requestStatus === "pending";
}

function isFulfilledAction(action: any) {
    return action.meta.requestStatus === "fulfilled";
}

function isStaleAction(action: any) {
    const oldAction = getAction(action);
    return oldAction?.meta?.requestId !== action.meta.requestId;
}

function isToolkitAction(action: any) {
    return !!action.meta?.requestId;
}

function setAction(action: any) {
    const name = getActionName(action);
    actions[name] = action;
}

function getAction(action: any) {
    const name = getActionName(action);
    return actions[name];
}

function removeAction(action: any) {
    const name = getActionName(action);
    delete actions[name];
}

function getActionName(action: any) {
    const regex = new RegExp("/(pending|fulfilled)+");
    return action.type?.replace(regex, "");
}

const concurrencyMiddleware =
    () => (next: Dispatch<AnyAction>) => (action: any) => {
        if (!isToolkitAction(action)) {
            return next(action);
        }

        if (isPendingAction(action)) {
            if (isStaleAction(action)) {
                removeAction(action);
            }

            setAction(action);
            return next(action);
        }

        if (isFulfilledAction(action)) {
            if (isStaleAction(action)) {
                return next({ ...action, payload: null });
            }

            removeAction(action);
        }

        next(action);
    };

export default concurrencyMiddleware;
