import React from "react";
import { useField } from "formik";
import { Input } from "./Input";

/**
 *
 * @param {{name: string}} props
 */
export function FormInput({
    id,
    name,
    children,
    validate,
    getValue,
    onChange,
    ...rest
}) {
    const [field, meta] = useField({ name, validate });

    const { error, touched } = meta;
    const hasError = !!error && touched;
    const value = getValue ? getValue(field.value) : field.value;
    return (
        <Input
            {...field}
            value={value}
            defaultValue={value}
            id={id}
            error={error}
            hasError={hasError}
            onChange={handleChange}
            {...rest}
        >
            {children}
        </Input>
    );

    function handleChange(e) {
        field.onChange(e);

        onChange && onChange(e);
    }
}
