import { PropsWithChildren } from "react";

import styles from "./BaseDropdown.module.scss";

type BaseDropdownProps = {
    onBackdropClick?: () => void;
};

export default function BaseDropdown(
    props: PropsWithChildren<BaseDropdownProps>
) {
    const { children, onBackdropClick } = props;

    return (
        <>
            <div className={styles.drop}>{children}</div>
            <div className={styles.backdrop} onClick={onBackdropClick} />
        </>
    );
}
