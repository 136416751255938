import { createSelector } from 'reselect';

const base = (state) => state.common.public.softSkills.interpretation;

const getInterpretation = createSelector(
    base,
    (interpretation) => interpretation.data
);

const isLoading = createSelector(
    base,
    getInterpretation,
    (interpretation, data) => !data && interpretation.loading
);

export const PublicInterpretationSelectors = {
    getInterpretation,
    isLoading,
};
