import React from 'react';
import { createClass } from 'classname-helper';

/**
 *
 * @param {{
 *  size?: "small",
 *  color?: "primary" | "green" | "blue",
 * }} props
 */
export function Label({ size, color, className, children, ...rest }) {
    return (
        <div className={createClassName({ className, size, color })} {...rest}>
            {children}
        </div>
    );
}

function createClassName({ className, size, color }) {
    return createClass({
        label: true,
        [className]: !!className,
        'font-9': size === 'small',
        [color]: !!color
    });
}
