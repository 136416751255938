import { call, put } from 'redux-saga/effects';

import { NotificationActions } from '../_actions/NotificationActions';

/**
 *
 * @param {function} callFunction
 * @param {*} payload
 * @param {(payload: any) => { type: string, payload?: any, error?: string }} successAction
 * @param {(error: string) => { type: string, payload?: any, error?: string }} failAction
 * @param {{success?: string, fail?: string, info?: string, warning?: string}} messages
 */
export function* handleAsyncAction(
    callFunction,
    payload,
    successAction,
    failAction,
    messages = {}
) {
    const { success, fail, info, warning } = messages;
    try {
        const response = yield call(callFunction, payload);
        yield put(successAction(response.data || payload));

        if (success) {
            yield put(NotificationActions.pushSuccess(success));
        }
        if (warning) {
            yield put(NotificationActions.pushWarning(warning));
        }
        if (info) {
            yield put(NotificationActions.pushMessage(info));
        }
    } catch (error) {
        yield put(failAction(error));
        if (fail) {
            yield put(NotificationActions.pushError(fail));
        }
    }
}
