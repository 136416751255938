import React, { useMemo } from "react";
import { PieChart, Pie } from "recharts";
import { resolveFit } from "../../_helpers/resolveFit";

/**
 * @param {{
 *  fit: number;
 *  size?: "medium" | "small" | "tiny";
 *  isAnimationActive?: boolean;
 * }} props
 */
export function JobMatchChart({
    fit,
    size,
    color,
    innerRadius,
    isAnimationActive = false,
}) {
    const [outer, inner] = resolveSize(size);
    const data = useMemo(
        () => [
            { value: fit, fill: color || resolveFit(fit).color },
            { value: 1 - fit, fill: "#E5E6E8" },
        ],
        [fit, color]
    );
    const width = outer * 2;
    return (
        <PieChart width={width} height={width}>
            <Pie
                data={data}
                dataKey="value"
                startAngle={90}
                innerRadius={innerRadius || inner}
                outerRadius={outer}
                endAngle={-270}
                paddingAngle={1}
                fill="#82ca9d"
                isAnimationActive={isAnimationActive && fit > 0}
                animationBegin={0}
            />
        </PieChart>
    );
}

function resolveSize(size) {
    switch (size) {
        case "tiny":
            return [15, 5];
        case "small":
            return [20, 10];
        case "medium":
            return [25, 12];
        default:
            return [30, 15];
    }
}
