import {
    SET_CANDIDATE_SEARCH_FILTER,
    RESET_CANDIDATE_SEARCH_FILTER,
} from '../_constants/actions/candidateSearch.constants';

export const CandidateSearchActions = {
    setFilter,
    resetFilters,
};

function setFilter(key, value) {
    return { type: SET_CANDIDATE_SEARCH_FILTER, payload: { key, value } };
}

function resetFilters() {
    return { type: RESET_CANDIDATE_SEARCH_FILTER };
}
