import { MathHelper } from './MathHelper';

export const SVGHelper = {
    describeArc,
};

function describeArc(x, y, radius, startAngle, endAngle) {
    const start = MathHelper.polarToCartesian(x, y, radius, endAngle);
    const end = MathHelper.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    // const sweepArcFlag = endAngle - startAngle > 180 ? '0' : '1';

    const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
    ].join(' ');

    return d;
}
