import { cx } from "@emotion/css";
import { PropsWithChildren } from "react";
import styles from "./ListItem.module.scss";

type ListItemProps = {
    className?: string;
};

export default function ListItem(props: PropsWithChildren<ListItemProps>) {
    const { children, className } = props;

    return <div className={cx(styles.item, className)}>{children}</div>;
}
