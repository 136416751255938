import React from 'react';
import { useSelector } from 'react-redux';

import { ViewsMenu } from './ViewsMenu';
import { NotificationSelectors } from '../../_selectors/NotificationSelectors';
import { NotificationActions } from '../../_actions';
import { BaseNotificationButton } from './BaseNotificationButton';

export function ViewsButton(props) {
    const count = useSelector((state) =>
        NotificationSelectors.getViewsCount(state)
    );
    return (
        <BaseNotificationButton
            icon="icon-view"
            Menu={ViewsMenu}
            count={count}
            getNotificationsCountAction={
                NotificationActions.getNotificationViewsCount
            }
            {...props}
        />
    );
}
