import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";

import Button from "../../../../common/components/buttons/Button/Button";
import ConfirmDialog from "../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog";
import FiltersWrapper from "../../../../common/components/filters/FiltersWrapper/FiltersWrapper";
import BaseListLayout from "../../../../common/components/layout/BaseListLayout/BaseListLayout";
import ITeamMember from "../../../../common/types/ITeamMember";
import AdminTeamActions from "../../../_actions/AdminTeamActions";
import AdminTeamList from "../AdminTeamList/AdminTeamList";
import styles from "./AdminTeamEdit.module.scss";
import TeamInviteDialog from "./components/InviteDialog";

type AdminTeamEditProps = {
    members: ITeamMember[];
    isLoading: boolean;
    updating: number[];
    onSearch: (search: string) => void;
};

type State = {
    member: ITeamMember | null;
    deleteDialog: boolean;
    inviteDialog: boolean;
};

export default function AdminTeamEdit(props: AdminTeamEditProps) {
    const { members, isLoading, updating, onSearch } = props;
    const translate = useTranslate("admin.team");
    const dispatch = useDispatch();

    const [state, setState] = useState<State>({
        member: null,
        deleteDialog: false,
        inviteDialog: false,
    });

    return (
        <BaseListLayout<ITeamMember>
            title={translate("title")}
            searchProps={{
                placeholder: translate("search"),
                onSearch,
                loadOptions: __search,
                getOptionLabel: (option) =>
                    `${option.firstName} ${option.lastName}`,
            }}
            headingProps={{
                content: (
                    <Button onClick={openInviteDialog}>
                        {translate("invite.new")}
                    </Button>
                ),
                count: `${members.length} Active`,
            }}
            filtersProps={{ content: <Filters /> }}
        >
            <ConfirmDialog
                title={translate("deleteDialog.title")}
                onConfirm={deleteMember}
                onCancel={closeDeleteDialog}
                onClose={closeDeleteDialog}
                isVisible={state.deleteDialog}
            >
                {state.member &&
                    translate("deleteDialog.message", {
                        name: `${state.member.firstName} ${state.member.lastName}`,
                    })}
            </ConfirmDialog>
            <TeamInviteDialog
                isVisible={state.inviteDialog}
                onCancel={closeInviteDialog}
                onConfirm={invite}
            />
            <AdminTeamList
                members={members}
                updating={updating}
                isLoading={isLoading}
                onDelete={openDeleteDialog}
            />
        </BaseListLayout>
    );

    async function __search(text: string) {
        let options = [
            {
                id: 1,
                firstName: "Peter",
                lastName: "Almasi",
            },
            {
                id: 2,
                firstName: "Hank",
                lastName: "Peterson",
            },
            {
                id: 3,
                firstName: "Kevin",
                lastName: "McAlister",
            },
            {
                id: 4,
                firstName: "John",
                lastName: "Almasi",
            },
        ] as ITeamMember[];
        await new Promise((r) => setTimeout(r, 500));
        const regex = new RegExp(text.trim(), "i");
        return options.filter((option) => {
            const { firstName, lastName } = option;
            const label = `${firstName} ${lastName}`;
            return label.search(regex) >= 0;
        });
    }

    function openInviteDialog() {
        setState((prev) => ({ ...prev, inviteDialog: true }));
    }

    function closeInviteDialog() {
        setState((prev) => ({ ...prev, inviteDialog: false }));
    }

    function invite() {
        dispatch(AdminTeamActions.inviteMembers({ id: 0 }));
        closeInviteDialog();
    }

    function deleteMember() {
        if (state.member) {
            dispatch(AdminTeamActions.deleteMember({ id: state.member.id }));
        }
        closeDeleteDialog();
    }

    function closeDeleteDialog() {
        setState((prev) => ({ ...prev, deleteDialog: false }));
    }

    function openDeleteDialog(member: ITeamMember) {
        setState((prev) => ({ ...prev, deleteDialog: true, member }));
    }
}

function Filters() {
    return (
        <>
            <FiltersWrapper.Button label="All Roles"></FiltersWrapper.Button>
            <FiltersWrapper.Button label="Departments"></FiltersWrapper.Button>
            <FiltersWrapper.Button label="Locations"></FiltersWrapper.Button>
        </>
    );
}
