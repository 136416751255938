import { createSelector } from "reselect";

import { MainState } from "../../root.reducer";

const roles = (state: MainState) => state.candidate.jobRoles2;

const getList = createSelector(roles, (roles) => roles.list);

const getIndustryCategories = createSelector(roles, (roles) => roles.industries);

const JobRoleListSelectors = {
    getList,
    getIndustryCategories
};

export default JobRoleListSelectors;
