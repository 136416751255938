import React from "react";
import { useTranslate } from "react-translate.ts";

import { Button } from "../buttons";

function OnboardingButtons(props) {
    return <div className="onboarding-buttons">{props.children}</div>;
}

function Next(props) {
    return (
        <_Button
            tKey="next"
            onClick={props.onClick}
            disabled={props.disabled}
            content={props.content}
            valid={props.valid}
            variant="black"
        />
    );
}

function Back(props) {
    const { onClick, disabled } = props;

    return (
        <_Button
            tKey="back"
            onClick={onClick}
            disabled={disabled}
            variant="white"
        />
    );

    // function handleClick() {
    //     if (disabled) {
    //         return;
    //     }

    //     onClick ? onClick() : removeStep();
    // }

    // function removeStep() {
    //     dispatch(HeroOnboardingActions.addStep(-1));
    // }
}

function _Button(props) {
    const { tKey, variant, onClick, disabled, content } = props;

    const translate = useTranslate("common.heroOnboarding.steps");

    return (
        <Button variant={variant} onClick={handleClick} disabled={disabled}>
            {content || translate(tKey)}
        </Button>
    );

    function handleClick() {
        if (disabled) {
            return;
        }

        onClick && onClick();
    }

    // function addStep() {
    //     dispatch(HeroOnboardingActions.addStep(1));
    // }
}

OnboardingButtons.Next = Next;
OnboardingButtons.Back = Back;

export { OnboardingButtons };
