export const profileConstants = {
    MY_PROFILE_BEGIN: 'MY_PROFILE_BEGIN',
    MY_PROFILE_SUCCESS: 'MY_PROFILE_SUCCESS',
    MY_PROFILE_FAILURE: 'MY_PROFILE_FAILURE',

    JOB_EXPERIENCE_BEGIN: 'JOB_EXPERIENCE_BEGIN',
    JOB_EXPERIENCE_SUCCESS: 'JOB_EXPERIENCE_SUCCESS',
    JOB_EXPERIENCE_FAILURE: 'JOB_EXPERIENCE_FAILURE',

    JOB_ROLES_BEGIN: 'JOB_ROLES_BEGIN',
    JOB_ROLES_SUCCESS: 'JOB_ROLES_SUCCESS',
    JOB_ROLES_FAILURE: 'JOB_ROLES_FAILURE',

    EDUCATION_BEGIN: 'EDUCATION_BEGIN',
    EDUCATION_SUCCESS: 'EDUCATION_SUCCESS',
    EDUCATION_FAILURE: 'EDUCATION_FAILURE',

    SKILLS_SAVE_BEGIN: 'SKILLS_SAVE_BEGIN',
    SKILLS_SAVE_SUCCESS: 'SKILLS_SAVE_SUCCESS',
    SKILLS_SAVE_FAILURE: 'SKILLS_SAVE_FAILURE',

    ADD_SKILL: 'ADD_SKILL',
    UPDATE_SKILL: 'UPDATE_SKILL',
    DELETE_SKILL: 'DELETE_SKILL',
    REVERT_SKILLS: 'REVERT_SKILLS',

    LANGUAGES_SAVE_BEGIN: 'LANGUAGES_SAVE_BEGIN',
    LANGUAGES_SAVE_SUCCESS: 'LANGUAGES_SAVE_SUCCESS',
    LANGUAGES_SAVE_FAILURE: 'LANGUAGES_SAVE_FAILURE',

    ADD_LANGUAGE: 'ADD_LANGUAGE',
    UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
    DELETE_LANGUAGE: 'DELETE_LANGUAGE',
    REVERT_LANGUAGES: 'REVERT_LANGUAGES',

    PHOTO_UPLOAD_BEGIN: 'PHOTO_UPLOAD_BEGIN',
    PHOTO_UPLOAD_SUCCESS: 'PHOTO_UPLOAD_SUCCESS',
    PHOTO_UPLOAD_ERROR: 'PHOTO_UPLOAD_ERROR',

    LOAD_LANGUAGES: 'LOAD_LANGUAGES',
    LOAD_LANGUAGES_SUCCESS: 'LOAD_LANGUAGES_SUCCESS',
    LOAD_LANGUAGES_FAIL: 'LOAD_LANGUAGES_FAIL',

    LOAD_LANGUAGE_LEVELS: 'LOAD_LANGUAGE_LEVELS',
    LOAD_LANGUAGE_LEVELS_SUCCESS: 'LOAD_LANGUAGE_LEVELS_SUCCESS',
    LOAD_LANGUAGE_LEVELS_FAIL: 'LOAD_LANGUAGE_LEVELS_FAIL',

    UPDATE_ABOUT_ME: 'UPDATE_ABOUT_ME',
    UPDATE_ABOUT_ME_SUCCESS: 'UPDATE_ABOUT_ME_SUCCESS',
    UPDATE_ABOUT_ME_FAIL: 'UPDATE_ABOUT_ME_FAIL',

    SAVE_INTERESTS: 'SAVE_INTERESTS',
    SAVE_INTERESTS_SUCCESS: 'SAVE_INTERESTS_SUCCESS',
    SAVE_INTERESTS_FAIL: 'SAVE_INTERESTS_FAIL',

    ADD_INTEREST: 'ADD_INTEREST',
    ADD_INTEREST_SUCCESS: 'ADD_INTEREST_SUCCESS',
    ADD_INTEREST_FAIL: 'ADD_INTEREST_FAIL',

    UPDATE_INTEREST: 'UPDATE_INTEREST',
    UPDATE_INTEREST_SUCCESS: 'UPDATE_INTEREST_SUCCESS',
    UPDATE_INTEREST_FAIL: 'UPDATE_INTEREST_FAIL',

    DELETE_INTEREST: 'DELETE_INTEREST',
    DELETE_INTEREST_SUCCESS: 'DELETE_INTEREST_SUCCESS',
    DELETE_INTEREST_FAIL: 'DELETE_INTEREST_FAIL',

    ADD_EDUCATION: 'ADD_EDUCATION',
    ADD_EDUCATION_SUCCESS: 'ADD_EDUCATION_SUCCESS',
    ADD_EDUCATION_FAIL: 'ADD_EDUCATION_FAIL',

    UPDATE_EDUCATION: 'UPDATE_EDUCATION',
    UPDATE_EDUCATION_SUCCESS: 'UPDATE_EDUCATION_SUCCESS',
    UPDATE_EDUCATION_FAIL: 'UPDATE_EDUCATION_FAIL',

    DELETE_EDUCATION: 'DELETE_EDUCATION',
    DELETE_EDUCATION_SUCCESS: 'DELETE_EDUCATION_SUCCESS',
    DELETE_EDUCATION_FAIL: 'DELETE_EDUCATION_FAIL',

    ADD_EXPERIENCE: 'ADD_EXPERIENCE',
    ADD_EXPERIENCE_SUCCESS: 'ADD_EXPERIENCE_SUCCESS',
    ADD_EXPERIENCE_FAIL: 'ADD_EXPERIENCE_FAIL',

    UPDATE_EXPERIENCE: 'UPDATE_EXPERIENCE',
    UPDATE_EXPERIENCE_SUCCESS: 'UPDATE_EXPERIENCE_SUCCESS',
    UPDATE_EXPERIENCE_FAIL: 'UPDATE_EXPERIENCE_FAIL',

    DELETE_EXPERIENCE: 'DELETE_EXPERIENCE',
    DELETE_EXPERIENCE_SUCCESS: 'DELETE_EXPERIENCE_SUCCESS',
    DELETE_EXPERIENCE_FAIL: 'DELETE_EXPERIENCE_FAIL',

    UPDATE_EMAIL: 'UPDATE_EMAIL',
    UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
    UPDATE_EMAIL_FAIL: 'UPDATE_EMAIL_FAIL',

    VERIFY_EMAIL: 'VERIFY_EMAIL',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAIL: 'VERIFY_EMAIL_FAIL',

    UPDATE_PHONE: 'UPDATE_PHONE',
    UPDATE_PHONE_SUCCESS: 'UPDATE_PHONE_SUCCESS',
    UPDATE_PHONE_FAIL: 'UPDATE_PHONE_FAIL',

    UPDATE_ADDRESS: 'UPDATE_ADDRESS',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAIL: 'UPDATE_ADDRESS_FAIL'
};
