import React from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Notification from './Notification/Notification';

export function NotificationList() {
    const notifications = useSelector((state) =>
        state.common.notifications.current
    );

    return (
        <TransitionGroup id="notification-list">
            {notifications.map((notification) => (
                <CSSTransition
                    key={notification.timestamp}
                    classNames="notification-"
                    timeout={300}
                >
                    <Notification key={notification.timestamp} notification={notification} />
                </CSSTransition>
            ))}
        </TransitionGroup>
    );
}
