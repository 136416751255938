import React, { useEffect, useState } from "react";
import { createClass } from "classname-helper";

import { CollegeForm } from "./CollegeForm";
import { UserProfileSelectors } from "../../../../../common/_selectors/UserProfileSelectors";
import { useDispatch, useSelector } from "react-redux";
import { ProfileActions } from "../../../../../common/_actions/ProfileActions";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import { JobRoleForm } from "./JobRoleForm";
import { UniversityForm } from "./UniversityForm";
import {
    PROFILE_TYPE_COLLEGE,
    PROFILE_TYPE_PROFESSIONAL,
    PROFILE_TYPE_UNIVERSITY,
} from "../../../../../common/_constants/profile.constants";
import { SVGIcon } from "../../../../../common/components/SVGIcon";
import { ProfileTypeSelect } from "../../../../../common/components";

export function CareerPathsSelector(props) {
    const { onSubmit } = props;

    const { profileType, profileId } = useSelector(mapState);

    const [selected, setSelected] = useState(profileType && profileType.type);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!profileType) {
            dispatch(ProfileActions.getProfileType(profileId));
        }
    }, [dispatch, profileId, profileType]);

    return (
        <div id="career-paths-selector">
            <h2>Choose your current status</h2>
            <div className="subtitle font-light">
                Please fill up information which is necessary to show career map
            </div>
            <ProfileTypeSelect
                renderCollege={renderCollege}
                renderUniversity={renderUniversity}
                renderJobRole={renderJobRole}
            />
            {/* <div className="options">
                <Option
                    name="College Student"
                    value={PROFILE_TYPE_COLLEGE}
                    selected={selected}
                    onSelect={setSelected}
                    icon="school"
                />
                <Option
                    name="University Student"
                    value={PROFILE_TYPE_UNIVERSITY}
                    selected={selected}
                    onSelect={setSelected}
                    icon="uni"
                />
                <Option
                    name="Experienced"
                    value={PROFILE_TYPE_PROFESSIONAL}
                    selected={selected}
                    onSelect={setSelected}
                    icon="profi"
                />
            </div>
            <OptionSwitch
                selected={selected}
                onSubmit={onSubmit}
                profileType={profileType}
            /> */}
        </div>
    );

    function renderCollege() {
        return <CollegeForm onSubmit={onSubmit} />;
    }

    function renderUniversity() {
        const dontShowForm =
            profileType &&
            profileType.profileSectionCompleted &&
            profileType.profileSectionCompleted.includes("EDUCATION");

        return <UniversityForm onSubmit={onSubmit} showForm={!dontShowForm} />;
    }

    function renderJobRole() {
        return <JobRoleForm onSubmit={onSubmit} />;
    }
}

function OptionSwitch(props) {
    const { selected, onSubmit, profileType } = props;

    switch (selected) {
        case PROFILE_TYPE_COLLEGE:
            return <CollegeForm onSubmit={onSubmit} />;
        case PROFILE_TYPE_UNIVERSITY:
            const dontShowForm =
                profileType &&
                profileType.profileSectionCompleted &&
                profileType.profileSectionCompleted.includes("EDUCATION");

            return (
                <UniversityForm onSubmit={onSubmit} showForm={!dontShowForm} />
            );
        case PROFILE_TYPE_PROFESSIONAL:
            return <JobRoleForm onSubmit={onSubmit} />;
        default:
            return null;
    }
}

function Option(props) {
    const { icon, name, selected, onSelect, value } = props;

    return (
        <div
            className={createClass({
                option: true,
                selected: selected === value,
                "font-book": true,
            })}
            onClick={() => onSelect(value)}
        >
            <div className="check">
                <SVGIcon name="check" />
            </div>
            <div className="image">
                <img src={"/img/career-paths/" + icon + ".svg"} />
            </div>
            <div>{name}</div>
        </div>
    );
}

function mapState(state) {
    return {
        profileType: UserProfileSelectors.getType(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
