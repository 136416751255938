import {
    GET_SKILLS_BEGIN,
    GET_SKILLS_SUCCESS,
    GET_SKILLS_ERROR,
    GET_SKILL_CATEGORIES_BEGIN,
    GET_SKILL_CATEGORIES_SUCCESS,
    GET_SKILL_CATEGORIES_ERROR
} from '../_constants/actions/skill.contants';
import { List } from 'immutable';

export function skills(
    state = {
        categories: new List(),
        skills: new List(),
        loading: false,
        error: null
    },
    { type, payload, error }
) {
    switch (type) {
        case GET_SKILLS_BEGIN:
            return { ...state, loading: true, skills: new List() };
        case GET_SKILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                skills: new List(payload.skills)
            };
        case GET_SKILLS_ERROR:
            return { ...state, loading: false, error };
        case GET_SKILL_CATEGORIES_BEGIN:
            return { ...state, loading: true, categories: new List() };
        case GET_SKILL_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: new List(payload.categories)
            };
        case GET_SKILL_CATEGORIES_ERROR:
            return { ...state, loading: false, error };
        default:
            return state;
    }
}
