import { combineReducers } from "redux";
import { Map } from "immutable";

import ProfileInformationConstants from "../../_constants/actions/ProfileInformationConstants";
import ProfileConstants from "../../_constants/actions/ProfileConstants";
import produce from "immer";

export default combineReducers({
    about,
    interests,
    jobExperience,
    educations,
    languages,
    skills,
});

function about(state = { content: "", id: 0 }, action) {
    switch (action.type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return action.payload.about || { content: "", id: 0 };
        case ProfileInformationConstants.UPDATE_ABOUT_ME_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

function interests(state = Map(), action) {
    const { type, payload } = action;
    switch (type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return Map(
                action.payload.interests.map((interest) => [
                    interest.id,
                    interest,
                ])
            );
        case ProfileInformationConstants.ADD_INTEREST_SUCCESS:
        case ProfileInformationConstants.UPDATE_INTEREST_SUCCESS:
            return state.set(payload.id, payload);
        case ProfileInformationConstants.DELETE_INTEREST_SUCCESS:
            return state.remove(payload);
        default:
            return state;
    }
}

function jobExperience(state = Map(), action) {
    const { type, payload } = action;
    switch (type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return Map(
                action.payload.jobExperience.map((item) => [item.id, item])
            );
        case ProfileInformationConstants.ADD_EXPERIENCE_SUCCESS:
        case ProfileInformationConstants.UPDATE_EXPERIENCE_SUCCESS:
            return state.set(payload.id, payload);
        case ProfileInformationConstants.DELETE_EXPERIENCE_SUCCESS:
            return state.remove(payload);
        default:
            return state;
    }
}

function educations(state = Map(), action) {
    const { type, payload } = action;
    switch (type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return Map(
                action.payload.educations.map((item) => [item.id, item])
            );
        case ProfileInformationConstants.ADD_EDUCATION_SUCCESS:
        case ProfileInformationConstants.UPDATE_EDUCATION_SUCCESS:
            return state.set(payload.id, payload);
        case ProfileInformationConstants.DELETE_EDUCATION_SUCCESS:
            return state.remove(payload);
        default:
            return state;
    }
}

function languages(state = Map(), action) {
    const { type, payload } = action;

    switch (type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return Map(
                action.payload.languages.map((item) => [item.language.id, item])
            );
        case ProfileInformationConstants.ADD_LANGUAGE:
        case ProfileInformationConstants.UPDATE_LANGUAGE:
            return state.set(payload.language.id, payload);
        case ProfileInformationConstants.DELETE_LANGUAGE:
            return state.remove(payload);
        case ProfileInformationConstants.REVERT_LANGUAGE_CHANGES:
            return Map(payload.map((item) => [item.language.id, item]));
        default:
            return state;
    }
}

function skills(state = [], action) {
    const { type, payload } = action;

    switch (type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            return action.payload.skills;
        case ProfileInformationConstants.ADD_SKILL:
            return produce(state, (draft) => {
                draft.push(payload);
            });
        case ProfileInformationConstants.UPDATE_SKILL:
            return produce(state, (draft) => {
                const index = state.findIndex(
                    (item) => item.skill.id === payload.skill.id
                );

                if (index > -1) {
                    draft[index] = payload;
                }
            });
        case ProfileInformationConstants.DELETE_SKILL:
            return produce(state, (draft) => {
                const index = state.findIndex(
                    (item) => item.skill.id === payload
                );

                if (index > -1) {
                    draft.splice(index, 1);
                }
            });
        case ProfileInformationConstants.REVERT_LANGUAGE_CHANGES:
            return payload;

        default:
            return state;
    }
}
