import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { AuthSelectors } from "../../_selectors/AuthSelectors";
import { OnboardingSoftSkills } from "./Onboarding/OnboardingSoftSkills";
import { LoadingOverlay } from "../../components";
import { Links } from "./Onboarding/Links";
import OnboardingSelectors from "../../_selectors/OnboardingSelectors";
import { OnboardingHardSkills } from "./Onboarding/OnboardingHardSkills";
import ProfileActions from "../../../candidate/_actions/ProfileActions";
import {
    ACTIVE_STATUS,
    VERIFICATION_PENDING_STATUS,
} from "../../_constants/user.constants";
import {
    PROFILE_PATH_INFO,
    VERIFY_PATH,
} from "../../_constants/route.constants";
import OnboardingPersonal from "./Onboarding/OnboardingPersonal/OnboardingPersonal";
import { MainState } from "../../../root.reducer";
import OnboardingPageLayout from "../../components/layout/OnboardingPageLayout/OnboardingPageLayout";
import { css } from "@emotion/css";

export function Onboarding() {
    const { isSigned, userStatus, profileId, step, isLoading } =
        useSelector(mapState);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isSigned && profileId) {
            dispatch(ProfileActions.getProfile(profileId));
        }
    }, [isSigned, profileId, dispatch]);
    if (userStatus === ACTIVE_STATUS) {
        return <Redirect to={PROFILE_PATH_INFO} />;
    }

    if (userStatus === VERIFICATION_PENDING_STATUS) {
        return <Redirect to={VERIFY_PATH} />;
    }

    return (
        <OnboardingPageLayout
            headerProps={{ className: css({ border: "none" }) }}
        >
            <LoadingOverlay isLoading={isLoading} />
            <Links />
            {renderStep()}
        </OnboardingPageLayout>
    );

    function renderStep() {
        switch (step) {
            case 0:
                return <OnboardingPersonal />;
            case 1:
                return <OnboardingHardSkills />;
            case 2:
                return <OnboardingSoftSkills />;
            default:
                return <OnboardingPersonal />;
        }
    }

    // function changeStep(step) {
    //     StorageService.setLocal(ONBOARDING_STEP, step);
    // }
}

function mapState(state: MainState) {
    return {
        isSigned: AuthSelectors.isSigned(state),
        profileId: AuthSelectors.getProfileId(state),
        userStatus: AuthSelectors.userStatus(state),
        step: OnboardingSelectors.getCurrentStep(state),
        isLoading: OnboardingSelectors.isLoading(state),
    };
}
