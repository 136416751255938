import SoftSkillsConstants from "../_constants/actions/SoftSkillsConstants";

const SoftSkillsActions = {
    getComparison,
    getStatuses,
    getStatus,
    getTest,
    completeTest,
};

function getComparison(profileId, test) {
    return {
        type: SoftSkillsConstants.GET_COMPARISON,
        payload: { profileId, test: test.toUpperCase() },
    };
}

function getStatuses(profileId) {
    return { type: SoftSkillsConstants.GET_STATUSES, payload: profileId };
}

function getStatus(profileId, name, useForce = false) {
    return {
        type: SoftSkillsConstants.GET_STATUS,
        payload: { profileId, name: name.toUpperCase(), useForce },
    };
}

function getTest(profileId, test) {
    return { type: SoftSkillsConstants.GET_TEST, payload: { profileId, test } };
}

function completeTest(profileId, requestId) {
    return {
        type: SoftSkillsConstants.COMPLETE_TEST,
        payload: { profileId, requestId },
    };
}

export default SoftSkillsActions;
