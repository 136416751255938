import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";
import { CompanyCandidates } from "../../components/CompanyCandidates/CompanyCandidates";

export default function CompanyCandidatesPage() {
    return (
        <CompanyPageLayout>
            <CompanyCandidates />
        </CompanyPageLayout>
    );
}
