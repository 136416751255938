import { cx } from "@emotion/css";
import { useTranslate } from "react-translate.ts";

import { Button } from "../../../../../components";
import styles from "./VideoRecordPanel.module.scss";
import VideoPanel from "../VideoPanel/VideoPanel";
import VideoIcon from "../VideoIcon/VideoIcon";

type VideoRecordProps = {
    onStart: () => void;
    onSkip: () => void;
};

export default function VideoRecordPanel(props: VideoRecordProps) {
    const { onStart, onSkip } = props;
    const translate = useTranslate("common.heroOnboarding.steps.video.record");

    return (
        <VideoPanel className={styles.record}>
            <div className={styles.content}>
                <VideoIcon
                    src="/img/heroes/onboard/video-record.svg"
                    className={styles.icon}
                />
                <div className={styles.title}>{translate("title")}</div>
                <div className={cx(styles.desc, "font-book")}>
                    {translate("subtitle")}
                </div>
                <div className={styles.buttons}>
                    <Button variant="white" onClick={onSkip}>
                        {translate("skip")}
                    </Button>
                    <Button variant="black" onClick={onStart}>
                        {translate("start")}
                    </Button>
                </div>
            </div>
        </VideoPanel>
    );
}
