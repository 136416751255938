import React from 'react';

export function BaseDialogBox({ children, onClose }) {
    return (
        <div className="popup__box">
            <div className="popup__box__close" onClick={onClose}>
                <img src="/img/icons/close-primary.svg" alt="Close" />
            </div>
            {children}
        </div>
    );
}
