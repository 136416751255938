import React from 'react';
import { Route } from 'react-router-dom';
import { HeadTitle } from '../misc/HeadTitle';

export function BaseRoute({ title, ...rest }) {
    return (
        <>
            <HeadTitle title={title} />
            <Route {...rest} />
        </>
    );
}
