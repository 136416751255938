import React from 'react';
import { Translate } from 'react-translate.ts';

export function Panel({ heading, children }) {
    return (
        <div className="company-profile--panel">
            <Heading>{heading}</Heading>
            <div className="company-profile--panel--content">{children}</div>
        </div>
    );
}

function Heading({ children }) {
    if (!children) {
        return null;
    }

    return (
        <>
            <Translate
                namespace="common.company.profile"
                className="company-profile--panel--heading font-18"
            >
                {children}
            </Translate>
            <div className="company-profile--panel--border" />
        </>
    );
}
