import { BaseInfo, Panel } from ".";
import Video from "../../media/Video/Video";

export function Overview({ heading, company }) {
    return (
        <>
            <Panel heading={heading}>
                <BaseInfo company={company} />
            </Panel>
            <div className="company-profile--video">
                <Video
                    videoId={company.videoId}
                    videoProvider={company.videoProvider}
                    style={{ padding: 0 }}
                />
            </div>
        </>
    );
}
