import { HTMLAttributes, PropsWithChildren, PropsWithRef } from "react";
import { cx } from "@emotion/css";

import styles from "./VideoPanel.module.scss";

type VideoPanelProps = PropsWithChildren<{
    className?: string;
    contentProps?: PropsWithRef<HTMLAttributes<HTMLDivElement>>;
}>;

export default function VideoPanel(props: VideoPanelProps) {
    const { children, className, contentProps = {} } = props;
    return (
        <div className={cx(styles.panel, className)}>
            <div {...contentProps} className={cx(styles.content, contentProps?.className)} >{children}</div>
        </div>
    );
}
