import { combineReducers } from 'redux';
import {
    GET_NOTIFICATIONS_VIEWS_SUCCESS,
    GET_NOTIFICATIONS_VIEWS,
    GET_NOTIFICATIONS_VIEWS_FAIL,
    GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS,
} from '../../_constants/actions/notification.constants';
import {
    ASYNC_STATE_LOADING,
    ASYNC_STATE_SUCCESS,
    ASYNC_STATE_FAIL,
} from '../../_constants/async.constants';

export default combineReducers({
    list,
    state,
    count,
});

function list(state = [], action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_VIEWS_SUCCESS:
            return action.payload.content;
        default:
            return state;
    }
}

function state(state = null, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_VIEWS:
            return ASYNC_STATE_LOADING;
        case GET_NOTIFICATIONS_VIEWS_SUCCESS:
            return ASYNC_STATE_SUCCESS;
        case GET_NOTIFICATIONS_VIEWS_FAIL:
            return ASYNC_STATE_FAIL;
        default:
            return state;
    }
}

function count(state = 0, action) {
    if (action.type === GET_NOTIFICATIONS_VIEWS_COUNT_SUCCESS) {
        return action.payload;
    }

    return state;
}
