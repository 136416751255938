import { OrderedMap } from 'immutable';

import {
    GET_JOB_CARDS_SUCCESS,
    SET_JOB_FAVOURITE_SUCCESS,
    UNSET_JOB_FAVOURITE_SUCCESS,
} from '../../../_constants';

export default function (state = OrderedMap(), action) {
    switch (action.type) {
        case GET_JOB_CARDS_SUCCESS:
            return OrderedMap(
                action.payload.content.map((item) => [item.jobPostId, item])
            );
        case SET_JOB_FAVOURITE_SUCCESS:
            return setFavourite(state, action.payload, true);
        case UNSET_JOB_FAVOURITE_SUCCESS:
            return setFavourite(state, action.payload, false);
        default:
            return state;
    }
}

function setFavourite(state, { jobPostId }, isFavourite) {
    const card = state.get(jobPostId);
    const newCard = Object.assign({}, card, { isFavourite });

    return state.set(jobPostId, newCard);
}
