import React from 'react';
import { createClass, addClass } from 'classname-helper';

/**
 *
 * @param {{
 *      justifyContent?: 'space-between' | 'center' | 'end';
 *      alignItems?: 'center' | 'end'| 'start' | 'baseline';
 *      alignContent?: 'end' | 'space-between';
 *      wrap?: 'wrap' | 'nowrap';
 *      direction?: 'column';
 *      spacing?: number;
 *      children?: any;
 *      className?: string;
 *      onClick?: () => void;
 *  }} props
 */
export function Flex({
    justifyContent,
    alignItems,
    alignContent,
    wrap,
    direction,
    className,
    spacing,
    forwardRef,
    ...rest
}) {
    return (
        <div
            ref={forwardRef}
            className={createClassName({
                justifyContent,
                alignItems,
                alignContent,
                wrap,
                direction,
                spacing,
                className
            })}
            {...rest}
        ></div>
    );
}

function createClassName({
    justifyContent,
    alignItems,
    alignContent,
    wrap,
    direction,
    spacing,
    className
}) {
    let result = createClass({ flex: true, [className]: !!className });

    if (justifyContent) {
        result = addClass(result, `justify-${justifyContent}`);
    }

    if (alignItems) {
        result = addClass(result, `align-items-${alignItems}`);
    }

    if (alignContent) {
        result = addClass(result, `align-content-${alignContent}`);
    }

    if (wrap) {
        result = addClass(result, `flex-${wrap}`);
    }

    if (direction) {
        result = addClass(result, `flex-${direction}`);
    }

    if (spacing) {
        result = addClass(result, `spacing-${spacing}`);
    }

    return result;
}
