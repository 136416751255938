import cx from "classnames";
import React, {
    CSSProperties,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from "react";
import SliderThumb from "./components/SliderThumb/SliderThumb";
import styles from "./Slider.module.scss";
import SliderMark from "./components/SliderMark/SliderMark";

type Mark = {
    value: number;
    label?: string;
};

interface SliderProps {
    disabled?: boolean;
    dotSize?: number;
    onChange?: (newValue: number) => void;
    onChangeCommitted?: (newValue: number) => void;
    step?: number;
    min?: number;
    max?: number;
    value: number;
    railHeight?: number;
    readonly?: boolean;
    marks?: Mark[];
    renderMark?: (mark: Mark, position: number, index: number) => ReactNode;
    classNames?: { thumb?: string; rail?: string; track?: string };
}

export default function Slider(props: SliderProps) {
    const {
        onChange,
        onChangeCommitted,
        value,
        min = 0,
        max = 1,
        step = 1,
        dotSize = 16,
        disabled = false,
        railHeight = 4,
        readonly = false,
        marks,
        renderMark,
        classNames,
    } = props;

    const sliderElement = useRef<HTMLDivElement>();
    const [valueChanged, setValueChanged] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        window.addEventListener("touchmove", updateValueOnEvent);
        window.addEventListener("touchcancel", onDragEnd);
        window.addEventListener("touchend", onDragEnd);
        window.addEventListener("mousemove", updateValueOnEvent);
        window.addEventListener("mouseup", onDragEnd);

        return () => {
            window.removeEventListener("touchmove", updateValueOnEvent);
            window.removeEventListener("touchcancel", onDragEnd);
            window.removeEventListener("touchend", onDragEnd);
            window.removeEventListener("mousemove", updateValueOnEvent);
            window.removeEventListener("mouseup", onDragEnd);
        };
    }, []);

    return (
        <div
            className={cx(
                styles.slider,
                disabled && styles.disabled,
                readonly && styles.readonly
            )}
            style={{ "--size": `${railHeight}px` } as CSSProperties}
            ref={sliderElement}
            onTouchStart={onDragStart}
            onMouseDown={onDragStart}
        >
            <div
                className={cx(styles.track, classNames.track)}
                style={{ width: `${valueToPercent(value, min, max)}%` }}
            />
            <div className={cx(styles.rail, classNames?.rail)} />
            <div className={styles.marks}>
                {marks
                    ?.filter(
                        (mark: any) => mark.value >= min && mark.value <= max
                    )
                    .map((mark: any, index: number) =>
                        renderMark ? (
                            renderMark(
                                mark,
                                valueToPercent(mark.value, min, max),
                                index
                            )
                        ) : (
                            <SliderMark
                                position={valueToPercent(mark.value, min, max)}
                                size={dotSize}
                                label={mark.label}
                                disabled={disabled}
                            />
                        )
                    )}

                {value !== null && value !== undefined && !isNaN(value) && (
                    <SliderThumb
                        position={valueToPercent(value, min, max)}
                        size={dotSize}
                        className={classNames?.thumb}
                    />
                )}
            </div>
        </div>
    );

    function onUpdate(newValue: any) {
        onChange?.(newValue);
        setValueChanged(true);
    }

    function onDragEnd(event: any) {
        if (disabled) {
            return;
        }

        const newValue = updateValueOnEvent(event);
        setIsDragging(false);

        if (valueChanged) {
            onChangeCommitted != null && onChangeCommitted(newValue);
        }

        setValueChanged(false);
    }

    function onDragStart(event: any) {
        if (disabled) {
            return;
        }

        setIsDragging(true);
        setValueChanged(false);

        updateValueOnEvent(event);
    }

    function updateValueOnEvent(event: any) {
        if (disabled || sliderElement.current == null) {
            return;
        }

        if (event.type !== "click" && !isDragging) {
            return;
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        }
        if (event.preventDefault) {
            event.preventDefault();
        }

        const clientX =
            event.type === "touchmove" || event.type === "touchstart"
                ? event.touches[0].clientX
                : event.clientX;
        const rect = sliderElement.current.getBoundingClientRect();
        const x = clientX - rect.left;
        const percent = x / rect.width;
        const value = percentToValue(percent, min, max);
        const newValue = step > 0 ? roundValueToStep(value, step, min) : value;
        onUpdate(newValue);

        return newValue;
    }
}

export function valueToPercent(value: number, min: number, max: number) {
    return ((value - min) * 100) / (max - min);
}

export function percentToValue(percent: number, min: number, max: number) {
    return clamp((max - min) * percent + min, min, max);
}

export function roundValueToStep(value: number, step: number, min: number) {
    return Math.round((value - min) / step) * step + min;
}

function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}
