import React from 'react';
import { useTranslate } from 'react-translate.ts';

export function ShareButton(props) {
    const { onClick, label, icon } = props;
    const translate = useTranslate();

    return (
        <button className="share-button" onClick={onClick}>
            {icon || (
                <img
                    src="/img/icons/share.svg"
                    alt=""
                    className="share-button__icon"
                />
            )}
            {label || translate('common.actions.shareSocial')}
        </button>
    );
}
