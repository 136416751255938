import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { StringSchema, ObjectSchema } from "yup";

import Button from "../../../../components/buttons/Button/Button";
import Field from "../../../../components/input/Field/Field";
import FormInput from "../../../../components/input/FormInput";
import { UserActions } from "../../../../_actions/UserActions";
import styles from "./ForgotPassword.module.scss";

const EMAIL_FIELD = "email";

type Values = {
    [EMAIL_FIELD]: string;
};

export default function ForgotPasswordEmail() {
    const dispatch = useDispatch();
    const translateAuth = useTranslate("common.auth.forgot-pass");
    const translateError = useTranslate("common.input.field.error");
    const initialValues = { [EMAIL_FIELD]: "" };

    return (
        <>
            <div>
                <h2>{translateAuth("header")}</h2>
                <div className={styles.subtitle}>
                    {translateAuth("email.text")}
                </div>
            </div>
            <Formik<Values>
                initialValues={initialValues}
                validationSchema={createValidationSchema()}
                onSubmit={submitForm}
            >
                <FormWrapper />
            </Formik>
        </>
    );

    function submitForm(values: Values) {
        const { email } = values;
        dispatch(dispatch(UserActions.forgotPassword(email)));
    }

    function createValidationSchema() {
        const name = "Email";
        return new ObjectSchema({
            email: new StringSchema()
                .required(translateError("required", { name }))
                .email(translateError("email", { name })),
        });
    }
}

function FormWrapper() {
    const translate = useTranslate("common.auth.forgot-pass.form");

    return (
        <Form>
            <Field label={translate("email.label")} required>
                <FormInput
                    name={EMAIL_FIELD}
                    placeholder={translate("email.placeholder")}
                    data-test="email"
                />
            </Field>
            <div>
                <Button type="submit" data-test="submit">{translate("confirm")}</Button>
            </div>
        </Form>
    );
}
