import React from 'react';

export function BaseDialog({ isVisible, onClose, children, id }) {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="popup" id={id}>
            <div className="popup-backdrop" onClick={onClose} />
            {children}
        </div>
    );
}
