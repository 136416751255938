export const ACTIVE_STATUS = "ACTIVE";
export const VERIFICATION_PENDING_STATUS = "VERIFICATION_PENDING";
export const ONBOARDING_PERSONAL_DETAILS_STATUS = "ONBOARDING_PERSONAL_DETAILS";
export const ONBOARDING_HARD_SKILLS_STATUS = "ONBOARDING_HARD_SKILLS";
export const ONBOARDING_EDUCATION_STATUS = "ONBOARDING_EDUCATION";
export const ONBOARDING_TEST_STATUS = "ONBOARDING_TEST";

export const ONBOARDING_USER_STATUS = {
    ONBOARDING_PERSONAL_DETAILS: 0,
    ONBOARDING_HARD_SKILLS: 1,
    ONBOARDING_TEST: 2,
    ACTIVE_STATUS: 3,
};

export const HERO_USER_STATUS = {
    HERO_ONBOARDING_PERSONAL_DETAILS: 0,
    HERO_ONBOARDING_EXPERIENCE: 1,
    HERO_ONBOARDING_TEST: 2,
    HERO_ONBOARDING_ANSWER: 3,
    HERO_ONBOARDING_VIDEO: 4,
    ACTIVE: 5,
};

export const HERO_ONBOARDING_PERSONAL_DETAILS_STATUS =
    "HERO_ONBOARDING_PERSONAL_DETAILS";
export const HERO_ONBOARDING_EXPERIENCE_STATUS = "HERO_ONBOARDING_EXPERIENCE";
export const HERO_ONBOARDING_TEST_STATUS = "HERO_ONBOARDING_TEST";
export const HERO_ONBOARDING_ANSWER_STATUS = "HERO_ONBOARDING_ANSWER";
