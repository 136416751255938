import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { ObjectSchema, StringSchema } from "yup";

import Button from "../../../../components/buttons/Button/Button";
import Field from "../../../../components/input/Field/Field";
import FormInput from "../../../../components/input/FormInput";
import PasswordInput from "../../../../components/input/PasswordInput/PasswordInput";
import { UserActions } from "../../../../_actions/UserActions";
import styles from "./ForgotPassword.module.scss";

const TOKEN_FIELD = "token";
const PASS_FIELD = "pass";

type Values = {
    [TOKEN_FIELD]: string;
    [PASS_FIELD]: string;
};

export default function ForgotPasswordToken(props: { token?: string }) {
    const { token } = props;
    const dispatch = useDispatch();
    const translate = useTranslate("common.auth.forgot-pass");
    const translateError = useTranslate("common.input.field.error");
    const initialValues = { [TOKEN_FIELD]: token, [PASS_FIELD]: "" };

    return (
        <>
            <div>
                <h2>{translate("header")}</h2>
                <div className={styles.subtitle}>{translate("reset.text")}</div>
            </div>
            <Formik<Values>
                initialValues={initialValues}
                validationSchema={createValidationSchema()}
                onSubmit={submitForm}
            >
                <FormWrapper />
            </Formik>
        </>
    );

    function submitForm(values: Values) {
        const { token, pass } = values;
        dispatch(UserActions.resetPassword(token.trim(), pass.trim()));
    }

    function createValidationSchema() {
        return new ObjectSchema({
            [TOKEN_FIELD]: new StringSchema().required(
                translateError("required", {
                    name: translate("form.token.label"),
                })
            ),
        });
    }
}

function FormWrapper() {
    const translate = useTranslate("common.auth.forgot-pass.form");

    return (
        <Form autoComplete="off">
            <Field>
                <FormInput
                    name={TOKEN_FIELD}
                    placeholder={translate("token.placeholder")}
                    data-test="token"
                    autoComplete="off"
                />
            </Field>
            <Field label={translate("pass.label")} required>
                <PasswordInput
                    name={PASS_FIELD}
                    placeholder={translate("pass.placeholder")}
                    autoComplete="new-password"
                    data-test="password"
                />
            </Field>
            <div>
                <Button type="submit" data-test="submit">
                    {translate("reset")}
                </Button>
            </div>
        </Form>
    );
}
