import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { ReducerHelpers } from "../../candidate/_helpers";
import IBaseState from "../../common/types/IBaseState";
import IBenefit from "../../common/types/IBenefit";
import ICompanyJob from "../../common/types/ICompanyJob";
import IIdealLanguage from "../../common/types/IIdealLanguage";
import IIdealQualification from "../../common/types/IIdealQualification";
import ISelectType from "../../common/types/ISelectType";
import { IIdealSkill } from "../../common/types/ISkillScore";
import AdminJobSelectors from "../_selectors/AdminJobSelectors";
import AdminJobService from "../_services/AdminJobService";

const NAME = "ADMIN_JOB";

const createJob = createAsyncThunk(
    `${NAME}/createJob`,
    AdminJobService.createJob
);
const editJob = createAsyncThunk(`${NAME}/editJob`, AdminJobService.editJob);
const editSalary = createAsyncThunk(
    `${NAME}/editSalary`,
    AdminJobService.editSalary
);

const getJobs = createAsyncThunk(`${NAME}/getJobs`, AdminJobService.getJobs);
const getJobPost = createAsyncThunk(
    `${NAME}/getJobPost`,
    AdminJobService.getJobPost
);
const getEmploymentTypes = createAsyncThunk(
    `${NAME}/getEmploymentTypes`,
    AdminJobService.getEmploymentTypes
);
const getSalaryTypes = createAsyncThunk(
    `${NAME}/getSalaryTypes`,
    AdminJobService.getSalaryTypes
);

const saveBenefit = createAsyncThunk(
    `${NAME}/saveBenefit`,
    AdminJobService.saveBenefit
);
const deleteBenefit = createAsyncThunk(
    `${NAME}/deleteBenefit`,
    AdminJobService.deleteBenefit
);

const getWorkloads = createAsyncThunk(
    `${NAME}/getWorkloads`,
    AdminJobService.getWorkloads
);

const saveSkills = createAsyncThunk(
    `${NAME}/saveSkills`,
    AdminJobService.saveSkills
);
const deleteSkill = createAsyncThunk(
    `${NAME}/deleteSkill`,
    AdminJobService.deleteSkill
);

const saveLanguage = createAsyncThunk(
    `${NAME}/saveLanguage`,
    AdminJobService.saveLanguage
);
const deleteLanguage = createAsyncThunk(
    `${NAME}/deleteLanguage`,
    AdminJobService.deleteLanguage
);

const saveQualification = createAsyncThunk(
    `${NAME}/saveQualification`,
    AdminJobService.saveQualification
);
const deleteQualification = createAsyncThunk(
    `${NAME}/deleteQualification`,
    AdminJobService.deleteQualification
);

const publishJob = createAsyncThunk(
    `${NAME}/publishJob`,
    AdminJobService.publishJob
);
const unpublishJob = createAsyncThunk(
    `${NAME}/unpublishJob`,
    AdminJobService.unpublishJob
);

const saveJobExperience = createAsyncThunk(
    `${NAME}/saveJobExperience`,
    AdminJobService.saveJobExperience
);

const saveInfo = createAsyncThunk(`${NAME}/saveInfo`, AdminJobService.saveInfo);

type State = {
    edit: IBaseState<ICompanyJob> & {
        sending: boolean;
        employmentTypes: IBaseState<ISelectType[]>;
        salaryTypes: IBaseState<ISelectType[]>;
        benefits: {
            sending: boolean;
        };
        workloads: IBaseState<ISelectType[]>;
        pending: {
            lang: number[];
            skills: number[];
            qualifications: number[];
            benefits: number[];
        };
    };
    list: IBaseState<any[]>;
    create: {
        loading: boolean;
        success: boolean;
        id: number;
    };
};

const initialState: State = {
    edit: {
        data: null,
        loading: false,
        sending: false,
        employmentTypes: {
            data: null,
            loading: false,
        },
        salaryTypes: {
            data: null,
            loading: false,
        },
        benefits: {
            sending: false,
        },
        workloads: {
            data: null,
            loading: false,
        },
        pending: {
            lang: [],
            skills: [],
            qualifications: [],
            benefits: [],
        },
    },
    list: {
        data: [],
        loading: false,
    },
    create: {
        loading: false,
        success: false,
        id: null,
    },
};

const slice = createSlice({
    name: NAME,
    reducers: {
        createJobReset: (state) => {
            state.create.id = null;
            state.create.success = false;
        },
    },
    initialState,
    extraReducers: (builder) => {
        builder.addCase(createJob.pending, (state) => {
            state.create.loading = true;
            state.create.success = false;
            state.create.id = null;
        });
        builder.addCase(createJob.fulfilled, (state, action) => {
            state.create.loading = false;
            state.create.success = true;
            state.create.id = action.payload?.data?.id;
            !!action.payload?.data && state.list.data.push(action.payload.data);
        });
        builder.addCase(createJob.rejected, (state) => {
            state.create.loading = false;
            state.create.success = false;
            state.create.id = null;
        });

        builder.addCase(editJob.pending, (state) => {
            state.edit.sending = true;
        });
        builder.addCase(editJob.fulfilled, (state, action) => {
            state.edit.sending = false;
            state.edit.data = {
                ...state.edit.data,
                ...action.meta.arg,
            };
        });
        builder.addCase(editJob.rejected, (state) => {
            state.edit.sending = false;
        });

        builder.addCase(editSalary.pending, (state) => {
            state.edit.sending = true;
        });
        builder.addCase(editSalary.fulfilled, (state, action) => {
            state.edit.sending = false;
            state.edit.data = {
                ...state.edit.data,
                ...action.meta.arg,
            };
        });
        builder.addCase(editSalary.rejected, (state) => {
            state.edit.sending = false;
        });

        builder.addCase(getJobPost.pending, (state, action) => {
            state.edit.loading = true;
            state.edit.tag = action.meta.arg.jobPostId;
        });
        builder.addCase(getJobPost.fulfilled, (state, action) => {
            ReducerHelpers.fulfilled(state.edit, action);
        });
        builder.addCase(getJobPost.rejected, (state) =>
            ReducerHelpers.rejected(state.edit)
        );

        builder.addCase(getJobs.pending, (state) =>
            ReducerHelpers.pending(state.list)
        );
        builder.addCase(getJobs.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.list, action)
        );
        builder.addCase(getJobs.rejected, (state) =>
            ReducerHelpers.rejected(state.list)
        );

        builder.addCase(getEmploymentTypes.pending, (state) =>
            ReducerHelpers.pending(state.edit.employmentTypes)
        );
        builder.addCase(getEmploymentTypes.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.edit.employmentTypes, action)
        );
        builder.addCase(getEmploymentTypes.rejected, (state) =>
            ReducerHelpers.rejected(state.edit.employmentTypes)
        );

        builder.addCase(getSalaryTypes.pending, (state) =>
            ReducerHelpers.pending(state.edit.salaryTypes)
        );
        builder.addCase(getSalaryTypes.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.edit.salaryTypes, action)
        );
        builder.addCase(getSalaryTypes.rejected, (state) =>
            ReducerHelpers.rejected(state.edit.salaryTypes)
        );

        builder.addCase(getWorkloads.pending, (state) =>
            ReducerHelpers.pending(state.edit.workloads)
        );
        builder.addCase(getWorkloads.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.edit.workloads, action)
        );
        builder.addCase(getWorkloads.rejected, (state) =>
            ReducerHelpers.rejected(state.edit.workloads)
        );

        builder.addCase(saveSkills.pending, (state, action) => {
            const id = action.meta.arg.id;
            const skills = state.edit.data?.idealCandidate?.skills || [];
            const index = skills.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.skills.push(id);
            }
        });
        builder.addCase(saveSkills.fulfilled, (state, action) => {
            const id = action.payload?.data?.id;
            const {
                weightType,
                timeYearCountMax,
                timeYearCountMin,
                levelMax,
                levelMin,
                skills,
            } = action.meta.arg;
            const idealSkill = {
                id,
                weightType,
                timeYearCountMax,
                timeYearCountMin,
                levelMax,
                levelMin,
                skills,
            } as IIdealSkill;

            const idealSkills = state.edit.data?.idealCandidate?.skills || [];
            const index = idealSkills.findIndex((item) => {
                return item.id === id;
            });

            if (index >= 0) {
                idealSkills[index] = idealSkill;
            } else {
                idealSkills.push(idealSkill);
            }

            const pending = state.edit.pending.skills;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(saveSkills.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.skills;
            const index = pending.indexOf(id);

            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(deleteSkill.pending, (state, action) => {
            const id = action.meta.arg.skillGroupId;
            const skills = state.edit.data?.idealCandidate?.skills || [];

            const index = skills.findIndex(
                (skill) => skill.id === action.meta.arg.skillGroupId
            );

            if (index >= 0) {
                state.edit.pending.skills.push(id);
            }
        });
        builder.addCase(deleteSkill.fulfilled, (state, action) => {
            const id = action.meta.arg.skillGroupId;
            const skills = state.edit.data?.idealCandidate?.skills || [];
            const index = skills.findIndex((item) => item.id === id);

            if (index >= 0) {
                skills.splice(index, 1);
            }

            const pending = state.edit.pending.skills;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(deleteSkill.rejected, (state, action) => {
            const id = action.meta.arg.skillGroupId;

            const pending = state.edit.pending.skills;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(saveLanguage.pending, (state, action) => {
            const id = action.meta.arg.id;
            const langs = state.edit.data?.idealCandidate?.languages || [];
            const index = langs.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.lang.push(id);
            }
        });
        builder.addCase(saveLanguage.fulfilled, (state, action) => {
            const id = action.payload?.data?.id;
            const { weightType, levelMax, levelMin, language } =
                action.meta.arg;
            const idealLanguage: IIdealLanguage = {
                id,
                weightType,
                levelMax,
                levelMin,
                language,
            };

            const langs = state.edit.data?.idealCandidate?.languages || [];
            const index = langs.findIndex((item) => {
                return item.id === id;
            });

            if (index >= 0) {
                langs[index] = idealLanguage;
            } else {
                langs.push(idealLanguage);
            }

            const pending = state.edit.pending.lang;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(saveLanguage.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.lang;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(deleteLanguage.pending, (state, action) => {
            const id = action.meta.arg.id;
            const langs = state.edit.data?.idealCandidate?.languages || [];
            const index = langs?.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.lang.push(id);
            }
        });
        builder.addCase(deleteLanguage.fulfilled, (state, action) => {
            const id = action.meta.arg.id;
            const langs = state.edit.data?.idealCandidate?.languages || [];
            const index = langs?.findIndex((item) => item.id === id);

            if (index >= 0) {
                langs.splice(index, 1);
            }

            const pending = state.edit.pending.lang;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(deleteLanguage.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.lang;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(saveQualification.pending, (state, action) => {
            const id = action.meta.arg.id;
            const qualifications =
                state.edit.data?.idealCandidate?.qualificationItems || [];
            const index = qualifications.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.qualifications.push(id);
            }
        });
        builder.addCase(saveQualification.fulfilled, (state, action) => {
            const id = action.payload?.data?.id;

            const qualification = action.meta.arg as IIdealQualification;
            qualification.id = id;

            const qualifications =
                state.edit.data?.idealCandidate?.qualificationItems || [];
            const index = qualifications.findIndex((item) => {
                return item.id === id;
            });

            if (index >= 0) {
                qualifications[index] = qualification;
            } else {
                qualifications.push(qualification);
            }

            const pending = state.edit.pending.qualifications;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(saveQualification.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.qualifications;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(deleteQualification.pending, (state, action) => {
            const id = action.meta.arg.id;
            const qualifications =
                state.edit.data?.idealCandidate?.qualificationItems || [];
            const index = qualifications?.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.qualifications.push(id);
            }
        });
        builder.addCase(deleteQualification.fulfilled, (state, action) => {
            const id = action.meta.arg.id;
            const qualifications =
                state.edit.data?.idealCandidate?.qualificationItems || [];
            const index = qualifications?.findIndex((item) => item.id === id);

            if (index >= 0) {
                qualifications.splice(index, 1);
            }

            const pending = state.edit.pending.qualifications;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(deleteQualification.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.qualifications;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(saveBenefit.pending, (state, action) => {
            const id = action.meta.arg?.category?.id;
            const benefits = state.edit.data?.jobBenefits || [];
            const index = benefits.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.benefits.push(id);
            }
        });
        builder.addCase(saveBenefit.fulfilled, (state, action) => {
            const { meta, payload } = action;
            const id = payload?.data?.id;
            const { category, metricType, value, currency, metric } = meta.arg;
            const benefit: IBenefit = {
                id,
                name: category.name,
                metricTypePair: metricType,
                metricPair: metric,
                value,
                currency,
                formattedValue: payload?.data?.formattedValue,
                logoUrl: payload?.data?.logoUrl,
                description: "",
                featured: null,
            };

            const benefits = state.edit.data?.jobBenefits || [];
            const index = benefits.findIndex((item) => {
                return item.id === id;
            });

            if (index >= 0) {
                benefits[index] = benefit;
            } else {
                benefits.push(benefit);
            }

            const pending = state.edit.pending.benefits;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(saveBenefit.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.benefits;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(deleteBenefit.pending, (state, action) => {
            const id = action.meta.arg.id;
            const benefits = state.edit.data?.jobBenefits || [];
            const index = benefits?.findIndex((item) => item.id === id);

            if (index >= 0) {
                state.edit.pending.benefits.push(id);
            }
        });
        builder.addCase(deleteBenefit.fulfilled, (state, action) => {
            const id = action.meta.arg.id;
            const benefits = state.edit.data?.jobBenefits || [];
            const index = benefits?.findIndex((item) => item.id === id);

            if (index >= 0) {
                benefits.splice(index, 1);
            }

            const pending = state.edit.pending.benefits;
            const pendingIndex = pending.indexOf(id);

            if (pendingIndex >= 0) {
                pending.splice(pendingIndex, 1);
            }
        });
        builder.addCase(deleteBenefit.rejected, (state, action) => {
            const id = action.meta.arg.id;

            const pending = state.edit.pending.benefits;
            const index = pending.indexOf(id);
            if (index >= 0) {
                pending.splice(index, 1);
            }
        });

        builder.addCase(saveJobExperience.fulfilled, (state, action) => {
            const { jobExperienceYearMax, jobExperienceYearMin } =
                action.meta.arg;
            if (!state.edit.data) {
                state.edit.data = {} as any;
            }

            if (!state.edit.data.idealCandidate) {
                state.edit.data.idealCandidate = {} as any;
            }

            state.edit.data.idealCandidate = {
                ...state.edit.data.idealCandidate,
                jobExperienceYearMax,
                jobExperienceYearMin,
            };
        });

        builder.addCase(saveInfo.pending, (state) => {
            state.edit.sending = true;
        });

        builder.addCase(saveInfo.fulfilled, (state, action) => {
            state.edit.sending = false;
            const { jobOtherInfos } = action.meta.arg;
            if (!state.edit.data) {
                state.edit.data = {} as any;
            }

            if (!state.edit.data.jobOtherInfos) {
                state.edit.data.jobOtherInfos = [] as any;
            }

            state.edit.data.jobOtherInfos = jobOtherInfos;
        });

        builder.addCase(saveInfo.rejected, (state) => {
            state.edit.sending = false;
        });
    },
});

const AdminJobActions = {
    createJob,
    editJob,
    editSalary,
    getJobs,
    getJobPost,
    getEmploymentTypes,
    getSalaryTypes,
    saveBenefit,
    deleteBenefit,
    getWorkloads,
    saveSkills,
    deleteSkill,
    publishJob,
    unpublishJob,
    saveLanguage,
    deleteLanguage,
    saveQualification,
    deleteQualification,
    saveJobExperience,
    useGetJobPost,
    saveInfo,
    ...slice.actions,
};

function useGetJobPost(jobPostId: number, invalidate = 30) {
    const { timestamp, tag } = AdminJobSelectors.useGetEdit();
    const dispatch = useDispatch();
    const tagRef = useRef(tag);
    const invalidateRef = useRef(invalidate);
    const timestampRef = useRef(timestamp);

    useEffect(() => {
        if (
            tagRef.current !== jobPostId ||
            Date.now() - invalidateRef.current * 60000 >= timestampRef.current
        ) {
            dispatch(
                AdminJobActions.getJobPost({
                    jobPostId,
                })
            );
        }
    }, [dispatch, jobPostId]);
}

export default AdminJobActions;
export const AdminJobReducer = slice.reducer;
