import styles from "./MatchPie.module.scss";
import { resolveFit } from "../../../common/_helpers/resolveFit";
import { Pie, PieChart } from "recharts";
import { MathHelper } from "../../../common/_helpers";
import { CSSProperties, useMemo } from "react";

type MatchPieProps = {
    match: number;
    size?: number;
    thickness?: number;
    color?: string;
    isAnimationActive?: boolean;
    fontSize?: number;
    position?: "none" | "center" | "right";
};

// export function MatchPie(props: MatchPieProps) {
//     const { match, thickness = 10 } = props;

//     return (
//         <div className={styles.pie} style={{'--thickness': thickness}}>
//             <div className={cx(styles.segment, styles.empty)}   />
//             <div className={cx(styles.segment, styles.fill)} style={{'--percent': 40}} />
//             {match}
//         </div>
//     );
// }

export function MatchPie(props: MatchPieProps) {
    const {
        match = 0,
        thickness = 9,
        size = 30,
        color,
        fontSize = 18,
        // position = "center",
        isAnimationActive = false,
    } = props;

    const outerRadius = size;
    const innerRadius = size - thickness;

    const data = useMemo(
        () => [
            { value: match, fill: color || resolveFit(match).color },
            { value: 1 - match, fill: "#E5E6E8" },
        ],
        [match, color]
    );
    const diameter = outerRadius * 2;

    return (
        <div className={styles.pie} style={{ fontSize, "--size": diameter } as CSSProperties}>
            <Match match={match} />
            <PieChart width={diameter} height={diameter}>
                <Pie
                    data={data}
                    dataKey="value"
                    startAngle={90}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    endAngle={-270}
                    paddingAngle={1}
                    fill="#82ca9d"
                    isAnimationActive={isAnimationActive && match > 0}
                    animationBegin={0}
                />
            </PieChart>
        </div>
    );
}

function Match(props: { match: number }) {
    return (
        <div
            className={styles.match}
            style={{ "--value": MathHelper.round(props.match * 100, 0) } as CSSProperties}
        >
            {/* {MathHelper.round(props.match * 100, 0)} */}
            <sup>%</sup>
        </div>
    );
}
