import { combineReducers } from "redux";

import common from "./common/_reducers/index";
import candidate from "./candidate/_reducers/index";
import admin from "./admin/_reducers/index";

const rootReducer = combineReducers({
    common,
    candidate,
    admin,
});

export default rootReducer;

export type MainState = ReturnType<typeof rootReducer>;
