import TccTest from "../../../../components/TccTest/TccTest";
import useSoftSkillsTest from "../../../../hooks/questionnaire/useSoftSkillsTest";
import styles from "./OnboardingTest.module.scss";

const TEST_NAME = "MOP";

export default function OnboardingTest() {
    const { test, locale, completeTest } = useSoftSkillsTest(TEST_NAME);

    return (
        <TccTest
            testName={TEST_NAME}
            test={test}
            locale={locale}
            className={styles.test}
            onCompleted={completeTest}
        />
    );
}
