import styles from "./Label.module.scss";

export type LabelProps = {
    label: string | React.ReactElement;
    required?: boolean;
};

export default function Label(props: LabelProps) {
    const { label, required } = props;

    return (
        <div className={styles.label}>
            <label className={required && styles.required}>{label}</label>
        </div>
    );
}
