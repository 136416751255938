import React from "react";
import { Translate, useTranslate } from "react-translate.ts";

import { resolveSectionColor } from "../../Interpretation/resolveSectionColor";
import { BaseMop } from "./BaseMop";
import { Link, useRouteMatch } from "react-router-dom";
import { Button } from "../../../../common/components";
import { InterpretationInfo } from "./InterpretationInfo";
import { InterpretationLanguages } from "./InterpretationLanguages";
import {
    SOFT_SKILL_TEST_COMPARE_MOP_PATH,
    SOFT_SKILL_TEST_INTERPRETATION_MOP_PATH,
} from "../../../_constants/route.constants";
import { MathHelper } from "../../../../common/_helpers";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export function Mop(props) {
    const { getStatus, getInterpretation, isMobile } = props;

    const { path } = useRouteMatch();
    const translate = useTranslate(TRANSLATE_KEY);
    const translateInterpretation = useTranslate(
        TRANSLATE_KEY + ".mop.interpretation"
    );

    return (
        <BaseMop
            name="mop"
            extraChartContent={() => (
                <div id="soft-skills-test--mop__chart__legend">
                    {renderLegend("setting")}
                    {renderLegend("decisions")}
                    {renderLegend("activity")}
                    {renderLegend("perception")}
                </div>
            )}
            interpretationContentEmpty={renderInterpretationEmpty}
            interpretationContentProcessed={renderInterpretationProcessed}
            getStatus={getStatus}
            getInterpretation={getInterpretation}
            isMobile={isMobile}
        ></BaseMop>
    );

    function renderInterpretationEmpty(_, __, status) {
        return (
            <>
                <h5>{translate("mop.title")}</h5>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translateInterpretation("info"),
                    }}
                />
                <InterpretationInfo
                    time={translate("mop.duration")}
                    count={status.questionCount}
                />
                <InterpretationLanguages languages={["cs", "sk", "en", "pl"]} />
                <div className="interpretation--icon">
                    <img src="/img/soft-skills/web-search.svg" alt="" />
                </div>
                <div className="interpretation--actions">
                    <div className="interpretation--item">
                        <Link to={path + "/interpretation"}>
                            <Button>{translate("actions.preview")}</Button>
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    function renderInterpretationProcessed(interpretation) {
        const profiles = interpretation.profiles;
        const bestProfile = getBestProfile(profiles);

        if (!profiles || !bestProfile) {
            return null;
        }

        return (
            <>
                <h5>{translateInterpretation("heading")}</h5>
                <div>
                    <ProfileBar profile={bestProfile} />
                    <div className="interpretation__sub-title">
                        {translateInterpretation("profile")}
                    </div>
                    <div className="interpretation__desc font-book">
                        {bestProfile.description}
                    </div>
                    <div className="interpretation__profiles">
                        {profiles.map((profile, i) => {
                            if (i === profiles.length - 1) {
                                return null;
                            }

                            return <ProfileBar key={i} profile={profile} />;
                        })}
                    </div>
                </div>
                <div className="interpretation--actions">
                    <Link to={SOFT_SKILL_TEST_INTERPRETATION_MOP_PATH}>
                        <Button>{translateInterpretation("action")}</Button>
                    </Link>
                    <Link to={SOFT_SKILL_TEST_COMPARE_MOP_PATH}>
                        <Button variant="secondary">
                            {translate("actions.compare")}
                        </Button>
                    </Link>
                </div>
            </>
        );

        function ProfileBar(props) {
            const { profile } = props;
            return (
                <div className="interpretation__profiles__profile">
                    <div>{profile.name}</div>
                    <div className="profile-bar">
                        <div
                            className={"profile-bar__fill " + profile.section}
                            style={{
                                width: `calc(${profile.value}% - 37px)`,
                            }}
                        />
                        <div className="profile-bar__value">
                            {MathHelper.round(profile.value)}%
                        </div>
                    </div>
                </div>
            );
        }
    }
}

function renderLegend(name) {
    return (
        <div className="legend-item">
            <div
                className="legend-item__bar"
                style={{ background: resolveSectionColor(name) }}
            />
            <Translate>
                {`candidate.interpretation.mop.headings.${name}`}
            </Translate>
        </div>
    );
}

function getBestProfile(profiles) {
    if (ArrayHelper.isEmpty(profiles)) {
        return null;
    }

    return profiles[profiles.length - 1];
}
