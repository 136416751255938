import { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import { cx } from "@emotion/css";

import styles from "./Panel.module.scss";

export type PanelProps = {
    className?: string;
    anchor?: string;
    style?: CSSProperties;
};

export function Panel(props: PropsWithChildren<PanelProps>) {
    const { children, className, anchor, style, ...rest } = props;

    return (
        <div className={cx(styles.panel, className)} style={style} {...rest}>
            {anchor ? <div className={styles.anchor} id={anchor} /> : null}
            {children}
        </div>
    );
}

export type PanelHeadingProps = PropsWithChildren<
    PanelProps & HTMLAttributes<HTMLDivElement>
>;

function Heading(props: PanelHeadingProps) {
    const { children, className, style } = props;

    return (
        <div className={cx(styles.heading, className)} style={style}>
            {children}
        </div>
    );
}

function Subtitle(props: PropsWithChildren<PanelHeadingProps>) {
    const { children, className } = props;

    return (
        <div className={cx(styles.subtitle, "font-book", className)}>
            {children}
        </div>
    );
}

Panel.Heading = Heading;
Panel.Subtitle = Subtitle;
