import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Map } from "immutable";

import { PROFILE_HARD_SKILLS_PATH } from "../../../../_constants/route.constants";
import { ProfileEdit } from "../ProfileEdit";
import ProfileInformationSelectors from "../../../../_selectors/ProfileInformationSelectors";
import { AuthSelectors } from "../../../../../common/_selectors";
import { ProfileInformationActions } from "../../../../_actions/ProfileInformationActions";
import { HardSkillsEditor } from "../../../../../common/components/skills/HardSkillsEditor";

let oldSkills = null;

export function HardSkillsEdit() {
    const { skills, hardSkills, profileId } = useSelector(mapState);
    const { saveSkills, revertSkills } = mapDispatch(useDispatch());
    const history = useHistory();
    const location = useLocation();
    const editor = useRef(null);
    const [validation, setValidation] = useState({
        isValid: false,
        errors: Map(),
    });

    useEffect(() => {
        oldSkills = skills;

        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    useEffect(scrollToSkill, [location.hash, hardSkills]);

    return render();
    function render() {
        return (
            <ProfileEdit
                forwardRef={editor}
                onClose={cancel}
                heading="candidate.profile.hardSkills.edit.heading"
                subHeading="candidate.profile.hardSkills.edit.subHeading"
                formButtonsProps={{
                    onCancel: cancel,
                    onConfirm: confirm,
                    confirmText: "common.actions.save",
                }}
            >
                <HardSkillsEditor />
            </ProfileEdit>
        );
    }

    function cancel() {
        revertSkills(oldSkills);
        close();
    }

    function confirm() {
        const { isValid, errors } = validateSkills(hardSkills);
        setValidation({ isValid, errors });
        if (!isValid) {
            return;
        }

        saveSkills(hardSkills, profileId);
        close();
    }

    function close() {
        history.push(PROFILE_HARD_SKILLS_PATH);
    }

    function scrollToSkill() {
        const hash = location.hash;
        const element = hash
            ? document.getElementById(hash.replace("#", ""))
            : null;
        if (element && editor.current) {
            const rect = element.getBoundingClientRect();
            editor.current.scrollTop = rect.top;
            history.replace(location.pathname);
        }
    }
}

function validateSkills(skills) {
    const errors = skills.reduce((accumulator, value) => {
        const skillErrors = validateSkill(value);
        if (Object.keys(skillErrors).length < 1) {
            return accumulator;
        }
        return accumulator.set(value.skill.id, skillErrors);
    }, Map());
    const isValid = errors.size < 1;

    return { isValid, errors };
}

function validateSkill({ timeYearCount, level }) {
    let errors = {};
    if (timeYearCount < 1) {
        errors.timeYearCount = "required";
    }

    if (level < 1) {
        errors.level = "required";
    }

    return errors;
}

function mapDispatch(dispatch) {
    return {
        saveSkills: (skills, profileId) =>
            dispatch(ProfileInformationActions.saveSkills(skills, profileId)),
        revertSkills: (oldSkills) =>
            dispatch(ProfileInformationActions.revertSkillChanges(oldSkills)),
    };
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        skills: ProfileInformationSelectors.getSkills(state),
        hardSkills: ProfileInformationSelectors.getHardSkillsList(state),
    };
}
