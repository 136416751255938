import { createSelector } from 'reselect';
import { ArrayHelper } from '../../common/_helpers/ArrayHelper';

const loading = state => state.candidate.jobs.search.loading;
const cards = state => state.candidate.jobs.search.cards;

const isLoading = createSelector(
    loading,
    cards,
    (loading, cards) => loading && ArrayHelper.isEmpty(cards)
);

const getCards = createSelector(cards, cards => cards.valueSeq().toArray());

const getFavourites = createSelector(getCards, cards =>
    cards.filter(card => card.isFavourite)
);

const getFavouritesCount = createSelector(getFavourites, cards => cards.length);

export const JobSearchSelectors = {
    isLoading,
    getCards,
    getFavourites,
    getFavouritesCount
};
