import React from 'react';
import {
    ResponsiveContainer,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    Legend
} from 'recharts';
import { useWindowState } from '../misc/WindowStateProvider';
import { BREAKPOINT_SM } from '../../_constants/theme.constants';

export function BaseRadarChart({
    children,
    data,
    withLegend = true,
    width = '100%',
    height = '100%'
}) {
    const windowWidth = useWindowState().width;

    return (
        <div style={{ width, height }}>
            <ResponsiveContainer>
                <RadarChart data={data}>
                    {renderLegend()}
                    {children}
                    <PolarGrid gridType="circle" stroke="#e7e6e6" />
                    {windowWidth < BREAKPOINT_SM ? null : (
                        <PolarAngleAxis
                            dataKey="name"
                            tickLine={{ size: 20 }}
                            tick={{ fill: '#293140', fontSize: 14 }}
                        />
                    )}
                    {/*<PolarRadiusAxis angle={15} domain={[0, 'dataMax']} tickCount={6} />*/}
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );

    function renderLegend() {
        if (!withLegend) {
            return null;
        }
        return <Legend wrapperStyle={{ paddingTop: 30, fontSize: 14 }} />;
    }
}
