import React from "react";
import { useTranslate } from "react-translate.ts";
import {
    Dot,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Area,
    BarChart,
    Bar,
} from "recharts";
import { PRIMARY_COLOR } from "../../_constants/theme.constants";
import { MathHelper } from "../../_helpers";
import { ArrayHelper } from "../../_helpers/ArrayHelper";
import { BaseTooltip } from "./BaseTooltip";

export function SoftSkillsBarChart(props) {
    const {
        data,
        height = "100%",
        width = "100%",
        maxHeight,
        idealRangeKey = "idealRange",
        valueKey = "you",
        nameKey = "name",
        displayOnly = false,
        style = {},
    } = props;

    const translate = useTranslate("common.misc");

    return (
        <div
            className="soft-skills-bar-chart"
            style={{ width, height, maxHeight, ...style }}
        >
            <ResponsiveContainer
                width={width}
                height={height}
                maxHeight={maxHeight}
            >
                <AreaChart
                    width={width}
                    height={height}
                    data={data}
                    layout="vertical"
                >
                    <XAxis
                        dataKey={valueKey}
                        domain={[0, 100]}
                        type="number"
                        hide
                    />
                    <YAxis
                        dataKey={nameKey}
                        type="category"
                        tickFormatter={(value) => value}
                        angle={-25}
                        padding={{ bottom: 16, top: 16 }}
                        width={80}
                    />
                    <CartesianGrid vertical={false} />
                    <Tooltip
                        isAnimationActive={false}
                        content={(props) => {
                            const { payload } = props;
                            const data =
                                payload &&
                                !ArrayHelper.isEmpty(payload) &&
                                payload[0].payload;
                            const { idealMin, idealMax, you, match } = data
                                ? data
                                : {};

                            return (
                                <BaseTooltip
                                    isAbsolute={false}
                                    withArrow={false}
                                    title={`${translate(
                                        "match"
                                    )} ${MathHelper.round(match, 0)}%`}
                                    panels={[
                                        {
                                            label: translate(
                                                displayOnly
                                                    ? "candidateScore"
                                                    : "yourScore"
                                            ),
                                            value: you + "%",
                                        },
                                        {
                                            label: translate("requiredScore"),
                                            value: `${idealMin}-${idealMax}%`,
                                        },
                                    ]}
                                />
                            );
                        }}
                    />
                    <Area
                        type="monotone"
                        dataKey={idealRangeKey}
                        fillOpacity={0.4}
                        fill={PRIMARY_COLOR}
                        strokeOpacity={0}
                    />
                    <Area
                        type="monotone"
                        dataKey={valueKey}
                        fill={PRIMARY_COLOR}
                        fillOpacity={0}
                        stroke={PRIMARY_COLOR}
                        strokeOpacity={1}
                        strokeWidth={3}
                        dot={(props) => {
                            const { strokeWidth } = props;
                            return (
                                <Dot
                                    {...props}
                                    r={strokeWidth * 2.15}
                                    strokeWidth={strokeWidth}
                                    stroke="#fff"
                                    fillOpacity={1}
                                />
                            );
                        }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
