import {
    LOGIN_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    SIGNUP_SUCCESS,
} from "../../_constants/actions/auth.constants";
import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";
import { UserService } from "../../_services";

export default function (state = !!UserService.getUser(), action) {
    switch (action.type) {
        case LOGIN:
        case LOGIN_FAIL:
        case LOGOUT:
        case HeroOnboardingConstants.SAVE_USER:
            return false;
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS:
        case HeroOnboardingConstants.SAVE_USER_SUCCESS:
            return true;
        default:
            return state;
    }
}
