import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { StepProgressBar } from '../misc/StepProgressBar';

export function SkillMatchBar(props) {
    const { name, level, yearsOfExperience, steps, noYears = false } = props;
    const translateMisc = useTranslate('common.misc');

    return (
        <div className="font-book progress-chart--item">
            <div className="progress-chart--item--name">{name}</div>
            {noYears ? null : (
                <div className="progress-chart--item--info">
                    {translateMisc('yearsOfExperience', {
                        count: yearsOfExperience || 0,
                    })}
                </div>
            )}
            <div className="w100">
                <StepProgressBar
                    className="is-rounded"
                    step={level}
                    steps={steps}
                />
            </div>
        </div>
    );
}
