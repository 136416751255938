import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useTranslate } from 'react-translate.ts';
import { useSelector, useDispatch } from 'react-redux';

import { TrackingLayout } from '../../../common/components';
import {
    APPLICATIONS_TRACKING_PATH,
    APPLICATIONS_TRACKING_ARCHIVED_PATH,
    APPLICATIONS_TRACKING_REJECTED_PATH,
    APPLICATIONS_TRACKING_SENT_PATH,
    APPLICATIONS_TRACKING_ACCEPTED_PATH,
    APPLICATIONS_TRACKING_SHORTLIST_PATH,
} from '../../_constants/route.constants';
import { Active } from './components/Active';
import { Archived } from './components/Archived';
import { Rejected } from './components/Rejected';
import { useWindowState } from '../../../common/components/misc/WindowStateProvider';
import { BREAKPOINT_SM } from '../../../common/_constants/theme.constants';
import { ApplicationsTrackingSelectors } from '../../_selectors/ApplicationsTrackingSelectors';
import { ApplicationsList } from './components/ApplicationsList';
import { useEffect } from 'react';
import { ApplicationsTrackingActions } from '../../_actions';
import { AuthSelectors } from '../../../common/_selectors/AuthSelectors';

export function ApplicationsTracking() {
    const { state, profileId } = useSelector((state) => ({
        state,
        profileId: AuthSelectors.getProfileId(state),
    }));
    const { width } = useWindowState();

    const translate = useTranslate('candidate.jobs.tracking');

    const isMobile = width <= BREAKPOINT_SM;

    return (
        <TrackingLayout
            heading={translate('heading')}
            links={getLinks(state, translate, isMobile)}
        >
            {isMobile ? (
                <Mobile profileId={profileId} />
            ) : (
                <Desktop profileId={profileId} />
            )}
        </TrackingLayout>
    );
}

function Desktop({ profileId }) {
    return (
        <DndProvider backend={Backend}>
            <Switch>
                <Route exact path={APPLICATIONS_TRACKING_PATH}>
                    <Active profileId={profileId} />
                </Route>
                <Route path={APPLICATIONS_TRACKING_REJECTED_PATH}>
                    <Rejected profileId={profileId} />
                </Route>
                <Route path={APPLICATIONS_TRACKING_ARCHIVED_PATH}>
                    <Archived profileId={profileId} />
                </Route>
            </Switch>
        </DndProvider>
    );
}

function Mobile({ profileId }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(ApplicationsTrackingActions.getApplications(profileId));
        }
    }, [profileId]);

    return (
        <Switch>
            <Route path={APPLICATIONS_TRACKING_SENT_PATH}>
                <ApplicationsList
                    applicationsSelector={
                        ApplicationsTrackingSelectors.getApplied
                    }
                />
            </Route>
            <Route path={APPLICATIONS_TRACKING_SHORTLIST_PATH}>
                <ApplicationsList
                    applicationsSelector={
                        ApplicationsTrackingSelectors.getShortlisted
                    }
                />
            </Route>
            <Route exact path={APPLICATIONS_TRACKING_PATH}>
                <ApplicationsList
                    applicationsSelector={
                        ApplicationsTrackingSelectors.getInvited
                    }
                />
            </Route>
            <Route path={APPLICATIONS_TRACKING_ACCEPTED_PATH}>
                <ApplicationsList
                    applicationsSelector={
                        ApplicationsTrackingSelectors.getAccepted
                    }
                />
            </Route>
        </Switch>
    );
}

function getLinks(state, translate, isMobile) {
    const {
        appliedCount,
        shortlistCount,
        acceptedCount,
        invitedCount,
        activeCount,
    } = mapState(state);

    if (isMobile) {
        return [
            {
                path: APPLICATIONS_TRACKING_SENT_PATH,
                label: translate('links.sent'),
                count: appliedCount,
            },
            {
                path: APPLICATIONS_TRACKING_SHORTLIST_PATH,
                label: translate('links.shortlist'),
                count: shortlistCount,
            },
            {
                path: APPLICATIONS_TRACKING_PATH,
                label: translate('links.invited'),
                count: invitedCount,
                exact: true,
            },
            {
                path: APPLICATIONS_TRACKING_ACCEPTED_PATH,
                label: translate('links.accepted'),
                count: acceptedCount,
            },
        ];
    }

    return [
        {
            path: APPLICATIONS_TRACKING_PATH,
            label: translate('links.active'),
            count: activeCount,
            exact: true,
        },
        {
            path: APPLICATIONS_TRACKING_REJECTED_PATH,
            label: translate('links.declined'),
        },
        {
            path: APPLICATIONS_TRACKING_ARCHIVED_PATH,
            label: translate('links.archived'),
        },
    ];
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
        appliedCount: ApplicationsTrackingSelectors.getAppliedCount(state),
        shortlistCount: ApplicationsTrackingSelectors.getShortlistedCount(
            state
        ),
        acceptedCount: ApplicationsTrackingSelectors.getAcceptedCount(state),
        invitedCount: ApplicationsTrackingSelectors.getInvitedCount(state),
        activeCount: ApplicationsTrackingSelectors.getActiveCount(state),
    };
}
