import React, { useEffect, useState } from "react";
import { createClass } from "classname-helper";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AuthActions } from "../../_actions";
import { SVGIcon } from "../SVGIcon";
import { DropdownMenu } from "../header/DropdownMenu";
import { useTranslate } from "react-translate.ts";
import { Languages } from "../header/Languages";

function OnboardingLinks(props) {
    const {
        steps,
        currentStep,
        nextStepIsValid,
        onBack,
        onForward,
    } = props;

    const step = steps[currentStep];
    const stepCount = steps.length;

    return (
        <div className="onboarding-links">
            <div className="bar">
                <div
                    className="fill"
                    style={{
                        width: `${((currentStep + 1) / stepCount) * 100}%`,
                    }}
                />
            </div>
            <div className="onboarding-links__head">
                <div
                    className={createClass({
                        "step-button": true,
                        back: true,
                        disabled: currentStep <= 0,
                    })}
                    onClick={removeStep}
                >
                    <SVGIcon name="chevron-left" />
                </div>
                <div className="name font-heavy">
                    <div>{step.name}</div>
                    <div className="steps">
                        Step {currentStep + 1}/{stepCount}
                    </div>
                </div>
                <div
                    className={createClass({
                        "step-button": true,
                        forward: true,
                        disabled: !nextStepIsValid,
                    })}
                    onClick={addStep}
                >
                    <SVGIcon name="chevron-right" />
                </div>
            </div>
        </div>
    );

    function addStep() {
        if (!nextStepIsValid) {
            return;
        }

        onForward && onForward();
    }

    function removeStep() {
        if (currentStep <= 0) {
            return;
        }
        onBack && onBack();
    }
}

function OnboardingLink(props) {
    const { to, children, complete, active } = props;

    const linkContent = (
        <>
            {complete ? (
                <div className="onboarding-link__complete">complete</div>
            ) : null}
            {children}
        </>
    );

    return (
        <div className={createClass({ "onboarding-link": true, complete })}>
            {active ? (
                <NavLink className="nav-link" to={to}>
                    {linkContent}
                </NavLink>
            ) : (
                <div className="nav-link">{linkContent}</div>
            )}
            <div className="onboarding-link__checkmark">
                {complete ? "✓" : null}
            </div>
        </div>
    );

    function render() {
        return (
            <>
                {complete ? (
                    <div className="onboarding-link__complete">complete</div>
                ) : null}
                {children}
            </>
        );
    }
}

function MenuButton(props) {
    const { imageUrl, name } = props;

    const [isOpen, setIsOpen] = useState();

    return (
        <div className="menu-button-wrapper">
            <div className="menu-button" onClick={() => setIsOpen(true)}>
                <div className="picture">
                    <img src={imageUrl ? imageUrl : "/img/avatar.svg"} />
                </div>
                <div className="name font-heavy">{name || "Menu"}</div>
            </div>
            <DropdownMenu
                className="nav-menu"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <SignOut />
            </DropdownMenu>
        </div>
    );
}

function SignOut() {
    const dispatch = useDispatch();
    const translate = useTranslate();

    return (
        <div className="signout" onClick={() => dispatch(AuthActions.logout())}>
            {translate("common.header.nav.sign-out")}
        </div>
    );
}

OnboardingLinks.Link = OnboardingLink;
OnboardingLinks.MenuButton = MenuButton;

export { OnboardingLinks };
