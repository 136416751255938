import { PRIMARY_COLOR, BORDER_COLOR } from '../_constants/theme.constants';

export function useTheme(options) {
    return {
        reactSelect: createReactInputStyle(options),
        searchBar: createSearchBarStyle(),
    };
}

function createReactInputStyle({ showDropdown, hasError, noBorder }) {
    return {
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#fe3464',
            border: '1px solid #fe3464',
            borderRadius: 12,
            color: 'white',
            flexShrink: '0',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            backgroundColor: '#fe3464',
            borderRadius: 12,
        }),
        control: (styles) => ({
            ...styles,
            borderColor: noBorder
                ? 'transparent'
                : hasError
                ? PRIMARY_COLOR
                : BORDER_COLOR,
            '&:hover': noBorder
                ? 'transparent'
                : hasError
                ? PRIMARY_COLOR
                : BORDER_COLOR,
            boxShadow: 0,
            borderRadius: 0,
            height: 50,
            padding: '0 12px',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'white',
        }),
        menu: (styles) => ({ ...styles, position: 'absolute' }),
        valueContainer: (styles) => ({
            ...styles,
            flexWrap: 'nowrap',
            overflowX: 'auto',
            scrollbarColor: '#f6f7f9 white',
            scrollbarWidth: 'thin',
            maxHeight: 45,
            width: '100%',
        }),
        dropdownIndicator: (styles) =>
            showDropdown ? styles : { ...styles, display: 'none' },
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
        placeholder: (styles) => ({
            ...styles,
            color: '#51596c',
            fontSize: 15,
            opacity: 0.52,
        }),
        singleValue: (styles) => ({
            ...styles,
            width: '100%',
            margin: 0,
            paddingRight: 10,
        }),
        option: (styles) => ({
            ...styles,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap',
        }),
    };
}

function createSearchBarStyle() {
    return {
        control: (styles) => ({
            ...styles,
            borderColor: BORDER_COLOR,
            '&:hover': BORDER_COLOR,
            boxShadow: 0,
            borderRadius: 0,
        }),
    };
}
