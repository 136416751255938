import axios, { AxiosResponse } from "axios";

import * as api from "../../types/api";
import { GAUtils } from "../_helpers/GAUtils";
import CacheService from "./CacheService";
import { LanguageService } from "./LanguageService";
import { UserService } from "./UserService";

type Headers = {
    [key: string]: string;
};

interface RequestOptions {
    headers?: Headers;
    onUploadProgress?: (progressEvent: any) => void;
    cache?: { life?: number };
}

const RequestService2 = {
    get,
    post,
    put,
    delete: _delete,
};

type Param = string | number | (string | number)[];

async function get<ResultData = any>(
    path: string,
    params: { [key: string]: Param } = {},
    options: RequestOptions = {}
) {
    const { headers, cache, ...rest } = options;
    const url = new URL(createEndpoint(path));
    const method = "get";
    const requestHeaders = createHeaders(headers);

    for (const param of Object.entries(params)) {
        const [key, value] = param;

        if (value !== null && value !== undefined) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    url.searchParams.append(key, value.join(","));
                }
            } else {
                const string = value.toString().trim();
                if (string.length > 0) {
                    url.searchParams.append(key, string);
                }
            }
        }
    }

    const cacheKey =
        cache && `${method}:${url.href}:${JSON.stringify(requestHeaders)}`;

    if (cache) {
        const cached = CacheService.get(cacheKey);

        if (cached) {
            return { status: 200, data: JSON.parse(cached) as ResultData };
        }
    }

    const response = (await axios({
        method,
        url: url.href,
        headers: requestHeaders,
        ...rest,
    })) as AxiosResponse<ResultData>;

    if (cache) {
        CacheService.set(cacheKey, JSON.stringify(response.data), cache.life);
    }

    GAUtils.event(
        "Response",
        "Received  " + response.status + " " + new URL(url).pathname
    );

    return { status: response.status, data: response.data };
}

async function post<Data = any, ResultData = any>(
    path: string,
    data?: Data,
    options: RequestOptions = {}
) {
    GAUtils.event("Request", "POST SENT " + path);

    return await dataRequest<Data, ResultData>("post", path, data, options);
}

async function put<Data = any, ResultData = any>(
    path: string,
    data?: Data,
    options: RequestOptions = {}
) {
    GAUtils.event("Request", "PUT SENT " + path);

    return await dataRequest<Data, ResultData>("put", path, data, options);
}

async function dataRequest<Data = any, ResultData = any>(
    method: "put" | "post",
    path: string,
    data: Data,
    options: RequestOptions = {}
) {
    const url = createEndpoint(path);
    const { headers, ...rest } = options;

    const response = (await axios({
        method,
        url,
        data: data || {},
        headers: createHeaders(options?.headers),
        ...rest,
    })) as AxiosResponse<ResultData>;

    GAUtils.event(
        "Response",
        "Received  " + response.status + " " + new URL(url).pathname
    );

    return { status: response.status, data: response.data };
}

async function _delete(path: string, options: RequestOptions = {}) {
    GAUtils.event("Request", "DELETE SENT " + path);

    const { headers, ...rest } = options;
    const url = new URL(createEndpoint(path));
    const method = "delete";
    const requestHeaders = createHeaders(headers);

    const response = await axios({
        method,
        url: url.href,
        headers: requestHeaders,
        ...rest,
    });

    GAUtils.event(
        "Response",
        "Received  " + response.status + " " + new URL(url).pathname
    );

    return response;
}

function createEndpoint(path: string) {
    return `${api.endpoint}/${path}`;
}

function createHeaders(headers: Headers = {}) {
    return Object.assign(
        {
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getAccessToken()}`,
            "Accept-Language": LanguageService.getCurrentLocale(),
        },
        headers
    );
}

export default RequestService2;
