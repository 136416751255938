import { RequestService, UserService } from "../../common/_services";

export const OnboardingService = {
    savePersonal,
    saveHardSkills,
    personal,
    education,
    test,
    skip,
};

function savePersonal(payload) {
    return RequestService.post("onboarding/step-personal-details", payload);
}

function saveHardSkills(skills) {
    return RequestService.post("onboarding/step-hard-skills", skills);
}

function personal(values, uuid) {
    const {
        firstName,
        lastName,
        locationId,
        enrolled,
        phoneNumber,
        phoneNumberCountryCode,
    } = values;

    const { code, dial } = phoneNumberCountryCode;
    const body = {
        uuid,
        firstName,
        lastName,
        locationId: locationId.value,
        enrolled,
        phoneNumberCountryCode: code,
        phoneNumber: dial + phoneNumber,
    };

    return RequestService.post("onboarding/step-personal-details", body);
}

function education({ values, uuid }) {
    const {
        endYear,
        startYear,
        universityDegreeFieldId,
        universityDegreeTitleId,
        universityDegreeTypeId,
        universityId,
        locationId,
    } = values;

    const body = {
        uuid,
        startYear: startYear,
        endYear: endYear,
        universityDegreeFieldId: universityDegreeFieldId.value,
        universityDegreeTitleId: universityDegreeTitleId.value,
        universityDegreeTypeId: universityDegreeTypeId.value,
        universityId: universityId.value,
        locationId: locationId.placeId,
    };

    return RequestService.post("onboarding/step-education-details", body);
}

function test() {
    const profileId = UserService.getProfileId();
    return RequestService.get(`diagnostic/${profileId}`);
}

async function skip() {
    const response = await RequestService.put(`onboarding/skip`);
    UserService.setUserStatus(response.data);
    return response;
}
