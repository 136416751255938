import SoftSkillsConstants from "../../../_constants/actions/SoftSkillsConstants";

const data = (state = null, action) => {
    if (action.type === SoftSkillsConstants.GET_TEST_SUCCESS) {
        return action.payload;
    }

    return state;
};

export default data;
