import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTranslate } from "react-translate.ts";

import FiltersWrapper from "../../../common/components/filters/FiltersWrapper/FiltersWrapper";
import { HeroList } from "../../../common/components/heroes/HeroList/HeroList";
import BaseListLayout from "../../../common/components/layout/BaseListLayout/BaseListLayout";
import Pagination from "../../../common/components/misc/Pagination/Pagination";
import useIsSigned from "../../../common/hooks/selectors/useIsSigned";
import HeroActions from "../../../common/_actions/HeroActions";
import HeroSelectors from "../../../common/_selectors/HeroSelectors";
import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export default function HeroListPage() {
    const isSigned = useIsSigned();
    const list = HeroSelectors.useList();
    const { page } = useParams<{ page?: string }>();
    const translate = useTranslate("candidate.heroes.list");
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isSigned) {
            return;
        }

        dispatch(HeroActions.getList({ page: parseInt(page) || 1 }));
    }, [dispatch, isSigned, page]);

    return (
        <CandidatePageLayout>
            <BaseListLayout
                title={translate("title")}
                searchProps={{
                    placeholder: translate("search"),
                    onSearch: console.log,
                }}
                // filtersProps={{
                //     content: <Filters />,
                // }}
            >
                <HeroList heroes={list.data} />
                <Pagination pages={list.pagination.pages} />
            </BaseListLayout>
        </CandidatePageLayout>
    );
}

function Filters() {
    const [state, setState] = useState(0);
    const translate = useTranslate("candidate.heroes.list.filters");

    return (
        <FiltersWrapper>
            <FiltersWrapper.Check
                label={translate("top")}
                active={state === 0}
                onChange={() => setState(0)}
            />
            <FiltersWrapper.Check
                label={translate("recent")}
                active={state === 1}
                onChange={() => setState(1)}
            />
            <FiltersWrapper.Check
                label={translate("all")}
                active={state === 2}
                onChange={() => setState(2)}
            />
        </FiltersWrapper>
    );
}
