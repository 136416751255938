import { takeLatest } from "redux-saga/effects";

import { SagaHelpers } from "../../common/_helpers/SagaHelpers";
import DashboardConstants from "../_constants/actions/DashboardConstants";
import DashboardService from "../_services/DashboardService";

function* getData(action) {
    yield SagaHelpers.handleRequest({
        request: [DashboardService.getData, action.payload],
        actions: [
            DashboardConstants.GET_DATA_SUCCESS,
            DashboardConstants.GET_DATA_FAIL,
        ],
    });
}

export default [takeLatest(DashboardConstants.GET_DATA, getData)];
