import React from "react";
import { ResponsiveContainer, PieChart, Pie } from "recharts";
import { useSelector } from "react-redux";
import { UserProfileSelectors } from "../../../../common/_selectors/UserProfileSelectors";
import { Translate } from "react-translate.ts";

export function ProfileCompletion() {
    const completion = useSelector(UserProfileSelectors.getProfileCompleteness);
    return render();

    function render() {
        const percentage = completion * 100;
        const percentageRounded = (completion * 100).toFixed(0);
        const data = [
            { value: percentage, fill: "#fe3464" },
            { value: 100 - percentage, fill: "#e5e6e8" },
        ];

        return (
            <div id="profile-completion">
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            innerRadius={60}
                            fill="#fe3464"
                            isAnimationActive={false}
                        />
                    </PieChart>
                </ResponsiveContainer>
                <div id="completion-text">
                    <div>
                        <div id="completion-percentage">
                            {percentageRounded}%
                        </div>
                        <div>
                            <Translate textOnly>candidate.profile.completion</Translate>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
