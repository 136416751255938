import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import { SOFT_SKILL_TEST_PATH } from "../../../_constants/route.constants";
import Test from "./Test/Test";
import InterpretationSelectors from "../../../_selectors/InterpretationSelectors";
import { AuthSelectors } from "../../../../common/_selectors/AuthSelectors";
import SoftSkillsSelectors from "../../../_selectors/SoftSkillsSelectors";
import {
    PROCESSED_STATUS,
    COMPLETED_STATUS,
    UNPROCESSED_STATUS,
    IN_PROGRESS_STATUS,
} from "../../../_constants/testStatus.constants";
import { Results } from "./Results";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import { InProgress } from "./InProgress";
import {
    Loading,
    BackButton,
    HeadTitle,
} from "../../../../common/components";
import { useRenderToHeader } from "../../../../common/hooks/useRenderToHeader";
import { BaseDiagnosticLayout } from "./BaseDiagnosticLayout";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export function BaseDiagnostic(props) {
    const {
        id,
        name,
        interpretationContentEmpty,
        interpretationContentProcessed,
        contentProcessed,
        contentUnprocessed,
        extraContent,
        getStatus,
        getInterpretation,
        isMobile,
    } = props;

    const translateDiagnostic = useTranslate(TRANSLATE_KEY + "." + name);

    const routeMatch = useRouteMatch();

    const { isLoading, interpretation, profileId, status, isFinished } =
        useSelector((state) => mapState(state, name));
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId && name) {
            getStatus(profileId, name);
        }
    }, [dispatch, profileId, name]);

    const timer = useRef(null);
    useEffect(() => {
        if (!profileId) {
            return;
        }

        if (
            status.status === UNPROCESSED_STATUS ||
            status.status === PROCESSED_STATUS
        ) {
            getInterpretation(profileId, name);
        }

        if (status.status === COMPLETED_STATUS && !timer.current) {
            timer.current = setInterval(
                () => dispatch(SoftSkillsActions.getStatus(profileId, name)),
                10000
            );
        }

        return () => {
            clearInterval(timer.current);
            timer.current = null;
        };
    }, [dispatch, status, profileId, name]);

    const parentRef = useRef(null);
    parentRef.current = document.getElementById("soft-skills-test");

    const renderToHeader = useRenderToHeader();

    // if (status && !status.unlocked) {
    //     return null;
    // }

    return (
        <>
            {isMobile
                ? renderToHeader(<BackButton link={SOFT_SKILL_TEST_PATH} />)
                : null}
            {isMobile && parentRef.current
                ? ReactDOM.createPortal(render(), parentRef.current)
                : render()}
        </>
    );

    function render() {
        return (
            <>
                <HeadTitle title={translateDiagnostic("title")} />
                <div>
                    <Switch>
                        <Route exact path={SOFT_SKILL_TEST_PATH + "/" + name}>
                            {(() => {
                                switch (status.status) {
                                    case UNPROCESSED_STATUS:
                                    case PROCESSED_STATUS:
                                        return (
                                            <BaseDiagnosticLayout
                                                name={name}
                                                id={id}
                                                isLoading={isLoading}
                                                isFinished={isFinished}
                                                status={status}
                                                interpretation={interpretation}
                                                contentProcessed={
                                                    contentProcessed
                                                }
                                                contentUnprocessed={
                                                    contentUnprocessed
                                                }
                                                interpretationContentProcessed={
                                                    interpretationContentProcessed
                                                }
                                                interpretationContentEmpty={
                                                    interpretationContentEmpty
                                                }
                                                extraContent={extraContent}
                                            />
                                        );
                                    case IN_PROGRESS_STATUS:
                                        return <InProgress isLocked={!status.unlocked} />;
                                    case COMPLETED_STATUS:
                                        return (
                                            <Results
                                                timestamp={
                                                    status.lastModifiedDate
                                                }
                                            />
                                        );
                                    default:
                                        return <Loading />;
                                }
                            })()}
                        </Route>
                        <Route exact path={routeMatch.path + "/test"}>
                            <Test name={name} />
                        </Route>
                    </Switch>
                </div>
            </>
        );
    }
}

function mapState(state, name) {
    const status = SoftSkillsSelectors.getStatus(state, name);
    return {
        isLoading:
            InterpretationSelectors.getInterpretationLoading(name)(state),
        interpretation: InterpretationSelectors.getInterpretation(name)(state),
        profileId: AuthSelectors.getProfileId(state),
        status,
        isFinished: status.status && status.status === PROCESSED_STATUS,
    };
}
