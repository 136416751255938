import { AutocompleteService } from "../../../candidate/_services";
import IAddress from "../../types/IAddress";
import FormMultiSelect from "../input/MultiSelect/FormMultiSelect";

export interface LocationsEditProps {
    name: string;
    placeholder?: string;
    isLoading?: boolean;
}

export default function LocationsEdit(props: LocationsEditProps) {
    const { name, placeholder, isLoading } = props;

    return (
        <FormMultiSelect<IAddress>
            name={name}
            loadOptions={AutocompleteService.address}
            getOptionLabel={(option) => option?.title || option?.text}
            placeholder={placeholder}
            disabled={isLoading}
        />
    );
}
