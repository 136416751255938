import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { MeterBar } from './MeterBar';
import { BasePress } from './BasePress';

const TRANSLATE_KEY = 'candidate.softSkillsTest';

export function Press() {
    const translate = useTranslate(TRANSLATE_KEY);

    return (
        <BasePress
            id="press"
            name="press"
            icon="memory-storage"
            extraContent={renderPanels}
        />
    );

    function renderPanels(interpretation, isLoading) {
        if (
            isLoading ||
            !interpretation.softSkills ||
            !interpretation.softSkills.speed ||
            !interpretation.softSkills.accuracy
        ) {
            return null;
        }

        const skills = interpretation.softSkills;
        const speedScore = skills.speed[0].value;
        const qualityScore = skills.accuracy[0].value;

        return (
            <>
                <div className="soft-skills-test-layout--panels">
                    <div className="soft-skills-test-layout--panel">
                        <h5>{translate('titles.speed')}</h5>
                        <MeterBar value={speedScore / 100} />
                    </div>
                    <div className="soft-skills-test-layout--panel">
                        <h5>{translate('titles.quality')}</h5>
                        <MeterBar value={qualityScore / 100} />
                    </div>
                </div>
                <div className="soft-skills-test-layout--panel">
                    <h5>{translate('press.titles.profile')}</h5>
                    {renderProfile(interpretation)}
                </div>
            </>
        );
    }
}

function renderProfile(interpretation) {
    if (!interpretation.profiles) {
        return null;
    }

    const { imageUrl, name, description } = interpretation.profiles[0];

    return (
        <div id="soft-skills-test--press__profile">
            <img src={imageUrl} alt="" />
            <div>
                <div id="soft-skills-test--press__profile__name">
                    <strong>{name}</strong>
                </div>
                <div id="soft-skills-test--press__profile__description">
                    {description}
                </div>
            </div>
        </div>
    );
}
