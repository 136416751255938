import InviteFriendsConstants from '../_constants/actions/InviteFriendsConstants';

const InviteFriendsActions = { getInvites, sendInvites, sendInvite };

function getInvites(profileId, testCode) {
    return {
        type: InviteFriendsConstants.GET_INVITES,
        payload: { profileId, testCode },
    };
}

function sendInvites(emails, message, testCode) {
    return {
        type: InviteFriendsConstants.SEND_INVITES,
        payload: { emails, msg: message, testCode },
    };
}

function sendInvite(id) {
    return {
        type: InviteFriendsConstants.SEND_INVITE,
        payload: id,
    };
}

export default InviteFriendsActions;
