import profileSaga from "./profile.saga";
import skillsSaga from "./skills.saga";
import jobRoleSaga from "./jobRole.saga";
import applicationsTracking from "./applicationsTracking.saga";
import dashboard from "./dashboard.saga";
import interpretation from "./interpretation.saga";
import softSkills from "./softSkills.saga";
import careerPaths from "./careerPaths.saga";
import careerSearch from "./careerSearch.saga";
import inviteFriends from "./inviteFriends.saga";
import heroes from "./heroes.saga";

export default [
    ...profileSaga,
    ...skillsSaga,
    ...jobRoleSaga,
    ...applicationsTracking,
    ...dashboard,
    ...interpretation,
    ...softSkills,
    ...careerPaths,
    ...careerSearch,
    ...inviteFriends,
    ...heroes,
];
