import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { ProfilePicture, Flex } from '../../../common/components';

export function CandidateBaseInfo({ profile, pictureSize, isLocked }) {
    const {
        id,
        headerTitle,
        location,
        blurredImageUrl,
        imageUrl,
        firstName,
        lastName,
    } = profile;

    const translate = useTranslate('admin.candidate.locked');

    return (
        <Flex
            id="candidate-overlay--base-info"
            justifyContent="space-between"
            alignItems="center"
            spacing={10}
            className="w100 w66-sm"
        >
            <div className="text-right flex-shrink-0">
                <ProfilePicture
                    imageUrl={isLocked ? blurredImageUrl : imageUrl}
                    size={pictureSize}
                />
            </div>
            <Flex direction="column" className="w100">
                {isLocked ? (
                    <div
                        id="candidate-overlay--base-info--label"
                        className="label"
                    >
                        {translate('status')}
                    </div>
                ) : null}
                <h3>
                    {isLocked
                        ? translate('name', { id })
                        : `${firstName} ${lastName}`}
                </h3>
                <div className="flex font-12 font-book">
                    <div>{headerTitle}</div>
                    <div>{location}</div>
                </div>
            </Flex>
        </Flex>
    );
}
