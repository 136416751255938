import { RequestService } from '.';

export const JobDetailService = {
    getDetail,
    postJobApplication,
    acceptJobInvitation,
};

const PROFILE_PATH = (profileId) =>
    `api/profiles/${profileId}/job-applications`;

function getDetail(id) {
    return RequestService.get(`api/job-posts/${id}`);
}

function postJobApplication({ profileId, jobPostId }) {
    return RequestService.post(PROFILE_PATH(profileId), {
        profileId,
        jobPostId,
    });
}

function acceptJobInvitation({ profileId, jobPostId }) {
    return RequestService.put(PROFILE_PATH(profileId), {
        profileId,
        jobPostId,
        status: 'ACCEPTED',
    });
}
