import {
    Switch,
    Route,
    Link,
    generatePath,
    useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import {
    ADMIN_JOBS_PATH,
    ADMIN_JOB_EDIT_DETAIL_PATH,
    ADMIN_JOB_EDIT_PATH,
} from "../../../common/_constants/route.constants";
import { JobSelectors } from "../../_selectors/JobSelectors";
import { ADMIN_JOBS_PREVIEW_PATH } from "../../_constants/route.constants";
import AdminJobList from "../../components/Jobs/AdminJobList/AdminJobList";

import styles from "./AdminJobListPage.module.scss";
import Button from "../../../common/components/buttons/Button/Button";
import FiltersWrapper from "../../../common/components/filters/FiltersWrapper/FiltersWrapper";
import BaseListLayout from "../../../common/components/layout/BaseListLayout/BaseListLayout";
import AdminJobActions from "../../_actions/AdminJobActions";
import { useEffect, useState } from "react";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import { AuthSelectors } from "../../../common/_selectors";
import { LoadingOverlay } from "../../../common/components";
import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";

export default function AdminJobListPage() {
    const translate = useTranslate("admin.jobs.list");

    const history = useHistory();
    const dispatch = useDispatch();

    const createdJob = AdminJobSelectors.useGetCreate();
    const list = AdminJobSelectors.useGetList();
    const companyId = AuthSelectors.useGetCompanyId();

    useEffect(() => {
        dispatch(AdminJobActions.getJobs(companyId));
    }, [companyId, dispatch]);

    useEffect(() => {
        if (createdJob.success && createdJob.id) {
            dispatch(AdminJobActions.createJobReset());
            history.push(
                generatePath(ADMIN_JOB_EDIT_DETAIL_PATH, { id: createdJob.id })
            );
        }
    }, [createdJob.success, createdJob.id]);

    return (
        <CompanyPageLayout>
            <Switch>
                <Route path={ADMIN_JOBS_PREVIEW_PATH}>
                    {/* <BaseJobDetail backLink={ADMIN_JOBS_PATH} displayOnly /> */}
                </Route>
                <Route path={ADMIN_JOBS_PATH} exact>
                    <BaseListLayout
                        title={translate("heading")}
                        headingProps={{
                            content: (
                                <Button
                                    loading={createdJob.loading}
                                    className={styles.button}
                                    onClick={() =>
                                        dispatch(AdminJobActions.createJob())
                                    }
                                >
                                    {translate("newJob")}
                                </Button>
                            ),
                        }}
                        // filtersProps={{ content: <Filters /> }}
                    >
                        <LoadingOverlay
                            isLoading={list.loading || createdJob.loading}
                        />
                        <AdminJobList jobs={list.data} />
                    </BaseListLayout>
                </Route>
            </Switch>
        </CompanyPageLayout>
    );
}

function Filters() {
    return (
        <>
            <FiltersWrapper.Button label="Job Status"></FiltersWrapper.Button>
            <FiltersWrapper.Button label="Departments"></FiltersWrapper.Button>
            <FiltersWrapper.Button label="Locations"></FiltersWrapper.Button>
        </>
    );
}
