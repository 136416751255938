import {
    KeyboardEvent,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import { cx } from "@emotion/css";

import {
    ARROW_DOWN,
    ARROW_UP,
    ENTER,
    ESCAPE,
    TAB,
} from "../../../_constants/input.constants";
import { InputProps } from "../Input/Input";
import styles from "./Select.module.scss";
import { SVGIcon } from "../../SVGIcon";
import SelectMenu from "../SelectMenu/SelectMenu";

interface BaseProps<T> {
    options?: T[];
}
export interface SelectProps<T> extends BaseProps<T> {
    name?: string;
    placeholder?: string;
    searchable?: boolean;
    inputProps?: InputProps;
    className?: string;
    loadOptions?: (search: string) => Promise<T[]>;
    value?: T;
    onChange?: (value: string) => void;
    onSelect?: (value: T) => void;
    loading?: boolean;
    getOptionLabel?: (option: T) => string;
    disabled?: boolean;
    text?: string;
    preload?: boolean;
    hasError?: boolean;
    createable?: boolean;
    getNewValue?: (text: string) => T;
    onCreate?: (value: T) => void;
    error?: boolean;
}

type State<T> = {
    text: string;
    hovered: number | null;
    options: T[];
    loading: boolean;
    focused: boolean;
    touched: boolean;
};

export default function Select<T>(props: SelectProps<T>) {
    const {
        name,
        options,
        onSelect,
        inputProps = {},
        className,
        loadOptions,
        value,
        getOptionLabel,
        searchable = false,
        placeholder,
        loading = false,
        disabled = false,
        text,
        onChange,
        preload = false,
        hasError,
        createable = false,
        onCreate,
        getNewValue,
        error,
        ...rest
    } = props;

    const [menuVisible, setMenuVisible] = useState(false);
    const [state, setState] = useState<State<T>>({
        text: getLabel(value) || text || "",
        hovered: null,
        options: options || [],
        loading,
        focused: false,
        touched: false,
    });

    const inputRef = useRef<HTMLInputElement>();
    const valueRef = useRef<HTMLDivElement>();

    // update options on change
    useEffect(() => {
        const newText = getLabel(value);
        setState((prev) => ({
            ...prev,
            text:
                newText !== null && newText !== undefined ? newText : prev.text,
            loading,
        }));
    }, [value, loading]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            text,
        }));
    }, [text]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            options: options || [],
        }));
    }, [options]);

    useEffect(() => {
        if ((!searchable || !loadOptions || !state.touched) && !preload) {
            return;
        }

        if (!state.text && !preload) {
            setState((prev) => ({ ...prev, options: [] }));
            return;
        }

        const asyncSearch = async () => {
            setState((prev) => ({ ...prev, loading: true }));
            try {
                const search = state.text?.replace(/[^a-z0-9 ]/gi, "");
                const newOptions = await loadOptions(search || "");

                setState((prev) => ({
                    ...prev,
                    options: newOptions,
                }));
            } catch (ex) {
                throw ex;
            } finally {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                }));
            }
        };

        const timeout = setTimeout(() => {
            asyncSearch();
        }, 200);

        return () => clearTimeout(timeout);
    }, [searchable, loadOptions, state.text, preload, state.touched]);

    const label = getLabel(value);
    return (
        <div className={styles.container} {...rest}>
            <div
                className={cx(
                    styles.select,
                    state.focused && styles.select__focus,
                    searchable && styles.select__searchable,
                    disabled && styles.select__disabled,
                    hasError && styles.select__error,
                    className
                )}
            >
                <input
                    ref={inputRef}
                    {...inputProps}
                    name={name}
                    className={cx(
                        inputProps.className,
                        !searchable && styles.hidden
                    )}
                    disabled={disabled}
                    onChange={(ev) => {
                        if (onChange) {
                            onChange(ev.target.value);
                            setState((prev) => ({
                                ...prev,
                                hovered: 0,
                            }));
                            return;
                        }
                        setState((prev) => ({
                            ...prev,
                            hovered: 0,
                            text: ev.target.value,
                        }));
                    }}
                    value={state.text}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeypress}
                    placeholder={placeholder}
                />
                {!searchable && (
                    <div
                        ref={valueRef}
                        className={cx(
                            styles.value,
                            !label && styles.value__empty
                        )}
                        onClick={handleClick}
                    >
                        {label || placeholder}
                    </div>
                )}
            </div>
            <div className={styles.meta}>
                {state.loading && (
                    <div className={styles.loading}>
                        <div />
                        <div />
                        <div />
                    </div>
                )}
                {!searchable && (
                    <SVGIcon
                        name="chevron-down"
                        className={cx(
                            styles.arrow,
                            disabled && styles.arrow__disabled
                        )}
                    />
                )}
            </div>
            <Menu<T>
                isVisible={menuVisible}
                inputRef={inputRef}
                valueRef={valueRef}
                options={state.options}
                onSelect={(option) => handleSelect(option)}
                hovered={state.hovered}
                onHover={(index) => setState((prev) => setHovered(prev, index))}
                onClose={close}
                getLabel={getLabel}
                value={value}
            />
        </div>
    );

    function handleSelect(option: T) {
        close();
        onSelect && onSelect(option);

        setState((prev) => ({
            ...prev,
            text: getLabel(option),
        }));
    }

    function handleKeySelect() {
        if (state.hovered === null) {
            handleSelect(value);
        } else {
            if (state.text && state.text.trim() !== "") {
                const option = state.options[state.hovered];
                handleSelect(option);
            }
        }
    }

    function handleFocus() {
        setState((prev) => ({
            ...prev,
            focused: true,
            touched: true,
            hovered: 0,
        }));
        setMenuVisible(true);
    }

    function handleBlur() {
        setState((prev) => {
            const newState = {
                ...prev,
                focused: false,
                text: (!createable && getLabel(value)) || prev.text,
                touched: true,
            };

            if (createable) {
                if (getOptionLabel(value) !== newState.text) {
                    const newValue = getNewValue(state.text);
                    onCreate && onCreate(newValue);
                }
            }

            return newState;
        });
    }

    function handleClick() {
        if (disabled) {
            return;
        }

        inputRef.current.focus();
        setState((prev) => ({ ...prev, touched: true }));
    }

    function toggleMenu() {
        setMenuVisible((prev) => !prev);
    }

    function close() {
        inputRef.current?.blur();
        setMenuVisible(false);
        setState((prev) => setHovered(prev, null));
    }

    function handleArrowUp(ev: KeyboardEvent) {
        ev.preventDefault();

        setState((prev) => {
            let newIndex = null;
            if (prev.hovered === null) {
                newIndex = prev.options.length - 1;
            } else {
                newIndex = prev.hovered - 1 < 0 ? null : prev.hovered - 1;
            }

            return { ...prev, hovered: newIndex };
        });
    }

    function handleArrowDown(ev: KeyboardEvent) {
        ev.preventDefault();

        setState((prev) => {
            let newIndex = null;
            if (prev.hovered === null) {
                newIndex = 0;
            } else {
                newIndex =
                    prev.hovered + 1 >= prev.options.length
                        ? null
                        : prev.hovered + 1;
            }

            return { ...prev, hovered: newIndex };
        });
    }

    function setHovered(state: State<T>, index: number | null) {
        return { ...state, hovered: index };
    }

    function handleKeypress(ev: KeyboardEvent) {
        if (ev.repeat) {
            return;
        }

        switch (ev.key) {
            case ENTER:
            case TAB:
                handleKeySelect();
                break;
            case ESCAPE:
                close();
                break;
            case ARROW_UP:
                handleArrowUp(ev);
                break;
            case ARROW_DOWN:
                handleArrowDown(ev);
                break;
            default:
                if (!searchable) {
                    ev.preventDefault();
                    ev.stopPropagation();
                }
        }
    }

    function getLabel(value: T) {
        return getOptionLabel && value !== null && value !== undefined
            ? getOptionLabel(value)
            : "";
    }
}

interface MenuProps<T> extends BaseProps<T> {
    isVisible: boolean;
    onClose: () => void;
    inputRef: MutableRefObject<HTMLInputElement>;
    valueRef: MutableRefObject<HTMLDivElement>;
    hovered: number;
    onHover: (index: number) => void;
    onSelect: (option: T) => void;
    value: T;
    getLabel: (option: T) => string;
}

function Menu<T>(props: MenuProps<T>) {
    const {
        isVisible,
        inputRef,
        valueRef,
        options,
        onSelect,
        hovered,
        onHover,
        onClose,
        getLabel,
        value,
    } = props;
    const menuRef = useRef();

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => document.removeEventListener("click", handleClick);
    }, []);

    return (
        <SelectMenu
            isVisible={isVisible}
            forwardRef={menuRef}
            onMouseLeave={onMouseLeave}
        >
            {options.map((option, index) => (
                <SelectMenu.Item
                    key={index}
                    onClick={onClick(option)}
                    onMouseOver={onMouseOver(index)}
                    hovered={hovered === index}
                    selected={value === option}
                >
                    {getLabel(option)}
                </SelectMenu.Item>
            ))}
        </SelectMenu>
    );

    function onMouseOver(index: number) {
        return () => handleHover(index);
    }

    function onMouseLeave() {
        handleHover(null);
    }

    function handleClick(ev: any) {
        const target = ev.target;

        if (
            target === inputRef.current ||
            target === valueRef.current ||
            target === menuRef.current ||
            target?.parentNode === menuRef.current
        ) {
            return;
        }

        onClose();
    }

    function onClick(option: T) {
        return () => handleSelect(option);
    }

    function handleSelect(option: T) {
        onSelect && onSelect(option);
    }

    function handleHover(index: number) {
        onHover && onHover(index);
    }
}
