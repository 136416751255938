import { combineReducers } from 'redux';

import InviteFriendsConstants from '../_constants/actions/InviteFriendsConstants';
import {
    STATE_LOADING,
    STATE_FAIL,
    STATE_SUCCESS,
} from '../../common/_constants/state.constants';
import {GAUtils} from "../../common/_helpers/GAUtils";

function data(state = [], action) {
    switch (action.type) {
        case InviteFriendsConstants.GET_INVITES:
            return [];
        case InviteFriendsConstants.GET_INVITES_SUCCESS:
            return action.payload;
        case InviteFriendsConstants.SEND_INVITES_SUCCESS:
            GAUtils.event('User', 'FRIEND_INVITATION_SENT');
            if (!Array.isArray(action.payload)) {
                return state;
            }

            return [...state, ...action.payload];
        default:
            return state;
    }
}

function state(state = null, action) {
    switch (action.type) {
        case InviteFriendsConstants.GET_INVITES:
        case InviteFriendsConstants.SEND_INVITES:
        case InviteFriendsConstants.SEND_INVITE:
            return STATE_LOADING;
        case InviteFriendsConstants.GET_INVITES_FAIL:
        case InviteFriendsConstants.SEND_INVITES_FAIL:
        case InviteFriendsConstants.SEND_INVITE_FAIL:
            return STATE_FAIL;
        case InviteFriendsConstants.GET_INVITES_SUCCESS:
        case InviteFriendsConstants.SEND_INVITES_SUCCESS:
        case InviteFriendsConstants.SEND_INVITE_SUCCESS:
            return STATE_SUCCESS;
        default:
            return state;
    }
}

export default combineReducers({ data, state });
