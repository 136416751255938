import { PropsWithChildren } from "react";
import Header from "../../nav/Header/Header";
import styles from "./AuthPageLayout.module.scss";

type AuthPageLayoutProps = PropsWithChildren<{}>;

export default function AuthPageLayout(props: AuthPageLayoutProps) {
    const { children } = props;

    return (
        <>
            <Header />
            <main className={styles.auth}>
                <div className={styles.content}>
                    <div className={styles.card}>{children}</div>
                </div>
            </main>
        </>
    );
}
