import { useEffect } from "react";
import { useTranslate } from "react-translate.ts";
import { useDispatch, useSelector } from "react-redux";

import { AuthSelectors } from "../../../_selectors";
import { OnboardingButtons, OnboardingForm } from "../../../components";
import { OnboardingActions } from "../../../_actions";
import OnboardingSelectors from "../../../_selectors/OnboardingSelectors";
import OnboardingTest from "./components/OnboardingTest";
import SoftSkillsActions from "../../../../candidate/_actions/SoftSkillsActions";
import { OnboardingInterpretation } from "./components/OnboardingInterpretation";
import SoftSkillsSelectors from "../../../../candidate/_selectors/SoftSkillsSelectors";
import { COMPLETED_STATUS } from "../../../../candidate/_constants/testStatus.constants";
import OnboardingLayout from "./OnboardingLayout/OnboardingLayout";

export function OnboardingSoftSkills() {
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { skills } = translate("common.heroOnboarding.steps");

    const { started, complete } = useSelector(mapState);

    const profileId = useSelector(AuthSelectors.getProfileId);

    useEffect(() => {
        if (profileId) {
            dispatch(SoftSkillsActions.getStatus(profileId, "MOP"));
        }
    }, [dispatch, profileId]);

    if (complete) {
        return <OnboardingInterpretation />;
    }

    if (started) {
        return <OnboardingTest />;
    }

    return (
        <OnboardingLayout
            color="blue"
            imageProps={{ src: "/img/onboarding/skills.png" }}
        >
            <OnboardingForm className="skills">
                <h1 className="font-light">{skills?.title}</h1>
                <div className="skills__subtitle font-book">
                    {skills?.subtitle}
                </div>
                <div className="skills__hints">
                    <div className="skills__hints__title">
                        {skills?.hints?.title}
                    </div>
                    <ul className="font-book">
                        {skills?.hints?.steps?.map(renderStep)}
                    </ul>
                </div>
                <div className="skills__time font-book">{skills?.time}</div>
            </OnboardingForm>
            <OnboardingButtons>
                <OnboardingButtons.Back
                    onClick={() => dispatch(OnboardingActions.addStep(-1))}
                />
                <OnboardingButtons.Next
                    onClick={startTest}
                    content="Start test"
                />
            </OnboardingButtons>
        </OnboardingLayout>
    );

    function renderStep(item: any, i: number) {
        return (
            <li key={i}>
                <div className="skills__hints__index font-light">{i + 1}</div>{" "}
                {item}
            </li>
        );
    }

    function startTest() {
        dispatch(OnboardingActions.startTest());
    }
}

function mapState(state: any) {
    const status = SoftSkillsSelectors.getStatus(state, "MOP");
    return {
        started: OnboardingSelectors.hasTestStarted(state),
        complete: status.status === COMPLETED_STATUS,
    };
}
