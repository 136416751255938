import { combineReducers } from "redux";

import {
    SET_JOB_FAVOURITE_SUCCESS,
    UNSET_JOB_FAVOURITE_SUCCESS,
} from "../../_constants";
import {
    ASYNC_STATE_LOADING,
    ASYNC_STATE_SUCCESS,
    ASYNC_STATE_FAIL,
} from "../../../common/_constants/async.constants";
import {
    JOB_DETAIL_ACCEPT,
    JOB_DETAIL_APPLY,
    JOB_DETAIL_APPLY_FAIL,
    JOB_DETAIL_ACCEPT_FAIL,
    GET_JOB_DETAIL_SUCCESS,
    GET_JOB_DETAIL,
    GET_JOB_DETAIL_FAIL,
    JOB_DETAIL_APPLY_SUCCESS,
    JOB_DETAIL_ACCEPT_SUCCESS,
} from "../../../common/_constants/actions/jobDetail.constants";

const defaultState = {
    jobMatch: {},
    company: { id: 0 },
    salaryRange: { currency: {} },
    payRaise: 0,
    type: 0,
    employmentType: {},
    jobBenefits: [],
    location: {},
};

const data = (state = defaultState, action) => {
    switch (action.type) {
        case GET_JOB_DETAIL:
            if (state.jobPostId === action.payload) {
                return state;
            }

            return defaultState;
        case GET_JOB_DETAIL_SUCCESS:
            return action.payload;
        case SET_JOB_FAVOURITE_SUCCESS:
            return setFavourite(state, action.payload, true);
        case UNSET_JOB_FAVOURITE_SUCCESS:
            return setFavourite(state, action.payload, false);
        default:
            return state;
    }
};

export default combineReducers({
    data,
    state,
    applicationStatus,
    applicationLoading,
    applicationState,
});

function setFavourite(state, { jobPostId }, isFavourite) {
    if (state.id === jobPostId) {
        return Object.assign({}, state, { isFavourite });
    }

    return state;
}

function state(state = null, action) {
    switch (action.type) {
        case GET_JOB_DETAIL:
            return ASYNC_STATE_LOADING;
        case GET_JOB_DETAIL_SUCCESS:
            return ASYNC_STATE_SUCCESS;
        case GET_JOB_DETAIL_FAIL:
            return ASYNC_STATE_FAIL;
        default:
            return state;
    }
}

function applicationStatus(state = null, action) {
    switch (action.type) {
        case GET_JOB_DETAIL:
            return "NOT_APPLIED";
        case GET_JOB_DETAIL_SUCCESS:
            return action.payload.jobApplicationStatus;
        case JOB_DETAIL_APPLY_SUCCESS:
            return "APPLIED";
        case JOB_DETAIL_ACCEPT_SUCCESS:
            return "ACCEPTED";
        default:
            return state;
    }
}

function applicationState(state = 0, action) {
    switch (action.type) {
        case GET_JOB_DETAIL:
            return 0;
        case JOB_DETAIL_APPLY:
        case JOB_DETAIL_ACCEPT:
            return 1;
        case JOB_DETAIL_APPLY_SUCCESS:
        case JOB_DETAIL_ACCEPT_SUCCESS:
            return 2;
        default:
            return state;
    }
}

function applicationLoading(state = false, action) {
    switch (action.type) {
        case JOB_DETAIL_ACCEPT:
        case JOB_DETAIL_APPLY:
            return true;
        case JOB_DETAIL_APPLY_SUCCESS:
        case JOB_DETAIL_ACCEPT_SUCCESS:
        case JOB_DETAIL_APPLY_FAIL:
        case JOB_DETAIL_ACCEPT_FAIL:
            return false;
        default:
            return state;
    }
}
