import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Benefits } from "../";
import {
    COMPANY_DETAIL_LOCATION_PATH,
    COMPANY_DETAIL_JOBS_PATH,
    COMPANY_DETAIL_GALLERY_PATH,
    COMPANY_DETAIL_BENEFITS_PATH,
} from "../../../../_constants/route.constants";
import { Gallery } from "../Gallery";
import { Jobs } from "../Jobs";
import { Offices } from "../Offices";
import { Overview } from "../Overview";

export function CompanyProfileRoutes({ company, displayOnly = true }) {
    const { path } = useRouteMatch();
    const { jobs, jobBenefits } = company;

    return (
        <div className="company-profile--column">
            <Switch>
                <Route exact path={`${path}`}>
                    <Overview company={company} />
                </Route>
                <Route path={`${path}${COMPANY_DETAIL_LOCATION_PATH}`}>
                    <Offices company={company} />
                </Route>
                <Route path={`${path}${COMPANY_DETAIL_JOBS_PATH}`}>
                    <Jobs jobs={jobs} displayOnly={displayOnly} />
                </Route>
                <Route path={`${path}${COMPANY_DETAIL_GALLERY_PATH}`}>
                    <Gallery images={company.images} />
                </Route>
                <Route path={`${path}${COMPANY_DETAIL_BENEFITS_PATH}`}>
                    <Benefits benefits={jobBenefits} />
                </Route>
            </Switch>
        </div>
    );
}
