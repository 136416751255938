import { put, takeLatest } from 'redux-saga/effects';

import { profileConstants } from '../_constants';
import { ProfileService } from '../_services';
import { handleAsyncAction } from '../../common/_helpers';
import { ProfileActions } from '../_actions';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { GET_PROFILE_COMPLETENESS } from '../../common/_actions/ProfileActions';
import ProfileInformationConstants from '../_constants/actions/ProfileInformationConstants';
import ProfileInformationService from '../_services/ProfileInformationService';
import ProfileConstants from '../_constants/actions/ProfileConstants';

function* getProfile(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.getProfile, action.payload],
        actions: [
            ProfileConstants.GET_PROFILE_SUCCESS,
            ProfileConstants.GET_PROFILE_FAIL,
        ],
    });
}

function* updateAboutMe(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.updateAboutMe, action.payload],
        actions: [
            ProfileInformationConstants.UPDATE_ABOUT_ME_SUCCESS,
            ProfileInformationConstants.UPDATE_ABOUT_ME_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

// function* saveInterests(action) {
//     yield SagaHelpers.handleRequest({
//         request: [ProfileService.saveInterests, action.payload],
//         actions: [
//             ProfileInformationConstants.SAVE_INTERESTS_SUCCESS,
//             ProfileInformationConstants.SAVE_INTERESTS_FAIL,
//         ],
//         onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
//     });
// }

function* addInterest(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.addInterest, action.payload],
        actions: [
            ProfileInformationConstants.ADD_INTEREST_SUCCESS,
            ProfileInformationConstants.ADD_INTEREST_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* updateInterest(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.updateInterest, action.payload],
        actions: [
            ProfileInformationConstants.UPDATE_INTEREST_SUCCESS,
            ProfileInformationConstants.UPDATE_INTEREST_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* deleteInterest(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.deleteInterest, action.payload],
        actions: [
            () => ({
                type: ProfileInformationConstants.DELETE_INTEREST_SUCCESS,
                payload: action.payload.interestId,
            }),
            ProfileInformationConstants.DELETE_INTEREST_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* addEducation(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.addEducation, action.payload],
        actions: [
            ProfileInformationConstants.ADD_EDUCATION_SUCCESS,
            ProfileInformationConstants.ADD_EDUCATION_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* updateEducation(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.updateEducation, action.payload],
        actions: [
            ProfileInformationConstants.UPDATE_EDUCATION_SUCCESS,
            ProfileInformationConstants.UPDATE_EDUCATION_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* deleteEducation(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.deleteEducation, action.payload],
        actions: [
            () => ({
                type: ProfileInformationConstants.DELETE_EDUCATION_SUCCESS,
                payload: action.payload.educationId,
            }),
            ProfileInformationConstants.DELETE_EDUCATION_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* addExperience(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.addExperience, action.payload],
        actions: [
            ProfileInformationConstants.ADD_EXPERIENCE_SUCCESS,
            ProfileInformationConstants.ADD_EXPERIENCE_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* updateExperience(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.updateExperience, action.payload],
        actions: [
            ProfileInformationConstants.UPDATE_EXPERIENCE_SUCCESS,
            ProfileInformationConstants.UPDATE_EXPERIENCE_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* deleteExperience(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.deleteExperience, action.payload],
        actions: [
            () => ({
                type: ProfileInformationConstants.DELETE_EXPERIENCE_SUCCESS,
                payload: action.payload.experienceId,
            }),
            ProfileInformationConstants.DELETE_EXPERIENCE_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* saveLanguages(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.saveLanguages, action.payload],
        actions: [
            ProfileInformationConstants.SAVE_LANGUAGES_SUCCESS,
            ProfileInformationConstants.SAVE_LANGUAGES_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* saveSkills(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileInformationService.saveSkills, action.payload],
        actions: [
            ProfileInformationConstants.SAVE_SKILLS_SUCCESS,
            ProfileInformationConstants.SAVE_SKILLS_FAIL,
        ],
        onSuccess: () => getProfileCompletenessAction(action.payload.profileId),
    });
}

function* updateEmail(action) {
    yield handleAsyncAction(
        ProfileService.updateEmail,
        action.payload,
        ProfileActions.updateEmailSuccess,
        ProfileActions.updateEmailFail,
        {
            success: 'candidate.profile.contact.notifications.email.success',
            fail: 'candidate.profile.contact.notifications.email.fail',
        }
    );
}

function* verifyEmail(action) {
    yield handleAsyncAction(
        ProfileService.verifyEmail,
        action.payload,
        ProfileActions.verifyEmailSuccess,
        ProfileActions.verifyEmailFail,
        {
            success: 'candidate.profile.contact.notifications.verify.success',
            fail: 'candidate.profile.contact.notifications.verify.fail',
        }
    );
}

function* updatePhone(action) {
    yield handleAsyncAction(
        ProfileService.updatePhone,
        action.payload,
        ProfileActions.updatePhoneSuccess,
        ProfileActions.updatePhoneFail,
        {
            success: 'candidate.profile.contact.notifications.phone.success',
            fail: 'candidate.profile.contact.notifications.phone.fail',
        }
    );
}

function* updateAddress(action) {
    yield handleAsyncAction(
        ProfileService.updateAddress,
        action.payload,
        ProfileActions.updateAddressSuccess,
        ProfileActions.updateAddressFail,
        {
            success: 'candidate.profile.contact.notifications.address.success',
            fail: 'candidate.profile.contact.notifications.address.fail',
        }
    );
}

function* uploadProfilePicture(action) {
    yield SagaHelpers.handleRequest({
        request: [ProfileService.uploadProfilePhoto, action.payload],
        actions: [
            ProfileConstants.UPLOAD_PHOTO_SUCCESS,
            ProfileConstants.UPLOAD_PHOTO_FAIL,
        ],
        messages: {
            success: 'candidate.profile.photo.notifications.upload.success',
            fail: 'candidate.profile.photo.notifications.upload.fail',
        },
    });
}

function* getProfileCompletenessAction(profileId) {
    yield put({
        type: GET_PROFILE_COMPLETENESS,
        payload: profileId,
    });
}

const profileSaga = [
    takeLatest(ProfileConstants.GET_PROFILE, getProfile),

    takeLatest(ProfileInformationConstants.UPDATE_ABOUT_ME, updateAboutMe),
    // takeLatest(profileConstants.SAVE_INTERESTS, saveInterests),
    takeLatest(ProfileInformationConstants.ADD_INTEREST, addInterest),
    takeLatest(ProfileInformationConstants.UPDATE_INTEREST, updateInterest),
    takeLatest(ProfileInformationConstants.DELETE_INTEREST, deleteInterest),

    takeLatest(ProfileInformationConstants.ADD_EDUCATION, addEducation),
    takeLatest(ProfileInformationConstants.UPDATE_EDUCATION, updateEducation),
    takeLatest(ProfileInformationConstants.DELETE_EDUCATION, deleteEducation),

    takeLatest(ProfileInformationConstants.ADD_EXPERIENCE, addExperience),
    takeLatest(ProfileInformationConstants.UPDATE_EXPERIENCE, updateExperience),
    takeLatest(ProfileInformationConstants.DELETE_EXPERIENCE, deleteExperience),

    takeLatest(ProfileInformationConstants.SAVE_LANGUAGES, saveLanguages),
    takeLatest(ProfileInformationConstants.SAVE_SKILLS, saveSkills),

    takeLatest(profileConstants.UPDATE_EMAIL, updateEmail),
    takeLatest(profileConstants.VERIFY_EMAIL, verifyEmail),
    takeLatest(profileConstants.UPDATE_PHONE, updatePhone),
    takeLatest(profileConstants.UPDATE_ADDRESS, updateAddress),

    takeLatest(ProfileConstants.UPLOAD_PHOTO, uploadProfilePicture),
];

export default profileSaga;
