import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CandidateTrackingActions } from '../../../_actions';
import { Grid, LoadingOverlay } from '../../../../common/components';
import { CandidateCard } from './CandidateCard';

export function CandidatesDeclined({ jobPostId, onCardClick }) {
    const { isLoading, cards } = useSelector(mapState);
    const getDeclinedCards = mapActions(useDispatch());

    useEffect(() => {
        getDeclinedCards(jobPostId);
    }, [jobPostId]);

    return (
        <div>
            <LoadingOverlay isLoading={isLoading} />
            <Grid size="repeat(4, 238px)" columnGap={11} rowGap={4}>
                {cards.map((candidate, key) => (
                    <CandidateCard
                        key={key}
                        candidate={candidate}
                        canDrag={false}
                        onClick={() => onCardClick && onCardClick(candidate)}
                    />
                ))}
            </Grid>
        </div>
    );
}

function mapState(state) {
    const { loading, declined } = state.admin.candidates.candidateTracking;
    return { isLoading: loading, cards: declined };
}

function mapActions(dispatch) {
    return (jobPostId) =>
        dispatch(CandidateTrackingActions.getDeclinedCards(jobPostId));
}
