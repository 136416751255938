import React from 'react';
import { Helmet } from 'react-helmet';

const BASE_TITLE = 'kareer.ai';

export function HeadTitle(props) {
    const { title } = props;

    const _title = title ? `${BASE_TITLE} - ${title}` : BASE_TITLE;

    return (
        <Helmet>
            <title>{_title}</title>
        </Helmet>
    );
}
