import React from 'react';

import { DropdownMenu } from './DropdownMenu';

export function BaseNavMenu(props) {
    const { children, isOpen, onClose, arrowRight, isFullscreen, id } = props;

    return (
        <DropdownMenu
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            arrowRight={arrowRight}
            isFullscreen={isFullscreen}
            className="nav-menu"
        >
            {children}
        </DropdownMenu>
    );
}
