import { useFormikContext, Form, useField } from "formik";
import { MonthInput } from "../../input";
import {
    AutocompleteService,
    Company,
    JobRole,
} from "../../../../candidate/_services";
import { useTranslate } from "react-translate.ts";
import FormField from "../../input/FormField";
import FormSelect from "../../input/FormSelect";
import FormTextArea from "../../input/FormTextArea";
import IAddress from "../../../types/IAddress";
import FormCheckbox from "../../input/FormCheckbox";
import Field from "../../input/Field/Field";

const CURRENT_FIELD = "current";
const LOCATION_FIELD = "location";
const DESCRIPTION_FIELD = "description";
const JOBROLE_FIELD = "jobRole";
const COMPANY_FIELD = "company";

export default function ExperienceForm(props: any) {
    const { values } = useFormikContext<any>();
    const translate = useTranslate(
        "candidate.profile.information.experience.form"
    );
    return (
        <Form {...props}>
            <FormField
                name={JOBROLE_FIELD}
                label={translate("jobRole.label")}
                required
            >
                <FormSelect<{ title?: string; name?: string }>
                    name={JOBROLE_FIELD}
                    loadOptions={AutocompleteService.jobRoles}
                    placeholder={translate("jobRole.placeholder")}
                    getOptionLabel={(option) =>
                        option?.title || option?.name || values.title
                    }
                    getNewValue={(text) => ({ name: text })}
                    searchable
                    createable
                />
            </FormField>
            <FormField
                name={COMPANY_FIELD}
                label={translate("company.label")}
                required
            >
                <FormSelect<{ title?: string; name?: string }>
                    name={COMPANY_FIELD}
                    loadOptions={AutocompleteService.companies}
                    placeholder={translate("company.placeholder")}
                    getOptionLabel={(option) => option?.title || option?.name}
                    getNewValue={(text) => ({ title: text })}
                    searchable
                    createable
                />
            </FormField>
            <FormField
                name={LOCATION_FIELD}
                label={translate("location.label")}
                required
            >
                <FormSelect<{ title?: string; text?: string }>
                    name={LOCATION_FIELD}
                    loadOptions={AutocompleteService.cities}
                    placeholder={translate("location.placeholder")}
                    getOptionLabel={(option) => option?.title || option?.text}
                    searchable
                />
            </FormField>
            <div className="control flex flex-wrap">
                <DateInputField name="started" />
                <DateInputField
                    name="ended"
                    isDisabled={values[CURRENT_FIELD]}
                />
            </div>
            <Field>
                <FormCheckbox
                    name={CURRENT_FIELD}
                    label={translate("current")}
                />
            </Field>
            <FormField
                name={DESCRIPTION_FIELD}
                label={translate("description.label")}
                required
            >
                <FormTextArea
                    name={DESCRIPTION_FIELD}
                    placeholder={translate("description.placeholder")}
                />
            </FormField>
        </Form>
    );

    function DateInputField(props: any) {
        const { name, isDisabled } = props;
        const [{ value }, { error, touched }, { setValue, setTouched }] =
            useField(name);
        const hasError = !!error && touched && !isDisabled;
        return (
            <div className="w100 w50-sm">
                <MonthInput
                    label={`candidate.profile.information.experience.${name}`}
                    value={value}
                    onChange={(value: any) => setValue(value)}
                    onBlur={() => setTouched(true)}
                    disabled={isDisabled}
                    wrapperProps={{ hasError, error }}
                />
            </div>
        );
    }
}
