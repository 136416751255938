import { Translate } from 'react-translate.ts';

import { Flex } from '../layout';
import { JobMatch } from '../jobs';

export function TrackingMatch({ children, match }) {
    return (
        <Flex
            justifyContent="space-between"
            alignItems="end"
            className="tracking-card--match"
        >
            {children}
            <JobMatch
                label={<Translate>common.jobs.match.overall</Translate>}
                fit={match}
                size="tiny"
            />
        </Flex>
    );
}
