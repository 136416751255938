import { Link } from "react-router-dom";
import { createClass } from "classname-helper";

import LoadingSpinner from "../loading/LoadingSpinner/LoadingSpinner";
import styles from "./Button.module.scss";

/**
 *
 * @param {{
 *  variant?: 'primary' | 'secondary' | 'black' | 'white';
 *  size?: 'small';
 *  children?: any;
 *  onClick?: any;
 *  isPill?: boolean;
 * }} param0
 */
export function Button({
    children,
    variant = "primary",
    className,
    isRounded,
    isPill,
    isThin,
    isWide,
    isLoading,
    disabled,
    isAnchor,
    isLink,
    type = "button",
    size,
    ...rest
}) {
    const props = { ...rest, type, className: createClassName(), disabled };

    if (isAnchor) {
        return (
            <a {...props} href={props.to}>
                {children}
            </a>
        );
    }

    if (isLink) {
        return <Link {...props}>{children}</Link>;
    }

    return (
        <button {...props}>
            <LoadingSpinner className={styles.loading} isVisible={isLoading} />
            {children}
        </button>
    );

    function createClassName() {
        return createClass({
            button: true,
            [variant]: !!variant,
            "is-rounded": isRounded,
            "is-pill": isPill,
            "is-thin": isThin,
            w100: isWide,
            "is-loading": isLoading,
            "is-disabled": disabled,
            [className]: !!className,
            [size]: size,
        });
    }
}
