import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    BasePageLayout,
    InterpretationShareButtons,
} from '../../components';
import { Loading } from "../../components";
import { useRenderToHeader } from '../../hooks/useRenderToHeader';
import { PublicActions } from '../../_actions';
import { PublicInterpretationSelectors } from '../../_selectors';
import InterpretationPersonalities from "../../components/interpretation/InterpretationPersonalities/InterpretationPersonalities";

export function PublicInterpretation() {
    const { uuid, test } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        if (test) {
            dispatch(PublicActions.getInterpretation(test, uuid));
        }
    }, [dispatch, uuid, test]);

    const { isLoading, interpretation } = useSelector(mapState);

    // const translate = useTranslate('candidate.interpretation');
    // const translateMopHeadings = (key) => translate('mop.headings.' + key);

    const renderToHeader = useRenderToHeader();

    if (isLoading) {
        return <Loading />;
    }

    if (!interpretation) {
        return null;
    }

    return (
        <BasePageLayout id="interpretation" withShareButton={false}>
            {renderToHeader(<InterpretationShareButtons />)}
            <InterpretationPersonalities
                profiles={interpretation.profiles}
                extraContent={<InterpretationShareButtons />}
                isLoading={false}
            />
        </BasePageLayout>
    );
}

function mapState(state) {
    return {
        isLoading: PublicInterpretationSelectors.isLoading(state),
        interpretation: PublicInterpretationSelectors.getInterpretation(state),
    };
}
