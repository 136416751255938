import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const state = (state: MainState) => state.common.hero;

const getList = createSelector(state, (state) => state.list);
const getProfile = createSelector(state, (state) => state.profile);

const HeroSelectors = {
    useList,
    useProfile,
};

function useList() {
    return useSelector(getList);
}

function useProfile() {
    return useSelector(getProfile);
}

export default HeroSelectors;
