import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { Button } from '../buttons';

export function Confirm({
    label,
    title,
    onCancel,
    onConfirm,
    confirmText,
    cancelText
}) {
    const translate = useTranslate();
    return (
        <div className="confirm-dialog">
            <div className="confirm-dialog--title">
                <strong>{translate(title || '')}</strong>
            </div>
            <div className="confirm-dialog--label">
                {translate(label || '')}
            </div>
            <div>
                <Button isPill isThin onClick={onConfirm}>
                    {translate(confirmText || 'common.actions.apply')}
                </Button>
                <Button variant="secondary" isPill isThin onClick={onCancel}>
                    {translate(cancelText || 'common.actions.cancel')}
                </Button>
            </div>
        </div>
    );
}
