import { takeLatest, select, put } from 'redux-saga/effects';

import {
    GET_CANDIDATE_CARDS,
    GET_CANDIDATE_PROFILE,
    GET_CANDIDATE_PROFILE_MATCH,
    INVITE_CANDIDATE,
    INVITE_CANDIDATE_SUCCESS,
    INVITE_CANDIDATE_FAIL,
    SET_FAVOURTIE_CANDIDATE,
    UNSET_FAVOURTIE_CANDIDATE,
    SET_FAVOURTIE_CANDIDATE_SUCCESS,
    SET_FAVOURTIE_CANDIDATE_FAIL,
    UNSET_FAVOURTIE_CANDIDATE_SUCCESS,
    UNSET_FAVOURTIE_CANDIDATE_FAIL,
    GET_CANDIDATE_CARDS_SAVED,
    GET_CANDIDATE_CARDS_SAVED_SUCCESS,
    GET_CANDIDATE_CARDS_SAVED_FAIL,
} from '../_constants/actions/candidates.constants';

import { handleAsyncAction } from '../../common/_helpers';
import { CandidateService } from '../_services/CandidateService';
import { CandidateActions } from '../_actions/CandidateActions';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { APPLY_CANDIDATE_SEARCH_FILTERS } from '../_actions';
import { RESET_CANDIDATE_SEARCH_FILTER } from '../_constants/actions/candidateSearch.constants';
import { CandidateSearchSelectors } from '../_selectors/CandidateSearchSelectors';
import { CandidateSearchActions } from '../_actions/CandidateSearchActions';

function* getFilters() {
    const filters = yield select(CandidateSearchSelectors.getFilters);
    const jobPostId = filters.jobPost && filters.jobPost.id;

    return yield { jobPostId, filters };
}

function* getCards() {
    const { jobPostId, filters } = yield getFilters();

    const pagination = yield select(
        CandidateSearchSelectors.getCardsPagination
    );

    yield handleAsyncAction(
        CandidateService.getCards,
        { jobPostId, filters, page: pagination.page },
        CandidateActions.getCardsSuccess,
        CandidateActions.getCardsFail
    );
}

function* getProfile(action) {
    yield handleAsyncAction(
        CandidateService.getProfile,
        action.payload,
        CandidateActions.getProfileSuccess,
        CandidateActions.getProfileFail
    );
}

function* getProfileMatch(action) {
    yield handleAsyncAction(
        CandidateService.getProfileMatch,
        action.payload,
        CandidateActions.getProfileMatchSuccess,
        CandidateActions.getProfileMatchFail
    );
}

function* inviteCandidate(action) {
    yield SagaHelpers.handleRequest({
        request: [CandidateService.inviteCandidate, action.payload],
        actions: [
            (payload) => ({ type: INVITE_CANDIDATE_SUCCESS, payload }),
            (error) => ({ type: INVITE_CANDIDATE_FAIL, error }),
        ],
        messages: {
            fail: 'Failed to invite candidate',
        },
    });
}

function* getFavourites() {
    const { jobPostId, filters } = yield getFilters();

    const pagination = yield select(
        CandidateSearchSelectors.getSavedPagination
    );

    yield SagaHelpers.handleRequest({
        request: [
            CandidateService.getFavourites,
            { jobPostId, filters, page: pagination.page },
        ],
        actions: [
            (payload) => ({
                type: GET_CANDIDATE_CARDS_SAVED_SUCCESS,
                payload,
            }),
            (error) => ({ type: GET_CANDIDATE_CARDS_SAVED_FAIL, error }),
        ],
        messages: {
            fail: 'Failed to save candidate',
        },
    });
}

function* setFavourite(action) {
    yield SagaHelpers.handleRequest({
        request: [CandidateService.setFavourite, action.payload],
        actions: [
            () => ({
                type: SET_FAVOURTIE_CANDIDATE_SUCCESS,
                payload: action.payload,
            }),
            (error) => ({ type: SET_FAVOURTIE_CANDIDATE_FAIL, error }),
        ],
        messages: {
            fail: 'Failed to save candidate',
        },
    });
}

function* unsetFavourite(action) {
    yield SagaHelpers.handleRequest({
        request: [CandidateService.unsetFavourite, action.payload],
        actions: [
            () => ({
                type: UNSET_FAVOURTIE_CANDIDATE_SUCCESS,
                payload: action.payload,
            }),
            (error) => ({ type: UNSET_FAVOURTIE_CANDIDATE_FAIL, error }),
        ],
        messages: {
            fail: 'Failed to remove candidate',
        },
    });
}

function* resetFilters() {
    yield put(CandidateActions.getCards());
    yield put(CandidateActions.getFavourites());
}

export default [
    takeLatest(GET_CANDIDATE_CARDS, getCards),
    takeLatest(GET_CANDIDATE_PROFILE, getProfile),
    takeLatest(GET_CANDIDATE_PROFILE_MATCH, getProfileMatch),
    takeLatest(INVITE_CANDIDATE, inviteCandidate),
    takeLatest(SET_FAVOURTIE_CANDIDATE, setFavourite),
    takeLatest(UNSET_FAVOURTIE_CANDIDATE, unsetFavourite),
    takeLatest(GET_CANDIDATE_CARDS_SAVED, getFavourites),
    takeLatest(RESET_CANDIDATE_SEARCH_FILTER, resetFilters),
];
