import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ProfileSelectors from "../../../../_selectors/ProfileSelectors";
import { AvatarEdit } from "./AvatarEdit";
import ProfileActions from "../../../../_actions/ProfileActions";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import { PROFILE_PATH_INFO } from "../../../../_constants/route.constants";

export function ProfilePicEdit() {
    const { imageFileName, originalImageUrl, isUpdating, profileId } =
        useSelector(mapState);

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <AvatarEdit
            imageFileName={imageFileName}
            originalImageUrl={originalImageUrl}
            isUpdating={isUpdating}
            onSave={(convertedImage, originalImage, imageFileName) =>
                dispatch(
                    ProfileActions.uploadProfilePhoto(
                        convertedImage,
                        originalImage,
                        imageFileName,
                        profileId
                    )
                )
            }
            onClose={() => history.push(PROFILE_PATH_INFO)}
        />
    );
}

function mapState(state) {
    return {
        ...ProfileSelectors.getPhoto(state),
        isUpdating: ProfileSelectors.isUpdating(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
