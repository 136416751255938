import { RequestService } from './RequestService';
import { LOGIN_PATH } from '../_constants/route.constants';
import { UserService } from './UserService';
import { NotificationService } from './NotificationService';

export const AuthService = {
    login,
    logout,
    verifyUser,
    signup,
    unauthorizeUser,
    signupWithoutToken,
};

function login(payload) {
    return RequestService.post('auth/signin', payload);
}

function logout() {
    unauthorizeUser();
    NotificationService.clearLocalNotifications();
}

function unauthorizeUser() {
    UserService.clearUserData();
    window.location.replace(LOGIN_PATH);
}

function verifyUser(token) {
    return RequestService.post(`users/verify`, { token: token.trim() });
}

function signup(values) {
    return RequestService.post('auth/signup', values);
}

function signupWithoutToken(values) {
    return RequestService.post('auth/signup-waiting-list', values);
}
