import { OrderedMap } from 'immutable';

import {
    GET_APPLICATIONS_SUCCESS,
    GET_APPLICATIONS_FAIL,
    APPLICATIONS_ACCEPT_SUCCESS,
    APPLICATIONS_REJECT_SUCCESS,
} from '../../../_constants/actions/applicationsTracking.constants';
import {
    TRACKING_ACCEPTED,
    TRACKING_SHORTLISTED,
    TRACKING_APPLIED,
    TRACKING_INVITED,
} from '../../../../admin/_constants/actions/tracking.constants';

const initialState = {
    [TRACKING_ACCEPTED]: OrderedMap(),
    [TRACKING_SHORTLISTED]: OrderedMap(),
    [TRACKING_APPLIED]: OrderedMap(),
    [TRACKING_INVITED]: OrderedMap(),
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_APPLICATIONS_SUCCESS:
            return getCards(initialState, payload);
        case GET_APPLICATIONS_FAIL:
            return initialState;
        case APPLICATIONS_ACCEPT_SUCCESS:
            return accept(state, payload);
        case APPLICATIONS_REJECT_SUCCESS:
            return reject(state, payload);
        default:
            return state;
    }
}

function getCards(state, cards) {
    Object.keys(cards).forEach((key) => {
        cards[key] = OrderedMap(cards[key].map((card) => [card.id, card]));
    });

    return { ...state, ...cards };
}

function accept(state, { id, oldStatus, newStatus }) {
    const oldCards = state[oldStatus];
    const cards = state[newStatus];

    const card = oldCards.get(id);
    const newCard = Object.assign({}, card, {
        status: newStatus,
    });

    return {
        ...state,
        [newStatus]: cards.set(id, newCard),
        [oldStatus]: oldCards.remove(id),
    };
}

function reject(state, { id, oldStatus }) {
    const oldCards = state[oldStatus];

    return {
        ...state,
        [oldStatus]: oldCards.remove(id),
    };
}
