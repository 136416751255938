import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { KanbanColumn, KanbanCard } from '../../index';
import { CandidateCard } from './CandidateCard';
import { TrackingColumn } from '../../../../common/components';

export function CandidateColumn({
    label,
    type,
    canDrag = false,
    canDrop = false,
    onCardDragEnd,
    onCardDragStart,
    jobPostId,
    onCardClick,
    cardSelector,
    dragState,
    setDragState,
    accept,
    dragAccept,
    isHighlighted,
    afterDrop,
    droppedCandidateId,
    ...rest
}) {
    const timer = useRef(null);
    const cards = useSelector((state) => mapState(state, type, cardSelector));

    const count = cards && cards.length;

    const candidateCards = (
        <TrackingColumn label={label} count={count}>
            {renderCards(cards, afterDrop, droppedCandidateId)}
        </TrackingColumn>
    );

    if (canDrop) {
        return (
            <KanbanColumn
                type={type}
                onDrop={onDrop}
                accept={accept}
                onHover={onColumnHover}
                isHighlighted={isHighlighted}
                {...rest}
            >
                {candidateCards}
            </KanbanColumn>
        );
    }

    return candidateCards;

    function renderPlaceholder(id, index, accepts) {
        if (!canDrop) {
            return null;
        }

        return (
            <KanbanCard
                key={`${id}-dragged`}
                accept={accepts}
                style={{
                    display:
                        dragState &&
                        dragState.index === index &&
                        dragState.type === type
                            ? 'block'
                            : 'none',
                }}
            >
                <div className="tracking-card dragged"></div>
            </KanbanCard>
        );
    }

    function renderCards(cards, afterDrop, currentCandidateId) {
        if (!cards || count === 0) {
            return renderPlaceholder(0, 0, '');
        }

        return cards.map((candidate, index) => {
            const candidateCard = (
                <CandidateCard
                    isHighlighted={
                        afterDrop && currentCandidateId === candidate.id
                    }
                    id={`candidate-card-${candidate.id}`}
                    key={candidate.id}
                    index={index}
                    candidate={candidate}
                    canDrag={canDrag}
                    payload={{ ...candidate, index, type }}
                    type={type}
                    onDrop={handleCardDrop}
                    onDragStart={onCardDragStart}
                    onClick={() => onCardClick && onCardClick(candidate)}
                    hover={onCardHover}
                    accept={dragAccept || ''}
                />
            );

            if (!canDrop) {
                return candidateCard;
            }

            return (
                <>
                    {candidateCard}
                    {renderPlaceholder(candidate.id, index, '')}
                </>
            );
        });
    }

    function onDrop() {
        setDragState(null);
    }

    function handleCardDrop(item, result) {
        onDrop();
        onCardDragEnd && onCardDragEnd(item, result);
    }

    function onColumnHover() {
        if (!dragState || dragState.type !== type) {
            setDragState({ index: count > 0 ? count - 1 : 0, type });
        }
    }

    function onCardHover(item, monitor, index, element) {
        // const dragIndex = item.payload.index;
        const hoverIndex = index;

        /*const hoverBoundingRect = element.getBoundingClientRect();

        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (drag && drag.index === hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        if (drag && drag.index === hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }*/

        clearTimeout(timer.current);

        if (
            dragState &&
            dragState.index === hoverIndex &&
            dragState.type === type
        ) {
            return;
        }

        timer.current = setTimeout(
            () => void setDragState({ index: hoverIndex, type }),
            1
        );
    }
}

function mapState(state, filter, cardSelector) {
    const getCards = cardSelector(filter);
    return getCards(state);
}
