import React from 'react';

import { Panel } from './Panel';
import { Locations } from './Locations';
import GoogleMap from '../../maps/GoogleMap';

export function Offices({ heading, company }) {
    return (
        <>
            {company.locations ? (
                <Panel heading={heading}>
                    <Locations locations={company.locations} />
                </Panel>
            ) : null}

            {company.locations && company.locations.length > 0 ? (
                <div className="company-profile--map">
                    <GoogleMap
                        locations={company.locations}
                        zoom={6}
                        style={{ minHeight: 352, height: 352 }}
                    />
                </div>
            ) : null}
        </>
    );
}
