import { useField } from "formik";
import { useTranslate } from "react-translate.ts";

import Field, { FieldProps } from "./Field/Field";

export interface FormFieldProps extends FieldProps {
    name: string;
    hideError?: boolean;
}

export default function FormField(props: FormFieldProps) {
    const { children, name, label, hideError = false } = props;
    const translate = useTranslate("common.misc.validation");
    const [, meta] = useField(name);
    const { error, touched } = meta;
    return (
        <Field
            error={
                !hideError &&
                error &&
                touched &&
                translate(error, { field: label })
            }
            label={label}
            {...props}
        >
            {children}
        </Field>
    );
}
