import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import HeroOnboardingActions from "../../_actions/HeroOnboarding/HeroOnboardingActions";
import { AuthSelectors } from "../../_selectors";
import HeroOnboardingSelectors from "../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";

export default function useHeroSoftSkillsTest() {
    const test = useSelector(HeroOnboardingSelectors.Skills.getTest);
    const heroProfileId = useSelector(AuthSelectors.getHeroProfileId);
    const locale = useSelector(LanguageSelectors.getLocale);
    const dispatch = useDispatch();

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroOnboardingActions.Skills.getTest(heroProfileId));
        }
    }, [dispatch, heroProfileId, locale]);

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroOnboardingActions.Skills.getStatus(heroProfileId));
        }
    }, [dispatch, heroProfileId, locale]);

    return { test, locale, heroProfileId, completeTest };

    function completeTest(payload: { questionnaireId: number }) {
        dispatch(
            HeroOnboardingActions.Skills.completeTest(
                heroProfileId,
                payload.questionnaireId
            )
        );
    }
}
