import React from 'react';
import { Room } from '@material-ui/icons';

import { Flex } from '../../../../common/components';
import { Grid } from '../../layout';

export function Locations({ locations }) {
    return (
        <Grid gap={10} min="250px" max="1fr">
            {locations.map((location, key) => (
                <Location key={key} location={location} />
            ))}
        </Grid>
    );
}

function Location({ location }) {
    return (
        <Grid columnGap={14} size="auto 1fr">
            <Room className="primary" style={{ fontSize: 25 }} />
            <Flex direction="column">
                <strong className="text-blue">{location.city}</strong>
                <p className="font-13 text-secondary padding-y-10">
                    {location.text}
                </p>
            </Flex>
        </Grid>
    );
}
