import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";

import Positions from "./HeroOnboardingPositionActions";
import Skills from "./HeroOnboardingSkillsActions";
import Personal from "./HeroOnboardingPersonalActions";

const HeroOnboardingActions = {
    SignUp: {
        saveUser: (username, password) => ({
            type: HeroOnboardingConstants.SAVE_USER,
            payload: { username, password },
        }),
    },
    Personal,
    Positions,
    Skills,
    Advice: {
        saveData: (payload) => ({
            type: HeroOnboardingConstants.SAVE_ADVICE,
            payload,
        }),
        getQuestions: () => ({
            type: HeroOnboardingConstants.GET_QUESTIONS,
        }),
    },
    Video: {
        upload: (formData) => ({
            type: HeroOnboardingConstants.UPLOAD_VIDEO,
            payload: formData,
        }),
        uploadRecorded: (formData) => ({
            type: HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO,
            payload: formData,
        }),
        checkInfo: () => ({ type: HeroOnboardingConstants.VIDEO_INFO_SEEN }),
        skip: () => ({ type: HeroOnboardingConstants.VIDEO_SKIP }),
    },
    addStep: (ammount) => ({
        type: HeroOnboardingConstants.ADD_STEP,
        payload: ammount,
    }),
    changeStep: (value) => ({
        type: HeroOnboardingConstants.CHANGE_STEP,
        payload: value,
    }),
};

export default HeroOnboardingActions;
