import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { UserProfileSelectors } from "../../../../../common/_selectors";
import { DiagramWrapper } from "./components/DiagramWrapper";
import { CareerPathsActions } from "../../../../_actions";
import { TodoList } from "./components/TodoList";
import { CareerPathsSelectors } from "../../../../_selectors/CareerPathsSelectors";

export function CareerPathsMap() {
    const { profileType, points, categoryId, subcategoryId } =
        useSelector(mapState);

    const dispatch = useDispatch();

    const location = useLocation();

    useEffect(() => {
        if (!!points.get(0) && !!points.get(1)) {
            dispatch(
                CareerPathsActions.getMapPaths(
                    points.get(0).id,
                    points.get(1).id,
                    categoryId,
                    subcategoryId
                )
            );
        }
    }, [dispatch, points, subcategoryId]);

    useEffect(() => {
        if (!!location.state && location.state.jobRoleId) {
            dispatch(
                CareerPathsActions.getMap({
                    jobRoleId: location.state.jobRoleId,
                    categoryId,
                    subcategoryId,
                })
            );
            return;
        }

        if (!profileType) {
            return;
        }

        dispatch(
            CareerPathsActions.getDefaultMap(
                profileType,
                categoryId,
                subcategoryId
            )
        );
    }, [dispatch, profileType, location.state, subcategoryId]);

    useEffect(() => {
        if (profileType && profileType.jobRoleId && !points.get(0)) {
            dispatch(
                CareerPathsActions.setPoint(0, {
                    id: profileType.jobRoleId,
                    title: profileType.jobRoleTitle,
                })
            );
        }
    }, [profileType, dispatch]);

    // useEffect(() => {
    //     const mainElement = document.getElementsByTagName("main")[0];
    //     const header = document.getElementsByTagName("header")[0];
    //
    //     mainElement.className = "full-size";
    //     document.body.className = "disable-scroll";
    //     header.className = "transparent";
    //
    //     return () => {
    //         mainElement.className = null;
    //         document.body.className = null;
    //         header.className = null;
    //     };
    // }, []);

    const [hoveredPathIndex, setHoveredPathIndex] = useState(null);

    return (
        <div id="career-paths">
            {/* <Nav
                onPathHover={(index) => {
                    if (hoveredPathIndex !== index) {
                        setHoveredPathIndex(index);
                    }
                }}
                onPathOut={() => setHoveredPathIndex(null)}
            /> */}
            {/* <NodeDetail
                onClose={() => {
                    console.log("CLOSE");
                    dispatch(CareerPathsActions.setSelectedNode(null));
                }}
            /> */}
            {/* <Options /> */}
            {/* <Controls onZoom={zoomMap} /> */}
            <TodoList />
            <DiagramWrapper
                matchScoreEnabled={profileType.matchScoreEnabled}
                hoveredPathIndex={hoveredPathIndex}
                selectedCategoryId={categoryId}
                selectedSubcategoryId={subcategoryId}
            />
        </div>
    );
}

function mapState(state) {
    return {
        profileType: UserProfileSelectors.getType(state),
        points: CareerPathsSelectors.routing.getPoints(state),
        categoryId: CareerPathsSelectors.map.getCategoryId(state),
        subcategoryId: CareerPathsSelectors.map.getSubcategoryId(state),
    };
}
