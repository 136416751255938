import React from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import HeroProfileSelectors from "../../../_selectors/HeroProfileSelectors";
import { Matches } from "../components/Matches";
import { VideoPlayer } from "../../../../common/components/misc/VideoPlayer";

export function Match() {
    const { heroProfile } = useSelector(mapState);
    const translate = useTranslate("candidate.heroes.profile.match");
    const { videoUrl, videoProvider, matchScore, firstName } = heroProfile;
    return (
        <div id="hero-profile__match" className="hero-card">
            {matchScore && matchScore.sectionScores ? (
                <Matches
                    match={matchScore.sectionScores}
                    title={translate("skills")}
                />
            ) : null}
            {videoUrl && (
                <>
                    <h5>{translate("video", { name: firstName })}</h5>
                    <VideoPlayer
                        videoProvider={videoProvider}
                        videoUrl={videoUrl}
                    />
                </>
            )}
        </div>
    );
}

function mapState(state) {
    return {
        // match: HeroProfileSelectors.getMatch(state),
        heroProfile: HeroProfileSelectors.getProfile(state),
    };
}
