import { FieldHookConfig, useField } from "formik";
import Select, { SelectProps } from "./Select/Select";
import styles from "./Input/Input.module.scss";

interface FormSelectProps<Option = any> extends SelectProps<Option> {
    fieldConfig?: FieldHookConfig<Option>;
}

export default function FormSelect<Option extends any>(
    props: FormSelectProps<Option>
) {
    const { name, fieldConfig = {}, ...rest } = props;
    const [field, meta, helper] = useField<Option>({ ...fieldConfig, name });

    const { error, touched } = meta;
    const hasError = touched && !!error;
    const { value } = field;
    const { setValue } = helper;

    return (
        <div className={styles.wrapper}>
            <Select<Option>
                value={value}
                name={field.name}
                onSelect={setValue}
                onCreate={setValue}
                hasError={hasError}
                {...rest}
            />
            {/* {hasError && <div className={styles.error}>{error}</div>} */}
        </div>
    );
}
