import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-translate.ts";
import { cx } from "@emotion/css";

import { NotificationActions } from "../../../_actions";
import styles from "./Notification.module.scss";
import useTimer from "../../../hooks/useTimer";
import INotification from "../../../types/INotification";

const NOTIFICATION_TIMEOUT = 3500;

type NotificationProps = {
    notification: INotification;
};

export default function Notification(props: NotificationProps) {
    const { notification } = props;
    const dispatch = useDispatch();
    const { type, message, timestamp } = notification;
    const { time, start, stop } = useTimer(3500, -1, 250);

    useEffect(() => {
        start();

        return () => {
            stop();
        };
    }, []);

    const pop = useCallback(
        () => dispatch(NotificationActions.pop(timestamp)),
        [dispatch, timestamp]
    );
    useEffect(() => {
        if (time <= 0) {
            pop();
        }
    }, [time, pop]);

    return (
        <div className={cx(styles.notification, styles[type])} onClick={pop}>
            <strong className={styles.message}>
                {message && (
                    <Translate textOnly>{message.toString()}</Translate>
                )}
            </strong>
            <div className={styles.timer}>
                <div
                    className={styles.timer__bar}
                    style={{
                        width: `${
                            time > 0 ? (time / NOTIFICATION_TIMEOUT) * 100 : 0
                        }%`,
                    }}
                />
            </div>
        </div>
    );
}
