import React from "react";

import Field, { FieldProps } from "../Field/Field";
import styles from "./RangeField.module.scss";

interface RangeFieldProps extends FieldProps {
    from: React.ReactElement;
    to: React.ReactElement;
}

export default function RangeField(props: RangeFieldProps) {
    const { from, to, ...rest } = props;

    return (
        <Field {...rest}>
            {from}
            <Divider />
            {to}
        </Field>
    );
}

function Divider() {
    return <div className={styles.divider} />;
}

RangeField.Divider = Divider;
