import { createSelector } from 'reselect';
import {
    STATE_LOADING,
    STATE_FAIL,
} from '../../common/_constants/state.constants';
import { ArrayHelper } from '../../common/_helpers/ArrayHelper';

const invites = (state) => state.candidate.invites;

const getInvites = createSelector(invites, (invites) => invites.data);

const hasInvites = createSelector(
    getInvites,
    (invites) => !ArrayHelper.isEmpty(invites)
);

const getState = createSelector(invites, (invites) => invites.state);

const isLoading = createSelector(getState, (state) => state === STATE_LOADING);

const hasFailed = createSelector(getState, (state) => state === STATE_FAIL);

const InviteFriendsSelectors = {
    getInvites,
    getState,
    isLoading,
    hasFailed,
    hasInvites,
};

export default InviteFriendsSelectors;
