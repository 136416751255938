import { AllHTMLAttributes, useState } from "react";
import { Translate } from "react-translate.ts";

import Button from "../../buttons/Button/Button";
import AutoComplete from "../AutoComplete/AutoComplete";
import styles from "./Search.module.scss";

export interface SearchProps<T> extends AllHTMLAttributes<HTMLInputElement> {
    onSearch: (search: string) => void;
    loadOptions?: (search: string) => Promise<T[]>;
    options?: T[];
    getOptionLabel?: (option: T) => string;
    menuClassName?: string;
}

export default function Search<T>(props: SearchProps<T>) {
    const { onSearch, loadOptions, options, getOptionLabel, menuClassName, ...rest } =
        props;

    const [value, setValue] = useState("");

    return (
        <div className={styles.wrapper}>
            <AutoComplete<T>
                className={styles.autocomplete}
                inputProps={{ className: styles.input, ...rest }}
                loadOptions={loadOptions}
                options={options}
                onSelect={onSearch}
                value={value}
                onChange={setValue}
                getOptionLabel={getOptionLabel}
                menuClassName={menuClassName}
            />
            <Button corners="round" inverted onClick={handleSearch}>
                <Translate textOnly>common.actions.search</Translate>
            </Button>
        </div>
    );

    function handleSearch() {
        onSearch(value);
    }
}
