import {
    GET_TRACKING_CARDS,
    CHANGE_TRACKING_STATE,
    REJECT_CANDIDATE,
    UNLOCK_CANDIDATE,
    GET_ARCHIVED_CARDS,
    GET_DECLINED_CARDS,
} from '../_constants/actions/candidateTracking.constants';

export const CandidateTrackingActions = {
    getTrackingCards,
    getArchivedCards,
    getDeclinedCards,
    changeState,
    rejectCandidate,
    unlockCandidate,
};

/**
 * @param {number} jobId
 * @returns {{payload: number, type: string}}
 */
function getTrackingCards(jobId) {
    return { type: GET_TRACKING_CARDS, payload: jobId };
}

/**
 * @param {number} jobId
 * @returns {{payload: number, type: string}}
 */
function getArchivedCards(jobId) {
    return { type: GET_ARCHIVED_CARDS, payload: jobId };
}

/**
 * @param {number} jobId
 * @returns {{payload: number, type: string}}
 */
function getDeclinedCards(jobId) {
    return { type: GET_DECLINED_CARDS, payload: jobId };
}

/**
 * @param {{ candidateId: number, oldStatus: string, newStatus: string, jobPostId: number, profileId: string }} payload
 * @returns {{payload: { candidateId: number, oldStatus: string, newStatus: string, jobPostId: number, profileId: string }, type: string}}
 */
function changeState({
    candidateId,
    oldStatus,
    newStatus,
    jobPostId,
    profileId,
}) {
    return {
        type: CHANGE_TRACKING_STATE,
        payload: { candidateId, oldStatus, newStatus, jobPostId, profileId },
    };
}

/**
 * @param {{candidateId: number, status: string, jobPostId: number, profileId: number}} payload
 * @returns {{payload: number, type: string}}
 */
function rejectCandidate(payload) {
    return {
        type: REJECT_CANDIDATE,
        payload,
    };
}

/**
 * @param {{ candidateId: number, oldStatus: string, jobPostId: number, profileId: string }} payload
 * @returns {{payload: { candidateId: number, oldStatus: string, jobPostId: number, profileId: string }, type: string}}
 */
function unlockCandidate(payload) {
    return { type: UNLOCK_CANDIDATE, payload };
}
