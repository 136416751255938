import cs from "./translations/cs.json";
import en from "./translations/en.json";
import sk from "./translations/sk.json";
import pl from "./translations/pl.json";

export default {
    cs,
    en,
    sk,
    pl
};
