import { PropsWithChildren } from "react";

import Label from "../Label/Label";
import styles from "./Field.module.scss";

export type FieldProps = PropsWithChildren<{
    label?: string | React.ReactElement;
    required?: boolean;
    error?: string;
}>;

export default function Field(props: FieldProps) {
    const { label, error, children, required } = props;

    return (
        <div className={styles.field}>
            {label ? <Label label={label} required={required} /> : null}
            <div className={styles.content}>{children}</div>
            {error && <div className={styles.error}>{error}</div>}
        </div>
    );
}

function Divider() {
    return <div className={styles.divider} />;
}

Field.Divider = Divider;
