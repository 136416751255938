import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { ProgressBar } from '../../../../common/components';
import { MathHelper } from '../../../../common/_helpers';
import { PRIMARY_COLOR } from '../../../../common/_constants/theme.constants';
import { BasePress } from './BasePress';

export function Abstract() {
    const translate = useTranslate('candidate.softSkillsTest');

    return <BasePress name="abs" icon="abstrakt" extraContent={renderPanels} />;

    function renderPanels(interpretation, isLoading) {
        if (
            isLoading ||
            !interpretation.softSkills ||
            !interpretation.softSkills.all
        ) {
            return null;
        }

        const skills = interpretation.softSkills;
        const speedScore = skills.speed[0].value;
        const qualityScore = skills.accuracy[0].value;

        return (
            <>
                <div className="soft-skills-test-layout--panel">
                    <h5>{translate('titles.subTest')}</h5>
                    {renderChart(interpretation.softSkills.all)}
                </div>
                <div id="abs-panels">
                    <div className="soft-skills-test-layout--panel">
                        <h5>{translate('titles.speed')}</h5>
                        {renderProgress(speedScore, 'speed')}
                    </div>
                    <div className="soft-skills-test-layout--panel">
                        <h5>{translate('titles.quality')}</h5>
                        {renderProgress(qualityScore, 'shield')}
                    </div>
                </div>
            </>
        );
    }

    function renderProgress(value, iconName) {
        const sanitizedValue = MathHelper.clamp(value, 0, 100);
        return (
            <div className="abs-progress">
                <div className="abs-progress__value">
                    <i className={'icon-' + iconName} />{' '}
                    <span>{sanitizedValue}%</span>
                </div>
                <ProgressBar
                    value={sanitizedValue}
                    maxValue={100}
                    color={PRIMARY_COLOR}
                    useMarkers={false}
                    isRounded
                />
            </div>
        );
    }

    function renderChart(data) {
        return (
            <div className="bar-chart horizontal">
                {data.map(renderItem)}
                <div className="bar-chart__item">
                    <div /> {/*empty offset element*/}
                    <div className="bar-chart__range">
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        );

        function renderItem(item, index) {
            const value = MathHelper.clamp(item.value, 0, 100);
            return (
                <div key={index} className="bar-chart__item">
                    <div className="bar-chart__label">{item.skill.name}</div>
                    <div className="bar-chart__bar">
                        <div
                            className="bar-chart__fill"
                            style={{
                                width: `${value}%`,
                            }}
                        >
                            <div className="bar-chart__value">
                                <strong>{value}%</strong>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
