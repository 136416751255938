import { Fragment } from "react";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import { FormButtons } from "../FormButtons";
import { PROFILE_PATH_INFO } from "../../../../_constants/route.constants";
import { ProfileEdit } from "../ProfileEdit";
import { ProfileInformationActions } from "../../../../_actions/ProfileInformationActions";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import ProfileInformationSelectors from "../../../../_selectors/ProfileInformationSelectors";
import ExperienceForm from "../../../../../common/components/form/forms/ExperienceForm";
import IAddress from "../../../../../common/types/IAddress";
import { MainState } from "../../../../../root.reducer";
import { ObjectSchema, StringSchema } from "yup";

const CURRENT_FIELD = "current";
const LOCATION_FIELD = "location";
const DESCRIPTION_FIELD = "description";
const JOBROLE_FIELD = "jobRole";
const COMPANY_FIELD = "company";
const STARTED_FIELD = "started";
const ENDED_FIELD = "ended";

type Values = {
    [CURRENT_FIELD]: boolean;
    [LOCATION_FIELD]: IAddress;
    [DESCRIPTION_FIELD]: string;
    [JOBROLE_FIELD]: any;
    [COMPANY_FIELD]: any;
    [STARTED_FIELD]: any;
    [ENDED_FIELD]: any;
};

export function ExperienceEdit() {
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const experienceId = parseInt(params.id, 10);
    const { experience, profileId } = useSelector((state: MainState) =>
        mapState(state, experienceId)
    );
    const dispatch = useDispatch();
    const initialValues = getInitialValues();

    return (
        <ProfileEdit
            onClose={close}
            formButtons={null}
            heading={
                !experience
                    ? "candidate.profile.information.experience.add"
                    : "candidate.profile.information.experience.edit"
            }
        >
            <Formik<Values>
                initialValues={initialValues}
                validationSchema={createValidationSchema}
                onSubmit={submit}
            >
                <Form />
            </Formik>
        </ProfileEdit>
    );

    function Form() {
        const { submitForm } = useFormikContext();
        return (
            <Fragment>
                <ExperienceForm style={{ marginTop: -20 }} />
                <FormButtons
                    onCancel={close}
                    onConfirm={submitForm}
                    confirmText="common.actions.save"
                    cancelText={null}
                    confirmDisabled={false}
                    prepend={null}
                />
            </Fragment>
        );
    }

    function createValidationSchema() {
        return new ObjectSchema({
            [JOBROLE_FIELD]: new ObjectSchema().nullable().required(),
            [COMPANY_FIELD]: new ObjectSchema().nullable().required(),
            [LOCATION_FIELD]: new ObjectSchema().nullable().required(),
            [DESCRIPTION_FIELD]: new StringSchema().required(),
        });
    }

    function submit(formValues: Values) {
        const {
            jobRole,
            company,
            location,
            started,
            ended,
            current,
            description,
        } = formValues;
        const values = {
            jobRoleId: jobRole.id || null,
            companyUUID: company.uuid || null,
            locationId: location.placeId,
            companyName: company.title || company.name,
            started: started,
            ended: current ? null : ended,
            description,
            title: jobRole.title || jobRole.name,
        };

        if (!experience) {
            dispatch(
                ProfileInformationActions.newJobExperience(values, profileId)
            );
        } else {
            dispatch(
                ProfileInformationActions.updateJobExperience(
                    values,
                    experience.id,
                    profileId
                )
            );
        }

        close();
    }

    function close() {
        history.push(PROFILE_PATH_INFO);
    }

    function getInitialValues() {
        return {
            company: experience?.company,
            jobRole: experience?.jobRole,
            location: experience?.locations && experience.locations[0],
            description: experience?.description,
            started: experience?.started || moment().format(),
            ended: experience?.ended || moment().format(),
            current: !experience?.ended,
            title: experience?.title,
        };
    }
}

function mapState(state: MainState, experienceId: number) {
    return {
        experience:
            ProfileInformationSelectors.getExperiences(state).get(experienceId),
        profileId: AuthSelectors.getProfileId(state),
    };
}
