import React, { useState } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { PRIMARY_COLOR } from '../../_constants/theme.constants';

const RADIAN = Math.PI / 180;

export function ResponsivePieChart({
    data,
    thickness = '70%',
    height,
    width = '100%',
    label,
    tooltip,
}) {
    const [activeIndex, setActiveIndex] = useState(0);

    return render();
    function render() {
        return (
            <ResponsiveContainer width={width} aspect={1} height={height}>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        //activeShape={renderActiveShape}
                        data={data}
                        dataKey="value"
                        innerRadius={thickness}
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={1}
                        fill="#82ca9d"
                        onMouseEnter={onPieEnter}
                        label={
                            label !== undefined ? label : renderCustomizedLabel
                        }
                        labelLine={false}
                        isAnimationActive={false}
                    />
                    {tooltip}
                </PieChart>
            </ResponsiveContainer>
        );
    }

    function renderCustomizedLabel({ cx, cy, midAngle, outerRadius, index }) {
        const radius = outerRadius + 10;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                className="font-heavy"
                x={x}
                y={y}
                fill={PRIMARY_COLOR}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {`${index + 1}.`}
            </text>
        );
    }

    function onPieEnter(data, index) {
        setActiveIndex(index);
    }
}
