import { Link } from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import { SkillsList } from "./SkillsList";
import { PROFILE_LANGUAGES_PATH } from "../../../../common/_constants/route.constants";
import ILanguageScore from "../../../../common/types/ILanguageScore";
import { LanguageMatch } from "../../LanguageMatch/LanguageMatch";

type LanguagesListProps = {
    heading: string;
    match: number;
    languages: ILanguageScore[];
    isLoading: boolean;
};

export function LanguagesList(props: LanguagesListProps) {
    const { heading, match, languages, isLoading } = props;
    const translate = useTranslate("candidate.jobDetail");
    const translateJobs = useTranslate("common.jobs");

    return (
        <SkillsList
            heading={heading}
            match={match}
            skills={
                !isLoading
                    ? languages
                    : Array.from(new Array(2), () => ({ languageMatchScore: 0 }))
            }
            renderItem={(language: ILanguageScore, i) => (
                <Item key={i} language={language} isLoading={isLoading} />
            )}
            getWeightType={getWeightType}
            noSkillsText={translate("noLanguageRequirements")}
            label={
                <>
                    <span>{`${translateJobs("match.language")} `}</span>
                    <Link to={PROFILE_LANGUAGES_PATH}>
                        {translateJobs("detail.skillsLink")}
                    </Link>
                </>
            }
            isLoading={isLoading}
        />
    );
}

function getWeightType(value: ILanguageScore) {
    return value?.idealLanguage?.weightType;
}

function Item(props: { language: ILanguageScore; isLoading: boolean }) {
    const { language, isLoading } = props;
    return (
        <div>
            <LanguageMatch
                match={language?.languageMatchScore}
                name={language?.idealLanguage?.language.name}
                level={language?.actualLanguage?.level?.name}
                levelMin={language?.idealLanguage?.levelMin?.name}
                levelMax={language?.idealLanguage?.levelMax?.name}
                isLoading={isLoading}
            />
        </div>
    );
}
