import React from "react";
import { createClass } from "classname-helper";

export function Checkbox({
    children,
    name,
    isChecked,
    onChange,
    className,
    isSmall,
    ...rest
}) {
    return (
        <label className={createClassName()}>
            <input
                {...rest}
                name={name}
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                className={isSmall ? "small" : ""}
            />
            <div className="label-container">{children}</div>
        </label>
    );

    function handleChange(event) {
        onChange && onChange(event.target.checked);
    }

    function createClassName() {
        return createClass({
            checkbox: true,
            [className]: !!className,
            "is-checked": isChecked,
            small: isSmall,
        });
    }
}
