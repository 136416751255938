export const GET_TRACKING_CARDS = 'GET_TRACKING_CARDS';
export const GET_TRACKING_CARDS_SUCCESS = 'GET_TRACKING_CARDS_SUCCESS';
export const GET_TRACKING_CARDS_FAIL = 'GET_TRACKING_CARDS_FAIL';

export const CHANGE_TRACKING_STATE = 'CHANGE_TRACKING_STATE';
export const CHANGE_TRACKING_STATE_SUCCESS = 'CHANGE_TRACKING_STATE_SUCCESS';
export const CHANGE_TRACKING_STATE_FAIL = 'CHANGE_TRACKING_STATE_FAIL';

export const REJECT_CANDIDATE = 'REJECT_CANDIDATE';
export const REJECT_CANDIDATE_SUCCESS = 'REJECT_CANDIDATE_SUCCESS';
export const REJECT_CANDIDATE_FAIL = 'REJECT_CANDIDATE_FAIL';

export const UNLOCK_CANDIDATE = 'UNLOCK_CANDIDATE';
export const UNLOCK_CANDIDATE_SUCCESS = 'UNLOCK_CANDIDATE_SUCCESS';
export const UNLOCK_CANDIDATE_FAIL = 'UNLOCK_CANDIDATE_FAIL';

export const GET_ARCHIVED_CARDS = 'GET_ARCHIVED_CARDS';
export const GET_ARCHIVED_CARDS_SUCCESS = 'GET_ARCHIVED_CARDS_SUCCESS';
export const GET_ARCHIVED_CARDS_FAIL = 'GET_ARCHIVED_CARDS_FAIL';

export const GET_DECLINED_CARDS = 'GET_DECLINED_CARDS';
export const GET_DECLINED_CARDS_SUCCESS = 'GET_DECLINED_CARDS_SUCCESS';
export const GET_DECLINED_CARDS_FAIL = 'GET_DECLINED_CARDS_FAIL';

export const MOVE_CANDIDATE_CARD = 'MOVE_CANDIDATE_CARD';
