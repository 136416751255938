import { combineReducers } from 'redux';

import {
    GET_WALLET_SUCCESS,
    GET_WALLET,
    GET_WALLET_FAIL,
    GET_COMPANY_CURRENCY_SUCCESS,
} from '../../_constants/actions/wallet.constants';

import pay from './pay.reducer';

export default combineReducers({ data, pay, loading, companyCurrency });

function companyCurrency(
    state = { id: 29, code: 'CZK', symbol: 'Kč' },
    action
) {
    if (action.type === GET_COMPANY_CURRENCY_SUCCESS) {
        return action.payload;
    }

    return state;
}

function data(state = { account: {} }, action) {
    switch (action.type) {
        case GET_WALLET_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

function loading(state = { isLoading: false, isLoaded: false }, action) {
    switch (action.type) {
        case GET_WALLET:
            return Object.assign({}, state, { isLoading: true });
        case GET_WALLET_SUCCESS:
            return { isLoading: false, isLoaded: true };
        case GET_WALLET_FAIL:
            return Object.assign({}, state, { isLoading: false });
        default:
            return state;
    }
}
