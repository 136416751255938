import { DatePicker } from "@mui/x-date-pickers";
import Input from "../Input/Input";

import styles from "./DateInput.module.scss";

interface DateInputProps {
    value: string;
    onChange: (newValue: string) => void;
}

export default function DateInput(props: DateInputProps) {
    const { value, onChange } = props;

    return (
        <DatePicker
            value={value}
            onChange={handleChange}
            renderInput={(params: any) => {
                const { inputProps, InputProps, inputRef } = params;
                return (
                    <div className={styles.input_wrapper}>
                        <Input
                            forwardRef={inputRef as any}
                            placeholder={inputProps?.placeholder}
                            type="tel"
                            value={inputProps?.value}
                            onChange={inputProps?.onChange}
                            onFocus={inputProps?.onFocus}
                            onBlur={inputProps?.onBlur}
                        />
                        {InputProps?.endAdornment}
                    </div>
                );
            }}
        />
    );

    function handleChange(newValue: string) {
        onChange && onChange(newValue);
    }
}
