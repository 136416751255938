import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SoftSkillsActions from "../../../candidate/_actions/SoftSkillsActions";
import SoftSkillsSelectors from "../../../candidate/_selectors/SoftSkillsSelectors";
import { AuthSelectors } from "../../_selectors";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";

export default function useSoftSkillsTest(name: string) {
    const test = useSelector(SoftSkillsSelectors.getTest);
    const profileId = useSelector(AuthSelectors.getProfileId);
    const locale = useSelector(LanguageSelectors.getLocale);
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(SoftSkillsActions.getTest(profileId, name));
        }
    }, [dispatch, profileId, locale, name]);

    useEffect(() => {
        if (profileId) {
            dispatch(SoftSkillsActions.getStatus(profileId, name));
        }
    }, [dispatch, profileId, locale, name]);

    return { test, locale, profileId, completeTest };

    function completeTest(payload: { questionnaireId: number }) {
        dispatch(
            SoftSkillsActions.completeTest(profileId, payload.questionnaireId)
        );
    }
}
