import { cx } from "@emotion/css";
import React, { HTMLAttributes } from "react";

export interface SVGIconProps extends HTMLAttributes<HTMLSpanElement> {
    name: SVGIconName;
    light?: boolean;
}

export function SVGIcon(props: SVGIconProps) {
    const { name, light, className, ...rest } = props;

    return (
        <i
            className={cx(`icon-${name}${light ? "-light" : ""}`, className)}
            {...rest}
        />
    );
}

export type SVGIconName =
    | "youtube"
    | "vimeo"
    | "facebook"
    | "linkedin"
    | "plus"
    | "edit"
    | "trash"
    | "trash-light"
    | "search"
    | "gallery"
    | "film"
    | "hero"
    | "calendar"
    | "star"
    | "bag"
    | "road"
    | "arrow"
    | "arrow-right"
    | "location-pin"
    | "flag"
    | "exchange"
    | "chevron-right"
    | "chevron-left"
    | "chevron-down"
    | "close"
    | "check"
    | "box-light"
    | "lock"
    | "playlist"
    | "school"
    | "view";
