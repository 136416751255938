/**
 * Adds function handler to element
 * @param {Element} node
 * @param {string} eventName
 * @param {function} handler
 * @param {boolean} shouldCapture
 */
export function addEventHandler<T extends Node | Window>(node: T, eventName: string, handler: any, shouldCapture?: boolean) {
    // @ts-expect-error
    if (node.attachEvent) {
        // @ts-expect-error
        node.attachEvent(`on${eventName}`, handler);
    } else {
        node.addEventListener(eventName, handler, shouldCapture);
    }
}
