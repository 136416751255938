import { RequestService } from "../../common/_services";

const CareerSearchService = {
    getJobRoles
}

function getJobRoles() {
    return RequestService.get("api/jobs/roles/list-items");
}

export default CareerSearchService;