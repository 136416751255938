import { createSelector } from 'reselect';
import { ASYNC_STATE_LOADING } from '../_constants/async.constants';
import { ArrayHelper } from '../_helpers/ArrayHelper';

const history = (state) => state.common.notifications.history.list;

const historyState = (state) => state.common.notifications.history.state;

const historyCount = (state) => state.common.notifications.history.count;

const getHistory = createSelector(history, (history) => history);

const getHistoryCount = createSelector(historyCount, (count) => count);

const getHistoryState = createSelector(historyState, (state) => state);

const isHistoryLoading = createSelector(
    getHistoryState,
    getHistory,
    (state, history) =>
        state === ASYNC_STATE_LOADING && ArrayHelper.isEmpty(history)
);

const views = (state) => state.common.notifications.views.list;

const viewsState = (state) => state.common.notifications.views.state;

const viewsCount = (state) => state.common.notifications.views.count;

const getViews = createSelector(views, (views) => views);

const getViewsCount = createSelector(viewsCount, (count) => count);

const getViewsState = createSelector(viewsState, (state) => state);

const isViewsLoading = createSelector(
    getViewsState,
    getViews,
    (state, views) =>
        state === ASYNC_STATE_LOADING && ArrayHelper.isEmpty(views)
);

const pagination = (state) => state.common.notifications.pagination;

const getPagination = createSelector(pagination, (values) => values);
const getPage = createSelector(getPagination, (values) => values.page);

export const NotificationSelectors = {
    getHistory,
    getHistoryCount,
    getHistoryState,
    isHistoryLoading,
    getViews,
    getViewsCount,
    getViewsState,
    isViewsLoading,
    getPage,
    getPagination,
};
