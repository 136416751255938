import React from 'react';
import { Field } from 'formik';
import { Checkbox } from '../input';

export function FormCheckbox({ defaultChecked, children, name, ...rest }) {
    return render();
    function render() {
        return <Field>{renderCheckbox}</Field>;
    }

    function renderCheckbox({ form }) {
        const { values, setValues } = form;
        const isChecked = values[name];
        return (
            <Checkbox
                {...rest}
                name={name}
                isChecked={isChecked}
                onChange={onChange}
            >
                {children}
            </Checkbox>
        );

        function onChange(value) {
            setValues({ ...values, [name]: value });
        }
    }
}
