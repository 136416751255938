import { PropsWithChildren } from "react";
import { Formik, Form } from "formik";
import { useTranslate } from "react-translate.ts";

import { AutocompleteService } from "../../../../candidate/_services";
import {
    INTEGER_FORM_FIELD,
    REQUIRED_FORM_FIELD,
} from "../../../_constants/validation.constants";
import FormYearSelect from "../../input/FormYearSelect";
import FormSelect from "../../input/FormSelect";
import FormField from "../../input/FormField";
import { NumberSchema, ObjectSchema } from "yup";

const UD_TYPE_FIELD = "universityDegreeType";
const UD_FIELD_FIELD = "universityDegreeField";
const UD_TITLE_FIELD = "universityDegreeTitle";
const UNIVERSITY_FIELD = "university";
const LOCATION_FIELD = "location";
const START_YEAR_FIELD = "startYear";
const END_YEAR_FIELD = "endYear";

export type EducationFormProps = {
    className?: string;
    values: any;
    onSubmit: any;
};

export default function EducationForm(
    props: PropsWithChildren<EducationFormProps>
) {
    const { className, children, values, onSubmit, ...rest } = props;
    const initialValues = getInitialValues(values);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={createValidationSchema}
            {...rest}
        >
            <InnerForm className={className}>{children}</InnerForm>
        </Formik>
    );

    function handleSubmit(values: any) {
        onSubmit && onSubmit(values);
    }
}

function getInitialValues(values: any) {
    if (!values) {
        return {
            [UD_TYPE_FIELD]: null,
            [UD_FIELD_FIELD]: null,
            [UD_TITLE_FIELD]: null,
            [UNIVERSITY_FIELD]: null,
            [LOCATION_FIELD]: null,
            [START_YEAR_FIELD]: new Date().getFullYear(),
            [END_YEAR_FIELD]: new Date().getFullYear(),
        };
    }

    return values;
}

function InnerForm(props: any) {
    const { className, children } = props;
    const translate = useTranslate("common.onboarding.education.form");
    const getOptionLabel = (option: { name: string }) => option.name;

    const translateLabel = (field: any) => translate(field + ".label");
    const translatePlaceholder = (field: any) =>
        translate(field + ".placeholder");

    return (
        <Form id="education-form" className={className}>
            <FormField
                label={translateLabel("university")}
                name={UNIVERSITY_FIELD}
                required
            >
                <FormSelect
                    name={UNIVERSITY_FIELD}
                    loadOptions={AutocompleteService.universities}
                    getOptionLabel={getOptionLabel}
                    placeholder={translatePlaceholder("university")}
                    searchable
                />
            </FormField>
            <FormField
                label={translateLabel("location")}
                name={LOCATION_FIELD}
                required
            >
                <FormSelect<{ title?: string; text?: string }>
                    name={LOCATION_FIELD}
                    placeholder={translatePlaceholder("location")}
                    loadOptions={AutocompleteService.cities}
                    getOptionLabel={(option) => option?.title || option?.text}
                    searchable
                />
            </FormField>
            <FormField
                label={translateLabel("field")}
                name={UD_FIELD_FIELD}
                required
            >
                <FormSelect
                    name={UD_FIELD_FIELD}
                    placeholder={translatePlaceholder("field")}
                    loadOptions={AutocompleteService.degreeFields}
                    getOptionLabel={getOptionLabel}
                    searchable
                />
            </FormField>
            <FormField
                label={translateLabel("title")}
                name={UD_TITLE_FIELD}
                required
            >
                <FormSelect
                    name={UD_TITLE_FIELD}
                    placeholder={translatePlaceholder("title")}
                    loadOptions={AutocompleteService.degreeTitles}
                    getOptionLabel={getOptionLabel}
                    searchable
                    preload
                />
            </FormField>
            <FormField
                name={START_YEAR_FIELD}
                label={translate("startYear")}
                required
            >
                <FormYearSelect name={START_YEAR_FIELD} yearCount={21} />
            </FormField>
            <FormField
                name={END_YEAR_FIELD}
                label={translate("endYear")}
                required
            >
                <FormYearSelect
                    name={END_YEAR_FIELD}
                    yearCount={21}
                    addedYears={10}
                />
            </FormField>
            <FormField
                label={translateLabel("type")}
                name={UD_TYPE_FIELD}
                required
            >
                <FormSelect
                    name={UD_TYPE_FIELD}
                    placeholder={translatePlaceholder("type")}
                    loadOptions={AutocompleteService.degreeTypes}
                    getOptionLabel={getOptionLabel}
                    searchable
                    preload
                />
            </FormField>
            {children}
        </Form>
    );
}

function createValidationSchema() {
    return new ObjectSchema({
        [UD_TYPE_FIELD]: new ObjectSchema()
            .required(REQUIRED_FORM_FIELD)
            .nullable(),
        [UD_FIELD_FIELD]: new ObjectSchema()
            .required(REQUIRED_FORM_FIELD)
            .nullable(),
        [UD_TITLE_FIELD]: new ObjectSchema()
            .required(REQUIRED_FORM_FIELD)
            .nullable(),
        [UNIVERSITY_FIELD]: new ObjectSchema()
            .required(REQUIRED_FORM_FIELD)
            .nullable(),
        [LOCATION_FIELD]: new ObjectSchema()
            .required(REQUIRED_FORM_FIELD)
            .nullable(),
        [START_YEAR_FIELD]: new NumberSchema()
            .required(REQUIRED_FORM_FIELD)
            .integer(INTEGER_FORM_FIELD)
            .positive(),
        [END_YEAR_FIELD]: new NumberSchema()
            .required(REQUIRED_FORM_FIELD)
            .integer(INTEGER_FORM_FIELD)
            .positive(),
    });
}
