import { createSelector } from "reselect";

import {
    COMPLETED_STATUS,
    PROCESSED_STATUS,
} from "../../../candidate/_constants/testStatus.constants";

const skills = (state) => state.common.heroes.onboarding.steps.skills;

const getTest = createSelector(skills, (skills) => skills.test);

const getStatus = createSelector(skills, (skills) => skills.status);
const isComplete = createSelector(
    getStatus,
    (status) =>
        status && (status === COMPLETED_STATUS || status === PROCESSED_STATUS)
);

const hasStarted = createSelector(skills, (skills) => skills.started);

const getResults = createSelector(skills, (skills) => skills.results);
const getSkills = createSelector(skills, (skills) => skills.skills);

const Skills = {
    getTest,
    hasStarted,
    isComplete,
    getStatus,
    getResults,
    getSkills,
};

export default Skills;
