import {
    ONBOARDING_PERSONAL_BEGIN,
    ONBOARDING_PERSONAL_SUCCESS,
    ONBOARDING_PERSONAL_ERROR,
    ONBOARDING_EDUCATION_BEGIN,
    ONBOARDING_EDUCATION_SUCCESS,
    ONBOARDING_EDUCATION_ERROR,
    ONBOARDING_TEST_BEGIN,
    ONBOARDING_TEST_SUCCESS,
    ONBOARDING_TEST_ERROR,
    ONBOARDING_SKIP_BEGIN,
    ONBOARDING_SKIP_SUCCESS,
    ONBOARDING_SKIP_ERROR,
    ONBOARDING_GO_BACK,
} from "../_constants/actions/onboarding.constants";
import OnboardingConstants from "../_constants/actions/OnboardingConstants";
import { OnboardingService } from "../_services/OnboardingService";

export const OnboardingActions = {
    personal,
    education,
    educationSuccess,
    educationError,
    test,
    skip,
    goBack,
    savePersonal,
    addStep: (ammount) => ({
        type: OnboardingConstants.ADD_STEP,
        payload: ammount,
    }),
    changeStep: (value) => ({
        type: OnboardingConstants.CHANGE_STEP,
        payload: value,
    }),
    saveHardSkills,
    startTest,
    changePersonalValue,
};

function startTest() {
    return { type: OnboardingConstants.START_TEST };
}

function saveHardSkills(skills) {
    return { type: OnboardingConstants.SAVE_HARD_SKILLS, payload: skills };
}

function savePersonal(
    uuid,
    firstName,
    lastName,
    profileType,
    locationId,
    jobRoleId,
    universityDegreeFieldId,
    universityDegreeTitleId
) {
    return {
        type: OnboardingConstants.SAVE_PERSONAL,
        payload: {
            uuid,
            firstName,
            lastName,
            profileType,
            locationId,
            jobRoleId,
            universityDegreeFieldId,
            universityDegreeTitleId,
        },
    };
}

function personal(values, uuid) {
    return (dispatch) => {
        dispatch({ type: ONBOARDING_PERSONAL_BEGIN });

        OnboardingService.personal(values, uuid).then(
            (response) =>
                dispatch({
                    type: ONBOARDING_PERSONAL_SUCCESS,
                    data: response.data,
                }),
            (response) =>
                dispatch({
                    type: ONBOARDING_PERSONAL_ERROR,
                    error: response.error,
                })
        );
    };
}

function education(values, uuid) {
    return {
        type: ONBOARDING_EDUCATION_BEGIN,
        payload: { values, uuid },
    };
}

function educationSuccess(data) {
    return { type: ONBOARDING_EDUCATION_SUCCESS, data };
}

function educationError(error) {
    return { type: ONBOARDING_EDUCATION_ERROR, error };
}

function test() {
    return (dispatch) => {
        dispatch({ type: ONBOARDING_TEST_BEGIN });

        OnboardingService.test().then(
            (response) =>
                dispatch({
                    type: ONBOARDING_TEST_SUCCESS,
                    data: response.data,
                }),
            (response) =>
                dispatch({
                    type: ONBOARDING_TEST_ERROR,
                    error: response.error,
                })
        );
    };
}

function skip() {
    return (dispatch) => {
        dispatch({ type: ONBOARDING_SKIP_BEGIN });

        OnboardingService.skip().then(
            (response) =>
                dispatch({
                    type: ONBOARDING_SKIP_SUCCESS,
                    payload: { status: response.data },
                }),
            (response) =>
                dispatch({
                    type: ONBOARDING_SKIP_ERROR,
                    error: response.error,
                })
        );
    };
}

function goBack(status) {
    return { type: ONBOARDING_GO_BACK, payload: status };
}

function changePersonalValue(field, value) {
    return {
        type: OnboardingConstants.CHANGE_PERSONAL_VALUE,
        payload: { field, value },
    };
}
