import { createSelector } from 'reselect';
import { STATE_LOADING } from '../../common/_constants/state.constants';

const detail = (state) => state.candidate.jobRoles.detail;

const monitoring = createSelector(detail, (detail) => detail.monitoring);

const getMonitoring = createSelector(
    monitoring,
    (monitoring) => monitoring.data
);

const getMonitoringLoading = createSelector(
    monitoring,
    (monitoring) =>
        monitoring.state === STATE_LOADING &&
        monitoring.loadedJobRoleId !== monitoring.currentJobRoleId
);

export const JobRoleDetailSelectors = {
    getMonitoring,
    getMonitoringLoading,
};
