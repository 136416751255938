import React, { ReactNode } from "react";
import { useTranslate } from "react-translate.ts";
import cx from "classnames";

import styles from "./InterpretationSummary.module.scss";
import InterpretationPanel from "../InterpretationPanel/InterpretationPanel";
import { renderEmptyElements } from "../../../_helpers";
import InterpretationSummaryList from "./InterpretationSummaryList/InterpretationSummaryList";

type InterpretationSummaryProps = {
    items: any[];
    heading: string;
    id?: string;
    children?: ReactNode;
    showList?: boolean;
};

export default function InterpretationSummary(
    props: InterpretationSummaryProps
) {
    const { items, heading, id = "summary", children, showList = true } = props;

    if (items?.length <= 0) {
        return null;
    }

    return (
        <InterpretationPanel className={styles.panel} id={id}>
            <InterpretationPanel.Inner>
                <h2>{heading}</h2>
                <div className={styles.items}>
                    {items.map((item, index) => (
                        <div key={index}>
                            <Item item={item} showList={showList} />
                            {index < items.length - 1 && <Separator />}
                        </div>
                    ))}
                </div>
                {children}
            </InterpretationPanel.Inner>
        </InterpretationPanel>
    );
}

type ItemProps = {
    item: {
        title: string;
        description: string;
        development?: [];
        advantages: [];
        risks: [];
        imageName: string;
        name?: string;
    };
    showList: boolean;
};

function Item(props: ItemProps) {
    const { item, showList } = props;
    const translate = useTranslate("candidate.interpretation");

    return (
        <div className={styles.items__item}>
            <div>
                {item?.name && (
                    <InterpretationPanel.Subtitle>
                        {item.name}
                    </InterpretationPanel.Subtitle>
                )}
                <div className={styles.items__item__image}>
                    <img
                        src={`/img/soft-skills/${item?.imageName}.svg`}
                        alt=""
                    />
                </div>
            </div>
            {render()}
        </div>
    );

    function render() {
        if (!item) {
            return (
                <>
                    <div>
                        <div className={styles.items__item__sub}>
                            {renderEmptyElements("div", 1)}
                        </div>
                        <div>{renderEmptyElements("div", 11)}</div>
                    </div>
                    <div />
                    <div className={styles.summary__item__list}>
                        <div className={styles.items__item__list__label} />
                        <ul>{renderEmptyElements("li", 3)}</ul>
                    </div>
                </>
            );
        }

        return (
            <>
                <div>
                    <div className={cx(styles.items__item__sub, "font-book")}>
                        {item?.title}
                    </div>
                    {item?.description &&
                        (Array.isArray(item?.description)
                            ? item.description
                            : [item.description]
                        ).map((description, index) => (
                            <p key={index}>{description}</p>
                        ))}
                </div>
                {showList && (
                    <>
                        {item?.development ? (
                            <InterpretationSummaryList
                                label={translate("summary.development")}
                                items={item?.development}
                            />
                        ) : (
                            <div />
                        )}
                        <InterpretationSummaryList
                            label={translate("summary.advantages")}
                            items={item?.advantages}
                        />
                        <div />
                        <InterpretationSummaryList
                            label={translate("summary.risks")}
                            items={item?.risks}
                        />
                    </>
                )}
            </>
        );
    }
}

function Separator() {
    return <div className={styles.separator} />;
}

InterpretationSummary.Separator = Separator;
