import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Slider, Tooltip, withStyles } from "@material-ui/core";

import { JobFilterActions } from "../../../../_actions";
import { FailedFilters } from "./FailedFilters";
import { useFiltersContext } from "./FiltersContext";
import styles from "./SalaryFilters.module.scss";
import { formatMoney } from "../../../../../common/_helpers";
import ICurrency from "../../../../../common/types/ICurrency";
import { useFreshState } from "../../../../../common/hooks/useFreshState";
import Select from "../../../../../common/components/input/Select/Select";

type FiltersProps = {
    currency: ICurrency | null;
    salary: [number, number];
    salaryRange: { amountMin: number; amountMax: number; id: number };
    currencyDTOS: ICurrency[];
};

type SalaryFiltersProps = {
    isLoading: boolean;
    hasFailed: boolean;
} & FiltersProps;

export default function SalaryFilters(props: SalaryFiltersProps) {
    const {
        salary,
        currency,
        isLoading,
        hasFailed,
        salaryRange,
        currencyDTOS,
    } = props;

    if (isLoading) {
        return <>Loading...</>;
    }

    if (hasFailed) {
        return <FailedFilters />;
    }

    return (
        <Filters
            salary={salary}
            salaryRange={salaryRange}
            currency={currency}
            currencyDTOS={currencyDTOS}
        />
    );
}

function Filters(props: FiltersProps) {
    const { salary, salaryRange, currency, currencyDTOS } = props;
    const dispatch = useDispatch();
    const { addConfirmEvent, removeConfirmEvent } = useFiltersContext();

    useEffect(() => {
        addConfirmEvent(save);

        return () => removeConfirmEvent(save);
    }, []);

    const [currentSalary, setCurrentSalary] = useFreshState(salary);
    const [currentCurrency, setCurrentCurrency] = useFreshState(currency);

    const firstRun = useRef(true);
    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return;
        }

        setCurrentSalary([salaryRange?.amountMin, salaryRange?.amountMax]);
    }, [salaryRange.id]);

    useEffect(() => {
        if (currentCurrency.current) {
            dispatch(JobFilterActions.getSalaryRange(currentCurrency.current));
        }
    }, [currentCurrency.current]);

    const defaultValue = useMemo(
        () => ({ id: -1, code: "Any", symbol: "#" }),
        []
    );

    const isSliderDisabled = !currentCurrency.current;

    return (
        <div className={styles.filters}>
            <div className={styles.select}>
                <Select<ICurrency>
                    options={[defaultValue, ...currencyDTOS]}
                    // defaultValue={defaultValue}
                    getOptionLabel={(option) =>
                        `${option.code} - ${option.symbol}`
                    }
                    value={currentCurrency.current || defaultValue}
                    onSelect={(value) => {
                        if (value.id === -1) {
                            setCurrentCurrency(null);
                            setCurrentSalary([null, null])
                            return;
                        }
                        setCurrentCurrency(value);
                    }}
                />
            </div>
            <div className={styles.slider}>
                <Slider
                    min={isSliderDisabled ? 0 : salaryRange?.amountMin}
                    max={isSliderDisabled ? 1 : salaryRange?.amountMax}
                    step={100}
                    value={isSliderDisabled ? [0, 1] : currentSalary.current}
                    onChange={(_, value) =>
                        setCurrentSalary(value as [number, number])
                    }
                    valueLabelDisplay={isSliderDisabled ? "off" : "on"}
                    ValueLabelComponent={SalaryTooltip}
                    valueLabelFormat={(value) =>
                        formatMoney(value, currentCurrency.current?.code)
                    }
                    disabled={isSliderDisabled}
                />
            </div>
        </div>
    );

    function save() {
        dispatch(JobFilterActions.changeSalary(currentSalary.current));
        dispatch(JobFilterActions.changeCurrency(currentCurrency.current));
    }
}

const CustomTooltip = withStyles({
    tooltip: {
        background: "#f0f0f0",
        fontSize: "12px",
        letterSpacing: "-0.2px",
        color: "#303d62",
        top: 10,
    },
    arrow: {
        color: "#f0f0f0",
    },
})(Tooltip);

function SalaryTooltip({
    value,
    children,
    open,
}: {
    value: any;
    children: any;
    open: boolean;
}) {
    const popperRef = useRef<any>(null);

    useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    }, [value]);

    return (
        <CustomTooltip
            title={value}
            open={open}
            placement="top"
            PopperProps={{
                popperRef,
                disablePortal: true,
                style: { zIndex: -1 },
            }}
            arrow
        >
            {children}
        </CustomTooltip>
    );
}
