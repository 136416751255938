import styles from "./Header.module.scss";
import { cx } from "@emotion/css";
import React, { useEffect } from "react";
import { LanguageButton } from "../../header/LanguageButton";
import { NotificationButton } from "../../header/NotificationButton";
import { ViewsButton } from "../../header/ViewsButton";
import { MainMenuButton } from "../../header/MainMenuButton";
import { Translate, useTranslate } from "react-translate.ts";
import { AuthSelectors, UserProfileSelectors } from "../../../_selectors";
import { useDispatch, useSelector } from "react-redux";
import { ProfileActions } from "../../../_actions/ProfileActions";
import { MathHelper } from "../../../_helpers";
import { BaseProgressBar } from "../../misc";
import { PRIMARY_COLOR } from "../../../_constants/theme.constants";

export type HeaderProps = {
    className?: string;
    showNotifications?: boolean;
    showViews?: boolean;
    showMainMenu?: boolean;
    showProfileProgress?: boolean;
    menuContent?: JSX.Element;
};

export default function Header(props: HeaderProps) {
    const {
        className,
        showNotifications = false,
        showViews = false,
        showMainMenu = false,
        showProfileProgress = true,
        menuContent,
    } = props;

    const profileId = AuthSelectors.useGetProfileId();
    const translate = useTranslate("common.header.buttons");

    return (
        <header className={cx(styles.header, className)}>
            <img className={styles.logo} src="/img/logo_simple.svg" alt="" />
            <div className="header-nav__right">
                {showProfileProgress && (
                    <ProfileProgress profileId={profileId} />
                )}
                <div className="header-nav--buttons">
                    <LanguageButton title={translate("language")} />
                    {showNotifications && (
                        <NotificationButton title={translate("notif")} />
                    )}
                    {showViews && <ViewsButton title={translate("views")} />}
                    {showMainMenu && (
                        <MainMenuButton
                            title={translate("profile")}
                            profileId={profileId}
                        >
                            {menuContent}
                        </MainMenuButton>
                    )}
                </div>
            </div>
        </header>
    );
}

function ProfileProgress(props: { profileId: number }) {
    const { profileId } = props;
    const completeness = useSelector(
        UserProfileSelectors.getProfileCompleteness
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(ProfileActions.getProfileCompleteness(profileId));
        }
    }, [profileId]);

    if (
        completeness === null ||
        completeness === undefined ||
        completeness >= 1
    ) {
        return null;
    }

    return (
        <div className="header-nav__progress">
            <Translate
                className="header-nav__progress__label"
                params={{ completed: MathHelper.round(completeness * 100, 0) }}
            >
                common.profile.completed
            </Translate>
            <BaseProgressBar
                value={completeness}
                maxValue={1}
                useMarkers={false}
                isRounded
                color={PRIMARY_COLOR}
            />
        </div>
    );
}
