import styles from "./BasicPageLayout.module.scss";
import { PropsWithChildren } from "react";
import { cx } from "@emotion/css";

type BasicPageLayoutProps = PropsWithChildren<{
    className?: string;
    header?: JSX.Element;
    sideBar?: JSX.Element;
    mobileBar?: JSX.Element;
}>;

export default function BasicPageLayout(props: BasicPageLayoutProps) {
    const { className, header, sideBar, mobileBar, children } = props;
    return (
        <div className={cx(styles.layout, className)}>
            {header}
            <div className={styles.body}>
                {sideBar}
                <main>
                    <div className={styles.content}>{children}</div>
                </main>
            </div>
            {mobileBar}
        </div>
    );
}
