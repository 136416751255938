import React, { useEffect, useReducer, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { useTranslate } from "react-translate.ts";
import { useSelector, useDispatch } from "react-redux";

import {
    SmallSelect,
    TrackingLayout,
    Loading,
    PopupOverlay,
} from "../../../common/components";
import {
    CANDIDATES_REJECTED_PATH,
    CANDIDATES_ARCHIVED_PATH,
} from "../../_constants/route.constants";

import { CandidatesActive, CandidatesArchive } from "./components";
import { JobsActions, CandidateActions } from "../../_actions";
import { ADMIN_CANDIDATES_PATH } from "../../../common/_constants/route.constants";
import { CandidatesDeclined } from "./components/CandidatesDeclined";
import { JobSelectors } from "../../_selectors/JobSelectors";
import { CandidateOverlay } from "../index";
import { TRACKING_ACCEPTED } from "../../_constants/actions/tracking.constants";

const SET_JOB = "SET_JOB";
const SHOW_UNLOCK = "SHOW_UNLOCK";
const HIDE_UNLOCK = "HIDE_UNLOCK";
const UNLOCK = "UNLOCK";
const SET_REJECTED_CANDIDATE = "SET_REJECTED_CANDIDATE";

const initUnlockState = {
    candidateId: null,
    filter: null,
};

function reducer(state, action) {
    switch (action.type) {
        case SHOW_UNLOCK:
            return Object.assign({}, state, { unlock: action.payload });
        case UNLOCK:
            return Object.assign({}, state, {
                unlock: Object.assign(state.unlock, {
                    filter: TRACKING_ACCEPTED,
                }),
            });
        case HIDE_UNLOCK:
            return Object.assign({}, state, { unlock: initUnlockState });
        case SET_JOB:
            return Object.assign({}, state, { jobPostId: action.payload });
        case SET_REJECTED_CANDIDATE:
            return Object.assign({}, state, {
                rejectedCandidate: action.payload,
            });
        default:
            return state;
    }
}

export function CompanyCandidates() {
    const { jobs, companyId, isLoading } = useSelector(mapState);

    const location = useLocation();

    const initState = {
        rejectedCandidate: null,
        unlock: initUnlockState,
        jobPostId: (location.state || { jobPostId: 0 }).jobPostId,
    };

    const [state, dispatch] = useReducer(reducer, initState);

    const { getCompanyJobs } = mapActions(useDispatch());

    useEffect(() => void getCompanyJobs(companyId), []);
    const translate = useTranslate("admin.candidate.tracking");

    const [profileDetail, setProfileDetail] = useState(null);

    const isOverlayVisible = !!profileDetail;
    const options = [
        {
            id: 0,
            name: translate("select.default"),
        },
        ...jobs,
    ];

    return (
        <TrackingLayout
            id="candidate-tracking"
            heading={translate("heading")}
            links={[
                {
                    path: ADMIN_CANDIDATES_PATH,
                    exact: true,
                    label: translate("links.active"),
                },
                {
                    path: CANDIDATES_REJECTED_PATH,
                    label: translate("links.declined"),
                },
                {
                    path: CANDIDATES_ARCHIVED_PATH,
                    label: translate("links.archived"),
                },
            ]}
            extraLinksContent={
                <SmallSelect
                    options={options}
                    getOptionValue={(item) => item.id}
                    getOptionLabel={(item) => item.name}
                    label="admin.candidate.tracking.select.label"
                    value={state.jobPostId}
                    onChange={({ target }) => setJobPostId(target.value)}
                    defaultValue={0}
                />
            }
        >
            <PopupOverlay
                isOpen={isOverlayVisible}
                onClose={() => setProfileDetail(null)}
            >
                <CandidateOverlay
                    candidate={profileDetail}
                    jobPostId={
                        profileDetail &&
                        profileDetail.jobPost &&
                        profileDetail.jobPost.id
                    }
                    profileId={
                        profileDetail &&
                        profileDetail.basicInfo &&
                        profileDetail.basicInfo.id
                    }
                    withAcceptAction
                    withoutSaveAction={true}
                    onAccept={(candidate) => {
                        showUnlock({
                            candidateId: candidate.id,
                            filter: candidate.status,
                        });
                    }}
                    onReject={(candidate) => {
                        setRejectedCandidate(candidate);
                    }}
                />
            </PopupOverlay>
            <Loading isLoading={isLoading} />
            <Switch>
                <Route
                    exact
                    path={ADMIN_CANDIDATES_PATH}
                    component={CandidatesActive}
                >
                    <CandidatesActive
                        jobPostId={state.jobPostId}
                        onCardClick={setProfileDetail}
                        showUnlock={showUnlock}
                        setRejectedCandidate={setRejectedCandidate}
                        unlockState={state.unlock}
                        rejectedCandidate={state.rejectedCandidate}
                        unlock={unlock}
                        hideUnlock={hideUnlock}
                        onReject={() => void setProfileDetail(null)}
                        onAccept={() => void setProfileDetail(null)}
                    />
                </Route>
                <Route path={CANDIDATES_REJECTED_PATH}>
                    <CandidatesDeclined
                        jobPostId={state.jobPostId}
                        onCardClick={setProfileDetail}
                    />
                </Route>
                <Route path={CANDIDATES_ARCHIVED_PATH}>
                    <CandidatesArchive
                        jobPostId={state.jobPostId}
                        onCardClick={(profile) => setProfileDetail(profile)}
                    />
                </Route>
            </Switch>
        </TrackingLayout>
    );

    function setJobPostId(id) {
        dispatch({ type: SET_JOB, payload: id });
    }

    function showUnlock(payload) {
        dispatch({ type: SHOW_UNLOCK, payload });
    }

    function hideUnlock() {
        dispatch({ type: HIDE_UNLOCK });
    }

    function unlock() {
        dispatch({ type: UNLOCK });
    }

    function setRejectedCandidate(payload) {
        dispatch({
            type: SET_REJECTED_CANDIDATE,
            payload,
        });
    }
}

function mapState(state) {
    return {
        jobs: state.admin.jobs.list,
        companyId: state.common.auth.user.companyId,
        isLoading: JobSelectors.isLoading(state),
    };
}

function mapActions(dispatch) {
    return {
        getCompanyJobs: (companyId) =>
            void dispatch(JobsActions.getCompanyJobs(companyId)),
        setFavourite: (candidate, profileId, jobPostId) => {
            if (profileId && jobPostId) {
                dispatch(
                    CandidateActions.setFavouriteCandidate(
                        candidate,
                        profileId,
                        jobPostId
                    )
                );
            }
        },
        unsetFavourite: (candidate, profileId, jobPostId) => {
            if (profileId && jobPostId) {
                void dispatch(
                    CandidateActions.unsetFavouriteCandidate(
                        candidate,
                        profileId,
                        jobPostId
                    )
                );
            }
        },
    };
}
