import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExperienceItem } from './ExperienceItem';
import { ProfileInformationActions } from '../../../../candidate/_actions/ProfileInformationActions';
import { AuthSelectors } from '../../../_selectors';

export function JobExperienceItem({ item, onEdit, displayOnly }) {
    const { id, company, title, started, ended, description, locations } = item;
    const actions = mapActions(useDispatch());
    const profileId = useSelector(AuthSelectors.getProfileId);

    // const logoUrl = resolveLogoUrl(item.logoUrl, company.url);
    const providerUrl = 'https://logo.clearbit.com/';
    const logoUrl =
        providerUrl + (company.domain ? company.domain.split('/')[0] : '');
    const location =
        locations && locations.length > 0 ? locations[0].text : null;
    const props = {
        logoUrl,
        title,
        name: company.name,
        started,
        ended,
        location,
        onEdit,
        description,
        onDelete: deleteItem,
        displayOnly,
        translatePrefix: 'candidate.profile.delete.experience',
    };

    return <ExperienceItem {...props} />;

    function deleteItem() {
        actions.deleteItem(id, profileId);
    }
}

function mapActions(dispatch) {
    return {
        deleteItem: (id, profileId) =>
            dispatch(
                ProfileInformationActions.deleteJobExperience(id, profileId)
            ),
    };
}
