import {
    JOB_FILTER_CHANGE_SALARY,
    JOB_FILTER_CHANGE_REMOTE,
    JOB_FILTER_CHANGE_CURRENCY,
    GET_JOB_FILTERS,
    JOB_FILTER_CHANGE_BENEFIT,
    JOB_FILTER_CHANGE_EMPLOYMENT,
    JOB_FILTER_CHANGE_LOCATIONS,
    JOB_FILTER_CHANGE_ROLES,
    JOB_FILTER_CLEAR,
    GET_JOB_SALARY_RANGE, JOB_FILTER_CHANGE_TITLE
} from "../_constants";

export const JobFilterActions = {
    getFilters,
    getSalaryRange,
    changeSalary,
    changeRemote,
    changeCurrency,
    changeBenefits,
    changeEmployment,
    changeLocations,
    changeRoles,
    clearFilters,
    changeEmploymentType,
    changeTitle,
};

function getFilters() {
    return { type: GET_JOB_FILTERS };
}

function getSalaryRange(currency) {
    return { type: GET_JOB_SALARY_RANGE, payload: currency.id || 1 };
}

function changeSalary(salary) {
    return { type: JOB_FILTER_CHANGE_SALARY, payload: salary };
}

function changeRemote(isActive) {
    return { type: JOB_FILTER_CHANGE_REMOTE, payload: isActive };
}

function changeCurrency(currency) {
    return { type: JOB_FILTER_CHANGE_CURRENCY, payload: currency };
}

function changeBenefits(benefits) {
    return { type: JOB_FILTER_CHANGE_BENEFIT, payload: benefits };
}

function changeEmployment(types) {
    return { type: JOB_FILTER_CHANGE_EMPLOYMENT, payload: types };
}

function changeLocations(locations) {
    return { type: JOB_FILTER_CHANGE_LOCATIONS, payload: locations };
}

function changeRoles(roles) {
    return { type: JOB_FILTER_CHANGE_ROLES, payload: roles };
}

function clearFilters() {
    return { type: JOB_FILTER_CLEAR };
}

function changeEmploymentType(types) {
    return { type: JOB_FILTER_CHANGE_EMPLOYMENT, payload: types };
}

function changeTitle(title) {
    return { type: JOB_FILTER_CHANGE_TITLE, payload: title };
}
