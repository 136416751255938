import {
    JOB_CARDS_CHANGE_PAGE,
    GET_JOB_CARDS_SUCCESS
} from '../../../_constants';

const initialState = {
    elements: 0,
    page: 0,
    pages: 0
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_JOB_CARDS_SUCCESS:
            return getCardsSuccess(action.payload);
        case JOB_CARDS_CHANGE_PAGE:
            return { ...state, page: action.payload };
        default:
            return state;
    }
}

function getCardsSuccess(payload) {
    const { pageable, numberOfElements, totalPages } = payload;

    return {
        page: pageable.pageNumber,
        elements: numberOfElements,
        pages: totalPages
    };
}
