import { useMemo } from "react";

export default function useYearOptions(
    currentYear: number,
    addedYears?: number,
    yearCount?: number
) {
    return useMemo(
        () => createOptions(currentYear, addedYears, yearCount),
        [currentYear, addedYears, yearCount]
    );
}

function createOptions(
    currentYear: number,
    addedYears?: number,
    yearCount?: number
) {
    const added = addedYears || 0;
    const count = (yearCount || 15) + added;
    const values = Array.from(new Array(count), (_, index) => {
        return currentYear + added - count + index + 1;
    });

    return values.reverse();
}
