export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAIL = 'GET_APPLICATIONS_FAIL';

export const GET_APPLICATIONS_ARCHIVED = 'GET_APPLICATIONS_ARCHIVED';
export const GET_APPLICATIONS_ARCHIVED_SUCCESS =
    'GET_APPLICATIONS_ARCHIVED_SUCCESS';
export const GET_APPLICATIONS_ARCHIVED_FAIL = 'GET_APPLICATIONS_ARCHIVED_FAIL';

export const GET_APPLICATIONS_REJECTED = 'GET_APPLICATIONS_REJECTED';
export const GET_APPLICATIONS_REJECTED_SUCCESS =
    'GET_APPLICATIONS_REJECTED_SUCCESS';
export const GET_APPLICATIONS_REJECTED_FAIL = 'GET_APPLICATIONS_REJECTED_FAIL';

export const APPLICATIONS_ACCEPT = 'APPLICATIONS_ACCEPT';
export const APPLICATIONS_ACCEPT_SUCCESS = 'APPLICATIONS_ACCEPT_SUCCESS';
export const APPLICATIONS_ACCEPT_FAIL = 'APPLICATIONS_ACCEPT_FAIL';

export const APPLICATIONS_REJECT = 'APPLICATIONS_REJECT';
export const APPLICATIONS_REJECT_SUCCESS = 'APPLICATIONS_REJECT_SUCCESS';
export const APPLICATIONS_REJECT_FAIL = 'APPLICATIONS_REJECT_FAIL';
