import { RequestService } from "../../common/_services/RequestService";

export const JobRoleService = {
    getJobRole,
    getJobRoleMonitoring,
};

function getJobRole(id) {
    return RequestService.get(`api/jobs/roles/${id}`);
}

function getJobRoleMonitoring(jobRoleId) {
    return RequestService.get(`jobs/roles/${jobRoleId}/monitoring`);
}
