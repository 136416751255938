import { takeLatest, put, call } from 'redux-saga/effects';

import { WalletService } from '../_services/WalletService';
import {
    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAIL,
    ACCEPT_PAYMENT,
    ACCEPT_PAYMENT_SUCCESS,
    ACCEPT_PAYMENT_FAIL,
    GET_WALLET,
    GET_WALLET_SUCCESS,
    GET_WALLET_FAIL,
    GET_CREDIT_PRICE,
    GET_CREDIT_PRICE_SUCCESS,
    GET_CREDIT_PRICE_FAIL,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES
} from '../_constants/actions/wallet.constants';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { loadStripe } from '@stripe/stripe-js';
import { NotificationActions } from '../../common/_actions/NotificationActions';

let stripe = null;

function* createPayment(action) {
    yield SagaHelpers.handleRequest({
        request: [WalletService.createPayment, action.payload],
        actions: [
            payload => ({ type: CREATE_PAYMENT_SUCCESS, payload }),
            error => ({ type: CREATE_PAYMENT_FAIL, error })
        ],
        messages: { fail: 'Failed to create payment' },
        onSuccess
    });

    function* onSuccess(response) {
        if (!stripe) {
            stripe = yield call(loadStripe, response.data.apiKey);
        }

        stripe
            .redirectToCheckout({
                sessionId: response.data.sessionId
            })
            .then(function*(result) {
                yield put(NotificationActions.pushError(result.error.message));
            });
    }
}

function* acceptPayment(action) {
    yield SagaHelpers.handleRequest({
        request: [WalletService.acceptPayment, action.payload],
        actions: [
            payload => ({ type: ACCEPT_PAYMENT_SUCCESS, payload }),
            error => ({ type: ACCEPT_PAYMENT_FAIL, error })
        ],
        messages: { fail: 'Failed to accept payment' }
    });
}


function* getWallet() {
    yield SagaHelpers.handleRequest({
        request: [WalletService.getWallet],
        actions: [
            payload => ({ type: GET_WALLET_SUCCESS, payload }),
            error => ({ type: GET_WALLET_FAIL, error })
        ],
        messages: { fail: 'Failed to get wallet' }
    });
}

function* getPrice(action) {
    yield SagaHelpers.handleRequest({
        request: [WalletService.getPrice, action.payload],
        actions: [
            payload => ({ type: GET_CREDIT_PRICE_SUCCESS, payload }),
            error => ({ type: GET_CREDIT_PRICE_FAIL, error })
        ],
        messages: { fail: 'Failed to get price' }
    });
}

function* getCurrencies() {
    yield SagaHelpers.handleRequest({
        request: [WalletService.getCurrencies],
        actions: [
            payload => ({ type: GET_CURRENCIES_SUCCESS, payload }),
            error => ({ type: GET_CURRENCIES_FAIL, error })
        ],
        messages: { fail: 'Failed to get currencies' }
    });
}

export default [
    takeLatest(CREATE_PAYMENT, createPayment),
    takeLatest(ACCEPT_PAYMENT, acceptPayment),
    takeLatest(GET_WALLET, getWallet),
    takeLatest(GET_CREDIT_PRICE, getPrice),
    takeLatest(GET_CURRENCIES, getCurrencies)
];
