import { BORDER_COLOR, PRIMARY_COLOR, SECONDARY_TEXT_COLOR } from "../_constants/theme.constants";

export function useReactSelectStyle(styles = {}, options = {}) {
    return {
        select: createSelect(styles, options),
        plainSelect: createPlainSelect(styles, options),
    };
}

export function useReactSelectTheme() {
    return (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: PRIMARY_COLOR,
            primary50: `${PRIMARY_COLOR}80`,
            primary25: `${PRIMARY_COLOR}40`,
        },
    });
}

function createPlainSelect(styles, { isMobile }) {
    const { placeholder, ...rest } = styles;
    return {
        container: (styles) => ({
            ...styles,
            width: "100%",
        }),
        control: (styles) => ({
            ...styles,
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
        }),
        menu: (styles) => ({ ...styles, position: "absolute" }),
        input: (styles) => ({ ...styles }),
        valueContainer: (styles) => ({
            ...styles,
            overflow: "auto",
            scrollbarColor: "#f6f7f9 white",
            scrollbarWidth: "thin",
            maxHeight: 45,
            width: "100%",
            padding: 0,
        }),
        dropdownIndicator: (styles) => ({ ...styles, display: "none" }),
        indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
        placeholder: (styles) => ({
            ...styles,
            color: SECONDARY_TEXT_COLOR,
            opacity: 1,
            fontSize: isMobile ? 12 : 16,
            ...placeholder,
        }),
        ...rest,
    };
}

function createSelect(styles, { hasError, noBorders, transparent }) {
    const {
        container,
        control,
        placeholder,
        valueContainer,
        singleValue,
        menu,
        menuPortal,
        ...rest
    } = styles;

    return {
        container: (provided) => ({
            ...provided,
            width: "100%",
            ...container,
        }),
        control: (provided) => ({
            ...provided,
            minHeight: transparent ? null : 53,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: hasError ? PRIMARY_COLOR : BORDER_COLOR,
            border: noBorders ? "none" : null,
            boxShadow: "none",
            transition: "border-color 0.08s ease",
            "&:hover": {
                borderColor: hasError ? PRIMARY_COLOR : BORDER_COLOR,
                border: noBorders ? "none" : null,
            },
            "&:focus": {
                borderColor: hasError ? PRIMARY_COLOR : PRIMARY_COLOR,
                border: noBorders ? "none" : null,
            },
            background: transparent ? "transparent" : provided.background,
            borderRadius: 0,
            ...control,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: SECONDARY_TEXT_COLOR,
            opacity: 1,
            fontSize: 16,
            ...placeholder,
        }),
        indicatorSeparator: () => ({ display: "none" }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "7px 14px",
            ...valueContainer,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display:
                state.selectProps.menuIsOpen && state.selectProps.isSearchable
                    ? "none"
                    : "block",
            ...singleValue,
        }),
        menu: (provided) => ({
            ...provided,
            ...menu,
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 10000,
            ...menuPortal,
        }),
        ...rest,
    };
}
