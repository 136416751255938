import { combineReducers } from "redux";

import DashboardConstants from "../../_constants/actions/DashboardConstants";
import { ReducerHelpers } from "../../_helpers";

const state = (state, action) =>
    ReducerHelpers.loading(state, action, DashboardConstants.GET_DATA);

export default combineReducers({ data, state });

function data(state = null, action) {
    if (action.type === DashboardConstants.GET_DATA_SUCCESS) {
        return action.payload;
    }

    return state;
}
