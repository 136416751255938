import React, { useState, useRef } from "react";
import { BaseTooltip } from "../../../../common/components/charts/BaseTooltip";

export function GroupBarChart(props) {
  const { data } = props;

  const [hovered, setHovered] = useState(null);

  return (
    <div className="group-bar-chart">
      {data.map((item, i) => (
        <Item key={i} item={item} index={i} />
      ))}
      <div className="group-bar-chart--item">
        {/*empty offset element*/}
        <div className="group-bar-chart--label" />
        <div className="group-bar-chart--range">
          <span>0</span>
          <span>20</span>
          <span>40</span>
          <span>60</span>
          <span>80</span>
          <span>100</span>
        </div>
      </div>
    </div>
  );

  function Item(props) {
    const { item, index } = props;
    const fill = item.value;
    const ref = useRef(null);

    return (
      <div
        key={index}
        className="group-bar-chart--item"
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered(null)}
        ref={ref}
      >
        <div className="group-bar-chart--label">{item.name}</div>
        <div className="group-bar-chart--bar">
          <div
            className="group-bar-chart--fill"
            style={{ width: `${fill}%`, background: item.color }}
          />
          {index === hovered ? (
            <BaseTooltip title={item.description} parentRef={ref} />
          ) : null}
        </div>
      </div>
    );
  }
}
