import { createSelector } from "reselect";

import { STATE_LOADING } from "../../common/_constants/state.constants";

const search = (state) => state.candidate.careerSearch;

const getJobRoles = createSelector(search, (search) => search.jobRoles);

const isLoading = createSelector(
    search,
    (search) =>
        search.state === STATE_LOADING &&
        (!search.jobRoles || search.jobRoles.length < 1)
);

const CareerSearchSelectors = {
    getJobRoles,
    isLoading,
};

export default CareerSearchSelectors;
