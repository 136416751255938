import {
    NOTIFICATION_TYPE_MESSAGE,
    NOTIFICATION_TYPE_ERROR,
    NOTIFICATION_TYPE_WARNING,
    NOTIFICATION_PUSH,
    NOTIFICATION_POP,
    NOTIFICATION_TYPE_SUCCESS,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_VIEWS,
    GET_NOTIFICATIONS_COUNT,
    GET_NOTIFICATIONS_VIEWS_COUNT,
    NOTIFICATIONS_CHANGE_PAGE,
} from '../_constants/actions/notification.constants';

export const NotificationActions = {
    push,
    pushSuccess,
    pushWarning,
    pushMessage,
    pushError,
    pop,
    getNotifications,
    getNotificationViews,
    getNotificationsCount,
    getNotificationViewsCount,
    changePage,
};

function push(message, type) {
    const timestamp = Date.now();
    return {
        type: NOTIFICATION_PUSH,
        payload: {
            message,
            type,
            timestamp,
        },
    };
}

function pushSuccess(message) {
    return push(message, NOTIFICATION_TYPE_SUCCESS);
}

function pushMessage(message) {
    return push(message, NOTIFICATION_TYPE_MESSAGE);
}

function pushWarning(message) {
    return push(message, NOTIFICATION_TYPE_WARNING);
}

function pushError(message) {
    return push(message, NOTIFICATION_TYPE_ERROR);
}

function pop(timestamp) {
    return { type: NOTIFICATION_POP, payload: timestamp };
}

function getNotifications(page) {
    return { type: GET_NOTIFICATIONS, payload: page };
}

function getNotificationViews(page) {
    return { type: GET_NOTIFICATIONS_VIEWS, payload: page };
}

function getNotificationsCount() {
    return { type: GET_NOTIFICATIONS_COUNT };
}

function getNotificationViewsCount() {
    return { type: GET_NOTIFICATIONS_VIEWS_COUNT };
}

function changePage(page) {
    return { type: NOTIFICATIONS_CHANGE_PAGE, payload: page };
}
