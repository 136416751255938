import { combineReducers } from "redux";
import { GET_PROFILE_TYPE_SUCCESS } from "../../../common/_actions/ProfileActions";
import OnboardingConstants from "../../../common/_constants/actions/OnboardingConstants";

import ProfileConstants from "../../_constants/actions/ProfileConstants";

const contact = combineReducers({ emails, phone });

export default combineReducers({ names, photo, locations, contact, cities });

function names(
    state = { firstName: null, lastName: null, middleName: null },
    action
) {
    switch (action.type) {
        case ProfileConstants.GET_PROFILE_SUCCESS:
            const { firstName, lastName, middleName } = action.payload;
            return { ...state, firstName, lastName, middleName };
        case OnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
            };
        default:
            return state;
    }
}

function photo(
    state = { originalImageUrl: null, imageUrl: null, imageFileName: null },
    action
) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        const { originalImageUrl, imageUrl, imageFileName } = action.payload;
        return { originalImageUrl, imageUrl, imageFileName };
    }

    return state;
}

function locations(state = [], action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        const { locations } = action.payload;
        return locations;
    }

    return state;
}

function cities(state = [], action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        const { locationCities } = action.payload;
        return locationCities;
    }

    return state;
}

function emails(state = [], action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        return action.payload.emails;
    }

    return state;
}

function phone(state = [], action) {
    if (action.type === ProfileConstants.GET_PROFILE_SUCCESS) {
        return action.payload.phoneNumbers;
    }

    return state;
}
