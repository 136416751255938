const HeroOnboardingConstants = {
    SAVE_USER: "HEROES__SAVE_USER",
    SAVE_USER_SUCCESS: "HEROES__SAVE_USER_SUCCESS",
    SAVE_USER_FAIL: "HEROES__SAVE_USER_FAIL",

    SAVE_PERSONAL: "HEROES__SAVE_PERSONAL",
    SAVE_PERSONAL_SUCCESS: "HEROES__SAVE_PERSONAL_SUCCESS",
    SAVE_PERSONAL_FAIL: "HEROES__SAVE_PERSONAL_FAIL",
    SET_PERSONAL_IMAGE: "HEROES__SET_PERSONAL_IMAGE",
    SET_PERSONAL_IMAGE_DATA: "HEROES__SET_PERSONAL_IMAGE_DATA",
    CHANGE_PERSONAL_VALUE: "HEROES__CHANGE_PERSONAL_VALUE",

    SAVE_POSITIONS: "HEROES__SAVE_POSITIONS",
    SAVE_POSITIONS_SUCCESS: "HEROES__SAVE_POSITIONS_SUCCESS",
    SAVE_POSITIONS_FAIL: "HEROES__SAVE_POSITIONS_FAIL",

    SAVE_SKILLS: "HEROES__SAVE_SKILLS",
    SAVE_SKILLS_SUCCESS: "HEROES__SAVE_SKILLS_SUCCESS",
    SAVE_SKILLS_FAIL: "HEROES__SAVE_SKILLS_FAIL",

    SAVE_ADVICE: "HEROES__SAVE_ADVICE",
    SAVE_ADVICE_SUCCESS: "HEROES__SAVE_ADVICE_SUCCESS",
    SAVE_ADVICE_FAIL: "HEROES__SAVE_ADVICE_FAIL",

    GET_QUESTIONS: "HEROES__GET_QUESTIONS",
    GET_QUESTIONS_SUCCESS: "HEROES__GET_QUESTIONS_SUCCESS",
    GET_QUESTIONS_FAIL: "HEROES__GET_QUESTIONS_FAIL",

    GET_TEST: "HEROES__GET_TEST",
    GET_TEST_SUCCESS: "HEROES__GET_TEST_SUCCESS",
    GET_TEST_FAIL: "HEROES__GET_TEST_FAIL",

    GET_STATUS: "HEROES__GET_STATUS",
    GET_STATUS_SUCCESS: "HEROES__GET_STATUS_SUCCESS",
    GET_STATUS_FAIL: "HEROES__GET_STATUS_FAIL",

    COMPLETE_TEST: "HEROES__COMPLETE_TEST",
    COMPLETE_TEST_SUCCESS: "HEROES__COMPLETE_TEST_SUCCESS",
    COMPLETE_TEST_FAIL: "HEROES__COMPLETE_TEST_FAIL",

    SET_MORE_OPTIONS: "HEROES__SET_MORE_OPTIONS",
    START_TEST: "HEROES__START_TEST",

    UPLOAD_VIDEO: "HEROES__UPLOAD_VIDEO",
    UPLOAD_VIDEO_SUCCESS: "HEROES__UPLOAD_VIDEO_SUCCESS",
    UPLOAD_VIDEO_FAIL: "HEROES__UPLOAD_VIDEO_FAIL",
    UPLOAD_VIDEO_CANCEL: "HEROES__UPLOAD_VIDEO_CANCEL",

    UPLOAD_RECORDED_VIDEO: "HEROES__UPLOAD_RECORDED_VIDEO",
    UPLOAD_RECORDED_VIDEO_SUCCESS: "HEROES__UPLOAD_RECORDED_VIDEO_SUCCESS",
    UPLOAD_RECORDED_VIDEO_FAIL: "HEROES__UPLOAD_RECORDED_VIDEO_FAIL",

    VIDEO_SKIP: "HEROES__VIDEO_SKIP",
    VIDEO_SKIP_SUCCESS: "HEROES__VIDEO_SKIP_SUCCESS",
    VIDEO_SKIP_FAIL: "HEROES__VIDEO_SKIP_FAIL",

    CANCEL_VIDEO_UPLOAD: "HEROES__CANCEL_VIDEO_UPLOAD",
    VIDEO_UPLOAD_PROGRESS: "HEROES__VIDEO_UPLOAD_PROGRESS",

    VIDEO_INFO_SEEN: "HEROES__VIDEO_INFO_SEEN",
    ADD_STEP: "HEROES__ADD_STEP",
    CHANGE_STEP: "HEROES__CHANGE_STEP",
};

export default HeroOnboardingConstants;
