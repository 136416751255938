const CacheService = {
    get,
    set,
    remove,
};

function get(key: string) {
    const item = sessionStorage.getItem(key);
    const now = Date.now();
    const data = JSON.parse(item);
    if (now > data.time + data.life) {
        remove(key);
        return null;
    }

    return item;
}

function set(key: string, value: string, life: number = 1800000) {
    sessionStorage.setItem(
        key,
        JSON.stringify({ value, time: Date.now(), life })
    );
}

function remove(key: string) {
    sessionStorage.removeItem(key);
}

export default CacheService;
