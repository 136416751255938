export const GET_INTERPRETATION_DETAIL = 'GET_INTERPRETATION_DETAIL';
export const GET_INTERPRETATION_DETAIL_SUCCESS =
    'GET_INTERPRETATION_DETAIL_SUCCESS';
export const GET_INTERPRETATION_DETAIL_FAIL = 'GET_INTERPRETATION_DETAIL_FAIL';

const InterpretationActions = {
    getInterpretation,
    getInterpretationSuccess,
    getInterpretationFail,
};

function getInterpretation(profileId, name) {
    return {
        type: GET_INTERPRETATION_DETAIL,
        payload: { profileId, name: name.toUpperCase() },
    };
}

function getInterpretationSuccess(name, data) {
    return { type: GET_INTERPRETATION_DETAIL_SUCCESS, payload: { name, data } };
}

function getInterpretationFail(error) {
    return { type: GET_INTERPRETATION_DETAIL_FAIL, error };
}

export default InterpretationActions;
