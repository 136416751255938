import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ICompany from "../../common/types/ICompany";
import IPaginationState from "../../common/types/IPaginationState";
import { NotificationActions } from "../../common/_actions";
import CompanyService, {
    GetCompanyListPayload,
} from "../_services/CompanyService2";

const NAME = "COMPANY";

const getCompanies = createAsyncThunk(
    `${NAME}/getCompanies`,
    (payload: GetCompanyListPayload, thunk) =>
        CompanyService.getList(payload, (error) =>
            thunk.dispatch(NotificationActions.pushError(error.message))
        )
);

type State = {
    list: {
        data: ICompany[];
        loading: boolean;
        pagination: IPaginationState;
        filters: { title: string };
    };
};

const initialState: State = {
    list: {
        data: [],
        loading: false,
        pagination: { page: 0, pages: 0, elements: 0 },
        filters: { title: "" },
    },
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<{ title: string }>) => {
            state.list.filters = {
                ...state.list.filters,
                title: action.payload.title,
            };
        },
        changePage: (state, action: PayloadAction<number>) => {
            state.list.pagination.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanies.pending, (state) => {
            state.list.loading = true;
        });
        builder.addCase(getCompanies.fulfilled, (state, action) => {
            state.list.loading = false;
            const { content, totalElements, totalPages, pageable } =
                action.payload;
            state.list.data = content;
            state.list.pagination = {
                elements: totalElements,
                pages: totalPages,
                page: pageable.pageNumber,
            };
        });
        builder.addCase(getCompanies.rejected, (state) => {
            state.list.loading = false;
        });
    },
});

const CompanyActions = { getCompanies, ...slice.actions };

export const CompanyReducer = slice.reducer;

export default CompanyActions;
