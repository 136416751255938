import { PropsWithChildren } from "react";

import EditTable, {
    EditTableProps,
} from "../../../common/components/EditTable/EditTable";
import EditDialog, {
    EditDialogProps,
} from "../../../common/components/dialogs/EditDialog/EditDialog";
import Button from "../../../common/components/buttons/Button/Button";

export interface BaseEditProps<T> extends EditTableProps<T>, EditDialogProps {
    onCreate?: () => void;
    createButtonText?: string;
    dialogClassName?: string;
}

export default function BaseEdit<T>(
    props: PropsWithChildren<BaseEditProps<T>>
) {
    const {
        data,
        createItem,
        children,
        onEdit,
        onCreate,
        onDelete,
        title,
        isVisible,
        onSave,
        onCancel,
        head,
        cols,
        createButtonText,
        dialogClassName,
        getItemName
    } = props;

    return (
        <>
            <EditTable<T>
                cols={cols}
                head={head}
                data={data}
                createItem={createItem}
                onEdit={onEdit}
                onDelete={onDelete}
                getItemName={getItemName}
            />
            {onCreate ? (
                <Button
                    style={{ margin: "20px 0 0 auto" }}
                    onClick={onCreate}
                    inverted
                    data-test="base-edit-add-button"
                >
                    {createButtonText || "Add"}
                </Button>
            ) : null}
            <EditDialog
                title={title}
                isVisible={isVisible}
                onSave={onSave}
                onCancel={onCancel}
                onClose={onCancel}
                style={{ minWidth: 600 }}
                className={dialogClassName}
                data-test="base-edit-dialog"
            >
                {children}
            </EditDialog>
        </>
    );
}
