import { combineReducers } from 'redux';

import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';
import { ReducerHelpers } from '../../_helpers';

function data(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.GET_MAP_TOOLTIP:
            return null;
        case CareerPathsConstants.GET_MAP_TOOLTIP_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const state = (state, action) =>
    ReducerHelpers.loading(state, action, CareerPathsConstants.GET_MAP_TOOLTIP);

export default combineReducers({ data, state });
