import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";

import Field from "../../../../common/components/input/Field/Field";
import LocationsEdit from "../../../../common/components/LocationsEdit/LocationsEdit";
import IAddress from "../../../../common/types/IAddress";
import ILocation from "../../../../common/types/ILocation";
import CompanyProfileActions from "../../../../common/_actions/CompanyProfileActions";
import UploadButton from "../../AdminJobEdit/parts/UploadButton";
import AdminEdit from "../AdminEdit";

type LocationsEditPanelProps = {
    id: number;
    locations: ILocation[];
    isLoading?: boolean;
    saving: boolean;
};

const LOCATION_FIELD = "locations";

type Values = {
    [LOCATION_FIELD]: IAddress[];
};

export default function LocationsEditPanel(props: LocationsEditPanelProps) {
    const { id, locations, isLoading, saving } = props;
    const dispatch = useDispatch();
    const translate = useTranslate("admin.profile.locations");
    const translateForm = useTranslate("admin.profile.locations.form");

    const value = useMemo(
        () =>
            locations
                ? locations.map((item) => ({
                      placeId: item.id,
                      title: item.text,
                  }))
                : [],
        [locations]
    );

    return (
        <AdminEdit.Panel
            heading={translate("title")}
            subtitle={translate("subtitle")}
            anchor="locations"
            // isLoading={isLoading}
        >
            <Formik<Values>
                initialValues={{ [LOCATION_FIELD]: value }}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <Form>
                    <Field label={translateForm("address.label")}>
                        <LocationsEdit
                            name={LOCATION_FIELD}
                            placeholder={translateForm("address.placeholder")}
                            isLoading={isLoading}
                        />
                    </Field>
                    <UploadButton sending={saving} />
                </Form>
            </Formik>
        </AdminEdit.Panel>
    );

    function handleSubmit(values: Values) {
        if (isLoading) {
            return;
        }
        const addresses = values[LOCATION_FIELD];

        dispatch(
            CompanyProfileActions.saveLocations({
                id,
                locationsId: addresses.map((item) => item.placeId),
            })
        );
    }
}
