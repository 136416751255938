import { useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useTranslate } from "react-translate.ts";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { OnboardingButtons, OnboardingForm } from "../../../components";
import { HERO_ONBOARDING_PATH } from "../../../_constants/route.constants";
import { useDispatch, useSelector } from "react-redux";
import HeroOnboardingActions from "../../../_actions/HeroOnboarding/HeroOnboardingActions";
import { AuthSelectors } from "../../../_selectors";
import PasswordInput from "../../../components/input/PasswordInput/PasswordInput";
import FormInput from "../../../components/input/FormInput";
import Field from "../../../components/input/Field/Field";
import FormCheckbox from "../../../components/input/FormCheckbox";
import SignupConsent from "../../../components/SignupConsent/SignupConsent";
import OnboardingPageLayout from "../../../components/layout/OnboardingPageLayout/OnboardingPageLayout";
import OnboardingLayout from "../../Auth/Onboarding/OnboardingLayout/OnboardingLayout";

const EMAIL_FIELD = "email";
const PASS_FIELD = "password";
const CONSENT_FIELD = "consent";

type Values = {
    [EMAIL_FIELD]: string;
    [PASS_FIELD]: string;
    [CONSENT_FIELD]: boolean;
};

export function HeroSignUp() {
    const dispatch = useDispatch();
    const history = useHistory();

    const isSigned = useSelector(AuthSelectors.isSigned);

    useEffect(() => {
        if (isSigned) {
            history.replace(HERO_ONBOARDING_PATH);
        }
    }, [isSigned, history]);

    return (
        <OnboardingPageLayout>
            <OnboardingLayout
                color="red"
                imageProps={{ src: "/img/heroes/onboard/signup.svg" }}
            >
                <Formik<Values>
                    initialValues={{
                        [EMAIL_FIELD]: "",
                        [PASS_FIELD]: "",
                        [CONSENT_FIELD]: false,
                    }}
                    isInitialValid={false}
                    onSubmit={submit}
                    validationSchema={getValidation}
                    validateOnMount
                >
                    <Content />
                </Formik>
            </OnboardingLayout>
        </OnboardingPageLayout>
    );

    function submit(values: Values) {
        const email = values[EMAIL_FIELD];
        const pass = values[PASS_FIELD];

        dispatch(HeroOnboardingActions.SignUp.saveUser(email, pass));
    }

    function getValidation() {
        return Yup.object().shape({
            [EMAIL_FIELD]: Yup.string().email("Invalid email").required(),
            [PASS_FIELD]: Yup.string().required(),
            [CONSENT_FIELD]: Yup.mixed().oneOf([true]),
        });
    }
}

function Content() {
    const translate = useTranslate("common.heroOnboarding.steps.signUp");
    const translateForm = useTranslate(
        "common.heroOnboarding.steps.signUp.form"
    );

    const { submitForm, isValid } = useFormikContext();

    return (
        <>
            <OnboardingForm>
                <h1 className="font-light">{translate("title")}</h1>
                <InnerForm translate={translateForm} />
            </OnboardingForm>
            <OnboardingButtons>
                <OnboardingButtons.Next
                    disabled={!isValid}
                    onClick={submitForm}
                />
            </OnboardingButtons>
        </>
    );
}

function InnerForm(props: { translate: any }) {
    const { translate } = props;

    return (
        <Form>
            <Field label={translate("email.label")} required>
                <FormInput
                    name={EMAIL_FIELD}
                    type="text"
                    placeholder={translate("email.placeholder")}
                />
            </Field>
            <Field label={translate("pass.label")} required>
                <PasswordInput
                    name={PASS_FIELD}
                    required
                    placeholder={translate("pass.placeholder")}
                />
            </Field>
            <Field>
                <FormCheckbox
                    name={CONSENT_FIELD}
                    label={<SignupConsent />}
                />
            </Field>
        </Form>
    );
}
