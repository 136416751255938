import { FieldHookConfig, useField } from "formik";
import Checkbox, { CheckboxProps } from "./Checkbox/Checkbox";
import styles from "./Input/Input.module.scss";

interface FormCheckboxProps<T extends boolean> extends CheckboxProps {
    fieldConfig?: FieldHookConfig<T>;
}

export default function FormCheckbox<T extends boolean>(
    props: FormCheckboxProps<T>
) {
    const { name, fieldConfig = {}, className, ...rest } = props;
    const [field, meta, helpers] = useField<T>({ ...fieldConfig, name });

    const { error, touched } = meta;
    const hasError = touched && !!error;
    return (
        <div className={styles.wrapper}>
            <Checkbox checked={field.value} onChange={helpers.setValue} {...rest} />
            {hasError && <div className={styles.error}>{error}</div>}
        </div>
    );
}
