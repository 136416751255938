import {
    COMPANY_PATH,
    JOBS_PATH,
} from "../../common/_constants/route.constants";

export const DASHBOARD_PATH = "/dashboard";

export const CAREER_PATHS_PATH = "/career-paths";

export const JOB_ROLES_PATH = "/job-roles";
export const JOB_ROLES_PAGE_PATH = `${JOB_ROLES_PATH}/:page(\\d+)?`;
// export const JOB_ROLES_TYPE_PATH = `${JOB_ROLES_PATH}/:type([a-z]+)`;
export const JOB_ROLES_TYPE_PATH = `${JOB_ROLES_PATH}/:id(\\d+)`;

export const JOB_ROLE_PATH = `${JOB_ROLES_PATH}/detail/:id(\\d+)`;
export const JOB_ROLE_MONITORING_PATH = `${JOB_ROLE_PATH}/monitoring`;
export const JOB_ROLE_SKILLS_PATH = `${JOB_ROLE_PATH}/skills`;
export const JOB_ROLE_DESC_PATH = `${JOB_ROLE_PATH}/description`;
export const JOB_ROLE_COMPARE_PATH = `${JOB_ROLE_PATH}/compare`;
export const JOB_ROLE_CAREER_PATH = `${JOB_ROLE_PATH}/career`;
export const JOB_ROLE_OFFERS_PATH = `${JOB_ROLE_PATH}/offers`;
export const JOB_ROLE_BENEFITS_PATH = `${JOB_ROLE_PATH}/benefits`;

export const PROFILE_PATH = `/profile`;
export const PROFILE_PATH_INFO = `${PROFILE_PATH}/information`;
export const PROFILE_SOFT_SKILLS_PATH = `${PROFILE_PATH}/soft-skills`;
export const PROFILE_HARD_SKILLS_PATH = `${PROFILE_PATH}/hard-skills`;
export const PROFILE_HARD_SKILLS_EDIT_PATH = `${PROFILE_HARD_SKILLS_PATH}/edit`;
export const PROFILE_PREFERENCES_PATH = `${PROFILE_PATH}/preferences`;
export const PROFILE_CONTACT_PATH = `${PROFILE_PATH}/contact`;

export const PROFILE_ABOUT_ME_PATH = `${PROFILE_PATH_INFO}/about-me`;
export const PROFILE_EXPERIENCE_PATH = `${PROFILE_PATH_INFO}/experience`;
export const PROFILE_EXPERIENCE_EDIT_PATH = `${PROFILE_EXPERIENCE_PATH}/:id?`;

export const PROFILE_EDUCATION_PATH = `${PROFILE_PATH_INFO}/education`;
export const PROFILE_EDUCATION_EDIT_PATH = `${PROFILE_EDUCATION_PATH}/:id?`;
export const PROFILE_LANGUAGES_PATH = `${PROFILE_PATH_INFO}/languages`;
export const PROFILE_INTERESTS_PATH = `${PROFILE_PATH_INFO}/interests`;

export const PROFILE_AVATAR_EDIT_PATH = `${PROFILE_PATH}/upload-photo`;

export const INTERPRETATION_PATH = "/interpretation";
export const INTERPRETATION_MOP_PATH = `${INTERPRETATION_PATH}/mop`;
export const INTERPRETATION_HOFIK_PATH = `${INTERPRETATION_PATH}/hofik`;
export const INTERPRETATION_KAKO_PATH = `${INTERPRETATION_PATH}/kako`;

export const APPLICATIONS_TRACKING_PATH = "/my-applications";
export const APPLICATIONS_TRACKING_REJECTED_PATH = `${APPLICATIONS_TRACKING_PATH}/rejected`;
export const APPLICATIONS_TRACKING_ARCHIVED_PATH = `${APPLICATIONS_TRACKING_PATH}/archived`;
export const APPLICATIONS_TRACKING_SENT_PATH = `${APPLICATIONS_TRACKING_PATH}/sent`;
export const APPLICATIONS_TRACKING_SHORTLIST_PATH = `${APPLICATIONS_TRACKING_PATH}/shortlist`;
export const APPLICATIONS_TRACKING_ACCEPTED_PATH = `${APPLICATIONS_TRACKING_PATH}/accepted`;

export const SOFT_SKILL_TEST_PATH = "/soft-skill-tests";
export const SOFT_SKILL_TEST_PARAM_PATH = `${SOFT_SKILL_TEST_PATH}/:test`;
export const SOFT_SKILL_TEST_MOP_PATH = `${SOFT_SKILL_TEST_PATH}/mop`;
export const SOFT_SKILL_TEST_KAKO_PATH = `${SOFT_SKILL_TEST_PATH}/kako`;
export const SOFT_SKILL_TEST_PRESS_PATH = `${SOFT_SKILL_TEST_PATH}/press`;
export const SOFT_SKILL_TEST_CULTURE_PATH = `${SOFT_SKILL_TEST_PATH}/hofik`;
export const SOFT_SKILL_TEST_ABSTRACT_PATH = `${SOFT_SKILL_TEST_PATH}/abs`;
export const SOFT_SKILL_TEST_NUMERIC_PATH = `${SOFT_SKILL_TEST_PATH}/num`;

export const SOFT_SKILL_TEST_INTERPRETATION_MOP_PATH = `${SOFT_SKILL_TEST_MOP_PATH}/interpretation`;
export const SOFT_SKILL_TEST_INTERPRETATION_CULTURE_PATH = `${SOFT_SKILL_TEST_CULTURE_PATH}/interpretation`;

const COMPARE = "/compare";
export const SOFT_SKILL_TEST_COMPARE_MOP_PATH = `${SOFT_SKILL_TEST_MOP_PATH}${COMPARE}`;
export const SOFT_SKILL_TEST_COMPARE_KAKO_PATH = `${SOFT_SKILL_TEST_KAKO_PATH}${COMPARE}`;
export const SOFT_SKILL_TEST_COMPARE_PRESS_PATH = `${SOFT_SKILL_TEST_PRESS_PATH}${COMPARE}`;
export const SOFT_SKILL_TEST_COMPARE_CULTURE_PATH = `${SOFT_SKILL_TEST_CULTURE_PATH}${COMPARE}`;
export const SOFT_SKILL_TEST_COMPARE_ABSTRACT_PATH = `${SOFT_SKILL_TEST_ABSTRACT_PATH}${COMPARE}`;
export const SOFT_SKILL_TEST_COMPARE_NUMERIC_PATH = `${SOFT_SKILL_TEST_NUMERIC_PATH}${COMPARE}`;

export const HEROES_PATH = "/heroes";
export const HEROES_LIST_PATH = `${HEROES_PATH}/:page(\\d+)?`;
export const HEROES_RECENT_PATH = `${HEROES_PATH}/recent`;
export const HEROES_ALL_PATH = `${HEROES_PATH}/all`;

export const HEROES_PROFILE_PATH = `${HEROES_PATH}/profile/:id(\\d+)`;
export const HEROES_PROFILE_CAREER_PATH = `${HEROES_PROFILE_PATH}/career`;
export const HEROES_PROFILE_MATCH_PATH = `${HEROES_PROFILE_PATH}/match`;

export const CAREER_SEARCH_PATH = "/career-search";
export const CAREER_SEARCH_MAP_PATH = "/career-search/map/:targetId";

export const USER_NAV_LINKS = [
    {
        to: CAREER_SEARCH_PATH,
        label: "careerSearch",
        icon: "search",
        size: 24,
    },
    {
        to: CAREER_PATHS_PATH,
        label: "career",
        icon: "wheel",
        size: 26,
    },
    {
        to: DASHBOARD_PATH,
        label: "dashboard",
        icon: "summary",
    },
    {
        to: SOFT_SKILL_TEST_PATH,
        label: "tests",
        icon: "idea",
        size: 24,
    },
    {
        to: JOB_ROLES_PATH,
        label: "jobRoles",
        icon: "bag-light",
        size: 20,
    },
    {
        to: HEROES_PATH,
        label: "heroes",
        icon: "hero",
        size: 26,
    },
    {
        to: PROFILE_PATH,
        label: "profile",
        icon: "head",
        size: 24,
    },
    {
        to: JOBS_PATH,
        label: "jobs",
        icon: "chair",
    },
    {
        to: APPLICATIONS_TRACKING_PATH,
        label: "tracking",
        icon: "track",
    },
    {
        to: COMPANY_PATH,
        label: "companies",
        icon: "company",
    },
];

export const USER_MOBILE_NAV_LINKS = [
    {
        to: CAREER_PATHS_PATH,
        label: "career",
        icon: "wheel",
        size: 25,
    },
    {
        to: DASHBOARD_PATH,
        label: "dashboard",
        icon: "summary",
        size: 20,
    },
    {
        to: SOFT_SKILL_TEST_PATH,
        label: "tests",
        icon: "idea",
        size: 21,
    },
    {
        to: JOB_ROLES_PATH,
        label: "jobRoles",
        icon: "bag-light",
        size: 19,
    },
    {
        to: HEROES_PATH,
        label: "heroes",
        icon: "hero",
        size: 23,
    },
    {
        to: PROFILE_PATH,
        label: "profile",
        icon: "head",
        size: 21,
    },
    {
        to: JOBS_PATH,
        label: "jobs",
        icon: "chair",
    },
    {
        to: APPLICATIONS_TRACKING_PATH,
        label: "tracking",
        icon: "track",
    },
    {
        to: COMPANY_PATH,
        label: "companies",
        icon: "company",
    },
];
