import React from 'react';

export function FlexContainer({
    children,
    spacing,
    justify,
    wrap,
    alignItems,
    className,
    style,
    ...rest
}) {
    return (
        <div
            {...rest}
            className={createClassName()}
            style={{
                justifyContent: justify,
                flexWrap: wrap,
                alignItems,
                ...style
            }}
        >
            {children}
        </div>
    );

    function createClassName() {
        return `flex-container ${spacing ? `flex-spacing-${spacing}` : ''} ${
            justify ? `justify-${justify}` : ''
        } ${className ? className : ''}`;
    }
}
