import { combineReducers } from "redux";

import map from "./map.reducer";
import routing from "./routing.reducer";
import tooltip from "./tooltip.reducer";
import detail from "./detail.reducer";
import select from "./select.reducer";
import search from "./search.reducer";
import options from "./options.reducer";
import categories from "./categories.reducer";

export default combineReducers({
    map,
    routing,
    tooltip,
    detail,
    select,
    search,
    options,
    categories,
});
