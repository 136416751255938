import { createSelector } from 'reselect';

const search = (state) => state.admin.candidates.search;
const cards = (state) => state.admin.candidates.search.cards;
const saved = (state) => state.admin.candidates.search.saved;
const pagination = createSelector(search, (search) => search.pagination);

const getCards = createSelector(cards, (values) => values.valueSeq().toArray());
const getCard = (state, profileId) => makeGetCard(state, profileId);

const getSaved = createSelector(saved, (values) => values.valueSeq().toArray());

const isInviteLoading = createSelector(
    search,
    ({ inviteLoading }) => inviteLoading
);

const getCardsPagination = createSelector(
    pagination,
    (pagination) => pagination.cards
);
const getSavedPagination = createSelector(
    pagination,
    (pagination) => pagination.saved
);

const getFilters = createSelector(search, ({ filters }) => filters);

export const CandidateSearchSelectors = {
    getCards,
    getCard,
    getSaved,
    getCardsPagination,
    getSavedPagination,
    isInviteLoading,
    getFilters,
};

function makeGetCard(state, profileId) {
    return createSelector(cards, (cards) => cards.get(profileId))(state);
}
