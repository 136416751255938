import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    FlexContainer,
    FlexItem,
    Loading,
    IntegratedDialog,
} from "../../../../../common/components";
import { SkillEdit } from "../SkillEdit";
import { PROFILE_PATH_INFO } from "../../../../_constants/route.constants";
import { FormButtons } from "../FormButtons";
import { AutocompleteService } from "../../../../_services";
import { Translate, useTranslate } from "react-translate.ts";
import { AsyncSelect } from "../../../../../common/components/input/AsyncSelect";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import ProfileInformationSelectors from "../../../../_selectors/ProfileInformationSelectors";
import { LanguageSelectors } from "../../../../../common/_selectors/LanguageSelectors";
import { LanguageActions } from "../../../../../common/_actions";
import { ProfileInformationActions } from "../../../../_actions/ProfileInformationActions";

let oldLanguages = null;

export function LanguagesEdit() {
    const history = useHistory();

    const { languages, levels, isLoading, profileId } = useSelector(mapState);

    const {
        addLanguage,
        updateLanguage,
        saveLanguages,
        deleteLanguage,
        revertLanguages,
        getLanguageLevels,
    } = mapDispatch(useDispatch());

    useEffect(() => {
        oldLanguages = languages;
        getLanguageLevels();
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    return render();
    function render() {
        if (isLoading) {
            return <Loading />;
        }

        return (
            <IntegratedDialog onClose={cancel}>
                <div id="language-edit" className="profile-edit">
                    <FlexContainer spacing={4}>
                        <FlexItem size={12}>
                            <FlexContainer spacing={1} className="text-center">
                                <FlexItem size={12}>
                                    <h4>
                                        <Translate textOnly>
                                            candidate.profile.information.language.edit.heading
                                        </Translate>
                                    </h4>
                                </FlexItem>
                            </FlexContainer>
                        </FlexItem>
                        <FlexItem size={12}>
                            <FlexContainer spacing={2} alignItems="center">
                                <FlexItem size={12}>
                                    <LanguageSearch />
                                </FlexItem>
                            </FlexContainer>
                        </FlexItem>
                        <FlexItem size={12}>
                            <FlexContainer>
                                {languages.map(renderLanguage)}
                            </FlexContainer>
                        </FlexItem>
                        <FlexItem size={12}>
                            <FormButtons
                                onCancel={cancel}
                                onConfirm={confirm}
                                confirmText="common.actions.save"
                            />
                        </FlexItem>
                    </FlexContainer>
                </div>
            </IntegratedDialog>
        );
    }

    function LanguageSearch() {
        const translate = useTranslate(
            "candidate.profile.information.language.search"
        );
        return (
            <AsyncSelect
                loadOptions={AutocompleteService.languages}
                placeholder={translate("placeholder")}
                loadingMessage={() => translate("loading")}
                getOptionValue={(value) => value.id}
                getOptionLabel={(value) => value.name}
                value={null}
                onChange={onSkillSelect}
            />
        );
    }

    function renderLanguage(item, index) {
        const { language, level } = item;
        const data = [
            {
                value: level.id,
                customRange: levels.map(({ id, name, description }) => ({
                    value: id,
                    label: name + " - " + description,
                })),
                updateValue: (skill, value) => ({
                    ...skill,
                    level: levels[value - 1],
                }),
            },
        ];
        return (
            <SkillEdit
                key={index}
                skill={item}
                name={language.name}
                skillId={language.id}
                data={data}
                onUpdate={onUpdate}
                onDelete={onDelete}
            />
        );
    }

    function cancel() {
        revertLanguages(oldLanguages);
        close();
    }

    function confirm() {
        saveLanguages(languages, profileId);
        close();
    }

    function close() {
        history.push(PROFILE_PATH_INFO);
    }

    function onUpdate(language) {
        registerAlert();

        updateLanguage(language);
    }

    function onDelete(id) {
        registerAlert();

        deleteLanguage(id);
    }

    function onSkillSelect(option) {
        registerAlert();

        addLanguage({ language: option, level: levels[0] });
    }

    function registerAlert() {
        window.onbeforeunload = () => {
            return "confirmation";
        };
    }

    function mapDispatch(dispatch) {
        return {
            saveLanguages: (languages, profileId) =>
                dispatch(
                    ProfileInformationActions.saveLanguages(
                        languages,
                        profileId
                    )
                ),
            addLanguage: (language) =>
                dispatch(ProfileInformationActions.addLanguage(language)),
            updateLanguage: (language) =>
                dispatch(ProfileInformationActions.updateLanguage(language)),
            deleteLanguage: (language) =>
                dispatch(ProfileInformationActions.deleteLanguage(language)),
            revertLanguages: (oldLanguages) =>
                dispatch(
                    ProfileInformationActions.revertLanguageChanges(
                        oldLanguages
                    )
                ),
            getLanguageLevels: () =>
                dispatch(LanguageActions.getLanguageLevels()),
        };
    }

    function mapState(state) {
        const profile = state.candidate.profile;
        const { loading } = profile.languages;

        return {
            languages: ProfileInformationSelectors.getLanguagesList(state),
            levels: LanguageSelectors.getLanguageLevels(state),
            isLoading: loading,
            profileId: AuthSelectors.getProfileId(state),
        };
    }
}
