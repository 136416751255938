import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { MainState } from "../../root.reducer";

const profile = (state: MainState) => state.common.company;

const getPending = createSelector(profile, (profile) => profile.pending);

const getDetail = createSelector(profile, (profile) => profile.detail);

const CompanyProfileSelectors = {
    getDetailData: createSelector(profile, (profile) => profile.detail.data),
    isLoading: createSelector(profile, (profile) => profile.detail.loading),
    useGetPending,
    useGetDetail
};

function useGetPending() {
    return useSelector(getPending);
}

function useGetDetail() {
    return useSelector(getDetail);
}

export default CompanyProfileSelectors;
