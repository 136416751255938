import { useRef, useState, useEffect, MutableRefObject } from "react";

export function useFileDrop<T extends HTMLElement>(
    onDrop: (files: FileList) => void
): [MutableRefObject<T | null>, boolean] {
    const dropRef = useRef<T | null>(null);

    const dragCount = useRef(0);

    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        mount();

        return unmount;
    }, []);

    function mount() {
        const div = dropRef.current;
        if (div) {
            div.addEventListener("dragenter", handleDragIn);
            div.addEventListener("dragstart", handleDragStart);
            div.addEventListener("dragleave", handleDragOut);
            div.addEventListener("dragover", handleDrag);
            div.addEventListener("drop", handleDrop);
        }
    }

    function unmount() {
        const div = dropRef.current;
        if (div) {
            div.removeEventListener("dragenter", handleDragIn);
            div.removeEventListener("dragstart", handleDragStart);
            div.removeEventListener("dragleave", handleDragOut);
            div.removeEventListener("dragover", handleDrag);
            div.removeEventListener("drop", handleDrop);
        }

        dragCount.current = 0;
    }

    function handleDrag(event: DragEvent) {
        preventDefault(event);
    }

    function handleDragStart(event: DragEvent) {
        preventDefault(event);
        event?.dataTransfer?.clearData();
    }

    function handleDragIn(event: DragEvent) {
        preventDefault(event);

        dragCount.current++;
        if (event?.dataTransfer?.items && event.dataTransfer.items.length > 0) {
            setIsDragging(true);
        }
    }

    function handleDragOut(event: DragEvent) {
        preventDefault(event);
        dragCount.current--;

        if (dragCount.current > 0) {
            return;
        }
        setIsDragging(false);
    }

    function handleDrop(event: DragEvent) {
        preventDefault(event);
        setIsDragging(false);

        if (event?.dataTransfer?.files && event.dataTransfer.files.length > 0) {
            onDrop(event.dataTransfer.files);
            dragCount.current = 0;
        }
    }

    function preventDefault(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    return [dropRef, isDragging];
}
