import { useRef, useState } from "react";
import { createClass } from "classname-helper";
import { useDispatch, useSelector } from "react-redux";

import { useFileDrop } from "../../../../../hooks";
import { Button, SVGIcon } from "../../../../../components";
import HeroOnboardingActions from "../../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import HeroOnboardingSelectors from "../../../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import styles from "./VideoUpload.module.scss";
import { cx } from "@emotion/css";
import VideoIcon from "../VideoIcon/VideoIcon";
import VideoPanel from "../VideoPanel/VideoPanel";

export function VideoUpload() {
    const fileInputRef = useRef(null);

    const [dropRef, isDraggingFile] = useFileDrop(handleFileDrop);

    const isVideoUploading = useSelector(
        HeroOnboardingSelectors.Advice.isVideoUploading
    );
    const [file, setFile] = useState();

    const dispatch = useDispatch();

    return (
        <>
            <VideoPanel
                className={styles.upload}
                contentProps={{
                    className: cx(
                        styles.content,
                        isDraggingFile && styles.drag
                    ),
                    ref: dropRef,
                }}
            >
                <VideoIcon
                    src="/img/heroes/onboard/video-upload.svg"
                    className={styles["video-icon"]}
                />
                <DropBox
                    isDragging={isDraggingFile}
                    onFileDialogOpen={openFileDialog}
                    file={file}
                />
                {file && <UploadProgress file={file} onRemove={removeFile} />}
                {/* {isVideoUploading ? <UploadProgress /> : null} */}

                {file && (
                    <Button
                        style={{ marginTop: 20 }}
                        variant="secondary"
                        disabled={isVideoUploading}
                        onClick={nextStep}
                    >
                        Next Step
                    </Button>
                )}
            </VideoPanel>
            <form>
                <input
                    name="file"
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    accept="video/*"
                    onChange={(event) => {
                        if (
                            event.target.files &&
                            event.target.files.length > 0
                        ) {
                            const file = event.target.files[0];
                            const formData = new FormData();
                            formData.append("file", file);
                            dispatch(
                                HeroOnboardingActions.Video.upload(formData)
                            );

                            setFile(file);
                        }
                    }}
                />
            </form>
        </>
    );

    function removeFile() {
        fileInputRef.current.value = null;

        setFile(null);
    }

    function nextStep() {
        if (isVideoUploading) {
            return;
        }

        dispatch(HeroOnboardingActions.addStep(1));
    }

    function handleFileDrop(files) {
        if (files && files.length > 0) {
            fileInputRef.current.files = files;

            setFile(files[0]);
        }
    }

    function openFileDialog() {
        if (!fileInputRef.current) {
            return;
        }

        fileInputRef.current.click();
    }
}

// function VideoLink() {
//     const [link, setLink] = useState();
//     return (
//         <div className="video-link">
//             <div className="title">
//                 <span></span>
//                 <span>Youtube Video</span>
//             </div>
//             <div>
//                 <input />
//             </div>
//         </div>
//     );
// }

function UploadProgress(props) {
    const { total, loaded } = useSelector(
        HeroOnboardingSelectors.Advice.getVideoUploadProgress
    );
    const isVideoUploading = useSelector(
        HeroOnboardingSelectors.Advice.isVideoUploading
    );
    return (
        <div className={styles["upload-progress"]}>
            {!isVideoUploading ? (
                <div
                    className={styles.close}
                    // onClick={() =>
                    //     dispatch({
                    //         type: HeroOnboardingConstants.CANCEL_VIDEO_UPLOAD,
                    //     })
                    // }
                    onClick={props.onRemove}
                >
                    <SVGIcon name="close" />
                </div>
            ) : null}
            <div className={styles.file}>
                File:{" "}
                <span className={styles.name}>
                    {props.file && props.file.name}
                </span>{" "}
                is
                {isVideoUploading ? " uploading..." : " done"}
            </div>
            <div className={styles.bar}>
                <div
                    className={styles.fill}
                    style={{ width: `${(loaded / total) * 100}%` }}
                />
            </div>
            {loaded}/{total}
        </div>
    );
}

function DropBox(props) {
    const { file } = props;

    return (
        <div className={styles.upload__drag}>
            <div className={styles.title}>Upload a New Video</div>
            <div className={cx(styles.desc, "font-book")}>
                Click in the button or drag and drop your file here
            </div>
            {/* {!!file ? <div>{file.name}</div> : null} */}
            {!file ? (
                <Button
                    onClick={props.onFileDialogOpen}
                    variant="secondary"
                    isPill
                >
                    Upload file
                </Button>
            ) : null}
            {props.isDragging ? (
                <div className={styles.upload__drop}>Drop your file here</div>
            ) : null}
        </div>
    );
}
