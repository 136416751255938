import React from 'react';
import { Translate } from 'react-translate.ts';

import { Button } from '../buttons';

export function MoreDaysButton({ isVisible }) {
    if (!isVisible) {
        return <div />;
    }

    return (
        <Button variant="secondary" size="small" isThin>
            <Translate textOnly params={{ count: 0 }}>
                common.actions.tracking.addDays
            </Translate>
        </Button>
    );
}
