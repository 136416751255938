import React, { useEffect, useRef, useState } from "react";

import { Diagram } from "./Diagram";
import { useDispatch, useSelector } from "react-redux";
import { CareerPathsActions } from "../../../../../_actions";
import { CareerPathsSelectors } from "../../../../../_selectors/CareerPathsSelectors";
import { PROFILE_TYPE_COLLEGE } from "../../../../../../common/_constants/profile.constants";
import { MapLoading } from "./MapLoading";
import { useLocation } from "react-router-dom";
import { UserProfileSelectors } from "../../../../../../common/_selectors";

export function DiagramWrapper(props) {
    const {
        matchScoreEnabled,
        hoveredPathIndex,
        selectedCategoryId,
        selectedSubcategoryId,
    } = props;

    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();

    const { setSelected, setPoint, addPoint, enableActiveRouteSearch, getMap } =
        mapActions(dispatch);

    const {
        map,
        points,
        selectedNode,
        searchSelected,
        industry,
        selectedPath,
        activeRouteSearch,
        profileType,
    } = useSelector(mapState);

    useEffect(() => {
        if (searchSelected) {
            getMap({ jobRoleId: searchSelected.id });
        } else if (industry) {
            getMap({
                industryId: industry.id,

                degreeFieldId: profileType.degreeFieldId,
                jobRoleId: profileType.jobRoleId,
            });
        }
    }, [searchSelected, industry]);

    useEffect(() => {
        dispatch(CareerPathsActions.getCategories());
    }, [profileType]);

    if (!map) {
        return <MapLoading />;
    }

    return (
        <div id="career-paths__diagram-wrapper" ref={wrapperRef}>
            <Diagram
                wrapperRef={wrapperRef}
                matchScoreEnabled={matchScoreEnabled}
                hoveredPathIndex={hoveredPathIndex}
                onRoute={handleRoute}
                highlightedPathIndex={selectedPath.index}
                activeRouteSearch={activeRouteSearch}
                onNodeSelect={selectNode}
                selectedNode={selectedNode}
                onIndustrySelect={(category, type) => {
                    dispatch(
                        CareerPathsActions.setSelectedCategory(
                            category.id,
                            type
                        )
                    );
                    dispatch(CareerPathsActions.setSelectedNode(null))
                }}
                selectedCategoryId={selectedCategoryId}
                selectedSubcategoryId={selectedSubcategoryId}
            />
        </div>
    );

    function selectNode(node) {
        if (!activeRouteSearch) {
            setSelected(node);
            // setInputValue(node.title);
        } else {
            for (let i = 0; i < points.size; i++) {
                const point = points.get(i);

                if (point === null) {
                    setPoint(i, node);
                    return;
                }
            }
        }
    }

    function handleRoute(node) {
        addPoint(node);
        enableActiveRouteSearch();
    }
}

function mapState(state) {
    return {
        map: CareerPathsSelectors.map.getData(state),
        points: CareerPathsSelectors.routing.getPoints(state),
        activeRouteSearch:
            CareerPathsSelectors.routing.getActiveRouteSearch(state),
        selectedNode: CareerPathsSelectors.detail.getSelectedNode(state),
        searchSelected: CareerPathsSelectors.search.getSelected(state),
        selectedPath: CareerPathsSelectors.routing.getSelectedPath(state),
        industry: CareerPathsSelectors.options.getIndustry(state),
        profileType: UserProfileSelectors.getType(state),
    };
}

function mapActions(dispatch) {
    return {
        setSelected: (node) =>
            dispatch(CareerPathsActions.setSelectedNode(node)),
        setPoint: (index, point) =>
            dispatch(CareerPathsActions.setPoint(index, point)),
        getMap: (params) => dispatch(CareerPathsActions.getMap(params)),
        addPoint: (node) => dispatch(CareerPathsActions.addPoint(node)),
        enableActiveRouteSearch: () =>
            dispatch(CareerPathsActions.setActiveRouteSearch(true)),
    };
}
