import { CareerPaths } from "../CareerPaths/CareerPaths";
import CareerPathsLayout from "../../components/layout/CareerPathsLayout/CareerPathsLayout";

export default function CareerPathsPage() {
    return (
        <CareerPathsLayout>
            <CareerPaths />
        </CareerPathsLayout>
    );
}
