import { combineReducers } from "redux";

import HeroOnboardingConstants from "../../../../_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../../_constants/actions/HeroProfileConstants";

export default combineReducers({ data, complete, image, saved });

function data(
    state = {
        firstName: "",
        lastName: "",
        introduction: "",
        linkedInUrl: "",
        image: null,
    },
    action
) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS:
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            if (action.payload) {
                const {
                    firstName,
                    lastName,
                    introduction,
                    imageUrl,
                    linkedInUrl,
                } = action.payload;

                return {
                    ...state,
                    firstName,
                    lastName,
                    introduction,
                    image: imageUrl,
                    linkedInUrl,
                };
            }

            return state;
        case HeroProfileConstants.SAVE_PROFILE_PICTURE_SUCCESS:
            return { ...state, image: action.payload };
        case HeroProfileConstants.GET_PROFILE_PICTURE_SUCCESS:
            return { ...state, image: action.payload.url };
        case HeroOnboardingConstants.CHANGE_PERSONAL_VALUE:
            const { field, value } = action.payload;
            return { ...state, [field]: value };
        default:
            return state;
    }
}

function complete(state = false, action) {
    if (action.type === HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS) {
        return true;
    }

    return state;
}

function saved(state = false, action) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_PERSONAL:
        case HeroOnboardingConstants.SAVE_PERSONAL_FAIL:
            return false;
        case HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return true;
        default:
            return state;
    }
}

function image(
    state = { convertedImage: null, originalImage: null, imageFileName: null },
    action
) {
    if (action.type === HeroProfileConstants.GET_PROFILE_PICTURE_SUCCESS) {
        const url = action.payload.url;
        return { ...state, convertedImage: url, originalImage: url };
    }

    return state;
}
