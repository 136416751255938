import { createSelector } from "reselect";

const softSkills = (state) => state.candidate.skillsNew.soft;

const getSoftSkills = createSelector(softSkills, (skills) => skills);

const getComparison = (name) =>
    createSelector(
        getSoftSkills,
        (skills) => skills.comparison.data[name.toUpperCase()]
    );

const statuses = createSelector(getSoftSkills, (skills) => skills.statuses);
const test = createSelector(getSoftSkills, (skills) => skills.test);

const getStatuses = createSelector(statuses, (statuses) => statuses.list);

const getStatus = (state, name) =>
    createSelector(getStatuses, (list) => {
        if (!name || !list) {
            return null;
        }

        return list[name.toUpperCase()];
    })(state);

const getTest = createSelector(test, (test) => test);

const SoftSkillsSelectors = {
    getSoftSkills,
    getComparison,
    getStatuses,
    getStatus,
    getTest,
};

export default SoftSkillsSelectors;
