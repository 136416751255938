import {
    GET_APPLICATIONS_ARCHIVED_SUCCESS,
    GET_APPLICATIONS_ARCHIVED_FAIL,
} from '../../../_constants/actions/applicationsTracking.constants';
import {
    TRACKING_ACCEPTED,
    TRACKING_SHORTLISTED,
    TRACKING_APPLIED,
    TRACKING_INVITED,
} from '../../../../admin/_constants/actions/tracking.constants';

const initialState = {
    [TRACKING_ACCEPTED]: [],
    [TRACKING_SHORTLISTED]: [],
    [TRACKING_APPLIED]: [],
    [TRACKING_INVITED]: [],
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_APPLICATIONS_ARCHIVED_SUCCESS:
            return Object.assign({}, state, payload);
        case GET_APPLICATIONS_ARCHIVED_FAIL:
            return initialState;
        default:
            return state;
    }
}
