import { combineReducers } from 'redux';

import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';
import { ReducerHelpers } from '../../_helpers';

function data(state = null, action) {
    switch (action.type) {
        case CareerPathsConstants.GET_MAP_DETAIL:
            return null;
        case CareerPathsConstants.GET_MAP_DETAIL_SUCCESS:
            return action.payload;
        case CareerPathsConstants.GET_MAP_PATHS_SUCCESS:
            return action.payload.map;
        default:
            return state;
    }
}

const state = (state, action) =>
    ReducerHelpers.loading(state, action, CareerPathsConstants.GET_MAP_DETAIL);

function selectedNode(state = null, action) {
    if (action.type === CareerPathsConstants.SET_SELECTED_NODE) {
        return action.payload;
    }

    return state;
}

export default combineReducers({ data, state, selectedNode });
