import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_SOFT_SKILLS,
    GET_SOFT_SKILLS_SUCCESS,
    GET_SOFT_SKILLS_FAIL,
    GET_INTERPRETATION
} from '../_constants/actions/skill.contants';
import { SkillService } from '../_services';
import { SkillActions } from '../_actions/SkillActions';

function* getSoftSkills(action) {
    try {
        const response = yield call(SkillService.getSoftSkills, action.payload);

        yield put({ type: GET_SOFT_SKILLS_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: GET_SOFT_SKILLS_FAIL, error });
    }
}

function* getSoftSkillsInterpretation(action) {
    try {
        const response = yield call(
            SkillService.getSoftSkillsInterpretation,
            action.payload
        );

        yield put(
            SkillActions.getSoftSkillsInterpretationSuccess(response.data)
        );
    } catch (error) {
        yield put(SkillActions.getSoftSkillsInterpretationFail(error));
    }
}

const skillsSaga = [
    takeLatest(GET_SOFT_SKILLS, getSoftSkills),
    takeLatest(GET_INTERPRETATION, getSoftSkillsInterpretation)
];

export default skillsSaga;
