import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";

const Positions = {
    saveData,
    setMoreOptions,
};

function saveData(positions, positions1, positions2) {
    return {
        type: HeroOnboardingConstants.SAVE_POSITIONS,
        payload: { positions, positions1, positions2 },
    };
}

function setMoreOptions(payload) {
    return { type: HeroOnboardingConstants.SET_MORE_OPTIONS, payload };
}

export default Positions;
