import { combineReducers } from 'redux';

import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';

function active(state = false, action) {
    if (action.type === CareerPathsConstants.SET_ACTIVE_SEARCH) {
        return action.payload;
    }

    return state;
}

function inputValue(state = '', action) {
    if (action.type === CareerPathsConstants.SET_INPUT_VALUE) {
        return action.payload;
    }

    return state;
}

function selected(state = null, action) {
    if (action.type === CareerPathsConstants.SET_SEARCH_SELECT) {
        return action.payload;
    }

    return state;
}

export default combineReducers({ active, inputValue, selected });
