import React, { useState, useRef } from "react";
import { createClass } from "classname-helper";
import { useTranslate } from "react-translate.ts";

import { BaseTooltip } from "./BaseTooltip";
import { MathHelper } from "../../_helpers";

const TRANSLATE_KEY = "candidate.softSkillsTest.compare";

export function ComparisonChart(props) {
    const { data } = props;
    const [hovered, setHovered] = useState(null);
    const translate = useTranslate(TRANSLATE_KEY);

    return (
        <div id="comparison">
            <div id="comparison__legend">
                <div className="item__legend" />
                <span className="font-light"> - {translate("you")}</span>
            </div>

            <div id="comparison__content">
                <div id="comparison__items">
                    <div id="comparison__items__head">
                        <div>{translate("attribute")}</div>
                        <div style={{ right: -10 }}>{translate("average")}</div>
                    </div>
                    <div id="comparison__items__body">
                        {data.map((item, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="item__name">
                                        {item.profileSkill &&
                                            item.profileSkill.skill &&
                                            item.profileSkill.skill.name}
                                    </div>
                                    <div className="item__average">
                                        {MathHelper.round(item.average, 0)}%
                                    </div>
                                    <div
                                        className={createClass({
                                            item__difference: true,
                                            negative: item.difference < 0,
                                        })}
                                    >
                                        <strong>
                                            {item.difference >= 0 ? "+" : null}
                                            {MathHelper.round(
                                                item.difference,
                                                0
                                            )}
                                            %
                                        </strong>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div id="comparison__table">
                    <div id="comparison__table__head">
                        {Array.from(Array(10), (_, index) => {
                            return (
                                <div className="item" key={index}>
                                    {(index + 1) * 10}
                                </div>
                            );
                        })}
                    </div>
                    <div id="comparison__table__body">
                        {data.map((item) => {
                            const skills = item.othersSkills;
                            return Array.from(Array(10), (_, index) => {
                                return (
                                    <Item
                                        key={index}
                                        index={index}
                                        item={item}
                                        hovered={hovered}
                                        setHovered={setHovered}
                                        skills={skills}
                                    />
                                );
                            });
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Item(props) {
    const { item, hovered, setHovered, index, skills } = props;

    const ref = useRef();

    const skill = skills.find((skill) => skill.min === (index + 1) * 10);
    const val = item.profileSkill && item.profileSkill.level;
    const isLabeled = skill
        ? val >= skill.min && val < skill.max + 1
        : val >= (index + 1) * 10 && val < (index + 2) * 10;

    if (!skill) {
        return isLabeled ? (
            <div className="item" key={index}>
                <div className="item__legend" />
            </div>
        ) : (
            <div className="item" key={index} />
        );
    }

    const isHovered =
        hovered && index === hovered.index && skill.skill.id === hovered.id;

    const showTooltip = () =>
        setHovered({
            index,
            id: skill.skill.id,
        });

    return (
        <div
            className="item font-book"
            style={{
                background: `rgba(254, 52, 100, ${skill.count / 13})`,
            }}
            // onMouseEnter={showTooltip}
            onClick={showTooltip}
            onTouchEnd={showTooltip}
            onMouseLeave={() => setHovered(null)}
            ref={ref}
        >
            {isLabeled ? <div className="item__legend" /> : null}
            {isHovered ? <Tooltip parentRef={ref} skill={skill} /> : null}
        </div>
    );
}

function Tooltip(props) {
    const { parentRef, skill } = props;

    const translate = useTranslate(TRANSLATE_KEY);

    const testedLabel = translate("tested");

    return (
        <BaseTooltip
            parentRef={parentRef}
            title={`${translate("score")} ${skill.range}`}
            panels={[
                {
                    label: testedLabel + " (%)",
                    value: MathHelper.round(skill.avg, 1) + "%",
                },
                {
                    label: testedLabel + " (#)",
                    value: skill.count,
                },
            ]}
        />
    );
}
