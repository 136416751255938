import HeroProfileConstants from "../_constants/actions/HeroProfileConstants";

const HeroProfileActions = {
    publish,
    unpublish,
    getProfile,
    getSkills,
    getSoftSkillsResults,
    getProfilePicture,
    saveProfilePicture,
};

function getProfile(id) {
    return { type: HeroProfileConstants.GET_PROFILE, payload: id };
}

function publish(heroProfileId) {
    return {
        type: HeroProfileConstants.PUBLISH,
        payload: heroProfileId,
    };
}

function unpublish(heroProfileId) {
    return {
        type: HeroProfileConstants.UNPUBLISH,
        payload: heroProfileId,
    };
}

function getSkills(heroProfileId) {
    return { type: HeroProfileConstants.GET_SKILLS, payload: heroProfileId };
}

function getSoftSkillsResults(heroProfileId, name) {
    return {
        type: HeroProfileConstants.GET_SOFT_SKILLS_RESULTS,
        payload: { heroProfileId, name },
    };
}

function getProfilePicture(heroProfileId) {
    return {
        type: HeroProfileConstants.GET_PROFILE_PICTURE,
        payload: heroProfileId,
    };
}

function saveProfilePicture(file, originalFile, fileName, heroProfileId) {
    return {
        type: HeroProfileConstants.SAVE_PROFILE_PICTURE,
        payload: { file, originalFile, fileName, heroProfileId },
    };
}

export default HeroProfileActions;
