const ProfileInformationConstants = {
    ADD_EDUCATION: 'PROFILE__ADD_EDUCATION',
    ADD_EDUCATION_SUCCESS: 'PROFILE__ADD_EDUCATION_SUCCESS',
    ADD_EDUCATION_FAIL: 'PROFILE__ADD_EDUCATION_FAIL',

    UPDATE_EDUCATION: 'PROFILE__UPDATE_EDUCATION',
    UPDATE_EDUCATION_SUCCESS: 'PROFILE__UPDATE_EDUCATION_SUCCESS',
    UPDATE_EDUCATION_FAIL: 'PROFILE__UPDATE_EDUCATION_FAIL',

    DELETE_EDUCATION: 'PROFILE__DELETE_EDUCATION',
    DELETE_EDUCATION_SUCCESS: 'PROFILE__DELETE_EDUCATION_SUCCESS',
    DELETE_EDUCATION_FAIL: 'PROFILE__DELETE_EDUCATION_FAIL',

    ADD_EXPERIENCE: 'PROFILE__ADD_EXPERIENCE',
    ADD_EXPERIENCE_SUCCESS: 'PROFILE__ADD_EXPERIENCE_SUCCESS',
    ADD_EXPERIENCE_FAIL: 'PROFILE__ADD_EXPERIENCE_FAIL',

    UPDATE_EXPERIENCE: 'PROFILE__UPDATE_EXPERIENCE',
    UPDATE_EXPERIENCE_SUCCESS: 'PROFILE__UPDATE_EXPERIENCE_SUCCESS',
    UPDATE_EXPERIENCE_FAIL: 'PROFILE__UPDATE_EXPERIENCE_FAIL',

    DELETE_EXPERIENCE: 'PROFILE__DELETE_EXPERIENCE',
    DELETE_EXPERIENCE_SUCCESS: 'PROFILE__DELETE_EXPERIENCE_SUCCESS',
    DELETE_EXPERIENCE_FAIL: 'PROFILE__DELETE_EXPERIENCE_FAIL',

    UPDATE_ABOUT_ME: 'PROFILE__UPDATE_ABOUT_ME',
    UPDATE_ABOUT_ME_SUCCESS: 'PROFILE__UPDATE_ABOUT_ME_SUCCESS',
    UPDATE_ABOUT_ME_FAIL: 'PROFILE__UPDATE_ABOUT_ME_FAIL',

    ADD_INTEREST: 'PROFILE__ADD_INTEREST',
    ADD_INTEREST_SUCCESS: 'PROFILE__ADD_INTEREST_SUCCESS',
    ADD_INTEREST_FAIL: 'PROFILE__ADD_INTEREST_FAIL',

    UPDATE_INTEREST: 'PROFILE__UPDATE_INTEREST',
    UPDATE_INTEREST_SUCCESS: 'PROFILE__UPDATE_INTEREST_SUCCESS',
    UPDATE_INTEREST_FAIL: 'PROFILE__UPDATE_INTEREST_FAIL',

    DELETE_INTEREST: 'PROFILE__DELETE_INTEREST',
    DELETE_INTEREST_SUCCESS: 'PROFILE__DELETE_INTEREST_SUCCESS',
    DELETE_INTEREST_FAIL: 'PROFILE__DELETE_INTEREST_FAIL',

    SAVE_INTERESTS: 'PROFILE__SAVE_INTERESTS',
    SAVE_INTERESTS_SUCCESS: 'PROFILE__SAVE_INTERESTS_SUCCESS',
    SAVE_INTERESTS_FAIL: 'PROFILE__SAVE_INTERESTS_FAIL',

    ADD_LANGUAGE: 'PROFILE__ADD_LANGUAGE',
    UPDATE_LANGUAGE: 'PROFILE__UPDATE_LANGUAGE',
    DELETE_LANGUAGE: 'PROFILE__DELETE_LANGUAGE',
    REVERT_LANGUAGE_CHANGES: 'PROFILE__REVERT_LANGUAGE_CHANGES',

    SAVE_LANGUAGES: 'PROFILE__SAVE_LANGUAGES',
    SAVE_LANGUAGES_SUCCESS: 'PROFILE__SAVE_LANGUAGES_SUCCESS',
    SAVE_LANGUAGES_FAIL: 'PROFILE__SAVE_LANGUAGES_FAIL',

    ADD_SKILL: 'PROFILE__ADD_SKILL',
    UPDATE_SKILL: 'PROFILE__UPDATE_SKILL',
    DELETE_SKILL: 'PROFILE__DELETE_SKILL',
    REVERT_SKILL_CHANGES: 'PROFILE__REVERT_SKILL_CHANGES',

    SAVE_SKILLS: 'PROFILE__SAVE_SKILLS',
    SAVE_SKILLS_SUCCESS: 'PROFILE__SAVE_SKILLS_SUCCESS',
    SAVE_SKILLS_FAIL: 'PROFILE__SAVE_SKILLS_FAIL',
};

export default ProfileInformationConstants;
