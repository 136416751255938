import React, { useEffect } from 'react';
import { IntegratedDialog } from '../../../../../common/components';
import { FormButtons } from '../FormButtons';

let localStream = null;
let video = null;
let canvas = null;
export function AvatarEditSelfie({ size, onClose, onConfirm, onCancel }) {
    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ video: { facingMode: 'user' }, audio: false })
            .then(stream => {
                if (video && canvas) {
                    localStream = stream;
                    video.srcObject = localStream;
                    video.play();
                }
            })
            .catch(err => {
                console.error({ err });
            });

        return endStream;
    }, []);

    return (
        <IntegratedDialog onClose={handleClose}>
            <div id="avatar-edit-selfie">
                <video ref={ref => (video = ref)} />
                <canvas ref={ref => (canvas = ref)} />
                <div className="w100">
                    <FormButtons
                        onCancel={handleCancel}
                        onConfirm={handleTakePhoto}
                        confirmText="candidate.profile.photo.take-picture"
                    />
                </div>
            </div>
        </IntegratedDialog>
    );

    function takePhoto() {
        if (!video || !canvas) {
            return null;
        }

        const context = canvas.getContext('2d');
        const width = size;
        const height = video.videoHeight / (video.videoWidth / width);
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);

        const photo = canvas.toDataURL('image/jpg');
        onConfirm(photo);
    }

    function endStream() {
        if (localStream) {
            localStream.getVideoTracks()[0].stop();
        }

        localStream = null;
        video = null;
        canvas = null;
    }

    function handleTakePhoto() {
        takePhoto();
        endStream();
        onCancel();
    }

    function handleCancel() {
        endStream();
        onCancel();
    }

    function handleClose() {
        endStream();
        onClose();
    }
}
