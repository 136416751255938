import React from "react";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

export function VideoPlayer(props) {
    const { videoUrl, videoProvider, ...rest } = props;

    return (
        <div className="video-player">{render(videoUrl, videoProvider)}</div>
    );
}

function render(videoUrl, videoProvider) {
    if (videoProvider === "youtube") {
        return renderYoutube(videoUrl);
    } else if (videoProvider === "vimeo") {
        return renderVimeo(videoUrl);
    } else if (videoProvider === "image") {
        return renderImage(videoUrl);
    }

    return <Video videoUrl={videoUrl} />;
}

function Video(props) {
    const { videoUrl } = props;
    return (
        <div className="video-wrapper">
            <video src={videoUrl} style={{ width: "100%" }} controls />
        </div>
    );
}

function renderYoutube(videoUrl) {
    return (
        <YouTube videoId={videoUrl} opts={{ width: "100%", height: "404px" }} />
    );
}

function renderVimeo(videoUrl) {
    return (
        <Vimeo
            video={videoUrl}
            autoplay={false}
            responsive={true}
            height={"404px"}
        />
    );
}

function renderImage(url) {
    return <img width="100%" src={url} alt="" />;
}
