import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';

export function TrackingTimer({ endDate, onTimeChange }) {
    const interval = useRef(null);
    const end = moment(endDate);
    const [date, setDate] = useState(calculateDate(end));
    const [isEnd, setIsEnd] = useState(isEnded(end));

    useEffect(() => {
        interval.current = setInterval(() => {
            // if end date is less then current date calculate new values
            if (!isEnded(end)) {
                setDate(calculateDate(end));

                const diff = end.diff(moment()) / 1000 / 3600 / 24;
                onTimeChange(diff);
            } else {
                clearInterval(interval.current);
                setIsEnd(true);
            }
        }, 1000);
        return () => {
            clearInterval(interval.current);
        };
    }, []);

    const { days, hours, minutes, seconds } = date;
    if (isEnd) {
        // TODO call action when timer reached 0
        return <div>ended</div>;
    }

    return constructTime(days, hours, minutes, seconds);
}

function isEnded(end) {
    return moment().valueOf() >= end.valueOf();
}

function calculateDate(end) {
    const now = moment();
    const secondsDiff = end.diff(now, 'seconds');
    const minutesDiff = end.diff(now, 'minutes');
    const hoursDiff = Math.floor(minutesDiff / 60);

    const hours = hoursDiff % 24;
    const days = Math.floor(hoursDiff / 24);
    const minutes = minutesDiff % 60;
    const seconds = secondsDiff % 60;

    return { days, hours, minutes, seconds };
}

function constructTime(days, hours, minutes, seconds) {
    if (days > 0) {
        return `${days}d ${hours}h ${minutes}m`;
    } else if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
    }
    return `${minutes}m ${seconds}s`;
}
