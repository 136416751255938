import { createClass } from "classname-helper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslate } from "react-translate.ts";

import {
    BaseDialog,
    Button,
    LoadingOverlay,
    SVGIcon,
} from "../../../../common/components";
import { BaseDialogBox } from "../../../../common/components/dialogs/BaseDialogBox";
import {
    ENTER_KEY,
    SPACEBAR_KEY,
    TAB_KEY,
} from "../../../../common/_constants/input.constants";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";
import { AuthSelectors } from "../../../../common/_selectors";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import InviteFriendsActions from "../../../_actions/InviteFriendsActions";
import InviteFriendsSelectors from "../../../_selectors/InviteFriendsSelectors";

export function InviteFriendsDialog(props) {
    const { isOpen, close } = props;

    const { isLoading } = useSelector(mapState);

    return (
        <BaseDialog
            isVisible={isOpen}
            onClose={close}
            id="invite-friends-dialog"
        >
            <BaseDialogBox onClose={close}>
                <LoadingOverlay isLoading={isLoading} />
                <Share close={close} />
            </BaseDialogBox>
        </BaseDialog>
    );
}

const defaultState = {
    inputValue: "",
    showInvites: false,
    addMessage: false,
    message: "",
    emails: [],
};

const INVITE_COUNT_UNLOCK = 5;

function Share(props) {
    const translate = useTranslate("candidate.softSkillsTest.invite");

    const [state, setState] = useState(defaultState);

    const hasEmails = !ArrayHelper.isEmpty(state.emails);

    const params = useParams();

    const dispatch = useDispatch();

    const isValid = hasEmails || state.inputValue.trim().length > 0;

    const { invites, profileId } = useSelector(mapState);

    useEffect(() => {
        if (profileId && params.test) {
            if (invites && invites.length >= INVITE_COUNT_UNLOCK) {
                dispatch(SoftSkillsActions.getStatus(profileId, params.test));
                props.close();
            }
        }
    }, [dispatch, profileId, params.test, invites]);

    useEffect(() => {
        if (profileId && params.test) {
            dispatch(
                InviteFriendsActions.getInvites(
                    profileId,
                    params.test.toUpperCase()
                )
            );
        }
    }, [dispatch, profileId, params.test]);

    return (
        <>
            <div className="title">
                {translate("title", { count: INVITE_COUNT_UNLOCK })}
            </div>
            <input
                value={state.inputValue}
                onChange={(event) => {
                    const inputValue = event.target.value;
                    setState({
                        ...state,
                        inputValue,
                    });
                }}
                onKeyDown={handleKeys}
                placeholder={translate("input")}
            />
            {hasEmails ? (
                <div className="emails">
                    {state.emails.map((email, i) => (
                        <div key={i} onClick={() => removeEmail(i)}>
                            {email}
                        </div>
                    ))}
                </div>
            ) : null}
            <div id="invite-friends-dialog__bottom">
                <Message
                    state={state}
                    setState={setState}
                    translate={translate}
                />
                <div>
                    <Button onClick={sendInvites} disabled={!isValid}>
                        {translate("button")}
                    </Button>
                </div>
            </div>
            <Invites state={state} setState={setState} translate={translate} />
        </>
    );

    function removeEmail(index) {
        const newEmails = [
            ...state.emails.slice(0, index),
            ...state.emails.slice(index + 1),
        ];
        setState({ ...state, emails: newEmails });
    }

    function handleKeys(event) {
        const value = event.target.value;
        if (!value || value.trim().length === 0) {
            return;
        }

        const code = event.code || event.keyCode || event.which;

        if (code === ENTER_KEY || code === SPACEBAR_KEY || code === TAB_KEY) {
            const emailSet = new Set(state.emails);
            emailSet.add(event.target.value);
            setState({
                ...state,
                inputValue: "",
                emails: Array.from(emailSet),
            });
        }
    }

    function sendInvites() {
        if (!isValid) {
            return;
        }

        dispatch(
            InviteFriendsActions.sendInvites(
                hasEmails ? state.emails : [state.inputValue],
                state.message,
                params.test.toUpperCase()
            )
        );

        setState(defaultState);
    }
}

function Message(props) {
    const { state, setState, translate } = props;

    return (
        <div className="message">
            <div
                className="add-message active"
                onClick={() =>
                    setState({
                        ...state,
                        addMessage: !state.addMessage,
                    })
                }
            >
                <SVGIcon name="playlist" />
                <span>{translate("message")}</span>
            </div>
            {state.addMessage ? (
                <div>
                    <textarea
                        value={state.message}
                        onChange={(event) =>
                            setState({
                                ...state,
                                message: event.target.value,
                            })
                        }
                    />{" "}
                </div>
            ) : null}
        </div>
    );
}

function Invites(props) {
    const { state, setState, translate } = props;

    const { invites, hasInvites } = useSelector(mapState);

    return (
        <>
            <div
                className={createClass({ invited: true, active: hasInvites })}
                onClick={() => {
                    if (!hasInvites) {
                        return;
                    }

                    setState({ ...state, showInvites: !state.showInvites });
                }}
            >
                {hasInvites ? (
                    <SVGIcon
                        name={
                            state.showInvites ? "chevron-down" : "chevron-right"
                        }
                    />
                ) : null}
                <span>
                    {translate(
                        hasInvites ? "invited" : "invitedZero",
                        hasInvites
                            ? {
                                  count: invites.length,
                              }
                            : {}
                    )}
                </span>
            </div>
            {state.showInvites && hasInvites ? (
                <ul className="invites">
                    {invites.map((invite, i) => (
                        <Invite key={i} invite={invite} translate={translate} />
                    ))}
                </ul>
            ) : null}
        </>
    );
}

function Invite(props) {
    const { name, email, status, id } = props.invite;
    const translate = props.translate;

    const dispatch = useDispatch();

    return (
        <li>
            <div className="invite">
                <img src="/img/avatar.svg" alt="" />
                <div>
                    <div className="name">{name || email}</div>
                    <div className="status">
                        {status === "ACTIVE" ? (
                            email
                        ) : (
                            <>
                                <span>{translate("pending")}</span>
                                <span className="resent" onClick={sendInvite}>
                                    {translate("resent")}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {status === "ACTIVE" ? (
                <div className="member">{translate("member")}</div>
            ) : null}
        </li>
    );

    function sendInvite() {
        dispatch(InviteFriendsActions.sendInvite(id));
    }
}

function mapState(state) {
    return {
        invites: InviteFriendsSelectors.getInvites(state),
        isLoading: InviteFriendsSelectors.isLoading(state),
        hasInvites: InviteFriendsSelectors.hasInvites(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
