import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import SoftSkillsActions from "../../../../_actions/SoftSkillsActions";
import SoftSkillsSelectors from "../../../../_selectors/SoftSkillsSelectors";
import { AuthSelectors } from "../../../../../common/_selectors/AuthSelectors";
import { LanguageSelectors } from "../../../../../common/_selectors/LanguageSelectors";
import { Loading } from "../../../../../common/components";
import { SOFT_SKILL_TEST_PATH } from "../../../../../common/_constants/route.constants";
import {
    COMPLETED_STATUS,
    PROCESSED_STATUS,
} from "../../../../_constants/testStatus.constants";
import { SOFT_SKILL_TEST_MOP_PATH } from "../../../../_constants/route.constants";
import { LanguageActions } from "../../../../../common/_actions";
import TccTest from "../../../../../common/components/TccTest/TccTest";
import { MainState } from "../../../../../root.reducer";
import styles from "./Test.module.scss";
import useSoftSkillsTest from "../../../../../common/hooks/questionnaire/useSoftSkillsTest";

type TestProps = {
    name: string;
};

export default function Test(props: TestProps) {
    const { name } = props;
    const { isCompleted, status } = useSelector((state: MainState) =>
        mapState(state, name)
    );
    const history = useHistory();

    useEffect(() => {
        if (status && status.unlocked === false) {
            history.replace(SOFT_SKILL_TEST_MOP_PATH);
        }
    }, [status]);

    const dispatch = useDispatch();

    const backLink = SOFT_SKILL_TEST_PATH + "/" + name;

    useEffect(() => {
        dispatch(LanguageActions.setAllowedLocales(["cs"]));

        return () => {
            dispatch(LanguageActions.setAllowedLocales([]));
        };
    }, []);

    const { test, locale, completeTest } = useSoftSkillsTest(name);

    if (isCompleted) {
        return <Redirect to={backLink} />;
    }

    if (!test) {
        return <Loading />;
    }

    return (
        <TccTest
            testName={name}
            test={test}
            locale={locale}
            className={styles.test}
            onCompleted={completeTest}
        />
    );
}

function mapState(state: MainState, name: string) {
    const status = SoftSkillsSelectors.getStatus(state, name);

    return {
        isCompleted:
            status.status &&
            (status.status === COMPLETED_STATUS ||
                status.status === PROCESSED_STATUS),
        status,
    };
}
