import { useRef } from "react";
import { cx } from "@emotion/css";

import { SVGIcon } from "..";
import MediaDrop from "../media/MediaDrop/MediaDrop";
import styles from "./GalleryEdit.module.scss";
import MediaEditItem from "../media/MediaEditItem/MediaEditItem";
import MediaUploadProgress from "../media/MediaUploadProgress/MediaUploadProgress";
import IImage from "../../types/IImage";
import IUploadState from "../../types/IUploadState";
import { useTranslate } from "react-translate.ts";

type GalleryEditProps = {
    onAdd: (file: File) => void;
    onDelete: (id: number) => void;
    images: IImage[];
    uploadState: IUploadState[];
};

export default function GalleryEdit(props: GalleryEditProps) {
    const { onAdd, onDelete, images, uploadState } = props;
    const inputRef = useRef<{
        element: HTMLInputElement;
        openDialog: () => void;
    } | null>(null);
    const translate = useTranslate("common.gallery.edit");

    return (
        <div className={styles.container}>
            <MediaDrop
                className={styles.dropbox}
                image={
                    <SVGIcon name="gallery" light style={{ fontSize: 100 }} />
                }
                text={translate("text")}
                accept="image/*"
                inputRef={inputRef}
                onFilesChange={handleAdd}
                onFileDrop={handleAdd}
                multiple
            />
            {uploadState.length > 0 && (
                <div className={cx(styles.list, styles.list__upload)}>
                    {uploadState.map((upload, index) => {
                        if (!upload.uploading) {
                            return null;
                        }

                        return (
                            <MediaUploadProgress
                                key={index}
                                className={styles.upload}
                                progress={upload.progress}
                                name={upload.id}
                            />
                        );
                    })}
                </div>
            )}
            <div className={styles.list}>
                {images.map((image) => {
                    return (
                        <MediaEditItem
                            key={image.id}
                            name={image.name}
                            onDelete={() => handleDelete(image.id)}
                        >
                            <img
                                className={styles.thumb}
                                src={image.publicUrl}
                                alt=""
                            />
                        </MediaEditItem>
                    );
                })}
            </div>
        </div>
    );

    function handleDelete(id: number) {
        onDelete(id);
    }

    function handleAdd(files: FileList | null) {
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                onAdd(files[i]);
            }
        }
    }
}

type ItemProps = {
    data: string;
    title: string;
    size: number;
    onDelete: (index: number) => void;
    id: number;
};

function Item(props: ItemProps) {
    const { data, title, size, onDelete, id } = props;

    return (
        <div className={styles.item}>
            <div className={styles.item__image}>
                <img src={data} alt="" />
            </div>
            <div className={styles.item__info}>
                <div className={styles.item__title}>{title}</div>
                <div className={styles.item__size}>{size}</div>
            </div>
            <div className={styles.item__delete}>
                <button onClick={handleDelete}>
                    <SVGIcon name="close" />
                </button>
            </div>
        </div>
    );

    function handleDelete() {
        onDelete(id);
    }
}
