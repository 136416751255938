import { createContext, PropsWithChildren, useContext, useRef } from "react";
import { List } from "immutable";

type FiltersContext = {
    onConfirm: () => void;
    addConfirmEvent: (event: () => void) => void;
    removeConfirmEvent: (event: () => void) => void;
};

export const FiltersContext = createContext<FiltersContext>({
    onConfirm: () => {},
    addConfirmEvent: () => {},
    removeConfirmEvent: () => {},
});

type FiltersContextProviderProps = {};

export function FiltersContextProvider(
    props: PropsWithChildren<FiltersContextProviderProps>
) {
    const { children } = props;
    const events = useRef(List<() => void>());

    return (
        <FiltersContext.Provider
            value={{ addConfirmEvent, removeConfirmEvent, onConfirm }}
        >
            {children}
        </FiltersContext.Provider>
    );

    function onConfirm() {
        for (let i = 0; i < events.current.size; i++) {
            const event = events.current.get(i);

            event && event();
        }
    }

    function addConfirmEvent(event: () => void) {
        events.current = events.current.push(event);
    }

    function removeConfirmEvent(event: () => void) {
        const index = events.current.findIndex((value) => value === event);
        if (index >= 0) {
            events.current = events.current.remove(index);
        }
    }
}

export function useFiltersContext() {
    return useContext(FiltersContext);
}
