import { takeLatest } from 'redux-saga/effects';

import { SagaHelpers } from '../../common/_helpers/SagaHelpers';
import { ApplicationsTrackingService } from '../_services/ApplicationTrackingService';
import {
    GET_APPLICATIONS,
    GET_APPLICATIONS_FAIL,
    GET_APPLICATIONS_SUCCESS,
    GET_APPLICATIONS_ARCHIVED,
    GET_APPLICATIONS_ARCHIVED_SUCCESS,
    GET_APPLICATIONS_ARCHIVED_FAIL,
    GET_APPLICATIONS_REJECTED,
    GET_APPLICATIONS_REJECTED_SUCCESS,
    GET_APPLICATIONS_REJECTED_FAIL,
    APPLICATIONS_ACCEPT_SUCCESS,
    APPLICATIONS_ACCEPT_FAIL,
    APPLICATIONS_ACCEPT,
    APPLICATIONS_REJECT,
    APPLICATIONS_REJECT_SUCCESS,
    APPLICATIONS_REJECT_FAIL,
} from '../_constants/actions/applicationsTracking.constants';

function* getApplications(action) {
    yield SagaHelpers.handleRequest({
        request: [ApplicationsTrackingService.getApplications, action.payload],
        actions: [
            (payload) => ({ type: GET_APPLICATIONS_SUCCESS, payload }),
            (error) => ({ type: GET_APPLICATIONS_FAIL, error }),
        ],
        messages: {
            fail: 'fail',
        },
    });
}

function* getArchived(action) {
    yield SagaHelpers.handleRequest({
        request: [
            ApplicationsTrackingService.getArchivedApplications,
            action.payload,
        ],
        actions: [
            (payload) => ({ type: GET_APPLICATIONS_ARCHIVED_SUCCESS, payload }),
            (error) => ({ type: GET_APPLICATIONS_ARCHIVED_FAIL, error }),
        ],
        messages: {
            fail: 'fail',
        },
    });
}

function* getRejected(action) {
    yield SagaHelpers.handleRequest({
        request: [
            ApplicationsTrackingService.getRejectedApplications,
            action.payload,
        ],
        actions: [
            (payload) => ({ type: GET_APPLICATIONS_REJECTED_SUCCESS, payload }),
            (error) => ({ type: GET_APPLICATIONS_REJECTED_FAIL, error }),
        ],
        messages: {
            fail: 'fail',
        },
    });
}

function* acceptJob(action) {
    yield SagaHelpers.handleRequest({
        request: [ApplicationsTrackingService.acceptJob, action.payload],
        actions: [
            () => ({
                type: APPLICATIONS_ACCEPT_SUCCESS,
                payload: action.payload,
            }),
            (error) => ({ type: APPLICATIONS_ACCEPT_FAIL, error }),
        ],
        messages: {
            fail: 'candidate.notify.tracking.accept.fail',
            success: 'candidate.notify.tracking.accept.success',
        },
    });
}

function* rejectJob(action) {
    yield SagaHelpers.handleRequest({
        request: [ApplicationsTrackingService.rejectJob, action.payload],
        actions: [
            () => ({
                type: APPLICATIONS_REJECT_SUCCESS,
                payload: action.payload,
            }),
            (error) => ({ type: APPLICATIONS_REJECT_FAIL, error }),
        ],
        messages: {
            fail: 'candidate.notify.tracking.reject.fail',
            success: 'candidate.notify.tracking.reject.success',
        },
    });
}

export default [
    takeLatest(GET_APPLICATIONS, getApplications),
    takeLatest(GET_APPLICATIONS_ARCHIVED, getArchived),
    takeLatest(GET_APPLICATIONS_REJECTED, getRejected),
    takeLatest(APPLICATIONS_ACCEPT, acceptJob),
    takeLatest(APPLICATIONS_REJECT, rejectJob),
];
