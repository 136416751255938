import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router";

import { BackButton } from "../../../common/components";
import CompanyProfile from "../../../common/components/company-profile/CompanyProfile";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import CompanyProfileActions from "../../../common/_actions/CompanyProfileActions";
import {
    ADMIN_PROFILE_PATH,
    ADMIN_PROFILE_PREVIEW_PATH,
} from "../../../common/_constants/route.constants";
import CompanyProfileSelectors from "../../../common/_selectors/CompanyProfileSelectors";
import { MainState } from "../../../root.reducer";
import AdminProfileSelectors from "../../_selectors/AdminProfileSelectors";
import AdminProfileEdit from "../../components/AdminProfileEdit/AdminProfileEdit";
import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";

export default function AdminProfilePage() {
    const { id, video, images, imageUploadState } = useSelector(mapState);
    const dispatch = useDispatch();
    const renderToHeader = useRenderToHeader();
    const history = useHistory();
    const {
        data: profile,
        loading,
        saving,
    } = CompanyProfileSelectors.useGetDetail();

    useEffect(() => {
        if (id) {
            dispatch(CompanyProfileActions.getDetail(id));
        }
    }, [dispatch, id]);

    return (
        <CompanyPageLayout>
            <Switch>
                <Route exact path={ADMIN_PROFILE_PATH}>
                    <AdminProfileEdit
                        companyId={id}
                        name={profile?.name}
                        description={profile?.description}
                        url={profile?.url}
                        size={profile?.size}
                        industries={profile?.industries}
                        locations={profile?.locations}
                        isLoading={loading}
                        video={video}
                        images={images}
                        imageUploadState={imageUploadState}
                        benefits={profile.jobBenefits}
                        founded={profile.founded}
                        headquarters={profile.headquarterLocation}
                        videoUrl={profile.videoUrl}
                        published={profile.published}
                        saving={saving}
                    />
                </Route>
                <Route path={ADMIN_PROFILE_PREVIEW_PATH}>
                    <>
                        {renderToHeader(<BackButton onClick={goBack} />)}
                        <CompanyProfile companyId={id} displayOnly={true} />
                    </>
                </Route>
            </Switch>
        </CompanyPageLayout>
    );

    function goBack() {
        history.push(ADMIN_PROFILE_PATH);
    }
}

function mapState(state: MainState) {
    return {
        id: state.common.auth.user.companyId,
        profile: CompanyProfileSelectors.getDetailData(state),
        isLoading: CompanyProfileSelectors.isLoading(state),
        video: AdminProfileSelectors.getVideo(state),
        images: AdminProfileSelectors.getImages(state),
        imageUploadState: AdminProfileSelectors.getImageUploadState(state),
    };
}
