import { Form, Formik } from "formik";
import { useTranslate } from "react-translate.ts";
import { useEffect, useState } from "react";
import produce from "immer";

import Button from "../../../../../common/components/buttons/Button/Button";
import AdminEdit from "../../../AdminEdit/AdminEdit";
import { IJobInfo } from "../../../../../common/types/ICompanyJob";
import BaseEdit from "../../../BaseEdit/BaseEdit";
import { Panel } from "../../../../../common/components/Panel/Panel";
import FormInput from "../../../../../common/components/input/FormInput";
import styles from "./InfoEditPanel.module.scss";
import { useDispatch } from "react-redux";
import AdminJobActions from "../../../../_actions/AdminJobActions";
import { LanguageSelectors } from "../../../../../common/_selectors/LanguageSelectors";

type InfoEditPanelProps = {
    info: IJobInfo[];
    jobPostId: number;
    loading: boolean;
};

type Values = string[];

export default function InfoEditPanel(props: InfoEditPanelProps) {
    const { info, jobPostId, loading } = props;
    const translate = useTranslate("admin.jobs.edit.info");
    const [values, setValues] = useState([]);
    const dispatch = useDispatch();
    const lang = LanguageSelectors.useGetLocale();

    useEffect(() => {
        setValues(info?.map((item) => item.description) || []);
    }, [info]);

    return (
        <Panel anchor="info">
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <div>
                <Formik<Values>
                    initialValues={values}
                    enableReinitialize
                    onSubmit={submit}
                >
                    <InnerForm
                        translate={translate}
                        values={values}
                        onCreate={createItem}
                        onDelete={deleteItem}
                        loading={loading}
                    />
                </Formik>
            </div>
        </Panel>
    );

    function submit(values: Values) {
        if (loading) {
            return;
        }

        dispatch(
            AdminJobActions.saveInfo({
                jobPostId,
                jobOtherInfos: values.map((value, i) => ({
                    description: value,
                    lang,
                    order: i + 1,
                })),
            })
        );
    }

    function createItem() {
        setValues((prev) =>
            produce(prev, (draft) => {
                draft.push("");
            })
        );
    }

    function deleteItem(index: number) {
        setValues((prev) =>
            produce(prev, (draft) => {
                draft.splice(index, 1);
            })
        );
    }
}

type InnerFormProps = {
    translate: (key: string) => any;
    values: string[];
    onCreate: () => void;
    onDelete: (index: number) => void;
    loading: boolean;
};

function InnerForm(props: InnerFormProps) {
    const { translate, values, onCreate, onDelete, loading } = props;

    return (
        <Form>
            <BaseEdit<string>
                cols={["4fr"]}
                head={[<div>{translate("form.info.label")}</div>]}
                createItem={(_, index) => {
                    return (
                        <FormInput
                            name={index.toString()}
                            placeholder={translate("form.info.placeholder")}
                        />
                    );
                }}
                data={values}
                title={translate("title")}
                isVisible={false}
                onDelete={(_, index) => onDelete(index)}
                onCreate={onCreate}
                createButtonText={translate("create")}
                getItemName={(item) => item}
            />
            <Button
                type="submit"
                inverted
                disabled={loading}
                className={styles.button}
            >
                {translate("form.info.submit")}
            </Button>
        </Form>
    );
}
