import React from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'react-translate.ts';
import { List } from 'immutable';

import { Filters } from './Filters';
import {
    useReactSelectStyle,
    useReactSelectTheme,
} from '../../../../common/hooks';
import { SearchBar } from '../../../../common/components';
import { AutocompleteService } from '../../../../candidate/_services';
import { CandidateSearchSelectors } from '../../../_selectors/CandidateSearchSelectors';
import { CandidateSearchActions } from '../../../_actions';

export function Search(props) {
    const { onApply, isMobile, isLoading, className, jobs } = props;
    const { filters } = useSelector(mapState);
    const { changeFilter } = mapActions(useDispatch());

    const styles = useReactSelectStyle({}, { isMobile, noBorders: true })
        .select;
    const selectTheme = useReactSelectTheme();
    const translate = useTranslate('candidate.jobs.filters');

    return (
        <SearchBar
            className={className}
            onApply={onApply}
            buttons={
                <Filters
                    onApply={onApply}
                    filters={filters}
                    changeFilter={changeFilter}
                />
            }
            buttonLabel={translate('search')}
            buttonIsLoading={isLoading}
            SearchInput={
                <Select
                    styles={styles}
                    theme={selectTheme}
                    options={jobs}
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.id}
                    placeholder={translate('jobTitle')}
                    onChange={(item) => {
                        changeFilter(
                            'jobPost',
                            jobs.find((value) => item.id === value.id)
                        );
                    }}
                    value={filters.jobPost}
                />
            }
        >
            <div className="search-input">
                <div className="search-input--icon location">
                    <img src="/img/icons/location.svg" alt="" />
                </div>
                <Async
                    styles={styles}
                    theme={selectTheme}
                    placeholder={translate('location')}
                    value={filters.locations.toArray()}
                    onChange={changeLocations}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.placeId}
                    components={{ DropdownIndicator: null }}
                    loadOptions={AutocompleteService.cities}
                    isMulti
                    openMenuOnClick={false}
                    openMenuOnFocus={false}
                />
            </div>
        </SearchBar>
    );

    function changeLocations(value, { action, option }) {
        switch (action) {
            case 'select-option':
                changeFilter('locations', filters.locations.push(option));
                break;
            case 'remove-value':
                changeFilter('locations', List(value));
                break;
            case 'clear':
                changeFilter('locations', List());
                break;
            default:
                break;
        }
    }
}

function mapState(state) {
    return {
        filters: CandidateSearchSelectors.getFilters(state),
    };
}

function mapActions(dispatch) {
    return {
        changeFilter: (key, value) =>
            void dispatch(CandidateSearchActions.setFilter(key, value)),
    };
}
