import CandidatePageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";
import { ApplicationsTracking } from "../ApplicationsTracking/ApplicationsTracking";

export default function ApplicationsTrackingPage() {
    return (
        <CandidatePageLayout>
            <ApplicationsTracking />
        </CandidatePageLayout>
    );
}
