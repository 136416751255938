import { useState } from "react";
import { Translate } from "react-translate.ts";
import Async from "react-select/async";

import { AutocompleteService } from "../../_services";
import {
    useReactSelectTheme,
    useReactSelectStyle,
} from "../../../common/hooks/useReactSelectStyle";

export function SkillSearch({ onSelect, className, ...rest }) {
    const [inputValue, setInputValue] = useState("");
    const theme = useReactSelectTheme();
    const styles = useReactSelectStyle().select;

    return (
        <div className="w100">
            <Async
                placeholder={
                    <Translate textOnly>
                        candidate.profile.hardSkills.search
                    </Translate>
                }
                loadOptions={AutocompleteService.hardSkills}
                theme={theme}
                styles={styles}
                value={inputValue}
                onChange={onSelect}
                handleInputChange={setInputValue}
                getOptionLabel={(item) => item.name}
                getOptionValue={(item) => item.id}
                noOptionsMessage={() => (
                    <Translate textOnly>common.actions.startTyping</Translate>
                )}
            />
        </div>
    );
}
