export function resolveMatch(match) {
    if (match > 0.75) {
        return { message: 'common.jobs.match.excellent', color: '#2DD58A' };
    } else if (match > 0.5) {
        return { message: 'common.jobs.match.very-good', color: '#B4EB64' };
    } else if (match > 0.25) {
        return { message: 'common.jobs.match.average', color: '#FCF84D' };
    }
    return { message: 'job-match.bad', color: '#fe3464' };
}
