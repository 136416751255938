import { combineReducers } from "redux";

import personal from "./personal.reducer";
import advice from "./advice.reducer";
import positions from "./positions.reducer";
import skills from "./skills.reducer";
import HeroOnboardingConstants from "../../../../_constants/actions/HeroOnboardingConstants";
import {
    HERO_ONBOARDING_PERSONAL_DETAILS_STATUS,
    HERO_USER_STATUS,
} from "../../../../_constants/user.constants";
import { StorageService } from "../../../../_services";
import { GET_CURRENT_SUCCESS } from "../../../../_constants/actions/user.constants";

const lastStatus = StorageService.getLocal(
    "hero_onboarding_status",
    HERO_ONBOARDING_PERSONAL_DETAILS_STATUS
);

const current = (state = HERO_USER_STATUS[lastStatus], action) => {
    switch (action.type) {
        case GET_CURRENT_SUCCESS:
            const status = action.payload.userStatus;

            if (!status) {
                return 0;
            }

            const step = HERO_USER_STATUS[status.status];

            return step || 0;
        case HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return 1;
        case HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS:
            return 2;
        case HeroOnboardingConstants.SAVE_ADVICE_SUCCESS:
            return 4;
        case HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO_SUCCESS:
        case HeroOnboardingConstants.VIDEO_SKIP_SUCCESS:
            return 5;
        case HeroOnboardingConstants.ADD_STEP:
            return validateStep(state + action.payload);
        case HeroOnboardingConstants.CHANGE_STEP:
            return validateStep(action.payload);
        default:
            return state;
    }

    function validateStep(step) {
        if (step < 0) {
            return 0;
        } else if (step > 5) {
            return 5;
        }

        return step;
    }
};

export default combineReducers({
    personal,
    advice,
    positions,
    skills,
    current,
});
