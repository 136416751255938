import { FieldHookConfig, useField } from "formik";
import Input, { InputProps } from "./Input/Input";
import styles from "./Input/Input.module.scss";

interface FormInputProps<T extends string> extends InputProps {
    fieldConfig?: FieldHookConfig<T>;
}

export default function FormInput<T extends string>(props: FormInputProps<T>) {
    const { name, fieldConfig = {}, className, ...rest } = props;
    const [field, meta] = useField<T>({ ...fieldConfig, name });

    const { error, touched } = meta;
    const hasError = touched && !!error;
    return (
        <div className={styles.wrapper}>
            <Input
                {...field}
                error={hasError}
                onChange={field.onChange}
                {...rest}
            />
            {/* {hasError && <div className={styles.error}>{error}</div>} */}
        </div>
    );
}
