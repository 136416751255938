import { createSelector } from "reselect";
import {
    STATE_FAIL,
    STATE_LOADING,
} from "../../common/_constants/state.constants";

const careerPaths = (state) => state.candidate.careerPaths;

const map = createSelector(careerPaths, (career) => career.map);
const getMapData = createSelector(map, (map) => map.data);
const getMapState = createSelector(map, (map) => map.state);

const routing = createSelector(careerPaths, (career) => career.routing);
const getRoutingData = createSelector(routing, (routing) => routing.data);
const getRoutingPaths = createSelector(
    getRoutingData,
    (data) => data && data.paths
);
const getRoutingState = createSelector(routing, (routing) => routing.state);
const getRoutingPoints = createSelector(routing, (routing) => routing.points);
const getActiveRouteSearch = createSelector(
    routing,
    (routing) => routing.activeRouteSearch
);
const getSelectedPath = createSelector(
    routing,
    (routing) => routing.selectedPath
);

const tooltip = createSelector(careerPaths, (career) => career.tooltip);
const getTooltipData = createSelector(tooltip, (tooltip) => tooltip.data);
const getTooltipState = createSelector(tooltip, (tooltip) => tooltip.state);

const detail = createSelector(careerPaths, (career) => career.detail);
const getDetailData = createSelector(detail, (detail) => detail.data);
const getDetailState = createSelector(detail, (detail) => detail.state);
const getSelectedNode = createSelector(detail, (detail) => detail.selectedNode);

const select = createSelector(careerPaths, (career) => career.select);
const getCollegeData = createSelector(select, (select) => select.college);

const search = createSelector(careerPaths, (career) => career.search);
const getActiveSearch = createSelector(search, (search) => search.active);
const getInputValue = createSelector(search, (search) => search.inputValue);
const getSearchSelect = createSelector(search, (search) => search.selected);

const options = createSelector(careerPaths, (career) => career.options);
const getCountry = createSelector(options, (options) => options.country);
const getIndustry = createSelector(options, (options) => options.industry);

const getCategories = createSelector(
    careerPaths,
    (career) => career.categories.data
);

const getCategoryId = createSelector(
    careerPaths,
    (career) => career.categories.selectedCategoryId
);

const getSubcategoryId = createSelector(
    careerPaths,
    (career) => career.categories.selectedSubcategoryId
);

export const CareerPathsSelectors = {
    map: {
        getData: getMapData,
        isLoading: createSelector(
            getMapState,
            (state) => state === STATE_LOADING
        ),
        hasFailed: createSelector(getMapState, (state) => state === STATE_FAIL),
        getCategories,
        getCategoryId,
        getSubcategoryId,
    },
    routing: {
        getData: getRoutingData,
        getPaths: getRoutingPaths,
        getPoints: getRoutingPoints,
        getActiveRouteSearch,
        getSelectedPath,
        isLoading: createSelector(
            getRoutingState,
            (state) => state === STATE_LOADING
        ),
        hasFailed: createSelector(
            getRoutingState,
            (state) => state === STATE_FAIL
        ),
    },
    tooltip: {
        getData: getTooltipData,
        isLoading: createSelector(
            getTooltipState,
            (state) => state === STATE_LOADING
        ),
        hasFailed: createSelector(
            getTooltipState,
            (state) => state === STATE_FAIL
        ),
    },
    detail: {
        getData: getDetailData,
        isLoading: createSelector(
            getDetailState,
            (state) => state === STATE_LOADING
        ),
        hasFailed: createSelector(
            getDetailState,
            (state) => state === STATE_FAIL
        ),
        getSelectedNode,
    },
    search: {
        getActiveSearch,
        getInputValue,
        getSelected: getSearchSelect,
    },
    options: {
        getCountry,
        getIndustry,
    },
    select: {
        college: {
            getData: getCollegeData,
        },
    },
};
