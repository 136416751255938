import { HTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { cx } from "@emotion/css";

import styles from "./Dialog.module.scss";
import useTimer from "../../../hooks/useTimer";

export type DialogProps = PropsWithChildren<
    {
        isVisible?: boolean;
        onClose?: () => void;
    } & HTMLAttributes<HTMLDivElement>
>;

export default function Dialog(props: DialogProps) {
    const { isVisible, onClose, children, className, ...rest } = props;
    const [hidden, setHidden] = useState(!isVisible);

    const { time, start, stop } = useTimer(200, -1, 20);

    useEffect(() => {
        if (!isVisible) {
            start();
        } else {
            setHidden(false);
            stop();
        }

        return () => {
            stop();
        };
    }, [isVisible]);

    useEffect(() => {
        if (time <= 0) {
            setHidden(true);
            stop();
        }
    }, [time]);

    if (hidden) {
        return null;
    }

    return (
        <div
            className={cx(
                styles.dialog,
                isVisible ? styles.dialog__visible : null,
                className
            )}
            data-test="dialog"
            {...rest}
        >
            <div className={styles.backdrop} onClick={onClose} />
            {children}
        </div>
    );
}
