import { createSelector } from 'reselect';

const interpretation = (state) => state.candidate.interpretation;

const getInterpretation = (name) =>
    createSelector(
        interpretation,
        (interpretation) => interpretation.detail[name.toUpperCase()]
    );

const getInterpretationLoading = (name) =>
    createSelector(
        interpretation,
        getInterpretation(name.toUpperCase()),
        (interpretation, detail) => interpretation?.loading && !detail?.loaded
    );

const InterpretationSelectors = {
    getInterpretation,
    getInterpretationLoading,
};

export default InterpretationSelectors;
