import React, { useEffect } from 'react';
import { Close } from '@material-ui/icons';

import { ESC_KEY } from '../../_constants/input.constants';
import { addEventHandler, removeEventHandler } from '../../_helpers';
import { CSSTransition } from 'react-transition-group';

const EVENT_NAME = 'keydown';

export function PopupOverlay({ children, onClose, isOpen }) {
    useEffect(() => {
        addEventHandler(window, EVENT_NAME, handleKeys);

        return () => removeEventHandler(window, EVENT_NAME, handleKeys);
    }, []);

    return (
        <CSSTransition in={isOpen} timeout={300} classNames="popup-overlay-">
            <div className="popup-overlay">
                <div className="popup-overlay-close">
                    <Close onClick={onClose} fontSize="large" />
                </div>
                <div className="popup-overlay-content">{children}</div>
            </div>
        </CSSTransition>
    );

    function handleKeys(event) {
        const key = event.which || event.keyCode;

        if (key === ESC_KEY) {
            onClose();
        }
    }
}
