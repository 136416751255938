const OnboardingConstants = {
    SAVE_PERSONAL: "ONBOARDING__SAVE_PERSONAL",
    SAVE_PERSONAL_SUCCESS: "ONBOARDING__SAVE_PERSONAL_SUCCESS",
    SAVE_PERSONAL_FAIL: "ONBOARDING__SAVE_PERSONAL_FAIL",
    CHANGE_PERSONAL_VALUE: "ONBOARDING__CHANGE_PERSONAL_VALUE",
    // SET_PERSONAL_IMAGE: "ONBOARDING__SET_PERSONAL_IMAGE",
    // SET_PERSONAL_IMAGE_DATA: "ONBOARDING__SET_PERSONAL_IMAGE_DATA",

    SAVE_HARD_SKILLS: "ONBOARDING__SAVE_HARD_SKILLS",
    SAVE_HARD_SKILLS_SUCCESS: "ONBOARDING__SAVE_HARD_SKILLS_SUCCESS",
    SAVE_HARD_SKILLS_FAIL: "ONBOARDING__SAVE_HARD_SKILLS_FAIL",

    GET_TEST: "ONBOARDING__GET_TEST",
    GET_TEST_SUCCESS: "ONBOARDING__GET_TEST_SUCCESS",
    GET_TEST_FAIL: "ONBOARDING__GET_TEST_FAIL",

    GET_STATUS: "ONBOARDING__GET_STATUS",
    GET_STATUS_SUCCESS: "ONBOARDING__GET_STATUS_SUCCESS",
    GET_STATUS_FAIL: "ONBOARDING__GET_STATUS_FAIL",

    // SET_MORE_OPTIONS: "ONBOARDING__SET_MORE_OPTIONS",
    START_TEST: "ONBOARDING__START_TEST",

    ADD_STEP: "ONBOARDING__ADD_STEP",
    CHANGE_STEP: "ONBOARDING__CHANGE_STEP",
};

export default OnboardingConstants;
