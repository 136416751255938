import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const state = (state: MainState) => state.common.cookie;

const getConfig = createSelector(state, (state) => state.config);
const hasConsent = createSelector(state, (state) => state.consent);

const CookieConfigSelectors = {
    getConfig,
    hasConsent,
};

export default CookieConfigSelectors;
