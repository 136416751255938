import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import IHero from "../types/IHero";
import IPaginationState from "../types/IPaginationState";
import HeroService, { GetListPayload } from "../_services/HeroService";

const NAME = "HERO";

const getList = createAsyncThunk(
    `${NAME}/getList`,
    async function (payload: GetListPayload) {
        return await HeroService.getList(payload);
    }
);
const getProfile = createAsyncThunk(
    `${NAME}/getProfile`,
    HeroService.getProfile
);

type HeroState = {
    list: {
        data: IHero[];
        loading: boolean;
        pagination: IPaginationState;
    };
    profile: {
        data: IHero;
        loading: boolean;
    };
};

const initialState: HeroState = {
    list: {
        data: [],
        loading: false,
        pagination: { page: 0, pages: 0, elements: 0 },
    },
    profile: {
        data: null,
        loading: false,
    },
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getList.pending, (state) => {
            state.list.loading = true;
        });
        builder.addCase(getList.fulfilled, (state, action) => {
            const { content, pageable, totalElements, totalPages } =
                action.payload;

            state.list.loading = false;
            state.list.pagination = {
                page: pageable.pageNumber,
                elements: totalElements,
                pages: totalPages,
            };
            state.list.data = content;
        });
        builder.addCase(getList.rejected, (state) => {
            state.list.loading = false;
        });

        builder.addCase(getProfile.pending, (state) => {
            state.profile.loading = true;
        });
    },
});

const HeroActions = {
    getList,
    getProfile,
};

export default HeroActions;
export const HeroReducer = slice.reducer;
