import {
    ADMIN_CANDIDATES_PATH,
    ADMIN_JOBS_PATH,
    ADMIN_HIRE_PATH,
} from '../../common/_constants/route.constants';

export const CANDIDATES_JOB_ROLE_PATH = `${ADMIN_CANDIDATES_PATH}/:jobRoleId`;
export const CANDIDATES_REJECTED_PATH = `${ADMIN_CANDIDATES_PATH}/rejected`;
export const CANDIDATES_ARCHIVED_PATH = `${ADMIN_CANDIDATES_PATH}/archived`;

export const JOBS_DRAFTS_PATH = `${ADMIN_JOBS_PATH}/drafts`;
export const ADMIN_JOBS_PREVIEW_PATH = `${ADMIN_JOBS_PATH}/preview/:jobPostId`;

export const ADMIN_WALLET_PATH = '/e-wallet';
export const ADMIN_WALLET_PAY_PATH = `${ADMIN_WALLET_PATH}/pay`;
export const ADMIN_WALLET_CANCEL_PATH = `${ADMIN_WALLET_PATH}/cancel`;
export const ADMIN_WALLET_SUCCESS_PATH = `${ADMIN_WALLET_PATH}/success`;

export const ADMIN_HIRE_SAVED_PATH = `${ADMIN_HIRE_PATH}/saved`;
