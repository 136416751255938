import {
    LOGIN_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
    VERIFY,
    SIGNUP_SUCCESS,
    SIGNUP,
    SIGNUP_FAIL,
} from "../../_constants/actions/auth.constants";
import { UserService } from "../../_services";
import {
    ONBOARDING_EDUCATION_SUCCESS,
    ONBOARDING_TEST_SUCCESS,
    ONBOARDING_SKIP_SUCCESS,
    ONBOARDING_PERSONAL_SUCCESS,
    ONBOARDING_GO_BACK,
} from "../../_constants/actions/onboarding.constants";
import {
    ONBOARDING_EDUCATION_STATUS,
    ACTIVE_STATUS,
    HERO_ONBOARDING_EXPERIENCE_STATUS,
    HERO_ONBOARDING_TEST_STATUS,
    ONBOARDING_TEST_STATUS,
    ONBOARDING_HARD_SKILLS_STATUS,
} from "../../_constants/user.constants";
import { GET_CURRENT_SUCCESS } from "../../_constants/actions/user.constants";
import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";
import OnboardingConstants from "../../_constants/actions/OnboardingConstants";
import SoftSkillsConstants from "../../../candidate/_constants/actions/SoftSkillsConstants";

const initialUser = {
    userStatus: {},
};

export default function (state = UserService.getUser() || initialUser, action) {
    switch (action.type) {
        case LOGIN:
        case LOGIN_FAIL:
        case LOGOUT:
        case SIGNUP:
        case SIGNUP_FAIL:
        case HeroOnboardingConstants.SAVE_USER:
        case HeroOnboardingConstants.SAVE_USER_FAIL:
            return initialUser;

        case LOGIN_SUCCESS:
        case VERIFY_SUCCESS:
        case GET_CURRENT_SUCCESS:
        case SIGNUP_SUCCESS:
            return action.payload;

        case OnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return changeStatus(state, ONBOARDING_HARD_SKILLS_STATUS);
        case OnboardingConstants.SAVE_HARD_SKILLS_SUCCESS:
            return changeStatus(state, ONBOARDING_TEST_STATUS);
        case SoftSkillsConstants.COMPLETE_TEST_SUCCESS:
            return changeStatus(state, ACTIVE_STATUS);

        case HeroOnboardingConstants.SAVE_USER_SUCCESS:
            return action.payload.user;
        case HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS:
            return changeStatus(state, HERO_ONBOARDING_EXPERIENCE_STATUS);
        case HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS:
            return changeStatus(state, HERO_ONBOARDING_TEST_STATUS);

        // case HeroOnboardingConstants.SAVE_TEST_SUCCESS:
        // return changeStatus(state, HERO_ONBOARDING_TEST_STATUS);

        default:
            return state;
    }
}

function changeStatus(state, status) {
    return Object.assign({}, state, {
        userStatus: { status },
    });
}
