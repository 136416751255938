import { createSelector } from "reselect";

import { STATE_LOADING } from "../../common/_constants/state.constants";
import { ArrayHelper } from "../../common/_helpers/ArrayHelper";

const profile = (state) => state.candidate.profileNew;

const isUpdating = createSelector(
    profile,
    (profile) => profile.updateState === STATE_LOADING
);

const isLoading = createSelector(
    profile,
    (profile) => profile.state === STATE_LOADING && profile.id === null
);

const getHeaderTitle = createSelector(
    profile,
    (profile) => profile.headerTitle
);

const getPersonal = createSelector(profile, (profile) => profile.personal);

const getPhoto = createSelector(getPersonal, (personal) => personal.photo);

const getFullName = createSelector(getPersonal, (personal) => {
    const { firstName, lastName, middleName } = personal.names;
    return `${firstName ? firstName + " " : ""}${
        middleName ? middleName + " " : ""
    }${lastName ? lastName + " " : ""}`;
});

const getFirstName = createSelector(getPersonal, (personal) => {
    const { firstName } = personal.names;

    return firstName;
});

const getNames = createSelector(
    getPersonal,
    getFullName,
    (personal, fullName) => ({
        ...personal.names,
        fullName,
    })
);

const getCities = createSelector(getPersonal, (personal) => personal.cities);

const getLastCity = createSelector(getCities, (cities) => {
    if (ArrayHelper.isEmpty(cities)) {
        return null;
    }

    return cities[0];
});

const ProfileSelectors = {
    isUpdating,
    isLoading,
    getNames,
    getFullName,
    getPhoto,
    getHeaderTitle,
    getCities,
    getLastCity,
    getFirstName,
};

export default ProfileSelectors;
