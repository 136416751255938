import { takeLatest, put } from "redux-saga/effects";

import { SagaHelpers } from "../_helpers";
import HeroProfileConstants from "../_constants/actions/HeroProfileConstants";
import HeroProfileService from "../_services/HeroProfileService";
import { NotificationActions } from "../_actions/NotificationActions";

function* getSkills(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.getSkills, action.payload],
        actions: [
            HeroProfileConstants.GET_SKILLS_SUCCESS,
            HeroProfileConstants.GET_SKILLS_FAIL,
        ],
    });
}

function* getSoftSkillsResults(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.getSoftSkillsResults, action.payload],
        actions: [
            HeroProfileConstants.GET_SOFT_SKILLS_RESULTS_SUCCESS,
            HeroProfileConstants.GET_SOFT_SKILLS_RESULTS_FAIL,
        ],
    });
}

const PUBLISH_TRANS_KEY = "common.heroOnboarding.steps.review";
function* publish(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.publish, action.payload],
        action: HeroProfileConstants.PUBLISH,
        onSuccess: function* () {
            yield put(
                NotificationActions.pushSuccess(
                    PUBLISH_TRANS_KEY + ".publishSuccess"
                )
            );
        },
    });
}

function* unpublish(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.unpublish, action.payload],
        action: HeroProfileConstants.UNPUBLISH,
        onSuccess: function* () {
            yield put(
                NotificationActions.pushSuccess(
                    PUBLISH_TRANS_KEY + ".unpublishSuccess"
                )
            );
        },
    });
}

function* getProfilePicture(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.getProfilePicture, action.payload],
        actions: [
            HeroProfileConstants.GET_PROFILE_PICTURE_SUCCESS,
            HeroProfileConstants.GET_PROFILE_PICTURE_FAIL,
        ],
    });
}

function* saveProfilePicture(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroProfileService.saveProfilePicture, action.payload],
        actions: [
            HeroProfileConstants.SAVE_PROFILE_PICTURE_SUCCESS,
            HeroProfileConstants.SAVE_PROFILE_PICTURE_FAIL,
        ],
        messages: {
            success: 'candidate.profile.photo.notifications.upload.success',
            fail: 'candidate.profile.photo.notifications.upload.fail',
        },
    });
}

export default [
    takeLatest(HeroProfileConstants.GET_SKILLS, getSkills),
    takeLatest(
        HeroProfileConstants.GET_SOFT_SKILLS_RESULTS,
        getSoftSkillsResults
    ),
    takeLatest(HeroProfileConstants.PUBLISH, publish),
    takeLatest(HeroProfileConstants.UNPUBLISH, unpublish),
    takeLatest(HeroProfileConstants.GET_PROFILE_PICTURE, getProfilePicture),
    takeLatest(HeroProfileConstants.SAVE_PROFILE_PICTURE, saveProfilePicture),
];
