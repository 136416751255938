import {
    call,
    cancel,
    cancelled,
    fork,
    put,
    take,
    takeLatest,
} from "redux-saga/effects";

import { SagaHelpers } from "../_helpers";
import HeroOnboardingConstants from "../_constants/actions/HeroOnboardingConstants";
import HeroOnboardingService from "../_services/HeroOnboardingService";
import { LanguageService, UserService } from "../_services";
import HeroProfileConstants from "../_constants/actions/HeroProfileConstants";
import HeroProfileService from "../_services/HeroProfileService";
import axios from "axios";
import * as api from "../../types/api";
import { NotificationActions } from "../_actions";
import { END, eventChannel } from "redux-saga";
import HeroOnboardingActions from "../_actions/HeroOnboarding/HeroOnboardingActions";

function* saveUser(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.saveUser, action.payload],
        actions: [
            HeroOnboardingConstants.SAVE_USER_SUCCESS,
            HeroOnboardingConstants.SAVE_USER_FAIL,
        ],
        onSuccess: (response) => {
            const { accessToken, user } = response.data;
            UserService.setAccessToken(accessToken);
            UserService.setProfileId(user.profileId);
            UserService.setUser(user);
        },
    });
}

function* savePersonal(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.savePersonal, action.payload],
        actions: [
            HeroOnboardingConstants.SAVE_PERSONAL_SUCCESS,
            HeroOnboardingConstants.SAVE_PERSONAL_FAIL,
        ],
    });
}

function* savePositions(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.savePositions, action.payload],
        actions: [
            HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS,
            HeroOnboardingConstants.SAVE_POSITIONS_FAIL,
        ],
    });
}

function* saveAdvice(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.saveAdvice, action.payload],
        actions: [
            HeroOnboardingConstants.SAVE_ADVICE_SUCCESS,
            HeroOnboardingConstants.SAVE_ADVICE_FAIL,
        ],
    });
}

function* getQuestions(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.getQuestions, action.payload],
        actions: [
            HeroOnboardingConstants.GET_QUESTIONS_SUCCESS,
            HeroOnboardingConstants.GET_QUESTIONS_FAIL,
        ],
    });
}

function* getTest(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.getTest, action.payload],
        actions: [
            HeroOnboardingConstants.GET_TEST_SUCCESS,
            HeroOnboardingConstants.GET_TEST_FAIL,
        ],
    });
}

function* getTestStatus(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.getTestStatus, action.payload],
        actions: [
            HeroOnboardingConstants.GET_STATUS_SUCCESS,
            HeroOnboardingConstants.GET_STATUS_FAIL,
        ],
    });
}

function* completeTest(action) {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.completeTest, action.payload],
        actions: [
            HeroOnboardingConstants.COMPLETE_TEST_SUCCESS,
            HeroOnboardingConstants.COMPLETE_TEST_FAIL,
        ],
    });
}

// function* startVideoUpload(action) {
//     while (yield take())
//     const task = yield fork(uploadVideo, action);

//     yield take(HeroOnboardingConstants.CANCEL_VIDEO_UPLOAD);

//     yield cancel(task);
// }

function* uploadVideoFromFile(action) {
    yield uploadVideo(
        action,
        HeroOnboardingConstants.UPLOAD_VIDEO_SUCCESS,
        HeroOnboardingConstants.UPLOAD_VIDEO_FAIL
    );
}

function* uploadVideoFromCamera(action) {
    yield uploadVideo(
        action,
        HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO_SUCCESS,
        HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO_FAIL
    );
}

function* uploadVideo(action, successAction, failAction) {
    const cancelToken = axios.CancelToken.source();

    const [uploadPromise, chan] = createUploader(action.payload, cancelToken);
    yield fork(watchOnProgress, chan);

    try {
        const response = yield call(() => uploadPromise);
        if (response) {
            const status = response.status;

            if (status === 200 || status === 201) {
                yield put({
                    type: successAction,
                    payload: response.data,
                });
            }
        }
    } catch (ex) {
        const message = ex.response.data.message;
        yield put({
            type: failAction,
            error: message,
        });
        yield put(NotificationActions.pushError(message));
    } finally {
        if (yield cancelled()) {
            yield call(cancelToken.cancel);
            yield put(HeroOnboardingConstants.UPLOAD_VIDEO_CANCEL);
        }
    }
}

function createUploader(payload, cancelToken) {
    let emit;
    const chan = eventChannel((emitter) => {
        emit = emitter;
        return () => {}; // it's necessarily. event channel should
        // return unsubscribe function. In our case
        // it's empty function
    });

    const uploadPromise = HeroOnboardingService.uploadVideo(
        payload,
        cancelToken,
        (event) => {
            const { loaded, total } = event;
            if (loaded === total) {
                emit({ loaded, total });
                emit(END);
            }

            emit({ loaded, total });
        }
    );

    return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
    while (true) {
        const data = yield take(chan);
        yield put({
            type: HeroOnboardingConstants.VIDEO_UPLOAD_PROGRESS,
            payload: data,
        });
    }
}

function* skipVideo() {
    yield SagaHelpers.handleRequest({
        request: [HeroOnboardingService.skipVideo],
        action: HeroOnboardingConstants.VIDEO_SKIP,
    });
}

export default [
    takeLatest(HeroOnboardingConstants.SAVE_USER, saveUser),
    takeLatest(HeroOnboardingConstants.SAVE_PERSONAL, savePersonal),
    takeLatest(HeroOnboardingConstants.SAVE_POSITIONS, savePositions),
    takeLatest(HeroOnboardingConstants.SAVE_ADVICE, saveAdvice),
    takeLatest(HeroOnboardingConstants.GET_TEST, getTest),
    takeLatest(HeroOnboardingConstants.COMPLETE_TEST, completeTest),
    takeLatest(HeroOnboardingConstants.GET_STATUS, getTestStatus),
    takeLatest(HeroOnboardingConstants.GET_QUESTIONS, getQuestions),
    takeLatest(HeroOnboardingConstants.UPLOAD_VIDEO, uploadVideoFromFile),
    takeLatest(
        HeroOnboardingConstants.UPLOAD_RECORDED_VIDEO,
        uploadVideoFromCamera
    ),
    takeLatest(HeroOnboardingConstants.VIDEO_SKIP, skipVideo),
];
