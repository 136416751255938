import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { Loading } from "../../../components";
import { MainState } from "../../../../root.reducer";
import ForgotPasswordToken from "./components/ForgotPasswordToken";
import ForgotPasswordEmail from "./components/ForgotPasswordEmail";
import AuthPageLayout from "../../../components/layout/AuthPageLayout/AuthPageLayout";

export default function ForgotPasswordPage() {
    const { token } = useParams<{ token?: string }>();
    const { loading, passwordChangePending, passwordChanged } =
        useSelector(mapState);

    if (passwordChanged) {
        return <Redirect to="/" />;
    }

    if (loading) {
        return <Loading />;
    }

    return <AuthPageLayout>{render()}</AuthPageLayout>;

    function render() {
        if (passwordChangePending || token) {
            return <ForgotPasswordToken token={token} />;
        }

        return <ForgotPasswordEmail />;
    }

    function mapState(state: MainState) {
        return state.common.authentication.forgotPassword;
    }
}
