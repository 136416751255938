export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';

export const ACCEPT_PAYMENT = 'ACCEPT_PAYMENT';
export const ACCEPT_PAYMENT_SUCCESS = 'ACCEPT_PAYMENT_SUCCESS';
export const ACCEPT_PAYMENT_FAIL = 'ACCEPT_PAYMENT_FAIL';

export const GET_WALLET = 'GET_WALLET';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAIL = 'GET_WALLET_FAIL';

export const CHANGE_CREDIT_CURRENCY = 'CHANGE_CREDIT_CURRENCY';

export const GET_CREDIT_PRICE = 'GET_CREDIT_PRICE';
export const GET_CREDIT_PRICE_SUCCESS = 'GET_CREDIT_PRICE_SUCCESS';
export const GET_CREDIT_PRICE_FAIL = 'GET_CREDIT_PRICE_FAIL';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAIL = 'GET_CURRENCIES_FAIL';

export const GET_COMPANY_CURRENCY = 'WALLLET__GET_COMPANY_CURRENCY';
export const GET_COMPANY_CURRENCY_SUCCESS = 'WALLLET__GET_COMPANY_CURRENCY_SUCCESS';
export const GET_COMPANY_CURRENCY_FAIL = 'WALLLET__GET_COMPANY_CURRENCY_FAIL';
