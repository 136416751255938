import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    generatePath,
    NavLink,
    Route,
    Switch,
    useParams,
} from "react-router-dom";
import { useTranslate } from "react-translate.ts";

import {
    BackButton,
    InlineLinks,
    JobMatch,
    Loading,
} from "../../../common/components";
import { useRenderToHeader } from "../../../common/hooks/useRenderToHeader";
import HeroProfileActions from "../../../common/_actions/HeroProfileActions";
import {
    HEROES_PATH,
    HEROES_PROFILE_CAREER_PATH,
    HEROES_PROFILE_MATCH_PATH,
    HEROES_PROFILE_PATH,
} from "../../_constants/route.constants";
import HeroProfileSelectors from "../../_selectors/HeroProfileSelectors";
import { Career } from "./routes/Career";
import { CompleteMatch } from "./routes/CompleteMatch";
import { Match } from "./routes/Match";

export function HeroProfile(props) {
    const { heroProfile, isLoading } = useSelector(mapState);
    const params = useParams();
    const dispatch = useDispatch();
    const translate = useTranslate("candidate.heroes.profile");
    const translateMatch = useTranslate("common.jobs.match");

    useEffect(() => {
        dispatch(HeroProfileActions.getProfile(params.id));
    }, [params.id]);

    const renderToHeader = useRenderToHeader();

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    const {
        firstName,
        originalImageUrl,
        lastName,
        experiences,
        matchScore,
        introduction,
    } = heroProfile;

    return (
        <div id="hero-profile">
            {renderToHeader(<BackButton link={HEROES_PATH} />)}
            <div id="hero-profile__info">
                <div id="hero-profile__info__personal">
                    <div className="picture">
                        <img src={originalImageUrl} alt="" />
                    </div>
                    <div className="name">{`${firstName} ${lastName}`}</div>
                    <div className="positions font-book">
                        {experiences.map((item, i) => (
                            <div className="item" key={i}>
                                {item.title}
                            </div>
                        ))}
                    </div>
                </div>
                <div id="hero-profile__info__match">
                    <JobMatch
                        fit={matchScore && matchScore.overallScore}
                        size="medium"
                        label={translateMatch("overall")}
                    />
                    <div className="intro">
                        <div className="intro__title">{translate("intro")}</div>
                        <div className="intro__content font-book">
                            {introduction}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <InlineLinks>
                    <NavLink
                        exact
                        to={generatePath(HEROES_PROFILE_PATH, {
                            id: params.id,
                        })}
                    >
                        {translate("links.match")}
                    </NavLink>
                    <NavLink
                        to={generatePath(HEROES_PROFILE_CAREER_PATH, {
                            id: params.id,
                        })}
                    >
                        {translate("links.advice")}
                    </NavLink>
                    <NavLink
                        to={generatePath(HEROES_PROFILE_MATCH_PATH, {
                            id: params.id,
                        })}
                    >
                        {translate("links.complete")}
                    </NavLink>
                </InlineLinks>
                <div>
                    <Switch>
                        <Route exact path={HEROES_PROFILE_PATH}>
                            <Match />
                        </Route>
                        <Route path={HEROES_PROFILE_CAREER_PATH}>
                            <Career />
                        </Route>
                        <Route path={HEROES_PROFILE_MATCH_PATH}>
                            <CompleteMatch />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

function mapState(state) {
    return {
        heroProfile: HeroProfileSelectors.getProfile(state),
        isLoading: HeroProfileSelectors.isLoading(state),
    };
}
