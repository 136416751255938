import { RequestService } from "../../common/_services/RequestService";

export const CareerPathsService = {
    getMap,
    getMapPaths,
    getTooltip,
    getDetail,
    getCategories,
};

function getMap(params) {
    const {
        jobRoleId,
        industryId,
        degreeFieldId,
        profileType,
        categoryId,
        subcategoryId,
    } = params;
    return RequestService.get("career-maps", [
        ["jobRoleId", jobRoleId],
        ["industryId", industryId],
        ["degreeFieldId", degreeFieldId],
        ["profileType", profileType],
        ["departmentCategoryId", categoryId],
        ["departmentSubcategoryId", subcategoryId],
    ]);
}

function getMapPaths(payload) {
    const { startJobRoleId, endJobRoleId, categoryId, subcategoryId } = payload;

    return RequestService.get("career-paths", [
        ["startJobRoleId", startJobRoleId],
        ["endJobRoleId", endJobRoleId],
        ["type", "TIME"],
        ["departmentCategoryId", categoryId],
        ["departmentSubcategoryId", subcategoryId],
    ]);
}

function getTooltip(jobRoleId) {
    return RequestService.get("jobs/roles/" + jobRoleId + "/tooltip");
}

function getDetail(jobRoleId) {
    return RequestService.get("jobs/roles/" + jobRoleId + "/panel");
}

function getCategories(jobRoleId) {
    return RequestService.get("career-maps/departments", [
        ["jobRoleId", jobRoleId],
    ]);
}
