import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ITeamMember from "../../../common/types/ITeamMember";
import { MainState } from "../../../root.reducer";
import AdminTeamEdit from "../../components/AdminTeam/AdminTeamEdit/AdminTeamEdit";
import AdminTeamActions from "../../_actions/AdminTeamActions";
import AdminTeamSelectors from "../../_selectors/AdminTeamSelectors";
import CompanyPageLayout from "../../components/layout/CompanyPageLayout/CompanyPageLayout";

export default function CompanyTeamPage() {
    const { members, isLoading, updating } = useSelector(mapState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AdminTeamActions.getMembers({}));
    }, [dispatch]);

    return (
        <CompanyPageLayout>
            <AdminTeamEdit
                members={members as ITeamMember[]}
                isLoading={isLoading}
                updating={updating}
                onSearch={(search) =>
                    dispatch(AdminTeamActions.getMembers({ search }))
                }
            />
        </CompanyPageLayout>
    );
}

function mapState(state: MainState) {
    return {
        members: AdminTeamSelectors.getMembers(state),
        isLoading: AdminTeamSelectors.isLoading(state),
        updating: AdminTeamSelectors.getUpdating(state),
    };
}
