import React, { useCallback } from "react";
// import { useSelector } from "react-redux";
import {
    GoogleMap as _GoogleMap,
    Marker,
    useJsApiLoader,
} from "@react-google-maps/api";

import Skeleton from "../Skeleton/Skeleton";
import { useEffect } from "react";
// import { LanguageSelectors } from "../../_selectors/LanguageSelectors";

type GoogleMapProps = {
    locations: Location[];
    zoom?: number;
    height?: number;
    isStylized?: boolean;
    mapStyling?: any;
    customMarker?: any;
};

type GoogleMapState = {
    map: google.maps.Map | null;
    center: google.maps.LatLngLiteral;
};

type Location = { text: string; lat: number; lng: number };

export default function GoogleMap(props: GoogleMapProps) {
    const {
        locations,
        zoom,
        isStylized,
        mapStyling,
        customMarker,
        height = 700,
    } = props;

    // const language = useSelector(LanguageSelectors.getLocale) as string;

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDHPZqULrYL_-a31Stuc-w1zRSAlHw6M-c",
        // language: language.toUpperCase()
    });

    const [state, setState] = React.useState<GoogleMapState>({
        map: null,
        center: { lat: 0, lng: 0 },
    });

    const onLoad = useCallback((map: google.maps.Map) => {
        setState((prev) => ({ ...prev, map }));
    }, []);

    const onUnmount = useCallback(() => {
        setState((prev) => ({ ...prev, map: null }));
    }, []);

    useEffect(() => {
        setState((prev) => ({ ...prev, center: getCenter() }));
    }, [locations]);

    if (!isLoaded) {
        return <Skeleton width="100%" height={height} />;
    }

    return (
        <_GoogleMap
            mapContainerStyle={{ height }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            center={state.center}
            options={{ styles: isStylized ? mapStyling || stylize() : null, gestureHandling: "cooperative" }}
            zoom={zoom}
        >
            {renderMarkers()}
        </_GoogleMap>
    );

    function renderMarkers() {
        if (!locations) {
            return null;
        }

        if (customMarker) {
            return locations.map((location, i) =>
                customMarker(
                    location,
                    i,
                    state.map ? state.map.getZoom() : zoom
                )
            );
        }

        return locations.map(renderMarker);
    }

    function getCenter() {
        if (locations && locations.length > 0) {
            const { lat, lng } = locations[0];
            return { lat, lng };
        }

        return { lat: 0, lng: 0 };
    }

    function renderMarker(location: Location, index: number) {
        const { text, lat, lng } = location;
        return (
            <Marker
                key={index}
                position={{ lat, lng }}
                // label={text}
                /*icon={{
                        url: '/public/img/lunch.png',
                        anchor: new google.maps.Point(32, 32),
                        scaledSize: new google.maps.Size(64, 64)
                    }}*/
            />
        );
    }

    function stylize() {
        return [
            {
                elementType: "geometry.fill",
                stylers: [{ color: "#FFFFFF" }],
            },
            {
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.land_parcel",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "administrative.neighborhood",
                stylers: [{ visibility: "off" }],
            },

            {
                featureType: "landscape",
                elementType: "geometry.fill",
                stylers: [{ color: "#FFFFFF" }],
            },

            {
                featureType: "landscape.natural.terrain",
                elementType: "geometry.fill",
                stylers: [{ color: "#FFFFFF" }],
            },

            {
                featureType: "road",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [{ color: "#88E8FF" }],
            },
        ];
    }
}
