import React from "react";
import { css, cx } from "@emotion/css";
import styles from "./SliderThumb.module.scss";

interface SliderThumbProps {
    className?: string;
    size: number;
    position: number;
}

export default function SliderThumb(props: SliderThumbProps) {
    const { className, size, position } = props;
    return (
        <div
            className={cx(
                styles.thumb,
                className,
                css({
                    left: `${position}%`,
                    width: size,
                    height: size,
                    borderRadius: size,
                })
            )}
        />
    );
}
