import {
    GET_JOB_DETAIL,
    JOB_DETAIL_ACCEPT,
    JOB_DETAIL_APPLY,
} from '../_constants/actions/jobDetail.constants';

export const JobDetailActions = {
    getDetail,
    applyToJob,
    acceptJob,
};

function getDetail(id) {
    return { type: GET_JOB_DETAIL, payload: id };
}

function applyToJob(jobPostId, profileId) {
    return { type: JOB_DETAIL_APPLY, payload: { jobPostId, profileId } };
}

function acceptJob(jobPostId, profileId) {
    return { type: JOB_DETAIL_ACCEPT, payload: { jobPostId, profileId } };
}
