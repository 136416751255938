import React from 'react';
import { useTranslate } from 'react-translate.ts';
import { generatePath, Link } from 'react-router-dom';

import { Flex } from '../layout';
import { JOB_DETAIL_PATH } from '../../_constants/route.constants';
import { Salary } from './Salary';
import { JobMatch } from './JobMatch';
import { Button } from '../buttons';

export function JobCard({ job, className, withLink = false }) {
    const {
        title,
        subtitle,
        salaryMin,
        salaryMax,
        currency,
        salaryType,
        jobPostId,
        jobMatchScore,
    } = job;

    const translate = useTranslate('common');

    return (
        <div className={className}>
            <div className="card has-border">
                <div className="card-body">
                    <Flex wrap="wrap">
                        <strong className="font-14 padding-bottom-10 w100">
                            {title + (subtitle ? ' ' + subtitle : '')}
                        </strong>
                        <Salary
                            salaryMin={salaryMin}
                            salaryMax={salaryMax}
                            currency={currency}
                            frequency={salaryType}
                            className="label font-heavy font-15"
                        />
                        <div className="padding-y-20 w100">
                            <JobMatch
                                size="small"
                                fit={jobMatchScore}
                                label={translate('jobs.match.overall')}
                            />
                        </div>
                        {withLink ? (
                            <Link
                                to={generatePath(JOB_DETAIL_PATH, {
                                    jobPostId,
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button variant="secondary" isPill isThin>
                                    {translate('misc.moreDetails')}
                                </Button>
                            </Link>
                        ) : null}
                    </Flex>
                </div>
            </div>
        </div>
    );
}
