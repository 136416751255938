import ProfileConstants from "../_constants/actions/ProfileConstants";
import { GET_PROFILE_IMAGE } from "../../common/_constants/actions/profile-actions.constants";

const ProfileActions = {
    getProfile,
    uploadProfilePhoto,
    getProfileImage,
};

function getProfileImage(profileId) {
    return { type: GET_PROFILE_IMAGE, payload: profileId };
}

function getProfile(profileId) {
    return { type: ProfileConstants.GET_PROFILE, payload: profileId };
}

/**
 * @param {string} image
 * @param {string} originalImage
 * @param {string} fileName
 * @param {number} profileId
 */
function uploadProfilePhoto(file, originalFile, fileName, profileId) {
    return {
        type: ProfileConstants.UPLOAD_PHOTO,
        payload: { file, originalFile, fileName, profileId },
    };
}

export default ProfileActions;
