import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-translate.ts";

import { useCamera } from "../../hooks/useCamera";
import HeroOnboardingActions from "../../_actions/HeroOnboarding/HeroOnboardingActions";
import { ArrayHelper } from "../../_helpers/ArrayHelper";
import TimeHelpers from "../../_helpers/TimeHelpers";
import { Button } from "../buttons";
import { SVGIcon } from "../SVGIcon";

export function VideoRecord(props) {
    const { onClose, onSave } = props;
    const { videoRef, stream } = useCamera();

    const [playbackPosition, setPlaybackPosition] = useState(0);

    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasRecord, setHasRecord] = useState(false);

    const [recordingTime, setRecordingTime] = useState(0);
    const mediaRecorder = useRef(null);
    const blobs = useRef([]);
    const playbackDuration = useRef(0);
    const recordTimeStamp = useRef(0);

    const [muted, setMuted] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            mediaRecorder.current = null;
            blobs.current = null;
        };
    }, []);

    const interval = useRef(0);
    useEffect(() => {
        if (isRecording) {
            interval.current = setInterval(
                () => setRecordingTime((prevTime) => prevTime + 1),
                1000
            );
        } else {
            stopCounter();
        }

        return stopCounter;
    }, [isRecording]);

    function stopCounter() {
        clearInterval(interval.current);
        interval.current = null;
    }

    return (
        <div id="video-record">
            <video
                ref={videoRef}
                muted={muted}
                onEnded={() => setIsPlaying(false)}
            />
            <Live isRecording={isRecording} time={recordingTime} />
            <button className="close" onClick={onClose}>
                <SVGIcon name="chevron-left" />
            </button>
            <button className="record" onClick={handleRecording}>
                {isRecording ? <StopButton /> : <RecordButton />}
            </button>
            {hasRecord && !isRecording ? (
                <>
                    <button className="play" onClick={handlePlay}>
                        {isPlaying ? <PauseButton /> : <PlayButton />}
                    </button>
                    <button className="delete" onClick={deleteVideo}>
                        <img src="/img/icons/trash.svg" alt="" />
                    </button>
                    {videoRef.current ? (
                        <Playback
                            video={videoRef.current}
                            position={playbackPosition}
                            onChange={setPlaybackPosition}
                            duration={playbackDuration}
                        />
                    ) : null}
                </>
            ) : null}
            <Button className="save" onClick={handleSave} disabled={!hasRecord}>
                <Translate textOnly>
                    common.heroOnboarding.steps.video.record.save
                </Translate>
            </Button>
            {/* <div id="video-record__controls"></div> */}
        </div>
    );

    function deleteVideo() {
        videoRef.current.srcObject = null;
        videoRef.current.src = null;

        setHasRecord(false);
    }

    function handleSave() {
        if (hasRecord) {
            const file = new File(blobs.current, "video.mp4", {
                type: "video/mp4",
                lastModified: new Date().getTime(),
            });

            const formData = new FormData();
            formData.append("file", file);

            dispatch(HeroOnboardingActions.Video.uploadRecorded(formData));
            onClose();
        }
    }

    function handlePlay() {
        if (isPlaying) {
            stopVideo();
        } else {
            startVideo();
        }
    }

    function startVideo() {
        setIsPlaying(true);

        videoRef.current.play();
    }

    function stopVideo() {
        setIsPlaying(false);
        videoRef.current.pause();
        //videoRef.current.src = null;
        //videoRef.current.srcObject = null;
        //videoRef.current.src = stream;
    }

    function handleRecording() {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    function startRecording() {
        const mimeType = getSupportedMimeTypes()[0];
        blobs.current = [];

        try {
            mediaRecorder.current = new MediaRecorder(stream.current, {
                mimeType,
            });
            mediaRecorder.current.onstop = onMediaRecorderStop;
            mediaRecorder.current.ondataavailable = handleDataAvailable;
            mediaRecorder.current.start();
            videoRef.current.src = null;
            videoRef.current.srcObject = stream.current;
            videoRef.current.play();

            recordTimeStamp.current = TimeHelpers.performance();

            setIsRecording(true);
            setMuted(true);
        } catch (ex) {
            console.error(ex);
            return;
        }
    }

    function stopRecording() {
        setIsRecording(false);
        setRecordingTime(0);

        mediaRecorder.current && mediaRecorder.current.stop();
    }

    function onMediaRecorderStop() {
        const recordEndTimeStamp = TimeHelpers.performance();

        if (!ArrayHelper.isEmpty(blobs.current)) {
            playbackDuration.current =
                recordEndTimeStamp - recordTimeStamp.current;

            const superBuffer = new Blob(blobs.current, {
                type: "video/mp4;codecs=h264,aac",
            });
            videoRef.current.srcObject = null;
            videoRef.current.src = window.URL.createObjectURL(superBuffer);

            setHasRecord(true);
            setMuted(false);
        }
    }

    function handleDataAvailable(event) {
        if (event.data && event.data.size > 0) {
            blobs.current.push(event.data);
        }
    }

    function getSupportedMimeTypes() {
        const possibleTypes = [
            "video/webm;codecs=vp9,opus",
            "video/webm;codecs=vp8,opus",
            "video/webm;codecs=h264,opus",
            "video/mp4;codecs=h264,aac",
        ];
        return possibleTypes.filter((mimeType) => {
            return MediaRecorder.isTypeSupported(mimeType);
        });
    }
}

function Playback(props) {
    const { video, duration, onChange } = props;
    const barRef = useRef(null);

    const isDragging = useRef(false);

    useEffect(() => {
        document.body.addEventListener("mousemove", drag);
        document.body.addEventListener("mouseup", endDragging);
        document.body.addEventListener("mouseleave", endDragging);
        video.addEventListener("timeupdate", () => {
            const position = (video.currentTime * 1000) / duration.current;
            onChange(position);
        });

        return () => {
            document.body.removeEventListener("mousemove", drag);
            document.body.removeEventListener("mouseup", endDragging);
            document.body.removeEventListener("mouseleave", endDragging);
        };
    }, []);

    return (
        <div className="playback">
            <div
                ref={barRef}
                className="bar-wrapper"
                onMouseDown={startDragging}
                onClick={setNewPosition}
            >
                <div className="bar" />
            </div>
            <div
                className="handle"
                onMouseDown={startDragging}
                style={{ left: `${props.position * 100}%` }}
            >
                <Time time={video.currentTime} />
            </div>
        </div>
    );

    function startDragging() {
        isDragging.current = true;
    }

    function endDragging() {
        isDragging.current = false;
    }

    function drag(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!isDragging.current) {
            return;
        }

        setNewPosition(event);
    }

    function setNewPosition(event) {
        const { clientX } = event;
        const barRect = barRef.current.getBoundingClientRect();
        const barX = barRect.x;
        const barWidth = barRect.width;
        let x = clientX - barX;
        if (x < 0) {
            x = 0;
        } else if (x > barWidth) {
            x = barWidth;
        }

        const newPosition = x / barWidth;

        video.currentTime = (duration.current / 1000) * newPosition;

        props.onChange(newPosition);
    }
}

function RecordButton() {
    return (
        <div className="record-button">
            <div />
        </div>
    );
}

function StopButton() {
    return <div className="stop-button" />;
}

function PlayButton() {
    return (
        <div className="play-button">
            <SVGIcon name="play" />
        </div>
    );
}

function PauseButton() {
    return (
        <div className="pause-button">
            <div />
            <div />
        </div>
    );
}

function Live(props) {
    if (props.isRecording) {
        return (
            <div className="live">
                <div className="dot" />
                <div>
                    <Time time={props.time} />
                </div>
            </div>
        );
    }

    return null;
}

const HOUR = 3600;
const MINUTE = 60;
function Time(props) {
    const time = Math.floor(props.time);
    const hours = Math.floor(time / HOUR);
    const hourSubstract = HOUR * hours;
    const minutes = Math.floor((time - hourSubstract) / MINUTE);
    const seconds = time - hourSubstract - MINUTE * minutes;

    return (
        <span>
            {hours > 0 ? `${hours}:` : null}
            {minutes <= 9 ? `0${minutes}` : minutes}:
            {seconds <= 9 ? `0${seconds}` : seconds}
        </span>
    );
}
