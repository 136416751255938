import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { HardSkillsContent } from './';
import {
    PROFILE_PATH_INFO,
    PROFILE_HARD_SKILLS_PATH,
    PROFILE_CONTACT_PATH,
} from '../../../_constants/route.constants';
import { ContactContent } from './contact/ContactContent';
import { InformationContent } from '../../../../common/components';
import {
    PROFILE_EDUCATION_PATH,
    PROFILE_EXPERIENCE_PATH,
} from '../../../../common/_constants/route.constants';
import { useSelector } from 'react-redux';
import ProfileInformationSelectors from '../../../_selectors/ProfileInformationSelectors';

export function ProfileContent({ profile }) {
    const skills = useSelector(ProfileInformationSelectors.getHardSkillsList);
    const history = useHistory();

    return (
        <div className="content">
            <div className="inner">
                <Switch>
                    <Route path={PROFILE_PATH_INFO}>
                        <InformationContent
                            spacing={10}
                            profile={profile}
                            onEducationEdit={editEducation}
                            onEducationAdd={addEducation}
                            onExperienceEdit={editExperience}
                            onExperienceAdd={addExperience}
                            displayOnly={false}
                        />
                    </Route>
                    <Route path={PROFILE_HARD_SKILLS_PATH}>
                        <HardSkillsContent skills={skills} />
                    </Route>
                    {/* <Route
                        path={PROFILE_CONTACT_PATH}
                        component={ContactContent}
                    /> */}
                    {/*<Route*/}
                    {/*    path={PROFILE_PREFERENCES_PATH}*/}
                    {/*    component={PreferencesContent}*/}
                    {/*/>*/}
                </Switch>
            </div>
        </div>
    );

    function addEducation() {
        history.push(PROFILE_EDUCATION_PATH);
    }

    function editEducation(id) {
        history.push(`${PROFILE_EDUCATION_PATH}/${id}`);
    }

    function addExperience() {
        history.push(PROFILE_EXPERIENCE_PATH);
    }

    function editExperience(id) {
        history.push(`${PROFILE_EXPERIENCE_PATH}/${id}`);
    }
}
