import {
    GET_ARCHIVED_CARDS_SUCCESS,
    GET_ARCHIVED_CARDS_FAIL
} from '../../../_constants/actions/candidateTracking.constants';
import {
    TRACKING_ACCEPTED,
    TRACKING_APPLIED,
    TRACKING_INVITED,
    TRACKING_SHORTLISTED
} from '../../../_constants/actions/tracking.constants';

const initialState = {
    [TRACKING_ACCEPTED]: [],
    [TRACKING_APPLIED]: [],
    [TRACKING_INVITED]: [],
    [TRACKING_SHORTLISTED]: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ARCHIVED_CARDS_SUCCESS:
            return { ...initialState, ...action.payload };
        case GET_ARCHIVED_CARDS_FAIL:
            return initialState;
        default:
            return state;
    }
}
