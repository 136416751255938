import React from "react";
import { createClass } from "classname-helper";
import { FieldError } from "./FieldError";
import { Translate } from "react-translate.ts";

export function Input({
    id,
    options,
    type,
    label,
    required,
    className,
    value,
    containerClass,
    error,
    hasError,
    children,
    displayName,
    resize,
    ...rest
}) {
    function renderMain() {
        switch (type) {
            case "textarea":
                return renderTextArea();
            case "select":
                return renderSelect();
            default:
                return renderInput();
        }
    }

    function renderSelect() {
        const inputClassName = `${className} ${hasError ? "has-danger" : ""}`;
        return (
            <select
                type={type}
                {...rest}
                className={inputClassName}
                value={value}
            >
                {options.map((option) => (
                    <option
                        className="with-flag flag-cz"
                        key={option.value}
                        value={option.value}
                    >
                        {option.title}
                    </option>
                ))}
            </select>
        );
    }

    function renderInput() {
        const inputClassName = `${className} flex-shrink-half input ${
            hasError ? "has-danger" : ""
        }`;

        return (
            <div className="flex align-items-center">
                <input
                    type={type}
                    {...rest}
                    className={inputClassName}
                    value={value}
                />
                {children}
            </div>
        );
    }

    function renderTextArea() {
        const inputClassName = createClass({
            input: true,
            [className]: !!className,
            "has-danger": hasError,
            noresize: resize === false,
        });
        return (
            <textarea
                type={type}
                value={value}
                {...rest}
                className={inputClassName}
            />
        );
    }

    function renderError() {
        if (!hasError || error === "required") {
            return null;
        }

        return <FieldError name={displayName} error={error} />;
    }

    function renderLabel() {
        if (!label) {
            return null;
        }

        const labelClassName = required ? "required" : "";

        return (
            <label className={labelClassName}>
                <Translate textOnly>{label}</Translate>
            </label>
        );
    }

    function renderRequired() {
        if (!required) {
            return null;
        }

        const requiredClassName = `required ${
            hasError && error === "required" ? "has-danger" : ""
        }`;

        return (
            <div className={requiredClassName}>
                {/*{<Translate>Required</Translate>}*/}
            </div>
        );
    }

    return (
        <div id={id} className={createContainerClass()}>
            {renderLabel()}
            {renderMain()}
            {renderRequired()}
            {renderError()}
        </div>
    );

    function createContainerClass() {
        return createClass({
            "input-field": true,
            flex: true,
            "flex-column": true,
            [containerClass]: !!containerClass,
        });
    }
}
