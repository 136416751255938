import { NOTIFICATION_TYPE_ERROR } from '../../common/_constants/actions/notification.constants';
import { profileConstants } from '../_constants/actions/profile.constants';
import { NotificationActions } from '../../common/_actions';
import { ProfileService } from '../_services';

export const ProfileActions = {
    jobRoles,

    updateEmail,
    updateEmailSuccess,
    updateEmailFail,
    verifyEmail,
    verifyEmailSuccess,
    verifyEmailFail,
    updatePhone,
    updatePhoneSuccess,
    updatePhoneFail,
    updateAddress,
    updateAddressSuccess,
    updateAddressFail,
};

function jobRoles(search) {
    return (dispatch) => {
        dispatch({ type: profileConstants.JOB_ROLES_BEGIN });

        ProfileService.jobRoles(search).then(
            (response) => {
                dispatch({
                    type: profileConstants.JOB_ROLES_SUCCESS,
                    list: response.data,
                });
            },
            (response) => {
                const { error } = response.error;
                dispatch({ type: profileConstants.JOB_ROLES_FAILURE, error });
                dispatch(
                    NotificationActions.push(error, NOTIFICATION_TYPE_ERROR)
                );
            }
        );
    };
}

function updateEmail(email) {
    return { type: profileConstants.UPDATE_EMAIL, payload: email };
}

function updateEmailSuccess(success) {
    return { type: profileConstants.UPDATE_EMAIL_SUCCESS, payload: success };
}

function updateEmailFail(error) {
    return { type: profileConstants.UPDATE_EMAIL_FAIL, error };
}

function verifyEmail(payload) {
    return { type: profileConstants.VERIFY_EMAIL, payload };
}

function verifyEmailSuccess(success) {
    return { type: profileConstants.VERIFY_EMAIL_SUCCESS, payload: success };
}

function verifyEmailFail(error) {
    return { type: profileConstants.VERIFY_EMAIL_FAIL, error };
}

function updatePhone(phone) {
    return { type: profileConstants.UPDATE_PHONE, payload: phone };
}

function updatePhoneSuccess(success) {
    return { type: profileConstants.UPDATE_PHONE_SUCCESS, payload: success };
}

function updatePhoneFail(error) {
    return { type: profileConstants.UPDATE_PHONE_FAIL, error };
}

function updateAddress(payload) {
    return { type: profileConstants.UPDATE_ADDRESS, payload };
}

function updateAddressSuccess(success) {
    return { type: profileConstants.UPDATE_ADDRESS_SUCCESS, payload: success };
}

function updateAddressFail(error) {
    return { type: profileConstants.UPDATE_ADDRESS_FAIL, error };
}
