import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "react-translate.ts";

import { ProfileItemCard } from "../ProfileItemCard";
import { PROFILE_INTERESTS_PATH } from "../../../../candidate/_constants/route.constants";
import { Flex } from "../../layout";
import { ArrayHelper } from "../../../_helpers/ArrayHelper";
import { EditButton, AddButton } from "../../buttons";
import ProfileInformationSelectors from "../../../../candidate/_selectors/ProfileInformationSelectors";

export function Interests({ displayOnly = false }) {
    const interests = useSelector(ProfileInformationSelectors.getInterests);
    const hasInterests = !ArrayHelper.isEmpty(interests);

    return render();
    function render() {
        return (
            <ProfileItemCard
                label={
                    <Translate textOnly>
                        candidate.profile.information.interest.title
                    </Translate>
                }
                extraContent={
                    displayOnly ? null : (
                        <Link to={PROFILE_INTERESTS_PATH}>
                            {hasInterests ? <EditButton /> : <AddButton />}
                        </Link>
                    )
                }
            >
                <Flex wrap="wrap" spacing={3}>
                    {hasInterests ? (
                        interests.map((interest, key) => (
                            <Interest key={key} label={interest.content} />
                        ))
                    ) : (
                        <Placeholder />
                    )}
                </Flex>
            </ProfileItemCard>
        );
    }
}

function Interest({ label }) {
    return (
        <div>
            <div className="interest-pill">#{label}</div>
        </div>
    );
}

function Placeholder() {
    return (
        <p className="text-secondary">
            <Translate textOnly>
                candidate.profile.information.interest.placeholder
            </Translate>
        </p>
    );
}
