import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { LanguageActions } from "../../_actions";
import { LanguageSelectors } from "../../_selectors/LanguageSelectors";
import { LanguageService } from "../../_services";
import { Flex } from "../layout";

const LOCALES = ["en", "pl" /*'cs', 'sk'*/];
const LANGUAGES = {
    cs: "Česky",
    en: "English",
    sk: "Slovensky",
    pl: "Polski",
};

export function Languages({ onSelect }) {
    const { pathname } = useLocation();
    const { allowedLocales, locale } = useSelector(mapState);

    // const current = LanguageService.getCurrentLocale();

    const locales = useMemo(
        () => [...LOCALES, ...allowedLocales],
        [allowedLocales]
    ); // LOCALES.filter((locale) => locale !== current);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!locales.includes(locale)) {
            dispatch(LanguageActions.changeLocale(LOCALES[0]));
        }
    }, [locale, locales]);

    return (
        <ul>
            {locales.map((locale, key) => (
                <Language
                    key={key}
                    locale={locale}
                    path={pathname}
                    onSelect={onSelect}
                />
            ))}
        </ul>
    );
}

function Language({ locale, onSelect, path }) {
    if (!LANGUAGES[locale]) {
        return null;
    }

    return (
        <li onClick={onLanguageChange}>
            <Flex>
                {/* <div className={`lang-flag icon flag-${locale}`}></div> */}
                <div>{LANGUAGES[locale]}</div>
            </Flex>
        </li>
    );

    function onLanguageChange() {
        LanguageService.changeLocale(locale);
        onSelect && onSelect();
        window.location.replace(path);
    }
}

function mapState(state) {
    return {
        allowedLocales: LanguageSelectors.getAllowedLocales(state),
        locale: LanguageSelectors.getLocale(state),
    };
}
