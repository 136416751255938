import React from 'react';
import { Link } from 'react-router-dom';
import { Translate, useTranslate } from 'react-translate.ts';

import { ArrayHelper } from '../../../_helpers/ArrayHelper';
import { ProfileItemCard } from '../';
import { PROFILE_LANGUAGES_PATH } from '../../../../candidate/_constants/route.constants';
import { EditButton, AddButton } from '../../buttons';
import { JobMatch } from '../../jobs';
import { SkillMatchBar } from '../../charts/SkillMatchBar';
import { useSelector } from 'react-redux';
import ProfileInformationSelectors from '../../../../candidate/_selectors/ProfileInformationSelectors';

/**
 *
 * @param {{ langugages: [] }} props
 */
export function Languages({ match, displayOnly = false }) {
    const languages = useSelector(ProfileInformationSelectors.getLanguagesList);

    const hasLanguages = !ArrayHelper.isEmpty(languages);
    const steps = ['A2', 'B1', 'B2', 'C1', 'C2', 'Native'];
    return (
        <ProfileItemCard
            label="candidate.profile.information.language.title"
            extraContent={
                <ExtraContent {...{ displayOnly, hasLanguages, match }} />
            }
        >
            {hasLanguages ? (
                <div className="progress-chart">
                    <div className="progress-chart--grid">
                        {languages.map((item, key) => {
                            const { level, language } = item;
                            return (
                                <SkillMatchBar
                                    key={key}
                                    level={steps.indexOf(level.name)}
                                    name={language.name}
                                    steps={steps}
                                    noYears
                                />
                            );
                        })}
                    </div>
                </div>
            ) : (
                <Placeholder />
            )}
        </ProfileItemCard>
    );
}

function Placeholder() {
    return (
        <p className="text-secondary">
            <Translate textOnly>
                candidate.profile.information.language.placeholder
            </Translate>
        </p>
    );
}

function ExtraContent({ displayOnly, match, hasLanguages }) {
    const translate = useTranslate();

    if (displayOnly) {
        return (
            <JobMatch
                fit={match}
                label={translate('common.jobs.match.language')}
                size="small"
            />
        );
    }

    return (
        <Link to={PROFILE_LANGUAGES_PATH}>
            {hasLanguages ? <EditButton /> : <AddButton />}
        </Link>
    );
}
