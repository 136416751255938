import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addClass } from "classname-helper";
import { useTranslate, Translate } from "react-translate.ts";

import { CandidateBaseInfo } from "./CandidateBaseInfo";
import {
    Flex,
    InformationContent,
    LoadingOverlay,
    InlineLinks,
    ProfileItemCard,
} from "../../../common/components";
import { HardSkills, SoftSkills } from "./";
import { CandidateActionPanel } from "./CandidateActionPanel";
import { CandidateActions } from "../../_actions";

const INFO_TAB = "info";
const SOFT_SKILLS_TAB = "soft";
const HARD_SKILLS_TAB = "hard";
const CONTACT_TAB = "contact";

export function CandidateOverlay({
    candidate,
    jobPostId,
    profileId,
    children,
    onInvite,
    onFavourite,
    onReject,
    onAccept,
    noActionButtons = false,
    withAcceptAction,
    withoutSaveAction,
}) {
    const [tab, setTab] = useState(INFO_TAB);

    const dispatch = useDispatch();

    useEffect(() => {
        if (profileId) {
            dispatch(CandidateActions.getProfile(profileId, jobPostId));
            dispatch(CandidateActions.getProfileMatch(profileId, jobPostId));
        }
    }, [profileId, jobPostId]);

    const { profile, isLoading } = useSelector(mapState);

    if (!candidate) {
        return null;
    }

    const scores = candidate.scores;

    const links = [
        {
            name: INFO_TAB,
            label: "common.profile.tabs.information",
        },
        {
            name: SOFT_SKILLS_TAB,
            label: "common.profile.tabs.softSkills",
        },
        {
            name: HARD_SKILLS_TAB,
            label: "common.profile.tabs.hardSkills",
        },
        {
            name: CONTACT_TAB,
            label: "common.profile.tabs.contact",
        },
    ];

    return (
        <Flex direction="column" id="candidate-overlay">
            {isLoading ? (
                <LoadingOverlay isLoading={isLoading} />
            ) : (
                <>
                    {children}
                    <Header
                        {...{
                            profile,
                            onInvite,
                            tab,
                            onChange: (newTab) => setTab(newTab),
                            links,
                            candidate,
                            onFavourite,
                            onReject,
                            onAccept,
                            noActionButtons,
                            withAcceptAction,
                            withoutSaveAction,
                        }}
                    />
                    <div id="candidate-overlay--content">
                        <Content
                            {...{
                                profile,
                                unlocked: candidate.unlocked,
                                scores,
                                tab,
                            }}
                        />
                    </div>
                </>
            )}
        </Flex>
    );
}

function Link({ link, tab, onChange }) {
    const translate = useTranslate();
    const isCurrent = link.name === tab;
    return (
        <div
            className={addClass(
                "nav-link clickable",
                isCurrent ? "active" : ""
            )}
            onClick={() => onChange(link.name)}
        >
            {translate(link.label)}
        </div>
    );
}

function Content({ profile, unlocked, scores, tab }) {
    switch (tab) {
        case SOFT_SKILLS_TAB:
            return (
                <SoftSkills
                    match={profile.match}
                    fit={scores.jobMatchSoftSkillScore}
                />
            );
        case HARD_SKILLS_TAB:
            return (
                <HardSkills
                    match={profile.match}
                    fit={scores.jobMatchHardSkillScore}
                    skills={profile.skills}
                />
            );
        case CONTACT_TAB:
            return <Contact unlocked={unlocked} profile={profile} />;
        default:
            return (
                <InformationContent
                    profile={profile}
                    scores={scores}
                    displayOnly={true}
                />
            );
    }
}

function Contact(props) {
    const translate = useTranslate("common.profile");

    return (
        <ProfileItemCard label="candidate.profile.contact.title">
            <div className="padding-top-20">
                <Inner />
            </div>
        </ProfileItemCard>
    );

    function Inner() {
        const { unlocked, profile } = props;
        if (unlocked !== true) {
            return <Translate>admin.candidate.locked.unlock</Translate>;
        }

        const email = profile.emails && profile.emails[0];
        const phone = profile.phoneNumbers && profile.phoneNumbers[0];
        return (
            <>
                {renderItem(
                    phone,
                    "phone",
                    (value) => `+${value.country.dial} ${value.number}`
                )}
                {renderItem(email, "email", (value) => (
                    <a
                        href={`mailto: ${value.email}`}
                        className="underline text-blue"
                    >
                        {value.email}
                    </a>
                ))}
            </>
        );
    }

    function renderItem(value, name, createValue) {
        return (
            <div
                className="padding-bottom-10"
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 8fr",
                    gap: 10,
                }}
            >
                {value ? (
                    <>
                        <span className="text-secondary padding-right-10 font-14">
                            {translate(name)}
                        </span>
                        <span>{createValue(value)}</span>
                    </>
                ) : null}
            </div>
        );
    }
}

function mapState(state) {
    const { data, loading } = state.admin.candidates.profile;

    return {
        profile: data,
        isLoading: loading,
    };
}

function Header({
    profile,
    candidate,
    onInvite,
    onFavourite,
    onReject,
    links,
    tab,
    onChange,
    onAccept,
    noActionButtons,
    withAcceptAction,
    withoutSaveAction,
}) {
    const { jobApplicationStatus, isFavourite, unlocked } = candidate;
    return (
        <div id="candidate-overlay--header">
            <div id="candidate-overlay--header--content">
                <CandidateBaseInfo
                    profile={profile}
                    pictureSize="medium"
                    isLocked={!unlocked}
                />
                {noActionButtons ? null : (
                    <div>
                        <CandidateActionPanel
                            onInvite={() => onInvite(candidate)}
                            onSave={() => onFavourite(candidate)}
                            onReject={() => onReject(candidate)}
                            onAccept={() => onAccept(candidate)}
                            status={jobApplicationStatus}
                            isSaved={isFavourite}
                            withAccept={withAcceptAction}
                            withoutSave={withoutSaveAction}
                        />
                    </div>
                )}
            </div>
            <InlineLinks>
                {links.map((link, key) => (
                    <Link {...{ key, link, tab, onChange }} />
                ))}
            </InlineLinks>
        </div>
    );
}
