import React from 'react';
import { useSelector } from 'react-redux';
import ProfileInformationSelectors from '../../../../candidate/_selectors/ProfileInformationSelectors';

import {
    AboutMe,
    ExperienceList,
    Languages,
    Interests,
    JobExperienceItem,
    EducationItem,
} from './';

/**
 *
 * @param {{
 *      spacing: number;
 *      displayOnly: boolean;
 *      onEducationEdit: (index: number) => any;
 *      onExperienceEdit: (index: number) => any;
 *      onEducationAdd: () => any;
 *      onExperienceAdd: () => any;
 * }} props
 */
export function InformationContent({
    spacing,
    profile,
    scores,
    displayOnly = true,
    onEducationEdit,
    onExperienceEdit,
    onEducationAdd,
    onExperienceAdd,
}) {
    const { languages } = profile;
    const jobMatchScore = scores ? scores.jobMatchScore : 0;
    const jobMatchLanguageScore = scores ? scores.jobMatchLanguageScore : 0;

    const { jobExperience, educations } = useSelector(mapState);

    return (
        <div id="information-content">
            <div>
                <AboutMe match={jobMatchScore} displayOnly={displayOnly} />
            </div>
            <div>
                <ExperienceList
                    items={jobExperience}
                    label="candidate.profile.information.experience.title"
                    placeholder="candidate.profile.information.experience.placeholder"
                    Item={JobExperienceItem}
                    displayOnly={displayOnly}
                    onEdit={onExperienceEdit}
                    onAdd={onExperienceAdd}
                />
            </div>
            <div>
                <ExperienceList
                    items={educations}
                    label="candidate.profile.information.education.title"
                    placeholder="candidate.profile.information.education.placeholder"
                    Item={EducationItem}
                    displayOnly={displayOnly}
                    onEdit={onEducationEdit}
                    onAdd={onEducationAdd}
                />
            </div>
            <div>
                <Languages
                    languages={languages}
                    match={jobMatchLanguageScore}
                    displayOnly={displayOnly}
                />
            </div>
            <div>
                <Interests displayOnly={displayOnly} />
            </div>
        </div>
    );
}

function mapState(state) {
    return {
        jobExperience: ProfileInformationSelectors.getExperiencesList(state),
        educations: ProfileInformationSelectors.getEducationsList(state),
    };
}
