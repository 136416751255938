import {
    NOTIFICATION_TYPE_ERROR,
    NOTIFICATION_TYPE_SUCCESS,
} from '../_constants/actions/notification.constants';
import {
    UserConstants,
    GET_CURRENT,
} from '../_constants/actions/user.constants';

import { UserService } from '../_services';
import { NotificationActions } from './';

export const UserActions = {
    current,
    generateVerificationToken,
    forgotPassword,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFail,
};

function current() {
    return { type: GET_CURRENT };
}

function generateVerificationToken() {
    return (dispatch) => {
        UserService.generateNewVerificationCode().then(
            (response) => {
                dispatch(
                    NotificationActions.push(
                        'auth.generate-verification-token.success',
                        NOTIFICATION_TYPE_SUCCESS
                    )
                );
            },
            (response) => {
                NotificationActions.push(
                    'auth.generate-verification-token.fail',
                    NOTIFICATION_TYPE_ERROR
                );
            }
        );
    };
}

function forgotPassword(email) {
    return (dispatch) => {
        dispatch({ type: UserConstants.FORGOT_PASSWORD_BEGIN });
        UserService.forgotPassword(email).then(
            (response) => {
                const { data } = response;
                dispatch({
                    type: UserConstants.FORGOT_PASSWORD_SUCCESS,
                    payload: { success: data },
                });
                NotificationActions.push(
                    'auth.forgot-password.success',
                    NOTIFICATION_TYPE_SUCCESS
                );
            },
            (response) => {
                const { error } = response;
                dispatch({ type: UserConstants.FORGOT_PASSWORD_ERROR, error });
                dispatch(
                    NotificationActions.push(
                        'auth.forgot-password.fail',
                        NOTIFICATION_TYPE_ERROR
                    )
                );
            }
        );
    };
}

/**
 *
 * @param {string} token
 * @param {string} password
 */
function resetPassword(token, password) {
    return { type: UserConstants.RESET_PASSWORD, payload: { token, password } };
}

function resetPasswordSuccess() {
    return { type: UserConstants.RESET_PASSWORD_SUCCESS };
}

function resetPasswordFail(error) {
    return { type: UserConstants.RESET_PASSWORD_ERROR, error };
}
