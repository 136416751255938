import React from "react";
import { useTranslate } from "react-translate.ts";
import { useDispatch } from "react-redux";

import { Button } from "../../../../common/components";
import { MathHelper } from "../../../../common/_helpers";
import { MeterBar } from "./MeterBar";
import { RadialProgressChart } from "../../../../common/components/charts/RadialProgressChart";
import {
    BREAKPOINT_SM,
    PRIMARY_COLOR,
} from "../../../../common/_constants/theme.constants";
import { BaseDiagnostic } from "./BaseDiagnostic";
import { Link, useRouteMatch } from "react-router-dom";
import { InterpretationInfo } from "./InterpretationInfo";
import { InterpretationLanguages } from "./InterpretationLanguages";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import InterpretationActions from "../../../_actions/InterpretationActions";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export function BasePress(props) {
    const dispatch = useDispatch();

    const { id, name, icon, extraContent } = props;
    const translate = useTranslate(TRANSLATE_KEY);
    const translatePress = (key) => translate(`${name}.${key}`);
    const translateTitles = (key) => translatePress("titles." + key);
    const translateInterpretation = (key) =>
        translatePress("interpretation." + key);

    const { path } = useRouteMatch();

    const isMobile = useBreakpoint(BREAKPOINT_SM);

    return (
        <BaseDiagnostic
            id={"soft-skills-test--" + (id || name)}
            name={name}
            contentUnprocessed={renderUnprocessed}
            contentProcessed={renderProcessed}
            interpretationContentEmpty={renderUnprocessedInterpretation}
            interpretationContentProcessed={renderProcessedInterpretation}
            extraContent={extraContent}
            getStatus={getStatus}
            getInterpretation={getInterpretation}
            isMobile={isMobile}
        />
    );

    function getStatus(profileId, name) {
        dispatch(SoftSkillsActions.getStatus(profileId, name));
    }

    function getInterpretation(profileId, name) {
        dispatch(InterpretationActions.getInterpretation(profileId, name));
    }

    function renderUnprocessed(_, __, status) {
        return (
            <>
                <div id="soft-skills-test--press__start">
                    <h2>{translateTitles("start")}</h2>
                    <p>{translatePress("info")}</p>
                    {status.unlocked === true ? (
                        <Link to={path + "/test"}>
                            <Button>{translatePress("start")}</Button>
                        </Link>
                    ) : (
                        <Button disabled>{translatePress("start")}</Button>
                    )}
                </div>
                <div>
                    <div>
                        <h5>{translate("titles.quality")}</h5>
                        <MeterBar value={0.3} disabled />
                    </div>
                    <div>
                        <h5>{translate("titles.speed")}</h5>
                        <MeterBar value={0.3} disabled />
                    </div>
                </div>
            </>
        );
    }

    function renderProcessed(interpretation) {
        if (
            !interpretation.skillInterpretations ||
            !interpretation.skillInterpretations.all ||
            !interpretation.skillInterpretations.all.overall ||
            !interpretation.softSkills ||
            !interpretation.softSkills.speed ||
            !interpretation.softSkills.accuracy
        ) {
            return null; // TODO LOADING
        }

        const overall = interpretation.skillInterpretations.all.overall;
        const title = overall[0].title;
        const speed = overall[1];
        const quality = overall[2];

        const skills = interpretation.softSkills;
        const speedScore = skills.speed[0].value;
        const qualityScore = skills.accuracy[0].value;

        return (
            <div>
                <div id="soft-skills-test--press--results">
                    <h6>{title}</h6>
                    <p>
                        {speed.title} {speed.description}
                    </p>
                    <p>
                        {quality.title} {quality.description}
                    </p>
                </div>
                <div className="margin-auto">
                    <div id="soft-skills-test--press--bars">
                        <IconBar
                            name={translate("values.quality")}
                            value={qualityScore}
                            icon="shield"
                        />
                        <IconBar
                            name={translate("values.speed")}
                            value={speedScore}
                            icon="speed"
                        />
                    </div>
                    <Link to={path + "/compare"}>
                        <Button variant="secondary">
                            {translate("actions.compare")}
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }

    function renderUnprocessedInterpretation(_, __, status) {
        return (
            <>
                <h5>{translateInterpretation("heading")}</h5>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translateInterpretation("info"),
                    }}
                />
                <InterpretationInfo
                    time={translatePress("duration")}
                    // tested="viac ako 20 000"
                    count={status.questionCount}
                />
                <InterpretationLanguages languages={["cs", "sk", "en", "pl"]} />
                <div className="interpretation--icon">
                    <img src={`/img/soft-skills/${icon}.svg`} alt="" />
                </div>
            </>
        );
    }

    function renderProcessedInterpretation(interpretation) {
        if (!interpretation.softSkills) {
            return null; // TODO LOADING
        }

        const skills = interpretation.softSkills;
        const score = skills.overall[0].value;

        return (
            <>
                <h5>{translate("sum")}</h5>
                <RadialProgressChart
                    background="#ffeaef"
                    thickness={10}
                    fill={PRIMARY_COLOR}
                    progress={score / 100}
                    extraContent={(_, center) => (
                        <text
                            x={center.x + 12}
                            y={center.y + 16}
                            fontSize={55}
                            textAnchor="middle"
                        >
                            {score}%
                        </text>
                    )}
                />
            </>
        );
    }
}

function IconBar(props) {
    const { name, value, icon } = props;
    const fill = `${MathHelper.round(MathHelper.clamp(value, 0, 100), 1)}%`;

    return (
        <div className="value-bar">
            <div className="value-bar--bar">
                <div className="value-bar--fill" style={{ height: fill }}>
                    <div className="value-bar--name">{name}</div>
                    <div className="value-bar--value">
                        <strong>{fill}</strong>
                    </div>
                </div>
            </div>
            <div className="value-bar--base">
                <i className={"icon-" + icon} />
            </div>
        </div>
    );
}
