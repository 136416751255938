import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

// import { ProfileActions } from '../../_actions/profile.action';
import {
    Loading,
    BasePageLayout,
    HeadTitle,
    LoadingOverlay,
} from "../../../common/components";
import {
    ProfileBasic,
    ProfileContent,
    ExperienceEdit,
    EducationEdit,
} from "./parts";
import { HardSkillsEdit } from "./parts/hard-skills/HardSkillsEdit";
import { AboutMeEdit } from "./parts/information/AboutMeEdit";
import { LanguagesEdit } from "./parts/information/LanguagesEdit";
import { InterestEdit } from "./parts/information/InterestEdit";
import { AvatarEdit } from "./parts/avatar/AvatarEdit";
import {
    PROFILE_AVATAR_EDIT_PATH,
    PROFILE_ABOUT_ME_PATH,
    PROFILE_EXPERIENCE_EDIT_PATH,
    PROFILE_EDUCATION_EDIT_PATH,
    PROFILE_LANGUAGES_PATH,
    PROFILE_INTERESTS_PATH,
    PROFILE_HARD_SKILLS_EDIT_PATH,
} from "../../_constants/route.constants";
import { AuthSelectors } from "../../../common/_selectors";
import ProfileSelectors from "../../_selectors/ProfileSelectors";
import ProfileActions from "../../_actions/ProfileActions";
import { ProfilePicEdit } from "./parts/avatar/ProfilePicEdit";
import UserBasicPageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export function Profile() {
    const actions = mapActions(useDispatch());
    const { profile, isLoading, hasBeenEdited, profileId, isUpdating } =
        useSelector(mapState);

    useEffect(() => {
        if (!profileId) {
            return;
        }

        actions.getProfile(profileId);
    }, [profileId]);

    useEffect(() => {
        if (!profileId) {
            return;
        }

        if (hasBeenEdited) {
            actions.getProfile(profileId);
        }
    }, [profileId, hasBeenEdited]);

    const translate = useTranslate("common.titles");

    return (
        <UserBasicPageLayout>
            <HeadTitle
                title={
                    profile && (profile.firstName || profile.lastName)
                        ? `${profile.firstName} ${profile.lastName}`
                        : translate("profile")
                }
            />
            {render()}
        </UserBasicPageLayout>
    );

    function render() {
        if (!profile || Object.entries(profile).length === 0) {
            return null;
        }

        if (isLoading) {
            return <Loading />;
        }

        return (
            <>
                <LoadingOverlay isLoading={isUpdating} />
                <Switch>
                    <Route
                        path={PROFILE_ABOUT_ME_PATH}
                        component={AboutMeEdit}
                    />
                    <Route
                        path={PROFILE_EXPERIENCE_EDIT_PATH}
                        component={ExperienceEdit}
                    />
                    <Route
                        path={PROFILE_EDUCATION_EDIT_PATH}
                        component={EducationEdit}
                    />
                    <Route
                        path={PROFILE_LANGUAGES_PATH}
                        component={LanguagesEdit}
                    />
                    <Route
                        path={PROFILE_INTERESTS_PATH}
                        component={InterestEdit}
                    />
                    <Route
                        path={PROFILE_HARD_SKILLS_EDIT_PATH}
                        component={HardSkillsEdit}
                    />
                    <Route
                        path={PROFILE_AVATAR_EDIT_PATH}
                        component={ProfilePicEdit}
                    />
                    <Route path="/profile/*" render={renderProfile} />
                </Switch>
            </>
        );
    }

    function renderProfile() {
        return (
            <BasePageLayout id="profile">
                <ProfileBasic profile={profile} />
                <ProfileContent profile={profile} />
            </BasePageLayout>
        );
    }

    function mapState(state) {
        const { hasBeenEdited } = state.candidate.loading.profile;
        return {
            profile: state.candidate.profile.profile,
            isLoading: ProfileSelectors.isLoading(state),
            hasBeenEdited,
            profileId: AuthSelectors.getProfileId(state),
            isUpdating: ProfileSelectors.isUpdating(state),
        };
    }

    function mapActions(dispatch) {
        return {
            getProfile: (profileId) =>
                dispatch(ProfileActions.getProfile(profileId)),
        };
    }
}
