import React from 'react';
import { useSelector } from 'react-redux';

import { NotificationMenu } from './NotificationMenu';
import { NotificationActions } from '../../_actions';
import { NotificationSelectors } from '../../_selectors/NotificationSelectors';
import { BaseNotificationButton } from './BaseNotificationButton';

export function NotificationButton(props) {
    const count = useSelector((state) =>
        NotificationSelectors.getHistoryCount(state)
    );

    return (
        <BaseNotificationButton
            icon="icon-notifications"
            Menu={NotificationMenu}
            count={count}
            getNotificationsCountAction={
                NotificationActions.getNotificationsCount
            }
            {...props}
        />
    );
}
