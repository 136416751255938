import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { RadialProgressChart } from '../../../../common/components/charts/RadialProgressChart';
import { PRIMARY_COLOR } from '../../../../common/_constants/theme.constants';
import { useAnimateValue } from '../../../../common/hooks/useAnimateValue';

export function Results() {
    const translate = useTranslate('candidate.softSkillsTest.completed');

    return (
        <div id="soft-skills-test--results">
            <div id="soft-skills-test--results--inner">
                <div id="soft-skills-test--results--title">
                    {translate('title')}
                </div>
                <Chart />
                <div id="soft-skills-test--results--processing">
                    {translate('processing')}
                </div>
                <div id="soft-skills-test--results--hint">
                    {translate('hint')}
                </div>
            </div>
        </div>
    );
}

function Chart() {
    const startAngle = useAnimateValue(0, 8, 24);

    return (
        <div id="soft-skills-test--results--chart">
            <RadialProgressChart
                background="#e6e6e6"
                thickness={10}
                fill={PRIMARY_COLOR}
                progress={0.33}
                startAngle={startAngle}
            />
        </div>
    );
}
