import {
    GET_INTERPRETATION,
    GET_INTERPRETATION_FAIL,
    GET_INTERPRETATION_SUCCESS,
    GET_SOFT_SKILLS,
    GET_SOFT_SKILLS_FAIL,
    GET_SOFT_SKILLS_SUCCESS
} from '../_constants/actions/skill.contants';

const initialState = {
    isLoading: false,
    isLoaded: false,
    skills: {
        mop: null,
        kako: null,
        hofik: null
    },
    skillInterpretations: {
        mop: null,
        kako: null,
        hofik: null
    },
    interpretation: null
};

export function softSkills(state = initialState, action) {
    switch (action.type) {
        case GET_SOFT_SKILLS:
        case GET_INTERPRETATION:
            return { ...state, isLoading: true, interpretation: null };
        case GET_SOFT_SKILLS_SUCCESS:
            const { softSkills, skillInterpretations } = action.payload;
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                skills: softSkills,
                skillInterpretations
            };
        case GET_INTERPRETATION_FAIL:
        case GET_SOFT_SKILLS_FAIL:
            return { ...state, isLoading: false, error: action.error };
        case GET_INTERPRETATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                interpretation: action.payload
            };
        default:
            return state;
    }
}
