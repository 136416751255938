import IAddress from "../../common/types/IAddress";
import ICompanySize from "../../common/types/ICompanySize";
import IID from "../../common/types/IID";
import ILanguage from "../../common/types/ILanguage";
import { RequestService } from "../../common/_services/RequestService";
import RequestService2 from "../../common/_services/RequestService2";

export const AutocompleteService = {
    cities,
    jobRoles,
    hardSkills,
    softSkills,
    jobCities,
    companies,
    address,
    languages,
    degreeFields,
    universities,
    degreeTitles,
    degreeTypes,
    industries,
    sizes,
    industryCategories,
    companyBenefits,
    degreeFieldCategories,
    educationLevels,
};

async function cities(search: string): Promise<IAddress[]> {
    return await resolve("locations/cities", search);
}

export interface JobRole extends IID {
    name: string;
}

async function jobRoles(search: string): Promise<JobRole[]> {
    const { data, status } = await RequestService2.get(
        `api/jobs/roles/autocomplete?search=${search || ""}`
    );

    if (status !== 200) {
        return [];
    }

    return data || [];
}

async function hardSkills(search: string) {
    const { data, ok } = await RequestService.get(
        `skills/hard/autocomplete?search=${search}`
    );

    if (!ok) {
        return [];
    }

    return data;
}

async function softSkills(search: string) {
    const { data, ok } = await RequestService.get(
        `skills/soft/autocomplete?search=${search}`
    );

    if (!ok) {
        return [];
    }

    return data;
}

function jobCities(search: string) {
    return RequestService.get(
        `jobs/cities/autocomplete${search ? `?search=${search}` : ""}`
    );
}

export interface Company {
    title: string;
}

async function companies(search: string): Promise<Company[]> {
    return await resolve("companies", search);
}

async function address(search: string): Promise<IAddress[]> {
    const { data, status } = await RequestService2.get(
        `locations/addresses/autocomplete?search=${search || ""}`
    );

    if (status !== 200) {
        return [];
    }

    return data;
}

async function languages(search: string): Promise<ILanguage[]> {
    const { data, status } = await RequestService2.get(
        `languages/autocomplete?search=${search || ""}`
    );

    if (status !== 200) {
        return [];
    }

    return data;
}

async function degreeFields(search: string) {
    return await resolve("university-degree-fields", search);
}

async function degreeTitles(search: string) {
    return await resolve("university-degree-titles", search);
}

async function degreeTypes(search: string) {
    return await resolve("university-degree-types", search);
}

async function degreeFieldCategories(search: string) {
    return await resolve("university-degree-field-categories", search);
}

async function educationLevels(search: string) {
    return await resolve("education-levels", search);
}

async function universities(search: string) {
    return await resolve("universities", search);
}

async function industryCategories() {
    const { data, ok } = await RequestService.get("api/industry-categories");

    if (!ok) {
        return [];
    }

    return data.content;
}

async function sizes(search: string): Promise<ICompanySize[]> {
    const { data, ok } = await RequestService.get(
        `companies/sizes/autocomplete?search=${search || ""}`
    );

    if (!ok) {
        return [];
    }

    return data;
    // return await resolve("companies/sizes", search);
}

async function industries(search: string) {
    const { data, status } = await RequestService2.get(
        `companies/industries/autocomplete?search=${search || ""}`
    );

    if (status !== 200) {
        return [];
    }

    return data;
}

async function companyBenefits(search: string) {
    const { data, status } = await RequestService2.get(
        `companies/0/benefits/autocomplete?search=${search || ""}`
    );

    if (status !== 200) {
        return [];
    }

    return data;
}

async function resolve(url: string, search: string) {
    const { data, ok } = await RequestService.get(
        `${url}${search ? `/autocomplete?search=${search}` : ""}`
    );

    if (!ok) {
        return [];
    }

    return data;
}
