import React from 'react';
import { createClass } from 'classname-helper';

import { BaseProgressBar } from './BaseProgressBar';

const getBarPercentage = (value, maxValue) =>
    Math.round((value / maxValue) * 100);

export function StepProgressBar(props) {
    const { steps = [], step, className } = props;
    const maxValue = steps.length - 1;

    return (
        <div className="step-progress-bar">
            <BaseProgressBar
                maxValue={maxValue}
                value={step}
                useMarkers={false}
                className={className}
            />
            <div>
                {steps.map((current, index) => {
                    return (
                        <div
                            key={index}
                            className={createClass({
                                'step-progress-bar--step': true,
                                active: step === index,
                            })}
                            style={{
                                left: `${getBarPercentage(index, maxValue)}%`,
                            }}
                        >
                            {current}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
