import { LanguageService } from "../_services/LanguageService";
import { useTranslate } from "react-translate.ts";

export function useFormatMoneyRange(locale) {
  const translate = useTranslate("common.misc.formatting.money");

  return format;

  function format(minValue, maxValue, currency, frequency) {
    if (minValue && maxValue && minValue !== maxValue) {
      return (
        formatMoney(minValue) +
        "-" +
        formatMoney(maxValue, currency, locale) +
        (frequency ? " /" + translate(frequency.toLowerCase()) : "")
      );
    }
    return (
      formatMoney(minValue ? minValue : maxValue, currency, locale) +
      (frequency ? " /" + translate(frequency.toLowerCase()) : "")
    );
  }
}

export function formatMoney(value, currency, locale) {
  let formatter;
  if (currency) {
    formatter = new Intl.NumberFormat(
      locale || LanguageService.getCurrentLocale(),
      {
        style: "currency",
        currency: resolveCurrency(currency),
        minimumFractionDigits: 0,
      }
    );
  } else {
    formatter = new Intl.NumberFormat(
      locale || LanguageService.getCurrentLocale(),
      {
        minimumFractionDigits: 0,
      }
    );
  }

  return formatter.format(value);
}

const currencyMap = { $: "USD", "€": "EUR", C$: "CAD", Kč: "CZK" };
function resolveCurrency(currency) {
  const code = currencyMap[currency];
  if (code) {
    return code;
  }

  return currency;
}
