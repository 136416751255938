import { useRef } from 'react';
import ReactDOM from 'react-dom';

export function useRenderTo(elementId) {
    const ref = useRef(null);

    ref.current = document.getElementById(elementId);

    return (content) => {
        if (!ref.current) {
            return null;
        }

        return ReactDOM.createPortal(content, ref.current);
    };
}
