import { PropsWithChildren } from "react";
import CandidateSideBar from "../../nav/CandidateSideBar/CandidateSideBar";
import styles from "./CareerPathsLayout.module.scss";
import CandidateHeader from "../../header/CandidateHeader/CandidateHeader";
import CandidateMobileNav from "../../nav/CandidateMobileNav/CandidateMobileNav";

type CareerPathsLayoutProps = PropsWithChildren<{}>;

export default function CareerPathsLayout(props: CareerPathsLayoutProps) {
    const { children } = props;
    return (
        <div className={styles.layout}>
            <CandidateHeader />
            <div>
                <CandidateSideBar />
                <main>{children}</main>
            </div>
            <CandidateMobileNav />
        </div>
    );
}
