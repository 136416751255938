import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobDetailActions } from "../../../../common/_actions";
import {
    AuthSelectors,
    JobDetailSelectors,
} from "../../../../common/_selectors";
import { JobDetail } from "../JobDetail";
import IJobDetail from "../../../../common/types/IJobDetail";
import CompanyProfileActions from "../../../../common/_actions/CompanyProfileActions";
import CompanyProfileSelectors from "../../../../common/_selectors/CompanyProfileSelectors";

type WrappedJobDetailProps = {
    jobPostId: number;
    displayOnly?: boolean;
};

export function WrappedJobDetail(props: WrappedJobDetailProps) {
    const { jobPostId, displayOnly = false } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (jobPostId) {
            dispatch(JobDetailActions.getDetail(jobPostId));
        }
    }, [dispatch, jobPostId]);

    const {
        data,
        isLoading,
        companyDetail,
        profileId,
        hasApplied,
        hasShortlisted,
        isInvited,
        companyIsLoading,
    } = useSelector((state) => mapState(state));

    useEffect(() => {
        if (data?.company?.id) {
            dispatch(CompanyProfileActions.getDetail(data.company.id));
        }
    }, [dispatch, data?.company?.id]);

    return (
        <JobDetail
            isLoading={isLoading}
            jobPostId={jobPostId}
            title={data.title}
            location={data.location}
            company={data.company}
            employmentType={data.employmentType?.name}
            jobBenefits={data.jobBenefits}
            skills={data.jobMatch?.skillScores}
            hardSkillsMatch={data.jobMatch?.jobMatchHardSkillScore}
            softSkillsMatch={data.jobMatch?.jobMatchSoftSkillScore}
            languagesMatch={data.jobMatch?.jobMatchLanguageScore}
            qualificationsMatch={data.jobMatch?.jobMatchQualificationScore}
            qualifications={data.qualificationRequirements}
            languages={data.jobMatch?.languageScores}
            info={data.jobOtherInfos}
            match={data.jobMatch?.jobMatchScore}
            payRaise={data.payRaise}
            salaryMin={data.salaryRange?.amountMin}
            salaryMax={data.salaryRange?.amountMax}
            currency={data.salaryRange?.currency?.code}
            applicationStatus={data.jobApplicationStatus}
            hasApplied={hasApplied}
            hasShortlisted={hasShortlisted}
            isInvited={isInvited}
            onApply={() =>
                dispatch(JobDetailActions.applyToJob(jobPostId, profileId))
            }
            companyDetail={{
                description: companyDetail?.description,
                followed: companyDetail?.followed,
                founded: companyDetail?.founded,
                name: companyDetail?.name,
                url: companyDetail?.url,
                logoUrl: companyDetail?.logoUrl,
                headquarterLocation: companyDetail?.headquarterLocation,
                images: companyDetail?.images,
                industries: companyDetail?.industries,
                benefits: companyDetail?.jobBenefits,
                jobs: companyDetail?.jobs,
                locations: companyDetail?.locations,
                videoId: companyDetail?.videoId,
                videoProvider: companyDetail?.videoProvider,
                employees: companyDetail?.size?.name,
            }}
            companyIsLoading={companyIsLoading}
            isFavourite={false}
            startDate=""
            displayOnly={displayOnly}
            description={data?.description}
            responsibilities={data?.jobResponsibilities}
            applyUrl={data?.applyUrl}
            careerMap={data?.careerMap}
        />
    );
}

function mapState(state: any) {
    return {
        data: JobDetailSelectors.getData(state) as IJobDetail,
        isLoading: JobDetailSelectors.isLoading(state as never),
        hasApplied: JobDetailSelectors.hasApplied(state as never),
        hasShortlisted: JobDetailSelectors.hasShortlisted(state as never),
        isInvited: JobDetailSelectors.isInvited(state as never),
        hasFailed: JobDetailSelectors.hasFailed(state as never),
        profileId: AuthSelectors.getProfileId(state as never),
        companyDetail: CompanyProfileSelectors.getDetailData(state),
        companyIsLoading: CompanyProfileSelectors.isLoading(state),

        // companyDetail: state.common.company.profile as ICompanyDetail,
    };
}
