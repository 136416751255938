import { useEffect, useRef } from "react";

export function useCamera(
    constraints = { video: { facingMode: "user" }, audio: true }
) {
    const localStream = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        if (supportsUserMedia()) {
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    if (videoRef) {
                        localStream.current = stream;
                        //videoRef.current.srcObject = stream;
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

        return endStream;
    }, []);

    function supportsUserMedia() {
        return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    }

    function endStream() {
        if (localStream.current) {
            localStream.current.getVideoTracks()[0].stop();
        }

        localStream.current = null;
        videoRef.current = null;
    }

    return { videoRef, stream: localStream };
}
