import {
    LOGIN,
    LOGOUT,
    VERIFY,
    SIGNUP,
    SIGNUP_WITHOUT_TOKEN,
} from '../_constants/actions/auth.constants';

export const AuthActions = {
    login,
    logout,
    verifyUser,
    signup,
    signupWithoutToken,
};

function login(username, password) {
    return { type: LOGIN, payload: { username, password } };
}

function logout() {
    return { type: LOGOUT };
}

function verifyUser(token) {
    return { type: VERIFY, payload: token };
}

function signup({ username, password, firstName, lastName, accessCode, marketingConsent }) {
    return {
        type: SIGNUP,
        payload: { username, password, firstName, lastName, accessCode, marketingConsent },
    };
}

function signupWithoutToken({ username, password, firstName, lastName }) {
    return {
        type: SIGNUP_WITHOUT_TOKEN,
        payload: { username, password, firstName, lastName },
    };
}
