import { createSelector } from 'reselect';

import {
    TRACKING_APPLIED,
    TRACKING_SHORTLISTED,
    TRACKING_ACCEPTED,
    TRACKING_INVITED,
} from '../../admin/_constants/actions/tracking.constants';

const cards = (state) => state.candidate.jobs.tracking.cards;

const getApplied = makeGetActiveCards(TRACKING_APPLIED);
const getShortlisted = makeGetActiveCards(TRACKING_SHORTLISTED);
const getAccepted = makeGetActiveCards(TRACKING_ACCEPTED);
const getInvited = makeGetActiveCards(TRACKING_INVITED);

const getAppliedCount = createSelector(getApplied, (items) => items.length);
const getShortlistedCount = createSelector(
    getShortlisted,
    (items) => items.length
);
const getAcceptedCount = createSelector(getAccepted, (items) => items.length);
const getInvitedCount = createSelector(getInvited, (items) => items.length);

const getActiveCount = createSelector(
    getAppliedCount,
    getShortlistedCount,
    getAcceptedCount,
    getInvitedCount,
    (applied, shortlisted, accepted, invited) =>
        applied + shortlisted + accepted + invited
);

export const ApplicationsTrackingSelectors = {
    getApplied,
    getShortlisted,
    getAccepted,
    getInvited,
    getAppliedCount,
    getAcceptedCount,
    getInvitedCount,
    getActiveCount,
    getShortlistedCount,
};

function makeGetActiveCards(type) {
    return createSelector(cards, (cards) => cards[type].valueSeq().toArray());
}
