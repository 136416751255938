import { RequestService } from '../../common/_services/RequestService';

const InviteFriendsService = {
    getInvites,
    sendInvites,
    sendInvite,
};

const ENDPOINT = 'api/invitations';

function getInvites({ profileId, testCode }) {
    return RequestService.get(ENDPOINT, [
        ['profileId', profileId],
        ['testCode', testCode],
    ]);
}

function sendInvites({ emails, msg, testCode }) {
    return RequestService.post(ENDPOINT, { emails, msg, testCode });
}

function sendInvite(id) {
    return RequestService.post(`${ENDPOINT}/${id}`);
}

export default InviteFriendsService;
