import LanguageConstants from "../_constants/actions/LanguageConstants";

export const LanguageActions = {
    getLanguages,
    getLanguageLevels,
    changeLocale,
    setAllowedLocales,
};

function getLanguages() {
    return { type: LanguageConstants.GET_LANGUAGES };
}

function getLanguageLevels() {
    return { type: LanguageConstants.GET_LEVELS };
}

function changeLocale(locale: string) {
    return { type: LanguageConstants.CHANGE_LOCALE, payload: locale };
}

function setAllowedLocales(locales: string[]) {
    return { type: LanguageConstants.SET_ALLOWED, payload: locales };
}
