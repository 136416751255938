import { RequestService } from "./RequestService";
import RequestService2 from "./RequestService2";

const CompanyProfileService = {
    getCompanyProfile,
    getCompanyCurrency,
};

async function getCompanyProfile(companyId: number) {
    return await RequestService2.get(`api/companies/${companyId}`);
}

async function getCompanyCurrency(companyId: number) {
    return await RequestService.get(`api/companies/${companyId}/currency`);
}

export default CompanyProfileService;
