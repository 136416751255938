import { takeLatest } from 'redux-saga/effects';
import {
    GET_JOB_ROLE_SUCCESS,
    GET_JOB_ROLE_FAIL,
    GET_JOB_ROLE,
} from '../_constants';
import { JobRoleService } from '../_services';
import { JobRoleActions, GET_JOB_ROLE_MONITORING } from '../_actions';
import { SagaHelpers } from '../../common/_helpers/SagaHelpers';

function* getDetail(action) {
    yield SagaHelpers.handleRequest({
        request: [JobRoleService.getJobRole, action.payload],
        actions: [
            (payload) => ({ type: GET_JOB_ROLE_SUCCESS, payload }),
            (error) => ({ type: GET_JOB_ROLE_FAIL, error }),
        ],
    });
}

function* getDetailMonitoring(action) {
    yield SagaHelpers.handleRequest({
        request: [JobRoleService.getJobRoleMonitoring, action.payload],
        actions: [
            (data) =>
                JobRoleActions.getJobRoleMonitoringSuccess(
                    data,
                    action.payload
                ),
            JobRoleActions.getJobRoleMonitoringFail,
        ],
    });
}

const jobRoleSaga = [
    takeLatest(GET_JOB_ROLE, getDetail),
    takeLatest(GET_JOB_ROLE_MONITORING, getDetailMonitoring),
];

export default jobRoleSaga;
