import { AllHTMLAttributes, CSSProperties, PropsWithChildren } from "react";
import { cx } from "@emotion/css";

import styles from "./Button.module.scss";

export interface ButtonProps extends PropsWithChildren<{}> {
    variant?: "primary" | "secondary";
    onClick?: () => void;
    fontSize?: number;
    corners?: "rounded" | "round";
    disabled?: boolean;
    inverted?: boolean;
    loading?: boolean;
    style?: CSSProperties;
    className?: string;
    type?: "submit" | "button";
    // corners?: "roun"
}

export default function Button(props: ButtonProps) {
    const {
        children,
        variant = "primary",
        fontSize,
        onClick,
        corners = "rounded",
        disabled = false,
        inverted = false,
        loading = false,
        className,
        style,
        type,
        ...rest
    } = props;

    return (
        <button
            className={cx(
                styles.button,
                styles[variant],
                inverted ? styles.inverted : null,
                loading && styles.loading,
                className
            )}
            onClick={onClick}
            style={{ fontSize, borderRadius: gerBorderRadius(), ...style }}
            disabled={disabled}
            type={type}
            {...rest}
        >
            {children}
        </button>
    );

    function gerBorderRadius() {
        switch (corners) {
            case "rounded":
                return 10;
            case "round":
                return 30;
            default:
                return 0;
        }
    }
}

function Secondary(props: ButtonProps) {
    return <Button {...props} variant="secondary" />;
}

Button.Secondary = Secondary;
