import React, { useRef } from 'react';
import { Translate } from 'react-translate.ts';

import { Flex, Grid } from '../layout';
import moment from 'moment';
import { ProgressBar } from '../misc';
import { TRACKING_INVITED } from '../../../admin/_constants/actions/tracking.constants';
import { MoreDaysButton } from './MoreDaysButton';

export function TrackingProgress({
    deadlineDate,
    createdDate,
    eventDate,
    eventNumber,
    status,
    addDaysVisible,
}) {
    const created = useRef(moment(createdDate));
    const deadline = useRef(moment(deadlineDate));
    const now = moment();
    const minutesCreatedNowDiff = created.current.diff(now, 'hours');
    const minutesNowDeadlineDiff = deadline.current.diff(now, 'hours');
    const progress =
        minutesCreatedNowDiff /
        (minutesCreatedNowDiff - minutesNowDeadlineDiff);
    return (
        <Flex direction="column">
            <ProgressBar
                className="is-rounded"
                coloredProgress={true}
                coloredInverted={true}
                value={progress}
                maxValue={1}
            />
            {renderProgressLabels(status)}
        </Flex>
    );

    function renderProgressLabels(status) {
        return (
            <Grid size="auto auto auto" columnGap={1}>
                {(() => {
                    if (status === TRACKING_INVITED) {
                        return (
                            <>
                                <ProgressLabel
                                    label="candidate.tracking.sent"
                                    date={created.current.format('dddd, HH:mm')}
                                />
                                <ProgressLabel
                                    label="candidate.tracking.seen"
                                    params={{
                                        value: eventNumber ? eventNumber : 0,
                                    }}
                                    date={
                                        eventDate &&
                                        eventDate.current &&
                                        eventDate.current.format('dddd, HH:mm')
                                    }
                                    className="text-center"
                                />
                                <ProgressLabel
                                    label="candidate.tracking.answer"
                                    date={deadline.current.format(
                                        'dddd, HH:mm'
                                    )}
                                    className="text-right"
                                />
                            </>
                        );
                    }

                    return (
                        <>
                            <ProgressLabel
                                label="candidate.tracking.sent"
                                date={created.current.format('dddd, HH:mm')}
                            />
                            <MoreDaysButton isVisible={addDaysVisible} />
                            <ProgressLabel
                                label="candidate.tracking.answer"
                                date={deadline.current.format('dddd, HH:mm')}
                                className="text-right"
                            />
                        </>
                    );
                })()}
            </Grid>
        );
    }

    function ProgressLabel({ label, params, date, className }) {
        return (
            <Flex direction="column" className={className}>
                <Translate className="font-9" params={params} namespace="admin">
                    {label}
                </Translate>
                <div className="font-8 text-secondary">{date}</div>
            </Flex>
        );
    }
}
