import { Delete } from "@material-ui/icons";

import {
    FlexContainer,
    FlexItem,
    ReactSelect,
} from "../../../../common/components";
import { Translate, useTranslate } from "react-translate.ts";

const defaultRange = [
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.level.1
            </Translate>
        ),
        value: 1,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.level.2
            </Translate>
        ),
        value: 2,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.level.3
            </Translate>
        ),
        value: 3,
    },
    {
        label: (
            <Translate textOnly>
                candidate.profile.hardSkills.edit.select.level.4
            </Translate>
        ),
        value: 4,
    },
];

export function SkillEdit({
    id,
    data,
    skill,
    name,
    skillId,
    onUpdate,
    onDelete,
    errors,
}) {
    const translate = useTranslate();

    return render();

    function render() {
        return (
            <FlexItem size={12} id={id}>
                <FlexContainer
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                >
                    <FlexItem size={12} sm={5}>
                        <div className="skill-edit-name">
                            <FlexContainer
                                justify="space-between"
                                alignItems="center"
                            >
                                <span>{name}</span>
                                <div
                                    className="skill-delete"
                                    onClick={deleteSkill}
                                >
                                    <Delete />
                                </div>
                            </FlexContainer>
                        </div>
                    </FlexItem>
                    <FlexItem size={12} sm={7}>
                        <FlexContainer spacing={1} alignItems="center">
                            {data && data.map(renderEdit)}
                        </FlexContainer>
                    </FlexItem>
                </FlexContainer>
            </FlexItem>
        );
    }

    function renderEdit(
        { value, options, valueName, customRange, updateValue, placeholder },
        index
    ) {
        const error = errors && errors[valueName];
        const count = data.length;
        const size = 12 / count;
        const range = customRange || defaultRange;
        return (
            <FlexItem key={index} size={12} sm={size}>
                {renderSelect(
                    value,
                    options ? [...options, ...range] : range,
                    valueName,
                    updateValue,
                    placeholder,
                    error
                )}
            </FlexItem>
        );
    }

    function renderSelect(
        value,
        options,
        valueName,
        updateValue,
        placeholder,
        error
    ) {
        return (
            <ReactSelect
                value={options.find((option) => option.value === value)}
                onChange={updateSkill}
                options={options}
                isSearchable={false}
                showDropdown={true}
                placeholder={placeholder ? translate(placeholder) : null}
                hasError={!!error}
            />
        );

        function updateSkill({ value }) {
            let updatedSkill;
            if (valueName) {
                updatedSkill = { ...skill, [valueName]: value };
            } else {
                updatedSkill = updateValue(skill, value);
            }

            onUpdate(updatedSkill);
        }
    }

    function deleteSkill() {
        onDelete(skillId);
    }
}
