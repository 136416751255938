import { createSelector } from 'reselect';

const tracking = (state) => state.admin.candidates.candidateTracking;

const trackingCards = createSelector(tracking, ({ cards }) => cards);

const archived = createSelector(tracking, ({ archived }) => archived);

const declined = createSelector(tracking, ({ declined }) => declined);

export function makeGetTrackingCards(filter) {
    return createSelector(trackingCards, (cards) => {
        return cards[filter].valueSeq().toArray();
    });
}

export function makeGetArchivedCards(filter) {
    return createSelector(archived, (cards) => {
        return cards[filter];
    });
}

export function makeGetDeclinedCards(filter) {
    return createSelector(declined, (cards) => {
        return cards[filter];
    });
}

export function makeGetCard(id, filter) {
    return createSelector(trackingCards, (cards) => {
        return cards[filter] && cards[filter].get(id);
    });
}

export const CandidateTrackingSelectors = {};
