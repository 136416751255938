import {
    CSSProperties,
    PropsWithChildren,
    useEffect,
    useRef,
    useState,
} from "react";
import { cx } from "@emotion/css";

import styles from "./ActiveButton.module.scss";

type ActiveButtonProps = {
    variant?: "primary" | "secondary";
    onClick?: () => void;
    fontSize?: number;
    corners?: "rounded" | "round";
    disabled?: boolean;
    minWidth?: number;
    height?: number;
    states: string[];
    state: number;
    transitionSpeed?: number;
    success?: boolean;
    fail?: boolean;
    // corners?: "roun"
};

export default function ActiveButton(
    props: PropsWithChildren<ActiveButtonProps>
) {
    const {
        variant = "primary",
        fontSize,
        onClick,
        corners = "rounded",
        disabled = false,
        state,
        states,
        minWidth,
        height,
        transitionSpeed = 300,
        children,
        success,
        fail,
    } = props;

    const classNames = resolveState();

    const firstRef = useRef();
    const secondRef = useRef();
    const thirdRef = useRef();

    const [width, setWidth] = useState(minWidth);

    useEffect(() => {
        setWidth(getWidth(state));

        function getWidth(state: number) {
            switch (state) {
                case 0:
                    return getElementWidth(firstRef.current);
                case 1:
                    return getElementWidth(secondRef.current);
                case 2:
                    return getElementWidth(thirdRef.current);
                default:
                    return minWidth;
            }
        }

        function getElementWidth(element: HTMLLIElement) {
            return element ? element.getBoundingClientRect().width : minWidth;
        }
    }, [minWidth, state]);

    return (
        <button
            className={cx(
                styles.button,
                styles[variant],
                ...classNames,
                success ? styles.success : null,
                fail ? styles.fail : null
            )}
            onClick={onClick}
            style={
                {
                    fontSize,
                    borderRadius: gerBorderRadius(),
                    "--transition-speed": `${transitionSpeed / 1000}s`,
                    "--rotation": !disabled ? `${state * 90}deg` : null,
                } as CSSProperties
            }
            disabled={disabled || state > 0}
        >
            {disabled ? (
                <ul style={{ width, height }}>
                    <li ref={firstRef}>{children}</li>
                </ul>
            ) : (
                <ul style={{ width, height }}>
                    <li ref={firstRef}>{states[0]}</li>
                    <li ref={secondRef}>{states[1]}</li>
                    <li ref={thirdRef}>{states[2]}</li>
                </ul>
            )}
        </button>
    );

    function gerBorderRadius() {
        switch (corners) {
            case "rounded":
                return 10;
            case "round":
                return 30;
            default:
                return 0;
        }
    }

    function resolveState() {
        switch (state) {
            case 1:
                return [styles.loading];
            case 2:
                return [styles.success];
            default:
                return [];
        }
    }
}
