import React from 'react';

import { InformationDate } from './InformationDate';
import { Image } from '../../../../common/components';
import { Flex, EditButton, DeleteButton } from '../../';

export function ExperienceItem({
    logoUrl,
    title,
    name,
    started,
    ended,
    dateFormat,
    description,
    location,
    onEdit,
    onDelete,
    translatePrefix,
    displayOnly = false,
}) {
    const defaultLogo = '/img/icons/company.svg';
    return (
        <div className="list-item">
            <div className="experience-item">
                <div className="experience-item__logo">
                    <Image src={logoUrl} fallbackSrc={defaultLogo} alt={name} />
                </div>
                <div className="experience-item__content">
                    <div className="experience-item__main-info">
                        <div className="experience-item__title">{title}</div>
                        <div className="experience-item__label">{name}</div>
                        <div className="experience-item__label">{location}</div>
                    </div>
                    <div className="experience-item__date">
                        <InformationDate
                            started={started}
                            ended={ended}
                            format={dateFormat}
                        />
                    </div>
                    <p>{description}</p>
                    <ControlButtons
                        {...{
                            onDelete,
                            onEdit,
                            isVisible: !displayOnly,
                            translatePrefix,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function ControlButtons({ onDelete, onEdit, isVisible, translatePrefix }) {
    if (!isVisible) {
        return null;
    }

    return (
        <Flex className="w100" justifyContent="end">
            <DeleteButton
                onDelete={onDelete}
                translatePrefix={translatePrefix}
            />
            <EditButton onClick={onEdit} />
        </Flex>
    );
}
