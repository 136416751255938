import GoogleMap from "../../../../common/components/maps/GoogleMap";
import ILocation from "../../../../common/types/ILocation";

export function LocationMap(props: { location: ILocation; height?: number }) {
    const { location, height } = props;

    if (!location || !location.lat || !location.lng) {
        return null;
    }

    return (
        <>
            {/* <LocationPanel post={post} onItemPick={onMarkersUpdate} /> */}
            <GoogleMap locations={[location]} height={height} zoom={13} />
        </>
    );
}
