const SoftSkillsConstants = {
    GET_COMPARISON: "SOFT_SKILLS__GET_COMPARISON",
    GET_COMPARISON_SUCCESS: "SOFT_SKILLS__GET_COMPARISON_SUCCESS",
    GET_COMPARISON_FAIL: "SOFT_SKILLS__GET_COMPARISON_FAIL",

    GET_STATUSES: "SOFT_SKILLS__GET_STATUSES",
    GET_STATUSES_SUCCESS: "SOFT_SKILLS__GET_STATUSES_SUCCESS",
    GET_STATUSES_FAIL: "SOFT_SKILLS__GET_STATUSES_FAIL",

    GET_STATUS: "SOFT_SKILLS__GET_STATUS",
    GET_STATUS_SUCCESS: "SOFT_SKILLS__GET_STATUS_SUCCESS",
    GET_STATUS_FAIL: "SOFT_SKILLS__GET_STATUS_FAIL",

    GET_TEST: "SOFT_SKILLS__GET_TEST",
    GET_TEST_SUCCESS: "SOFT_SKILLS__GET_TEST_SUCCESS",
    GET_TEST_FAIL: "SOFT_SKILLS__GET_TEST_FAIL",

    COMPLETE_TEST: "SOFT_SKILLS__COMPLETE_TEST",
    COMPLETE_TEST_SUCCESS: "SOFT_SKILLS__COMPLETE_TEST_SUCCESS",
    COMPLETE_TEST_FAIL: "SOFT_SKILLS__COMPLETE_TEST_FAIL",
};

export default SoftSkillsConstants;
