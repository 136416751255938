import React, { useState } from 'react';

export function Image({ src, fallbackSrc, alt, onError, ...rest }) {
    const [source, setSource] = useState(src || fallbackSrc);

    return <img src={source} {...rest} onError={handleError} alt={alt} />;

    function handleError(event) {
        setSource(fallbackSrc);
        onError && onError(event);
    }
}
