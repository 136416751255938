import ICurrency from "../../common/types/ICurrency";
import IMetric from "../../common/types/IMetric";
import IMetricType from "../../common/types/IMetricType";
import ISelectType from "../../common/types/ISelectType";
import RequestService2 from "../../common/_services/RequestService2";

const ENDPOINT = "companies";

const AdminProfileEditService = {
    save,
    saveLocations,
    uploadVideo,
    uploadImage,
    deleteImage,
    getBenefitMetrics,
    getBenefitMetricTypes,
    saveBenefit,
    deleteBenefit,
    publish,
    unpublish,
    saveVideo,
};

export type SavePayload = {
    id: number;
    name: string;
    description: string;
    url: string;
    sizeId: number;
    industryIds: number[];
};

async function save(payload: SavePayload) {
    const { id, ...rest } = payload;
    return await RequestService2.put(`${ENDPOINT}/${id}`, rest);
}

export type SaveLocationsPayload = {
    id: number;
    locationsId: string[];
};

async function saveLocations(payload: SaveLocationsPayload) {
    const { id, locationsId } = payload;
    const response = await RequestService2.put(
        `${ENDPOINT}/${id}/locations`,
        JSON.stringify({
            companyId: id,
            locationsId,
        })
    );

    return response.data;
}

async function uploadVideo(
    payload: UploadMediaPayload,
    onUploadProgress: (progress: number) => void
) {
    return await uploadMedia("videos", payload, onUploadProgress);
}

async function uploadImage(
    payload: UploadMediaPayload,
    onUploadProgress: (progress: number) => void
) {
    return await uploadMedia("images", payload, onUploadProgress);
}

export type DeleteMediaPayload = {
    companyId: number;
    mediaId: number;
};

async function deleteImage(payload: DeleteMediaPayload) {
    const { companyId, mediaId } = payload;
    return await RequestService2.delete(
        `${ENDPOINT}/${companyId}/images/${mediaId}`
    );
}

export type UploadMediaPayload = {
    id: number;
    data: any;
    fileName: string;
};

async function uploadMedia(
    path: string,
    payload: UploadMediaPayload,
    onUploadProgress: (progress: number) => void
) {
    const { id, data } = payload;
    const response = await RequestService2.put(
        `${ENDPOINT}/${id}/${path}`,
        data,
        {
            onUploadProgress: (event) =>
                onUploadProgress((event.loaded / event.total) * 100),
        }
    );
    return response.data;
}

async function getBenefitMetrics() {
    return await RequestService2.get<IMetric[]>("benefit-metrics");
}

async function getBenefitMetricTypes() {
    return await RequestService2.get<IMetricType[]>("benefit-metric-types");
}

export type SaveBenefitPayload = {
    id?: number;
    companyId: number;
    category: ISelectType;
    featured?: boolean;
    metricType: IMetricType;
    metric?: IMetric;
    value?: number;
    currency?: ICurrency;
    orderIndex?: number;
};

async function saveBenefit(payload: SaveBenefitPayload) {
    const { companyId, metric, metricType, currency, category, ...rest } =
        payload;
    return await RequestService2.put(`${ENDPOINT}/${companyId}/benefits`, {
        companyId,
        metric: metric?.id,
        metricType: metricType?.id,
        currencyId: currency?.id,
        jobBenefitId: category?.id,
        ...rest,
    });
}

async function deleteBenefit(payload: { companyId: number; id: number }) {
    const { companyId, id } = payload;
    return await RequestService2.delete(
        `${ENDPOINT}/${companyId}/benefits/${id}`
    );
}

async function publish(payload: { companyId: number }) {
    const { companyId } = payload;
    return await RequestService2.put(`${ENDPOINT}/${companyId}/publish`);
}

async function unpublish(payload: { companyId: number }) {
    const { companyId } = payload;
    return await RequestService2.put(`${ENDPOINT}/${companyId}/unpublish`);
}

async function saveVideo(payload: { companyId: number; url: string }) {
    const { companyId, url } = payload;
    return await RequestService2.put(
        `${ENDPOINT}/${companyId}/videos/external`,
        { url }
    );
}

export default AdminProfileEditService;
