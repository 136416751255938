import React from "react";
import { Switch, Route } from "react-router-dom";

import {
    INTERPRETATION_MOP_PATH,
    INTERPRETATION_HOFIK_PATH,
    INTERPRETATION_KAKO_PATH,
} from "../../_constants/route.constants";
import { MopInterpretation } from "./MopInterpretation";
import { HofikInterpretation } from "./Hofik/HofikInterpretation/HofikInterpretation";
import { KakoInterpretation } from "./KakoInterpretation";
import UserBasicPageLayout from "../../components/layout/CandidatePageLayout/CandidatePageLayout";

export function Interpretations() {
    return (
        <UserBasicPageLayout>
            <Switch>
                <Route
                    path={INTERPRETATION_MOP_PATH}
                    component={MopInterpretation}
                />
                <Route
                    path={INTERPRETATION_HOFIK_PATH}
                    component={HofikInterpretation}
                />
                <Route
                    path={INTERPRETATION_KAKO_PATH}
                    component={KakoInterpretation}
                />
            </Switch>
        </UserBasicPageLayout>
    );
}
