import React from "react";
import { AdminMainMenu } from "../../header/AdminMainMenu";
import Header from "../../../../common/components/nav/Header/Header";

export default function CompanyHeader() {
    return (
        <Header
            menuContent={<AdminMainMenu />}
            showMainMenu
            showNotifications
        />
    );
}

