import { Translate } from "react-translate.ts";

export default function SignupConsent() {
    const gdprLink = (
        <a
            href="https://www.kareer.ai/gdpr"
            target="_blank"
            rel="noopener noreferrer"
        />
    );

    return (
        <Translate
            components={{
                termsLink: (
                    <a
                        href="https://www.kareer.ai/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                ),
                gdprLink1: gdprLink,
                gdprLink2: gdprLink,
            }}
        >
            common.onboarding.signUp.form.consent
        </Translate>
    );
}
