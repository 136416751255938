import React from "react";
import { Link, generatePath } from "react-router-dom";

import { TrackingCard } from "../../../../common/components/tracking/TrackingCard";
import { useFormatMoneyRange } from "../../../../common/_helpers";
import { JOB_DETAIL_PATH } from "../../../../common/_constants/route.constants";

export function ApplicationCard({ application, canDrag, accept, ...rest }) {
  const formatMoneyRange = useFormatMoneyRange();
  const {
    jobPost,
    status,
    responseTimeDeadline,
    events,
    createdDate,
    viewStatus,
  } = application;

  if (!jobPost) {
    return null;
  }

  const {
    companyName,
    companyLogoUrl,
    salaryMin,
    salaryMax,
    currency,
    salaryType,
    title,
    subtitle,
    jobMatchScore,
    location,
    jobPostId,
  } = jobPost;

  return (
    <Link to={generatePath(JOB_DETAIL_PATH, { jobPostId })} target="_blank">
      <TrackingCard
        image={companyLogoUrl}
        heading={title + (subtitle ? " " + subtitle : "")}
        subHeadings={[
          `${companyName}, ${location}`,
          `${formatMoneyRange(salaryMin, salaryMax, currency, salaryType)}`,
        ]}
        params={{
          responseTimeDeadline,
          score: jobMatchScore,
          status,
          events,
          createdDate,
        }}
        canDrag={canDrag}
        isNew={viewStatus !== "VIEWED"}
        accept={accept}
        {...rest}
      />
    </Link>
  );
}
