import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AsyncSelect, Button, Select } from "../../../../../common/components";
import { CareerPathsSelectors } from "../../../../_selectors/CareerPathsSelectors";
import { ProfileActions } from "../../../../../common/_actions/ProfileActions";
import { AuthSelectors } from "../../../../../common/_selectors";
import { PROFILE_TYPE_COLLEGE } from "../../../../../common/_constants/profile.constants";
import { AutocompleteService } from "../../../../_services";

export function CollegeForm(props) {
    const { onSubmit } = props;

    const { profileId } = useSelector(mapState);

    const dispatch = useDispatch();

    const [value, setValue] = useState(null);

    return (
        <>
            <div className="form">
                <div className="subtitle">
                    Please select industry, you can switch between industries.
                    More industries will be available soon
                </div>
                <div>
                    <AsyncSelect
                        loadOptions={AutocompleteService.industryCategories}
                        defaultOptions
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        value={value}
                        onChange={(value) => setValue(value)}
                    />
                </div>
            </div>
            <Button onClick={submit} disabled={!value}>
                Show career map
            </Button>
        </>
    );

    function submit() {
        if (value === null) {
            return;
        }

        dispatch(
            ProfileActions.saveProfileType(profileId, PROFILE_TYPE_COLLEGE, {
                industryId: value.id,
                industryName: value.name,
            })
        );

        onSubmit && onSubmit();
    }
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
    };
}
