import React from 'react';

export function InterpretationLanguages(props) {
    const { languages } = props;

    return (
        <div className="interpretation__languages">
            {languages.map((lang, index) => (
                <img
                    key={index}
                    src={'/img/flags/' + lang + '.svg'}
                    alt={lang}
                />
            ))}
        </div>
    );
}
