import styles from "./Discover.module.scss";
import InterpretationPanel from "../InterpretationPanel/InterpretationPanel";
import { useTranslate } from "react-translate.ts";
import React from "react";
import { MathHelper, renderEmptyElements } from "../../../_helpers";
import { resolveSectionColor } from "../../../../candidate/pages/Interpretation/resolveSectionColor";

type DiscoverProps = {
    softSkills: any;
};

export default function Discover(props: DiscoverProps) {
    const { softSkills } = props;

    const translate = useTranslate();

    return (
        <InterpretationPanel className={styles.panel} id="discover">
            <InterpretationPanel.Inner>
                <h2>{translate("candidate.interpretation.mop.headings.discover")}</h2>
                {softSkills && (
                    <div className={styles.categories}>
                        <Section
                            items={softSkills.perception}
                            name="perception"
                        />
                        <Section items={softSkills.setting} name="setting" />
                        <Section items={softSkills.activity} name="activity" />
                        <Section
                            items={softSkills.decisions}
                            name="decisions"
                        />
                    </div>
                )}
            </InterpretationPanel.Inner>
        </InterpretationPanel>
    );
}

type SectionProps = {
    items: any[];
    name: string;
};

function Section(props: SectionProps) {
    const { items, name } = props;

    const translate = useTranslate("candidate.interpretation");

    return (
        <div className={styles.categories__section}>
            <div className={styles.categories__section__title}>
                {translate("mop.headings."+name)}
            </div>
            <p>{translate("categories." + name)}</p>
            <div className={styles.categories__skills}>
                {!items
                    ? renderEmptyElements("div", 4)
                    : items.map((item, index) => (
                          <Skill item={item} key={index} />
                      ))}
            </div>
        </div>
    );
}

type SkillProps = {
    item: any;
};

function Skill(props: SkillProps) {
    const { skill, value } = props.item;
    const { name, description, section } = skill;

    return (
        <div className={styles.categories__skills__item}>
            <InterpretationPanel.Subtitle>{name}</InterpretationPanel.Subtitle>
            <p>{description}</p>
            <div className={styles.categories__skills__item__bar}>
                <div
                    className={styles.categories__skills__item__bar__fill}
                    style={{
                        width: `calc(${value}% - 69px)`,
                        background: resolveSectionColor(section),
                    }}
                />
                <div className={styles.categories__skills__item__bar__value}>
                    {MathHelper.round(value, 0)}%
                </div>
            </div>
        </div>
    );
}
