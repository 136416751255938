import React, { useRef } from 'react';

import { PRIMARY_COLOR } from '../../../../common/_constants/theme.constants';
import { MathHelper, SVGHelper } from '../../../../common/_helpers';
import { useMeasurements } from '../../../../common/hooks';

const DISABLED_COLOR = '#e6e6e6';

export function MeterBar(props) {
    const { value, min = 0, max = 100, disabled, thickness = 12 } = props;
    const ref = useRef(null);
    const measurements = useMeasurements(ref);
    const { height, width, aspect } = measurements;

    const startAngle = -90;
    const fill = startAngle + (disabled ? 0.33 : value) * 180;
    const center = { x: width / 2, y: height - (disabled ? 70 : 40) };

    const radius = Math.abs(
        (aspect > 1 ? height * 2 - (disabled ? 140 : 80) : width) / 2 -
            thickness
    );
    const pointer = MathHelper.polarToCartesian(
        center.x,
        center.y,
        radius - thickness,
        fill
    );

    return (
        <div className="meter-bar" ref={ref}>
            <svg>
                <path
                    fill="none"
                    stroke={disabled ? DISABLED_COLOR : '#ff94ae33'}
                    strokeWidth={thickness}
                    d={SVGHelper.describeArc(
                        center.x,
                        center.y,
                        radius,
                        startAngle,
                        90
                    )}
                />
                <path
                    fill="none"
                    stroke={disabled ? DISABLED_COLOR : PRIMARY_COLOR}
                    strokeWidth={thickness}
                    d={SVGHelper.describeArc(
                        center.x,
                        center.y,
                        radius,
                        startAngle,
                        fill
                    )}
                />
                <line
                    x1={center.x}
                    y1={center.y - 12}
                    x2={pointer.x}
                    y2={pointer.y}
                    strokeWidth={3}
                    stroke={disabled ? DISABLED_COLOR : PRIMARY_COLOR}
                />
                <circle
                    cx={center.x}
                    cy={center.y - 12}
                    r={6}
                    stroke={disabled ? DISABLED_COLOR : PRIMARY_COLOR}
                    strokeWidth={3}
                    fill="#fff"
                />
                {renderRange(min, -radius - 6)}
                {renderRange(max, radius - 12)}
                {!disabled ? (
                    <text
                        x={center.x}
                        y={center.y + 24}
                        fontSize={24}
                        textAnchor="middle"
                    >
                        {MathHelper.round(value * 100, 1)}%
                    </text>
                ) : null}
            </svg>
        </div>
    );

    function renderRange(value, offset) {
        return (
            <text
                x={center.x + offset}
                y={center.y + 24}
                fill={disabled ? '#75808b' : '#000'}
                fontSize={12}
            >
                {value}%
            </text>
        );
    }
}
