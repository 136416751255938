import React from "react";
import { DateDiff, resolveLogoUrl } from "../../_helpers";
import { useTranslate } from "react-translate.ts";

export function NotificationMenuItem({ notification }) {
    const translate = useTranslate();
    const translateDate = useTranslate("common.misc.date");
    const translateBody = useTranslate("common.notification.body");
    const msg = getMsgSubstitutions(notification, translate);

    const { status, createdDate } = notification;
    const dateDiff = DateDiff.before(new Date(createdDate), new Date());

    return (
        <li className={status !== "VIEWED" ? "new" : ""}>
            <div>
                <div>
                    <img src={msg.logo} alt="" />
                </div>
                <div>
                    <div className="padding-bottom-5">
                        <strong className="font-18">{msg.subject}</strong>
                    </div>
                    <div style={{ lineHeight: 1.7 }}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: msg.body.__html,
                            }}
                        />
                    </div>
                </div>
                <div className="text-right">
                    {`${translateDate("before")} ${dateDiff[0]} ${translateDate(
                        dateDiff[1] + "Short"
                    )}`}
                </div>
            </div>
        </li>
    );

    function getMsgSubstitutions(notification, translate) {
        const { type, candidate, company, jobPost, isCompany, testCode } =
            notification;
        const { firstName, lastName, imageUrl } = candidate;
        const { logoUrl, name } = company ? company : {};
        const jobTitle = jobPost ? jobPost.title : null;

        const logo = logoUrl ? resolveLogoUrl(logoUrl) : null;
        const resultKey = "SOFT_SKILL_TEST_RESULT";
        const body =
            type === resultKey
                ? translateBody(resultKey, {
                      testName: translateBody(testCode),
                  })
                : translateBody(type, {
                      firstName: firstName,
                      lastName: lastName,
                      imageUrl: imageUrl,
                      name: name,
                      jobTitle: jobTitle,
                      id: candidate ? candidate.id : null,
                  });
        return {
            subject: translate("common.notification.subject." + type),
            body: {
                __html: body,
            },
            logo: isCompany ? imageUrl : logo,
        };
    }
}
