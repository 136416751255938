import React from "react";
import { useTranslate } from "react-translate.ts";

import { LoadingOverlay, useWindowState } from "../../../../common/components";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import { BREAKPOINT_SM } from "../../../../common/_constants/theme.constants";

export function BaseDiagnosticLayout(props) {
    const {
        id,
        isLoading,
        name,
        isFinished,
        contentProcessed,
        contentUnprocessed,
        interpretationContentProcessed,
        interpretationContentEmpty,
        interpretation,
        extraContent,
        status,
    } = props;

    const translate = useTranslate("candidate.softSkillsTest");
    const translateDiagnostic = useTranslate(
        "candidate.softSkillsTest." + name
    );

    const isMobile = useBreakpoint(BREAKPOINT_SM);

    return (
        <div className="soft-skills-test-layout" id={id}>
            {isMobile ? <h3>{translateDiagnostic("title")}</h3> : null}
            <div className="soft-skills-test-layout--panel">
                <LoadingOverlay isLoading={isLoading} isFull />
                <h5>
                    {isFinished
                        ? translate("results")
                        : translateDiagnostic("heading")}
                </h5>
                <div id="soft-skills-test__content">
                    {isFinished
                        ? contentProcessed(interpretation, isLoading)
                        : contentUnprocessed(interpretation, isLoading, status)}
                </div>
            </div>
            <div
                className={
                    "soft-skills-test-layout--panel" +
                    (isFinished ? " processed" : "")
                }
            >
                <LoadingOverlay isLoading={isLoading} isFull />
                <div className="interpretation">
                    {isFinished
                        ? interpretationContentProcessed(
                              interpretation,
                              isLoading
                          )
                        : interpretationContentEmpty(
                              interpretation,
                              isLoading,
                              status
                          )}
                </div>
            </div>
            {extraContent && extraContent(interpretation, isLoading)}
        </div>
    );
}
