const LanguageConstants = {
    GET_LANGUAGES: 'LANGUAGES__GET_LANGUAGES',
    GET_LANGUAGES_SUCCESS: 'LANGUAGES__GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_FAIL: 'LANGUAGES__GET_LANGUAGES_FAIL',

    GET_LEVELS: 'LANGUAGES__GET_LANGUAGE_LEVELS',
    GET_LEVELS_SUCCESS: 'LANGUAGES__GET_LANGUAGE_LEVELS_SUCCESS',
    GET_LEVELS_FAIL: 'LANGUAGES__GET_LANGUAGE_LEVELS_FAIL',

    CHANGE_LOCALE: 'LANGUAGES__CHANGE_LOCALE',
    SET_ALLOWED: 'LANGUAGES__SET_ALLOWED_LOCALES',
};

export default LanguageConstants;
