import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./MobileNav.module.scss";
import { css, cx } from "@emotion/css";

export type MobileNavProps = {
    links: MobileNavLink[];
};

type MobileNavLink = {
    to: string;
    label?: string;
    icon: string;
    size?: number;
};

export default function MobileNav(props: MobileNavProps) {
    const { links } = props;
    return (
        <nav
            className={cx(
                styles.nav,
                css({ gridTemplateColumns: `repeat(${links.length}, 1fr)` })
            )}
        >
            {links.map((link, index) => (
                <Link key={index} link={link} />
            ))}
        </nav>
    );
}

function Link(props: { link: MobileNavLink }) {
    const { link } = props;
    return (
        <NavLink
            className={styles.link}
            to={link.to}
            activeClassName={styles.active}
        >
            <div>
                <i
                    className={`icon-${link.icon}`}
                    style={{ fontSize: link.size }}
                />
            </div>
            {/*<div className={styles.link__label}>{link.label}</div>*/}
        </NavLink>
    );
}
