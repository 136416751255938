import authSaga from "./auth.saga";
import onboardingSaga from "./onboarding.saga";
import companyProfile from "./company-profile.saga";
import notifications from "./notifications.saga";
import user from "./user.saga";
import profile from "./profile.saga";
import jobs from "./jobs.saga";
import publicSaga from "./public.saga";
import language from "./language.saga";
import heroOnboarding from "./heroOnboarding.saga";
import heroProfile from "./heroProfile.saga";

export default [
    ...authSaga,
    ...onboardingSaga,
    ...companyProfile,
    ...notifications,
    ...user,
    ...profile,
    ...jobs,
    ...publicSaga,
    ...language,
    ...heroOnboarding,
    ...heroProfile,
];
