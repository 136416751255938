import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SVGIcon } from "../../../../../../common/components";

import {
    PROFILE_PATH,
    SOFT_SKILL_TEST_PATH,
} from "../../../../../../common/_constants/route.constants";
import { UserProfileSelectors } from "../../../../../../common/_selectors/UserProfileSelectors";
import { useTranslate } from "react-translate.ts";

export function TodoList() {
    const { profileType } = useSelector(mapState);

    const [isOpen, setIsOpen] = useState(true);
    const translate = useTranslate("candidate.toDoList");

    if (!profileType || !isOpen) {
        return null;
    }

    const completed = profileType.profileSectionCompleted;

    const finished = [
        "JOB_EXPERIENCE",
        "EDUCATION",
        "HARD_SKILL",
        "SOFT_SKILL",
    ].every((value) => completed.includes(value));

    if (finished) {
        return null;
    }

    return (
        <div id="career-paths__todo">
            <button onClick={() => setIsOpen(false)}>
                <SVGIcon name="close" />
            </button>
            <div>{translate("title")}</div>
            <div>
                <ul>
                    <Item
                        label={translate("softSkills")}
                        completed={completed.includes("")}
                        link={SOFT_SKILL_TEST_PATH}
                    />
                    <Item
                        label={translate("profile")}
                        completed={[
                            "JOB_EXPERIENCE",
                            "EDUCATION",
                            "HARD_SKILL",
                            "SOFT_SKILL",
                        ].every((value) => completed.includes(value))}
                        link={PROFILE_PATH}
                    />
                </ul>
            </div>
        </div>
    );
}

function Item(props) {
    const { label, completed, link } = props;
    return (
        <li className={completed ? "completed" : null}>
            <Link to={link} />
            <div>
                <div className="circle" />
                <span>{label}</span>
            </div>
            <SVGIcon name="chevron-right" />
        </li>
    );
}

function mapState(state) {
    return {
        profileType: UserProfileSelectors.getType(state),
    };
}
