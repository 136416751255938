import RequestService2 from "../../common/_services/RequestService2";

const JobRoleService2 = {
    getJobRoleList,
    getIndustryCategories,
};

export interface GetJobRoleListPayload {
    filters: {
        title?: string;
        page?: number;
        industryIds?: number[];
    };
}

async function getJobRoleList(
    payload: GetJobRoleListPayload,
    onFail: () => void
) {
    try {
        const response = await RequestService2.get("api/jobs/role-cards", {
            ...payload.filters,
            size: 10,
        });

        return response.data;
    } catch (ex) {
        onFail();
        throw ex;
    }
}

async function getIndustryCategories(onFail: () => void) {
    try {
        const response = await RequestService2.get("api/industry-categories");
        return response.data;
    } catch (ex) {
        onFail();
        throw ex;
    }
}

export default JobRoleService2;
