import {
    GET_JOB_CARDS,
    JOB_CARDS_CHANGE_PAGE,
    SET_JOB_FAVOURITE,
    UNSET_JOB_FAVOURITE
} from '../_constants';

export const JobActions = {
    getCards,
    changePage,
    setFavourite,
    unsetFavourite
};

function getCards(page, activeFilters, profileId) {
    return { type: GET_JOB_CARDS, payload: { page, activeFilters, profileId } };
}

function changePage(page) {
    return { type: JOB_CARDS_CHANGE_PAGE, payload: page };
}

function setFavourite(jobPostId, profileId) {
    return { type: SET_JOB_FAVOURITE, payload: { jobPostId, profileId } };
}

function unsetFavourite(jobPostId, profileId) {
    return { type: UNSET_JOB_FAVOURITE, payload: { jobPostId, profileId } };
}
