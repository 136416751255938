import { combineReducers } from 'redux';

import {
    GET_CREDIT_PRICE_SUCCESS,
    CHANGE_CREDIT_CURRENCY,
    GET_CURRENCIES_SUCCESS,
    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAIL,
} from '../../_constants/actions/wallet.constants';

export default combineReducers({
    currency,
    price,
    currencies,
    loading,
});

function currency(state = { id: 29, code: 'CZK', symbol: 'Kč' }, action) {
    if (action.type === CHANGE_CREDIT_CURRENCY) {
        return action.payload;
    }

    return state;
}

function price(state = 499.99, action) {
    if (action.type === GET_CREDIT_PRICE_SUCCESS) {
        return action.payload.amount;
    }

    return state;
}

function currencies(state = [], action) {
    if (action.type === GET_CURRENCIES_SUCCESS) {
        return action.payload;
    }

    return state;
}

function loading(state = false, action) {
    switch (action.type) {
        case CREATE_PAYMENT:
            return true;
        case CREATE_PAYMENT_SUCCESS:
        case CREATE_PAYMENT_FAIL:
            return false;
        default:
            return state;
    }
}
