import { RequestService } from '../../common/_services';
import { ArrayHelper } from '../../common/_helpers/ArrayHelper';

export const JobService = {
    getCards,
    getSalaryRange,
    saveCard,
    getFilters,
};

const JOB_CARDS_URL = 'job-cards';

function getCards(payload) {
    const url = `api/${JOB_CARDS_URL}?`;
    const params = createParams(payload);
    return RequestService.get(`${url}${params.join('&')}`);

    function createParams(params) {
        const keys = Object.keys(params);

        return keys
            .filter((key) => {
                const param = params[key];
                if (typeof param === 'object') {
                    return !ArrayHelper.isEmpty(param);
                }

                return param !== null || param !== undefined;
            })
            .map((key) => {
                const param = params[key];
                return createParam(key, param);
            });
    }

    function createParam(key, value) {
        if (typeof value === 'object') {
            return `${key}=${value.join(',')}`;
        }
        return `${key}=${value}`;
    }
}

function getSalaryRange(currencyId) {
    return RequestService.get(`${JOB_CARDS_URL}/salary-ranges/${currencyId}`);
}

function saveCard({ jobPostId, profileId, saved }) {
    return RequestService.post('saved-job-posts', {
        jobPostId,
        profileId,
        saved,
    });
}

function getFilters() {
    return RequestService.get('api/job-filters');
}
