import { MatchPie } from "../MatchPie/MatchPie";
import styles from "./HardSkillMatch.module.scss";
import { cx } from "@emotion/css";
import { useTranslate } from "react-translate.ts";
import Skeleton from "../../../common/components/Skeleton/Skeleton";

type HardSkillMatchProps = {
    match: number;
    name: string;
    years: number | null;
    minYear: number | null;
    maxYear: number | null;
    level: number | null;
    levelMin: number | null;
    levelMax: number | null;
    fontSize?: number;
    isLoading: boolean;
};

export function HardSkillMatch(props: HardSkillMatchProps) {
    const {
        match,
        name,
        years,
        minYear,
        maxYear,
        level,
        levelMin,
        levelMax,
        isLoading,
    } = props;

    const translateMisc = useTranslate("common.misc");
    const translateLevels = useTranslate("common.skills.level");

    return (
        <div className={styles.container}>
            <div className={styles.pie}>
                <MatchPie match={match} isAnimationActive={!isLoading} />
            </div>
            <div className="font-book">
                <div className={cx(styles.name)}>
                    {!isLoading ? name : <Skeleton height={19} width={130} />}
                </div>
                <div className={styles.values}>
                    {!isLoading ? (
                        renderYears(years, minYear, maxYear)
                    ) : (
                        <Skeleton height={13} width={180} />
                    )}
                </div>
                <div className={styles.values}>
                    {!isLoading ? (
                        renderLevels(level, levelMin, levelMax)
                    ) : (
                        <Skeleton height={13} width={170} />
                    )}
                </div>
            </div>
        </div>
    );

    function renderYears(
        years: number | null,
        minYear: number | null,
        maxYear: number | null
    ) {
        return `${translateMisc("yearsOfExperience", {
            count: years || 0,
        })}${
            minYear || maxYear
                ? ` ( ${translateMisc("experienceRequired", {
                      min: minYear,
                      max: maxYear,
                  })} )`
                : ""
        }`;
    }

    function renderLevels(
        level: number | null,
        levelMin: number | null,
        levelMax: number | null
    ) {
        return `${translateLevels(level?.toString() || "0")} ( ${translateMisc(
            "experienceRequired",
            {
                min: !!levelMin ? translateLevels(levelMin?.toString()) : null,
                max: !!levelMax ? translateLevels(levelMax?.toString()) : null,
            }
        )} )`;
    }
}
