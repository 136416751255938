import React from 'react';
import { useSelector } from 'react-redux';
import { FormatMoneyRange } from '../';
import { LanguageSelectors } from '../../_selectors/LanguageSelectors';

export function Salary({
    salaryMin,
    salaryMax,
    currency,
    className,
    locale,
    frequency,
}) {
    const currentLocale = useSelector(LanguageSelectors.getLocale);
    return (
        <div className={className}>
            <FormatMoneyRange
                min={salaryMin}
                max={salaryMax}
                currency={currency}
                locale={locale || currentLocale}
                frequency={frequency}
            />
        </div>
    );
}
