import React from "react";
import { createClass } from "classname-helper";

export function OnboardingForm(props) {
    const { className, children } = props;

    return (
        <div
            className={createClass({
                "onboarding-form": true,
                [className]: !!className,
            })}
        >
            {children}
        </div>
    );
}
