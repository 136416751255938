import { combineReducers } from "redux";

import { profile } from "./profile.reducer";
import { skills } from "./skill.reducer";
import { softSkills } from "./softSkills.reducer";
import loading from "./loading.reducer";
import jobs from "./jobs";
import jobRoles from "./jobRoles";
import dashboard from "./dashboard";
import interpretation from "./interpretation.reducer";
import skillsNew from "./skills/skills.reducer";
import careerPaths from "./careerPaths";
import careerSearch from "./careerSearch";
import invites from "./invites.reducer";
import profileNew from "./profile/profile.reducer";
import heroes from "./heroes/heroes.reducer";
import { JobRoleListReducer } from "../_actions/JobRoleListActions";
import { CompanyReducer } from "../_actions/CompanyActions";

export default combineReducers({
    dashboard,
    profile,
    jobRoles,
    jobRoles2: JobRoleListReducer,
    jobs,
    skills,
    softSkills,
    loading,
    interpretation,
    skillsNew,
    careerPaths,
    careerSearch,
    invites,
    profileNew,
    heroes,
    company: CompanyReducer
});
