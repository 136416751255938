import { PropsWithChildren } from "react";
import { useTranslate } from "react-translate.ts";

import ISkillWeightType from "../../../common/types/ISkillWeightType";
import {
    SKILL_WEIGHT_TYPE_PREFERRED,
    SKILL_WEIGHT_TYPE_REQUIRED,
} from "../../../common/_constants/actions/skill.constants";
import BaseEdit, { BaseEditProps } from "../BaseEdit/BaseEdit";
import FormSelect from "../../../common/components/input/FormSelect";
import FormField from "../../../common/components/input/FormField";

interface SkillsEditProps<T> extends BaseEditProps<T> {
    skillName: string;
    valueName: string;
}

export default function SkillsEdit<T>(
    props: PropsWithChildren<SkillsEditProps<T>>
) {
    const { data, createItem, children, skillName, head, valueName, ...rest } =
        props;

    const translate = useTranslate("admin.edit.skills");

    return (
        <BaseEdit<T>
            data={data}
            createItem={createItem}
            head={[<div>{skillName}</div>, ...head]}
            {...rest}
        >
            {children}
            <FormField name={valueName} label={translate("weightType.label")}>
                <FormSelect<ISkillWeightType>
                    name={valueName}
                    placeholder={translate("weightType.placeholder")}
                    options={[
                        SKILL_WEIGHT_TYPE_PREFERRED,
                        SKILL_WEIGHT_TYPE_REQUIRED,
                    ]}
                    getOptionLabel={(option) => option}
                    data-test="skills-edit-weight-type"
                />
            </FormField>
        </BaseEdit>
    );
}
