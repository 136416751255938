import React from "react";
import { useTranslate } from "react-translate.ts";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { InterpretationInfo } from "./InterpretationInfo";
import { InterpretationLanguages } from "./InterpretationLanguages";
import {
    SOFT_SKILL_TEST_COMPARE_CULTURE_PATH,
    SOFT_SKILL_TEST_INTERPRETATION_CULTURE_PATH,
} from "../../../_constants/route.constants";
import SoftSkillsActions from "../../../_actions/SoftSkillsActions";
import InterpretationActions from "../../../_actions/InterpretationActions";
import { useBreakpoint } from "../../../../common/hooks/useBreakpoint";
import { BREAKPOINT_SM } from "../../../../common/_constants/theme.constants";
import { BaseMop } from "./BaseMop";
import Button from "../../../../common/components/buttons/Button/Button";

const TRANSLATE_KEY = "candidate.softSkillsTest";

export default function Hofik() {
    const dispatch = useDispatch();

    const translate = useTranslate(TRANSLATE_KEY);
    const translateInterpretation = useTranslate(
        TRANSLATE_KEY + ".hofik.interpretation"
    );

    const isMobile = useBreakpoint(BREAKPOINT_SM);

    return (
        <BaseMop
            id="soft-skills-test--hofik"
            name="hofik"
            interpretationContentEmpty={renderInterpretationEmpty}
            interpretationContentProcessed={renderInterpretationProcessed}
            getStatus={getStatus}
            getInterpretation={getInterpretation}
            isMobile={isMobile}
            withInfo={false}
        />
    );

    function getStatus(profileId: number, name: string) {
        dispatch(SoftSkillsActions.getStatus(profileId, name));
    }

    function getInterpretation(profileId: number, name: string) {
        dispatch(InterpretationActions.getInterpretation(profileId, name));
    }

    function renderInterpretationEmpty(_: any, __: any, status: any) {
        return (
            <>
                <h5>{translate("hofik.title")}</h5>
                <p
                    dangerouslySetInnerHTML={{
                        __html: translate("hofik.info"),
                    }}
                />
                <InterpretationInfo
                    time={translate("hofik.duration")}
                    count={status.questionCount}
                />
                <InterpretationLanguages languages={["cs", "sk", "en", "pl"]} />
                <div className="interpretation--languages">
                    <div className="interpretation--languages--item"></div>
                </div>
                <div className="interpretation--icon">
                    <img src="/img/soft-skills/decisions.svg" alt="" />
                </div>
            </>
        );
    }

    function renderInterpretationProcessed(interpretation: any) {
        if (!interpretation?.skillInterpretations?.all) {
            return null;
        }

        return (
            <>
                <h5>{translateInterpretation("heading")}</h5>
                <div>
                    {renderList(
                        null,
                        interpretation.skillInterpretations.all[
                            "hofik-company-advantages"
                        ]
                    )}
                </div>
                <div className="interpretation--actions">
                    <Link to={SOFT_SKILL_TEST_INTERPRETATION_CULTURE_PATH}>
                        <Button>{translate("actions.full")}</Button>
                    </Link>
                    <Link to={SOFT_SKILL_TEST_COMPARE_CULTURE_PATH}>
                        <Button variant="secondary">
                            {translate("actions.compare")}
                        </Button>
                    </Link>
                </div>
            </>
        );

        function renderList(title: string, items: any[]) {
            return (
                <div>
                    {title && <h6>{translateInterpretation(title)}</h6>}
                    <ul>
                        {items?.map((item) => (
                            <li key={item.orderIndex}>{item.description}</li>
                        ))}
                    </ul>
                </div>
            );
        }
    }
}
