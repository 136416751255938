import React, { CSSProperties } from "react";
import { cx } from "@emotion/css";

import styles from "./Table.module.scss";

export type TableProps<T = any> = {
    head?: React.ReactElement[];
    data: T[];
    createItem: (value: T, index: number) => React.ReactNode;
    cols?: string[];
    style?: CSSProperties;
    className?: string;
};

export default function Table<T>(props: TableProps<T>) {
    const { head, data, cols, style, createItem, className, ...rest } = props;
    return (
        <div
            className={cx(styles.container, className)}
            style={{ "--columns": cols?.join(" "), ...style } as CSSProperties}
            {...rest}
        >
            {head ? (
                <div className={styles.head}>
                    {head.map((element, i) => (
                        <div key={i}>{element}</div>
                    ))}
                </div>
            ) : null}
            <div className={styles.list}>
                {data.map((item, i) => (
                    <div className={styles.list__item} key={i} data-test="table-item">
                        {createItem(item, i)}
                    </div>
                ))}
            </div>
        </div>
    );
}
