import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";

import ITeamMember from "../../common/types/ITeamMember";
import { NotificationActions } from "../../common/_actions";
import AdminTeamService from "../_services/AdminTeamService";

const NAME = "ADMIN_TEAM";
const GET_MEMBERS_ACTION = `${NAME}/getMembers`;
const DELETE_MEMBER_ACTION = `${NAME}/deleteMember`;
const INVITE_MEMBERS_ACTION = `${NAME}/inviteMembers`;

const getMembers = createAsyncThunk(
    GET_MEMBERS_ACTION,
    AdminTeamService.getMembers
);

const deleteMember = createAsyncThunk(
    DELETE_MEMBER_ACTION,
    AdminTeamService.deleteMember
);

const inviteMembers = createAsyncThunk(
    INVITE_MEMBERS_ACTION,
    async (payload: { id: number }, thunk) => {
        try {
            const result = await AdminTeamService.inviteMembers(payload);

            if (result.ok) {
                thunk.dispatch(NotificationActions.pushSuccess("Success"));
            } else {
                throw null;
            }
        } catch (ex) {
            thunk.dispatch(NotificationActions.pushError("Error"));
        }
    }
);

type State = {
    members: ITeamMember[];
    loading: boolean;
    updating: number[];
};

const initialState: State = {
    members: [],
    loading: false,
    updating: [],
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMembers.pending, (state, action) => {
            return { ...state, loading: true };
        });
        builder.addCase(getMembers.fulfilled, (state, action) => {
            return { ...state, members: action.payload, loading: false };
        });
        builder.addCase(getMembers.rejected, (state) => {
            return { ...state, loading: false };
        });

        builder.addCase(deleteMember.pending, (state, action) => {
            const id = action.meta.arg.id;
            state.updating.push(id);
        });
        builder.addCase(deleteMember.fulfilled, (state, action) => {
            const id = action.meta.arg.id;
            const index = state.members.findIndex((value) => value.id === id);
            if (index >= 0) {
                state.members.splice(index, 1);
            }
            removeUpdating(state.updating, id);
        });
        builder.addCase(deleteMember.rejected, (state, action) => {
            const id = action.meta.arg.id;
            removeUpdating(state.updating, id);
        });
    },
});

function removeUpdating(updating: WritableDraft<number[]>, id: number) {
    const index = updating.findIndex((value) => value === id);
    if (index >= 0) {
        updating.splice(index, 1);
    }
}

const AdminTeamActions = { getMembers, deleteMember, inviteMembers };

export default AdminTeamActions;
export const AdminTeamReducer = slice.reducer;
