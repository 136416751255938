import { useTranslate } from "react-translate.ts";
import { Panel, PanelProps } from "../../../../common/components/Panel/Panel";
import AdminEdit from "../../AdminEdit/AdminEdit";
import SoftSkillsEdit, {
    SoftSkillsEditProps,
} from "../../SoftSkillsEdit/SoftSkillsEdit";

export default function SoftSkillsEditPanel(
    props: SoftSkillsEditProps & PanelProps
) {
    const { skills, jobPostId, ...rest } = props;
    const translate = useTranslate("admin.jobs.edit.skills.soft");

    return (
        <Panel anchor="soft-skills" data-test="soft-skills" {...rest}>
            <AdminEdit.PanelHeading>
                {translate("title")}
            </AdminEdit.PanelHeading>
            <Panel.Subtitle>{translate("subtitle")}</Panel.Subtitle>
            <SoftSkillsEdit skills={skills} jobPostId={jobPostId} />
        </Panel>
    );
}
