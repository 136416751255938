import React from 'react';
import { Translate } from 'react-translate.ts';
import { AccessTime } from '@material-ui/icons';

import { Flex } from '../layout';
import { TrackingTimer } from './TrackingTimer';

export function TrackingTime({ endDate, onTimeChange }) {
    return (
        <Flex direction="column" className="tracking-card--time">
            <Translate className="text-secondary font-10">
                admin.candidate.tracking.response-time
            </Translate>
            <Flex alignItems="center">
                <div className="tracking-card--time--icon">
                    <AccessTime color="secondary" />
                </div>
                <div className="font-13 text-blue">
                    <TrackingTimer
                        endDate={endDate}
                        onTimeChange={onTimeChange}
                    />
                </div>
            </Flex>
        </Flex>
    );
}
