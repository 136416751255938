import ICurrency from "../types/ICurrency";
import RequestService2 from "./RequestService2";

const CurrencyService = {
    getCurrencies
};

async function getCurrencies() {
    return (await RequestService2.get<ICurrency[]>("currencies/default")).data;
}

export default CurrencyService;
