import { createSelector } from "reselect";
import { MathHelper } from "../_helpers";

const profile = (state) => state.common.profile;

const getImage = createSelector(profile, ({ image }) => image);

const getProfileCompleteness = createSelector(
    profile,
    ({ completeness }) => completeness.completeness
);

const getCompletenessPercentage = createSelector(
    getProfileCompleteness,
    (completeness) => MathHelper.round(completeness * 100, 0)
);

const getProfileType = createSelector(profile, (profile) => profile.type);

// const getProfileName = createSelector(profile, (profile) => )

export const UserProfileSelectors = {
    getImage,
    getProfileCompleteness,
    getType: getProfileType,
    getCompletenessPercentage,
};
