import {
    KeyboardEvent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import {
    ARROW_DOWN,
    ARROW_UP,
    ENTER,
    ESCAPE,
    TAB,
} from "../../../_constants/input.constants";
import Input, { InputProps } from "../Input/Input";
import SelectMenu from "../SelectMenu/SelectMenu";

interface Option {
    value?: string | number;
    label?: string;
}

interface BaseProps<T extends Option> {
    options?: T[];
    getOptionValue?: (option: T) => string | number;
    getOptionLabel?: (option: T) => string;
}

interface AutoCompleteProps<T extends Option> extends BaseProps<T> {
    searchable?: boolean;
    inputProps?: InputProps;
    className?: string;
    loadOptions?: (search: string) => Promise<T[]>;
    value: string;
    onChange: (value: string) => void;
    onSelect?: (value: string) => void;
    menuClassName?: string;
}

type State<T> = {
    hovered: number | null;
    options: T[];
};

export default function AutoComplete<T extends Option>(
    props: AutoCompleteProps<T>
) {
    const {
        options,
        onSelect,
        inputProps,
        className,
        loadOptions,
        value,
        onChange,
        getOptionLabel,
        getOptionValue,
        menuClassName,
    } = props;
    const [menuVisible, setMenuVisible] = useState(false);
    const [state, setState] = useState<State<T>>({
        hovered: null,
        options: options || [],
    });

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        const asyncSearch = async () => {
            if (loadOptions) {
                const newOptions = await loadOptions(value);
                setState((prev) => ({ ...prev, options: newOptions }));
            }
        };

        asyncSearch();
    }, [loadOptions, value]);

    return (
        <div className={className}>
            <Input
                {...inputProps}
                forwardRef={inputRef}
                onChange={(ev) => {
                    onChange(ev.target.value);
                    setState((prev) => setHovered(prev, null));
                }}
                value={value}
                onFocus={handleFocus}
                onKeyDown={handleKeypress}
            />
            <Menu<T>
                className={menuClassName}
                isVisible={menuVisible && state.options?.length > 0}
                inputRef={inputRef}
                options={state.options}
                onSelect={(option) =>
                    handleSelect(
                        getOptionLabel ? getOptionLabel(option) : option.label
                    )
                }
                hovered={state.hovered}
                onHover={(index) => setState((prev) => setHovered(prev, index))}
                onClose={close}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
            />
        </div>
    );

    function handleSelect(value: string) {
        onChange(value);
        close();
        onSelect && onSelect(value);
    }

    function handleKeySelect() {
        if (state.hovered === null) {
            handleSelect(value);
        } else {
            const option = state.options[state.hovered];
            handleSelect(
                getOptionLabel ? getOptionLabel(option) : option.label
            );
        }
    }

    function handleFocus() {
        setMenuVisible(true);
    }

    function close() {
        inputRef?.current?.blur();
        setMenuVisible(false);
        setState((prev) => setHovered(prev, null));
    }

    function handleArrowUp(ev: KeyboardEvent) {
        ev.preventDefault();

        setState((prev) => {
            let newIndex = null;
            if (prev.hovered === null) {
                newIndex = prev.options.length - 1;
            } else {
                newIndex = prev.hovered - 1 < 0 ? null : prev.hovered - 1;
            }

            return { ...prev, hovered: newIndex };
        });
    }

    function handleArrowDown(ev: KeyboardEvent) {
        ev.preventDefault();

        setState((prev) => {
            let newIndex = null;
            if (prev.hovered === null) {
                newIndex = 0;
            } else {
                newIndex =
                    prev.hovered + 1 >= prev.options.length
                        ? null
                        : prev.hovered + 1;
            }

            return { ...prev, hovered: newIndex };
        });
    }

    function setHovered(state: State<T>, index: number | null) {
        return { ...state, hovered: index };
    }

    function handleKeypress(ev: KeyboardEvent) {
        if (ev.repeat) {
            return;
        }

        switch (ev.key) {
            case ENTER:
            case TAB:
                handleKeySelect();
                break;
            case ESCAPE:
                close();
                break;
            case ARROW_UP:
                handleArrowUp(ev);
                break;
            case ARROW_DOWN:
                handleArrowDown(ev);
                break;
        }
    }
}

interface MenuProps<T extends Option> extends BaseProps<T> {
    isVisible: boolean;
    onClose: () => void;
    inputRef: MutableRefObject<HTMLInputElement>;
    hovered: number;
    onHover: (index: number) => void;
    onSelect: (option: T) => void;
    className?: string;
}

function Menu<T extends Option>(props: MenuProps<T>) {
    const {
        isVisible,
        inputRef,
        options,
        onSelect,
        hovered,
        onHover,
        onClose,
        getOptionLabel,
        className,
    } = props;
    const menuRef = useRef();

    const handleClick = useCallback((ev: any) => {
        const target = ev.target;

        if (
            target === inputRef?.current ||
            target === menuRef?.current ||
            target.parentNode === menuRef?.current
        ) {
            return;
        }

        onClose();
    }, [onClose, inputRef]);

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => document.removeEventListener("click", handleClick);
    }, [handleClick]);

    return (
        <SelectMenu
            isVisible={isVisible}
            forwardRef={menuRef}
            className={className}
            onMouseLeave={onMouseLeave}
        >
            {options &&
                Array.isArray(options) &&
                options.map((option, index) => (
                    <SelectMenu.Item
                        key={index}
                        hovered={hovered === index}
                        onClick={onClick(option)}
                        onMouseOver={onMouseOver(index)}
                    >
                        {getOptionLabel ? getOptionLabel(option) : option.label}
                    </SelectMenu.Item>
                ))}
        </SelectMenu>
    );

    function onMouseOver(index: number) {
        return () => handleHover(index);
    }

    function onMouseLeave() {
        handleHover(null);
    }

    function onClick(option: T) {
        return () => handleSelect(option);
    }

    function handleSelect(option: T) {
        onSelect && onSelect(option);
    }

    function handleHover(index: number) {
        onHover && onHover(index);
    }
}
