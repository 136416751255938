import React from 'react';

import { JobCard } from './JobCard';

export function JobList({ jobs, displayOnly = true }) {
    return (
        <div className="job-list">
            {jobs.map((job, key) => (
                <JobCard key={key} job={job} withLink={!displayOnly} />
            ))}
        </div>
    );
}
