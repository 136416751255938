import { UserConstants } from '../_constants/actions/user.constants';

const forgotPasswordState = {
    passwordChangePending: false,
    loading: false,
    passwordChanged: false,
};

const initialState = {
    loggedIn: false,
    user: { user: {} },
    current: [],
    loading: false,
    error: [],
    signedIn: false,
    verification: null,
    isLoaded: false,
    forgotPassword: forgotPasswordState,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UserConstants.FORGOT_PASSWORD_BEGIN:
        case UserConstants.FORGOT_PASSWORD_SUCCESS:
        case UserConstants.FORGOT_PASSWORD_ERROR:
        case UserConstants.RESET_PASSWORD:
        case UserConstants.RESET_PASSWORD_SUCCESS:
        case UserConstants.RESET_PASSWORD_ERROR:
            return {
                ...state,
                forgotPassword: forgotPassword(state.forgotPassword, action),
            };

        default:
            return state;
    }

    function forgotPassword(state = forgotPasswordState, action) {
        switch (action.type) {
            case UserConstants.FORGOT_PASSWORD_BEGIN:
                return { ...state, loading: true };
            case UserConstants.FORGOT_PASSWORD_SUCCESS:
                return {
                    ...state,
                    passwordChangePending: true,
                    loading: false,
                };
            case UserConstants.FORGOT_PASSWORD_ERROR:
                return { ...state, loading: false };
            case UserConstants.RESET_PASSWORD:
                return { ...state, passwordChanged: false, loading: true };
            case UserConstants.RESET_PASSWORD_SUCCESS:
                return {
                    ...state,
                    passwordChanged: true,
                    loading: false,
                };
            case UserConstants.RESET_PASSWORD_ERROR:
                return { ...state, passwordChanged: false, loading: false };
            default:
                return state;
        }
    }
}
