import { cx } from "@emotion/css";
import React, { PropsWithChildren } from "react";

import styles from "./OnboardingLayout.module.scss";

type OnboardingLayoutProps = PropsWithChildren<{
    rightSideContent?: JSX.Element;
    color?: string;
    imageProps?: {
        className?: string;
        src?: string;
    };
}>;

export default function OnboardingLayout(props: OnboardingLayoutProps) {
    const { children, rightSideContent, color, imageProps = {} } = props;

    return (
        <div className={styles.layout}>
            <div className={styles.left}>{children}</div>
            <div className={cx(styles.right, styles[color])}>
                {rightSideContent}
                {imageProps?.src && (
                    <img
                        src={imageProps.src}
                        className={imageProps?.className}
                        alt=""
                    />
                )}
            </div>
        </div>
    );
}
