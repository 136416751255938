import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { MainState } from "../../root.reducer";

const AdminJobSelectors = {
    useGetEdit,
    useGetCreate,
    useGetList,
    useGetEmploymentTypes,
    useGetSalaryTypes,
    useGetWorkloads,
    useGetPending
};

const state = (state: MainState) => state.admin.job;

const getEdit = createSelector(state, (state) => state.edit);
const getCreate = createSelector(state, (state) => state.create);
const getList = createSelector(state, (state) => state.list);
const getEmploymentTypes = createSelector(
    getEdit,
    (edit) => edit.employmentTypes
);
const getSalaryTypes = createSelector(getEdit, (edit) => edit.salaryTypes);
const getWorkloads = createSelector(getEdit, (edit) => edit.workloads);
const getPending = createSelector(getEdit, (edit) => edit.pending);

function useGetEdit() {
    return useSelector(getEdit);
}

function useGetCreate() {
    return useSelector(getCreate);
}

function useGetList() {
    return useSelector(getList);
}

function useGetEmploymentTypes() {
    return useSelector(getEmploymentTypes);
}

function useGetSalaryTypes() {
    return useSelector(getSalaryTypes);
}

function useGetWorkloads() {
    return useSelector(getWorkloads);
}

function useGetPending() {
    return useSelector(getPending);
}

export default AdminJobSelectors;
