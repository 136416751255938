import { createElement, CSSProperties } from "react";
import cx from 'classnames';

import styles from "./Skeleton.module.scss";

type SkeletonProps = {
    count?: number;
    width?: string | number;
    height?: string | number;
    marginBottom?: number;
    type?: string;
    className?: string;
    style?: CSSProperties;
};

export default function Skeleton(props: SkeletonProps) {
    const { width, height, marginBottom, count, type = "div", className, style } = props;

    if (count) {
        return (
            <>
                {Array.from(new Array(count), (_, i) => (
                    <Component key={i} />
                ))}
            </>
        );
    }

    return <Component />;

    function Component() {
        return createElement(type, {
            className: cx(styles.skeleton, className),
            style: { width, height, marginBottom, ...style },
        });
    }
}
