import { ProfileItemCard } from '../ProfileItemCard';
import { ArrayHelper } from '../../../_helpers/ArrayHelper';
import { AddButton } from '../../buttons';
import { Translate } from 'react-translate.ts';

export function ExperienceList(props) {
    const {
        items,
        label,
        placeholder,
        Item,
        onAdd,
        onEdit,
        displayOnly = false,
    } = props;
    const hasItems = !ArrayHelper.isEmpty(items);

    return (
        <ProfileItemCard
            label={label}
            extraContent={<ExtraContent {...{ displayOnly, onAdd }} />}
        >
            <Items
                {...{ items, placeholder, onEdit, Item, hasItems, displayOnly }}
            />
        </ProfileItemCard>
    );
}

function Items({ items, placeholder, onEdit, Item, hasItems, displayOnly }) {
    if (!hasItems) {
        return <Placeholder placeholder={placeholder} />;
    }

    return items.map((item) => (
        <Item
            key={item.id}
            item={item}
            onEdit={() => onEdit && onEdit(item.id)}
            displayOnly={displayOnly}
        />
    ));
}

function Placeholder({ placeholder }) {
    return (
        <p className="text-secondary">
            <Translate textOnly>{placeholder || ''}</Translate>
        </p>
    );
}

function ExtraContent({ onAdd, displayOnly }) {
    if (displayOnly) {
        return null;
    }

    return <AddButton onAdd={onAdd} />;
}
