import React from 'react';
import { useSelector } from 'react-redux';

import { LanguageMenu } from './LanguageMenu';
import { BaseNavButton } from './BaseNavButton';
import { LanguageSelectors } from '../../_selectors/LanguageSelectors';

export function LanguageButton(props) {
    const locale = useSelector(LanguageSelectors.getLocale);

    return (
        <BaseNavButton
            Icon={
                () => <span>{locale}</span>
                // <i
                //     className="header-nav__button__icon__lang"
                //     style={{
                //         backgroundImage: `url(/img/flags/${locale}.svg)`,
                //     }}
                // />
            }
            Menu={LanguageMenu}
            {...props}
        />
    );
}
