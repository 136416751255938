import { useEffect, useRef } from 'react';
import { addEventHandler } from '../_helpers/addEventHandler';
import { removeEventHandler } from '../_helpers/removeEventHandler';

export function useDisableScroll(isDisabled) {
    const ref = useRef(null);

    useEffect(() => {
        if (isDisabled) {
            addEvents();
        } else {
            removeEvents();
        }

        return () => removeEvents();
    }, [isDisabled, ref.current]);

    return ref;

    function disableScroll(event) {
        event.preventDefault();
        return false;
    }

    function addEvents() {
        if (!ref.current) {
            return;
        }

        addEventHandler(window, 'touchend', disableScroll);
        document.body.style.overflow = 'hidden';
    }

    function removeEvents() {
        if (!ref.current) {
            return;
        }

        removeEventHandler(window, 'touchend', disableScroll);
        document.body.style.overflow = 'auto';
    }
}
