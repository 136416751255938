import React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationCard } from './ApplicationCard';

export function ApplicationsList({ applicationsSelector }) {
    const applications = useSelector((state) => applicationsSelector(state));

    return (
        <div className="tracking-column">
            {applications.map((application, key) => (
                <ApplicationCard
                    key={key}
                    application={application}
                    canDrag={false}
                />
            ))}
        </div>
    );
}
