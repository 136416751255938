import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslate } from "react-translate.ts";
import { Formik, useFormikContext } from "formik";

import { AutocompleteService } from "../../../candidate/_services";
import { SVGIcon } from "../../../common/components";
import Field from "../../../common/components/input/Field/Field";
import RangeField from "../../../common/components/input/RangeField/RangeField";
import Skeleton from "../../../common/components/Skeleton/Skeleton";
import IIdealQualification from "../../../common/types/IIdealQualification";
import ISkillWeightType from "../../../common/types/ISkillWeightType";
import {
    SKILL_WEIGHT_TYPE_PREFERRED,
    SKILL_WEIGHT_TYPE_REQUIRED,
} from "../../../common/_constants/actions/skill.constants";
import AdminJobActions from "../../_actions/AdminJobActions";
import AdminJobSelectors from "../../_selectors/AdminJobSelectors";
import BaseEdit from "../BaseEdit/BaseEdit";
import FormSelect from "../../../common/components/input/FormSelect";
import FormInput from "../../../common/components/input/FormInput";
import FormCheckbox from "../../../common/components/input/FormCheckbox";
import FormMultiSelect from "../../../common/components/input/MultiSelect/FormMultiSelect";
import { ArraySchema, ObjectSchema } from "yup";
import FormField from "../../../common/components/input/FormField";
import { REQUIRED_FORM_FIELD } from "../../../common/_constants/validation.constants";

export type QualificationEditProps = {
    qualifications: IIdealQualification[];
    jobPostId: number;
};

type Values = Partial<IIdealQualification>;

const EDU_LEVEL_MIN = "educationLevelMin";
const EDU_LEVEL_MAX = "educationLevelMax";
const EDU_YEAR_MIN = "educationYearMin";
const EDU_YEAR_MAX = "educationYearMax";
const COMPLETED = "educationCompleted";
const TYPE = "type";
const CATEGORIES = "universityDegreeFieldCategories";
const FIELDS = "universityDegreeFields";
const TITLES = "universityDegreeTitles";

const DEFAULT_VALUES: Values = {
    [TYPE]: SKILL_WEIGHT_TYPE_PREFERRED,
    [COMPLETED]: undefined,
    [EDU_LEVEL_MAX]: undefined,
    [EDU_LEVEL_MIN]: undefined,
    [EDU_YEAR_MAX]: undefined,
    [EDU_YEAR_MIN]: undefined,
    [CATEGORIES]: undefined,
    [FIELDS]: undefined,
    [TITLES]: undefined,
};

export default function QualificationEdit(props: QualificationEditProps) {
    const { qualifications, jobPostId } = props;
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={DEFAULT_VALUES}
            onSubmit={submit}
            enableReinitialize
            validationSchema={createValidation()}
        >
            <Inner qualifications={qualifications} jobPostId={jobPostId} />
        </Formik>
    );

    function createValidation() {
        return new ObjectSchema({
            [FIELDS]: new ArraySchema().required(REQUIRED_FORM_FIELD).nullable(),
            [CATEGORIES]: new ArraySchema().required(REQUIRED_FORM_FIELD).nullable(),
        });
    }

    function submit(values: Values) {
        dispatch(
            AdminJobActions.saveQualification({
                ...values,
                jobPostId,
            })
        );
    }
}

type InnerProps = QualificationEditProps & {};

function Inner(props: InnerProps) {
    const { qualifications, jobPostId } = props;
    const translateEdit = useTranslate("admin.edit");
    const translateQualification = useTranslate(
        "admin.edit.skills.qualification"
    );
    const pending = AdminJobSelectors.useGetPending();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const formik = useFormikContext<Values>();

    return (
        <BaseEdit<IIdealQualification>
            data={qualifications}
            head={[
                <div>{translateQualification("table.field")}</div>,
                <div>{translateQualification("table.category")}</div>,
                <div>{translateQualification("table.level")}</div>,
                <div>{translateQualification("table.years")}</div>,
            ]}
            cols={["3fr", "3fr", "3fr", "1fr"]}
            createItem={(qualification) => {
                const {
                    id,
                    universityDegreeFields,
                    universityDegreeFieldCategories,
                    educationLevelMin,
                    educationLevelMax,
                    educationYearMin,
                    educationYearMax,
                } = qualification;
                const isLoading = pending.qualifications.includes(id);
                if (isLoading) {
                    return <Skeleton height={18} count={2} width="80%" />;
                }

                return (
                    <>
                        <div data-test="qualification-edit-field">
                            {createName(universityDegreeFields)}
                        </div>
                        <div>{createName(universityDegreeFieldCategories)}</div>
                        <div>
                            {renderRange(
                                educationLevelMin?.name,
                                educationLevelMax?.name
                            )}
                        </div>

                        <div>
                            {renderRange(educationYearMin, educationYearMax)}
                        </div>
                    </>
                );
            }}
            title={translateQualification("title")}
            isVisible={visible}
            onEdit={openEditDialog}
            onCreate={openNewDialog}
            onSave={save}
            onCancel={cancel}
            onClose={cancel}
            onDelete={delete_}
            createButtonText={translateQualification("create")}
            getItemName={(item) => createName(item?.universityDegreeFields)}
        >
            <FormField
                name={FIELDS}
                // label={
                //     <>
                //         <SVGIcon name="school" style={{ fontSize: 23 }} />
                //         <span>
                //             {translateQualification("form.field.label")}
                //         </span>
                //     </>
                // }
                label={translateQualification("form.field.label")}
                required
            >
                <FormMultiSelect<{ name: string }>
                    name={FIELDS}
                    placeholder={translateQualification(
                        "form.field.placeholder"
                    )}
                    loadOptions={AutocompleteService.degreeFields}
                    getOptionLabel={(option) => option.name}
                    data-test="qualification-edit-field"
                />
            </FormField>
            <FormField
                name={CATEGORIES}
                label={translateQualification("form.category.label")}
                required
            >
                <FormMultiSelect<{ name: string }>
                    name={CATEGORIES}
                    placeholder={translateQualification(
                        "form.category.placeholder"
                    )}
                    loadOptions={AutocompleteService.degreeFieldCategories}
                    getOptionLabel={(option) => option.name}
                    data-test="qualification-edit-category"
                />
            </FormField>
            <RangeField
                label={translateQualification("form.level.label")}
                from={
                    <FormSelect<{ name: string }>
                        name={EDU_LEVEL_MIN}
                        placeholder={translateQualification(
                            "form.level.placeholder.min"
                        )}
                        loadOptions={AutocompleteService.educationLevels}
                        getOptionLabel={(option) => option.name}
                        searchable
                        preload
                        data-test="qualification-edit-level-min"
                    />
                }
                to={
                    <FormSelect<{ name: string }>
                        name={EDU_LEVEL_MAX}
                        placeholder={translateQualification(
                            "form.level.placeholder.max"
                        )}
                        loadOptions={AutocompleteService.educationLevels}
                        getOptionLabel={(option) => option.name}
                        searchable
                        preload
                        data-test="qualification-edit-level-max"
                    />
                }
            />
            <RangeField
                label={translateQualification("form.year.label")}
                from={
                    <FormInput
                        name={EDU_YEAR_MIN}
                        placeholder={translateQualification(
                            "form.year.placeholder.min"
                        )}
                        type="number"
                        data-test="qualification-edit-years-min"
                    />
                }
                to={
                    <FormInput
                        name={EDU_YEAR_MAX}
                        placeholder={translateQualification(
                            "form.year.placeholder.max"
                        )}
                        type="number"
                        data-test="qualification-edit-years-max"
                    />
                }
            />
            <Field>
                <FormCheckbox
                    name={COMPLETED}
                    label={translateQualification("form.complete")}
                    data-test="qualification-edit-complete"
                />
            </Field>
            <Field label={translateEdit("weightType.label")}>
                <FormSelect<ISkillWeightType>
                    name={TYPE}
                    placeholder={translateEdit("weightType.placeholder")}
                    options={[
                        SKILL_WEIGHT_TYPE_PREFERRED,
                        SKILL_WEIGHT_TYPE_REQUIRED,
                    ]}
                    getOptionLabel={(option) => option}
                    data-test="qualification-edit-type"
                />
            </Field>
        </BaseEdit>
    );

    function createName(items: { name: string }[]) {
        return items?.map((item) => item.name).join(" | ");
    }

    function delete_(qualification: IIdealQualification) {
        dispatch(
            AdminJobActions.deleteQualification({
                id: qualification.id,
                jobPostId,
            })
        );
    }

    async function save() {
        await formik.submitForm();

        if (formik.isValid) {
            closeDialog();
        }
    }

    function cancel() {
        closeDialog();
    }

    function openNewDialog() {
        formik.setValues(DEFAULT_VALUES);
        setVisible(true);
    }

    function openEditDialog(values: IIdealQualification) {
        formik.setValues(values);
        setVisible(true);
    }

    function closeDialog() {
        formik.setValues(DEFAULT_VALUES);
        formik.setErrors({});
        formik.setTouched({});
        setVisible(false);
    }
}

function renderRange(min: number | string, max: number | string) {
    const parts = [];
    if (min) {
        parts.push(min.toString());
    }
    if (max) {
        parts.push(max.toString());
    }

    if (min && max) {
        parts.splice(1, 0, " - ");
    }

    return parts.join("");
}
