import {
    GET_CANDIDATE_PROFILE_SUCCESS,
    GET_CANDIDATE_PROFILE_MATCH_SUCCESS,
} from '../../../_constants/actions/candidates.constants';

const initialState = {
    educations: [],
    locations: [],
    locationCities: [],
    phoneNumbers: [],
    emails: [],
    about: {},
    interests: [],
    languages: [],
    match: {
        skillMatch: [],
        candidateSkillCount: 0,
        score: 0,
        scoreHard: 0,
        scoreSoft: 0,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CANDIDATE_PROFILE_SUCCESS:
            return Object.assign({}, state, action.payload);
        case GET_CANDIDATE_PROFILE_MATCH_SUCCESS:
            return Object.assign({}, state, {
                match: action.payload,
            });
        default:
            return state;
    }
}
