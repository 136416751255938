import HeroOnboardingConstants from "../../_constants/actions/HeroOnboardingConstants";

const Skills = {
    getTest,
    startTest,
    getStatus,
    completeTest,
};

function getTest(profileId: number) {
    return {
        type: HeroOnboardingConstants.GET_TEST,
        payload: profileId,
    };
}

function startTest() {
    return { type: HeroOnboardingConstants.START_TEST };
}

function getStatus(profileId: number) {
    return {
        type: HeroOnboardingConstants.GET_STATUS,
        payload: profileId,
    };
}

function completeTest(profileId: number, id: number) {
    return {
        type: HeroOnboardingConstants.COMPLETE_TEST,
        payload: { profileId, id },
    };
}

export default Skills;
