import { takeLatest } from "redux-saga/effects";

import { SagaHelpers } from "../../common/_helpers/SagaHelpers";
import { CareerPathsService } from "../_services/CareerPathsService";
import { IndustryService } from "../_services/IndustryService";
import { CareerPathsConstants } from "../_constants/actions/CareerPathsConstants";

function* getMap(action) {
    yield SagaHelpers.handleRequest({
        request: [CareerPathsService.getMap, action.payload],
        actions: [
            CareerPathsConstants.GET_MAP_SUCCESS,
            CareerPathsConstants.GET_MAP_FAIL,
        ],
        notifyError: false,
    });
}

function* getMapPaths(action) {
    yield SagaHelpers.handleRequest({
        request: [CareerPathsService.getMapPaths, action.payload],
        actions: [
            CareerPathsConstants.GET_MAP_PATHS_SUCCESS,
            CareerPathsConstants.GET_MAP_PATHS_FAIL,
        ],
        notifyError: false,
    });
}

function* getTooltip(action) {
    yield SagaHelpers.handleRequest({
        request: [CareerPathsService.getTooltip, action.payload],
        actions: [
            CareerPathsConstants.GET_MAP_TOOLTIP_SUCCESS,
            CareerPathsConstants.GET_MAP_TOOLTIP_FAIL,
        ],
    });
}

function* getDetail(action) {
    yield SagaHelpers.handleRequest({
        request: [CareerPathsService.getDetail, action.payload],
        actions: [
            CareerPathsConstants.GET_MAP_DETAIL_SUCCESS,
            CareerPathsConstants.GET_MAP_DETAIL_FAIL,
        ],
    });
}

function* getCollegeData(action) {
    yield SagaHelpers.handleRequest({
        request: [IndustryService.getIndustryCategories, action.payload],
        actions: [
            CareerPathsConstants.GET_COLLEGE_DATA_SUCCESS,
            CareerPathsConstants.GET_COLLEGE_DATA_FAIL,
        ],
    });
}

function* getCategories(action) {
    yield SagaHelpers.handleRequest({
        request: [CareerPathsService.getCategories, action.payload],
        actions: [
            CareerPathsConstants.GET_CATEGORIES_SUCCESS,
            CareerPathsConstants.GET_CATEGORIES_FAIL,
        ],
    });
}

export default [
    takeLatest(CareerPathsConstants.GET_MAP, getMap),
    takeLatest(CareerPathsConstants.GET_MAP_PATHS, getMapPaths),
    takeLatest(CareerPathsConstants.GET_MAP_TOOLTIP, getTooltip),
    takeLatest(CareerPathsConstants.GET_MAP_DETAIL, getDetail),
    takeLatest(CareerPathsConstants.GET_MAP_DETAIL, getDetail),
    takeLatest(CareerPathsConstants.GET_COLLEGE_DATA, getCollegeData),
    takeLatest(CareerPathsConstants.GET_CATEGORIES, getCategories),
];
