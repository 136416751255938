import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Translate } from "react-translate.ts";

import { Button, ScrollToTop } from "../components";
import { AuthSelectors } from "../_selectors/AuthSelectors";

export function NotAllowed() {
    const { isSigned, isActive, isHero } = useSelector(mapState);
    const to = resolveRoute(isSigned, isActive, isHero);

    return (
        <>
            <ScrollToTop />
            <div id="not-found">
                <h2>
                    <Translate textOnly>common.403.title</Translate>
                </h2>
                <div id="not-found__text">
                    <Translate textOnly>common.404.text</Translate>
                </div>
                <Link to={to}>
                    <Button isPill>
                        <Translate textOnly>common.404.link</Translate>
                    </Button>
                </Link>
            </div>
        </>
    );
}

function resolveRoute(isSigned, isActive, isHero) {
    if (isSigned) {
        if (!isActive) {
            return isHero ? "/hero-onboarding" : "/onboarding";
        }

        return "/profile/information";
    }

    return "/login";
}

function mapState(state) {
    return {
        isSigned: AuthSelectors.isSigned(state),
        isActive: AuthSelectors.isActive(state),
        isHero: AuthSelectors.isHero(state),
    };
}
