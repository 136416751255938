import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";
import { Translate } from "react-translate.ts";

import { SVGIcon } from "../../../../common/components";
import HeroProfileSelectors from "../../../_selectors/HeroProfileSelectors";

export function Career() {
    const { advice } = useSelector(mapState);
    const [state, setState] = useState(createState());

    useEffect(() => {
        setState(createState());
    }, [advice.length]);

    return (
        <div id="hero-profile__career" className="hero-card">
            <h5>
                <Translate textOnly>
                    candidate.heroes.profile.advice.title
                </Translate>
            </h5>
            <ul>
                {advice.map((item, i) => (
                    <ListItem
                        key={i}
                        title={item.question.text}
                        content={item.answer}
                        open={state.get(i)}
                        onClick={() => setState(state.set(i, !state.get(i)))}
                    />
                ))}
            </ul>
        </div>
    );

    function createState() {
        return List(Array.from(new Array(advice.length), (_, i) => i === 0));
    }
}

function ListItem(props) {
    const { title, content, open, onClick } = props;

    return (
        <li className={open ? "open" : ""}>
            <div className="item-title" onClick={onClick}>
                <div>{title}</div>
                <ListButton open={open} onClick={onClick} />
            </div>
            <div className="item-text">
                <p>{content}</p>
            </div>
        </li>
    );
}

function ListButton(props) {
    const { open } = props;

    return (
        <div className="list-button">
            <SVGIcon name={open ? "minus" : "plus"} />
        </div>
    );
}

function mapState(state) {
    return {
        advice: HeroProfileSelectors.getAdvice(state),
    };
}
