import {
    ActionReducerMapBuilder,
    createAction,
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import { ReducerHelpers } from "../../candidate/_helpers";
import IBaseState from "../../common/types/IBaseState";

import IImage from "../../common/types/IImage";
import IMetric from "../../common/types/IMetric";
import IMetricType from "../../common/types/IMetricType";
import IUploadState from "../../common/types/IUploadState";
import { NotificationActions } from "../../common/_actions";
import CompanyProfileActions from "../../common/_actions/CompanyProfileActions";
import AdminProfileEditService from "../_services/AdminProfileEditService";

const NAME = "ADMIN_PROFILE_EDIT";

const uploadVideo = createAsyncThunk(
    `${NAME}/uploadVideo`,
    async (
        payload: Parameters<typeof AdminProfileEditService.uploadVideo>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.uploadVideo(
            payload,
            (progress: number) => thunk.dispatch(updateVideoProgress(progress))
        );

        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.video.success")
        );

        return response;
    }
);
const updateVideoProgress = createAction<number>(`${NAME}/uploadVideoProgress`);

const uploadImage = createAsyncThunk(
    `${NAME}/uploadImage`,
    async (
        payload: Parameters<typeof AdminProfileEditService.uploadImage>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.uploadImage(
            payload,
            (progress: number) =>
                thunk.dispatch(
                    updateImageProgress({ progress, name: payload.fileName })
                )
        );

        thunk.dispatch(
            NotificationActions.pushSuccess("admin.profile.image.success")
        );

        return response;
    }
);

const updateImageProgress = createAction<{ progress: number; name: string }>(
    `${NAME}/uploadImageProgress`
);
const deleteImage = createAsyncThunk(
    `${NAME}/deleteImage`,
    async (
        payload: Parameters<typeof AdminProfileEditService.deleteImage>[0],
        thunk
    ) => {
        const response = await AdminProfileEditService.deleteImage(payload);
        thunk.dispatch(
            NotificationActions.pushSuccess(
                "admin.profile.image.delete.success"
            )
        );

        return response;
    }
);

const getBenefitMetrics = createAsyncThunk(
    `${NAME}/getBenefitMetrics`,
    AdminProfileEditService.getBenefitMetrics
);
const getBenefitMetricTypes = createAsyncThunk(
    `${NAME}/getBenefitMetricTypes`,
    AdminProfileEditService.getBenefitMetricTypes
);

type MediaState = {
    uploading: boolean;
    progress: number;
    fileName: string | null;
    url: string | null;
};

type State = {
    video: MediaState;
    images: {
        upload: { [key: string]: IUploadState };
        list: IImage[];
    };
    benefits: {
        metric: IBaseState<IMetric[]>;
        metricType: IBaseState<IMetricType[]>;
    };
};

const initialState: State = {
    video: { uploading: false, progress: 0, url: null, fileName: null },
    images: {
        upload: {},
        list: [],
    },
    benefits: {
        metric: {
            data: null,
            loading: false,
        },
        metricType: {
            data: null,
            loading: false,
        },
    },
};

const slice = createSlice({
    name: NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            CompanyProfileActions.getDetail.fulfilled,
            (state, action) => {
                if (action?.payload) {
                    const { images } = action.payload;
                    state.images.list = images;
                }
            }
        );

        videoReducers(builder);
        imageReducers(builder);

        builder.addCase(getBenefitMetricTypes.pending, (state) =>
            ReducerHelpers.pending(state.benefits.metricType)
        );
        builder.addCase(getBenefitMetricTypes.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.benefits.metricType, action)
        );
        builder.addCase(getBenefitMetricTypes.rejected, (state) =>
            ReducerHelpers.rejected(state.benefits.metricType)
        );

        builder.addCase(getBenefitMetrics.pending, (state) =>
            ReducerHelpers.pending(state.benefits.metric)
        );
        builder.addCase(getBenefitMetrics.fulfilled, (state, action) =>
            ReducerHelpers.fulfilled(state.benefits.metric, action)
        );
        builder.addCase(getBenefitMetrics.rejected, (state) =>
            ReducerHelpers.rejected(state.benefits.metric)
        );
    },
});

function videoReducers(builder: ActionReducerMapBuilder<State>) {
    builder.addCase(uploadVideo.pending, (state, action) => {
        state.video.uploading = true;
        state.video.progress = 0;
        state.video.fileName = action.meta.arg.fileName;
    });
    builder.addCase(uploadVideo.fulfilled, (state, action) => {
        const { url, name } = action.payload;
        state.video.uploading = false;
        state.video.progress = 100;
        state.video.url = url;
        state.video.fileName = name;
    });
    builder.addCase(uploadVideo.rejected, (state) => {
        state.video.uploading = false;
        state.video.progress = 0;
        state.video.fileName = null;
    });
    builder.addCase(updateVideoProgress, (state, action) => {
        state.video.progress = Math.min(action.payload, 100);
    });
}

function imageReducers(builder: ActionReducerMapBuilder<State>) {
    builder.addCase(uploadImage.pending, (state, action) => {
        const fileName = action.meta.arg.fileName;
        state.images.upload[fileName] = {
            uploading: true,
            progress: 0,
            id: fileName,
        };
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
        const fileName = action.meta.arg.fileName;
        delete state.images.upload[fileName];
        state.images.list.unshift(action.payload);
    });
    builder.addCase(uploadImage.rejected, (state, action) => {
        const fileName = action.meta.arg.fileName;
        delete state.images.upload[fileName];
    });
    builder.addCase(updateImageProgress, (state, action) => {
        const { progress, name } = action.payload;
        const image = state.images.upload[name];

        image.progress = Math.min(progress, 100);
    });

    builder.addCase(deleteImage.pending, (state, action) => {});
    builder.addCase(deleteImage.fulfilled, (state, action) => {
        const id = action.meta.arg.mediaId;
        const index = state.images.list.findIndex((item) => item.id === id);
        if (index >= 0) {
            state.images.list.splice(index, 1);
        }
    });
    builder.addCase(deleteImage.rejected, (state, action) => {});
}

const AdminProfileEditActions = {
    uploadVideo,
    uploadImage,
    deleteImage,
    getBenefitMetricTypes,
    getBenefitMetrics,
};

export default AdminProfileEditActions;
export const AdminProfileEditReducer = slice.reducer;
