import * as Yup from 'yup';
import smoothscroll from 'smoothscroll-polyfill';

import { RequestService } from './common/_services';

export default function setup() {
    setViewportHeight();

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // kick off the polyfill!
    smoothscroll.polyfill();

    addValidation();
}

function setViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function addValidation() {
    Yup.addMethod(Yup.string, 'password', function () {
        const message = 'password';
        return this.test('password', message, function (value) {
            const { path, createError } = this;
            const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
            const isValid = regex.test(value);
            if (!isValid) {
                return createError({ path, message });
            }

            return isValid;
        });
    });

    Yup.addMethod(Yup.string, 'phoneNumber', function () {
        const message = 'phoneNumber';
        return this.test('phoneNumber', message, function (values) {
            const { path, createError } = this;
            try {
                const body = JSON.parse(values);
                return RequestService.post('phone-numbers/validate', body).then(
                    (response) => {
                        return response.status === 200;
                    },
                    () => {
                        return createError({ path, message });
                    }
                );
            } catch (error) {
                return createError({ path, message });
            }
        });
    });

    Yup.addMethod(Yup.string, 'error', function () {
        return this.typeError('string');
    });

    Yup.addMethod(Yup.string, 'addRequired', function () {
        return this.required('required');
    });

    Yup.addMethod(Yup.number, 'error', function () {
        return this.typeError('number');
    });

    Yup.addMethod(Yup.number, 'addRequired', function () {
        return this.required('required');
    });

    Yup.addMethod(Yup.boolean, 'error', function () {
        return this.typeError('boolean');
    });

    Yup.addMethod(Yup.boolean, 'addRequired', function () {
        return this.required('required');
    });
}
