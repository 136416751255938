import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    PropsWithChildren,
} from "react";
import { addEventHandler, removeEventHandler } from "../../_helpers";

type Context = {
    scroll: number;
    width: number;
    height: number;
};

const WindowContext = createContext<Context>({
    width: 0,
    height: 0,
    scroll: 0,
});

export function WindowStateProvider(props: PropsWithChildren<{}>) {
    const { children } = props;
    const [state, setState] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        scroll: window.pageYOffset,
    });

    useEffect(() => {
        function handleChange() {
            setTimeout(
                () =>
                    setState({
                        width: window.innerWidth,
                        height: window.innerHeight,
                        scroll: window.pageYOffset,
                    }),
                1
            );
        }

        addEventHandler(window, "touchend", handleChange);
        addEventHandler(window, "resize", handleChange);
        addEventHandler(window, "scroll", handleChange);

        return () => {
            removeEventHandler(window, "touchend", handleChange);
            removeEventHandler(window, "resize", handleChange);
            removeEventHandler(window, "scroll", handleChange);
        };
    }, []);

    return (
        <WindowContext.Provider value={state}>
            {children}
        </WindowContext.Provider>
    );
}

export function useWindowState() {
    return useContext<Context>(WindowContext);
}
