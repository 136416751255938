import { OrderedMap } from 'immutable';

import {
    GET_TRACKING_CARDS_SUCCESS,
    CHANGE_TRACKING_STATE_SUCCESS,
    REJECT_CANDIDATE_SUCCESS,
    GET_TRACKING_CARDS_FAIL,
    UNLOCK_CANDIDATE_SUCCESS,
} from '../../../_constants/actions/candidateTracking.constants';
import {
    TRACKING_ACCEPTED,
    TRACKING_APPLIED,
    TRACKING_INVITED,
    TRACKING_SHORTLISTED,
} from '../../../_constants/actions/tracking.constants';
import moment from 'moment';

const initialState = {
    [TRACKING_ACCEPTED]: OrderedMap(),
    [TRACKING_APPLIED]: OrderedMap(),
    [TRACKING_INVITED]: OrderedMap(),
    [TRACKING_SHORTLISTED]: OrderedMap(),
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_TRACKING_CARDS_SUCCESS:
            return getCards(initialState, payload);
        case GET_TRACKING_CARDS_FAIL:
            return initialState;
        case CHANGE_TRACKING_STATE_SUCCESS:
            return changeState(state, payload);
        case REJECT_CANDIDATE_SUCCESS:
            return rejectCandidate(state, payload);
        case UNLOCK_CANDIDATE_SUCCESS:
            return unlockCandidate(state, payload);
        default:
            return state;
    }
}

function getCards(state, cards) {
    Object.keys(cards).forEach((key) => {
        cards[key] = OrderedMap(cards[key].map((card) => [card.id, card]));
    });

    return { ...state, ...cards };
}

/**
 * @param {Map} cards
 * @param {{candidateId: number, state: string}} payload
 */
function changeState(state, { candidateId, oldStatus, newStatus }) {
    const oldCards = state[oldStatus];
    const cards = state[newStatus];

    const card = oldCards.get(candidateId);
    const newCard = {
        ...card,
        status: newStatus,
        unlocked: newStatus === TRACKING_ACCEPTED ? true : card.unlocked,
    };

    const newCards = cards.set(candidateId, newCard);
    let finalCards = newCards;

    if (newStatus !== TRACKING_ACCEPTED) {
        finalCards = newCards.sort((a, b) => {
            const isAfter = moment(a.responseTimeDeadline).isAfter(
                b.responseTimeDeadline
            );
            const isBefore = moment(a.responseTimeDeadline).isBefore(
                b.responseTimeDeadline
            );
            if (isAfter) {
                return 1;
            } else if (isBefore) {
                return -1;
            }

            return 0;
        });
    }

    return {
        ...state,
        [newStatus]: finalCards,
        [oldStatus]: oldCards.remove(candidateId),
    };
}

/**
 * @param {{}} state
 * @param {number} candidateId
 */
function rejectCandidate(state, { candidateId, status }) {
    const cards = state[status];
    return { ...state, [status]: cards.remove(candidateId) };
}

/**
 * @param {{}} state
 * @param {{id: number, card: { status: string }}} payload
 */
function unlockCandidate(state, { id, oldStatus, ...card }) {
    const candidateId = id;
    const oldCards = state[oldStatus];
    const cards = state[TRACKING_ACCEPTED];

    const oldCard = oldCards.get(candidateId);
    const newCard = {
        ...oldCard,
        ...card,
        status: TRACKING_ACCEPTED,
        unlocked: true,
    };

    return {
        ...state,
        [TRACKING_ACCEPTED]: cards.set(candidateId, newCard),
        [oldStatus]: oldCards.remove(candidateId),
    };
}
