import { Link } from "react-router-dom";
import { useTranslate } from "react-translate.ts";
import ISkillScore from "../../../../common/types/ISkillScore";
import { HardSkillMatch } from "../../HardSkillMatch/HardSkillMatch";
import { SkillsList } from "./SkillsList";
import { PROFILE_HARD_SKILLS_PATH } from "../../../_constants/route.constants";

type HardSkillsListProps = {
    heading: string;
    match: number;
    skills: ISkillScore[];
    isLoading: boolean;
};

export function HardSkillsList(props: HardSkillsListProps) {
    const { heading, match, skills, isLoading } = props;
    const translate = useTranslate("candidate.jobDetail");
    const translateJobs = useTranslate("common.jobs");

    return (
        <SkillsList
            heading={heading}
            match={match}
            skills={
                !isLoading ? skills : Array.from(new Array(4), () => {})
            }
            renderItem={(skill: ISkillScore, i) => (
                <Item key={i} skill={skill} isLoading={isLoading} />
            )}
            getWeightType={getWeightType}
            noSkillsText={translate("noHardSkillRequirements")}
            label={
                <>
                    <span>{`${translateJobs("match.hardSkills")} `}</span>
                    <Link to={PROFILE_HARD_SKILLS_PATH}>
                        {translateJobs("detail.skillsLink")}
                    </Link>
                </>
            }
            isLoading={isLoading}
        />
    );
}

function getWeightType(value: ISkillScore) {
    return value?.idealSkill?.weightType;
}

function Item(props: { skill: ISkillScore; isLoading: boolean }) {
    const { skill, isLoading } = props;
    return (
        <div>
            <HardSkillMatch
                match={skill?.skillMatchScore}
                name={skill?.idealSkill?.skills
                    .map((skill) => skill.name)
                    .join(' / ')
                }
                years={skill?.actualSkill?.timeYearCount}
                minYear={skill?.idealSkill?.timeYearCountMin}
                maxYear={skill?.idealSkill?.timeYearCountMax}
                level={skill?.actualSkill?.level}
                levelMin={skill?.idealSkill?.levelMin}
                levelMax={skill?.idealSkill?.levelMax}
                isLoading={isLoading}
            />
        </div>
    );
}
