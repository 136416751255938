import { combineReducers } from "redux";

import candidates from "./candidates/candidates.reducer";
import jobs from "./jobs/jobs.reducer";
import wallet from "./wallet";
import { AdminProfileEditReducer } from "../_actions/AdminProfileEditActions";
import { AdminTeamReducer } from "../_actions/AdminTeamActions";
import { AdminJobReducer } from "../_actions/AdminJobActions";
import { AdminProfileReducer } from "../_actions/AdminProfileAction";

export default combineReducers({
    candidates,
    jobs,
    wallet,
    profileEdit: AdminProfileEditReducer,
    team: AdminTeamReducer,
    job: AdminJobReducer,
    profile: AdminProfileReducer,
});
