export const GET_SKILLS_BEGIN = 'GET_SKILLS_BEGIN';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_ERROR = 'GET_SKILLS_ERROR';

export const GET_SKILL_CATEGORIES_BEGIN = 'GET_SKILL_CATEGORIES_BEGIN';
export const GET_SKILL_CATEGORIES_SUCCESS = 'GET_SKILL_CATEGORIES_SUCCESS';
export const GET_SKILL_CATEGORIES_ERROR = 'GET_SKILL_CATEGORIES_ERROR';

export const GET_SOFT_SKILLS = 'GET_SOFT_SKILLS';
export const GET_SOFT_SKILLS_SUCCESS = 'GET_SOFT_SKILLS_SUCCESS';
export const GET_SOFT_SKILLS_FAIL = 'GET_SOFT_SKILLS_FAIL';

export const GET_INTERPRETATION = 'GET_INTERPRETATION';
export const GET_INTERPRETATION_SUCCESS = 'GET_INTERPRETATION_SUCCESS';
export const GET_INTERPRETATION_FAIL = 'GET_INTERPRETATION_FAIL';
