import React, { Fragment, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import { useState } from "react";

import {
    Button,
    Range,
    IntegratedDialog,
} from "../../../../../common/components";
import { AvatarEditSelfie } from "./AvatarEditSelfie";
import { FormButtons } from "../FormButtons";
import { ImageHelpers } from "../../../../../common/_helpers/ImageHelpers";
import { Translate } from "react-translate.ts";

const MIN_SCALE = 1;
const MAX_SCALE = 3;
const MIN_ROTATION = -180;
const MAX_ROTATION = 180;
const SELFIE_SIZE = 1920;

export function AvatarEdit(props) {
    const { imageFileName, originalImageUrl, isUpdating, onSave, onClose } =
        props;

    let editor = null;

    const [originalImage, setOriginalImage] = useState(null);
    const [loadedImage, setLoadedImage] = useState(null);
    const [scale, setScale] = useState(MIN_SCALE);
    const [rotation, setRotation] = useState(0);
    const [isTakingPhoto, setIsTakingPhoto] = useState(false);

    useEffect(() => {
        let isCanceled = false;

        const convertOriginalImage = async () => {
            try {
                const response = await fetch(originalImageUrl);
                if (isCanceled || !response.ok) {
                    return;
                }

                const image = await ImageHelpers.loadImageToBase64(response);
                if (isCanceled || !image) {
                    return;
                }

                setOriginalImage(image);
            } catch (ex) {
                console.error(ex);
            }
        };

        if (originalImageUrl) {
            convertOriginalImage();
        }

        return () => {
            isCanceled = true;
        };
    }, [originalImageUrl]);

    if (isTakingPhoto) {
        return (
            <AvatarEditSelfie
                size={SELFIE_SIZE}
                onClose={close}
                onCancel={() => setIsTakingPhoto(false)}
                onConfirm={(photo) => setLoadedImage(photo)}
            />
        );
    }

    const imageStyle = { width: 250, height: 250, borderRadius: 250 };
    const editorIsEnabled = originalImage || loadedImage;
    return render();
    function render() {
        return (
            <IntegratedDialog onClose={close} isLoading={isUpdating}>
                <div id="avatar-edit">
                    <div id="avatar-edit-wrapper">
                        {editorIsEnabled ? (
                            renderEditor()
                        ) : (
                            <Placeholder onFileChange={loadFile} />
                        )}
                    </div>
                    <div className="w100 flex flex-column align-items-center">
                        <Ranges
                            scale={scale}
                            rotation={rotation}
                            onScaleChange={changeScale}
                            onRotationChange={changeRotation}
                            editorIsEnabled={editorIsEnabled}
                        />
                    </div>
                    <div className="w100">
                        <FormButtons
                            withCancel={false}
                            onCancel={close}
                            onConfirm={save}
                            confirmText="candidate.profile.photo.save"
                            prepend={
                                <Button
                                    variant="secondary"
                                    className="relative margin-right-5"
                                    isThin
                                >
                                    <Translate textOnly>
                                        candidate.profile.photo.add
                                    </Translate>
                                    <FileInput onChange={loadFile} />
                                </Button>
                            }
                        />
                    </div>
                    {renderSelfieButton()}
                </div>
            </IntegratedDialog>
        );
    }

    function renderEditor() {
        return (
            <Fragment>
                <AvatarEditor
                    ref={(ref) => (editor = ref)}
                    image={loadedImage || originalImage}
                    {...imageStyle}
                    scale={scale}
                    rotate={rotation}
                />
                <div id="avatar-edit-overlay-wrapper">
                    <div id="avatar-edit-overlay">
                        <div id="avatar-edit-overlay-h" />
                        <div id="avatar-edit-overlay-v" />
                    </div>
                </div>
            </Fragment>
        );
    }

    function renderSelfieButton() {
        if (!navigator.mediaDevices || true) {
            return null;
        }

        return (
            <div className="w100 flex justify-center padding-top-10">
                <Button
                    variant="tertiary"
                    onClick={() => setIsTakingPhoto(true)}
                    isThin
                >
                    <Translate textOnly>candidate.profile.photo.picture</Translate>
                </Button>
            </div>
        );
    }

    function changeScale(_, value) {
        setScale(value);
    }

    function changeRotation(_, value) {
        setRotation(value);
    }

    function close() {
        onClose();
    }

    async function save() {
        const convertedImage = editor.getImageScaledToCanvas().toDataURL();
        if (loadedImage) {
            const originalImage = await ImageHelpers.loadImageToBase64(
                new Response(loadedImage)
            );
            onSave(convertedImage, originalImage, loadedImage.name);
        } else {
            const originalImage = await ImageHelpers.loadImageToBase64(
                await fetch(originalImageUrl)
            );
            onSave(convertedImage, originalImage, imageFileName);
        }
    }

    function loadFile({ target }) {
        const file = target.files[0];
        setLoadedImage(file);
    }
}

function Placeholder({ onFileChange }) {
    return (
        <div className="relative">
            <div id="add-photo-text" className="text-blue absolute text-center">
                <Translate textOnly>candidate.profile.photo.add</Translate>
            </div>
            <img src="/img/upload-image.png" alt="" />
            <FileInput onChange={onFileChange} />
        </div>
    );
}

function Ranges({
    scale,
    onScaleChange,
    rotation,
    onRotationChange,
    editorIsEnabled,
}) {
    const zoomOptions = {
        label: "candidate.profile.photo.zoom",
        value: scale,
        valueAppend: "x",
        min: MIN_SCALE,
        max: MAX_SCALE,
        onChange: onScaleChange,
        step: 0.1,
    };
    const rotationOptions = {
        label: "candidate.profile.photo.rotation",
        value: rotation,
        valueAppend: <span>&deg;</span>,
        min: MIN_ROTATION,
        max: MAX_ROTATION,
        onChange: onRotationChange,
    };

    return (
        <Fragment>
            {renderRange(zoomOptions)}
            {renderRange(rotationOptions)}
        </Fragment>
    );

    function renderRange({
        label,
        value,
        valueAppend,
        onChange,
        min,
        max,
        step,
    }) {
        return (
            <div className="w100 w33-sm padding-y-10">
                <div className="flex justify-space-between">
                    <label>
                        <Translate textOnly>{label}</Translate>
                    </label>
                    <div>
                        {value}
                        {valueAppend}
                    </div>
                </div>
                <Range
                    onChange={onChange}
                    value={value}
                    min={min}
                    max={max}
                    disabled={!editorIsEnabled}
                    step={step}
                />
            </div>
        );
    }
}

function FileInput({ onChange }) {
    return <input className="clickable" type="file" onChange={onChange} />;
}
