import React, { useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { createClass } from 'classname-helper';
import { DeleteOutlined } from '@material-ui/icons';

import { Flex } from '../../common/components';

export function KanbanColumn({
    children,
    type,
    className,
    accept = '',
    onDrop,
    isHighlighted = false,
    onHover,
}) {
    const [, drop] = useDrop({
        accept,
        drop: (monitor) => {
            onDrop && onDrop(monitor);
            return { type };
        },
        hover: onHover,
    });

    return (
        <div
            ref={drop}
            className={createClass({
                'kanban-column': true,
                highlighted: isHighlighted,
                className,
            })}
        >
            {children}
        </div>
    );
}

export function KanbanCard({
    children,
    type = '',
    payload,
    onDrop,
    onDragStart,
    canDrag = true,
    index,
    hover,
    accept,
    isHighlighted,
    ...rest
}) {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            hover && hover(item, monitor, index, ref.current);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type, payload },
        begin: () => {
            onDragStart && onDragStart({ type });
        },
        end: (item, monitor) => {
            onDrop && onDrop(item, monitor.getDropResult());
        },
        canDrag: () => canDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        drag(drop(ref));
    });

    return (
        <div
            ref={ref}
            className={createClass({
                'kanban-card': true,
                highlighted: isHighlighted,
            })}
            style={{ opacity: isDragging ? '0' : '1' }}
            {...rest}
        >
            <div className="card-label">new</div>
            {children}
        </div>
    );
}

export function DeleteDrop({ isVisible, type, accept }) {
    const [{ isHovered }, drop] = useDrop({
        accept,
        drop: () => ({ type }),
        collect: (monitor) => {
            return {
                isHovered: monitor.isOver(),
            };
        },
    });

    if (!isVisible) {
        return null;
    }

    return (
        <Flex
            forwardRef={drop}
            className={createClass({
                'drop-delete font-65 text-secondary': true,
                hovered: isHovered,
            })}
            alignItems="center"
            justifyContent="center"
        >
            <DeleteOutlined fontSize="inherit" color="inherit" />
        </Flex>
    );
}
