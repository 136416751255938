import { RequestService } from "../../common/_services/RequestService";

export const SoftSkillsService = {
    getComparison,
    getStatuses,
    getStatus,
    getTest,
    completeTest,
};

const DIAGNOSTIC_URL = "diagnostic/";
// const PROFILE_URL = 'api/profiles';

function getComparison(payload) {
    const { profileId, test } = payload;
    return RequestService.get(
        "profiles/" +
            profileId +
            "/soft-skill-comparisons/" +
            test.toUpperCase()
    );
}

function getStatuses(profileId) {
    return RequestService.get(DIAGNOSTIC_URL + `${profileId}/statuses`);
}

function getStatus(payload) {
    const { profileId, name, useForce } = payload;

    return RequestService.get(
        DIAGNOSTIC_URL +
            `${profileId}/${name}/status` +
            (useForce ? "?force=1" : "")
    );
}

function getTest(payload) {
    const { profileId, test } = payload;
    return RequestService.get(DIAGNOSTIC_URL + `${profileId}/${test}`);
}

function completeTest(payload) {
    const { profileId, requestId } = payload;

    return RequestService.post(
        DIAGNOSTIC_URL + `${profileId}/completed?id=${requestId}`
    );
}
