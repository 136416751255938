import React from 'react';
import { addClass } from 'classname-helper';

export function TrackingColumn({ label, children, count = 0, className }) {
    return (
        <div className={addClass('tracking-column', className)}>
            <div className="tracking-column--label">
                <span className="tracking-column--label--text">{label}</span>
                <span className="label-bubble">{count}</span>
            </div>
            <div className="tracking-column--cards">{children}</div>
        </div>
    );
}
