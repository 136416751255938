import { addClass } from "classname-helper";
import { JobMatchChart } from "./JobMatchChart";
import { resolveFit } from "../../_helpers/resolveFit";
import { MathHelper } from "../../_helpers";

export function JobMatch(props) {
    const {
        fit = 0,
        label,
        size,
        className,
        children,
        noValue = false,
    } = props;
    const { color } = resolveFit(fit);
    return (
        <div className={addClass("job-match", className, size)}>
            <JobMatchChart {...props} color={color} />
            <div className="job-match--text">
                {noValue ? null : <Fit fit={fit} size={size} />}
                <div className={addClass("job-match--label", size)}>
                    {label}
                </div>
                {children ? (
                    <div className="font-11 text-secondary">{children}</div>
                ) : null}
            </div>
        </div>
    );
}

function Fit({ fit, size }) {
    return (
        <div
            className={addClass("job-match--value", size)}
            style={{ "--value": MathHelper.round(fit * 100, 0) }}
        >
            <sup>%</sup>
        </div>
    );
}
