import ReactGA from "react-ga";

export class GAUtils {

    static isDebugMode() {
        return false;
    }

    static initialize(user) {
        if(!GAUtils.isDebugMode()) {
            ReactGA.initialize('UA-149912875-1', {
                debug: true,
                titleCase: false,
                gaOptions: {
                    name: user.profileId,
                    userId: user.uuid
                }
            });
        }
    }

    static pageview() {
        if(!GAUtils.isDebugMode()) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    static event(category, action, label, value) {
        if(!GAUtils.isDebugMode()) {
            ReactGA.event({
                category: category, // 'User',
                action: action, //'Created an Account'
                label: label,
                value: value
            });
        }
    }
}
