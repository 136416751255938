import React from "react";
import styles from "./HofikSoftSkills.module.scss";
import Slider from "../../../../../common/components/input/Slider/Slider";
import cx from "classnames";
import ISkill from "../../../../../common/types/ISkill";

type Item = {
    value: number;
    skill: ISkill;
};

type HofikSoftSkillsProps = {
    heading: string;
    items: Item[];
};

export default function HofikSoftSkills(props: HofikSoftSkillsProps) {
    const { heading, items } = props;
    const [one, two, three, four] = items;

    return (
        <div className={styles.wrapper}>
            <h2>{heading}</h2>
            <div className={styles.items}>
                {[
                    [one, two],
                    [three, four],
                ].map((items: Item[], index: number) => {
                    const [left, right] = items;
                    return (
                        <div>
                            <div className={styles.labels}>
                                {renderLabel(left)}
                                {renderLabel(right)}
                            </div>
                            <Slider
                                key={index}
                                value={left?.value}
                                railHeight={8}
                                min={0}
                                max={100}
                                marks={[{ value: 50 }]}
                                readonly
                                renderMark={(_, position) => {
                                    return (
                                        <div
                                            className={styles.mark}
                                            style={{ left: `${position}%` }}
                                        />
                                    );
                                }}
                                classNames={{
                                    thumb: styles.thumb,
                                    rail: styles.rail,
                                    track: styles.track,
                                }}
                            />
                        </div>
                    );

                    function renderLabel(item: Item) {
                        return (
                            <div className={styles.label}>
                                <div className={styles.label__title}>
                                    {item?.skill?.name}
                                </div>
                                {item?.skill?.description && (
                                    <div
                                        className={cx(
                                            styles.label__desc,
                                            "font-book"
                                        )}
                                    >
                                        {item.skill.description}
                                    </div>
                                )}
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
}
