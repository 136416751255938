import React, { Children, cloneElement } from "react";

import { useTooltipLabel } from "../hooks/useTooltipLabel";

/**
 *
 * @param {{
 *    label: string;
 *    children: JSX.Element;
 *    anchor?: { x: "right", y: "middle" };
 * }} props
 * @returns
 */
export function TooltipLabel(props) {
    const { label, children, anchor = {} } = props;
    const [parentRef, labelElement] = useTooltipLabel(label, {
        anchorY: anchor.y,
        anchorX: anchor.x,
    });

    return (
        <>
            {Children.only(cloneElement(children, { ref: parentRef }))}
            {labelElement}
        </>
    );
}
