import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SIGNUP,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_WITHOUT_TOKEN,
    SIGNUP_WITHOUT_TOKEN_SUCCESS,
    SIGNUP_WITHOUT_TOKEN_FAIL,
} from '../../_constants/actions/auth.constants';

export default function (state = false, action) {
    switch (action.type) {
        case LOGIN:
        case SIGNUP:
        case SIGNUP_WITHOUT_TOKEN:
            return true;
        case LOGIN_SUCCESS:
        case SIGNUP_SUCCESS:
        case SIGNUP_WITHOUT_TOKEN_SUCCESS:
            return false;
        case LOGIN_FAIL:
        case SIGNUP_FAIL:
        case SIGNUP_WITHOUT_TOKEN_FAIL:
            return false;
        default:
            return state;
    }
}
