import { combineReducers } from 'redux';

import { CareerPathsConstants } from '../../_constants/actions/CareerPathsConstants';

function college(state = null, action) {
    if (action.type === CareerPathsConstants.GET_COLLEGE_DATA_SUCCESS) {
        return action.payload.content;
    }

    return state;
}

export default combineReducers({ college });
