import React from "react";

import { Jobs } from "../Jobs";
import { Base } from "../Base";
import { Benefits } from "../Benefits";
import { Overview } from "../Overview";
import { Offices } from "../Offices";
import { Gallery } from "../Gallery";

export function MobileLayout({ company, onFollow, displayOnly = true }) {
    const { jobs, jobBenefits } = company;

    return (
        <>
            <Base
                company={company}
                onFollow={onFollow}
                displayOnly={displayOnly}
            />
            <div className="company-profile--column">
                <Overview company={company} heading="overview.heading" />
                {company?.locations?.length > 0 && (
                    <Offices company={company} heading="locations.heading" />
                )}
                <Jobs heading="jobs.heading" jobs={jobs} />
                <Gallery heading="gallery.heading" images={company.images} />
                <Benefits heading="benefits.heading" benefits={jobBenefits} />
                {/*<CompanyPaths />*/}
            </div>
            {/* <MeetUs company={company} /> */}
            {/* {company.locations.length < 1 ? null : ( */}
            {/* <div className="map-container"> */}
            {/* <GoogleMap locations={company.locations} zoom={6} /> */}
            {/* </div> */}
            {/* )} */}
        </>
    );
}
