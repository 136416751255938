import { PropsWithChildren } from "react";
import BasicPageLayout from "../../../../common/components/layout/BasicPageLayout/BasicPageLayout";
import CandidateSideBar from "../../nav/CandidateSideBar/CandidateSideBar";
import CandidateHeader from "../../header/CandidateHeader/CandidateHeader";
import CandidateMobileNav from "../../nav/CandidateMobileNav/CandidateMobileNav";

type CandidatePageLayoutProps = PropsWithChildren<{
    headerProps?: any;
}>;

export default function CandidatePageLayout(props: CandidatePageLayoutProps) {
    const { children } = props;
    return (
        <BasicPageLayout
            header={<CandidateHeader />}
            sideBar={<CandidateSideBar />}
            mobileBar={<CandidateMobileNav />}
        >
            {children}
        </BasicPageLayout>
    );
}
