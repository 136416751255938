import { PropsWithChildren, useMemo } from "react";
import { useTranslate } from "react-translate.ts";
import ISkillWeightType from "../../../../common/types/ISkillWeightType";

import {
    SKILL_WEIGHT_TYPE_PREFERRED,
    SKILL_WEIGHT_TYPE_REQUIRED,
} from "../../../../common/_constants/actions/skill.constants";
import { ArrayHelper } from "../../../../common/_helpers/ArrayHelper";
import { BaseSkills } from "./BaseSkills";
import styles from "./SkillsList.module.scss";

type SkillsListProps = {
    heading: string;
    match: number;
    skills: any[];
    renderItem: RenderItem;
    getWeightType: GetWeightType;
    noSkillsText: string;
    label: JSX.Element;
    isLoading: boolean;
};

type RenderItem = (value: any, index: number) => JSX.Element;
type GetWeightType = (skill: any) => string;

export function SkillsList(props: PropsWithChildren<SkillsListProps>) {
    const {
        heading,
        match,
        skills,
        renderItem,
        getWeightType,
        noSkillsText,
        label,
        isLoading,
    } = props;

    const required = useMemo(
        () =>
            !isLoading
                ? filterSkills(
                      skills,
                      SKILL_WEIGHT_TYPE_REQUIRED,
                      getWeightType
                  )
                : skills,
        [skills, isLoading]
    );
    const prefered = useMemo(
        () =>
            !isLoading
                ? filterSkills(
                      skills,
                      SKILL_WEIGHT_TYPE_PREFERRED,
                      getWeightType
                  )
                : skills,
        [skills, isLoading]
    );

    const translate = useTranslate("common.misc");

    return (
        <BaseSkills heading={heading} match={match} label={label} isLoading={isLoading}>
            {ArrayHelper.isEmpty(skills) ? (
                <p className="text-secondary">{noSkillsText}</p>
            ) : (
                <>
                    <List
                        title={translate("required")}
                        skills={required}
                        renderItem={renderItem}
                        isLoading={isLoading}
                    />
                    <List
                        title={translate("optional")}
                        skills={prefered}
                        renderItem={renderItem}
                        isLoading={isLoading}
                    />
                </>
            )}
        </BaseSkills>
    );
}

type ListProps = {
    title: string;
    skills: any[];
    renderItem: RenderItem;
    isLoading: boolean;
};

function List(props: ListProps) {
    const { title, skills, renderItem, isLoading } = props;

    if (ArrayHelper.isEmpty(skills) && !isLoading) {
        return null;
    }

    return (
        <div className={styles.list}>
            <div className={styles.list__title}>{title}</div>
            <div className={styles.list__items}>{skills.map(renderItem)}</div>
        </div>
    );
}

function filterSkills(
    skills: any[],
    type: ISkillWeightType,
    getWeightType: GetWeightType
) {
    return skills?.filter((skill) => getWeightType(skill) === type) || [];
}
