import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TranslationProvider } from "react-translate.ts";
import { useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { GAUtils } from "./common/_helpers/GAUtils";
import { hotjar } from "react-hotjar";
import {
    MuiThemeProvider,
    NotificationList,
    ScrollIntoView,
} from "./common/components";
import { Routes } from "./Routes";
import { LanguageService } from "./common/_services";
import translations from "./translations";
import { AuthSelectors } from "./common/_selectors";

import "react-image-lightbox/style.css";
import "@stripe/stripe-js";

import { UserActions } from "./common/_actions/UserActions";
import { LanguageSelectors } from "./common/_selectors/LanguageSelectors";
import { MainState } from "./root.reducer";
import CookieManager from "./common/components/CookieManager/CookieManager";
import CookieConfigSelectors from "./common/_selectors/CookieConfigSelectors";
import CookieConfigActions from "./common/_actions/CookieConfigActions";

export function App() {
    const { user, isSigned, locale } = useSelector(mapState);
    const dispatch = useDispatch();
    const location = useLocation();
    const cookieConfig = useSelector(CookieConfigSelectors.getConfig);

    useEffect(() => {
        if (isSigned) {
            dispatch(UserActions.current());
        }
    }, [dispatch, isSigned]);

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            console.group("CURRENT LOCATION: ");
            console.info(`%c ${location.pathname}`, "color: green");
            console.groupEnd();
        }
    }, [location]);

    useEffect(() => {
        if (cookieConfig) {
            GAUtils.initialize(user);
            GAUtils.pageview();
            hotjar.initialize(2283307, 6);
        }
    }, [user, cookieConfig]);

    useEffect(() => {
        const cookieConsent = new URLSearchParams(location.search).get(
            "cookie-consent"
        );
        const cookieConfig = new URLSearchParams(location.search).get(
            "cookie-config"
        );

        if (cookieConfig !== null && cookieConfig !== undefined) {
            dispatch(CookieConfigActions.setConfig(cookieConfig));
        }

        if (parseInt(cookieConsent) === 1) {
            dispatch(CookieConfigActions.setConsent());
        }
    }, [location.search, dispatch]);

    return (
        <>
            <MuiThemeProvider>
                <LocalizationProvider
                    locale={locale}
                    dateAdapter={AdapterMoment}
                >
                    <ScrollIntoView />
                    <TranslationProvider
                        translations={translations}
                        locale={LanguageService.getCurrentLocale()}
                        options={{
                            fallbackLocale: "en",
                        }}
                    >
                        <NotificationList />
                        <Routes />
                        <CookieManager />
                    </TranslationProvider>
                </LocalizationProvider>
            </MuiThemeProvider>
        </>
    );
}

function mapState(state: MainState) {
    return {
        user: AuthSelectors.getUser(state),
        isSigned: AuthSelectors.isSigned(state),
        locale: LanguageSelectors.getLocale(state),
    };
}
