import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { DropdownMenu } from '../header/DropdownMenu';
import { useWindowState } from '../misc';
import { useSwitch } from '../../hooks';
import { BREAKPOINT_SM } from '../../_constants/theme.constants';
import { Button } from './Button';

/**
 *
 * @param {{
 *   filters:  { label: string, input: React.Element, props?: any}[]
 *   onApply: () => void;
 *   onReset: () => void;
 *   label?: string;
 * }} props
 */

export function FiltersButton(props) {
    const { children, label, onApply, onReset, filters } = props;
    const [isOpen, , close, toggle] = useSwitch();

    const { width } = useWindowState();
    const isFullscreen = width < BREAKPOINT_SM;

    const translate = useTranslate('common.buttons.filters');

    return (
        <div className="filters-button">
            <button onClick={toggle}>
                <img src="/img/icons/filter.svg" alt="" />{' '}
                <span>{translate('label')}</span>
            </button>
            <DropdownMenu
                isOpen={isOpen}
                onClose={close}
                isFullscreen={isFullscreen}
                arrowRight={45}
                hasBackdrop
            >
                <div className="filters-button__dropdown">
                    {label ? (
                        <div className="filters-button__label">{label}</div>
                    ) : null}
                    {children}
                    {filters.map((item, key) => (
                        <div
                            key={key}
                            className="filters-button__filter"
                            {...item.props}
                        >
                            {item.component ? (
                                item.component
                            ) : (
                                <>
                                    {item.label ? (
                                        <div className="filters-button__filter__label">
                                            {item.label}
                                        </div>
                                    ) : null}
                                    <div className="filters-button__filter__input">
                                        {item.input}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                    <div className="filters-button__buttons">
                        <Button onClick={onApply}>{translate('apply')}</Button>
                        <Button variant="tertiary" onClick={onReset}>
                            {translate('reject')}
                        </Button>
                    </div>
                </div>
            </DropdownMenu>
        </div>
    );
}
