import { combineReducers } from "redux";

import { ArrayHelper } from "../../../../_helpers/ArrayHelper";
import HeroOnboardingConstants from "../../../../_constants/actions/HeroOnboardingConstants";
import HeroProfileConstants from "../../../../_constants/actions/HeroProfileConstants";

export default combineReducers({ data, complete, moreOptions, saved });

function data(
    state = { positions: "", positions2: "", positions3: "" },
    action
) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS:
        case HeroProfileConstants.GET_PROFILE_SUCCESS:
            if (
                action.payload &&
                !ArrayHelper.isEmpty(action.payload.experiences)
            ) {
                const [positions, positions2, positions3] =
                    action.payload.experiences;

                return {
                    positions: positions && positions.title,
                    positions2: positions2 && positions2.title,
                    positions3: positions3 && positions3.title,
                };
            }
        default:
            return state;
    }
}

function complete(state = false, action) {
    if (action.type === HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS) {
        return true;
    }

    return state;
}

function saved(state = false, action) {
    switch (action.type) {
        case HeroOnboardingConstants.SAVE_POSITIONS:
        case HeroOnboardingConstants.SAVE_POSITIONS_FAIL:
            return false;
        case HeroOnboardingConstants.SAVE_POSITIONS_SUCCESS:
            return true;
        default:
            return state;
    }
}

function moreOptions(state = false, action) {
    if (action.type === HeroOnboardingConstants.SET_MORE_OPTIONS) {
        return action.payload;
    }

    return state;
}
