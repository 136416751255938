import React from 'react';

import { KanbanColumn } from '../../../../admin/components';
import { ApplicationCard } from './ApplicationCard';
import { TrackingColumn } from '../../../../common/components';

export function StatusColumn({
    label,
    type,
    canDrag,
    onCardDragEnd,
    onCardDragStart,
    jobPostId,
    onCardClick,
    cards,
    canDrop,
    dragAccept,
    accept,
    ...rest
}) {
    const count = cards && cards.length;
    const applicationCards = (
        <TrackingColumn label={label} count={count}>
            {cards &&
                cards.map((application, key) => {
                    return (
                        <ApplicationCard
                            key={key}
                            application={application}
                            canDrag={canDrag}
                            payload={application}
                            type={type}
                            onDrop={onCardDragEnd}
                            onDragStart={onCardDragStart}
                            onClick={() =>
                                onCardClick && onCardClick(application)
                            }
                            accept={dragAccept}
                        />
                    );
                })}
        </TrackingColumn>
    );

    if (canDrop) {
        return (
            <KanbanColumn type={type} accept={accept} {...rest}>
                {applicationCards}
            </KanbanColumn>
        );
    }

    return applicationCards;
}
