import {
    NOTIFICATION_PUSH,
    NOTIFICATION_POP,
} from "../../_constants/actions/notification.constants";
import NotificationHelpers from "../../_helpers/NotificationHelpers";
import { NotificationService } from "../../_services";

const initState = NotificationService.getLocalNotifications();

export default function (state = initState, action) {
    switch (action.type) {
        case NOTIFICATION_PUSH:
            return push(state, action.payload);
        case NOTIFICATION_POP:
            return pop(state, action.payload);
        default:
            return state;
    }
}

function push(state, notification) {
    return NotificationHelpers.addNotification(notification, state);
}

function pop(state, timestamp) {
    return NotificationHelpers.removeNotification(timestamp, state);
}
