import React from 'react';
import { useTranslate } from 'react-translate.ts';

import { ShareButton } from '../../../common/components/buttons/ShareButton';
import { BasePageLayout } from '../../../common/components';

export function BaseInterpretation(props) {
    const { children, links, onShare } = props;

    const translate = useTranslate('candidate.interpretation');

    return (
        <BasePageLayout
            id="interpretation"
            heading={translate('headings.full')}
            useHashLinks
            links={links}
            onShare={onShare}
            withShareButton={true}
            extraContent={
                <div id="interpretation__share">
                    <ShareButton onClick={onShare} />
                </div>
            }
        >
            {children}
        </BasePageLayout>
    );
}
