import moment from "moment";

import { LanguageService } from "../_services/LanguageService";

export function formatDate(date, format, locale) {
    const _locale = locale || LanguageService.getCurrentLocale();

    moment.locale(_locale);
    let momentDate = moment.utc(date);
    if (momentDate.isValid()) {
        return momentDate.format(format);
    }
    momentDate = moment(date, format);
    if (momentDate.isValid()) {
        return momentDate.format(format);
    }

    return moment.utc(date).format(format);
}

export const DateDiff = {
    inSeconds: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / 1000);
    },

    inMinutes: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (60 * 1000));
    },

    inHours: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (3600 * 1000));
    },

    inDays: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },

    inWeeks: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    inMonths: function (d1, d2) {
        const d1Y = d1.getFullYear();
        const d2Y = d2.getFullYear();
        const d1M = d1.getMonth();
        const d2M = d2.getMonth();

        return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },

    inYears: function (d1, d2) {
        return d2.getFullYear() - d1.getFullYear();
    },

    before: (d1, d2) => {
        const days = DateDiff.inDays(d1, d2);
        const hours = DateDiff.inHours(d1, d2);
        const minutes = DateDiff.inMinutes(d1, d2);
        const seconds = DateDiff.inSeconds(d1, d2);
        if (days > 0) {
            return [days, "day"];
        }
        if (hours > 0) {
            return [hours, "hour"];
        }
        if (minutes > 0) {
            return [minutes, "minute"];
        }
        if (seconds > 0) {
            return [seconds, "second"];
        }

        return [0, ""];
    },
};
