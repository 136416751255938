import { useCallback } from "react";
import TccQuestionnaire from "@tcc-online/questionnaire-react-integration";
import { cx } from "@emotion/css";

import { PRIMARY_COLOR } from "../../_constants/theme.constants";
import styles from "./TccTest.module.scss";

export interface TccTestProps {
    testName: string;
    test: { url: string; authToken: string; id: number };
    locale: string;
    onCompleted: (payload: { questionnaireId: number }) => void;
    className?: string;
}

export default function TccTest(props: TccTestProps) {
    const { test, className, onCompleted, locale, testName } = props;

    let questionnaireId: number;
    if (!test?.id) {
        const pathname = test?.url && new URL(test?.url)?.pathname;
        if (pathname) {
            questionnaireId = parseInt(
                pathname.substring(pathname.lastIndexOf("/") + 1),
                10
            );
        }
    }

    const renderQuestionnaire = useCallback(() => {
        return (
            <TccQuestionnaire
                questionnaireId={test.id || questionnaireId}
                locale={resolveLocale(locale, testName)}
                refreshToken={test.authToken}
                onQuestionnaireClosed={onQuestionnaireClosed}
                theme={{
                    primaryColor: PRIMARY_COLOR,
                    primaryDarkColor: PRIMARY_COLOR,
                    primaryHighColor: PRIMARY_COLOR,
                    secondaryColor: PRIMARY_COLOR,
                }}
                overrideTheme
                endpoint="https://api2.tcconline.cz/v1"
            />
        );
    }, [test?.id, questionnaireId, locale, test?.authToken, testName]);

    if (test?.authToken && (test?.id || questionnaireId)) {
        return (
            <div className={cx(styles.test, className)}>
                {renderQuestionnaire()}
            </div>
        );
    }

    return null;

    function onQuestionnaireClosed(payload: { questionnaireId: number }) {
        onCompleted && onCompleted(payload);
    }

    function resolveLocale(locale: string, name: string) {
        const _locale = locale.toUpperCase();
        const _name = name.toLowerCase();
        if (_locale === "PL") {
            if (_name !== "mop" && _name !== "kako") {
                return "EN";
            }

            return "PL";
        }

        return _locale;
    }
}
