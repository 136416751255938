import DashboardConstants from "../_constants/actions/DashboardConstants";

const DashboardActions = {
    getData,
};

function getData(profileId) {
    return {
        type: DashboardConstants.GET_DATA,
        payload: profileId,
    };
}

export default DashboardActions;
