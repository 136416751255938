import React, { useEffect, useMemo, useRef, useState } from "react";
import NetworkDiagram from "career-path-graph-component";
import "career-path-graph-component/dist/index.css";

import { useMeasurements } from "../../../../../../common/hooks";
import { MapFail } from "./MapFail";
import { BREAKPOINT_SM } from "../../../../../../common/_constants/theme.constants";
import { Controls } from "./Controls";
import { MapLoading } from "./MapLoading";
import { useBreakpoint } from "../../../../../../common/hooks/useBreakpoint";
import { useDispatch } from "react-redux";
import SvelteWrapper from "./SvelteWrapper";

const TOOLTIP_SHOW_DELAY = 200;
const TOOLTIP_HIDE_DELAY = 200;

export function DiagramContent(props) {
    const {
        wrapperRef,
        matchScoreEnabled,
        hoveredPathIndex,
        highlightedPathIndex,
        onRoute,
        activeRouteSearch,
        onNodeSelect,
        selectedNode = null,
        displayOnly = false,
        zoom,
        map,
        paths,
        isLoading,
        hasFailed,
        unsetSelectedNode,
        onZoom,
        onControlsZoom,
        minZoom,
        maxZoom,
        usePan = true,
        useResetButton,
        categories,
        onCategorySelect,
        profileImage,
        profileName,
        selectedCategoryId,
        selectedSubcategoryId,
    } = props;

    const dispatch = useDispatch();

    const [hovered, setHovered] = useState(null);

    const hoveredRef = useRef(null);
    const tooltipRef = useRef(null);
    const hoverOutTimer = useRef(null);

    const wrapperMeasurements = useMeasurements(wrapperRef);

    const isSmallScreen = useBreakpoint(BREAKPOINT_SM);

    useEffect(() => {
        if (hovered) {
            updateTooltipPosition();
        }
    }, [hovered, zoom]);

    const svelteComponent = useMemo(
        () => (
            <SvelteWrapper
                id="career-map-diagram"
                svelte={NetworkDiagram}
                data={{
                    data: map,
                    paths: paths || null,
                    minZoom,
                    maxZoom,
                    onNodeSelect: (node) => {
                        if (node.level === 0) {
                            unsetSelectedNode && unsetSelectedNode();
                            return;
                        }

                        onNodeSelect && onNodeSelect(node);

                        hideTooltip();
                    },
                    onNodeHover: (node, _, ref) => {
                        // if (isSmallScreen) {
                        //     return;
                        // }

                        clearTimer();

                        if (node.id === 0 || isSmallScreen) {
                            return;
                        }

                        hoveredRef.current = ref;
                        setHovered(node);
                    },
                    onNodeHoverOut: startHoverOutTimer,
                    onZoom,
                    // onPan: updateTooltipPosition,
                    zoom,
                    onBackdropClick: () => {
                        // if (hovered) {
                        //     hideTooltip();
                        // } else {
                        //     unsetSelectedNode && unsetSelectedNode();
                        // }
                        unsetSelectedNode && unsetSelectedNode();
                    },
                    selectedNode,
                    nodeHoverDelay: TOOLTIP_SHOW_DELAY,
                    matchScoreEnabled,
                    highlightedPathIndex,
                    hoveredPathIndex,
                    usePan,
                    categories,
                    onCategorySelect,
                    centerImage: profileImage,
                    centerText: profileName,
                    selectedCategory: selectedCategoryId,
                    selectedSubcategory: selectedSubcategoryId,
                    // useDebugMode: true,
                }}
            />
        ),
        [
            map,
            selectedNode,
            zoom,
            categories,
            profileImage,
            profileName,
            selectedCategoryId,
            selectedSubcategoryId,
        ]
    );

    if (hasFailed) {
        return <MapFail />;
    }

    if (isLoading) {
        return <MapLoading />;
    }

    if (!map) {
        return null;
    }

    return (
        <>
            {/* {!isSmallScreen ? (
                <BaseTooltip
                    forwardRef={tooltipRef}
                    style={{
                        bottom: "unset",
                        opacity: !!hovered ? "1" : "0",
                    }}
                    withArrow={false}
                    light
                    onMouseOver={clearTimer}
                    onMouseOut={startHoverOutTimer}
                >
                    {!!hovered ? (
                        <Tooltip
                            detail={hovered}
                            onRoute={onRoute}
                            displayOnly={displayOnly}
                        />
                    ) : null}
                </BaseTooltip>
            ) : null} */}
            {/*<NodeDetail*/}
            {/*    onClose={() =>*/}
            {/*        dispatch(CareerPathsActions.setSelectedNode(null))*/}
            {/*    }*/}
            {/*    node={selectedNode}*/}
            {/*/>*/}
            {!displayOnly ? (
                <Controls
                    onZoom={onControlsZoom}
                    useResetButton={useResetButton}
                />
            ) : null}
            {/* <LoadingOverlay isLoading={isLoading} /> */}
            {svelteComponent}
        </>
    );

    function hideTooltip() {
        setHovered(null);
    }

    function updateTooltipPosition() {
        if (!hovered || !hoveredRef.current || !tooltipRef.current) {
            return;
        }

        const x = parseInt(hoveredRef.current.attributes.x.nodeValue, 10);
        const y = parseInt(hoveredRef.current.attributes.y.nodeValue, 10);

        const top =
            (wrapperMeasurements.top + wrapperMeasurements.height >
            y + tooltipRef.current.offsetHeight
                ? y + 20
                : y - tooltipRef.current.offsetHeight - 20) + "px";

        const left =
            (wrapperMeasurements.left + wrapperMeasurements.width >
            x + tooltipRef.current.offsetWidth
                ? x - 40
                : x - tooltipRef.current.offsetWidth + 40) + "px";

        tooltipRef.current.style.left = left;
        tooltipRef.current.style.top = top;
    }

    function startHoverOutTimer() {
        hoverOutTimer.current = setTimeout(() => {
            setHovered(null);
        }, TOOLTIP_HIDE_DELAY);
    }

    function clearTimer() {
        if (hoverOutTimer.current !== null) {
            clearTimeout(hoverOutTimer.current);
            hoverOutTimer.current = null;
        }
    }
}
