import ITeamMember from "../../common/types/ITeamMember";
import TeamConstants from "../../common/_constants/TeamConstants";
import { RequestService } from "../../common/_services";

const ENDPOINT = "team";

let data = [
    {
        id: 1,
        firstName: "Peter",
        lastName: "Almasi",
        status: TeamConstants.OWNER,
        position: "Human Resource Manager",
        imgUrl: "",
    },
    {
        id: 2,
        firstName: "Hank",
        lastName: "Peterson",
        status: TeamConstants.MEMBER,
        position: "Human Resource Manager",
        imgUrl: "",
    },
    {
        id: 3,
        firstName: "Kevin",
        lastName: "McAlister",
        status: TeamConstants.MEMBER,
        position: "Human Resource Manager",
        imgUrl: "",
    },
    {
        id: 4,
        firstName: "John",
        lastName: "Almasi",
        status: TeamConstants.MEMBER,
        position: "Human Resource Manager",
        imgUrl: "",
    },
] as ITeamMember[];

const AdminTeamService = {
    getMembers,
    deleteMember,
    inviteMembers,
};

type GetMembersPayload = {
    search?: string;
};

async function getMembers(payload: GetMembersPayload): Promise<ITeamMember[]> {
    // return await RequestService.get(`${ENDPOINT}`);
    const { search } = payload;
    await new Promise((r) => setTimeout(r, 2500));

    if (!search) {
        return data;
    }

    return data.filter((value) => {
        const regex = new RegExp(search.trim(), "i");
        const name = `${value.firstName} ${value.lastName}`;

        return name.search(regex) >= 0;
    });
}

type DeleteMemberPayload = {
    id: number;
};

async function deleteMember(payload: DeleteMemberPayload): Promise<boolean> {
    const { id } = payload;
    const index = data.findIndex((value) => value.id === id);

    await new Promise((r) => setTimeout(r, 2500));

    return index >= 0;
}

type InviteMembersPayload = {
    id: number;
};

async function inviteMembers(
    payload: InviteMembersPayload
): Promise<{ ok: boolean }> {
    const { id } = payload;

    await new Promise((r) => setTimeout(r, 2000));

    return { ok: true };
}

export default AdminTeamService;
