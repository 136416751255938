import { takeLatest } from "redux-saga/effects";

import { JobService } from "../../candidate/_services";

import { JobDetailService } from "../_services";
import { SagaHelpers } from "../_helpers";
import {
    SET_JOB_FAVOURITE,
    UNSET_JOB_FAVOURITE,
    SET_JOB_FAVOURITE_SUCCESS,
    SET_JOB_FAVOURITE_FAIL,
    UNSET_JOB_FAVOURITE_SUCCESS,
    UNSET_JOB_FAVOURITE_FAIL,
    GET_JOB_FILTERS_SUCCESS,
    GET_JOB_FILTERS_FAIL,
    GET_JOB_FILTERS,
    GET_JOB_SALARY_RANGE_SUCCESS,
    GET_JOB_SALARY_RANGE_FAIL,
    GET_JOB_SALARY_RANGE,
    GET_JOB_CARDS,
    GET_JOB_CARDS_FAIL,
    GET_JOB_CARDS_SUCCESS,
} from "../../candidate/_constants";
import {
    JOB_DETAIL_ACCEPT,
    GET_JOB_DETAIL,
    GET_JOB_DETAIL_SUCCESS,
    GET_JOB_DETAIL_FAIL,
    JOB_DETAIL_ACCEPT_SUCCESS,
    JOB_DETAIL_ACCEPT_FAIL,
    JOB_DETAIL_APPLY_SUCCESS,
    JOB_DETAIL_APPLY_FAIL,
    JOB_DETAIL_APPLY,
} from "../_constants/actions/jobDetail.constants";

function* getCards(action) {
    const { profileId, page, activeFilters } = action.payload;

    const {
        locations,
        benefits,
        employmentType,
        remote,
        salary,
        currency,
        title
    } = activeFilters;

    const payload = {
        profileId,
        page: page - 1,
        remote,
        salaryMinAmount: salary[0],
        salaryMaxAmount: salary[1],
        salaryCurrency: currency && currency.id,
        locationCityIds: locations ? [locations.id] : null,
        jobBenefitIds: benefits
            .filter((item) => item.checked === true)
            .map((item) => item.id),
        employmentTypeIds: employmentType
            .filter((item) => item.checked === true)
            .map((item) => item.id),
        title: title || null,
    };

    yield SagaHelpers.handleRequest({
        request: [JobService.getCards, payload],
        actions: [
            (payload) => ({ type: GET_JOB_CARDS_SUCCESS, payload }),
            (error) => ({ type: GET_JOB_CARDS_FAIL, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* getSalaryRange(action) {
    yield SagaHelpers.handleRequest({
        request: [JobService.getSalaryRange, action.payload],
        actions: [
            (payload) => ({ type: GET_JOB_SALARY_RANGE_SUCCESS, payload }),
            (error) => ({ type: GET_JOB_SALARY_RANGE_FAIL, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* saveCard(payload, successConst, failConst) {
    yield SagaHelpers.handleRequest({
        request: [JobService.saveCard, payload],
        actions: [
            (payload) => ({ type: successConst, payload }),
            (error) => ({ type: failConst, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* setFavourite(action) {
    yield saveCard(
        { ...action.payload, saved: true },
        SET_JOB_FAVOURITE_SUCCESS,
        SET_JOB_FAVOURITE_FAIL
    );
}

function* unsetFavourite(action) {
    yield saveCard(
        { ...action.payload, saved: false },
        UNSET_JOB_FAVOURITE_SUCCESS,
        UNSET_JOB_FAVOURITE_FAIL
    );
}

function* getFilters() {
    yield SagaHelpers.handleRequest({
        request: [JobService.getFilters],
        actions: [
            (payload) => ({ type: GET_JOB_FILTERS_SUCCESS, payload }),
            (error) => ({ type: GET_JOB_FILTERS_FAIL, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* getDetail(action) {
    yield SagaHelpers.handleRequest({
        request: [JobDetailService.getDetail, action.payload],
        actions: [
            (payload) => ({ type: GET_JOB_DETAIL_SUCCESS, payload }),
            (error) => ({ type: GET_JOB_DETAIL_FAIL, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* applyToJob(action) {
    yield SagaHelpers.handleRequest({
        request: [JobDetailService.postJobApplication, action.payload],
        actions: [
            (payload) => ({ type: JOB_DETAIL_APPLY_SUCCESS, payload }),
            (error) => ({ type: JOB_DETAIL_APPLY_FAIL, error }),
        ],
        messages: {
            fail: "fail",
        },
    });
}

function* acceptJob(action) {
    yield SagaHelpers.handleRequest({
        request: [JobDetailService.acceptJobInvitation, action.payload],
        actions: [
            (payload) => ({ type: JOB_DETAIL_ACCEPT_SUCCESS, payload }),
            (error) => ({ type: JOB_DETAIL_ACCEPT_FAIL, error }),
        ],
        messages: {
            success: "candidate.jobs.detail.notify.invite.accept.success",
            fail: "candidate.jobs.detail.notify.invite.accept.fail",
        },
    });
}

export default [
    takeLatest(GET_JOB_CARDS, getCards),
    takeLatest(SET_JOB_FAVOURITE, setFavourite),
    takeLatest(UNSET_JOB_FAVOURITE, unsetFavourite),
    takeLatest(GET_JOB_FILTERS, getFilters),
    takeLatest(GET_JOB_SALARY_RANGE, getSalaryRange),
    takeLatest(GET_JOB_DETAIL, getDetail),
    takeLatest(JOB_DETAIL_APPLY, applyToJob),
    takeLatest(JOB_DETAIL_ACCEPT, acceptJob),
];
