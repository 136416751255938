import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import HeroOnboardingSelectors from "../../../../_selectors/HeroOnboarding/HeroOnboardingSelectors";
import { VideoRecord } from "../../../../components/misc/VideoRecord";
import { useRenderTo } from "../../../../hooks/useRenderTo";
import { VideoUpload } from "../../components/HeroVideo/VideoUpload/VideoUpload";
import { VideoLoading } from "../../components/HeroVideo/VideoLoading";
import { HeroVideoInfo } from "../HeroVideoInfo";
import HeroOnboardingActions from "../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import styles from "./HeroVideo.module.scss";
import VideoRecordPanel from "../../components/HeroVideo/VideoRecordPanel/VideoRecordPanel";

export function HeroVideo() {
    const dispatch = useDispatch();
    const { isRecordedVideoUploading, videoInfoSeen } = useSelector(mapState);
    const translate = useTranslate("common.heroOnboarding.steps.video.record");

    const [isRecording, setIsRecording] = useState(false);

    const portal = useRenderTo("app-main");

    if (!videoInfoSeen) {
        return <HeroVideoInfo />;
    }

    if (isRecordedVideoUploading) {
        return <VideoLoading />;
    }
    if (isRecording) {
        return <VideoRecord onClose={() => setIsRecording(false)} />;
    }

    return (
        <div className={styles.video}>
            <VideoRecordPanel
                onStart={() => setIsRecording(true)}
                onSkip={() => dispatch(HeroOnboardingActions.Video.skip())}
            />
            <VideoUpload />
        </div>
    );
}

function mapState(state) {
    return {
        data: HeroOnboardingSelectors.Advice.getData(state),
        questions: HeroOnboardingSelectors.Advice.getQuestions(state),
        isRecordedVideoUploading:
            HeroOnboardingSelectors.Advice.isRecordedVideoUploading(state),
        videoInfoSeen: HeroOnboardingSelectors.Advice.videoInfoSeen(state),
    };
}
