import { useEffect } from "react";
import { useDispatch } from "react-redux";

import TccTest from "../../../../components/TccTest/TccTest";
import useHeroSoftSkillsTest from "../../../../hooks/questionnaire/useHeroSoftSkillsTest";
import HeroOnboardingActions from "../../../../_actions/HeroOnboarding/HeroOnboardingActions";
import styles from "./HeroTest.module.scss";

export default function HeroTest() {
    const { test, heroProfileId, locale, completeTest } =
        useHeroSoftSkillsTest();

    const dispatch = useDispatch();

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroOnboardingActions.Skills.getTest(heroProfileId));
        }
    }, [dispatch, heroProfileId, locale]);

    useEffect(() => {
        if (heroProfileId) {
            dispatch(HeroOnboardingActions.Skills.getStatus(heroProfileId));
        }
    }, [dispatch, heroProfileId, locale]);

    return (
        <TccTest
            testName="mop"
            test={test}
            locale={locale}
            className={styles.test}
            onCompleted={completeTest}
        />
    );
}
