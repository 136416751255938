import React, { useState } from 'react';
import { Close } from '@material-ui/icons';
import { addClass } from 'classname-helper';

import { BaseDialog } from './BaseDialog';
import { Flex } from '../layout';
import { Checkbox } from '../input';
import { Button } from '../buttons';
import { Translate } from 'react-translate.ts';
import { LoadingOverlay } from '../loading';

/**
 * @param {{
 *  isVisible: boolean;
 *  heading?: string;
 *  subHeading?: string;
 *  infoList?: string[];
 *  buttonLabel?: string;
 *  icon?: JSX.Element;
 * }} props
 */
export function ActionDialog({
    children,
    isVisible,
    isConfirmed,
    onConfirm,
    onClose,
    icons,
    Buttons,
    autoCheckLabel,
    isLoading = false,
    ...rest
}) {
    const [hasConfirmed, setHasConfirmed] = useState(!!isConfirmed);

    return (
        <BaseDialog isVisible={isVisible} onClose={onClose}>
            {isConfirmed || hasConfirmed ? (
                <Content className="padding-30" isLoading={isLoading}>
                    <Icon icon={icons[1] ? icons[1] : icons[0]} />
                    {children}
                </Content>
            ) : (
                <Content className="padding-50" isLoading={isLoading}>
                    <ActionContent
                        {...rest}
                        autoCheckLabel={autoCheckLabel}
                        onClose={onClose}
                        onConfirm={onConfirm || setHasConfirmed}
                        icon={icons[0]}
                        Buttons={Buttons}
                    />
                </Content>
            )}
        </BaseDialog>
    );
}

function Icon({ icon }) {
    return (
        <div className="action-dialog--icon">
            <img src={icon} alt="" />
        </div>
    );
}

function ActionContent({
    onClose,
    onConfirm,
    heading,
    subHeading,
    infoList,
    buttonLabel,
    icon,
    Buttons,
    autoCheckLabel,
}) {
    return (
        <>
            <div className="action-dialog--close" onClick={onClose}>
                <Close color="primary" />
            </div>
            <Flex
                alignItems="center"
                direction="column"
                className="surface padding-40 text-center border-bottom"
            >
                <Icon icon={icon} />
                <h2>{heading}</h2>
                <div className="font-12">{subHeading}</div>
            </Flex>
            <Flex
                alignItems="center"
                direction="column"
                className="surface padding-40"
            >
                <ul>
                    {infoList.map((info, key) => (
                        <li key={key}>{info}</li>
                    ))}
                </ul>
            </Flex>
            <AutoCheck text={autoCheckLabel} />
            <div>
                {Buttons ? (
                    Buttons
                ) : (
                    <Button isWide onClick={() => onConfirm(true)}>
                        {buttonLabel}
                    </Button>
                )}
            </div>
        </>
    );
}

function Content({ className, children, isLoading }) {
    return (
        <Flex
            direction="column"
            className={addClass('action-dialog', className)}
            spacing={10}
        >
            <LoadingOverlay isLoading={isLoading} isFull />
            {children}
        </Flex>
    );
}

export function AutoCheck({ text }) {
    return (
        <div>
            <Checkbox>
                {text ? (
                    text
                ) : (
                    <Translate textOnly>common.misc.autoCheck</Translate>
                )}
            </Checkbox>
        </div>
    );
}
