import { LanguageService } from ".";
import { RequestService } from "./RequestService";
import { UserService } from "./UserService";
import * as api from "../../types/api";
import axios from "axios";

const HeroOnboardingService = {
    saveUser,
    savePersonal,
    savePositions,
    saveAdvice,
    getTest,
    completeTest,
    getTestStatus,
    getQuestions,
    uploadVideo,
    skipVideo,
};

const BASE_URL = "heroes/on-boarding";
const BASE_TEST_URL = "heroes/diagnostic";

function saveUser(payload) {
    const { username, password } = payload;

    return RequestService.post("auth/heroes/signup", {
        username,
        password,
    });
}

function savePersonal(payload) {
    const { firstName, lastName, introduction, imageUrl, linkedInUrl } =
        payload;

    return RequestService.post(BASE_URL + "/personal-details", {
        firstName,
        lastName,
        introduction,
        imageUrl,
        linkedInUrl,
    });
}

function savePositions(payload) {
    const { positions, positions1, positions2 } = payload;

    return RequestService.post(BASE_URL + "/job-experience", {
        experiences: [positions, positions1, positions2],
    });
}

function saveAdvice(payload) {
    const answers = payload.map((question) => {
        return {
            questionId: question.id,
            answer: question.value,
        };
    });

    return RequestService.post(BASE_URL + "/answers", {
        answers,
    });
}

function getTest(payload) {
    const profileId = payload;

    return RequestService.get(`${BASE_TEST_URL}/${profileId}/MOP`);
}

function completeTest(payload) {
    const { profileId, id } = payload;

    return RequestService.post(
        `${BASE_TEST_URL}/${profileId}/completed?id=${id}`
    );
}

function getTestStatus(payload) {
    const profileId = payload;

    return RequestService.get(
        `${BASE_TEST_URL}/${profileId}/MOP/status?force=1`
    );
}

function getQuestions() {
    return RequestService.get("heroes/questions");
}

async function uploadVideo(data, cancelToken, onUploadProgress) {
    const headers = {
        Authorization: `Bearer ${UserService.getAccessToken()}`,
        "Accept-Language": LanguageService.getCurrentLocale(),
    };

    return await axios({
        method: "post",
        url: `${api.endpoint}/${BASE_URL}/video`,
        data,
        headers,
        onUploadProgress,
        cancelToken: cancelToken.token,
    });
}

function skipVideo() {
    return RequestService.post(`${BASE_URL}/video/skip`);
}

export default HeroOnboardingService;
