import { combineReducers } from "redux";

import CareerSearchConstants from "../../_constants/actions/CareerSearchConstants";
import { ReducerHelpers } from "../../_helpers";

const state = (state, action) => ReducerHelpers.loading(state, action, CareerSearchConstants.GET_JOB_ROLES);

export default combineReducers({ jobRoles, state });

function jobRoles(state = null, action) {
    switch(action.type) {
        case CareerSearchConstants.GET_JOB_ROLES_SUCCESS:
            return action.payload.data;
        default:
            return state;
    }
}