import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../../../../../common/components";
import { ProfileActions } from "../../../../../common/_actions/ProfileActions";
import { AuthSelectors } from "../../../../../common/_selectors";
import { useFormikContext } from "formik";
import EducationForm from "../../../../../common/components/form/forms/EducationForm";

export function UniversityForm(props) {
    const { onSubmit, showForm = true } = props;

    const { profileId } = useSelector(mapState);

    const dispatch = useDispatch();

    const [state, setState] = useState(false);

    if (showForm) {
        return (
            <>
                <div className="form">
                    <div className="subtitle">
                        Please fill up your university details, based on that we
                        show your potentianal career map
                    </div>
                    <div>
                        <EducationForm isInitialValid={false} onSubmit={submit}>
                            <ValidationChange
                                onStateChange={(values) => setState(values)}
                            />
                        </EducationForm>
                    </div>
                </div>
                {renderButton(submit, !state.isValid)}
            </>
        );
    }

    return renderButton(submitEmpty, false);

    function renderButton(onClick, disabled) {
        return (
            <Button onClick={onClick} disabled={disabled}>
                Show career map
            </Button>
        );
    }

    function submitEmpty() {
        dispatch(ProfileActions.saveProfileType(profileId, "UNIVERSITY"));

        onSubmit && onSubmit();
    }

    function submit() {
        if (!state.isValid === null) {
            return;
        }

        const {
            endYear,
            startYear,
            location,
            universityDegreeField,
            universityDegreeTitle,
            universityDegreeType,
            university,
        } = state.values;

        dispatch(
            ProfileActions.saveProfileType(profileId, "UNIVERSITY", {
                endYear,
                startYear,
                universityId: university.id,
                universityDegreeTypeId: universityDegreeType.id,
                universityDegreeFieldId: universityDegreeField.id,
                universityDegreeTitleId: universityDegreeTitle.id,
                locationId: location.placeId,
            })
        );

        onSubmit && onSubmit();
    }
}

function ValidationChange(props) {
    const { isValid, values } = useFormikContext();

    useEffect(() => {
        props.onStateChange({ isValid, values });
    }, [isValid, values]);

    return null;
}

function mapState(state) {
    return {
        profileId: AuthSelectors.getProfileId(state),
    };
}
