import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-translate.ts";

import { EmploymentFilters } from "./parts/EmploymentFilters";
import { BenefitsFilters } from "./parts/BenefitsFilters";
import SalaryFilters from "./parts/SalaryFilters";
import { FiltersContextProvider } from "./parts/FiltersContext";
import {
    STATE_LOADING,
    STATE_FAIL,
} from "../../../../common/_constants/state.constants";
import { JobFilterActions } from "../../../_actions";
import FiltersWrapper from "../../../../common/components/filters/FiltersWrapper/FiltersWrapper";

export function JobFilters() {
    const {
        employmentType,
        benefits,
        remote,
        isLoading,
        hasFailed,
        salaryRange,
        salary,
        currency,
        currencyDTOS,
        employmentTypeCount,
        benefitsCount,
        salaryActive,
    } = useSelector(mapState);
    const dispatch = useDispatch();
    const translate = useTranslate("candidate.jobs.filters");

    return (
        <FiltersWrapper>
            <FiltersContextProvider>
                <FiltersWrapper.Button
                    label={translate("type.label")}
                    count={employmentTypeCount}
                    active={employmentTypeCount > 0}
                >
                    <EmploymentFilters
                        filters={employmentType}
                        isLoading={isLoading}
                        hasFailed={hasFailed}
                    />
                </FiltersWrapper.Button>
                <FiltersWrapper.Button
                    label={translate("benefits.label")}
                    count={benefitsCount}
                    active={benefitsCount > 0}
                >
                    <BenefitsFilters
                        filters={benefits}
                        isLoading={isLoading}
                        hasFailed={hasFailed}
                    />
                </FiltersWrapper.Button>
                <FiltersWrapper.Button label={translate("salary.label")} active={salaryActive}>
                    <SalaryFilters
                        currency={currency}
                        salary={salary}
                        isLoading={isLoading}
                        hasFailed={hasFailed}
                        salaryRange={salaryRange}
                        currencyDTOS={currencyDTOS}
                    />
                </FiltersWrapper.Button>
                <FiltersWrapper.Check
                    label={translate("remote")}
                    active={remote}
                    onChange={() =>
                        dispatch(JobFilterActions.changeRemote(!remote))
                    }
                />
            </FiltersContextProvider>
        </FiltersWrapper>
    );
}

function mapState(state: any) {
    const { filters, activeFilters } = state.candidate.jobs.search;
    const { locationCityDTOS, employmentTypeDTOS, currencyDTOS } = filters.data;
    const {
        locations,
        roles,
        employmentType,
        benefits,
        remote,
        salary,
        currency,
        state: asyncState,
    } = activeFilters;

    return {
        activeFilters,
        locationCityDTOS,
        employmentTypeDTOS,
        locations,
        roles,
        employmentType,
        benefits,
        remote,
        isLoading: asyncState === STATE_LOADING,
        hasFailed: asyncState === STATE_FAIL,
        salaryRange: filters.salaryRange,
        salary,
        currency,
        currencyDTOS,
        employmentTypeCount: employmentType.filter(count).length,
        benefitsCount: benefits.filter(count).length,
        salaryActive: !salary.includes(null),
    };

    function count(item: any) {
        return item.checked;
    }
}
