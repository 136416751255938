import { combineReducers } from 'redux';
import { List, Map } from 'immutable';

import cards from './cards.reducer';
import pagination from './pagination.reducer';
import loading from './loading.reducer';
import saved from './saved.reducer';

import {
    RESET_CANDIDATE_SEARCH_FILTER,
    SET_CANDIDATE_SEARCH_FILTER,
} from '../../../_constants/actions/candidateSearch.constants';
import {
    INVITE_CANDIDATE_SUCCESS,
    INVITE_CANDIDATE_FAIL,
    INVITE_CANDIDATE,
} from '../../../_constants/actions/candidates.constants';

const defaultFilters = {
    locations: List(),
    skills: List(),
    university: List(),
    // graduation: null,
    graduation: [null, null],
    experience: [null, null],
    calculators: Map([
        ['calculatorHardSkills', true],
        ['calculatorSoftSkills', true],
        ['calculatorLanguages', true],
        ['calculatorQualifications', true],
    ]),
};

const defaultState = {
    jobPost: null,
    ...defaultFilters,
};

const filters = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_CANDIDATE_SEARCH_FILTER:
            return { ...state, ...defaultFilters };
        case SET_CANDIDATE_SEARCH_FILTER:
            const { key, value } = action.payload;
            return Object.assign({}, state, { [key]: value });
        default:
            return state;
    }
};

export default combineReducers({
    cards,
    pagination,
    loading,
    filters,
    saved,
    inviteLoading,
});

function inviteLoading(state = false, action) {
    switch (action.type) {
        case INVITE_CANDIDATE:
            return true;
        case INVITE_CANDIDATE_SUCCESS:
        case INVITE_CANDIDATE_FAIL:
            return false;
        default:
            return state;
    }
}
