import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { CareerPathsSelector } from "./components/CareerPathsSelector/CareerPathsSelector";
import { Loading } from "../../../common/components";
import { CareerPathsMap } from "./components/CareerPathsMap/CareerPathsMap";
import {
    AuthSelectors,
    UserProfileSelectors,
} from "../../../common/_selectors";
import { ProfileActions } from "../../../common/_actions/ProfileActions";
import { CareerPathsActions } from "../../_actions";

export function CareerPaths() {
    const { profileId, profileType } = useSelector(mapState);
    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!profileId) {
            return;
        }

        dispatch(ProfileActions.getProfileType(profileId));
    }, [profileId]);

    useEffect(() => {
        if (location.state && location.state.targetId && profileType) {
            dispatch(
                CareerPathsActions.getMapPaths(
                    profileType.jobRoleId,
                    location.state.targetId
                )
            );
        }
    }, [location.state, profileType]);

    // useEffect(() => {
    //     const header = document.getElementsByTagName("header")[0];
    //
    //     header.className = "transparent";
    //
    //     return () => {
    //         header.className = null;
    //     };
    // }, []);

    if (!profileType) {
        return <Loading />;
    }

    if (!profileType.type) {
        return <CareerPathsSelector />;
    }

    return <CareerPathsMap />;
}

function mapState(state) {
    return {
        profileType: UserProfileSelectors.getType(state),
        profileId: AuthSelectors.getProfileId(state),
    };
}
