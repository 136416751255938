import React from "react";
import { useSelector } from "react-redux";
import { Translate, useTranslate } from "react-translate.ts";

import HeroProfileSelectors from "../../../_selectors/HeroProfileSelectors";
import { Matches } from "../components/Matches";

export function CompleteMatch() {
    const { heroProfile } = useSelector(mapState);
    const translate = useTranslate("candidate.heroes.profile.complete");

    const { matchScore } = heroProfile;

    if (!matchScore || !matchScore.attributeScores) {
        return <Translate>common.misc.noData</Translate>;
    }

    const { activity, decisions, perception, setting } =
        matchScore.attributeScores;

    return (
        <div id="hero-profile__match" className="hero-card">
            <Matches match={perception} title={translate("perception")} />
            <Matches match={setting} title={translate("setting")} />
            <Matches match={activity} title={translate("activity")} />
            <Matches match={decisions} title={translate("decisions")} />
        </div>
    );
}

function mapState(state) {
    return {
        heroProfile: HeroProfileSelectors.getProfile(state),
    };
}
