import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CandidateTrackingActions } from '../../../_actions';
import { makeGetArchivedCards } from '../../../_selectors/candidateTracking.selectors';
import { InactiveCards } from './InactiveCards';

export function CandidatesArchive({ jobPostId, onCardClick }) {
    const isLoading = useSelector(mapState);
    const getArchivedCards = mapActions(useDispatch());

    useEffect(() => {
        getArchivedCards(jobPostId);
    }, [jobPostId]);

    return (
        <div>
            <InactiveCards
                isLoading={isLoading}
                jobPostId={jobPostId}
                cardSelector={makeGetArchivedCards}
                onCardClick={onCardClick}
            />
        </div>
    );
}

function mapState(state) {
    return state.admin.candidates.candidateTracking.loading;
}

function mapActions(dispatch) {
    return (jobPostId) =>
        dispatch(CandidateTrackingActions.getArchivedCards(jobPostId));
}
