import { useTranslate } from "react-translate.ts";

import { SVGIcon } from "../../SVGIcon";
import ActionButton, { ActionButtonProps } from "../ActionButton/ActionButton";

export default function DeleteButton(props: ActionButtonProps) {
    const { onClick, label, ...rest } = props;
    const translate = useTranslate();

    return (
        <ActionButton
            label={label || translate("common.actions.delete")}
            onClick={onClick}
            {...rest}
        >
            <SVGIcon name="trash" light />
        </ActionButton>
    );
}
