import { HTMLAttributes, MutableRefObject } from "react";
import { cx } from "@emotion/css";

import { useFileDrop } from "../../../hooks";
import styles from "./FileDrop.module.scss";
import FileInput, { FileInputRef } from "../FileInput/FileInput";

export interface FileDropProps extends HTMLAttributes<HTMLDivElement> {
    image: string | React.ReactNode;
    text: string | React.ReactNode;
    onFileDrop?: (files: FileList) => void;
    accept?: string;
    inputRef?: MutableRefObject<FileInputRef>;
    multiple?: boolean;
}

export default function FileDrop(props: FileDropProps) {
    const {
        image,
        text,
        onFileDrop,
        inputRef,
        accept,
        className,
        multiple,
        ...rest
    } = props;

    const [dropRef, isDraggingFile] =
        useFileDrop<HTMLDivElement>(handleFileDrop);

    return (
        <div
            className={cx(
                styles.container,
                className,
                isDraggingFile ? styles.dragging : null
            )}
            ref={dropRef}
            {...rest}
        >
            <div className={styles.content}>
                <div className={styles.logo}>
                    {typeof image === "string" ? (
                        <img src={image} alt="" />
                    ) : (
                        image
                    )}
                </div>
                <div className={styles.text}>{text}</div>
            </div>
            <FileInput
                forwardRef={inputRef}
                accept={accept}
                multiple={multiple}
            />
        </div>
    );

    function handleFileDrop(files: FileList) {
        onFileDrop && onFileDrop(files);
    }
}
