import { RequestService } from '../../common/_services';

export const CandidateService = {
    getCards,
    getProfile,
    getProfileMatch,
    searchJobPosts,
    inviteCandidate,
    setFavourite,
    unsetFavourite,
    getFavourites,
};

/**
 * Get candidate cards
 * @param {{
 *      jobPostId: number;
 *      page: number;
 *      sorts: Map;
 *      filters: { jobPost: any | null; locations: Immutable.List; skills: Immutable.List; age: number[]; calculators: Immutable.Map };
 * }} params
 */
function getCards({ jobPostId, page, filters }, saved) {
    const calculators = filters.calculators
        .filter((value) => value)
        .keySeq()
        .toArray()
        .toString();
    const locationCityIds = filters.locations
        .toArray()
        .map((item) => item.placeId);
    const universityIds = filters.university.toArray().map((item) => item.id);
    const skillIds = filters.skills.toArray().map((item) => item.id);
    const [experienceMin, experienceMax] = filters.experience;
    const [graduationMin, graduationMax] = filters.graduation;

    const size = 10;
    return RequestService.get('candidates/cards', [
        { name: 'size', value: size },
        { name: 'saved', value: saved ? 1 : null },
        { name: 'jobPostId', value: jobPostId },
        { name: 'page', value: page },
        { name: 'calculators', value: calculators },
        { name: 'skillIds', value: skillIds },
        { name: 'locationCityIds', value: locationCityIds },
        { name: 'universityIds', value: universityIds },
        {
            name: 'experienceYearsMin',
            value: experienceMin && experienceMin.value,
        },
        {
            name: 'experienceYearsMax',
            value: experienceMax && experienceMax.value,
        },
        {
            name: 'educationGraduationYearMin',
            value: graduationMin && graduationMin.date,
        },
        {
            name: 'educationGraduationYearMax',
            value: graduationMax && graduationMax.date,
        },
    ]);
}

/**
 * Get candidate's profile
 * @param {number} profileId
 */
function getProfile({ profileId, jobPostId }) {
    return RequestService.get(`api/profiles/${profileId}`, [
        { name: 'jobPostId', value: jobPostId === 0 ? null : jobPostId },
    ]);
}

/**
 * Get candidates job role match
 * @param {{jobPostId: number, profileId: number}} params
 */
function getProfileMatch({ jobPostId, profileId }) {
    return RequestService.get(
        `profiles/${profileId}/job-matches/${
            jobPostId === 0 ? null : jobPostId
        }`
    );
}

/**
 * @param {number} companyId
 */
function searchJobPosts(companyId) {
    const url = 'api/job-posts/autocomplete';
    return RequestService.get(`${url}?companyId=${companyId}`);
}

function inviteCandidate({ profileId, jobPostId }) {
    return RequestService.post(`api/profiles/${profileId}/job-invites`, {
        profileId,
        jobPostId,
    });
}

function getFavourites(payload) {
    return getCards(payload, true);
}

function setFavourite(payload) {
    return favourite(payload, true);
}

function unsetFavourite(payload) {
    return favourite(payload, false);
}

function favourite({ profileId, jobPostId, candidate }, saved) {
    return RequestService.post('saved-candidates', {
        profileId,
        jobPostId,
        userUUID: candidate.profile.userUUID,
        saved,
    });
}
